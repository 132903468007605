import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import SearchModal from './SearchModal';

function mapStateToProps(state) {
  return {
    menu: state.menu,
    showCategoryInCard: state.keeper.showCategoryInCard,
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SearchModal));
