import { createSelector } from 'reselect';

export const productSelector = createSelector(
  state => state.menu.products,
  items => items,
);


export const itemsSelector = createSelector(
  state => state.menu.items,
  items => items,
);
