import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { showMessage } from '../../../redux/message/actions';
import Items from './Items';

function mapStateToProps(state) {
  return {
    resolvedBills: state.statistic.resolvedBills,
    menu: state.menu,
    equipment: state.equipment,
    activePlace: state.business.activePlace,
    currency: state.business.currency,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
      },
      dispatch,
    ),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Items));
