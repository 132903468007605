import { notificationTypes } from '../../redux/message/actions';
import { checkIsNameExist, hasEmptyString, hasZeroKey } from '../../utils/helpers';

export const getCleanState = t => ({
  name: '',
  price: { S: 0, M: 0, L: 0 },
  volume: { S: 0, M: 0, L: 0 },
  components: [],
  selectedItems: [],
  sizes: ['S', 'M', 'L'],
  category: t('global.all'),
  photo: null,
  consumables: [],
});

export const isProductValid = ({
  name, t, showMessage, sizes, price, volume, components, menu,
}) => {
  let isValid = true;
  if (name.trim() === '') {
    showMessage({ text: t('validation.productName'), type: notificationTypes.ERROR });
    isValid = false;
  }
  if (sizes.length === 0) {
    showMessage({ text: t('validation.pickSize'), type: notificationTypes.ERROR });
    isValid = false;
  }
  /* if (components.length === 0) {
    showMessage({ text: t('validation.productComponents'), type: notificationTypes.ERROR });
    isValid = false;
  } */
  if (hasEmptyString(price)) {
    showMessage({ text: t('validation.productPrice'), type: notificationTypes.ERROR });
    isValid = false;
  }
  if (hasZeroKey(volume)) {
    showMessage({ text: t('validation.productVolume'), type: notificationTypes.ERROR });
    isValid = false;
  }
  if (!components.reduce((isValid, el) => isValid && !hasZeroKey(el.count), true)) {
    showMessage({ text: t('validation.componentCount'), type: notificationTypes.ERROR });
    isValid = false;
  }
  if (menu && checkIsNameExist(name, menu.products)) {
    showMessage({ text: t('validation.uniqueProductName'), type: notificationTypes.ERROR });
    isValid = false;
  }
  return isValid;
};

export const toggleComponent = (component, self) => self.setState((state) => {
  const included = state.selectedItems.includes(component.name);
  return ({
    selectedItems: included
      ? state.selectedItems.filter(el => el !== component.name) : [...state.selectedItems, component.name],
    components: included
      ? state.components.filter(el => el.name !== component.name)
      : [...state.components, {
        ...component,
        count: state.sizes.reduce((res, key) => ({ ...res, [key]: 0 }), {}),
      }],
  });
});

export const updateSizes = (sizes, self) => self.setState(state => ({
  sizes,
  price: sizes.reduce((res, key) => ({ ...res, [key]: state.price[key] ? state.price[key] : 0 }), {}),
  volume: sizes.reduce((res, key) => ({ ...res, [key]: state.volume[key] ? state.volume[key] : 0 }), {}),
  components: state.components.map(el => ({
    ...el, count: sizes.reduce((res, key) => ({ ...res, [key]: el.count[key] ? el.count[key] : 0 }), {}),
  })),
}));

export const updateCount = (value, record, size, self) => self.setState(state => ({
  components: state.components.map(item => (item.name === record.name
    ? { ...item, count: { ...item.count, [size]: parseInt(value, 10) || 0 } }
    : item
  )),
}));
