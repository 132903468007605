import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { Input, Modal } from 'antd';
import { ModalHOC, UnitsDropdown } from '../../../common';
import { Column, Cost } from '../../../components';
import { notificationTypes, showMessage, showNetworkError } from '../../../redux/message/actions';
import { createComponent } from '../../../redux/menu/actions';
import { checkIsNameExist, getUnits } from '../../../utils/helpers';
import { isOnline } from '../../../persistance';

class CreateComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      componentName: '',
      units: 'g',
      cost: 0,
      supplyPrice: 0,
      supplyCount: 0,
    };
  }

  create = () => {
    if (!isOnline()) return showNetworkError();
    const { t, menu, actions: { createComponent, showMessage } } = this.props;
    const {
      componentName, units, cost, supplyPrice, supplyCount,
    } = this.state;
    if (componentName.trim() === '') {
      showMessage({ text: t('validation.productName'), type: notificationTypes.ERROR });
      return;
    }
    if (checkIsNameExist(componentName, menu.components)) {
      showMessage({ text: t('validation.uniqueComponentName'), type: notificationTypes.ERROR });
      return;
    }
    createComponent({
      name: componentName, units, cost, supplyPrice, supplyCount,
    });
    this.close();
  };

  close = () => {
    const { close } = this.props;
    close();
    this.setState({
      componentName: '', units: 'g', cost: 0, supplyPrice: 0, supplyCount: 0,
    });
  };

  render() {
    const { t, visible = false } = this.props;
    const { componentName, units } = this.state;
    return (
      <Modal
        maskClosable={false}
        centered
        visible={visible}
        onOk={this.create}
        onCancel={this.close}
        okText={t('control.create')}
        okButtonProps={{ size: 'large' }}
        cancelButtonProps={{ size: 'large' }}
        cancelText={t('control.cancel')}
        title={t('components.modalTitle')}
        width="584px"
      >
        <Column width="100%" className="default-card" margin="0 0 12px">
          <h3>{t('global.name')}</h3>
          <Input
            addonAfter={<UnitsDropdown value={units} callback={e => this.setState({ units: e })} />}
            placeholder={t('components.modalPlaceholder')}
            value={componentName}
            onChange={e => this.setState({ componentName: e.target.value })}
          />
        </Column>
        <Cost addon={getUnits(units)} callback={data => this.setState({ ...data })} />
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        createComponent,
        showMessage,
      },
      dispatch,
    ),
  };
}

export default ModalHOC(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CreateComponent)));

CreateComponent.propTypes = {
  close: PropTypes.func,
};
