import axios from '../index';
import { idbPayments, idbPlace, idbBusiness } from '../../config';
import {
  getPersistedBusiness, isOnline, persist, persistWithKey,
} from '../../persistance';

// Place flow

export const getPlaceDataRequest = id => new Promise(resolve => axios.get(`/db/place/${id}`)
  .then((res) => {
    persist(res.data, idbPlace);
    resolve(res);
  }));

export const createPlaceRequest = data => axios.post('/db/place', { data });

export const updatePlaceRequest = (id, data) => axios.put(`/db/place/${id}`, { data })
  .then((res) => {
    persist(res.data, idbPlace);
    return res;
  });

// Business flow

export const getBusinessDataRequest = id => new Promise((resolve) => {
  if (isOnline()) {
    return axios.get(`/db/business/${id}`)
      .then((res) => {
        persistWithKey('business', res.data, idbBusiness);
        resolve(res);
      });
  }
  return getPersistedBusiness().then(res => resolve({ data: res }));
});

export const updateBusinessRequest = (id, data) => axios
  .put(`/db/business/${id}`, { data });

export const createBusinessRequest = (uid, email) => axios.post('/db/business', { uid, email });

export const getPaymentsRequest = () => new Promise(resolve => axios.get('/app/payments')
  .then((res) => {
    res.data.map(el => persist(el, idbPayments));
    resolve(res);
  }));
