import React, { Component } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ModalMenu } from '../../../components';
import { setEmployer } from '../../../redux/keeper/actions';

class OpenModalMenuButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  dropEmployer = () => {
    const { history, actions: { setEmployer } } = this.props;
    setEmployer(null);
    history.replace('/app/dashboard');
  };

  render() {
    const { activeEmployer, t } = this.props;
    return (
      <>
        <Button
          style={{ color: 'white' }}
          onClick={() => this.modalMenu.show()}
          icon={<MenuOutlined />}
          className="employer-button"
          type="link"
        >
          {t('modalMenu.title')}
        </Button>
        <ModalMenu
          dropEmployer={this.dropEmployer}
          employer={activeEmployer}
          ref={(ref) => { this.modalMenu = ref; }}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    activeEmployer: state.keeper.activeEmployer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setEmployer,
      },
      dispatch,
    ),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OpenModalMenuButton)));
