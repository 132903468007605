import moment from 'moment';
import {
  GET_ADMIN_ACCESS, GET_BUSINESS_INFO, REMOVE_ADMIN_ACCESS, GET_PAYMENTS, GET_PLACE_INFO,
  SET_ACTIVE_PLACE, ADD_ADMIN_ACCESS,
} from './actions';
import { LITE, NOT_PAYED } from '../../constants/tariff';

export const initialState = {
  isAdmin: process.env.REACT_APP_ADMIN === 'true' || false,
  canInteract: false,
  dataLoaded: false,
  language: 'en',
  isFreeTrial: false,
  name: '',
  offset: '2;(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
  adminPassword: '',
  payedFor: moment().format('DD_MM_YYYY'),
  payments: [],
  completeFirstView: true,
  activePlace: null,
  places: [],
  shiftAutoClose: '00.00',
  currency: '₴',
  tariff: LITE,
  checkbox: {
    login: '',
    password: '',
    cashKey: '',
    token: '',
    useTaxesWebhook: false,
  },
};

function businessReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PAYMENTS:
      return {
        ...state,
        payments: action.payload,
      };
    case GET_BUSINESS_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case GET_PLACE_INFO:
      const canInteract = moment(action.payload.payedFor, 'DD_MM_YYYY').endOf('day').isAfter(moment());

      return {
        ...state,
        offset: '2;(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
        ...action.payload,
        dataLoaded: true,
        canInteract,
        tariff: canInteract ? LITE : NOT_PAYED,
      };
    case GET_ADMIN_ACCESS:
      return {
        ...state,
        isAdmin: action.payload,
      };
    case SET_ACTIVE_PLACE:
      return {
        ...state,
        activePlace: action.payload,
      };
    case REMOVE_ADMIN_ACCESS:
      return {
        ...state,
        isAdmin: false,
      };
    case ADD_ADMIN_ACCESS:
      return {
        ...state,
        isAdmin: true,
      };
    default:
      return state;
  }
}
export default businessReducer;
