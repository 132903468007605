import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './en';
import ru from './ru';
import ua from './ua';

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: {
      translations: en,
    },
    ru: {
      translations: ru,
    },
    ua: {
      translations: ua,
    },
  },
  fallbackLng: 'en',
  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
  },
  react: {
    wait: true,
  },
});

export default i18n;
