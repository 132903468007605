import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'antd/dist/antd.min.css';
import './normalize.css';
import './antd-overrides.scss';
import './view/global.scss';
import 'react-image-crop/dist/ReactCrop.css';

import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
// import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import ReactGA from 'react-ga';

import App from './view/App';
import * as serviceWorker from './serviceWorker';
import store from './store';
import { appHistory } from './config';
import i18n from './utils/translation';

Sentry.init({
  release: 'shoptoria@3.1.4',
  dsn: 'https://9064efdbc90e4fe7a1e7e41fa91befb8@o482467.ingest.sentry.io/5567010',
  autoSessionTracking: true,
  debug: true,
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const id = 'UA-153900598-1';

ReactGA.initialize(id);

// Initialize google analytics page view tracking
appHistory.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <Router history={appHistory}>
        <App />
      </Router>
    </Provider>
  </I18nextProvider>,
  document.getElementById('root'),
);

serviceWorker.register();
