import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Column, Row } from '../../../components';
import { prepareBillData } from '../../../redux/statistic/helpers';

class PopularItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.getPopularItems(this.props.bills),
    };
  }

  getAllItems = bills => bills.reduce((res, bill) => res.concat(bill.items), []);

  getPopularItems = (bills) => {
    // Data for items calculation
    const allItems = this.getAllItems(bills);
    return prepareBillData(allItems).filter(el => el.type !== 'addon' && !el.meta?.weightItem)
      .map(el => ({
        ...el,
        name: el.name,
        id: el.id,
        totalCount: el.sizes.reduce((sum, size) => el.quantity[size] + sum, 0),
      })).sort((a, b) => b.totalCount - a.totalCount);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { bills } = nextProps;
    if (bills.length !== this.props.bills.length) {
      this.setState({ items: this.getPopularItems(bills) });
    }
  }

  render() {
    const { t } = this.props;
    const { items } = this.state;
    const isFullSize = items.length > 4;
    if (items.length === 0) return <div />;
    return (
      <div className={`chart-wrapper popular ${isFullSize ? 'full' : ''}`}>
        <h3>{t('statistic.popularItem')}</h3>
        <Row className="mobile-popular-row" width="100%" justifyContent="space-between" alignItems="flex-start">
          <Column className="popular-column" width={isFullSize ? 'calc(50% - 10px)' : '100%'}>
            <Row width="100%" className="popular-header">
              <p>{t('layout.items')}</p>
              <p>{t('statistic.orders')}</p>
            </Row>
            {items.slice(0, 4).map(el => (
              <Row
                width="100%"
                key={el.name}
                alignItems="center"
                justifyContent="space-between"
                className="popular-row"
              >
                <Link to={{ pathname: '/app/menu', state: { [`${el.type}Id`]: el.id } }}>{el.name}</Link>
                <p>{`${el.totalCount} ${t('count')}`}</p>
              </Row>
            ))}
          </Column>
          {isFullSize && (
          <Column className="popular-column second" width="calc(50% - 10px)">
            <Row width="100%" className="popular-header">
              <p>{t('layout.items')}</p>
              <p>{t('statistic.orders')}</p>
            </Row>
            {items.slice(4, 8).map(el => (
              <Row
                width="100%"
                key={el.name}
                alignItems="center"
                justifyContent="space-between"
                className="popular-row"
              >
                <Link to={{ pathname: '/app/menu', state: { [`${el.type}Id`]: el.id } }}>{el.name}</Link>
                <p>{`${el.totalCount} ${t('count')}`}</p>
              </Row>
            ))}
          </Column>
          )}
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
  };
}

export default connect(mapStateToProps, null)(withTranslation()(PopularItem));
