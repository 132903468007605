import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input, Modal, Select } from 'antd';
import { ModalHOC, NumberInput } from '../../../common';
import { notificationTypes, showMessage } from '../../../redux/message/actions';
import { editEmployer } from '../../../redux/access/actions';
import { Column } from '../../../components';
import { validateNumberInput } from '../../../utils/helpers';

class EditEmployer extends Component {
  constructor(props) {
    super(props);
    const { employer, access: { jobs } } = props;
    this.state = {
      ...employer,
      jobName: jobs.find(job => job.id.toString() === employer.position.toString()).name || null,
    };
  }

  save = () => {
    const {
      employer, t, access: { jobs, employers }, close, actions: { editEmployer, showMessage },
    } = this.props;
    const {
      name, password, description = '', jobName,
    } = this.state;
    if (name.trim() === '') {
      showMessage({ text: t('validation.emptyName'), type: notificationTypes.ERROR });
      return;
    }
    if (password.length !== 4) {
      showMessage({ text: t('validation.password'), type: notificationTypes.ERROR });
      return;
    }
    if (employers.filter(el => el.id !== employer.id).find(el => el.password === password)) {
      showMessage({ text: t('validation.passwordInUse'), type: notificationTypes.ERROR });
      return;
    }
    if (!jobName) {
      showMessage({ text: t('validation.addJob'), type: notificationTypes.ERROR });
      return;
    }
    editEmployer({
      ...employer, name, password, description, position: jobs.find(el => el.name === jobName).id,
    });
    close();
  };

  render() {
    const {
      t, close, visible, access: { jobs },
    } = this.props;
    const {
      name, password, description, jobName,
    } = this.state;
    return (
      <Modal
        maskClosable={false}
        centered
        visible={visible}
        onOk={this.save}
        onCancel={close}
        width="450px"
        okText={t('control.save')}
        okButtonProps={{ size: 'large' }}
        cancelButtonProps={{ size: 'large' }}
        cancelText={t('control.cancel')}
        title={t('access.editEmployer')}
      >
        <Column
          style={{ maxWidth: '324px' }}
          width="100%"
          alignItems="flex-start"
          margin="0 auto"
          className="default-card"
        >
          <h3 style={{ margin: '0 0 5px' }}>{t('access.name')}</h3>
          <Input
            style={{ width: '100%' }}
            value={name}
            onChange={e => this.setState({ name: e.target.value })}
          />
          <h3 style={{ margin: '10px 0 5px' }}>{t('job.selectJob')}</h3>
          <Select style={{ width: '100%' }} value={jobName} onChange={name => this.setState({ jobName: name })}>
            {jobs.map(job => (
              <Select.Option key={job.name} value={job.name}>{job.name}</Select.Option>
            ))}
          </Select>
          <h3 style={{ margin: '10px 0 5px' }}>{t('global.description')}</h3>
          <Input
            style={{ width: '100%' }}
            placeholder=""
            onChange={e => this.setState({ description: e.target.value })}
            value={description}
          />
          <h3 style={{ margin: '10px 0 5px' }}>{t('job.terminalPassword')}</h3>
          <NumberInput
            type="tel"
            onKeyUp={validateNumberInput}
            style={{ width: '100%' }}
            placeholder=""
            controlled
            onChange={(e) => {
              const { value } = e.target;
              this.setState({ password: value.length > 4 ? value.slice(0, 4) : value });
            }}
            value={password}
          />
          <h4 style={{ margin: '5px 0 0' }}><span className="help-text">*</span>{t('access.passwordDescription')}</h4>
        </Column>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    access: state.access,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        editEmployer,
      },
      dispatch,
    ),
  };
}
export default ModalHOC(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditEmployer)));
