import React from 'react';
import { Table } from 'antd';
import { withTranslation } from 'react-i18next';
import { calculateSubtotal } from './Supply';

const supplyTableColumns = (t, currency) => [{
  title: t('global.name'),
  dataIndex: 'name',
  width: 'calc(100% - 400px)',
}, {
  title: t('global.quantity'),
  dataIndex: 'count',
  width: '200px',
}, {
  title: t('global.price'),
  dataIndex: 'price',
  width: '200px',
  render: text => `${text} ${currency}`,
}];

const SupplyTable = ({ items, t, currency }) => (
  <Table
    bordered
    pagination={false}
    size="default"
    className="supply-table storage-page-table"
    columns={supplyTableColumns(t, currency)}
    dataSource={items.map((el, index) => ({
      ...el, key: index, subtotal: calculateSubtotal(el).toFixed(2),
    }))}
  />
);
export default withTranslation()(SupplyTable);
