import React from 'react';
import { Row } from '../../../../components';
import './style.scss';
import './media.scss';

const Banner = ({ t, history }) => (
  <div className="banner-wrapper">
    <div className="banner-sale">
      <p>Quarantine</p>
      <h3>Sale</h3>
      <Row alignItems="flex-start" justifyContent="flex-start">
        <h3 className="white">100</h3>
        <p className="banner-sale-bottom text">% <br /> OFF</p>
      </Row>
    </div>
    <div className="banner-teaser-wrapper">
      <div className="banner-teaser">
        <h2>Shoptoria POS</h2>
        <h3>{t('sales.quarantine.banner.title')}</h3>
        <p>{t('sales.quarantine.banner.subtitle')}</p>
        <button onClick={() => history.push('/app/login')} type="button">{t('control.try')}</button>
      </div>
      <h4 className="banner-teaser-link">{t('sales.quarantine.banner.link')}</h4>
      <button
        className="mobile-visible"
        onClick={() => history.push('/app/login')}
        type="button"
      >{t('control.try')}
      </button>
    </div>
  </div>
);

export default Banner;
