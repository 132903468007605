import {
  getComponents, createComponent, editComponent, removeComponent,
} from './components';
import {
  getCategories, createCategory, removeCategory, editCategory,
} from './categories';
import {
  getItems, createItem, removeItem, editItem, toggleFavouritesItem,
} from './items';
import {
  getProducts, createProduct, removeProduct, editProduct, toggleFavouritesProduct,
} from './products';

import {
  getAddons, createAddon, editAddon, removeAddon,
} from './addons';

export {
  getProducts, createProduct, removeProduct, editProduct, toggleFavouritesProduct,
  getComponents, createComponent, removeComponent, editComponent,
  getItems, createItem, removeItem, editItem, toggleFavouritesItem,
  getCategories, createCategory, removeCategory, editCategory,
  getAddons, createAddon, editAddon, removeAddon,
};
