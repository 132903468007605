import React, { Component } from 'react';
import { Modal } from 'antd';
import ReactCrop from 'react-image-crop';
import { withTranslation } from 'react-i18next';
import ModalHOC from '../../common/ModalHOC';
import { Row, Column, Card } from '../index';

class CropModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blob: null,
      changed: false,
      crop: {
        x: 0,
        y: 0,
        unit: '%',
        aspect: 154 / 94,
        minWidth: 154,
        width: 154,
      },
    };
  }

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = crop => this.makeClientCrop(crop);

  onCropChange = (newCrop) => {
    const {
      crop: {
        x, y, width, height,
      },
    } = this.state;
    if (x !== newCrop.x || y !== newCrop.y || width !== newCrop.width || height !== newCrop.height) {
      this.setState({ crop: newCrop, changed: true });
    }
  };

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          // reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve({ url: this.fileUrl, blob });
      }, this.props.file.type);
    });
  }

  close = () => {
    const { close } = this.props;
    close();
  };

  apply = () => {
    const { callback, close } = this.props;
    const { blob } = this.state;
    callback(blob);
    close();
  };

  async makeClientCrop(crop) {
    const { file } = this.props;
    const { changed } = this.state;
    if (this.imageRef && crop.width && crop.height && changed) {
      const { url, blob } = await this.getCroppedImg(this.imageRef, crop, file.name);
      this.setState({ croppedImageUrl: url, blob, changed: false });
    }
  }

  render() {
    const { file, visible, t } = this.props;
    const { crop, croppedImageUrl, blob } = this.state;
    return (
      <Modal
        maskClosable={false}
        centered
        okButtonProps={{ disabled: blob == null }}
        onOk={this.apply}
        visible={visible}
        title={t('image.modalTitle')}
        onCancel={this.close}
        width="600px"
      >
        <Column width="100%" justifyContent="flex-start" alignItems="flex-start" className="crop-wrapper">
          <ReactCrop
            src={URL.createObjectURL(file)}
            crop={crop}
            className="crop"
            style={{ height: '100%', margin: '0 auto 20px' }}
            ruleOfThirds
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
          />
          <h2>{t('image.croppedImg')}</h2>
          <Row width="100%" alignItems="flex-start" justifyContent="space-between">
            {croppedImageUrl && (
              <img alt="Crop" style={{ maxWidth: 'calc(100% - 200px)' }} src={croppedImageUrl} />
            )}
            <Card withFavourites={false} item={{ photo: croppedImageUrl, name: t('image.cardName') }} />
          </Row>
        </Column>
      </Modal>
    );
  }
}

export default ModalHOC(withTranslation()(CropModal));
