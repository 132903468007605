import React, { Component } from 'react';
import Chart from 'react-chartjs-2';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

const timeKey = {
  0: 'mon',
  1: 'tue',
  2: 'wed',
  3: 'thu',
  4: 'fri',
  5: 'sat',
  6: 'sun',
};

class SalesPerDay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeSales: this.separateBillsByTime(this.props.bills),
    };
  }

  separateBillsByTime = (bills) => {
    const timeSales = {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
    };
    bills.forEach((bill) => {
      const day = moment(bill.id).format('e');
      timeSales[day] = parseFloat(bill.pay.total) + timeSales[day];
    });
    return timeSales;
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { bills } = nextProps;
    if (bills.length !== this.props.bills.length) {
      this.setState({ timeSales: this.separateBillsByTime(bills) });
    }
  }

  render() {
    const { t, currency } = this.props;
    const { timeSales } = this.state;
    return (
      <div id="time-chart" className="chart-wrapper chart-day">
        <h3>{t('time.sortByDay')}</h3>
        <div style={{ width: '100%', height: '170px' }} className="chart-content">
          <Chart
            type="bar"
            height={170}
            data={{
              labels: Object.keys(timeSales).map(key => t(`time.${timeKey[key]}`)),
              datasets: [{
                data: Object.keys(timeSales).map(day => timeSales[day].toFixed(2)),
                backgroundColor: 'rgba(0, 143, 251, 0.8)',
              }],
            }}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              scales: {
                xAxes: [{ gridLines: { display: false } }],
                yAxes: [{
                  lineHeight: 2,
                  gridLines: { tickMarkLength: 6 },
                  ticks: { min: 0, padding: 3, lineHeight: '2.2' },
                }],
              },
              title: { display: false },
              tooltips: {
                callbacks: {
                  title: tooltipItem => tooltipItem[0].xLabel,
                  label: tooltipItem => `${tooltipItem.yLabel.toFixed(2)} ${currency}`,
                },
              },
              legend: { display: false },
              animation: { duration: 0 },
              hover: { animationDuration: 0 },
              responsiveAnimationDuration: 0,
            }}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(SalesPerDay);
