import React, { Component } from 'react';
import { Button, Input, notification } from 'antd';
import axios from 'axios';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Column } from '../../components';
import Header from '../Landing/Header';
import Footer from '../Landing/Footer';
import './style.scss';

const { TextArea } = Input;

// eslint-disable-next-line max-len
const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const feedbackUsRoute = 'https://shoptoria-be.herokuapp.com/userFeedback';

const showErrorMessage = message => notification.error({ message });

class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedback: this.getCleanFeedback(),
      pending: false,
    };
  }

  getCleanFeedback = () => ({
    email: { value: '', isValid: true },
    text: { value: '', isValid: true },
    name: { value: '', isValid: true },
  });

  updateContactUs = (key, value, isValid = true) => this.setState(state => ({
    feedback: { ...state.feedback, [key]: { value, isValid } },
  }));

  validateEmail = email => this.updateContactUs('email', email, emailRegExp.test(email));

  validateText = text => this.updateContactUs('text', text, text.trim().length > 2);

  validateName = name => this.updateContactUs('name', name, name.length > 2);

  submitContactUs = () => {
    const { t, business } = this.props;
    const { feedback: { name, text, email } } = this.state;

    if (!name.isValid || name.value === '') {
      showErrorMessage(t('validation.emptyName'));
      return;
    }
    if (!email.isValid || email.value === '') {
      showErrorMessage(t('validation.email'));
      return;
    }
    if (!text.isValid || text.value.trim() === '') {
      showErrorMessage(t('validation.text'));
      return;
    }

    this.setState({ pending: true });

    axios.post(feedbackUsRoute, {
      id: business.id || '', name: name.value, text: text.value, email: email.value,
    })
      .then((res) => {
        if (res.status === 201) {
          notification.success({
            message: t('landing.successSubmit'),
          });
          this.setState({ feedback: this.getCleanFeedback() });
        } else {
          showErrorMessage(t('validation.something'));
        }
        this.setState({ pending: false });
      });
  };

  render() {
    const { t } = this.props;
    const { feedback, pending } = this.state;

    return (
      <div className="landing terms-wrapper" id="top">
        <Header />
        <div className="feedback-content">
          <h2 className="feedback-title">{t('feedback.title')}</h2>
          <Column
            margin="10px auto 30px"
            id="contact"
            width="100%"
            alignItems="flex-start"
            className="landing-contact-form"
          >
            <input
              onChange={e => this.updateContactUs('name', e.target.value)}
              type="text"
              className={`easy ${!feedback.name.isValid ? 'error' : ''}`}
              value={feedback.name.value}
              onBlur={() => this.validateName(feedback.name.value)}
              placeholder={t('landing.company')}
            />
            <input
              onChange={e => this.updateContactUs('email', e.target.value)}
              type="email"
              className={`easy ${!feedback.email.isValid ? 'error' : ''}`}
              value={feedback.email.value}
              onBlur={() => this.validateEmail(feedback.email.value)}
              placeholder="Email"
            />
            <TextArea
              onChange={e => this.updateContactUs('text', e.target.value)}
              rows={5}
              autoSize={{ minRows: 5, maxRows: 10 }}
              className={`feedback-textarea ${!feedback.text.isValid ? 'error' : ''}`}
              value={feedback.text.value}
              onBlur={() => this.validateText(feedback.text.value)}
              placeholder={t('textPlaceholder')}
            />

            <Button
              loading={pending}
              onClick={this.submitContactUs}
              size="large"
              type="primary"
            >{t('control.submit')}
            </Button>
          </Column>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    business: state.business,
  };
}


export default connect(mapStateToProps, null)(withTranslation()(Feedback));
