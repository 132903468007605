import React, { Component } from 'react';
import { Input } from 'antd';
import { connect } from 'react-redux';
import i18 from '../../utils/translation';
import { Row, Column } from '../index';
import { calculateCostFloat, validateNumberInput } from '../../utils/helpers';
import './style.scss';
import { FloatInput } from '../../common';

class Cost extends Component {
  constructor(props) {
    super(props);
    const { cost = 0, supplyCount = 0, supplyPrice = 0 } = props;
    this.state = {
      cost: cost === 0 && (supplyCount !== 0 && supplyPrice !== 0)
        ? calculateCostFloat({ price: supplyPrice, count: supplyCount }) : cost,
      supplyCount,
      supplyPrice,
    };
  }

  reset = () => {
    const { cost = 0, supplyCount = 0, supplyPrice = 0 } = this.props;
    this.setState({
      cost: cost === 0 && (supplyCount !== 0 && supplyPrice !== 0)
        ? calculateCostFloat({ price: supplyPrice, count: supplyCount }) : cost,
      supplyCount,
      supplyPrice,
    });
  };

  calculateCost = () => {
    const { callback } = this.props;
    this.setState(state => ({
      cost: calculateCostFloat({ price: state.supplyPrice, count: state.supplyCount }),
    }), () => callback(this.state));
  };

  updatePrice = price => this.setState({ supplyPrice: price || 0 }, () => this.calculateCost());

  updateCount = supplyCount => this.setState({ supplyCount: parseInt(supplyCount, 10) || 0 },
    () => this.calculateCost());

  calculateProfit = () => {
    const { price } = this.props;
    const { cost } = this.state;
    if (price === 0) return 0;
    if (cost === 0) return 0;
    const profit = parseFloat((parseInt(price, 10) - parseFloat(cost)) / parseFloat(cost) * 100).toFixed(2);
    return isFinite(profit) ? profit : 0;
  };

  calculateProfitWeightItem = () => {
    const { saleCost } = this.props;
    const { cost } = this.state;
    if (saleCost === 0) return 0;
    if (cost === 0) return 0;
    const profit = (parseFloat(saleCost) - parseFloat(cost)) / parseFloat(cost) * 100;
    return isFinite(profit) ? profit.toFixed(2) : 0;
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.inEdit !== this.props.inEdit) {
      const { cost, supplyCount, supplyPrice } = nextProps;
      this.setState(state => ({ cost: cost || state.cost, supplyCount, supplyPrice }));
    }
  }

  render() {
    const {
      addon = 'count', inEdit = true, price = 0, business, weightItem = false,
    } = this.props;
    const { cost, supplyPrice, supplyCount } = this.state;
    return (
      <Column className="default-card cost" width="100%" justifyContent="flex-start" alignItems="flex-start">
        <h3 style={{ margin: '0 0 5px' }}>{i18.t('cost.title')}
          <span style={{ fontSize: '14px', color: 'rgba(0,0,0,0.55)' }}>
            &nbsp;({i18.t('optional.title')}, {i18.t('optional.addSoon')})
          </span>
        </h3>
        <Row
          className="mobile-cost-column"
          width="100%"
          justifyContent="space-between"
          alignItems="flex-end"
          margin="5px 0 0"
        >
          <Column className="cost-column" alignItems="flex-start" margin="0 5px 0 0">
            <h4 style={{ margin: '0 20px 9px 0' }}>{i18.t('cost.price')}</h4>
            <FloatInput
              className="cost-input"
              style={{ width: '150px' }}
              placeholder="0"
              type="tel"
              value={supplyPrice}
              onChange={(value) => {
                // eslint-disable-next-line eqeqeq
                if (value[0] == 0 && value[1] !== '.') {
                  this.updatePrice(value.slice(1, value.length));
                } else {
                  this.updatePrice(value);
                }
              }}
              addonAfter={business.currency}
              readOnly={!inEdit}
            />
          </Column>
          <span className="cost-helper">/</span>
          <Column className="cost-column" alignItems="flex-start" margin="0 5px">
            <h4 style={{ margin: '0 20px 9px 0' }}>{i18.t('cost.count')}</h4>
            <Input
              className="cost-input"
              onKeyUp={validateNumberInput}
              style={{ width: '150px' }}
              placeholder="0"
              type="tel"
              value={supplyCount}
              onChange={e => this.updateCount(e.target.value)}
              addonAfter={i18.t(addon)}
              readOnly={!inEdit}
            />
          </Column>
          <span className="cost-helper">=</span>
          <Column className="cost-column" alignItems="flex-start" margin="0 0 0 5px">
            <h4 style={{ margin: '0 20px 9px 0' }}>{i18.t('cost.one')}</h4>
            <Input
              className="cost-input"
              style={{ width: '150px' }}
              readOnly
              value={cost}
              addonAfter={business.currency}
            />
          </Column>
        </Row>
        <h3 style={{ margin: '10px 0 0', minHeight: '25px' }}>
          {(price !== 0 && cost !== 0)
            ? weightItem
              ? `${i18.t('global.weightProfit')}${i18.t(addon)} : ${this.calculateProfitWeightItem()}%`
              : `${i18.t('global.profit')}: ${this.calculateProfit()}%`
            : ' '}
        </h3>
      </Column>
    );
  }
}

function mapStateToProps(state) {
  return {
    business: state.business,
  };
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(Cost);
