import React, { Component } from 'react';
import { Button, Table, Tag } from 'antd';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { firebase } from '../../../config';
import {
  Content, Page, PageHeader, TopBar,
} from '../../../components';
import { RangePicker } from '../../../common';
import { MONTH } from '../../../common/RangePicker';
import { calculateTotal, getItemsCost, printPage } from '../../../utils/helpers';
import { getBillsFromDayRange } from '../../../redux/statistic/helpers';
import { isOnline } from '../../../persistance';
import { showNetworkError } from '../../../redux/message/actions';

class SalePerEmployer extends Component {
  constructor(props) {
    super(props);
    const { resolvedBills = [] } = this.props;
    this.state = {
      bills: resolvedBills,
      pending: false,
      rangeStart: moment().subtract(1, 'week'),
      rangeEnd: moment(),
    };
  }

  componentDidMount() {
    const { activePlace } = this.props;

    if (activePlace) {
      this.updateRange(this.state);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { activePlace, dataLoaded } = nextProps;
    if ((this.props.activePlace !== activePlace && dataLoaded) || this.props.dataLoaded !== dataLoaded) {
      this.updateRange({ ...this.state });
    }
  }

  updateRange = ({ rangeStart, rangeEnd }) => {
    if (!isOnline()) return showNetworkError();
    this.setState({ rangeStart, rangeEnd, pending: true });
    getBillsFromDayRange(rangeStart, rangeEnd)
      .then((bills = []) => this.setState({ bills, pending: false }));
  };

  getStatisticFromBill = (bills) => {
    const cost = this.getDayCost(bills);
    const total = bills.reduce((acc, bill) => acc + parseFloat(calculateTotal(bill)), 0);
    return ({
      count: bills.length,
      middleBill: total === 0 ? 0 : (total / bills.length).toFixed(2),
      total: total.toFixed(2),
      gross: (total - cost).toFixed(2),
    });
  }

  getDayCost = (bills) => {
    const { menu, equipment } = this.props;
    const items = bills.map(bill => bill.items).flat().map(el => ({ ...el, ...menu.idMap[el.id] }));
    return equipment.loading ? 0 : getItemsCost(items, menu, equipment);
  };

  render() {
    const { t, currency, access: { employers, jobs } } = this.props;
    const { bills, pending } = this.state;
    const billsColumns = [
      {
        title: t('global.name'),
        dataIndex: 'name',
        sorter: (a, b) => (-1) * a.name.localeCompare(b.name),
        sortDirections: ['descend'],
      },
      {
        title: t('job.jobName'),
        dataIndex: 'position',
        width: 170,
        render: id => <Tag>{(jobs.find(job => job.id.toString() === id.toString()) || {}).name}</Tag>,
      },
      {
        title: t('global.revenue'),
        dataIndex: 'total',
        width: 120,
        render: total => `${total} ${currency}`,
      }, {
        title: t('global.gross'),
        dataIndex: 'gross',
        width: 150,
        render: gross => `${gross} ${currency}`,
      }, {
        title: t('layout.bills'),
        dataIndex: 'count',
        width: 120,
        render: bills => `${bills} ${t('count')}`,
      }, {
        title: t('statistic.middleBill'),
        dataIndex: 'middleBill',
        width: 120,
        render: middleBill => `${middleBill} ${currency}`,
      },
    ];
    const uniqBills = Array.from(new Set(bills.map(el => el.id))).map(id => bills.find(el => el.id === id));

    return (
      <Page spinning={!firebase.auth().currentUser || pending}>
        <PageHeader />
        <Content>
          <TopBar data-tour="component-tour-2">
            <h3 className="storage-page-title">
              {t('layout.employers')}: {employers.length}
            </h3>
            <Button
              className="no-print mobile-bottom-margin mobile-right-margin"
              size="large"
              onClick={() => printPage()}
              type="secondary"
            >{t('control.print')}
            </Button>
            <RangePicker
              shouldCheckPermission
              callback={this.updateRange}
              ranges={[MONTH]}
              rangeStart={moment().subtract(1, 'week')}
            />
          </TopBar>
          <Table
            bordered
            pagination={false}
            size="default"
            className="storage-page-table"
            columns={billsColumns}
            dataSource={employers.map(el => ({
              ...el,
              key: el.id,
              ...this.getStatisticFromBill(uniqBills.filter(bill => bill.employer.id === el.id)),
            }))}
          />
        </Content>
      </Page>
    );
  }
}

export default withTranslation()(SalePerEmployer);
