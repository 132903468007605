import React from 'react';
import { Table } from 'antd';
import { withTranslation } from 'react-i18next';
import { calculateSubtotal } from './Inventory';

const inventoryTableColumns = t => [{
  title: t('global.name'),
  dataIndex: 'name',
  className: 'no-break',
}, {
  title: t('inventory.before'),
  dataIndex: 'countBefore',
  width: '200px',
  render: (text, record) => `${text} ${record.units}`,
}, {
  title: t('inventory.now'),
  dataIndex: 'count',
  width: '200px',
  render: (text, record) => `${text} ${record.units}`,
}, {
  title: t('inventory.diff'),
  dataIndex: 'diff',
  width: '200px',
  render: (delta, record) => (delta === 0 ? <span className="color-gray">0 {record.units}</span> : delta < 0
    ? <span className="color-green">+ {(-1) * delta} {record.units}</span>
    : <span className="color-red">{(-1) * delta} {record.units}</span>),
}];

const InventoryTable = ({ items, t }) => (
  <Table
    bordered
    pagination={false}
    size="default"
    className="supply-table storage-page-table"
    columns={inventoryTableColumns(t)}
    dataSource={items.map((el, index) => ({
      ...el, key: index, subtotal: calculateSubtotal(el).toFixed(2),
    }))}
  />
);
export default withTranslation()(InventoryTable);
