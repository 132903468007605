import axios from '../index';
import {
  idbStoreInventory, idbStoreStorage, idbStoreSuppliers, idbStoreSupply, idbStoreWaste,
} from '../../config';
import { persist } from '../../persistance';
import { showErrorMessage } from '../../redux/message/actions';

// Storage flow

export const getStorageRequest = () => new Promise(resolve => axios.get('/db/storage/store')
  .then((res) => {
    res.data.map(el => persist(el, idbStoreStorage));
    resolve(res);
  }));

export const setStoreItemRequest = (id, data) => axios.post('/db/storage/store', { id, data })
  .catch(showErrorMessage);


export const removeStoreItemRequest = id => axios.delete(`/db/storage/store/${id}`);

// Suppliers flow

export const getSuppliersRequest = () => new Promise(resolve => axios.get('/db/storage/supplier')
  .then((res) => {
    res.data.map(el => persist(el, idbStoreSuppliers));
    resolve(res);
  }));

export const addSupplierRequest = (id, data) => axios.post('/db/storage/supplier', { id, data });

export const editSupplierRequest = (id, data) => axios.put('/db/storage/supplier', { id, data });

export const removeSupplierRequest = id => axios.delete(`/db/storage/supplier/${id}`);

// Supply flow

export const getSupplyRequest = () => new Promise(resolve => axios.get('/db/storage/supply')
  .then((res) => {
    res.data.map(el => persist(el, idbStoreSupply));
    resolve(res);
  }));

export const addSupplyRequest = (id, data) => axios.post('/db/storage/supply', { id, data });

export const editSupplyRequest = (id, data) => axios.put('/db/storage/supply', { id, data });

export const removeSupplyRequest = id => axios.delete(`/db/storage/supply/${id}`);


// Waste flow

export const getWasteRequest = () => new Promise(resolve => axios.get('/db/storage/waste')
  .then((res) => {
    res.data.map(el => persist(el, idbStoreWaste));
    resolve(res);
  }));

export const addWasteRequest = (id, data) => axios.post('/db/storage/waste', { id, data });

export const editWasteRequest = (id, data) => axios.put('/db/storage/waste', { id, data });

export const removeWasteRequest = id => axios.delete(`/db/storage/waste/${id}`);

// Inventory flow

export const getInventoryRequest = () => new Promise(resolve => axios.get('/db/storage/inventory')
  .then((res) => {
    res.data.map(el => persist(el, idbStoreInventory));
    resolve(res);
  }));

export const addInventoryRequest = (id, data) => axios.post('/db/storage/inventory', { id, data });

export const editInventoryRequest = (id, data) => axios.put('/db/storage/inventory', { id, data });

export const removeInventoryRequest = id => axios.delete(`/db/storage/inventory/${id}`);
