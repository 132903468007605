import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { showMessage } from '../../../redux/message/actions';
import LoyaltyProgram from './LoyaltyProgram';
import { editLoyalty } from '../../../redux/marketing/actions';

function mapStateToProps(state) {
  return {
    dataLoaded: state.business.dataLoaded,
    currency: state.business.currency,
    groups: state.marketing.groups,
    groupsLoaded: state.marketing.groupsLoaded,
    loyalty: state.marketing.loyalty,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        editLoyalty,
      },
      dispatch,
    ),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoyaltyProgram));
