import moment from 'moment';
import {
  SET_USER_DATA, CHECK_VERSION, GET_MIGRATION, GET_UPDATES,
} from './actions';

const initialState = {
  email: null,
  token: null,
  version: '3.1.5',
  newVersion: '3.1.4',
  isActualVersion: true,
  releaseDate: moment().subtract(1, 'day').format('DD_MM_YYYY'),
  migration: {},
  updates: {},
  dataLoaded: false,
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case CHECK_VERSION: {
      return {
        ...state,
        isActualVersion: state.version === action.payload.version,
        releaseDate: action.payload.releaseDate,
        newVersion: action.payload.version,
      };
    }
    case GET_MIGRATION: {
      return {
        ...state,
        migration: action.payload,
      };
    }
    case GET_UPDATES: {
      return {
        ...state,
        updates: action.payload,
        dataLoaded: true,
      };
    }
    case 'CLEAN_REDUCERS': {
      return initialState;
    }
    default:
      return state;
  }
}

export default userReducer;
