import axios from '../index';
import {
  idbMenuAddons, idbMenuCategories, idbMenuComponents, idbMenuItems, idbMenuProducts,
} from '../../config';
import { persist } from '../../persistance';
import { showErrorMessage } from '../../redux/message/actions';

// Addons flow

export const getAddonsDataRequest = () => new Promise(resolve => axios.get('/db/menu/addons')
  .then((res) => {
    res.data.map(el => persist(el, idbMenuAddons));
    resolve(res);
  }));

export const editAddonRequest = (id, data) => axios.put('/db/menu/addons', { id, data });

export const addAddonRequest = (id, data) => axios.post('/db/menu/addons', { id, data });

export const removeAddonRequest = id => axios.delete(`/db/menu/addons/${id}`);

// Categories flow

export const getCategoriesDataRequest = () => new Promise(resolve => axios.get('/db/menu/category')
  .then((res) => {
    res.data.map(el => persist(el, idbMenuCategories));
    resolve(res);
  }));

export const editCategoryRequest = (id, data) => axios.put('/db/menu/category', { id, data });

export const addCategoryRequest = (id, data) => axios.post('/db/menu/category', { id, data });

export const removeCategoryRequest = id => axios.delete(`/db/menu/category/${id}`);

// Components flow

export const getComponentsDataRequest = () => new Promise(resolve => axios.get('/db/menu/component')
  .then((res) => {
    res.data.map(el => persist(el, idbMenuComponents));
    resolve(res);
  }));

export const editComponentRequest = (id, data) => axios.put('/db/menu/component', { id, data })
  .catch(showErrorMessage);

export const addComponentRequest = (id, data) => axios.post('/db/menu/component', { id, data });

export const removeComponentRequest = id => axios.delete(`/db/menu/component/${id}`);

// Items flow

export const getItemsDataRequest = () => new Promise(resolve => axios.get('/db/menu/item')
  .then((res) => {
    res.data.map(el => persist(el, idbMenuItems));
    resolve(res);
  }));

export const editItemRequest = (id, data) => axios.put('/db/menu/item', { id, data })
  .catch(showErrorMessage);

export const addItemRequest = (id, data) => axios.post('/db/menu/item', { id, data });

export const removeItemRequest = id => axios.delete(`/db/menu/item/${id}`);

// Products flow

export const getProductsDataRequest = () => new Promise(resolve => axios.get('/db/menu/product')
  .then((res) => {
    res.data.map(el => persist(el, idbMenuProducts));
    resolve(res);
  }));

export const getProductsExampleDataRequest = () => new Promise(resolve => axios.get('/db/menu/product-example')
  .then((res) => {
    res.data.map(el => persist(el, idbMenuProducts));
    resolve(res);
  }));

export const editProductRequest = (id, data) => axios.put('/db/menu/product', { id, data })
  .catch(showErrorMessage);

export const addProductRequest = (id, data) => axios.post('/db/menu/product', { id, data });

export const removeProductRequest = id => axios.delete(`/db/menu/product/${id}`);
