import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Input, Modal } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Column, FileUpload, Row, Cost,
} from '../../../components';
import { ModalHOC } from '../../../common';
import { getDefaultImageByType } from '../../../utils/helpers';
import { editConsumable, removeConsumable } from '../../../redux/equipment/actions';
import { notificationTypes, showMessage } from '../../../redux/message/actions';
import './style.scss';

class ConsumableModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inEdit: false,
      photo: null,
      loading: false,
      ...props.consumable,
    };
  }

  submit = () => {
    const { t, consumable, actions: { editConsumable, showMessage } } = this.props;
    const {
      name, photo, cost, supplyPrice, supplyCount,
    } = this.state;
    if (name.trim() === '') {
      showMessage({ text: t('validation.emptyName'), type: notificationTypes.ERROR });
      return;
    }
    this.setState({ loading: true });
    editConsumable({
      ...consumable, photo, name, cost, supplyPrice, supplyCount,
    }).then(() => this.setState({ loading: false, inEdit: false }));
  };

  reset = () => this.setState({ ...this.props.consumable, inEdit: false });

  render() {
    const {
      t, visible, consumable, close,
    } = this.props;
    if (consumable == null) return <div />;
    const {
      inEdit, photo, loading, name,
    } = this.state;

    return (
      <Modal
        maskClosable={false}
        visible={visible}
        onOk={this.submit}
        onCancel={close}
        footer={null}
        className="consumable-modal"
        title={t('global.consumable')}
        width="600px"
      >
        {inEdit ? (
          <Row
            className="default-card mobile-consumable-column"
            margin="0 0 12px"
            justifyContent="flex-start"
            flexWrap="wrap"
            alignItems="flex-start"
          >
            <Column>
              <h3>{t('global.image')}</h3>
              <FileUpload imageUrl={photo} callback={photo => this.setState({ photo })} />
            </Column>
            <Column margin="0 10px" className="mobile-consumable-content">
              <h3>{t('global.name')}</h3>
              <Input
                style={{ width: '185px' }}
                className="create-product-input mobile-full-width"
                placeholder={t('item.modalNamePlaceholder')}
                onChange={e => this.setState({ name: e.target.value })}
                value={name}
              />
            </Column>
          </Row>
        ) : (
          <Row
            className="default-card"
            margin="0 0 12px"
            justifyContent="flex-start"
            flexWrap="wrap"
            alignItems="flex-start"
          >
            <Column>
              <h3>{t('global.image')}</h3>
              <img
                src={photo instanceof Blob ? URL.createObjectURL(photo) : photo || getDefaultImageByType('consumable')}
                alt="avatar"
                style={{ borderRadius: '4px', width: '154px', height: '94px' }}
              />
            </Column>
            <Column margin="0 10px 0 18px">
              <h3>{t('global.name')}</h3>
              <h4 className="product-modal-subtitle">{name}</h4>
            </Column>
          </Row>
        )}
        <Cost {...this.state} callback={data => this.setState({ ...data })} />
        <Row width="100%" justifyContent="flex-end" margin="12px 0 0">
          {inEdit ? (
            <Fragment>
              <Button
                size="large"
                style={{ marginRight: '10px' }}
                onClick={this.reset}
                type="default"
              >{t('control.cancel')}
              </Button>
              <Button size="large" loading={loading} type="primary" onClick={this.submit}>{t('control.save')}</Button>
            </Fragment>
          ) : (
            <Button size="large" onClick={() => this.setState({ inEdit: true })}>{t('control.edit')}</Button>
          )}
        </Row>
      </Modal>
    );
  }
}
function mapStateToProps(state) {
  return {
    consumables: state.equipment.consumables,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        removeConsumable,
        editConsumable,
        showMessage,
      },
      dispatch,
    ),
  };
}

export default ModalHOC(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConsumableModal)));
