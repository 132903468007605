import React, { Component } from 'react';
import { Modal, Transfer, Typography } from 'antd';
import { withTranslation } from 'react-i18next';
import { Row, Column } from '../index';
import { getSizeName } from '../../utils/helpers';
import './style.scss';

const { Text } = Typography;

class SeparateBill extends Component {
  constructor(props) {
    super(props);
    const { activeBills, activeKey } = props;
    const bill = activeBills.find(el => el.id === activeKey);
    this.state = {
      items: [],
      selectedKeys: [],
      targetKeys: [],
      ...bill,
    };
  }

  handleChange = targetKeys => this.setState({ targetKeys });

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => this.setState({
    selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
  });

  close = () => {
    const { close } = this.props;
    close();
    this.cleanState();
  };

  cleanState = () => this.setState({ selectedKeys: [], targetKeys: [] });

  renderItem = item => ({
    value: item.title,
    label: (
      <Row width="250px" style={{ display: 'inline-flex' }}>
        <Text>{item.title}</Text>
        <Text>{item.description}</Text>
      </Row>
    ),
  });

  separateBill = () => {
    const { actions: { separateBill } } = this.props;
    const { targetKeys, items, sale } = this.state;
    const sortedItems = items.reduce((res, el) => [...res, ...Array(el.quantity).fill({ ...el, quantity: 1 })], [])
      .filter(el => !el.count);
    const oldBill = sortedItems.filter((el, index) => !targetKeys.includes(index));
    const newBill = sortedItems.filter((el, index) => targetKeys.includes(index));
    separateBill({ oldBill, newBill, sale });
    this.close();
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { activeBills, activeKey } = nextProps;
    if (activeKey === null) {
      this.setState({ targetKeys: [] });
      return;
    }
    if (activeKey !== this.props.activeKey) {
      const bill = activeBills.find(el => el.id === activeKey);
      this.setState({ ...bill, targetKeys: bill.items });
      return;
    }
    if (activeKey) {
      const oldBill = this.props.activeBills.find(el => el.id === activeKey);
      const newBill = activeBills.find(el => el.id === activeKey);
      if (oldBill && newBill && oldBill.items.length !== newBill.items.length) {
        this.setState({ items: newBill.items });
      }
    }
  }

  render() {
    const {
      t, activeKey, visible = false, business,
    } = this.props;
    if (!activeKey) return <div />;
    const { items, selectedKeys, targetKeys } = this.state;
    return (
      <Modal
        maskClosable={false}
        centered
        visible={visible}
        width="700px"
        onOk={this.separateBill}
        okButtonProps={{ size: 'large' }}
        cancelButtonProps={{ size: 'large' }}
        onCancel={this.close}
        okText={t('control.separate')}
        cancelText={t('control.cancel')}
        title={t('bill.modalTitleSeparate')}
        className="separate-bill"
      >
        <Column width="100%" className="default-card">
          <Transfer
            showSelectAll={false}
            listStyle={{ width: 'calc(50% - 25px)' }}
            style={{ width: '100%' }}
            dataSource={items.reduce((res, el) => [...res, ...Array(el.quantity).fill({ ...el, quantity: 1 })], [])
              .filter(el => !el.count).map((el, index) => ({
                title: el.name,
                key: index,
                description: `${getSizeName(el.size)} - ${el.price}${business.currency}`,
              }))}
            titles={[t('bill.nodalActiveBill'), t('bill.modalNewBill')]}
            targetKeys={targetKeys}
            selectedKeys={selectedKeys}
            locale={{ itemUnit: `${t('count')}.`, itemsUnit: `${t('count')}.` }}
            onChange={this.handleChange}
            onSelectChange={this.handleSelectChange}
            render={this.renderItem}
          />
        </Column>
      </Modal>
    );
  }
}

export default withTranslation()(SeparateBill);
