import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { DeleteOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { getDefaultImageByType, getEmptyMaterial, getSizeName } from '../utils/helpers';
import {
  Row, AddEquipment, EditEquipment, Column,
} from '../components';

class EquipmentTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
    };
  }

  selectItem = (record) => {
    const { equipment: { items } } = this.props;
    const selectedItem = items.find(el => el.name === record.name);
    this.setState({ selectedItem });
    this.editEquipmentModal.show();
  };

  addEquipment = (item) => {
    const { create } = this.props;
    create(item);
  };

  editEquipment = (item) => {
    const { edit } = this.props;
    edit(item);
  };

  removeConsumable = (record) => {
    const { equipment: { items }, remove } = this.props;
    const item = items.find(el => el.name === record.name);
    remove(item.id);
  };

  render() {
    const {
      t, inEdit, equipment: { items, idMap }, sizes, showEmpty = false,
    } = this.props;
    const { selectedItem } = this.state;
    const itemsColumns = [
      {
        title: t('global.name'), dataIndex: 'name', width: 120, className: 'no-break',
      },
      ...sizes.map(size => ({
        title: getSizeName(size),
        className: 'table-header-center',
        dataIndex: size,
        render: (text, record) => {
          const { sizes } = record;
          const material = idMap[sizes[size]] || getEmptyMaterial(t);
          return (
            <Column width="100%" justifyContent="flex-start" alignItems="center">
              <img
                style={{ margin: '0 0 5px' }}
                className="default-img default-img-small"
                src={material.photo || getDefaultImageByType('consumable')}
                alt={material.name}
              />
              <Link to={{ pathname: '/app/equipment/consumables', state: { equipmentId: material.id } }}>
                {material.name}
              </Link>
            </Column>
          );
        },
      })),
    ];
    if (inEdit) {
      itemsColumns.push({
        title: '',
        width: '30px',
        render: (text, record) => (
          <Row width="100%" justifyContent="center">
            <Button onClick={() => this.selectItem(record)} type="link" icon={<EyeOutlined />} />
          </Row>
        ),
      });
      itemsColumns.push({
        title: '',
        width: '30px',
        render: (text, record) => (
          <Row width="100%" justifyContent="center">
            <Button onClick={() => this.removeConsumable(record)} type="link" icon={<DeleteOutlined />} />
          </Row>
        ),
      });
    }
    return (
      <Fragment>
        {(items.length > 0 || showEmpty) && (
          <Table
            bordered
            pagination={false}
            size="default"
            style={{ width: '100%', overflowX: 'scroll' }}
            columns={itemsColumns}
            dataSource={items.map((el, index) => ({ ...el, key: index }))}
            footer={inEdit ? () => (
              <Button icon={<PlusOutlined />} onClick={() => this.addEquipmentModal.show()}>
                {t('equipment.add')}
              </Button>
            ) : undefined}
          />
        )}
        <AddEquipment
          existedItems={items}
          callback={this.addEquipment}
          ref={(ref) => { this.addEquipmentModal = ref; }}
        />
        <EditEquipment
          existedItems={items}
          item={selectedItem}
          callback={this.editEquipment}
          ref={(ref) => { this.editEquipmentModal = ref; }}
        />
      </Fragment>
    );
  }
}


export default withTranslation()(EquipmentTable);
