import React, { Component, Fragment } from 'react';
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  Button, Input, Modal, Select,
} from 'antd';
import { withTranslation } from 'react-i18next';
import {
  Column, FileUpload, Row, Cost, SaleCost,
} from '../../components';
import { FloatInput } from '../../common';
import { notificationTypes, showNetworkError } from '../../redux/message/actions';
import { getDefaultImageByType, getUnits } from '../../utils/helpers';
import { deleteModal } from '../../utils/modals';
import './style.scss';
import { isOnline } from '../../persistance';

const { Option } = Select;

class ItemModal extends Component {
  constructor(props) {
    super(props);
    const {
      item = {
        name: '',
        price: 0,
        category: 'All',
        supplyPrice: 0,
        photo: null,
      },
    } = props;
    this.state = {
      ...item,
      inEdit: false,
      loading: false,
    };
  }

  edit = () => {
    const { t, item, actions: { editItem, showMessage } } = this.props;
    const {
      name, price, category, photo, supplyPrice, cost, supplyCount, weightItem = false,
      saleCount = '', salePrice = '', saleCost = 0, units = 'pc',
    } = this.state;
    if (name.trim() === '') {
      showMessage({ text: t('validation.itemName'), type: notificationTypes.ERROR });
      return;
    }
    if ((price === 0 || price === '') && !weightItem) {
      showMessage({ text: t('validation.itemPrice'), type: notificationTypes.ERROR });
      return;
    }
    if (weightItem && (salePrice === 0 || salePrice === '')) {
      showMessage({ text: t('validation.itemPrice'), type: notificationTypes.ERROR });
      return;
    }
    if (weightItem && (saleCount === 0 || saleCount === '')) {
      showMessage({ text: t('validation.addCount'), type: notificationTypes.ERROR });
      return;
    }
    this.setState({ loading: true });
    const newCategory = category === t('global.all') ? null : category;
    editItem({
      ...item,
      name,
      price,
      category: newCategory,
      photo,
      supplyPrice,
      cost,
      supplyCount,
      weightItem,
      saleCount,
      salePrice,
      saleCost,
      units,
    }).then(data => this.setState({
      ...item,
      name,
      price,
      category: newCategory,
      photo,
      supplyPrice,
      cost,
      supplyCount,
      weightItem,
      saleCount,
      salePrice,
      saleCost,
      units,
      ...data,
      inEdit: false,
      loading: false,
    }));
  };

  cleanState = () => {
    const { item } = this.props;
    this.setState({ ...item, inEdit: false }, () => this.cost.reset());
  };

  delete = () => {
    const { item, close, actions: { removeItem } } = this.props;
    removeItem(item);
    close();
  };

  updatePrice = price => this.setState({ price });

  UNSAFE_componentWillReceiveProps(nextProps) {
    if ((!this.props.item || nextProps.item.name !== this.props.item.name) && nextProps.item) {
      this.setState({ ...nextProps.item });
    }
  }

  render() {
    const {
      t, visible, item, close, menu, business, makeCopy,
    } = this.props;
    const {
      name, price, photo, inEdit, loading, category, supplyCount, supplyPrice, weightItem,
      saleCost, salePrice, saleCount, units,
    } = this.state;
    if (item == null) return <div />;
    return (
      <Modal
        maskClosable={false}
        centered
        visible={visible}
        onCancel={close}
        footer={(
          <Row width="100%" justifyContent="flex-end">
            {inEdit ? (
              <Fragment>
                <Button
                  style={{ marginRight: '10px' }}
                  onClick={this.cleanState}
                  type="default"
                  size="large"
                >{t('control.cancel')}
                </Button>
                <Button size="large" loading={loading} type="primary" onClick={this.edit}>{t('control.save')}</Button>
              </Fragment>
            ) : (
              <Fragment>
                <Button
                  size="large"
                  style={{ marginRight: '10px' }}
                  onClick={() => deleteModal(this.delete)}
                  icon={<DeleteOutlined />}
                />
                <Button
                  size="large"
                  style={{ marginRight: 'auto' }}
                  onClick={() => {
                    if (!isOnline()) return showNetworkError();
                    makeCopy();
                  }}
                  icon={<CopyOutlined />}
                />
                <Button
                  size="large"
                  onClick={() => {
                    if (!isOnline()) return showNetworkError();
                    this.setState({ inEdit: true });
                  }}
                >{t('control.edit')}
                </Button>
              </Fragment>
            )}
          </Row>
        )}
        width="624px"
        title={t('global.item')}
        className="item-modal modal"
      >
        <Column style={{ position: 'relative' }}>
          {inEdit ? (
            <Row
              width="100%"
              className="default-card mobile-item-column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Column>
                <h3>{t('global.image')}</h3>
                <FileUpload imageUrl={photo} callback={photo => this.setState({ photo })} />
              </Column>
              <Column alignItems="flex-start" margin="0 16px 0 18px" className="item-column">
                <h3>{t('global.name')}</h3>
                <Input
                  className="create-product-input item-input"
                  placeholder={t('item.modalNamePlaceholder')}
                  onChange={e => this.setState({ name: e.target.value })}
                  value={name}
                />
                {!weightItem && (
                  <Fragment>
                    <h3 style={{ marginTop: '13px' }}>{t('global.price')}</h3>
                    <FloatInput
                      className="item-input"
                      placeholder="0"
                      type="tel"
                      controlled
                      value={price}
                      disabled={weightItem}
                      onChange={(value) => {
                        // eslint-disable-next-line eqeqeq
                        if (value[0] == 0 && value[1] !== '.') {
                          this.updatePrice(value.slice(1, value.length));
                        } else {
                          this.updatePrice(value);
                        }
                      }}
                      addonAfter={business.currency}
                    />
                  </Fragment>
                )}
              </Column>
              <Column alignItems="flex-start" width="200px" className="mobile-full-width">
                <h3>{t('global.category')}</h3>
                <Select
                  defaultValue={category || t('global.all')}
                  placeholder={t('product.modalChooseCategory')}
                  style={{ width: '100%' }}
                  className="item-input"
                  onChange={el => this.setState({ category: el })}
                >
                  {menu.categories !== null && [{ name: t('global.all') }, ...menu.categories].map(el => (
                    <Option key={el.name} value={el.name}>{el.name}</Option>
                  ))}
                </Select>
              </Column>
            </Row>
          ) : (
            <Row
              width="100%"
              justifyContent="flex-start"
              alignItems="flex-start"
              className="default-card mobile-item-column"
            >
              <Column width="180px">
                <h3>{t('global.image')}</h3>
                <img
                  src={photo || getDefaultImageByType('item')}
                  alt="avatar"
                  style={{
                    opacity: photo ? 1 : '0.5',
                    borderRadius: '5px',
                    width: '180px',
                    height: '110px',
                    border: '1px solid #d9d9d9',
                  }}
                />
              </Column>
              <Column
                className="item-column"
                alignItems="flex-start"
                margin="0 20px 0 18px"
                width="200px"
                style={{ maxWidth: '200px' }}
              >
                <h3>{t('global.name')}</h3>
                <h4 className="product-modal-subtitle item-name">{name}</h4>
                {!weightItem && (
                  <Fragment>
                    <h3 style={{ marginTop: '20px' }}>{t('global.price')}</h3>
                    <h4 style={{ height: 'auto' }} className="product-modal-subtitle">
                      {weightItem ? '--' : `${price} ${business.currency}`}
                    </h4>
                  </Fragment>
                )}
              </Column>
              <Column alignItems="flex-start">
                <h3>{t('global.category')}</h3>
                <h4 className="product-modal-subtitle">{category || t('global.all')}</h4>
              </Column>
            </Row>
          )}
          {weightItem && (
            <Column alignItems="flex-start" width="100%" margin="12px 0">
              <SaleCost
                units={units}
                saleCost={saleCost}
                salePrice={salePrice}
                saleCount={saleCount}
                inEdit={inEdit}
                callback={data => this.setState({ ...data })}
              />
            </Column>
          )}
          <Column alignItems="flex-start" width="100%" margin="12px 0">
            <Cost
              addon={weightItem ? getUnits(units) : 'count'}
              ref={(ref) => { this.cost = ref; }}
              supplyCount={supplyCount}
              supplyPrice={supplyPrice}
              inEdit={inEdit}
              saleCost={saleCost}
              weightItem={weightItem}
              price={parseFloat(price)}
              callback={data => this.setState({ ...data })}
            />
          </Column>
        </Column>
      </Modal>
    );
  }
}

export default withTranslation()(ItemModal);
