import React, { Component, Fragment } from 'react';
import {
  DeleteOutlined, EllipsisOutlined, MessageOutlined, PercentageOutlined, PlusOutlined, ReloadOutlined, SwapOutlined,
  UserOutlined, MinusOutlined, BarcodeOutlined,
} from '@ant-design/icons';
import {
  Button, Tabs, List, Menu, Dropdown, Typography,
} from 'antd';
import { withTranslation } from 'react-i18next';
import {
  Row, SaleModal, Column, SeparateBill, CommentForBill,
} from '../index';
import PayModal from '../PayModal';
import { prepareBillData } from '../../redux/statistic/helpers';
import {
  calculateSubTotal, calculateTotal, getSizeName, getUnits, prepareCardNumber,
} from '../../utils/helpers';
import './style.scss';
import { isOnline } from '../../persistance';
import { notificationTypes } from '../../redux/message/actions';

const { TabPane } = Tabs;
const { Text } = Typography;

export const getSaleString = (sale, currency) => {
  const hasAmount = sale.amount && sale.amount !== 0;
  const hasPercent = sale.percent !== 0;
  if (hasAmount && hasPercent) {
    return `${sale.percent}% | ${sale.amount} ${currency}`;
  }
  if (hasAmount) {
    return `${sale.amount} ${currency}`;
  }
  if (hasPercent) {
    return `${sale.percent}%`;
  }
  return '-';
};

const EmptyBill = ({ createBill, t }) => (
  <div className="clients-empty">
    <Button size="large" icon={<PlusOutlined />} type="primary" onClick={() => createBill()}>{t('bills.new')}</Button>
  </div>
);

const Description = ({
  item = {}, removeFromBill, activeKey, currency,
}) => (
  <Fragment>
    {item.sizes.map(size => (
      <Row key={`item-${item.name}-size-${size}`}>
        <div className="ant-list-item-meta-description">
          {getSizeName(size)}
          {item.meta?.weightItem
            ? `${(item.quantity[size])}${getUnits(item.meta.units)}`
            : ` x ${item.quantity[size]}`
          }
        </div>
        <div className="ant-list-item-meta-description" style={{ marginLeft: 'auto' }}>
          {item.meta?.weightItem
            ? `${(item.quantity[size] * item.prices[size]).toFixed(2)}`
            : `${item.prices[size]} ${
              item.quantity[size] === 1 ? ' ' : `(${(item.quantity[size] * item.prices[size]).toFixed(2)}) `}`
          }
          {currency}
        </div>
        <Button
          style={{ margin: '5px 0 0 10px' }}
          onClick={() => removeFromBill(activeKey, item, size)}
          icon={item.quantity[size] === 1 || item.meta?.weightItem ? <DeleteOutlined /> : <MinusOutlined />}
        />
      </Row>
    ))}
  </Fragment>
);

class Clients extends Component {
  constructor(props) {
    super(props);
    const { activeKey } = props;
    this.state = {
      activeKey: activeKey !== null ? activeKey : 1,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { activeKey } = nextProps;
    if (this.props.activeKey !== activeKey) {
      this.setState({ activeKey: activeKey !== null ? activeKey : 1 });
    }
  }

  render() {
    const {
      activeBills, t, employer, resolvedBills, menu, business, actions: {
        createBill, removeBill, removeFromBill, setActiveKey, clearBill, showMessage,
      },
    } = this.props;
    const { activeKey } = this.state;
    const { idMap } = menu;
    return (
      <div className="clients">
        {activeBills.length === 0 ? (<EmptyBill t={t} createBill={() => createBill()} />) : (
          <Tabs
            hideAdd
            activeKey={activeKey.toString()}
            onChange={(activeKey) => {
              setActiveKey(activeKey);
              this.setState({ activeKey });
            }}
            style={{ height: '100%' }}
          >
            {activeBills.map((bill, index) => (
              <TabPane
                tab={(
                  <span><UserOutlined />#{resolvedBills.length + index + 1}</span>
                )}
                key={`${bill.id}`}
                className="clients-tab"
              >
                {bill.client && (
                  <Row
                    width="calc(100% - 20px)"
                    margin="10px"
                    justifyContent="space-between"
                    className="clients-tab-header"
                  >
                    <Text>{bill.client.name}</Text>

                    <Row alignItems="center">
                      <BarcodeOutlined />

                      <Text style={{ marginLeft: '5px' }}>
                        #{prepareCardNumber(bill.client.cardNumber)}
                      </Text>
                    </Row>
                  </Row>
                )}

                <List
                  className="clients-list"
                  itemLayout="horizontal"
                  dataSource={prepareBillData(bill.items.map(item => (item.componentId
                    ? { ...idMap[item.componentId], ...item } : item
                  )))}
                  renderItem={item => (
                    <List.Item>
                      <List.Item.Meta
                        title={item.displayName}
                        description={(
                          <Description
                            currency={business.currency}
                            activeKey={activeKey}
                            removeFromBill={removeFromBill}
                            item={item}
                            t={t}
                          />
                        )}
                      />
                    </List.Item>
                  )}
                />

                <Column
                  width="calc(100% - 20px)"
                  margin="auto 10px 0 10px"
                  flexWrap="nowrap"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  style={{
                    paddingTop: '10px',
                    borderTop: '1px solid rgba(0,0,0,0.15)',
                  }}
                >
                  <Row width="100%" margin="0 0 10px">
                    <Text className="clients-tab-price">{t('global.subtotal')}</Text>

                    <Text className="clients-tab-price" strong>
                      {calculateSubTotal(bill).toFixed(2)} {business.currency}
                    </Text>
                  </Row>

                  <Row width="100%" margin="0 0 10px">
                    <Text className="clients-tab-price">{t('global.sale')}</Text>

                    <Text className="clients-tab-price" strong>
                      {getSaleString(bill.sale, business.currency)}
                    </Text>
                  </Row>

                  <Row width="100%">
                    <Text className="clients-tab-price" strong>{t('global.total')}</Text>

                    <Text className="clients-tab-price" strong>
                      {calculateTotal(bill).replace(',', '.')} {business.currency}
                    </Text>
                  </Row>

                  <Row width="100%" margin="10px 0" justifyContent="flex-start">
                    <Dropdown
                      overlay={(
                        <Menu>
                          <Menu.Item>
                            <Button
                              icon={<DeleteOutlined />}
                              type="link"
                              onClick={() => removeBill(bill.id)}
                            >{t('control.delete')}
                            </Button>
                          </Menu.Item>

                          <Menu.Item>
                            <Button
                              icon={<MessageOutlined />}
                              type="link"
                              onClick={() => this.commentForBillModal.show()}
                            >{t('bill.addComment')}
                            </Button>
                          </Menu.Item>

                          <Menu.Item>
                            <Button
                              icon={<SwapOutlined />}
                              type="link"
                              disabled={bill.items.length === 0}
                              onClick={() => this.separateBillModal.show()}
                            >{t('control.separate')}
                            </Button>
                          </Menu.Item>

                          <Menu.Item>
                            <Button
                              icon={<ReloadOutlined />}
                              type="link"
                              disabled={bill.items.length === 0}
                              onClick={() => clearBill(bill.id)}
                            >{t('bill.clean')}
                            </Button>
                          </Menu.Item>
                        </Menu>
                          )}
                      placement="topCenter"
                      trigger={['click']}
                    >
                      <Button size="large" icon={<EllipsisOutlined />} />
                    </Dropdown>

                    <Button
                      size="large"
                      style={{ marginLeft: '10px' }}
                      icon={<PercentageOutlined />}
                      disabled={bill.items.length === 0}
                      onClick={() => this.saleModal.show()}
                    />

                    <Button
                      size="large"
                      style={{ width: '155px', marginLeft: 'auto' }}
                      type="primary"
                      disabled={bill.items.length === 0}
                      onClick={() => {
                        if (business.checkbox.useTaxesWebhook && !isOnline()) {
                          showMessage({ text: t('validation.networkRequired'), type: notificationTypes.ERROR });

                          return;
                        }
                        return this.payModal.show();
                      }}
                    >{t('control.pay')}
                    </Button>
                  </Row>
                </Column>
              </TabPane>
            ))}
          </Tabs>
        )}

        <SaleModal ref={(ref) => { this.saleModal = ref; }} />

        <PayModal employer={employer} ref={(ref) => { this.payModal = ref; }} />

        <SeparateBill ref={(ref) => { this.separateBillModal = ref; }} />

        <CommentForBill ref={(ref) => { this.commentForBillModal = ref; }} />
      </div>
    );
  }
}

export default withTranslation()(Clients);
