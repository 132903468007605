import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Spin, Table } from 'antd';
import moment from 'moment';
import { HashLink } from 'react-router-hash-link';
import { firebase } from '../../../config';
import { Header, Row, Column } from '../../../components';
import { getMonthWithLocale } from '../../../constants/time';
import i18 from '../../../utils/translation';
import { UNIVERSAL, normalizeName } from '../../../constants/tariff';
import './style.scss';
import { isOnline } from '../../../persistance';
import { showNetworkError } from '../../../redux/message/actions';

const REFUND = 'Refunded';
const DECLINED = 'Declined';
const APPROVE = 'Approved';
const PENDING = 'Pending';

class Subscriptions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  openPayWidget = () => {
    if (!isOnline()) return showNetworkError();
    const { business: { name, email, activePlace } } = this.props;
    const amount = '10.00';
    const currency = 'USD';
    const orderReference = `${Date.now()}-${activePlace}`;
    const orderDate = Math.floor(Date.now() / 1000);
    // eslint-disable-next-line max-len, no-undef
    const signature = md5(`shoptoria_net;shoptoria.net;${orderReference};${orderDate};${amount};${currency};Shoptoria Universal;1;${amount}`,
      'ecd58f18dbda672062370b0d6b9669688eac22c0');
    // eslint-disable-next-line no-undef
    new Wayforpay().run({
      merchantAccount: 'shoptoria_net',
      merchantDomainName: 'shoptoria.net',
      authorizationType: 'SimpleSignature',
      merchantSignature: signature,
      orderReference,
      orderDate,
      amount,
      currency,
      productName: 'Shoptoria Universal',
      productPrice: amount,
      productCount: '1',
      clientFirstName: name,
      clientLastName: '',
      clientEmail: email,
      straightWidget: true,
      clientPhone: '',
      paymentSystems: 'card',
      language: 'UA',
      serviceUrl: 'https://shoptoria-be.herokuapp.com/payments-new',
    });
  };

  render() {
    const { t, business } = this.props;
    const payedFor = moment(business.payedFor, 'DD.MM.YYYY');
    const transactionsTableColumns = [{
      title: '№',
      dataIndex: 'key',
      render: text => text + 1,
    }, {
      title: t('supply.date'),
      dataIndex: 'date',
      render: (text) => {
        const momentDate = moment(text);
        return `${momentDate.format('D')} ${getMonthWithLocale(i18.language, momentDate.format('M'))}
         ${momentDate.format('YYYY, hh:mm')}`;
      },
    }, {
      title: t('payments.amount'),
      dataIndex: 'amount',
      className: 'table-header-right',
      render: text => `${text} $`,
    }, {
      title: t('payments.status'),
      dataIndex: 'transactionStatus',
      className: 'table-header-right',
      render: (el) => {
        switch (el) {
          case APPROVE: return (
            <span style={{ fontWeight: 600 }} className="color-green">
              {t(`payments.${APPROVE}`)}
            </span>
          );
          case REFUND: return <span style={{ fontWeight: 600 }} className="color-red">{t(`payments.${REFUND}`)}</span>;
          case DECLINED: return (
            <span style={{ fontWeight: 600 }} className="color-red">{t(`payments.${DECLINED}`)}</span>
          );
          case PENDING: return (
            <span style={{ fontWeight: 600 }} className="color-gray">
              {t(`payments.${PENDING}`)}
            </span>
          );
          default: return <span style={{ fontWeight: 600 }} className="color-red">{el}</span>;
        }
      },
    }];
    return (
      <Spin wrapperClassName="storage-page" spinning={!firebase.auth().currentUser}>
        <Header><h2 className="header-name">{business.name}</h2></Header>
        {business.isFreeTrial && false && (
          <div className="sale-wrapper">
            <Row alignItems="center" justifyContent="center" className="sale" margin="0 20px">
              <img src="/assets/1dollar.png" alt="one dollar" />
              <Column margin="0 0 0 20px">
                <h2>BIG SALE</h2>
                <h3>{t('sale.firstMonth')}</h3>
              </Column>
            </Row>
          </div>
        )}
        <Column width="100%" className="storage-page-content" style={{ padding: 0 }}>
          <Column width="calc(100% - 20px)" margin="10px">
            <Row
              className="today subscription mobile-column-subscription"
              width="100%"
              alignItems="flex-start"
              data-tour="subscription-tour-2"
            >
              <Column className="mobile-row-subscription">
                <span className="subscription-name">{t('payments.payedFor')}</span>
                <h3>{payedFor.format('DD.MM.YYYY')}</h3>
              </Column>
              <Column className="mobile-row-subscription" alignItems="center">
                <span className="subscription-name">{t('payments.subscription')}</span>
                <h3>$10 / {t('payments.everyMonth')}</h3>
              </Column>
              <Column className="mobile-row-subscription">
                <span className="subscription-name">{t('payments.tariff')}</span>
                <h3>{normalizeName(UNIVERSAL)}
                  <br />
                  <span className="notice">
                    <HashLink className="notice-link" smooth to="/#contact">
                      {t('payments.contactUsLink')}
                    </HashLink>&nbsp;
                    {t('payments.contactUsText')}
                  </span>
                </h3>
              </Column>
            </Row>
          </Column>
          <Row width="calc(100% - 20px)" margin="10px" justifyContent="space-between" data-tour="subscription-tour-3">
            <h3 className="storage-page-title subscription-title" style={{ margin: '0' }}>{t('payments.payments')}</h3>
            <Button size="large" onClick={this.openPayWidget} type="primary" className="subscription-link">
              {business.tariff === 'LITE' ? t('payments.payForMonth') : t('payments.upgradeTariff')}
            </Button>
          </Row>
          <Table
            bordered
            pagination={false}
            size="default"
            width="calc(100% - 20px)"
            style={{ width: 'calc(100% - 20px)', overflowX: 'scroll', margin: '10px' }}
            columns={transactionsTableColumns}
            dataSource={business.payments.map((el, index) => ({
              ...el, key: index,
            })).reverse()}
          />
        </Column>
      </Spin>
    );
  }
}

export default withTranslation()(Subscriptions);
