import {
  ADD_CATEGORY, EDIT_CATEGORY, GET_CATEGORIES, REMOVE_CATEGORY,
} from './actions/categories';
import {
  GET_COMPONENTS, REMOVE_COMPONENT, ADD_COMPONENT, EDIT_COMPONENT,
} from './actions/components';
import {
  GET_PRODUCTS, REMOVE_PRODUCTS, ADD_PRODUCTS, EDIT_PRODUCTS,
} from './actions/products';
import {
  GET_ITEMS, ADD_ITEMS, REMOVE_ITEMS, EDIT_ITEMS,
} from './actions/items';
import {
  GET_ADDONS, ADD_ADDONS, EDIT_ADDONS, REMOVE_ADDONS,
} from './actions/addons';

const initialState = {
  components: null,
  products: [],
  items: [],
  categories: null,
  addons: [],
  idMap: {},
  componentsLoaded: false,
};

function menuReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CATEGORIES:
      return { ...state, categories: action.payload };
    case ADD_CATEGORY:
      return { ...state, categories: [...state.categories, action.payload] };
    case REMOVE_CATEGORY:
      return { ...state, categories: state.categories.filter(el => el.id !== action.payload) };
    case EDIT_CATEGORY:
      return { ...state, categories: state.categories.map(el => (el.id === action.payload.id ? action.payload : el)) };

    case GET_COMPONENTS:
      return {
        ...state,
        components: action.payload,
        componentsLoaded: true,
        idMap: { ...state.idMap, ...action.payload.reduce((res, el) => ({ ...res, [el.id]: el }), {}) },
      };

    case ADD_COMPONENT:
      return {
        ...state,
        components: [...state.components, action.payload],
        idMap: { ...state.idMap, [action.payload.id]: action.payload },
      };
    case REMOVE_COMPONENT:
      const idMapWithoutComponent = { ...state.idMap };
      delete idMapWithoutComponent[action.payload];
      return {
        ...state,
        components: state.components.filter(el => el.id !== action.payload),
        idMap: { ...idMapWithoutComponent },
      };
    case EDIT_COMPONENT:
      return {
        ...state,
        components: state.components.map(el => (el.id === action.payload.id ? action.payload : el)),
        idMap: { ...state.idMap, [action.payload.id]: action.payload },
      };

    case GET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        idMap: { ...state.idMap, ...action.payload.reduce((res, el) => ({ ...res, [el.id]: el }), {}) },
      };
    case ADD_PRODUCTS:
      return {
        ...state,
        products: [...state.products, action.payload],
        idMap: { ...state.idMap, [action.payload.id]: action.payload },
      };
    case REMOVE_PRODUCTS:
      const idMapWithoutProduct = { ...state.idMap };
      delete idMapWithoutProduct[action.payload];
      return {
        ...state,
        products: state.products.filter(el => el.id !== action.payload),
        idMap: { ...idMapWithoutProduct },
      };
    case EDIT_PRODUCTS:
      return {
        ...state,
        products: state.products.map(el => (el.id === action.payload.id ? action.payload : el)),
        idMap: { ...state.idMap, [action.payload.id]: action.payload },
      };

    case GET_ITEMS:
      return {
        ...state,
        items: action.payload,
        idMap: { ...state.idMap, ...action.payload.reduce((res, el) => ({ ...res, [el.id]: el }), {}) },
      };
    case ADD_ITEMS:
      return {
        ...state,
        items: [...state.items, action.payload],
        idMap: { ...state.idMap, [action.payload.id]: action.payload },
      };
    case REMOVE_ITEMS:
      const idMapWithoutItem = { ...state.idMap };
      delete idMapWithoutItem[action.payload];
      return {
        ...state,
        items: state.items.filter(el => el.id !== action.payload),
        idMap: { ...idMapWithoutItem },
      };
    case EDIT_ITEMS:
      return {
        ...state,
        items: state.items.map(el => (el.id === action.payload.id ? action.payload : el)),
        idMap: { ...state.idMap, [action.payload.id]: action.payload },
      };

    case GET_ADDONS:
      return {
        ...state,
        addons: action.payload,
        idMap: { ...state.idMap, ...action.payload.reduce((res, el) => ({ ...res, [el.id]: el }), {}) },
      };
    case ADD_ADDONS:
      return {
        ...state,
        addons: [...state.addons, action.payload],
        idMap: { ...state.idMap, [action.payload.id]: action.payload },
      };
    case REMOVE_ADDONS:
      const idMapWithoutAddon = { ...state.idMap };
      delete idMapWithoutAddon[action.payload];
      return {
        ...state,
        addons: state.addons.filter(el => el.id !== action.payload),
        idMap: { ...idMapWithoutAddon },
      };
    case EDIT_ADDONS:
      return {
        ...state,
        addons: state.addons.map(el => (el.id === action.payload.id ? action.payload : el)),
        idMap: { ...state.idMap, [action.payload.id]: action.payload },
      };
    case 'CLEAN_REDUCERS': {
      return initialState;
    }
    default:
      return state;
  }
}

export default menuReducer;
