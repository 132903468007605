import React, { Component } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { withTranslation } from 'react-i18next';
import { firebase } from '../../../config';
import {
  Row, PageHeader, Content, TopBar, Page,
} from '../../../components';
import AddFinancialCategory from './AddFinancialCategory';
import EditFinancialCategory from './EditFinancialCategory';
import { getDefaultFinancialCategories } from '../../../redux/financial/actions';
import { alphabeticalSort } from '../../../utils/helpers';
import { deleteModal } from '../../../utils/modals';
import { isOnline } from '../../../persistance';
import { showNetworkError } from '../../../redux/message/actions';

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
    };
  }

  selectItem = (record) => {
    if (!isOnline()) return showNetworkError();
    const { financial: { categories } } = this.props;
    const selectedItem = categories.find(el => el.name === record.name);
    this.setState({ selectedItem });
    this.editFinancialCategory.show();
  };

  render() {
    const {
      t, financial: { categories }, actions: { removeFinancialCategory },
    } = this.props;
    const { selectedItem } = this.state;
    const categoriesTableColumns = [{
      title: t('global.name'),
      dataIndex: 'name',
    }, {
      title: t('financial.allowedOperation'),
      dataIndex: 'count',
      render: (e, record) => `${record.canIncrease && record.canDecrease
        ? `${t('financial.income')} / ${t('financial.waste')}`
        : record.canIncrease ? t('financial.onlyIncome') : t('financial.onlyWaste')
      }`,
    },
    // record.id > 4 is a hotfix to hide table control for default categories, which id [1-4]
    {
      title: '',
      width: '30px',
      render: (text, record) => (
        <Row width="100%" justifyContent="center">
          {record.id > 4 && <Button onClick={() => this.selectItem(record)} type="link" icon={<EditOutlined />} />}
        </Row>
      ),
    }, {
      title: '',
      width: '30px',
      render: (text, record) => (
        <Row width="100%" justifyContent="center">
          {record.id > 4 && (
            <Button
              onClick={() => deleteModal(() => removeFinancialCategory(record.id))}
              type="link"
              icon={<DeleteOutlined />}
            />
          )}
        </Row>
      ),
    }];
    return (
      <Page spinning={!firebase.auth().currentUser}>
        <PageHeader />
        <Content>
          <TopBar data-tour="financialCategories-tour-2">
            <h3 className="storage-page-title">{t('financial.categories')}: {categories.length + 4}</h3>
            <Button
              className="add-button"
              size="large"
              onClick={() => {
                if (!isOnline()) return showNetworkError();
                this.addFinancialCategory.show();
              }}
            >
              {t('control.add')}
            </Button>
          </TopBar>
          <Table
            bordered
            pagination={false}
            size="default"
            className="storage-page-table"
            columns={categoriesTableColumns}
            dataSource={alphabeticalSort([...getDefaultFinancialCategories(t), ...categories], 'name')
              .map((el, index) => ({ ...el, key: index }))}
          />
        </Content>
        <AddFinancialCategory ref={(ref) => { this.addFinancialCategory = ref; }} />
        <EditFinancialCategory item={selectedItem} ref={(ref) => { this.editFinancialCategory = ref; }} />
      </Page>
    );
  }
}

export default withTranslation()(Categories);
