import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showMessage } from '../../../../redux/message/actions';
import { ModalHOC } from '../../../../common';
import { createConsumable } from '../../../../redux/equipment/actions';
import CreateConsumable from './CreateConsumable';

function mapStateToProps(state) {
  return {
    consumables: state.equipment.consumables,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        createConsumable,
        showMessage,
      },
      dispatch,
    ),
  };
}

export default ModalHOC(connect(mapStateToProps, mapDispatchToProps)(CreateConsumable));
