import React from 'react';
import { withRouter } from 'react-router-dom';
import './style.scss';

const Loading = ({ full }) => (
  <div className={`center ${full ? 'full' : ''}`}>
    <div className="loader" />
  </div>
);

export default withRouter(Loading);
