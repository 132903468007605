import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button, DatePicker, Input, Modal, Table, TimePicker,
} from 'antd';
import {
  SelectConsumableItem, ModalHOC, SupplierDropdown, StoreItemCountInput, FloatInput,
} from '../../../common';
import { Column, Row } from '../../../components';
import { notificationTypes, showMessage } from '../../../redux/message/actions';
import { addSupply } from '../../../redux/storage/actions';
import {
  calculateCost, generateId, hasZeroKey,
} from '../../../utils/helpers';

class AddSupply extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment(),
      time: moment(),
      supplier: '',
      comment: '',
      items: [],
      showSelectItem: false,
    };
  }

  getClearItem = (item = null) => ({
    item, count: '', price: '', timestamp: generateId(),
  });

  calculateTotal = () => {
    const { items } = this.state;
    const total = items.reduce((sum, item) => sum + parseInt(item.price, 10), 0);
    return isNaN(total) ? 0 : total;
  };

  create = () => {
    const { t, close, actions: { addSupply, showMessage } } = this.props;
    const {
      date, supplier, comment, items, time,
    } = this.state;
    if (items.length === 0) {
      showMessage({ text: t('validation.addConsumable'), type: notificationTypes.ERROR });
      return;
    }
    if (hasZeroKey(items.map(el => el.count || 0))) {
      showMessage({ text: t('validation.addCount'), type: notificationTypes.ERROR });
      return;
    }
    if (hasZeroKey(items.map(el => el.price || 0))) {
      showMessage({ text: t('validation.itemPrice'), type: notificationTypes.ERROR });
      return;
    }
    addSupply({
      date: date.format('DD_MM_YYYY'),
      supplier,
      time: time.format('HH:mm'),
      comment,
      items: items.map(el => ({ ...el, cost: calculateCost(el) })),
      total: this.calculateTotal(),
    });
    close();
  };

  setDate = date => this.setState({ date });

  setTime = time => this.setState({ time });

  addItem = item => this.setState(state => ({
    items: [...state.items, this.getClearItem(item)],
  }));

  updateItem = (key, value, record) => this.setState(state => ({
    items: state.items.map(el => (el.timestamp === record.key ? ({ ...el, [key]: value }) : el)),
  }));

  removeItem = timestamp => this.setState(state => ({ items: state.items.filter(el => el.timestamp !== timestamp) }));

  render() {
    const {
      t, close, menu, equipment, visible = false, business,
    } = this.props;
    const {
      date, comment, items, time, showSelectItem,
    } = this.state;
    const allIdMap = { ...menu.idMap, ...equipment.idMap };
    const selectedItems = items.map(el => el.item);
    const supplyColumns = [{
      title: t('global.name'),
      className: 'no-break',
      dataIndex: 'name',
      width: 'calc(100% - 400px)',
      render: (text, record) => allIdMap[record.item].name,
    }, {
      title: t('global.quantity'),
      dataIndex: 'count',
      width: '140px',
      render: (text, record) => (
        <StoreItemCountInput
          callback={value => this.updateItem('count', value, record)}
          units={allIdMap[record.item].units || 'pc'}
        />
      ),
    }, {
      title: t('global.price'),
      dataIndex: 'price',
      width: '140px',
      render: (text, record) => (
        <FloatInput
          style={{ width: '140px' }}
          placeholder="0"
          type="tel"
          onChange={(value) => {
            // eslint-disable-next-line eqeqeq
            if (value[0] == 0 && value[1] !== '.') {
              this.updateItem('price', value.slice(1, value.length), record);
            } else {
              this.updateItem('price', value, record);
            }
          }}
          addonAfter={business.currency}
        />
      ),
    }, {
      title: t('cost.title'),
      dataIndex: 'cost',
      width: '140px',
      render: text => `${text} ${business.currency}`,
    }, {
      title: '',
      dataIndex: 'action',
      width: '50px',
      render: (text, record) => <DeleteOutlined onClick={() => this.removeItem(record.timestamp)} />,
    }];

    return (
      <Modal
        maskClosable={false}
        centered
        visible={visible}
        onOk={this.create}
        onCancel={close}
        width="100%"
        style={{ maxWidth: '800px' }}
        okText={t('control.create')}
        okButtonProps={{ size: 'large' }}
        cancelButtonProps={{ size: 'large' }}
        cancelText={t('control.cancel')}
        title={t('supply.modalTitle')}
      >
        <Column width="100%" alignItems="flex-start">
          <Column width="100%" className="default-card">
            <h3>{t('supply.date')}</h3>
            <Row width="100%" justifyContent="flex-start">
              <DatePicker
                allowClear={false}
                style={{ width: '100%', maxWidth: '300px' }}
                value={date}
                onChange={this.setDate}
              />
              <TimePicker
                allowClear={false}
                style={{ marginLeft: '10px' }}
                value={time}
                onChange={this.setTime}
                format="HH:mm"
              />
            </Row>
            <h3 style={{ margin: '5px 0' }}>{t('supply.supplier')}</h3>
            <SupplierDropdown callback={supplier => this.setState({ supplier })} />
            <h3 style={{ margin: '5px 0' }}>{t('supply.comment')}</h3>
            <Input
              style={{ width: '100%', maxWidth: '438px' }}
              value={comment}
              placeholder={`${t('supply.comment')}...`}
              onChange={e => this.setState({ comment: e.target.value })}
            />
          </Column>
          <Table
            bordered
            pagination={false}
            size="default"
            style={{ width: '100%', overflowX: 'scroll', margin: '12px 0 0' }}
            columns={supplyColumns}
            dataSource={items.map(el => ({
              ...el,
              key: el.timestamp,
              cost: calculateCost(el),
            }))}
          />
          <Row width="100%" justifyContent="space-between" style={{ background: '#fafafa', padding: '12px' }}>
            <Button
              icon={<PlusOutlined />}
              onClick={() => this.setState({ showSelectItem: true })}
            >{t('consumable.add')}
            </Button>
            <h3 style={{ margin: 0 }}>{t('global.subtotal')}: {this.calculateTotal()} {business.currency}</h3>
          </Row>
        </Column>
        <SelectConsumableItem
          selectedItems={selectedItems}
          callback={data => this.addItem(data.id)}
          close={() => this.setState({ showSelectItem: false })}
          visible={showSelectItem}
        />
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    equipment: state.equipment,
    business: state.business,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        addSupply,
      },
      dispatch,
    ),
  };
}

export default ModalHOC(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddSupply)));
