import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import CheckAdminAccess from './CheckAdminAccess';
import { showMessage } from '../../redux/message/actions';
import { getAdminAccess, removeAdminAccess } from '../../redux/business/actions';
import { logout } from '../../redux/user/actions';

function mapStateToProps(state) {
  return {
    isAdmin: state.business.isAdmin,
    dataLoaded: state.business.dataLoaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        getAdminAccess,
        removeAdminAccess,
        logout,
      },
      dispatch,
    ),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CheckAdminAccess));
