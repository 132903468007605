import React, { Component } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { withTranslation } from 'react-i18next';
import { firebase } from '../../../config';
import {
  Row, PageHeader, Content, TopBar, Page,
} from '../../../components';
import { alphabeticalSort } from '../../../utils/helpers';
import { deleteModal } from '../../../utils/modals';
import AddMarketingGroup from './AddMarketingGroup';
import EditMarketingGroup from './EditMarketingGroup';
import { isOnline } from '../../../persistance';
import { showNetworkError } from '../../../redux/message/actions';

class MarketingGroups extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItem: null,
    };
  }

  selectItem = (record) => {
    if (!isOnline()) return showNetworkError();
    const { groups } = this.props;
    const selectedItem = groups.find(el => el.id === record.id);
    this.setState({ selectedItem });
    this.editGroup.show();
  };

  render() {
    const {
      t, groups, actions: { removeGroup },
    } = this.props;
    const { selectedItem } = this.state;
    const groupsTableColumns = [{
      title: t('marketing.groups.name'),
      dataIndex: 'name',
    }, {
      title: t('marketing.groups.type'),
      dataIndex: 'type',
      render: type => t(`marketing.groups.${type || 'sale'}`),
    }, {
      title: '%',
      dataIndex: 'count',
      render: e => `${e}%`,
    },
    {
      title: '',
      width: '30px',
      render: (text, record) => (
        <Row width="100%" justifyContent="center">
          <Button onClick={() => this.selectItem(record)} type="link" icon={<EditOutlined />} />
        </Row>
      ),
    }, {
      title: '',
      width: '30px',
      render: (text, record) => (
        <Row width="100%" justifyContent="center">
          <Button
            onClick={() => deleteModal(() => removeGroup(record.id))}
            type="link"
            icon={<DeleteOutlined />}
          />
        </Row>
      ),
    }];
    return (
      <Page spinning={!firebase.auth().currentUser}>
        <PageHeader />
        <Content>
          <TopBar data-tour="groups-tour-2">
            <h3 className="storage-page-title">{t('layout.groups')}: {groups.length}</h3>
            <Button
              className="add-button"
              size="large"
              onClick={() => {
                if (!isOnline()) return showNetworkError();
                this.addGroup.show();
              }}
            >
              {t('control.add')}
            </Button>
          </TopBar>
          <Table
            bordered
            pagination={false}
            size="default"
            style={{ width: '100%', overflowX: 'scroll', margin: '10px 0 0' }}
            columns={groupsTableColumns}
            dataSource={alphabeticalSort(groups, 'name')
              .map(el => ({ ...el, key: el.id }))}
          />
        </Content>
        <AddMarketingGroup ref={(ref) => { this.addGroup = ref; }} />
        <EditMarketingGroup item={selectedItem} ref={(ref) => { this.editGroup = ref; }} />
      </Page>
    );
  }
}

export default withTranslation()(MarketingGroups);
