/* eslint-disable */

export default {
  general: {
    question1Title: 'What is a free trial?',
    question1Answer: 'The first 15 days after registration you will use the pricing plan Startup for free. We will help you fill in the menu and start selling. We will also send you training videos and articles, and answer any questions.',
    question2Title: 'How to pay?',
    question2Answer: 'Pay a subscription by credit card via the management console.',
    question3Title: 'Can I switch to another pricing plan?',
    question3Answer: 'Yes, you always can switch to another pricing plan. To do so contact us.',
    question4Title: 'How much does it cost to set up Shoptoria?',
    question4Answer: 'You only pay for the subscription. We do not have any hidden or additional payments.',
    question5Title: 'If I pay the annual subscription and the business closes, will you refund my money?',
    question5Answer: 'Yes, if you close the restaurant, then we will refund your money. If you close for repairs or you move, we will suspend the subscription for the duration of either.',
    question6Title: 'Do I need to sign a contract?',
    question6Answer: 'We work with a public offer, so it is not necessary to sign a written or other form of agreement. Contact us if you want to sign a contract.',
  }
}
