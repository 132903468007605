import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DatePicker, Tag } from 'antd';
import { withTranslation } from 'react-i18next';
import { Row } from '../components';
import { isPermissionGranted } from '../constants/tariff';

export const TODAY = 'today';
export const YESTERDAY = 'yesterday';
export const WEEK = 'week';
export const MONTH = 'month';

const TIME_RANGE_START = moment().subtract(40, 'day').startOf('day').unix();
const TIME_RANGE_END = moment().endOf('day').unix();

const getTimeRange = (key) => {
  switch (key) {
    case TODAY: return [moment(), moment()];
    case YESTERDAY: return [moment().subtract(1, 'day'), moment().subtract(1, 'day')];
    case WEEK: return [moment().subtract(1, 'week'), moment()];
    case MONTH: return [moment().subtract(1, 'month'), moment()];
    default: return [moment(), moment()];
  }
};

/**
 * Validate date in range
 * @param timestamp {number} - date unix()
 * @param hasLimitation {boolean} - Should we use TIME_RANGE_START ?
 * @returns {boolean}
 */
const filterDate = (timestamp, hasLimitation) => {
  if (!timestamp) return true;
  if (hasLimitation) {
    return timestamp > TIME_RANGE_END || timestamp < TIME_RANGE_START;
  }
  return timestamp > TIME_RANGE_END;
};

class RangePicker extends Component {
  constructor(props) {
    super(props);
    const { rangeStart = moment(), rangeEnd = moment() } = props;
    this.state = {
      rangeStart,
      rangeEnd,
      isOpen: false,
    };
  }

  updateRange = ([rangeStart, rangeEnd], type) => {
    const { callback = () => {} } = this.props;
    this.setState({ rangeStart, rangeEnd });
    callback({ rangeStart, rangeEnd, type });
  };

  pickFromRange = ([rangeStart, rangeEnd], type) => {
    this.setState({ isOpen: false });
    this.picker.blur();
    this.updateRange([rangeStart, rangeEnd], type);
  }

  render() {
    const {
      t, ranges = [TODAY, YESTERDAY, WEEK, MONTH], shouldCheckPermission = false, business,
    } = this.props;
    const { rangeStart, rangeEnd, isOpen } = this.state;
    const isGrantedPermission = isPermissionGranted(business.tariff, 'unlimitedStatistic');
    const hasLimitation = shouldCheckPermission ? !isGrantedPermission : false;
    return (
      <DatePicker.RangePicker
        ref={(ref) => { this.picker = ref; }}
        disabledDate={date => filterDate(date.unix(), hasLimitation)}
        onChange={e => this.updateRange(e, 'custom')}
        className="datepicker-wrapper"
        style={{ marginLeft: '10px', width: '250px' }}
        size="large"
        dropdownClassName="no-print"
        defaultValue={[rangeStart, rangeEnd]}
        value={[rangeStart, rangeEnd]}
        inputReadOnly
        open={isOpen}
        onOpenChange={isOpen => this.setState({ isOpen })}
        renderExtraFooter={() => (
          <Row width="100%" justifyContent="flex-start" margin="10px">
            {ranges.map(key => (
              <Tag key={key} onClick={() => this.pickFromRange(getTimeRange(key), key)}>
                {t(`time.${key}`)}
              </Tag>
            ))}
          </Row>
        )}
        format="DD.MM.YYYY"
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    business: state.business,
  };
}


export default connect(mapStateToProps, null)(withTranslation()(RangePicker));

RangePicker.propTypes = {
  callback: PropTypes.any,
  rangeEnd: PropTypes.any,
  rangeStart: PropTypes.any,
  ranges: PropTypes.array,
  shouldCheckPermission: PropTypes.bool,
  t: PropTypes.any,
};
