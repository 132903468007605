import axios from '../index';
import { persist } from '../../persistance';
import { idbAccessEmployers, idbAccessJobs } from '../../config';

// Employer flow

export const getEmployersRequest = () => new Promise(resolve => axios.get('/db/access/employers')
  .then((res) => {
    res.data.map(el => persist(el, idbAccessEmployers));
    resolve(res);
  }));

export const editEmployerRequest = (id, data) => axios.put('/db/access/employers', { id, data });

export const addEmployerRequest = (id, data) => axios.post('/db/access/employers', { id, data });

export const removeEmployerRequest = id => axios.delete(`/db/access/employers/${id}`);

// Job flow
export const getJobsRequest = () => new Promise(resolve => axios.get('/db/access/jobs')
  .then((res) => {
    res.data.map(el => persist(el, idbAccessJobs));
    resolve(res);
  }));

export const editJobRequest = (id, data) => axios.put('/db/access/jobs', { id, data });

export const addJobRequest = (id, data) => axios.post('/db/access/jobs', { id, data });

export const removeJobRequest = id => axios.delete(`/db/access/jobs/${id}`);
