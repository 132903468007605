import React from 'react';
import { Dropdown, Menu } from 'antd';
import { HashLink } from 'react-router-hash-link';
import { Link, Row } from '../../components';
import { sendEvent } from '../../utils/analytics';

export const getDocTabs = t => [
  {
    name: t('landing.advantages.menu.title'),
    link: '/docs/menu/',
    linkHash: '/docs/menu/#top',
  }, {
    name: t('landing.advantages.store.title'),
    link: '/docs/store/',
    linkHash: '/docs/store/#top',
  }, {
    name: t('landing.advantages.statistic.title'),
    link: '/docs/statistic/',
    linkHash: '/docs/statistic/#top',
  }, {
    name: t('landing.advantages.financial.title'),
    link: '/docs/financial/',
    linkHash: '/docs/financial/#top',
  }, {
    name: t('landing.advantages.secure.title'),
    link: '/docs/secure/',
    linkHash: '/docs/secure/#top',
  },
];

export const JoinUs = ({ t, className = '' }) => (
  <div className={`landing-join ${className}`}>
    <div className="landing-join-text">
      <h2>{t('landing.joinUs')}</h2>
      <h3>
        {t('landing.workTogetherText1')}
        &nbsp;<b>Shoptoria</b>&nbsp;
        {t('landing.workTogetherText2')}
        &nbsp;<b>{t('landing.workTogetherText3')}</b>.
      </h3>
    </div>
    <Link
      to="/app/login"
    >{t('signUp.header')}
    </Link>
  </div>
);

export const JoinTelegram = ({ t, className = '' }) => (
  <div className={`landing-join ${className}`}>
    <div className="landing-join-text">
      <h2>{t('landing.joinTelegram')}</h2>
      <h3>
        {t('landing.joinTelegramText1')}
        &nbsp;<b>Telegram</b>&nbsp;
        {t('landing.joinTelegramText2')}
      </h3>
    </div>
    <Link
      type="light"
      to="https://t.me/shoptoriaPos"
      target="_blank"
    >{t('landing.joinTelegramButton')}
    </Link>
  </div>
);

export const OpenApp = ({ t, className = '' }) => (
  <div className={`landing-join ${className}`}>
    <div className="landing-join-text">
      <h2>{t('landing.readyToStart')}</h2>
      <h3>
        {t('landing.readyToStartDescription1')}
        &nbsp;<b>Shoptoria POS</b>&nbsp;
        {t('landing.readyToStartDescription2')}
      </h3>
    </div>
    <Link
      to="/app/login"
    >{t('landing.openApp')}
    </Link>
  </div>
);

export const OpenPlayMarket = ({ t, className = '' }) => (
  <div className={`landing-join ${className}`}>
    <div className="landing-join-text">
      <h2>{t('landing.openPlayMarket')}</h2>
      <h3>
        <b>Shoptoria POS</b>&nbsp;
        {t('landing.openPlayMarketDescription')}
      </h3>
    </div>
    <Link
      target="_blank"
      type="empty"
      to="https://play.google.com/store/apps/details?id=net.shoptoria.twa"
    >{t('landing.openPlayMarketButton')}
    </Link>
  </div>
);

export const Capabilities = ({ t, hideOnMobile = false }) => (
  <Dropdown
    className={hideOnMobile ? 'mobile-hide' : ''}
    overlayClassName="landing-dropdown"
    overlay={(
      <Menu>
        {getDocTabs(t).map((tab, i) => (
          <Menu.Item key={`${i + 1}`}>
            <HashLink
              onClick={() => sendEvent({ category: 'Landing', name: `Capabilities tap ${tab.name}` })}
              className={window.location.pathname === tab.link ? 'active' : ''}
              smooth
              to={tab.linkHash}
            >{tab.name}
            </HashLink>
          </Menu.Item>
        ))}
      </Menu>
    )}
    trigger={['click']}
  >
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <a className="ant-dropdown-link" href="#">{t('landing.capability')}</a>
  </Dropdown>
);

export const Spinner = () => (
  <div className="spinner">
    <div className="double-bounce1" />
    <div className="double-bounce2" />
  </div>
);

export const Socials = () => (
  <Row width="100%" justifyContent="center" className="social-row" margin="15px 0 8px">
    <a href="https://t.me/shoptoriaPos" target="_blank"><img src="/assets/telegram.png" alt="Telegram icon" /></a>
    <a href="https://www.instagram.com/shoptoria_pos/" target="_blank"><img src="/assets/instagram.png" alt="Instagram icon" /></a>
    <a href="https://www.facebook.com/shoptoria.pos" target="_blank"><img src="/assets/facebook.png" alt="Facebook icon" /></a>
  </Row>
);
