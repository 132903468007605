import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import {
  PageHeader, Row, Page, Content, TopBar,
} from '../../../components';
import { alphabeticalSort, getUnits } from '../../../utils/helpers';
import ComponentModal from './Modal';
import CreateComponent from './CreateComponent';
import { deleteModal } from '../../../utils/modals';
import { notificationTypes, showNetworkError } from '../../../redux/message/actions';
import './style.scss';
import { isOnline } from '../../../persistance';

class ComponentsList extends Component {
  constructor(props) {
    super(props);
    const { menu: { idMap }, history: { location: { state } } } = props;
    const selectedComponent = state && state.componentId ? idMap[state.componentId] : null;
    this.state = {
      selectedComponent,
    };
  }

  componentDidMount() {
    const { selectedComponent } = this.state;
    if (selectedComponent) {
      this.componentModal.show();
    }
  }

  showComponentModal = (selectedComponent) => {
    if (!isOnline()) return showNetworkError();
    this.setState({ selectedComponent });
    this.componentModal.show();
  };

  render() {
    const {
      menu, t, currency, actions: { removeComponent, showMessage },
    } = this.props;
    const { selectedComponent } = this.state;
    const componentsLoaded = menu.components !== null;
    const componentsTableColumns = [{
      title: t('global.name'),
      dataIndex: 'name',
      width: 'calc(100% - 400px)',
    }, {
      title: t('global.units'),
      dataIndex: 'units',
      width: '200px',
      render: units => getUnits(units),
    }, {
      title: t('cost.title'),
      dataIndex: 'cost',
      width: '200px',
      render: text => `${text} ${currency}`,
    }, {
      title: '',
      width: '30px',
      render: (text, record) => (
        <Row width="100%" justifyContent="center">
          <Button onClick={() => this.showComponentModal(record)} type="link" icon={<EyeOutlined />} />
        </Row>
      ),
    }, {
      title: '',
      width: '30px',
      render: (text, record) => (
        <Row width="100%" justifyContent="center">
          <Button
            onClick={() => {
              const canBeDeleted = !menu.products.map(el => el.components).flat().find(el => el.id === record.id);
              if (canBeDeleted) {
                deleteModal(() => removeComponent(record));
              } else {
                showMessage({ text: t('validation.componentInUse'), type: notificationTypes.ERROR });
              }
            }}
            type="link"
            icon={<DeleteOutlined />}
          />
        </Row>
      ),
    },
    ];
    return (
      <Page>
        <PageHeader />
        <Content>
          <TopBar data-tour="component-tour-2">
            <h3 className="storage-page-title">
              {t('global.components')}: {menu.components ? menu.components.length : 0}
            </h3>
            <Button
              className="add-button"
              size="large"
              onClick={() => {
                if (!isOnline()) return showNetworkError();
                this.createComponentModal.show();
              }}
            >
              {t('control.add')}
            </Button>
          </TopBar>
          {componentsLoaded && (
          <Table
            bordered
            pagination={false}
            size="default"
            className="storage-page-table"
            columns={componentsTableColumns}
            dataSource={alphabeticalSort(menu.components, 'name').map((el, index) => ({
              ...el, key: index,
            }))}
          />
          )}
          <CreateComponent ref={(ref) => { this.createComponentModal = ref; }} />
          <ComponentModal component={selectedComponent} ref={(ref) => { this.componentModal = ref; }} />
        </Content>
      </Page>
    );
  }
}

export default withTranslation()(ComponentsList);
