import tour from './tours/ru';
import faq from './faq/ru';

/* eslint-disable */
export default {
  validation: {
    bonusRangeNoGroup: 'Выберите бонусную систему для групы',
    bonusRangeNoAmount: 'Выберите количество бонусов для групы',
    saleRangeNoGroup: 'Выберите скидочную систему для групы',
    saleRangeNoAmount: 'Выберите размер скидки для групы',
    promotionDays: 'Выберите хотя бы 1 день работы акции!',
    saleItems: 'Выберите хотя бы 1 категорию или товар!',
    text: 'Добавьте немного текста!',
    forgotPassword: 'Напишите нам чтобы решить эту проблему!',
    something: 'Что-то пошло не так!',
    email: 'Некоректный формат емейла!',
    password: 'Введите пароль',
    passwordRepeat: 'Введенные пароли не совпадают!',
    passwordLength: 'Пароль должен быть не меньше 6 символов!',
    chooseComponent: 'Выберите компонент!',
    addonCalculation: 'Добавьте калькуляцию модификатора!',
    addonPrice: 'Добавьте цену модификатора!',
    productName: 'Добавьте название продукту!',
    pickSize: 'Добавьте хотя бы один размер!',
    addConsumable: 'Добавьте хотя бы один материал!',
    productComponents: 'Добавьте компоненты продукта!',
    productPrice: 'Добавьте цену продукта!',
    productVolume: 'Добавьте объём продукта!',
    componentCount: 'Добавьте количество компонентов!',
    addCount: 'Добавьте количество!',
    itemName: 'Добавьте название товара!',
    itemPrice: 'Добавьте цену товара!',
    consumableName: 'Добавьте название материала!',
    alreadyHaveConsumable: 'Вы не можете менять размеры после того, как добавили рассходные материалы!',
    emptyName: 'Добавьте название!',
    emptyPhone: 'Добавьте номер телефона!',
    emptySizes: 'Проверьте размеры!',
    emptyCategory: 'Добавьте категорию!',
    emptyGroup: 'Выберите группу!',
    emptyTransactionType: 'Выберите хотя бы 1 тип транзакций!',
    emptyAccount: 'Добавить счет!',
    emptySum: 'Добавьте сумму!',
    wrongIncome: 'Невозможно получить больше, чем было отправлено!',
    pickConsumables: 'Добавьте хотя бы один материал!',
    uniqueProductName: 'Продукт с таким названием уже существует!',
    uniqueComponentName: 'Компонент с таким названием уже существует!',
    uniqueItemName: 'Товар с таким названием уже существует!',
    uniqueAddonName: 'Модификатор с таким названием уже существует!',
    uniqueConsumableName: 'Расходный материал с таким названием уже существует!',
    uniqueGroupName: 'Группа с таким названием уже существует!',
    choose: 'Выберите...',
    passwordInUse: 'Этот пароль уже используется!',
    noSuchEmployer: 'Не найдено сотрудника с таким паролем!',
    emptySalary: 'Добавьте хотя бы один тип рассчёта зарплаты!',
    addJob: 'Выберите должность!',
    componentInUse: 'Вы не можете удалить компонент, который является модификатором или частью рецепта!',
    itemInUse: 'Вы не можете удалить материал, который является частью рецепта!',
    zeroValue: 'Зарплата должна быть больше 0!',
    saleMoreThen100: 'Размер скидки не может быть больше 100%!',
    emptyInviteBonus: 'Добавьте бонус за регистрацию',
    emptyMaxBonus: 'Добавьте максимальный % оплаты чека бонусами!',
    networkRequired: 'Для работы з пРРО вы должны быть онлайн!',
  },
  actions: {
    needNetworkTitle: 'Вы должны быть онлайн чтобы сделать это!',
    needNetworkDescription: 'Проверьте ваше подключение к Интернету.',
    employerEditedTitle: 'Данные о сотруднике успешно изменены!',
    employerCreatedTitle: 'Сотрудник успешно добавлен!',
    employerDeletedTitle: 'Сотрудник успешно удален!',
    jobEditedTitle: 'Данные о должности успешно сохранены!',
    jobCreatedTitle: 'Должность успешно добавлена!',
    jobDeletedTitle: 'Должность успешно удалена!',
    consumableEditedTitle: 'Данные о материале успешно сохранены!',
    consumableCreatedTitle: 'Материал успешно добавлен!',
    consumableDeletedTitle: 'Материал успешно удален!',
    equipmentEditedTitle: 'Данные о комплекте успешно сохранены!',
    equipmentCreatedTitle: 'Комплект успешно добавлен!',
    equipmentDeletedTitle: 'Комплект успешно удален!',
    financialAccountEditedTitle: 'Данные о финансовом аккаунте успешно сохранены!',
    financialAccountCreatedTitle: 'Финансовый аккаунт успешно добавлен!',
    financialAccountDeletedTitle: 'Финансовый аккаунт успешно удален!',
    financialCategoryEditedTitle: 'Данные о финансовой категории успешно сохранены!',
    financialCategoryCreatedTitle: 'Финансовая категория успешно добавлена!',
    financialCategoryDeletedTitle: 'Финансовая категория успешно удалена!',
    financialTransactionEditedTitle: 'Данные о финансовой транзакции успешно сохранены!',
    financialTransactionCreatedTitle: 'Финансовая транзакция успешно добавлена!',
    financialTransactionDeletedTitle: 'Финансовая транзакция успешно удалена!',
    keeperEditedTitle: 'Настройки терминала успешно сохранены!',
    marketingLoyaltyEditedTitle: 'Система лояльности успешно сохранена!',
    marketingClientEditedTitle: 'Данные о клиенте успешно сохранены!',
    marketingClientCreatedTitle: 'Клиент успешно добавлен!',
    marketingClientDeletedTitle: 'Клиент успешно удален!',
    marketingGroupEditedTitle: 'Данные о группе успешно сохранены!',
    marketingGroupCreatedTitle: 'Группа успешно добавлена!',
    marketingGroupDeletedTitle: 'Группа успешно удалена!',
    marketingSaleEditedTitle: 'Данные о акции успешно сохранены!',
    marketingSaleCreatedTitle: 'Акция успешно добавлена!',
    marketingSaleDeletedTitle: 'Акция успешно удалена!',
    menuAddonEditedTitle: 'Данные о модификаторе успешно сохранены!',
    menuAddonCreatedTitle: 'Модификатор успешно добавлен!',
    menuAddonDeletedTitle: 'Модификатор успешно удален!',
    menuCategoryEditedTitle: 'Данные о категории успешно сохранены!',
    menuCategoryCreatedTitle: 'Категория успешно добавлена!',
    menuCategoryDeletedTitle: 'Категория успешно удалена!',
    menuComponentEditedTitle: 'Данные о компоненте успешно сохранены!',
    menuComponentCreatedTitle: 'Компонент успешно добавлен!',
    menuComponentDeletedTitle: 'Компонент успешно удален!',
    menuItemEditedTitle: 'Данные о товаре успешно сохранены!',
    menuItemCreatedTitle: 'Товар успешно добавлен!',
    menuItemDeletedTitle: 'Товар успешно удален!',
    menuProductEditedTitle: 'Данные о продукте успешно сохранены!',
    menuProductCreatedTitle: 'Продукт успешно добавлен!',
    menuProductDeletedTitle: 'Продукт успешно удален!',
    storageSupplierEditedTitle: 'Данные о поставщике успешно сохранены!',
    storageSupplierCreatedTitle: 'Поставщик успешно добавлен!',
    storageSupplierDeletedTitle: 'Поставщик успешно удален!',
    storageSupplyEditedTitle: 'Данные о поставке успешно сохранены!',
    storageSupplyCreatedTitle: 'Поставка успешно добавлена!',
    storageSupplyDeletedTitle: 'Поставка успешно удалена!',
    storageWasteEditedTitle: 'Данные о списании успешно сохранены!',
    storageWasteCreatedTitle: 'Списание успешно добавлено!',
    storageWasteDeletedTitle: 'Списание успешно удалено!',
    storageInventoryEditedTitle: 'Данные о инвентаризации успешно сохранены!',
    storageInventoryCreatedTitle: 'Инвентаризация успешно добавлена!',
    storageInventoryDeletedTitle: 'Инвентаризация успешно удалена!',
  },
  feedback: {
    title: 'Поделитесь с нами вашими впечатлениями!'
  },
  textPlaceholder: 'Для меня Shoptoria это...',
  loading: {
    employers: 'Получаем данные о сотрудниках...',
  },
  modals: {
    deleteTitle: 'Вы уверены, что хотите удалить позицию?',
  },
  actionDrawer: {
    title: 'Список действий',
    createProduct: 'Создать продукт',
    createItem: 'Создать товар',
    createAddon: 'Создать модификатор',
    manageCategories: 'Категории',
    createConsumable: 'Создать расходный материал',
    createModalTitle: 'Что вы хотите создать?',
    createReceipt: 'Создать рецепт',
  },
  logOut: 'Выход',
  login: {
    header: 'Вход',
    email: 'Емейл',
    password: 'Пароль',
    submit: 'Войти',
    createAccount: 'Создать аккаунт',
    mainPage: 'На главную страницу',
    forgotPassword: 'Забыли пароль?',
    title: 'У вас нет аккаунта?',
    description: 'Присоединяйтесь к Shoptoria сегодня и получите 14 дней бессплатного использования!',
  },
  signUp: {
    header: 'Регистрация',
    email: 'Емейл',
    password: 'Пароль',
    passwordRepeat: 'Повторите пароль',
    submit: 'Создать Аккаунт',
    logIn: 'Войти в существующий аккаунт',
    terms: 'Нажимая Создать Аккаунт вы подтверждаете, что вы старше 18 лет и соглашаетесь с',
    termsLink: 'Пользовательским соглашением',
    title: 'Уже имеете аккаунт?',
    description: 'Рады видеть вас снова! Войдите в аккаунт, чтобы продолжить.',
  },
  layout: {
    keeper: 'Терминал',
    menu: 'Меню',
    statistic: 'Статистика',
    store: 'Склад',
    inventory: 'Инвентарь',
    equipment: 'Equipment',
    kits: 'Комплекты',
    kit: 'Комплект',
    items: 'Товары',
    bills: 'Чеки',
    profit: 'Продажи',
    category: 'Категории',
    payments: 'Оплаты',
    marketing: 'Маркетинг',
    clients: 'Клиенты',
    groups: 'Группы клиентов',
    loyalty: 'Программы лояльности',
    sales: 'Акции',
    loyaltyExclude: 'Исключения',
    financial: 'Финансы',
    transactions: 'Транзакции',
    account: 'Счета',
    financialReport: 'Отчет',
    salary: 'Зарплата',
    shifts: 'Кассовые смены',
    access: 'Доступ',
    jobs: 'Должности',
    employers: 'Сотрудники',
    settings: 'Настройки',
    general: 'Общие',
    notifications: 'Уведомления',
    updates: 'Обновления',
    subscription: 'Оплата подписки',
    checkbox: 'Checkbox',
  },
  keeper: {
    promotions: 'Акции',
    menuSearch: 'Поиск по меню',
    hi: 'Привет,',
    openCashShift: 'Кассовая смена открыта, желаем хорошего дня! (＾◡＾)',
  },
  components: {
    new: 'Новый компонент',
    modalTitle: 'Создать новый компонент',
    modalPlaceholder: 'Название компонента',
    edit: 'Редактирование компонента',
    replacer: 'Может быть заменён на',
    add: 'Добавить компонент',
  },
  addon: {
    modalTitle: 'Создать новый модификатор',
    modalSelect: 'Выберите компонент',
  },
  product: {
    modalTitle: 'Создать новый продукт',
    modalNamePlaceholder: 'Название продукта',
    modalChooseCategory: 'Выберите категорию',
    modalSizes: 'Размеры продукта',
  },
  item: {
    modalTitle: 'Создать новый товар',
    modalNamePlaceholder: 'Название товара',
    modalSizes: 'Размеры товара',
    forWeight: 'Продавать на розвес',
    saleCost: 'Цена продажи',
    price: 'Цена за',
    weight: 'Добавте количество товара',
    add: 'Добавить товары',
  },
  consumable: {
    modalTitle: 'Добавить материал',
    modalNamePlaceholder: 'Название материала',
    add: 'Добавить материал',
    edit: 'Редактирование материала',
    select: 'Выберите материалы',
  },
  equipment: {
    add: 'Добавить комплект',
    manage: 'Изменить инвентарь',
    edit: 'Редактирование комплекта',
    kitName: 'Название комплекта',
    helpText: 'Все выбранные материалы будут доступны при замене материала в продукте.',
    predefined: 'Готовые комплекты',
    empty: 'Пусто',
  },
  storage: {
    onStore: 'Остатки',
    supply: 'Поставки',
    waste: 'Списания',
    inventory: 'Инвентаризации',
    consumption: 'Расход',
    suppliers: 'Поставщики',
  },
  waste: {
    modalTitle: 'Новое списание',
    modalEdit: 'Редактировать списание',
  },
  inventory: {
    modalTitle: 'Добавить инвентаризацию',
    modalEdit: 'Редактировать инвентаризацию',
    before: 'Было',
    now: 'На складе',
    diff: 'Разница',
    profit: 'Прибыль',
    expenses: 'Расходы',
    result: 'Результат',
  },
  supply: {
    modalTitle: 'Добавить новую поставку',
    modalEdit: 'Редактировать поставку',
    supplier: 'Поставщик',
    date: 'Дата',
    comment: 'Комментарий',
    addSupply: 'Добавить поставку',
  },
  supplier: {
    modalTitle: 'Добавть нового поставщика',
    modalEdit: 'Редактировать поставщика',
    addSupply: 'Добавить поставщика',
  },
  cost: {
    title: 'Себестоимость',
    count: 'Количество',
    one: 'Цена единици',
    price: 'Сумма',
    recommended: 'Рекомендуемая цена',
    principe: 'Рекомендованная цена высчитывается как 100% прибыль над себестоимостью.',
    missedItem: 'Для правильного расчета себестоимости продукта, добавьте себестоимость всех материалов',
  },
  units: {
    pc: 'шт',
    ml: 'мл',
    g: 'г',
    l: 'л',
    kg: 'кг',
  },
  count: 'шт',
  categories: {
    modalTitle: 'Управление категориями',
    add: 'Добавить категорию',
    edit: 'Редактирование категории',
  },
  control: {
    print: 'Печать',
    create: 'Создать',
    cancel: 'Отмена',
    add: 'Добавить',
    edit: 'Изменить',
    save: 'Сохранить',
    delete: 'Удалить',
    separate: 'Разделить',
    pay: 'Оплата',
    close: 'Закрыть',
    apply: 'Применить',
    remove: 'Убрать',
    collapse: 'Скрыть',
    validate: 'Validate',
    clear: 'Удалить',
    submit: 'Отправить',
    try: 'Попробовать',
    open: 'Открыть',
    update: 'Обновить',
    start: 'Начать',
    back: 'Назад',
    next: 'Дальше',
    check: 'Проверить',
  },
  sale: {
    modalPercent: 'Процент скидки',
    modalAmount: 'Сумма скидки',
    firstMonth: 'Первый месяц подписки всего за 1 доллар',
  },
  pay: {
    cash: 'Наличными',
    card: 'Картой',
    bonus: 'Бонусы',
    byBonus: 'Бонусами',
    bonusAmount: 'Доступно бонусов',
    maxBonusAmount: 'Максимальная сумма оплаты бонусами',
    change: 'Сдача',
  },
  category: {
    name: 'Название категории',
    list: 'Список категорий',
    alreadyExist: 'Категория с таким именем уже существует!',
  },
  bill: {
    modalTitleSeparate: 'Разделение чека',
    nodalActiveBill: 'Активный чек',
    modalNewBill: 'Новый чек',
    count: 'Количество чеков',
    clean: 'Очистить',
    addComment: 'Добавить коментарий',
    commentForBill: 'Коментарий к чеку',
  },
  image: {
    modalTitle: 'Выберите часть изображения',
    croppedImg: 'Часть изображения',
    cardExample: 'Пример карточки',
    cardName: 'Пример',
  },
  notification: {
    settingsTitle: 'Уведомления',
    telegramToken: 'Telegram token',
    telegramBot: 'Telegram боту',
    telegramHelpText1: 'Чтобы получить Telegram token, напишите',
    telegramHelpText2: '',
    telegramHelpText3: 'Бот отправит Вам ваш',
    telegramHelpText4: 'Скопируйте ваш token в поле выше.',
    allowedEvents: 'Выберите события, о которых Вы хотите получать уведомления:',
    openShift: 'Открытие кассовой смены',
    closeShift: 'Закрытие кассовой смены',
    addTransaction: 'Добавлено финансовую транзакцию',
    addSupply: 'Добавлено поставку на склад',
    received: 'Уведомление успешно отправлено!',
    tokenAdded: 'Telegram token сохранено!',
    formSaved: 'Успешно сохранено!',
    comingSoon: 'Скоро!',
    comingSoonDescription: 'Мы работаем над этим прямо сейчас!',
  },
  marketing: {
    clients: {
      addClient: 'Добавить нового клиента',
      editClient: 'Редактирование данных клиента',
      name: 'Имя',
      gender: 'Пол',
      male: 'Мужчина',
      female: 'Женщина',
      number: 'Номер карты',
      profitCount: 'Сумма покупок',
      creationDate: 'Дата создания',
      bonusAmount: 'Количество бонусов',
    },
    groups: {
      createGroup: 'Добавить группу клиентов',
      editGroup: 'Редактирование группы клиентов',
      name: 'Название группы',
      type: 'Программа лояльности',
      sale: 'Скидочная система',
      bonus: 'Бонусная система',
      selectGroup: 'Выберите группу',
      salePercent: 'Размер скидки',
      bonusPercent: 'Размер бонусов',
    },
    loyalty: {
      bonusDescription1: 'Клиенты будут накапливать бонусы и оплачивать ими заказы или их часть. Для этого настройте разный размер бонусов для ваших групп клиентов.',
      bonusDescription2: 'А чтобы процент бонусов увеличивался, включите автоматический переход между группами и укажите суммы оплаты заказов, при достижении которых клиент перейдет в группу с более высоким процентом бонусов.',
      saleDescription1: 'Клиенты будут получать скидку от суммы чека. Для этого настройте разный размер скидки для ваших групп клиентов.',
      saleDescription2: 'А чтобы процент скидки увеличивался, включите автоматический переход между группами и укажите суммы оплаты заказов, при достижении которых клиент перейдет в группу с более высоким процентом скидки.',
      maxBonus: 'Максимально возможный % оплаты чека бонусами',
      inviteBonus: 'Бонус за регистрацию',
      groupTransit: 'Переход между группами',
      transitCondition: 'Условия перехода',
      yes: 'есть',
      no: 'нет',
      excludedCategories: 'Категории-исключения',
      excludedCategoriesDescription: 'Категории товаров, на которые не распространяется оплата бонусами, но применяются акции:',
      excludedItems: 'Товары-исключения',
      excludedItemsDescription: 'Товары, на которые не распространяется оплата бонусами, но применяются акции:',
      selectCategories: 'Выберите категории',
    },
    sales: {
      addSale: 'Создание акции',
      editSale: 'Редактирование акции',
      saleDate: 'Период работы акции',
      startDate: 'Дата начала акции',
      endDate: 'Дата конца акции',
      saleTime: 'Время работы акции',
      endTime: 'Время конца акции',
      includedCategories: 'Категории',
      includedItems: 'Товары',
      saleType: 'Тип скидки',
      percent: 'Процент скидки',
      amount: 'Сумма скидки',
      activeDays: 'Дни работы акции',
      needBill: 'Откройте чек чтобы продолжить!',
    }
  },
  days: {
    mon: 'Пн',
    tue: 'Вт',
    wed: 'Ср',
    thu: 'Чт',
    fri: 'Пт',
    sat: 'Сб',
    sun: 'Вс',
  },
  global: {
    contacts: 'Контакты',
    nothing: 'Ничего',
    group: 'Группа',
    favourites: 'Избранные',
    all: 'Все',
    image: 'Изображение',
    name: 'Название',
    timeRange: 'Временной промежуток',
    startDate: 'Дата начала',
    endDate: 'Дата конца',
    sale: 'Скидка',
    bonus: 'Бонус',
    category: 'Категория',
    product: 'Продукт',
    products: 'Продукты',
    item: 'Товар',
    items: 'Товары',
    component: 'Компонент',
    components: 'Компоненты',
    addon: 'Модификатор',
    addons: 'Модификаторы',
    consumable: 'Расходный материал',
    consumables: 'Расходные материалы',
    materials: 'Материалы',
    examples: 'Примеры',
    receipt: 'Рецепт',
    size: 'Размер',
    bonuses: 'Бонусы',
    total: 'К оплате',
    price: 'Цена',
    pricing: 'Ценообразование',
    volume: 'Объём',
    calculation: 'Калькуляция',
    subtotal: 'Сумма',
    quantity: 'Количество',
    shortQuantity: 'Кол-во',
    search: 'Поиск',
    cardNumber: 'Номер карты',
    client: 'Клиент',
    clients: 'Клиенты',
    language: 'Язык',
    type: 'Тип',
    units: 'Ед. измерения',
    photo: 'Изображение',
    profit: 'Прибыль',
    weightProfit: 'Прибыль за 100',
    excludeConsumableCost: 'Без рассчёта себестоимости материалов',
    sum: 'Всего',
    balance: 'Баланс',
    select: 'Выберите...',
    other: 'Другое',
    gross: 'Валовая прибыль',
    extra: 'Наценка',
    description: 'Дополнительная информация',
    revenue: 'Выручка',
    sales: 'Продажи',
    month: 'за месяц',
    owner: 'Владелец',
  },
  optional: {
    title: 'опционально',
    addSoon: 'можно добавить позже',
  },
  admin: {
    signUp: 'Добавьте информацию про ваш бизнес',
    shopName: 'Название заведения',
    businessType: 'Тип вашего заведения',
    coffee: 'Кофейня',
    cafe: 'Кафе',
    restaurant: 'Ресторан',
    customType: 'Другое',
    modalTitle: 'Добавьте пароль администратора',
    adminPassword: 'Пароль администратора',
    modalDescription: 'Пароль администратора нужен для доступа к меню, инвентаризации и статистике.',
    checkPassword: 'Готово',
    checkTitle: 'Введите пароль администратора!',
    checkType: 'Выберите тип заведения!',
    removeAccess: 'Убрать доступ',
    wrongPassword: 'Пароль неверен!',
    notAdminUser: 'Вы должны иметь доступ администратора, чтобы сделать это!',
  },
  time: {
    today: 'Сегодня',
    yesterday: 'Вчера',
    week: 'За неделю',
    month: 'За месяц',
    custom: 'Временной промежуток',
    all: 'За всё время',
    range: 'Временной промежуток',
    sortByTime: 'По времени',
    sortByDay: 'По дням недели',
    mon: 'Пн',
    tue: 'Вт',
    wed: 'Ср',
    thu: 'Чт',
    fri: 'Пт',
    sat: 'Сб',
    sun: 'Вс',
    offset: 'Временной пояс'
  },
  bills: {
    new: 'Новый чек',
    openTime: 'Время открытия чека',
    closeTime: 'Время закрытия чека',
  },
  financial: {
    report: 'Финансовый отчет',
    safeAccount: 'Сейф',
    cashbox: 'Касса',
    cardAccount: 'Счет ИП',
    addNewCategory: 'Добавление категории транзакций',
    editCategory: 'Редактирование категории транзакций',
    addNewAccount: 'Добавление финансового счета',
    editAccount: 'Редактирование финансового счета',
    addNewTransaction: 'Добавить транзакцию',
    editTransaction: 'Редактировать транзакцию',
    categories: 'Категории транзакций',
    income: 'Доходы',
    waste: 'Расходы',
    transfer: 'Перевод',
    onlyIncome: 'Только доходы',
    onlyWaste: 'Только расходы',
    allowedOperation: 'Допустимые типы транзакции',
    actual: 'Актуализация',
    bank: 'Банковские услуги',
    supply: 'Поставки',
    shift: 'Кассовые смены',
    exchange: 'Размен',
    cash: 'Наличные',
    card: 'Банковская карта',
    account: 'Безналичный счет',
    account2: 'Счет',
    operation: 'Транзакция',
    from: 'Со счета',
    to: 'На счет',
    type: 'Тип',
    delta: 'Разница',
  },
  settings: {
    title: 'Настройки компании',
    general: 'Общие настройки',
    shiftAutoCloseTitle: 'Закрытие кассовой смены',
    shiftAutoCloseSubtitle: 'Мы автоматически закроем кассовую смену в выбранное время.',
    currentVersion: 'Текущая версия',
    newVersion: 'Новая версия',
    releaseDate: 'Дата выхода',
    newVersionAvailable: 'Доступна новая версия!',
    managePlace: 'Заведения',
    choosePlace: 'Выберите заведение',
    currency: 'Валюта',
    keeper: 'Настройки Терминала',
    keeperDescription: 'Здесь вы можете выбрать какие глобальные вкладки будут отображены в',
    keeperDescription2: 'Терминале',
    keeperDescription3: '.',
    keeperTabs: 'Вкладки',
    other: 'Другие',
    showCategoryInCard: 'Показывать категорию в карточке товара',
    allowBonusPayment: 'Разрешить оплату бонусами',
    hideEmptyItem: 'Скрыть товары с 0 остатком',
    cashier: 'Кассир',
    cashierName: 'Имя кассиру',
    cashierToken: 'Токен кассира',
    cashierLoginTitle: 'Логин',
    cashierLoginPlaceholder: 'Логин',
    cashierPasswordTitle: 'Логин кассира',
    cashierPasswordPlaceholder: 'Логин кассира',
    cashKeyTitle: 'Ключ кассы',
    cashKeyPlaceholder: 'Ключ кассы',
    useTaxesWebhook: 'Отправлять данные в ДФС',
    taxTitle: 'ДФС',
    checkboxCashShift: 'Кассовая смена в ДФС',
    checkboxCashShiftEmpty: 'Кассовая смена отсутствует',
    syncWithTaxService: 'Синхронизировано с ДФС',
    onlySynced: 'Только синхронизированные',
    onlyUnSynced: 'Не синхронизированные',
    forceOpenCheckboxShift: 'Открыть кассовую смену в ДФС',

  },
  access: {
    name: 'Имя',
    employer: 'Сотрудник',
    addNewEmployer: 'Добавить сотрудника',
    editEmployer: 'Редактирование сотрудника',
    passwordDescription: 'Только 4 цифры',
  },
  job: {
    jobName: 'Название должности',
    selectJob: 'Выберите должность',
    addNewJob: 'Добавить должность',
    editJob: 'Редактирование должности',
    salaryCalculation: 'Расчет зарплаты',
    fixedRate: 'Фиксированная ставка',
    salaryPerShift: 'За смену',
    salaryPerMonth: 'За месяц',
    flexRate: 'Процент от продаж',
    flexRatePersonal: 'Личных продаж',
    flexRateTotal: 'Продаж за смену',
    terminalPassword: 'Пароль к терминалу',
  },
  statistic: {
    middleBill: 'Средний чек',
    popularItem: 'Популярные товары',
    orders: 'Заказы',
  },
  modalMenu: {
    title: 'Функции',
    getShiftReport: 'Отчет за смену',
    getDayReport: 'Отчет за день',
    shiftReport: 'Отчет по кассовой смене',
    dayReport: 'Отчет за день',
    closeShift: 'Закрыть смену',
    openShift: 'Открыть смену',
    cashBalance: 'Остаток в кассе',
    sumBalance: 'Сумма в кассе',
    openCashBalance: 'Сумма в кассе при открытии',
    closeCashBalance: 'Сумма в кассе при закрытии',
    expectedCashBalance: 'Ожидаемая сумма в кассе',
    afterCollection: 'Остаток наличных в кассе',
    afterCollection2: 'Должен быть равен сумме в кассе при открытии смены',
    collectionHelp: 'Перед тем, как закрыть смену, сделайте инкассацию наличных и оставьте в кассе только разменные деньги',
    openBy: 'Открыл смену',
    closeBy: 'Закрывает смену',
    closedBy: 'Закрыл смену',
    openTime: 'Время открытия',
    closeTime: 'Время закрытия',
    openReport: 'Открыть отчёт',
    employerSales: 'Продажи по сотрудникам',
    bills: 'Чеки',
    manageBills: 'Управление чеками',
    changePayments: 'Изменить оплату',
    checkSync: 'Синхронизировать',
    syncProcess: 'Синхронизация...',
    syncProcessDescription: 'Мы синхронизируем ваши данные!',
    syncSuccess: 'Синхронизировано!',
    syncSuccessDescription: 'Ваши данные синхронизированы.',
    syncFailed: 'Внимание!',
    syncFailedDescription: 'Вы не можете синхронизировать данные, пока устройство офлайн.',
    cleanCache: 'Очистить кеш',
    cacheCleaned: 'Готово!',
    cacheCleanedDescription: 'Кеш очищен.',
    consumptionCount: 'Расходы',
  },
  landing: {
    installTitle: 'Установить Shoptoria POS!',
    installText: 'Вы можете добавить приложение Shoptoria POS на домашний экран!',
    installButton: 'Установить',
    capability: 'Возможности',
    prices: 'Цены',
    terms: 'Соглашение',
    joinTelegram: 'Мы на связи!',
    joinTelegramText1: 'Следите за новостями, задавайте вопросы, делитесь фидбеком - все это в нашем',
    joinTelegramText2: 'канале.',
    joinTelegramButton: 'Присоедениться',
    openPlayMarket: 'Теперь и в Play Market!',
    openPlayMarketDescription: 'теперь доступна для скачивания с Play Market!',
    openPlayMarketButton: 'Перейти',
    openApp: 'Открыть приложение',
    readyToStart: 'Готовы начать?',
    readyToStartDescription1: 'Откройте приложение',
    readyToStartDescription2: 'и начните продавать уже сейчас!',
    joinUs: 'Давайте работать вместе!',
    workTogetherText1: 'Стань частью',
    workTogetherText2: 'уже сейчас и получи 2 недели подписки',
    workTogetherText3: 'бесплатно',
    company: 'Название заведения',
    phone: 'Телефон',
    why: 'Почему бизнес любит Shoptoria',
    heroText: 'облачная система учёта',
    heroContent1: 'Shoptoria POS - программа автоматизации и складского учета общепита заведений и HoReCa.',
    heroContent2: 'Удобная и надежная онлайн-касса на планшет iPad и Android.',
    testimonialTitle1: 'Доступ из любой точки мира',
    testimonialDescription1: 'Shoptoria — облачная система учёта. Это значит, что статистика, склад и финансы вашего бизнеса доступны вам в любой точке мира с интернетом.',
    testimonialTitle2: 'Простая установка и обучение',
    testimonialDescription2: 'Мы фанаты простых и удобных продуктов. Shoptoria не требует дополнительного обучения — все и так понятно. Ваши сотрудники начнут продавать через 5 минут.',
    testimonialTitle3: 'Доступная цена',
    testimonialDescription3: 'Shoptoria работает по модели подписки, вам не нужно замораживать большую сумму в автоматизации.',
    learnMore: 'Узнать больше',
    featureTitle1: 'Удобное рабочее место официанта',
    featureText1: 'Ваши официанты, бармены или бариста должны общаться с гостями, а не с программным обеспечением. Именно поэтому мы делаем POS-систему максимально простой, быстрой и понятной.',
    feature1Slide1Title: 'Быстрая работа с заказом',
    feature1Slide1Text: 'Категориям и товарам можно присвоить фотографии, чтобы быстрее работать с заказом.',
    feature1Slide2Title: 'Модификаторы блюд',
    feature1Slide2Text: 'Для блюд можно задать модификаторы — размер, специи, добавки.',
    feature1Slide3Title: 'Смешанные оплаты',
    feature1Slide3Text: 'Заказ можно оплатить наличными или картой. Или всем вместе.',
    feature1Slide4Title: 'Обслуживайте несколько клиентов сразу',
    feature1Slide4Text: 'Создавайте, разделяйте и удаляйте столько чеков, сколько нужно.',
    featureTitle2: 'Управляйте заведением из дома',
    featureText2: 'У Shoptoria POS полнофункциональный бэк-офис в браузере. Это значит, что вы сможете контролировать работу своего заведения из любой точки мира с любого ноутбука или планшета.',
    feature2Slide1Title: 'Технологические карты',
    feature2Slide1Text: 'При продаже блюда со склада автоматически списываются ингредиенты по тех. карте.',
    feature2Slide2Title: 'Быстрая инвентаризация',
    feature2Slide2Text: 'Проводите инвентаризацию по складу и находите ошибки технологии или воровство.',
    feature2Slide3Title: 'Финансы',
    feature2Slide3Text: 'Отчеты по каждой транзакции, инкассации, закрытию и открытию кассовых смен.',
    feature2Slide4Title: 'Подробная статистика',
    feature2Slide4Text: 'Весь ваш бизнес в виде понятных показателей, наглядных таблиц и графиков.',
    title: 'Преимущества Shoptoria',
    advantages: {
      menu: {
        title: 'Меню',
        example: 'Пример меню',
        consumable: 'Расходные материалы',
        componentReplace: 'Замена компонентов',
        modifier: 'Модификаторы для ваших продуктов',
        sizes: 'Размеры продукта',
      },
      store: {
        title: 'Склад',
        control: 'Контроль цены поставок',
        onStore: 'Остатки на складе',
        supply: 'Напоминание о закупках',
        inventory: 'Удобная инвентаризация',
        cost: 'Автоматический подсчёт себестоимости',
        waste: 'Контроль списаний',
      },
      statistic: {
        title: 'Статистика',
        dashboard: 'Дэшборд с основными показателями',
        salesPerDay: 'Продажи по дням недели',
        salesPerGroup: 'Продажи по продуктам и категориям',
        payments: 'Сегментирование оплат',
        receipt: 'Продажи по чекам',
        cost: 'Себестоимость продаж',
      },
      financial: {
        title: 'Финансы',
        shifts: 'Кассовые смены',
        balance: 'Остатки на счетах',
        cashFlow: 'Cash flow',
        payroll: 'Зарплатный проект',
        transactions: 'Транзакции',
      },
      secure: {
        title: 'Безопасность',
        access: 'Пароль на «опасные» действия',
        settings: 'Настройки доступа сотрудников',
      },
    },
    docs: {
      menu: {
        subtitle: 'Простой интерфейс для управления вашим меню',
        hero: 'Добавляйте тех. карты и модификаторы, учитывайте потери при обработке, объединяйте блюда в категории – в Shoptoria это займет у вас минимум времени.',
        slide1Text1: 'Мы подготовим несколько товаров, исходя из типа Вашего заведения, так что Вы можете опробовать роботу в Терминале без траты времени на подготовку.',
        slide1Text2: 'Мы стараемся сделать как можно больше роботы за вас, что бы ваше начало роботы с Shoptoria было максимально простым!',
        slide2Text1: 'Управляйте размером ваших продуктов, контролируйте себестоимость разных вариаций продукта и выбирайте разные расходные материалы для каждого размера.',
        slide2Text2: 'Сделайте ваши продукты на столько гибкими, на сколько захотите!',
        slide3Text1: 'Организуйте меню и зарабатывайте больше с модификаторами тех. карт. Предлагайте гостям разные топпинги для пиццы, степень прожарки стейка или соусы к нему – с модификаторами официант быстро примет любой такой заказ.',
      },
      store: {
        subtitle: 'Гибкая и удобная работа со складом',
        hero: 'Следите за остатками на складе в реальном времени, проводите инвентаризацию и добавляйте поставки в удобном интерфейсе — мы продумали все до мелочей.',
        slide1Text1: 'Контролируйте ваши поставки - стоимость поставок, количество товаров, изменение себестоимости продуктов.',
        slide1Text2: 'При добавлении накладной в систему, вы будете видеть предыдущую цену закупки и отклонение от нормы.',
        slide2Text1: 'Shoptoria поддерживает все виды складских документов и отчетов: поставки, инвентаризация, списания, перемещения и т. д.',
        slide2Text2: 'Вы всегда будете знать, что происходит со складом Вашего заведения.',
        slide3Text1: 'С Shoptoria Вы полюбите делать инвентаризацию. Просто вбейте фактические остатки продуктов, блюд и полуфабрикатов — все остальное система сделает сама.',
        slide4Text1: 'Информация о расходах с ваших складов всегда под рукой. Все компоненты и рассходные материалы, которые вы добавили в рецепт продукта будут подсчитаны в удобный отчет.',
        slide4Text2: 'Вы можете планировать ваши поставки и быть всегда готовыми к новым клиентам!',
      },
      financial: {
        subtitle: 'Простой и понятный финансовый учет',
        hero: 'В Shoptoria вы легко найдете любую цифру. Например, чистую прибыль за прошлый квартал или сколько вы потратили на зарплату в марте прошлого года.',
        slide1Text1: 'Учитывайте расходы на аренду, доставку воды или на уборку помещения. У вас будет полный отчет по всем транзакциям.',
        slide1Text2: 'Сортируйте ваши транзакции по типам, контролируйте финансовые потоки и будьте уверены - Вы ничего не упустите!',
        slide2Text1: 'Контролируйте наличные деньги в кассе. Если баланс за смену не сойдется, вы легко найдете расхождения.',
      },
      secure: {
        subtitle: 'Ваши данные в безопасности',
        hero: 'Базы данных хранятся в дата-центрах уровня TIER 3 в Европе и США. Для соединения используется HTTPS.',
        slide1Text1: 'Установите пароль администратора на некоторые действия на терминале. Например, на закрытие заказа без оплаты или на просмотр отчета за смену.',
        slide1Text2: 'Единственный, у кого есть доступ к вашей бизнес информации - это Вы.',
        slide2Text1: 'Каждый ваш сотрудник имеет свой собственный пароль доступа к терминалу.',
        slide2Text2: 'Все, что ваши сотрудники делают в Терминале имеет идентификатор пользователя, поэтому Вы всегда будете знать, кто открыл кассовую смену, закрыл этот чек или добавил транзацию.'
      },
      statistic: {
        subtitle: 'Нужная статистика всегда под рукой',
        hero: 'Все данные о продажах хранятся в безопасном облаке и доступны вам в браузере, всегда и отовсюду.',
        slide1Text1: 'Анализируйте продажи по времени суток и дням недели, прогнозируйте нагрузку на сотрудников зала и кухни.',
        slide1Text2: 'Все ваши продажи сгруппированы по удобным таблицам, так что Вы можете быстро провести анализ, принять решение или ответить на интересующий вас вопрос.',
        slide2Text1: 'Держите руку на пульсе своего бизнеса всегда — в отпуске, на ходу или перед сном. Вся информация доступна в вашем смартфоне. Вся аналитика доступна в браузере, из любой точки мира с доступом в интернет.',
      },
    },
    contact: 'Контакты',
    sendMessage: 'Напишите нам сообщение или свяжитесь по телефону',
    hasQuestion: 'Остались вопросы?',
    needHelp: 'Нужна помощь?',
    navigation: 'Навигация',
    socials: 'Социальные сети',
    plan: {
      allInOne: 'Максимальные возможности в одном тарифе!',
      point: 'Кофеточка, фудтрак',
      coffee: 'Кофейня',
      cafe: 'Кафе, бар',
      restaurant: 'Ресторан',
      items: 'Количество товаров: ∞',
      menu: 'Управление меню',
      statistic: 'Аналитика и отчеты',
      statisticShort: 'Статистика за 40 дней',
      store: 'Складской учет',
      financial: 'Расчет зарплаты',
      shifts: 'Кассовые смены',
      addons: 'Модификаторы продуктов',
      loyalty: 'Система лояльности',
      promotional: 'Акции',
      booking: 'Бронирование столов',
      notify: 'Оповещение о акциях',
      try: 'Попробуйте <b>Shoptoria POS</b> уже сейчас',
      subscribe: 'Shoptoria работает по модели подписки.',
      dollar: 'долларов',
      allFromQuarantine: 'Все с <b>QUARANTINE</b>, плюс:',
      allFromLite: 'Все с <b>LITE</b>, плюс:',
      allFromBusiness: 'Все с <b>BUSINESS</b>, плюс:',
    },
    successSubmit: 'Запрос успешно отправлен!',
    faq: {
      title: 'Нужна помощь?',
      subtitle: 'Найди свой вопрос ниже',
      generalBlock: 'Общие',
      ...faq,
    },
    install: {
      link: 'Установка',
      title: 'Как установить Shoptoria POS?',
      subtitle: 'Shoptoria POS - это система, которая базируется на PWA, поэтому для установки вам нужно лишь добавить shoptoria.net на ваш главный экран.',
      subtitle2: 'Просто следуйте инструкции',
      step: 'Шаг',
      step1: 'Добавьте Shoptoria POS на главный экран.',
      step1_1: 'Для IOS нажмите на кнопку "Поделиться".',
      step2: 'Подождите окончание установки, потом перейдите на главный экран.',
      step3: 'Откройте Shoptoria POS как обычное приложение.',
      finish: 'Установка завершена!',
      finish2: 'Shoptoria POS готова к использованию',
    }
  },
  payments: {
    cantInteract: 'Вы не можете использовать приложение, пока не будет оплачен ваш тарифный план!',
    payedFor: 'Тариф оплачен до',
    subscription: 'Подписка',
    payments: 'Платежи',
    tariff: 'Тариф',
    everyMonth: 'месяц',
    contactUsLink: 'Напишите нам',
    contactUsText: 'для изменения тарифа',
    amount: 'Сумма',
    status: 'Статус',
    Refunded: 'Возвращен',
    Approved: 'Оплачен',
    Declined: 'Отклонен',
    Pending: 'В обработке',
    payForMonth: 'Оплатить 1 месяц',
    upgradeTariff: 'Обновить до Lite'
  },
  permission: {
    upgradeTariff: 'Моя подписка',
    notGrantedTitle: 'Не доступно в Вашем тарифном плане!',
    notGrantedDescription: 'Пожалуйста, выберите другую подписку чтобы воспользоваться этой функцией.',
  },
  sales: {
    quarantine: {
      teaser: 'Поддержка рестораторов во время пандемии коронавируса. Подробнее >>',
      banner: {
        title: 'в 2020 денег не берем!',
        subtitle: 'Новый тариф "Quarantine - 2020"',
        link: 'Гарантируем легкий и быстрый выход из карантина!'
      },
      help: {
        title: 'спешит на помощь!',
        content1: 'упрощает ведение ресторанного бизнеса.',
        content2: 'Мобильная POS-система решает все вопросы: онлайн-касса, управление меню и ценами, финансы, аналитика.',
        content3: 'Работает в облаке, устанавливается за 10 минут.',
        content4: 'Всего за <b>0,00$</b> в месяц, вместо 15$ до Сентября.',
        content5: 'Все функции доступны в <b>минимальном</b> тарифном плане.',
      },
      tariff: {
        title: 'В 2020 денег не берем!',
        content1: 'Новый тариф “<b>Quarantine</b>” - <b>0,00$</b> до <b>31.08.2020</b>.',
        content2: 'Специальное предложение для бизнеса <b>HoReCa</b> по быстрому и легкому выходу из карантина.',
        content3: 'Используйте все преимущества мобильной POS-системы <b>бесплатно</b> до Сентября:',
        testimonial1: 'ведите <b>цикл продаж</b>',
        testimonial2: 'просматривайте <b>отчетность</b> за день или <b>кассовую смену</b>',
        testimonial3: 'контролируйте <b>финансы</b>',
        testimonial4: 'добавляйте <b>модификаторы продуктов</b>',
        testimonial5: 'анализируйте <b>продажи и ходовые товары</b>',
        new: 'Бизнес отчеты теперь и в Telegram!',
        end: '* до 31.08.2020',
      },
      testimonials: {
        title1: 'Управление меню',
        text1: 'Меняйте цены и состав продуктов',
        title2: 'Модификаторы продуктов',
        text2: 'Зарабатывайте больше с модификаторами',
        title3: 'Аналитика прибыли',
        text3: 'Бизнес в виде понятных показателей, таблиц и графиков',
        title4: 'Кассовые смены',
        text4: 'Ваш финансовый отчет каждый день',
      },
      joinUs: 'Стань частью <b>Shoptoria POS</b> уже сейчас и пользуйся <b>бесплатно</b> до Сентября',
    }
  },
  ...tour,
};
