import {
  GET_EMPLOYERS, EDIT_EMPLOYER, ADD_EMPLOYER, REMOVE_EMPLOYER, GET_JOBS, EDIT_JOB, ADD_JOB, REMOVE_JOB,
} from './actions';

const initialState = {
  employers: [],
  employersLoaded: false,
  jobs: [],
};

function accessReducer(state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYERS:
      return {
        ...state,
        employers: action.payload,
        employersLoaded: true,
      };
    case EDIT_EMPLOYER: return {
      ...state,
      employers: state.employers.map(el => (el.id === action.payload.id ? action.payload : el)),
    };
    case ADD_EMPLOYER:
      return {
        ...state,
        employers: [...state.employers, action.payload],
      };
    case REMOVE_EMPLOYER:
      return {
        ...state,
        employers: state.employers.filter(el => el.id !== action.payload),
      };
    case GET_JOBS:
      return {
        ...state,
        jobs: action.payload,
      };
    case EDIT_JOB: return {
      ...state,
      jobs: state.jobs.map(el => (el.id === action.payload.id ? action.payload : el)),
    };
    case ADD_JOB:
      return {
        ...state,
        jobs: [...state.jobs, action.payload],
      };
    case REMOVE_JOB:
      return {
        ...state,
        jobs: state.jobs.filter(el => el.id !== action.payload),
      };
    case 'CLEAN_REDUCERS': {
      return initialState;
    }
    default: return state;
  }
}

export default accessReducer;
