import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { CaretDownOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import moment from 'moment';
import {
  Content, Page, PageHeader, Row, TopBar,
} from '../../../components';
import AddInventory from './AddInventory';
import InventoryTable from './InventoryTable';
import EditInventory from './EditInventory';
import { deleteModal } from '../../../utils/modals';
import { notificationTypes, showNetworkError } from '../../../redux/message/actions';
import { printPage } from '../../../utils/helpers';
import { isOnline } from '../../../persistance';

export const calculateSubtotal = item => parseInt(item.price || 0, 10) * parseInt(item.count || 0, 10);

class Inventory extends Component {
  constructor(props) {
    super(props);
    const { inventoriesLoaded, dataLoaded } = props;

    this.state = {
      selectedInventory: null,
      loadingStarted: false,
    };

    if (!inventoriesLoaded && dataLoaded) {
      this.loadData();
    }
  }

  loadData = () => {
    if (!isOnline()) return showNetworkError();
    const { actions: { getInventory } } = this.props;
    const { loadingStarted } = this.state;
    if (!loadingStarted) {
      this.setState({ loadingStarted: true });
      getInventory();
    }
  }

  calculateTotal = items => items.reduce((sum, item) => sum + parseInt(item.price, 10), 0);

  selectInventory = (selectedInventory) => {
    this.setState({ selectedInventory });
    this.editInventoryModal.show();
  };

  addInventory = () => {
    if (!isOnline()) return showNetworkError();
    const { t, canInteract, actions: { showMessage } } = this.props;
    if (canInteract) {
      this.addInventoryModal.show();
    } else {
      showMessage({ type: notificationTypes.ERROR, text: t('payments.cantInteract') });
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { inventoriesLoaded, dataLoaded } = nextProps;

    if (!inventoriesLoaded && dataLoaded) {
      this.loadData();
    }
  }

  render() {
    const {
      t, inventories, menu, equipment, actions: { removeInventory }, inventoriesLoaded,
    } = this.props;
    const { selectedInventory } = this.state;
    const allIdMap = { ...menu.idMap, ...equipment.idMap };
    const supplyColumns = [
      {
        title: t('supply.date'),
        dataIndex: 'date',
        sorter: (a, b) => {
          const momentA = moment(`${a.date} ${a.time}`, 'DD_MM_YYYY HH:mm');
          const momentB = moment(`${b.date} ${b.time}`, 'DD_MM_YYYY HH:mm');
          return momentA.isBefore(momentB) ? -1 : 1;
        },
        sortDirections: ['descend'],
        defaultSortOrder: 'descend',
        render: (text, record) => `${text.replace(/_/g, '.')} ${record.time}`,
      }, {
        title: t('supply.comment'),
        dataIndex: 'comment',
      }, {
        title: '',
        width: '30px',
        render: (text, record) => (
          <Row width="100%" justifyContent="center">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                if (!isOnline()) return showNetworkError();
                this.selectInventory(record);
              }}
              type="link"
              icon={<EditOutlined />}
            />
          </Row>
        ),
      }, {
        title: '',
        dataIndex: 'action',
        width: '50px',
        render: (text, record) => (
          <DeleteOutlined
            onClick={(e) => {
              e.stopPropagation();
              deleteModal(() => removeInventory(record.id));
            }}
          />
        ),
      },
    ];
    return (
      <Page spinning={!inventoriesLoaded && isOnline()}>
        <PageHeader />
        <Content>
          <TopBar data-tour="inventory-tour-2">
            <h3 className="storage-page-title">{t('storage.inventory')}: {inventories.length}</h3>
            <Button
              className="no-print right-margin"
              size="large"
              onClick={() => printPage()}
              type="secondary"
            >{t('control.print')}
            </Button>
            <Button className="add-button" size="large" onClick={this.addInventory}>{t('control.add')}</Button>
          </TopBar>
          <Table
            bordered
            pagination={false}
            size="default"
            className="storage-page-table"
            columns={supplyColumns}
            expandRowByClick
            expandIcon={
              ({ expanded }) => <CaretDownOutlined className={`table-icon ${expanded ? 'rotate' : ''}`} />
            }
            expandedRowRender={r => (
              <InventoryTable
                items={r.items.filter(el => allIdMap[el.item]).map(el => ({ ...el, name: allIdMap[el.item].name }))}
              />
            )}
            dataSource={inventories.map((el, i) => ({ ...el, key: i }))}
          />
        </Content>
        <AddInventory ref={(ref) => { this.addInventoryModal = ref; }} />
        <EditInventory inventory={selectedInventory} ref={(ref) => { this.editInventoryModal = ref; }} />
      </Page>
    );
  }
}

export default withTranslation()(Inventory);
