import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { showMessage } from '../../../redux/message/actions';
import { getInventory, removeInventory } from '../../../redux/storage/actions';
import Inventory from './Inventory';

function mapStateToProps(state) {
  return {
    menu: state.menu,
    equipment: state.equipment,
    inventoriesLoaded: state.storage.inventoriesLoaded,
    inventories: state.storage.inventories,
    dataLoaded: state.business.dataLoaded,
    canInteract: state.business.canInteract,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        removeInventory,
        getInventory,
      },
      dispatch,
    ),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Inventory));
