import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { showMessage } from '../../../redux/message/actions';
import { removeConsumable } from '../../../redux/equipment/actions';
import Consumables from './Consumables';

function mapStateToProps(state) {
  return {
    menu: state.menu,
    equipment: state.equipment,
    currency: state.business.currency,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        removeConsumable,
      },
      dispatch,
    ),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Consumables));
