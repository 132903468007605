import React, { Component } from 'react';
import {
  Tag,
} from 'antd';
import { getSizeName } from '../utils/helpers';
import { Row } from '../components/Grid';

const { CheckableTag } = Tag;
export const sizesList = ['S', 'M', 'L', 'XL', 'XXL'];

class Sizes extends Component {
  constructor(props) {
    super(props);
    const { tags = ['S', 'M', 'L'] } = props;
    this.state = {
      tags: sizesList,
      selectedItems: tags,
    };
  }

  toggleComponent = (tag) => {
    const { callback, canBeModified = true } = this.props;
    const { selectedItems } = this.state;
    if (!canBeModified) return callback(selectedItems);
    const newTags = selectedItems.includes(tag)
      ? selectedItems.filter(el => el !== tag)
      : [...selectedItems, tag];
    this.setState({ selectedItems: newTags });
    callback(newTags);
  };

  render() {
    const { tags, selectedItems } = this.state;
    return (
      <Row flexWrap="wrap" margin="0" alignItems="flex-start" justifyContent="flex-start">
        {tags.map(tag => (
          <CheckableTag
            key={`components-${JSON.stringify(tag)}`}
            className="create-product-tag"
            onChange={() => this.toggleComponent(tag)}
            checked={selectedItems.includes(tag)}
          >
            {getSizeName(tag)}
          </CheckableTag>
        ))}
      </Row>
    );
  }
}
export default Sizes;
