import axios from '../index';

// User flow

export const getAppInfoRequest = () => axios.get('/app/info');

export const getAppMigrationRequest = () => axios.get('/app/migration');

export const setAppMigrationRequest = data => axios.put('/app/migration', data);

export const getAppUpdatesRequest = () => axios.get('/app/updates');
