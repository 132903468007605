import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { showMessage } from '../../../redux/message/actions';
import Clients from './Clients';
import { removeClient } from '../../../redux/marketing/actions';

function mapStateToProps(state) {
  return {
    clients: state.marketing.clients,
    groups: state.marketing.groups,
    currency: state.business.currency,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        removeClient,
      },
      dispatch,
    ),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Clients));
