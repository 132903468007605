import { subscriptionTourSteps } from './steps';

export const getSubscriptionTours = (t, goToNextStep, lastStepEvent) => [
  {
    pageRegExp: /\w/g,
    id: 3,
    isComplete: false,
    redirect: '/app/settings/subscription',
    title: t('reactour.subscription.title'),
    beforeEnter: () => {
      const layoutSelector = document.querySelector('[data-tour="settings-tour-0"]');
      if (layoutSelector.classList.contains('active-parent')) {
        layoutSelector.click();
      }
    },
    steps: subscriptionTourSteps(t, goToNextStep, lastStepEvent),
  },
];
