import React from 'react';
import { getMenuTours } from './menu';
import { getEquipmentTours } from './equipments';
import { getComponentTours } from './components';
import { getSupplyTours } from './supply';
import { getWasteTours } from './waste';
import { getInventoryTours } from './inventory';
import { getSubscriptionTours } from './subscription';
import { getConsumptionTours } from './consumption';
import { getFinancialAccountsTours } from './financialAccounts';
import { getFinancialCategoriesTours } from './financialCategories';
import { getFinancialShiftsTours } from './financialShifts';
import { getFinancialTransactionsTours } from './financialTransactions';
import { getEmployersTours } from './employers';
import { getJobsTours } from './jobs';
import { getFirstViewTour } from './firstView';
import { Row } from '../../index';
import '../style.scss';
/* eslint-disable */
export const StepInfo = ({
  title, subtitle, text, buttonText, buttonAction, skipText = null, skipAction = () => {},
}) => (
  <div className="tour-guide-step-wrapper">
    {title && <h2>{title}</h2>}
    {subtitle && <h3>{subtitle}</h3>}
    {text && <p dangerouslySetInnerHTML={{ __html: text }} />}
    <Row width="100%" alignItems="center" justifyContent="space-between">
      {skipText && (
        <button className="tour-guide-skip-button" type="button" onClick={skipAction}>{skipText}</button>
      )}
      {buttonText && (
        <button className="tour-guide-next-button" type="button" onClick={buttonAction}>{buttonText}</button>
      )}
    </Row>
  </div>
);

export {
  getMenuTours,
  getEquipmentTours,
  getComponentTours,
  getSupplyTours,
  getWasteTours,
  getInventoryTours,
  getSubscriptionTours,
  getConsumptionTours,
  getFinancialAccountsTours,
  getFinancialCategoriesTours,
  getFinancialShiftsTours,
  getFinancialTransactionsTours,
  getEmployersTours,
  getJobsTours,
  getFirstViewTour,
};
