import { generateId } from '../../../utils/helpers';
import { ADD_STORE_ITEM, REMOVE_STORE_ITEM } from '../../storage/actions';
import {
  addComponentRequest, editComponentRequest, getComponentsDataRequest, removeComponentRequest,
} from '../../../api/db/menu';
import { showErrorMessage, showMessage } from '../../message/actions';
import i18n from '../../../utils/translation';
import {
  getPersistedMenuComponents, isOnline, persist, removeDataFromPersist,
} from '../../../persistance';
import { idbMenuComponents } from '../../../config';

export const GET_COMPONENTS = 'GET_COMPONENTS';
export const ADD_COMPONENT = 'ADD_COMPONENT';
export const EDIT_COMPONENT = 'EDIT_COMPONENT';
export const REMOVE_COMPONENT = 'REMOVE_COMPONENT';

export function getComponents(persisted = false) {
  return (dispatch) => {
    if (persisted || !isOnline()) {
      return getPersistedMenuComponents()
        .then(data => dispatch({ type: GET_COMPONENTS, payload: data.map(el => ({ ...el, type: 'component' })) }));
    }
    return getComponentsDataRequest()
      .then(res => dispatch({ type: GET_COMPONENTS, payload: res.data.map(el => ({ ...el, type: 'component' })) }))
      .catch(showErrorMessage);
  };
}

export function createComponent({ name, units, ...props }) {
  return (dispatch) => {
    const id = generateId();
    const component = {
      name, units, type: 'component', replacer: [], ...props,
    };

    return addComponentRequest(id, component)
      .then((res) => {
        dispatch({ type: ADD_COMPONENT, payload: ({ ...res.data, id, type: 'component' }) });

        dispatch({
          type: ADD_STORE_ITEM,
          payload: {
            type: 'component', count: 0, id,
          },
        });

        persist({ ...res.data, id, type: 'component' }, idbMenuComponents);

        dispatch(showMessage({ text: i18n.t('actions.menuComponentCreatedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function editComponent({ id, ...props }) {
  return (dispatch) => {
    const newComponent = { ...props };

    delete newComponent.type;
    delete newComponent.key;
    delete newComponent.id;

    return editComponentRequest(id, newComponent)
      .then((res) => {
        dispatch({ type: EDIT_COMPONENT, payload: ({ ...res.data, id, type: 'component' }) });

        persist({ ...res.data, id, type: 'component' }, idbMenuComponents);

        dispatch(showMessage({ text: i18n.t('actions.menuComponentEditedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function removeComponent(item) {
  return dispatch => removeComponentRequest(item.id)
    .then(() => {
      dispatch({ type: REMOVE_COMPONENT, payload: item.id });

      dispatch({ type: REMOVE_STORE_ITEM, payload: item.id });

      removeDataFromPersist(item.id, idbMenuComponents);

      dispatch(showMessage({ text: i18n.t('actions.menuComponentDeletedTitle') }));
    })
    .catch(showErrorMessage);
}
