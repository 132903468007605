import React, { Component } from 'react';
import { toggleOverscroll } from '../utils/helpers';

function wrapInModal(ChildrenComponent) {
  class ModalHOC extends Component {
    constructor(props) {
      super(props);
      this.state = { visible: false };
    }

    show = () => {
      toggleOverscroll(false);
      this.setState({ visible: true });
    };

    hide = () => {
      toggleOverscroll(true);
      this.setState({ visible: false });
    };

    render() {
      const { visible } = this.state;
      return visible ? <ChildrenComponent {...this.props} visible close={this.hide} /> : <div />;
    }
  }
  return ModalHOC;
}

export default wrapInModal;
