import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { showMessage } from '../../../redux/message/actions';
import { getResolvedBills } from '../../../redux/statistic/actions';

import Bills from './Bills';

function mapStateToProps(state) {
  return {
    resolvedBills: state.statistic.resolvedBills,
    menu: state.menu,
    employers: state.access.employers,
    equipment: state.equipment,
    activePlace: state.business.activePlace,
    currency: state.business.currency,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        getResolvedBills,
      },
      dispatch,
    ),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Bills));
