import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Button } from 'antd';
import moment from 'moment';
import { Column, Row } from '../index';
import { cleanAllPersistedCollection, isOnline } from '../../persistance';
import { showNetworkError } from '../../redux/message/actions';

class UpdateVersion extends Component {
  resetCaches = () => {
    if (!isOnline()) return showNetworkError();
    cleanAllPersistedCollection().then(() => {
      if ('serviceWorker' in navigator) {
        console.log('Navigator exist');
        caches.keys()
          .then((cacheNames) => {
            console.log(cacheNames);
            return Promise.all(cacheNames.map((cacheName) => {
              console.log(cacheName);
              return caches.delete(cacheName);
            }))
              .then(() => {
                navigator.serviceWorker.ready
                  .then((registration) => {
                    registration.unregister();
                    window.location.reload(true);
                  })
                  .catch((error) => {
                    console.error('Can`t unregister SW!');
                    console.error(error.message);
                  });
              });
          });
      } else {
        console.log('Navigator not exist');
        window.location.reload(true);
      }
    });
  };

  render() {
    const {
      t, user: {
        version, newVersion, isActualVersion, releaseDate,
      },
    } = this.props;
    return (
      <Fragment>
        {!isActualVersion && (
          <Column
            style={{ padding: '15px', maxWidth: '600px' }}
            className="today subscription"
            width="100%"
            margin="0 0 10px"
          >
            <Row width="100%" flexWrap="wrap">
              <h2 className="update-title">{t('settings.newVersionAvailable')}</h2>
              <Button
                onClick={this.resetCaches}
                style={{ width: 109 }}
                color="white"
                size="large"
                type="primary"
              >
                {t('control.update')}
              </Button>
            </Row>
            <Row width="100%" margin="20px 0 0" flexWrap="wrap">
              <Column className="mobile-full-width">
                <span>{t('settings.currentVersion')}</span>
                <h3>{version}</h3>
              </Column>
              <Column className="mobile-full-width">
                <span>{t('settings.newVersion')}</span>
                <h3>{newVersion}</h3>
              </Column>
              <Column className="mobile-full-width">
                <span>{t('settings.releaseDate')}</span>
                <h3>{moment(releaseDate, 'DD_MM_YYYY').format('DD.MM.YYYY')}</h3>
              </Column>
            </Row>
          </Column>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(withTranslation()(UpdateVersion));
