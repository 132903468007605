import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import { getDocTabs } from '../../Landing/components';
import './style.scss';


class DocsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    const tabs = getDocTabs(t);
    return (
      <div className="docs-tab-wrapper">
        <div className="docs-tab">
          {tabs.map(tab => (
            <div key={tab.link} className={`docs-tab-cell ${window.location.pathname === tab.link ? 'active' : ''}`}>
              <HashLink smooth to={tab.link}>{tab.name}</HashLink>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default withTranslation()(DocsTab);
