import moment from 'moment';
import { generateId } from '../../utils/helpers';
import { ADD_TRANSACTION, getFinancialAccounts, getTransactionsDelta } from '../financial/actions';
import { WASTE } from '../../constants/transactions';
import i18 from '../../utils/translation';
import { getPlaceRef } from '../business/actions';
import { getPersistedShiftsData, isOnline, persistShifts } from '../../persistance';
import { notificationTypes, showErrorMessage, showMessage } from '../message/actions';
import { getShiftsFromTimeRange } from './helpers';
import {
  addCashShiftRequest,
  closeCashShiftRequest,
  getTodayShiftsRequest,
  updateCashShiftRequest,
} from '../../api/db/shifts';
import { cleanActiveBills } from '../statistic/actions';

export const GET_CASH_SHIFT = 'GET_CASH_SHIFT';
export const GET_TODAY_CASH_SHIFT = 'GET_TODAY_CASH_SHIFT';
export const ADD_CASH_SHIFT = 'ADD_CASH_SHIFT';
export const OPEN_CASH_SHIFT = 'OPEN_CASH_SHIFT';
export const ADD_CASH_SHIFT_DATA = 'ADD_CASH_SHIFT_DATA';

export function getCashShifts(persisted = false) {
  return async (dispatch) => {
    if (persisted || !isOnline()) {
      return getPersistedShiftsData()
        .then(data => dispatch({
          type: GET_TODAY_CASH_SHIFT, payload: data.map(el => ({ ...el, id: parseInt(el.id, 10) })),
        }));
    }
    getShiftsFromTimeRange(moment().subtract(1, 'week'))
      .then((shifts) => {
        shifts.map(el => persistShifts(el));
        return dispatch({ type: GET_CASH_SHIFT, payload: shifts });
      })
      .catch(showErrorMessage);

    return getTodayShiftsRequest()
      .then(res => dispatch({ type: GET_TODAY_CASH_SHIFT, payload: res.data }))
      .catch(showErrorMessage);
  };
}

export function openCashShift({ openCount, employer, history }) {
  return async (dispatch, getState) => {
    const placeRef = await dispatch(getPlaceRef());
    const { shift: { activeShift }, financial: { accounts } } = getState();

    if (activeShift) {
      return;
    }
    const id = generateId();
    const newShift = {
      startTime: Date.now(), isOpen: true, openBy: employer.id, transactions: [], openCount, closeCount: 0, id,
    };

    history.push('/app/keeper');

    // Greeting message for user
    dispatch(showMessage({
      text: `${i18.t('keeper.hi')} ${employer.name}!`,
      type: notificationTypes.INFO,
      description: i18.t('keeper.openCashShift'),
    }));

    // Add exchange transaction to redux
    dispatch({
      type: ADD_TRANSACTION,
      payload: {
        category: i18.t('financial.exchange'),
        date: moment().format('DD.MM.YYYY'),
        type: WASTE,
        to: '',
        from: accounts.find(el => el.id === 1),
        comment: '',
        wasteCount: openCount,
        incomeCount: '',
        id,
      },
    });

    dispatch({ type: OPEN_CASH_SHIFT, payload: { ...newShift, id } });

    if (!isOnline()) {
      persistShifts({ ...newShift, place: placeRef, id });
      return;
    }

    return addCashShiftRequest(id, newShift)
      .then((res) => {
        persistShifts({ ...res.data, place: placeRef });
      })
      .catch(showErrorMessage);
  };
}

export function addCashShiftData(data) {
  return async (dispatch, getState) => {
    const placeRef = await dispatch(getPlaceRef());

    const { shift: { activeShift } } = getState();

    const newShift = { ...activeShift, ...data };

    return updateCashShiftRequest(newShift.id, moment(activeShift.startTime).format('DD_MM_YYYY'), newShift)
      .then((res) => {
        dispatch({ type: ADD_CASH_SHIFT_DATA, payload: res.data });

        persistShifts({ ...newShift, place: placeRef });
      })
      .catch(showErrorMessage);
  };
}

export function addCashShift({ endTime, employer, closeCount }) {
  return async (dispatch, getState) => {
    const placeRef = await dispatch(getPlaceRef());

    const { shift: { cashShifts, activeShift } } = getState();
    const shiftData = {
      ...activeShift, endTime, closeBy: employer.id, isOpen: false, closeCount,
    };

    persistShifts({ ...shiftData, place: placeRef });

    dispatch({
      type: ADD_CASH_SHIFT,
      payload: [...cashShifts.filter(shift => !shift.isOpen), shiftData],
    });
    // TODO: Add get transactions and account request
    if (isOnline()) {
      await closeCashShiftRequest(activeShift.id, moment(activeShift.startTime).format('DD_MM_YYYY'), shiftData)
        .then(() => {
          dispatch(cleanActiveBills());

          setTimeout(() => {
            dispatch(getFinancialAccounts());
            dispatch(getTransactionsDelta(10));
          }, 20000);
        })
        .catch(showErrorMessage);
    }
  };
}
