import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { showMessage } from '../../../redux/message/actions';
import { getSupply, removeSupply } from '../../../redux/storage/actions';
import Supply from './Supply';

function mapStateToProps(state) {
  return {
    menu: state.menu,
    supplyLoaded: state.storage.supplyLoaded,
    supply: state.storage.supply,
    suppliers: state.storage.suppliers,
    equipment: state.equipment,
    currency: state.business.currency,
    dataLoaded: state.user.dataLoaded,
    canInteract: state.business.canInteract,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        removeSupply,
        getSupply,
      },
      dispatch,
    ),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Supply));
