import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  createEquipment, removeEquipment, editEquipment,
} from '../../../redux/equipment/actions';
import Kit from './Kits';

function mapStateToProps(state) {
  return {
    equipment: state.equipment,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        createEquipment,
        removeEquipment,
        editEquipment,
      },
      dispatch,
    ),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Kit));
