import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { changePlace } from '../../redux/user/actions';
import { addPlace } from '../../redux/business/actions';
import ModalHOC from '../../common/ModalHOC';
import ManagePlaces from './ManagePlaces';

function mapStateToProps(state) {
  return {
    activePlace: state.business.activePlace,
    places: state.business.places,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        changePlace,
        addPlace,
      },
      dispatch,
    ),
  };
}

export default ModalHOC(connect(mapStateToProps, mapDispatchToProps)(ManagePlaces));
