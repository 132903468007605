import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Checkbox, Input, Modal, Radio,
} from 'antd';
import { ModalHOC, NumberInput } from '../../../common';
import { notificationTypes, showMessage } from '../../../redux/message/actions';
import { editJob } from '../../../redux/access/actions';
import { Column, Row } from '../../../components';
import { validateNumberInput } from '../../../utils/helpers';

class EditJob extends Component {
  constructor(props) {
    super(props);
    const {
      job: {
        salaryPerShift, salaryPerMonth, flexRatePersonal, flexRateTotal,
      },
    } = props;
    this.state = {
      name: '',
      salaryPerShift: '',
      salaryPerMonth: '',
      flexRatePersonal: '',
      flexRateTotal: '',
      allowSalaryPerShift: salaryPerShift !== '',
      allowSalaryPerMonth: salaryPerMonth !== '',
      allowFlexRatePersonal: flexRatePersonal !== '',
      allowFlexRateTotal: flexRateTotal !== '',
      ...props.job,
    };
  }

  toggleRate = key => this.setState(state => ({ [key]: !state[key] }));

  create = () => {
    const {
      job, t, close, actions: { editJob, showMessage },
    } = this.props;
    const {
      name, salaryPerShift, salaryPerMonth, flexRatePersonal, flexRateTotal,
      allowSalaryPerShift, allowSalaryPerMonth, allowFlexRatePersonal, allowFlexRateTotal,
    } = this.state;
    const isFormEmpty = salaryPerShift.toString().trim() === '' && salaryPerMonth.toString().trim() === ''
      && flexRatePersonal.toString().trim() === '' && flexRateTotal.toString().trim() === '';
    const atLeastOneTypeChecked = allowSalaryPerShift || allowSalaryPerMonth || allowFlexRatePersonal
      || allowFlexRateTotal;
    const isValueGreaterThatZero = (salaryPerShift.toString().trim() !== '' && parseInt(salaryPerShift, 10) < 1)
      || (salaryPerMonth.toString().trim() !== '' && parseInt(salaryPerMonth, 10) < 1)
      || (flexRatePersonal.toString().trim() !== '' && parseInt(flexRatePersonal, 10) < 1)
      || (flexRateTotal.toString().trim() !== '' && parseInt(flexRateTotal, 10) < 1);
    if (name.trim() === '') {
      showMessage({ text: t('validation.emptyName'), type: notificationTypes.ERROR });
      return;
    }
    if (isFormEmpty) {
      showMessage({ text: t('validation.emptySalary'), type: notificationTypes.ERROR });
      return;
    }
    if (!atLeastOneTypeChecked) {
      showMessage({ text: t('validation.emptySalary'), type: notificationTypes.ERROR });
      return;
    }
    if (isValueGreaterThatZero) {
      showMessage({ text: t('validation.zeroValue'), type: notificationTypes.ERROR });
      return;
    }
    editJob({
      ...job,
      name,
      salaryPerShift: allowSalaryPerShift ? parseInt(salaryPerShift, 10) : '',
      salaryPerMonth: allowSalaryPerMonth ? parseInt(salaryPerMonth, 10) : '',
      flexRatePersonal: allowFlexRatePersonal ? parseInt(flexRatePersonal, 10) : '',
      flexRateTotal: allowFlexRateTotal ? parseInt(flexRateTotal, 10) : '',
    });
    close();
  };

  render() {
    const {
      t, close, visible, business,
    } = this.props;
    const {
      name, salaryPerShift, salaryPerMonth, flexRatePersonal, flexRateTotal,
      allowSalaryPerShift, allowSalaryPerMonth, allowFlexRatePersonal, allowFlexRateTotal,
    } = this.state;
    return (
      <Modal
        maskClosable={false}
        centered
        visible={visible}
        onOk={this.create}
        onCancel={close}
        width="500px"
        okText={t('control.save')}
        okButtonProps={{ size: 'large' }}
        cancelButtonProps={{ size: 'large' }}
        cancelText={t('control.cancel')}
        title={t('job.editJob')}
        className="add-job"
      >
        <Column width="100%" alignItems="flex-start">
          <Column width="100%" className="default-card">
            <h3 style={{ margin: '0 0 5px' }}>{t('job.jobName')}</h3>
            <Input
              style={{ width: '100%' }}
              value={name}
              placeholder={`${t('job.jobName')}...`}
              onChange={e => this.setState({ name: e.target.value })}
            />
          </Column>
          <Column width="100%" className="default-card" margin="12px 0 0">
            <h2 style={{
              width: '100%', margin: '10px 0 0', borderBottom: '1px solid rgba(0, 0, 0, 0.65)', paddingBottom: '5px',
            }}
            >
              {t('job.salaryCalculation')}
            </h2>
            <Column width="100%" alignItems="flex-start">
              <h3 style={{ margin: '10px 0 5px' }}>{t('job.fixedRate')}</h3>
              <Radio.Group
                value={allowSalaryPerShift ? 1 : allowSalaryPerMonth ? 2 : 0}
                style={{ width: '100%' }}
                size="large"
                name="salary"
              >
                <Row width="100%" justifyContent="flex-start" margin="10px 0 5px">
                  <Radio
                    value={1}
                    onChange={() => this.setState({
                      allowSalaryPerMonth: false, allowSalaryPerShift: true, salaryPerMonth: '',
                    })}
                  />
                  <h4 className="salary-point">{t('job.salaryPerShift')}</h4>
                  <NumberInput
                    type="tel"
                    style={{ width: '100%', maxWidth: '200px', marginLeft: '10px' }}
                    placeholder=""
                    controlled
                    onKeyUp={validateNumberInput}
                    onChange={e => this.setState({ salaryPerShift: e.target.value })}
                    value={salaryPerShift}
                    disabled={!allowSalaryPerShift}
                    addonAfter={business.currency}
                  />
                </Row>
                <Row width="100%" justifyContent="flex-start" margin="10px 0 5px">
                  <Radio
                    value={2}
                    onChange={() => this.setState({
                      allowSalaryPerMonth: true, allowSalaryPerShift: false, salaryPerShift: '',
                    })}
                  />
                  <h4 className="salary-point">{t('job.salaryPerMonth')}</h4>
                  <NumberInput
                    type="tel"
                    style={{ width: '100%', maxWidth: '200px', marginLeft: '10px' }}
                    placeholder=""
                    controlled
                    onKeyUp={validateNumberInput}
                    onChange={e => this.setState({ salaryPerMonth: e.target.value })}
                    value={salaryPerMonth}
                    disabled={!allowSalaryPerMonth}
                    addonAfter={business.currency}
                  />
                </Row>
              </Radio.Group>
              <h3 style={{ margin: '10px 0 5px' }}>{t('job.flexRate')}</h3>
              <Row width="100%" justifyContent="flex-start" margin="10px 0 5px">
                <Checkbox checked={allowFlexRatePersonal} onChange={() => this.toggleRate('allowFlexRatePersonal')} />
                <h4 className="salary-point">{t('job.flexRatePersonal')}</h4>
                <NumberInput
                  type="tel"
                  style={{ width: '100%', maxWidth: '200px', marginLeft: '10px' }}
                  placeholder=""
                  onKeyUp={validateNumberInput}
                  onChange={e => this.setState({ flexRatePersonal: e.target.value })}
                  value={flexRatePersonal}
                  disabled={!allowFlexRatePersonal}
                  addonAfter="%"
                />
              </Row>
              <Row width="100%" justifyContent="flex-start" margin="10px 0 5px">
                <Checkbox checked={allowFlexRateTotal} onChange={() => this.toggleRate('allowFlexRateTotal')} />
                <h4 className="salary-point">{t('job.flexRateTotal')}</h4>
                <NumberInput
                  type="tel"
                  style={{ width: '100%', maxWidth: '200px', marginLeft: '10px' }}
                  placeholder=""
                  onKeyUp={validateNumberInput}
                  onChange={e => this.setState({ flexRateTotal: e.target.value })}
                  value={flexRateTotal}
                  disabled={!allowFlexRateTotal}
                  addonAfter="%"
                />
              </Row>
            </Column>
          </Column>
        </Column>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    business: state.business,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        editJob,
      },
      dispatch,
    ),
  };
}
export default ModalHOC(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditJob)));
