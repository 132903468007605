import React, { Component } from 'react';
import { Button } from 'antd';
import {
  Column, UpdateVersion, PageHeader, Content, TopBar, Page,
} from '../../../components';
import { cleanAllPersistedCollection } from '../../../persistance';

class DevCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  resetCaches = () => {
    const { actions: { refetchData } } = this.props;
    cleanAllPersistedCollection().then(() => {
      refetchData();
    });
  }

  resetFiles = () => {
    cleanAllPersistedCollection().then(() => {
      if ('serviceWorker' in navigator) {
        console.log('Navigator exist');
        caches.keys()
          .then((cacheNames) => {
            console.log(cacheNames);
            return Promise.all(cacheNames.map((cacheName) => {
              console.log(cacheName);
              return caches.delete(cacheName);
            }))
              .then(() => {
                navigator.serviceWorker.ready
                  .then((registration) => {
                    registration.unregister();
                    window.location.reload(true);
                  })
                  .catch((error) => {
                    console.error('Can`t unregister SW!');
                    console.error(error.message);
                  });
              });
          });
      } else {
        console.log('Navigator not exist');
        window.location.reload(true);
      }
    });
  };

  render() {
    const {
      user: { version },
    } = this.props;
    return (
      <Page>
        <PageHeader />
        <Content>
          <TopBar>
            <h3 className="storage-page-title">Dev Center</h3>
          </TopBar>
          <UpdateVersion />
          <Column
            className="mobile-block default-card"
            style={{ maxWidth: '600px' }}
            width="100%"
            margin="10px 0"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Button type="primary" size="large" onClick={this.resetFiles} style={{ marginBottom: 12 }}>
              Clean local files
            </Button>

            <Button type="primary" size="large" onClick={this.resetCaches}>
              Clean Caches
            </Button>
            <p className="settings-text">Version: <span>#{version}@{process.env.REACT_APP_MINOR_VERSION}</span></p>
          </Column>
        </Content>
      </Page>
    );
  }
}

export default DevCenter;
