import React from 'react';
import { StepInfo } from '../index';

export const menuTourSteps = (t, goToNextStep, lastStepEvent) => [
  {
    selector: '[data-tour="menu-tour-1"]',
    stepInteraction: false,
    content: () => (
      <StepInfo
        title={t('reactour.menu.step1.title')}
        text={t('reactour.menu.step1.text')}
        buttonText={t('reactour.menu.step1.buttonText')}
        buttonAction={() => {
          const openDrawerButton = document.getElementById('menu-drawer-button');
          if (openDrawerButton) {
            openDrawerButton.click();
          }
          setTimeout(() => goToNextStep(false), 200);
        }}
      />
    ),
  }, {
    selector: '[data-tour="menu-tour-2"]',
    stepInteraction: false,
    position: 'bottom',
    content: () => (
      <StepInfo
        title={t('reactour.menu.step2.title')}
        text={t('reactour.menu.step2.text')}
        buttonText={t('reactour.menu.step2.buttonText')}
        buttonAction={() => goToNextStep(false)}
      />
    ),
  }, {
    selector: '[data-tour="menu-tour-2-1"]',
    stepInteraction: false,
    position: 'bottom',
    content: () => (
      <StepInfo
        title={t('reactour.menu.step21.title')}
        text={t('reactour.menu.step21.text')}
        buttonText={t('reactour.menu.step21.buttonText')}
        buttonAction={() => {
          const openModalButton = document.getElementById('menu-receipt');
          if (openModalButton) {
            openModalButton.click();
          }
          setTimeout(() => goToNextStep(false), 300);
        }}
      />
    ),
  }, {
    selector: '[data-tour="menu-tour-3"]',
    stepInteraction: false,
    position: 'bottom',
    content: () => (
      <StepInfo
        title={t('reactour.menu.step3.title')}
        text={t('reactour.menu.step3.text')}
        buttonText={t('reactour.menu.step3.buttonText')}
        buttonAction={() => goToNextStep(false)}
      />
    ),
  }, {
    selector: '[data-tour="menu-tour-4"]',
    stepInteraction: false,
    position: 'bottom',
    content: () => (
      <StepInfo
        title={t('reactour.menu.step4.title')}
        text={t('reactour.menu.step4.text')}
        buttonText={t('reactour.menu.step4.buttonText')}
        buttonAction={() => goToNextStep(false)}
      />
    ),
  }, {
    selector: '[data-tour="menu-tour-5"]',
    stepInteraction: false,
    position: 'bottom',
    content: () => (
      <StepInfo
        title={t('reactour.menu.step5.title')}
        text={t('reactour.menu.step5.text')}
        buttonText={t('reactour.menu.step5.buttonText')}
        buttonAction={() => {
          const isModalVisible = document.getElementsByClassName('ant-modal-close-x');
          if (isModalVisible[0]) {
            isModalVisible[0].click();
          }
          setTimeout(() => goToNextStep(false), 200);
        }}
      />
    ),
  }, {
    selector: '.ant-tabs-nav-wrap',
    stepInteraction: false,
    content: () => (
      <StepInfo
        title={t('reactour.menu.step6.title')}
        text={t('reactour.menu.step6.text')}
        buttonText={t('reactour.menu.step6.buttonText')}
        buttonAction={() => lastStepEvent()}
      />
    ),
  },
];
