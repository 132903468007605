import React, { Component } from 'react';
import { Select } from 'antd';
import { withTranslation } from 'react-i18next';
import { getUnits, validateNumberInput } from '../utils/helpers';
import { NumberInput } from './index';

const { Option } = Select;

const getUnitsByType = (type) => {
  switch (type) {
    case 'g': return ['g', 'kg'];
    case 'ml': return ['ml', 'l'];
    default: return ['pc'];
  }
};

class StoreItemCountInput extends Component {
  constructor(props) {
    super(props);

    const { units, value = '' } = props;
    this.state = {
      unitType: units,
      value,
    };
  }

  onChange = (value, unitType) => {
    const { callback, units } = this.props;
    const modifiedValue = units === unitType ? value : value * 1000;

    this.setState({ value });

    callback(modifiedValue);
  }

  render() {
    const { units, t } = this.props;
    const { value, unitType } = this.state;

    const CustomSelect = (
      <Select
        defaultValue={units}
        style={{ width: 60 }}
        {...(unitType ? { value: unitType } : {})}
        onChange={(unitType) => {
          this.setState({ unitType });

          this.onChange(value, unitType);
        }}
      >
        {getUnitsByType(units).map(unit => (
          <Option key={unit} value={unit}>{getUnits(unit)}</Option>
        ))}
      </Select>
    );

    return (
      <NumberInput
        placeholder="0"
        type="tel"
        value={value}
        addonAfter={units === 'pc' ? t('count') : CustomSelect}
        onKeyUp={validateNumberInput}
        onChange={e => this.onChange(e.target.value, unitType)}
        style={{ width: '140px' }}
      />
    );
  }
}

export default withTranslation()(StoreItemCountInput);
