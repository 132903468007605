import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { separateBill } from '../../redux/statistic/actions';
import SeparateBill from './SeparateBill';
import ModalHOC from '../../common/ModalHOC';

function mapStateToProps(state) {
  return {
    activeBills: state.statistic.activeBills,
    activeKey: state.statistic.activeKey,
    business: state.business,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        separateBill,
      },
      dispatch,
    ),
  };
}

export default ModalHOC(withRouter(connect(mapStateToProps, mapDispatchToProps)(SeparateBill)));
