import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { editComponent } from '../../../../redux/menu/actions';
import { ModalHOC } from '../../../../common';
import { showMessage } from '../../../../redux/message/actions';

import ComponentModal from './ComponentModal';

function mapStateToProps(state) {
  return {
    menu: state.menu,
    business: state.business,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        editComponent,
      },
      dispatch,
    ),
  };
}

export default ModalHOC(connect(mapStateToProps, mapDispatchToProps)(ComponentModal));
