// Keeper Settings flow

import axios from '../index';
import { idbKeeper } from '../../config';
import { persistWithKey } from '../../persistance';

export const getKeeperSettingsDataRequest = () => new Promise(resolve => axios.get('/db/keeper')
  .then((res) => {
    persistWithKey('keeper', res.data, idbKeeper);
    resolve(res);
  }));

export const updateKeeperSettingsRequest = data => axios.put('/db/keeper', { data });

export const setKeeperSettingsRequest = data => axios.post('/db/keeper', { data });
