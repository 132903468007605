import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Table, Tag } from 'antd';
import {
  PageHeader, Content, TopBar, Page,
} from '../../components';
import './style.scss';
import { getUnits, printPage } from '../../utils/helpers';
import { firebase } from '../../config';

export const getUrlByRecord = ({ id, type, name }) => {
  switch (type) {
    case 'consumable': return (
      <Link to={{ pathname: '/app/equipment/consumables', state: { equipmentId: id } }}>{name}</Link>
    );
    case 'component':
      return <Link to={{ pathname: '/app/equipment/components', state: { componentId: id } }}>{name}</Link>;
    case 'item': return <Link to={{ pathname: '/app/menu', state: { itemId: id } }}>{name}</Link>;
    default: return name;
  }
};

class Storage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      t, store, menuIdMap, equipmentIdMap, currency,
    } = this.props;
    const storeColumns = [
      {
        title: t('global.name'),
        dataIndex: 'name',
        width: 'calc(100% / 4)',
        sorter: (a, b) => {
          if (!a.name || !b.name) {
            return 0;
          }
          return (-1) * a.name.localeCompare(b.name);
        },
        sortDirections: ['descend'],
        defaultSortOrder: 'descend',
        render: (name, record) => getUrlByRecord(record),
      }, {
        title: t('global.type'),
        dataIndex: 'type',
        width: 'calc(100% / 4)',
        render: text => <Tag>{t(`global.${text}`)}</Tag>,
        sorter: (a, b) => (-1) * a.type.localeCompare(b.type),
        sortDirections: ['descend'],
      }, {
        title: t('cost.title'),
        dataIndex: 'cost',
        width: 'calc(100% / 4)',
        render: (text, record) => `${record.cost} ${currency}`,
        sorter: (a, b) => {
          const delta = parseInt(a.cost * 1000, 10) - parseInt(b.cost * 1000, 10);
          if (delta > 0) return -1;
          if (delta < 0) return 1;
          return 0;
        },
        sortDirections: ['descend'],
      }, {
        title: t('global.quantity'),
        dataIndex: 'count',
        width: 'calc(100% / 4)',
        sorter: (a, b) => {
          const delta = a.count - b.count;
          if (delta > 0) return -1;
          if (delta < 0) return 1;
          return 0;
        },
        render: (text, record) => `${text} ${record.units ? getUnits(record.units) : t('count')}`,
        sortDirections: ['descend'],
      },
    ];
    const filteredStore = store.map(el => ({
      ...el, ...(el.type === 'consumable' ? equipmentIdMap[el.id] : menuIdMap[el.id]), key: el.id,
    })).filter(el => el.name);
    return (
      <Page spinning={!firebase.auth().currentUser}>
        <PageHeader />
        <Content>
          <TopBar>
            <h3 className="storage-page-title">
              {t('global.consumables')}: {filteredStore.length}
            </h3>
            <Button
              className="no-print"
              size="large"
              onClick={() => printPage()}
              type="secondary"
            >{t('control.print')}
            </Button>
          </TopBar>
          <Table
            bordered
            pagination={false}
            size="default"
            className="storage-page-table"
            columns={storeColumns}
            dataSource={filteredStore}
          />
        </Content>
      </Page>
    );
  }
}

export default withTranslation()(Storage);
