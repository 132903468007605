import React, { Component } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Table, Tag } from 'antd';
import { withTranslation } from 'react-i18next';
import { firebase } from '../../../config';
import {
  Content, Page, PageHeader, Row, TopBar,
} from '../../../components';
import AddEmployer from './AddEmployer';
import EditEmployer from './EditEmployer';
import { alphabeticalSort } from '../../../utils/helpers';
import { deleteModal } from '../../../utils/modals';
import { isOnline } from '../../../persistance';
import { showNetworkError } from '../../../redux/message/actions';

class Employers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
    };
  }

  selectItem = (record) => {
    if (!isOnline()) return showNetworkError();
    const { access: { employers } } = this.props;
    const selectedItem = employers.find(el => el.name === record.name);
    this.setState({ selectedItem });
    this.editEmployer.show();
  };

  render() {
    const {
      t, access: { employers, jobs }, actions: { removeEmployer },
    } = this.props;
    const { selectedItem } = this.state;
    const employersTableColumns = [{
      title: t('global.name'),
      dataIndex: 'name',
      sorter: (a, b) => (-1) * a.name.localeCompare(b.name),
      sortDirections: ['descend'],
    }, {
      title: t('login.password'),
      dataIndex: 'password',
      render: type => <Tag>{type}</Tag>,
    }, {
      title: t('job.jobName'),
      dataIndex: 'position',
      render: id => <Tag>{(jobs.find(job => job.id.toString() === id.toString()) || {}).name}</Tag>,
    }, {
      title: t('global.description'),
      dataIndex: 'description',
      sorter: (a, b) => (-1) * a.name.localeCompare(b.name),
      sortDirections: ['descend'],
    }, {
      title: '',
      width: '30px',
      render: (text, record) => (
        <Row width="100%" justifyContent="center">
          <Button onClick={() => this.selectItem(record)} type="link" icon={<EditOutlined />} />
        </Row>
      ),
    }, {
      title: '',
      width: '30px',
      render: (text, record) => (
        <Row width="100%" justifyContent="center">
          <Button onClick={() => deleteModal(() => removeEmployer(record.id))} type="link" icon={<DeleteOutlined />} />
        </Row>
      ),
    }];
    return (
      <Page spinning={!firebase.auth().currentUser}>
        <PageHeader />
        <Content>
          <TopBar data-tour="employers-tour-2">
            <h3 className="storage-page-title">{t('layout.employers')}: {employers.length}</h3>
            <Button
              className="add-button"
              size="large"
              onClick={() => {
                if (!isOnline()) return showNetworkError();
                this.addEmployer.show();
              }}
            >
              {t('control.add')}
            </Button>
          </TopBar>
          <Table
            bordered
            pagination={false}
            size="default"
            className="storage-page-table"
            columns={employersTableColumns}
            dataSource={alphabeticalSort(employers).map((el, index) => ({ ...el, key: index }))}
          />
        </Content>
        <AddEmployer ref={(ref) => { this.addEmployer = ref; }} />
        <EditEmployer employer={selectedItem} ref={(ref) => { this.editEmployer = ref; }} />
      </Page>
    );
  }
}

export default withTranslation()(Employers);
