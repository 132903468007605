import {
  deleteImage, generateId, uploadImage,
} from '../../../utils/helpers';
import { getPlaceRef } from '../../business/actions';
import {
  addProductRequest, editProductRequest, getProductsDataRequest, removeProductRequest,
} from '../../../api/db/menu';
import { showErrorMessage, showMessage } from '../../message/actions';
import i18n from '../../../utils/translation';
import {
  getPersistedMenuProducts, isOnline, persist, removeDataFromPersist,
} from '../../../persistance';
import { idbMenuProducts } from '../../../config';

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const ADD_PRODUCTS = 'ADD_PRODUCTS';
export const EDIT_PRODUCTS = 'EDIT_PRODUCTS';
export const REMOVE_PRODUCTS = 'REMOVE_PRODUCTS';

export function getProducts(persisted = false) {
  return (dispatch) => {
    if (persisted || !isOnline()) {
      return getPersistedMenuProducts()
        .then(data => dispatch({ type: GET_PRODUCTS, payload: data.map(el => ({ ...el, type: 'product' })) }));
    }
    return getProductsDataRequest()
      .then(res => dispatch({ type: GET_PRODUCTS, payload: res.data.map(el => ({ ...el, type: 'product' })) }))
      .catch(showErrorMessage);
  };
}

export function editProduct({
  photo, ref, id, ...product
}, withNotification = true) {
  return async (dispatch) => {
    const placeRef = await dispatch(getPlaceRef());
    let url = photo;
    let newRef = ref;

    if (typeof photo !== 'string' && photo !== null) {
      newRef = `${placeRef}/product-${Date.now()}`;
      url = await uploadImage(newRef, photo);
    }

    const newItem = {
      ...product, photo: url, ref: newRef, id,
    };

    delete newItem.id;
    delete newItem.type;

    return editProductRequest(id, newItem)
      .then((res) => {
        dispatch({ type: EDIT_PRODUCTS, payload: ({ ...res.data, id, type: 'product' }) });

        if (withNotification) dispatch(showMessage({ text: i18n.t('actions.menuProductEditedTitle') }));

        persist({ ...res.data, id, type: 'product' }, idbMenuProducts);

        return { ...res.data, id, type: 'product' };
      })
      .catch(showErrorMessage);
  };
}

export function toggleFavouritesProduct(product) {
  return dispatch => dispatch(editProduct({ ...product, inFavourites: !product.inFavourites }, false));
}

export function createProduct(product) {
  return async (dispatch) => {
    const placeRef = await dispatch(getPlaceRef());
    let url = null;
    let ref = null;

    if (typeof product.photo === 'string') {
      ref = `${placeRef}/product-${Date.now()}`;
      url = product.photo;
    } else if (product.photo !== null) {
      ref = `${placeRef}/product-${Date.now()}`;
      url = await uploadImage(ref, product.photo);
    }

    const components = product.components.map(el => ({ count: el.count, id: el.id }));
    const id = generateId();
    const data = {
      ...product, components, ref, photo: url, inFavourites: false,
    };

    return addProductRequest(id, data)
      .then((res) => {
        dispatch({ type: ADD_PRODUCTS, payload: ({ ...res.data, id, type: 'product' }) });

        persist({ ...res.data, id, type: 'product' }, idbMenuProducts);

        dispatch(showMessage({ text: i18n.t('actions.menuProductCreatedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function removeProduct(item) {
  return dispatch => removeProductRequest(item.id)
    .then(() => {
      dispatch({ type: REMOVE_PRODUCTS, payload: item.id });

      if (item.photo) deleteImage(item.ref);

      removeDataFromPersist(item.id, idbMenuProducts);

      dispatch(showMessage({ text: i18n.t('actions.menuProductDeletedTitle') }));
    })
    .catch(showErrorMessage);
}
