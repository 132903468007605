import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import './style.scss';

const UrlPattern = /^https?:\/\//;
const Link = props => (UrlPattern.test(props.to) ? (
  <a
    {...props}
    className={`shoptoria-link ${props.type || 'primary'} ${props.className || ''}`}
    data-tip={props.tooltip}
    data-for={props.tooltip ? 'shoptoria-link' : ''}
    href={props.type === 'disable' ? null : props.to}
  >{props.children}
  </a>
) : <ReactLink {...props} />);

export default Link;
