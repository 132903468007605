import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Consumption from './Consumption';

function mapStateToProps(state) {
  return {
    resolvedBills: state.statistic.resolvedBills,
    menu: state.menu,
    equipment: state.equipment,
    activePlace: state.business.activePlace,
    currency: state.business.currency,
  };
}

export default withRouter(connect(mapStateToProps, null)(Consumption));
