import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { firebase } from '../../../config';
import {
  Header, EmployerLogin, ModalMenu,
} from '../../../components';
import { notificationTypes } from '../../../redux/message/actions';
import { isPermissionGranted, showNotGrantedMessage } from '../../../constants/tariff';

class KeeperLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employer: null,
    };
    document.body.classList.add('no-overscroll-page');

    const { history: { location: { pathname } }, actions: { initRedux }, dataLoaded } = props;
    const user = firebase.auth().currentUser;
    if (user !== null && /\/app\//.test(pathname) && !dataLoaded) {
      initRedux();
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('no-overscroll-page');
  }

  selectEmployer = (employer) => {
    const {
      t, tariff, history, actions: { setEmployer }, activeShift,
    } = this.props;
    if (!isPermissionGranted(tariff, 'terminal')) {
      return showNotGrantedMessage(t);
    }
    setEmployer(employer);
    if (!activeShift) {
      this.setState({ employer });
      this.modalMenu.show();
    } else {
      history.push('/app/keeper');
    }
  }

  dropEmployer = () => {
    const { actions: { setEmployer } } = this.props;
    setEmployer(null);
    this.modalMenu.hide();
  };

  render() {
    const {
      actions: { showMessage }, t, name,
    } = this.props;
    const { employer } = this.state;
    const canInteract = true;

    if (!!employer && !canInteract) showMessage({ type: notificationTypes.ERROR, text: t('payments.cantInteract') });
    return (
      <div className="keeper">
        <Header className="keeper-header no-min-width">
          <h2 className="header-name">{name}</h2>
        </Header>
        <EmployerLogin callback={employer => this.selectEmployer(employer)} />
        <ModalMenu dropEmployer={this.dropEmployer} employer={employer} ref={(ref) => { this.modalMenu = ref; }} />
      </div>
    );
  }
}

export default withTranslation()(KeeperLogin);
