import React, { Component } from 'react';
import { UpOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import { Column, Link, Row } from '../../../components';
import { Capabilities } from '../components';
import { sendEvent } from '../../../utils/analytics';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  scrollTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  render() {
    const { t } = this.props;
    return (
      <footer>
        <h2>Shoptoria POS</h2>
        <div className="footer-content">
          <Column style={{ order: 1 }} margin="50px 40px 0 0">
            <h3>{t('landing.navigation')}</h3>
            <Capabilities t={t} />
            <HashLink
              onClick={() => sendEvent({ category: 'Landing', name: 'Footer click to pricing' })}
              smooth
              to="/#pricing"
            >{t('landing.prices')}
            </HashLink>
            <Link
              onClick={() => sendEvent({ category: 'Landing', name: 'Footer click to install' })}
              to="/install"
            >{t('landing.install.link')}
            </Link>
            <Link
              onClick={() => sendEvent({ category: 'Landing', name: 'Footer click to terms' })}
              to="/terms"
            >{t('landing.terms')}
            </Link>
            <Link
              onClick={() => sendEvent({ category: 'Landing', name: 'Footer click to faq' })}
              to="/faq"
            >FAQ
            </Link>
          </Column>
          <Column margin="50px 40px 0 0" style={{ order: 3 }}>
            <h3>{t('landing.needHelp')}</h3>
            <Link
              onClick={() => sendEvent({ category: 'Landing', name: 'Footer click to feedback' })}
              to="/feedback"
            >Feedback
            </Link>
            <a href="mailto:shoptoria.info@gmail.com">shoptoria.info@gmail.com</a>
          </Column>
          <Column style={{ order: 3 }} margin="50px 40px 0 0" className="footer-socials">
            <h3>{t('landing.socials')}</h3>
            <Row width="100%" margin="0" justifyContent="flex-start">
              <a href="https://t.me/shoptoriaPos" target="_blank"><img src="/assets/telegram_white.png" alt="Telegram icon" /></a>
              <a href="https://www.instagram.com/shoptoria_pos/" target="_blank"><img src="/assets/instagram_white.png" alt="Instagram icon" /></a>
              <a href="https://www.facebook.com/shoptoria.pos" target="_blank"><img src="/assets/facebook_white.png" alt="Facebook icon" /></a>
            </Row>
          </Column>
          <div className="top-wrapper">
            <Link to="#top" onClick={this.scrollTop} className="top">
              <UpOutlined style={{ color: 'white', fontSize: '14px' }} />
            </Link>
          </div>
        </div>
      </footer>
    );
  }
}

export default withTranslation()(Footer);
