import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { CaretDownOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import moment from 'moment';
import {
  Content, Page, PageHeader, Row, TopBar,
} from '../../../components';
import AddWaste from './AddWaste';
import WasteTable from './WasteTable';
import EditWaste from './EditWaste';
import { notificationTypes, showNetworkError } from '../../../redux/message/actions';
import { deleteModal } from '../../../utils/modals';
import { getUnits, printPage } from '../../../utils/helpers';
import { isOnline } from '../../../persistance';

class Waste extends Component {
  constructor(props) {
    super(props);
    const { wasteLoaded, dataLoaded } = props;

    this.state = {
      selectedWaste: null,
      loadingStarted: false,
    };

    if (!wasteLoaded && dataLoaded) {
      this.loadData();
    }
  }

  loadData = () => {
    if (!isOnline()) return showNetworkError();
    const { actions: { getWaste } } = this.props;
    const { loadingStarted } = this.state;
    if (!loadingStarted) {
      this.setState({ loadingStarted: true });
      getWaste();
    }
  }

  selectWaste = (selectedWaste) => {
    if (!isOnline()) return showNetworkError();
    this.setState({ selectedWaste });
    this.editWasteModal.show();
  };

  addWaste = () => {
    if (!isOnline()) return showNetworkError();
    const { t, canInteract, actions: { showMessage } } = this.props;
    if (canInteract) {
      this.addWasteModal.show();
    } else {
      showMessage({ type: notificationTypes.ERROR, text: t('payments.cantInteract') });
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { wasteLoaded, dataLoaded } = nextProps;

    if (!wasteLoaded && dataLoaded) {
      this.loadData();
    }
  }

  render() {
    const {
      t, waste, menuIdMap, equipmentIdMap, actions: { removeWaste }, wasteLoaded,
    } = this.props;
    const { selectedWaste } = this.state;

    const allIdMap = { ...menuIdMap, ...equipmentIdMap };
    const wasteColumns = [
      {
        title: t('supply.date'),
        dataIndex: 'date',
        sorter: (a, b) => {
          const momentA = moment(`${a.date} ${a.time}`, 'DD_MM_YYYY HH:mm');
          const momentB = moment(`${b.date} ${b.time}`, 'DD_MM_YYYY HH:mm');
          return momentA.isBefore(momentB) ? -1 : 1;
        },
        sortDirections: ['descend'],
        defaultSortOrder: 'descend',
        render: (text, record) => `${text.replace(/_/g, '.')} ${record.time}`,
      }, {
        title: t('supply.comment'),
        dataIndex: 'comment',
      }, {
        title: '',
        width: '30px',
        render: (text, record) => (
          <Row width="100%" justifyContent="center">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                this.selectWaste(record);
              }}
              type="link"
              icon={<EditOutlined />}
            />
          </Row>
        ),
      }, {
        title: '',
        dataIndex: 'action',
        width: '50px',
        render: (text, record) => (
          <DeleteOutlined
            onClick={(e) => {
              e.stopPropagation();
              deleteModal(() => removeWaste(record.id));
            }}
          />
        ),
      },
    ];
    return (
      <Page spinning={!wasteLoaded}>
        <PageHeader />
        <Content>
          <TopBar data-tour="waste-tour-2">
            <h3 className="storage-page-title">{t('storage.waste')}: {waste.length}</h3>
            <Button
              className="no-print right-margin"
              size="large"
              onClick={() => printPage()}
              type="secondary"
            >{t('control.print')}
            </Button>
            <Button className="add-button" size="large" onClick={this.addWaste}>{t('control.add')}</Button>
          </TopBar>
          <Table
            bordered
            pagination={false}
            size="default"
            className="storage-page-table"
            columns={wasteColumns}
            expandRowByClick
            expandIcon={
              ({ expanded }) => <CaretDownOutlined className={`table-icon ${expanded ? 'rotate' : ''}`} />
            }
            expandedRowRender={r => (
              <WasteTable
                items={r.items.filter(el => allIdMap[el.item]).map(el => ({
                  ...el,
                  name: allIdMap[el.item].name,
                  count: `${el.count} ${allIdMap[el.item].units ? getUnits(allIdMap[el.item].units) : t('count')}`,
                }))}
              />
            )}
            dataSource={waste.map((el, i) => ({ ...el, key: i }))}
          />
        </Content>
        <AddWaste ref={(ref) => { this.addWasteModal = ref; }} />
        <EditWaste waste={selectedWaste} ref={(ref) => { this.editWasteModal = ref; }} />
      </Page>
    );
  }
}

export default withTranslation()(Waste);
