import React, { Component } from 'react';
import { CaretDownOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { firebase } from '../../../config';
import { RangePicker } from '../../../common';
import {
  Content, Page, PageHeader, Row, TopBar,
} from '../../../components';
import ShiftModal from './ShiftModal';
import { getShiftsFromTimeRange } from '../../../redux/shift/helpers';
import { printPage, sortShiftsByDate } from '../../../utils/helpers';
import { getMonthWithLocale } from '../../../constants/time';
import i18 from '../../../utils/translation';

class Shifts extends Component {
  constructor(props) {
    super(props);
    const { shift: { cashShifts } } = props;
    this.state = {
      shifts: cashShifts,
      pending: false,
      rangeStart: moment().subtract(1, 'week'),
      rangeEnd: moment(),
      selectedRecord: null,
    };
  }

  updateRange = ({ rangeStart, rangeEnd }) => {
    this.setState({ pending: true, rangeStart, rangeEnd });
    getShiftsFromTimeRange(rangeStart, rangeEnd)
      .then((shifts = []) => this.setState({ shifts, pending: false }));
  };

  openReport = (selectedRecord) => {
    this.setState({ selectedRecord });
    this.shiftModal.show();
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.shift.cashShifts.length !== nextProps.shift.cashShifts.length) {
      this.setState({ shifts: nextProps.shift.cashShifts });
    }
  }

  render() {
    const { t, access: { employers } } = this.props;
    const {
      pending, shifts, rangeStart, rangeEnd, selectedRecord,
    } = this.state;
    const dates = sortShiftsByDate(shifts.filter(shift => !shift.isOpen), rangeStart, rangeEnd);
    const shiftsColumns = [
      {
        title: t('modalMenu.openTime'),
        dataIndex: 'startTime',
        render: time => `${moment(time).format('HH:mm - DD')} 
        ${getMonthWithLocale(i18.language, moment(time).format('M'))}`,
      }, {
        title: t('modalMenu.openBy'),
        dataIndex: 'openBy',
        render: id => (employers.find(el => el.id === id) || {}).name,
      }, {
        title: t('modalMenu.closeTime'),
        dataIndex: 'endTime',
        render: time => `${moment(time).format('HH:mm - DD')} 
        ${getMonthWithLocale(i18.language, moment(time).format('M'))}`,
      }, {
        title: t('modalMenu.closedBy'),
        dataIndex: 'closeBy',
        render: id => (employers.find(el => el.id === id) || {}).name,
      },
      {
        render: (text, record) => (
          <Row width="100%" justifyContent="center">
            <Button
              style={{ margin: '0 auto' }}
              onClick={() => this.openReport(record)}
            >{t('modalMenu.openReport')}
            </Button>
          </Row>
        ),
      },
    ];
    return (
      <Page spinning={!firebase.auth().currentUser || pending}>
        <PageHeader />
        <Content>
          <TopBar data-tour="financialShifts-tour-2">
            <h3 className="storage-page-title">{t('layout.shifts')}</h3>
            <Button
              className="no-print mobile-bottom-margin mobile-right-margin"
              size="large"
              onClick={() => printPage()}
              type="secondary"
            >{t('control.print')}
            </Button>
            <RangePicker rangeStart={rangeStart} callback={this.updateRange} />
          </TopBar>
          <Table
            bordered
            pagination={false}
            size="default"
            className="storage-page-table"
            columns={[
              { title: t('supply.date'), dataIndex: 'date', render: date => date.format('DD.MM.YYYY') },
            ]}
            expandRowByClick
            dataSource={dates.filter(date => date.shifts.length > 0).map(el => ({ ...el, key: el.date.valueOf() }))}
            expandIcon={
                ({ expanded }) => <CaretDownOutlined className={`table-icon ${expanded ? 'rotate' : ''}`} />
              }
            expandedRowRender={r => (
              <Table
                className="supply-table"
                bordered
                columns={shiftsColumns}
                pagination={false}
                size="default"
                style={{ width: '100%', overflowX: 'scroll' }}
                dataSource={r.shifts.map(el => ({ ...el, key: `${el.startTime}-${el.endTime}` }))}
              />
            )}
          />
          <ShiftModal shift={selectedRecord} ref={(ref) => { this.shiftModal = ref; }} />
        </Content>
      </Page>
    );
  }
}


export default withTranslation()(Shifts);
