import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { LeftOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import i18 from '../../utils/translation';
import { Row } from '../index';
import { notificationTypes, showMessage } from '../../redux/message/actions';
import './style.scss';

class EmployerLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: [],
    };
  }

  validatePassword = () => {
    const {
      actions: { showMessage }, access: { employers }, callback,
    } = this.props;
    const { password } = this.state;
    if (password.length !== 4) return;
    const passwordStr = password.join('');
    const employer = employers.find(el => el.password === passwordStr);
    if (employer) {
      this.setState({ password: [] });
      callback(employer);
    } else {
      showMessage({ text: i18.t('validation.noSuchEmployer'), type: notificationTypes.ERROR });
      this.setState({ password: [] });
    }
  };

  addDigit = digit => this.setState(state => ({
    password: state.password.length === 4 ? state.password : [...state.password, digit],
  }), this.validatePassword);

  backspace = () => this.setState(state => ({
    password: state.password.slice(0, state.password.length - 1),
  }));

  clear = () => this.setState({ password: [] });

  render() {
    const { t, access: { employersLoaded }, shiftsLoaded } = this.props;
    const { password } = this.state;
    return (
      <Spin
        wrapperClassName="employer-wrapper"
        style={{ height: 'calc(100vh - 50px)', minHeight: '100vh' }}
        size="large"
        className="employer-tour"
        tip={t('loading.employers')}
        spinning={!employersLoaded || !shiftsLoaded}
      >
        <div className="employer-login">
          <Row width="300px" className="inputs">
            {password.map((el, i) => <div className="input" key={`${i + 1}`}><div /></div>)}
            {new Array(4 - password.length).fill(null).map((el, i) => (
              <div key={`${i + 1}`} className="input empty"><span /></div>
            ))}
          </Row>
          <div className="buttons">
            <button type="button" onClick={() => this.addDigit(1)}>1</button>
            <button type="button" onClick={() => this.addDigit(2)}>2</button>
            <button type="button" onClick={() => this.addDigit(3)}>3</button>
            <button type="button" onClick={() => this.addDigit(4)}>4</button>
            <button type="button" onClick={() => this.addDigit(5)}>5</button>
            <button type="button" onClick={() => this.addDigit(6)}>6</button>
            <button type="button" onClick={() => this.addDigit(7)}>7</button>
            <button type="button" onClick={() => this.addDigit(8)}>8</button>
            <button type="button" onClick={() => this.addDigit(9)}>9</button>
            <button type="button" onClick={this.clear} className="clear" style={{ fontSize: '14px' }}>
              {i18.t('control.clear')}
            </button>
            <button type="button" onClick={() => this.addDigit(0)}>0</button>
            <button type="button" onClick={this.backspace} className="clear">
              <LeftOutlined style={{ fontSize: '30px', lineHeight: '60px' }} />
            </button>
          </div>
        </div>
      </Spin>
    );
  }
}

function mapStateToProps(state) {
  return {
    access: state.access,
    shiftsLoaded: state.shift.shiftsLoaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ showMessage }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EmployerLogin));
