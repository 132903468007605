import { idbFinancialAccount, idbFinancialCategory, idbFinancialTransaction } from '../../config';
import axios from '../index';
import { persist } from '../../persistance';

// FinancialAccount flow

export const getFinancialAccountRequest = () => new Promise(resolve => axios.get('/db/financial/account')
  .then((res) => {
    res.data.map(el => persist(el, idbFinancialAccount));
    resolve(res);
  }));

export const editFinancialAccountRequest = (id, data) => axios.put('/db/financial/account', { id, data });

export const addFinancialAccountRequest = (id, data) => axios.post('/db/financial/account', { id, data });

export const removeFinancialAccountRequest = id => axios.delete(`/db/financial/account/${id}`);

// FinancialTransaction flow

export const getFinancialTransactionsRequest = () => new Promise(resolve => axios
  .get('/db/financial/transaction?limit=1000')
  .then((res) => {
    res.data.map(el => persist(el, idbFinancialTransaction));
    resolve(res);
  }));

export const getFinancialTransactionDeltaRequest = (limit = 10) => new Promise(resolve => axios
  .get(`/db/financial/transaction/delta?limit=${limit}`)
  .then((res) => {
    res.data.map(el => persist(el, idbFinancialTransaction));
    resolve(res);
  }));

export const addFinancialTransactionRequest = (id, data) => axios.post('/db/financial/transaction', { id, data });

export const editFinancialTransactionRequest = (id, data) => axios.put('/db/financial/transaction', { id, data });

export const removeFinancialTransactionRequest = id => axios.delete(`/db/financial/transaction/${id}`);

// FinancialCategory flow

export const getFinancialCategoryRequest = () => new Promise(resolve => axios.get('/db/financial/category')
  .then((res) => {
    res.data.map(el => persist(el, idbFinancialCategory));
    resolve(res);
  }));

export const editFinancialCategoryRequest = (id, data) => axios.put('/db/financial/category', { id, data });

export const addFinancialCategoryRequest = (id, data) => axios.post('/db/financial/category', { id, data });

export const removeFinancialCategoryRequest = id => axios.delete(`/db/financial/category/${id}`);
