import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal, Table } from 'antd';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { firebase } from '../../../config';
import { ItemsTable, ModalHOC } from '../../../common';
import { Column } from '../../../components';
import {
  calculateComponentsCost, calculateConsumablesCost, calculateTotal, getDayStatisticFromSortedBills, getItemsCost,
} from '../../../utils/helpers';
import { getBillsFromDayRange, prepareBillData } from '../../../redux/statistic/helpers';
import { showMessage } from '../../../redux/message/actions';
import { WASTE } from '../../../constants/transactions';

class Shifts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pending: true,
      bills: [],
    };
  }

  componentDidMount() {
    const { shift } = this.props;

    getBillsFromDayRange(shift.startTime, shift.endTime)
      .then(bills => this.setState({
        bills: bills.filter(bill => shift.startTime < bill.id && bill.id < shift.endTime),
        pending: false,
      }));
  }

  getAllItems = bills => bills.reduce((res, bill) => res.concat(bill.items), []);

  getCost = (el) => {
    const { menu: { idMap }, equipment } = this.props;
    if (!firebase.auth().currentUser || equipment.loading) return;
    switch (el.type) {
      case 'addon': return idMap[el.src.componentId].cost * el.src.count;
      case 'product': {
        return calculateComponentsCost(el.src.components, idMap, el.size)
          + calculateConsumablesCost(el.src.consumables, equipment.idMap, el.size);
      }
      default: return el.src.cost;
    }
  };

  getItemsData = (bills) => {
    const { menu } = this.props;
    // Data for items calculation
    const allItems = this.getAllItems(bills);
    return prepareBillData(allItems).filter(el => menu.idMap[el.id]).map(el => (el.sizes.map(size => ({
      ...el, size, price: el.prices[size], quantity: el.quantity[size], type: menu.idMap[el.id].type,
    })))).flat()
      .map((item, key) => {
        const data = { src: menu.idMap[item.id], ...item, key };
        const cost = parseFloat(this.getCost(data)).toFixed(2);
        return ({
          ...data,
          cost,
          sum: parseFloat(data.quantity * data.price).toFixed(2),
          gross: (data.quantity * parseFloat(data.price - cost)).toFixed(2),
        });
      });
  };

  render() {
    const {
      t, close, visible, menu, equipment, shift, access: { employers }, business,
    } = this.props;
    const { bills, pending } = this.state;
    // Info for sales calculation
    if (pending) return <h3>...</h3>;
    const todayStatistic = getDayStatisticFromSortedBills([{ date: moment(shift.startTime), bills }])[0];
    const items = bills.map(bill => bill.items).flat().map(el => ({ ...el, ...menu.idMap[el.id] }));
    const cost = getItemsCost(items, menu, equipment);
    const consumptionCount = shift.transactions.reduce((count, el) => count + parseInt(el.price, 10), 0);
    const cashBoxConsumption = shift.transactions.filter(el => el.account === 0)
      .reduce((count, el) => count + parseInt(el.price, 10), 0);
    return (
      <Modal
        maskClosable={false}
        visible={visible}
        onCancel={close}
        footer={null}
        width="740px"
        title={t('modalMenu.shiftReport')}
      >
        <Column width="100%" alignItems="flex-start">
          <Column width="100%" className="default-card">
            <h2>{t('modalMenu.openTime')}: {moment(shift.startTime).format('HH:mm, DD.MM')}</h2>
            <h3>{t('modalMenu.openCashBalance')}: {shift.openCount} {business.currency}</h3>
            <h2>{t('modalMenu.closeTime')}: {moment(shift.endTime).format('HH:mm, DD.MM')}</h2>
            <h3>{t('modalMenu.closeCashBalance')}: {shift.closeCount} {business.currency}</h3>
          </Column>
          <Column width="100%" className="default-card" margin="12px 0">
            <h2 style={{ margin: '0 0 15px' }}>{t('global.sales')}</h2>
            <Table
              bordered
              pagination={false}
              style={{ width: '100%' }}
              className="table-bold-last-row"
              columns={[
                { dataIndex: 'name', title: t('global.type') },
                {
                  dataIndex: 'value',
                  title: t('global.sum'),
                  className: 'table-header-right',
                  render: value => <span style={{ fontWeight: '600' }}>{value}</span>,
                },
              ]}
              dataSource={[
                { key: '1', name: t('pay.cash'), value: `${todayStatistic.cash} ${business.currency}` },
                { key: '2', name: t('pay.card'), value: `${todayStatistic.card} ${business.currency}` },
                { key: '3', name: t('global.sale'), value: `${todayStatistic.sale} ${business.currency}` },
                {
                  key: '4',
                  name: t('global.gross'),
                  value: `${(todayStatistic.total - cost).toFixed(2)} ${business.currency}`,
                },
                { key: '5', name: t('cost.title'), value: `${cost} ${business.currency}` },
                { key: '6', name: t('layout.bills'), value: `${todayStatistic.count} ${t('count')}` },
                {
                  key: '7',
                  name: t('statistic.middleBill'),
                  value: `${parseFloat((todayStatistic.total || 0) / (todayStatistic.count || 1))
                    .toFixed(2)} ${business.currency}`,
                },
                {
                  key: 10,
                  name: t('modalMenu.consumptionCount'),
                  value: `${consumptionCount} ${business.currency}`,
                },
                {
                  key: '9',
                  name: t('modalMenu.expectedCashBalance'),
                  value: `${(parseInt(shift.openCount, 10)
                  + parseFloat(todayStatistic.cash) - cashBoxConsumption)
                    .toFixed(2)} ${business.currency}`,
                },
                {
                  key: '8',
                  name: t('global.revenue'),
                  value: `${todayStatistic.total} ${business.currency}`,
                },
              ]}
            />
          </Column>
          <Column width="100%" className="default-card">
            <h2 style={{ margin: '0 0 15px' }}>{t('layout.transactions')}</h2>
            <Table
              bordered
              pagination={false}
              style={{ width: '100%', overflowX: 'scroll' }}
              columns={[
                {
                  title: t('access.employer'),
                  dataIndex: 'name',
                }, {
                  title: t('financial.account2'),
                  dataIndex: 'account',
                  render: (account = 0) => (account === 0 ? t('financial.cashbox') : t('financial.safeAccount')),
                }, {
                  title: t('financial.type'),
                  dataIndex: 'type',
                  render: type => t(`financial.${type}`),
                }, {
                  title: t('supply.comment'),
                  dataIndex: 'description',
                }, {
                  title: t('global.sum'),
                  dataIndex: 'value',
                  className: 'table-header-right',
                  render: value => (<span style={{ fontWeight: '600' }}>{value} {business.currency}</span>),
                },
              ]}
              dataSource={(shift.transactions || []).map(el => ({
                key: el.time,
                name: el.employer.name,
                description: el.description,
                value: el.price,
                type: el.type || WASTE,
              }))}
            />
          </Column>
          <Column width="100%" className="default-card" margin="12px 0">
            <h2 style={{ margin: '0 0 15px' }}>{t('modalMenu.employerSales')}</h2>
            <Table
              bordered
              pagination={false}
              style={{ width: '100%' }}
              columns={[
                { dataIndex: 'name', title: t('access.employer') },
                {
                  dataIndex: 'value',
                  title: t('global.sum'),
                  className: 'table-header-right',
                  render: value => <span style={{ fontWeight: '600' }}>{value} {business.currency}</span>,
                },
              ]}
              dataSource={Array.from(new Set(bills.map(el => el.employer.id))).map(id => ({
                key: id,
                name: employers.find(el => el.id.toString() === id.toString()).name,
                value: bills.filter(bill => bill.employer.id.toString() === id.toString())
                  .reduce((acc, bill) => acc + parseFloat(calculateTotal(bill)), 0)
                  .toFixed(2),
              }))}
            />
          </Column>
          <Column width="100%" className="default-card">
            <h2 style={{ margin: '0 0 5px' }}>{t('layout.items')}</h2>
            <ItemsTable t={t} data={this.getItemsData(bills)} currency={business.currency} />
          </Column>
        </Column>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    access: state.access,
    equipment: state.equipment,
    business: state.business,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
      },
      dispatch,
    ),
  };
}
export default ModalHOC(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Shifts)));
