import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { alphabeticalSort } from '../../utils/helpers';
import TabsControl from './TabsControl';
import TabsList from './TabsList';
import './style.scss';
import { itemsSelector } from '../../redux/menu/selectors';
import { getFilteredByZeroCountItems } from '../../redux/keeper/selectors';

const applyFilterByTabKey = (key, items) => {
  if (key.includes('card-category')) {
    const activeCategory = key.replace('card-category-', '').replace(/_/g, ' ');

    return items.filter(item => item.category === activeCategory);
  }

  switch (key) {
    case 'card-all': return items;
    case 'card-favourites': return items.filter(item => item.inFavourites);
    case 'card-addon': return items.filter(item => item.type === 'addon');
    case 'card-product': return items.filter(item => item.type === 'product');
    case 'card-item': return items.filter(item => item.type === 'item');
    default: return items;
  }
};


class MenuTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 'card-all',
    };
  }

  render() {
    const {
      products, items, addons, idMap, componentsLoaded, listCallback, withFavourites = false, withAddons = false,
      excludeCategories = false, showCategoryInCard, withRestriction = false,
    } = this.props;
    const { activeKey } = this.state;

    const allData = alphabeticalSort([
      ...products, ...items, ...(withAddons ? addons : [])
        .map(addon => ({ ...idMap[addon.componentId], ...addon }))],
    'name');

    return (
      <div style={{ width: '100%', padding: 0 }} className="keeper-tab">
        <TabsControl
          callback={activeKey => this.setState({ activeKey })}
          withRestriction={withRestriction}
          excludeCategories={excludeCategories}
          withAddons={withAddons}
          withFavourites={withFavourites}
          activeTab={activeKey}
        />

        <TabsList
          items={applyFilterByTabKey(activeKey, allData)}
          withCategories={showCategoryInCard}
          callback={listCallback}
          withFavourites={withFavourites}
        />

        {!componentsLoaded && (
        <div className="tabs-spinner">
          <Spin size="large" />
        </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    products: state.menu.products,
    items: ownProps.hideEmptyItems ? getFilteredByZeroCountItems(state) : itemsSelector(state),
    addons: state.menu.addons,
    idMap: state.menu.idMap,
    componentsLoaded: state.menu.componentsLoaded,
    showCategoryInCard: state.keeper.showCategoryInCard,
  };
}

export default withRouter(connect(mapStateToProps, null)(withTranslation()(MenuTabs)));
