import React, { Component, Fragment } from 'react';
import { Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import './style.scss';
import CropModal from './CropModal';

// TODO clean up mixed logic
class FileUpload extends Component {
  state = {
    fileList: [],
    uploading: false,
    fileToProcess: null,
  };

  modalCallback = (file) => {
    const { callback = () => {} } = this.props;
    this.setState({ fileList: [file] });
    callback(file);
  };

  render() {
    const { callback = () => {}, imageUrl } = this.props;
    const { fileList, uploading, fileToProcess } = this.state;
    const props = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
        callback(null);
      },
      beforeUpload: (file) => {
        // callback(file);
        this.setState({ fileToProcess: file }, () => this.cropModal.show());
        return false;
      },
      fileList: [],
      accept: '.jpeg, .jpg',
      className: 'create-product-upload',
    };
    return (
      <Fragment>
        <Upload {...props} disabled={fileList.length > 1} listType="picture-card">
          {fileList.length === 0
            ? imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : (
              <div>
                {uploading ? (
                  <LoadingOutlined />
                ) : (
                  <PlusOutlined />
                )}
                <div className="ant-upload-text">Upload</div>
              </div>
            ) : (
              <img src={URL.createObjectURL(fileList[0])} alt="avatar" style={{ width: '100%' }} />
            )}
        </Upload>
        <CropModal callback={this.modalCallback} file={fileToProcess} ref={(ref) => { this.cropModal = ref; }} />
      </Fragment>
    );
  }
}

export default FileUpload;
