import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Clients from './Clients';
import { showMessage } from '../../redux/message/actions';
import {
  clearBill,
  createBill, removeBill, removeFromBill, setActiveKey,
} from '../../redux/statistic/actions';

function mapStateToProps(state) {
  return {
    activeBills: state.statistic.activeBills,
    resolvedBills: state.statistic.resolvedBills,
    activeKey: state.statistic.activeKey,
    business: state.business,
    menu: state.menu,
    employer: state.keeper.activeEmployer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        setActiveKey,
        createBill,
        removeBill,
        removeFromBill,
        clearBill,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
