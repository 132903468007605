import React, { Component } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { firebase } from '../../../config';
import {
  Row, PageHeader, Content, TopBar, Page,
} from '../../../components';
import AddClient from './AddClient';
import EditClient from './EditClient';
import { alphabeticalSort, castToNormalFloat } from '../../../utils/helpers';
import { deleteModal } from '../../../utils/modals';
import { isOnline } from '../../../persistance';
import { showNetworkError } from '../../../redux/message/actions';

class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
    };
  }

  selectItem = (record) => {
    if (!isOnline()) return showNetworkError();
    const { clients } = this.props;
    const selectedItem = clients.find(el => el.id === record.id);
    this.setState({ selectedItem });
    this.editClient.show();
  };

  render() {
    const {
      t, clients, actions: { removeClient }, groups, currency,
    } = this.props;
    const { selectedItem } = this.state;
    const clientsTableColumns = [{
      title: t('marketing.clients.name'),
      dataIndex: 'name',
      sorter: (a, b) => (-1) * a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
    }, {
      title: '#ID',
      dataIndex: 'cardNumber',
      render: e => `#${e}`,
      sorter: (a, b) => {
        const delta = parseInt(a.cardNumber, 10) - parseInt(b.cardNumber, 10);
        if (delta > 0) return 1;
        if (delta < 0) return -1;
        return 0;
      },
      sortDirections: ['descend', 'ascend'],
    }, {
      title: t('global.group'),
      dataIndex: 'group',
      width: 150,
      render: (id) => {
        const group = groups.find(group => group.id === id);
        return group ? group.name : '-';
      },
      sorter: (a, b) => {
        const delta = parseInt(a.group || 1, 10) - parseInt(b.group || 1, 10);
        if (delta > 0) return 1;
        if (delta < 0) return -1;
        return 0;
      },
      sortDirections: ['descend', 'ascend'],
    }, {
      title: '%',
      dataIndex: 'group',
      width: 200,
      render: (id) => {
        const group = groups.find(group => group.id === id);
        return group ? `${t(`global.${group.type}`)} ${group.count}%` : '-';
      },
    }, {
      title: t('marketing.clients.profitCount'),
      dataIndex: 'profitCount',
      width: 150,
      render: count => `${castToNormalFloat(count)}${currency}`,
      sorter: (a, b) => {
        const delta = parseFloat(a.profitCount) - parseFloat(b.profitCount);
        if (delta > 0) return 1;
        if (delta < 0) return -1;
        return 0;
      },
      sortDirections: ['descend', 'ascend'],
    }, {
      title: t('pay.bonusAmount'),
      dataIndex: 'bonusCount',
      width: 150,
      render: bonusCount => `${castToNormalFloat(bonusCount || 0)}${currency}`,
      sorter: (a, b) => {
        const delta = parseFloat(a.bonusCount) - parseFloat(b.bonusCount);
        if (delta > 0) return 1;
        if (delta < 0) return -1;
        return 0;
      },
      sortDirections: ['descend', 'ascend'],
    }, {
      title: t('marketing.clients.gender'),
      dataIndex: 'gender',
      render: gender => (gender ? t(`marketing.clients.${gender}`) : '-'),
    }, {
      title: t('login.email'),
      dataIndex: 'email',
      render: email => (email.trim() !== '' ? email : '-'),
    }, {
      title: t('landing.phone'),
      dataIndex: 'phone',
      render: phone => (phone.trim() !== '' ? phone : '-'),
    }, {
      title: t('marketing.clients.creationDate'),
      dataIndex: 'creationDate',
      render: time => moment.unix(time / 1000).format('DD.MM.YYYY'),
      sorter: (a, b) => {
        const delta = parseInt(a.creationDate, 10) - parseInt(b.creationDate, 10);
        if (delta > 0) return 1;
        if (delta < 0) return -1;
        return 0;
      },
      sortDirections: ['descend', 'ascend'],
    }, {
      title: t('supply.comment'),
      dataIndex: 'comment',
    },
    {
      title: '',
      width: '30px',
      render: (text, record) => (
        <Row width="100%" justifyContent="center">
          <Button onClick={() => this.selectItem(record)} type="link" icon={<EditOutlined />} />
        </Row>
      ),
    }, {
      title: '',
      width: '30px',
      render: (text, record) => (
        <Row width="100%" justifyContent="center">
          <Button
            onClick={() => deleteModal(() => removeClient(record.id))}
            type="link"
            icon={<DeleteOutlined />}
          />
        </Row>
      ),
    }];
    return (
      <Page spinning={!firebase.auth().currentUser}>
        <PageHeader />
        <Content>
          <TopBar data-tour="clients-tour-2">
            <h3 className="storage-page-title">{t('layout.clients')}: {clients.length}</h3>
            <Button
              className="add-button"
              size="large"
              onClick={() => {
                if (!isOnline()) return showNetworkError();
                this.addClient.show();
              }}
            >
              {t('control.add')}
            </Button>
          </TopBar>
          <Table
            bordered
            pagination={false}
            size="default"
            style={{ width: '100%', overflowX: 'scroll', margin: '10px 0 0' }}
            columns={clientsTableColumns}
            dataSource={alphabeticalSort(clients, 'name')
              .map((el, index) => ({ ...el, key: index }))}
          />
        </Content>
        <AddClient ref={(ref) => { this.addClient = ref; }} />
        <EditClient item={selectedItem} ref={(ref) => { this.editClient = ref; }} />
      </Page>
    );
  }
}

export default withTranslation()(Clients);
