import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { PercentageOutlined } from '@ant-design/icons';
import { Modal, Tag } from 'antd';
import { Row, Column } from '../index';
import { validateNumberInput } from '../../utils/helpers';
import { NumberInput } from '../../common';
import './style.scss';

const { CheckableTag } = Tag;

const predefinedSalesPercent = ['5', '10', '20', '50', '100'];
const predefinedSalesAmount = ['3', '5', '10', '15'];

class SaleModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: props.bills.find(bill => bill.id === props.activeKey).sale.amount || '',
      percent: props.bills.find(bill => bill.id === props.activeKey).sale.percent || '',
    };
  }

  applySale = () => {
    const { activeKey, actions: { addSaleToBill } } = this.props;
    const { percent, amount } = this.state;
    addSaleToBill(activeKey, percent !== '' ? percent : 0, amount !== '' ? amount : 0);
    this.close();
  };

  close = () => {
    const { close } = this.props;
    close();
    this.cleanState();
  };

  cleanState = () => this.setState({
    amount: '', percent: '',
  });

  updateSale = (value, key) => this.setState({ [key]: value > 100 ? 100 : value });

  render() {
    const { t, currency, visible = false } = this.props;
    const { amount, percent } = this.state;
    return (
      <Modal
        maskClosable={false}
        centered
        width="100%"
        style={{ maxWidth: '500px' }}
        visible={visible}
        onOk={this.applySale}
        onCancel={this.close}
        okButtonProps={{ size: 'large' }}
        cancelButtonProps={{ size: 'large' }}
        okText={t('control.apply')}
        cancelText={t('control.cancel')}
        title={t('global.sale')}
      >
        <Column width="100%" className="default-card">
          <h3>{t('sale.modalPercent')}</h3>
          <Row width="100%" justifyContent="flex-start">
            {predefinedSalesPercent.map(sale => (
              <CheckableTag
                key={`sale-percent-${sale}`}
                onChange={() => this.updateSale(sale, 'percent')}
                checked={sale === percent}
              >
                {sale}%
              </CheckableTag>
            ))}
            <NumberInput
              onKeyUp={validateNumberInput}
              addonAfter={<PercentageOutlined />}
              value={percent}
              controlled
              style={{ margin: '0 0 8px auto', width: '150px' }}
              placeholder="0"
              type="tel"
              onChange={e => this.updateSale(e.target.value, 'percent')}
            />
          </Row>
        </Column>
        <Column width="100%" className="default-card" margin="10px 0 0 0">
          <h3>{t('sale.modalAmount')}</h3>
          <Row width="100%" justifyContent="flex-start">
            {predefinedSalesAmount.map(sale => (
              <CheckableTag
                key={`sale-amount-${sale}`}
                onChange={() => this.updateSale(sale, 'amount')}
                checked={sale === amount}
              >
                {sale}{currency}
              </CheckableTag>
            ))}
            <NumberInput
              onKeyUp={validateNumberInput}
              addonAfter={currency}
              value={amount}
              controlled
              style={{ margin: '0 0 8px auto', width: '150px' }}
              placeholder="0"
              type="tel"
              onChange={e => this.updateSale(e.target.value, 'amount')}
            />
          </Row>
        </Column>
      </Modal>
    );
  }
}

export default withTranslation()(SaleModal);

SaleModal.propTypes = {
  close: PropTypes.func,
  visible: PropTypes.bool,
};
