import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

class NumberInput extends Component {
  constructor(props) {
    super(props);
    const { value = '', controlled = false } = props;
    this.state = {
      integer: controlled || value !== '' ? value : '',
    };
  }

  onChange = (e) => {
    const { value } = e.target;
    const reg = /^[0-9]*$/;
    if ((!isNaN(value) && reg.test(value)) || value === '') {
      this.props.onChange(e);
      this.setState({ integer: value });
    }
  };

  render() {
    const { value = '', controlled = false, ...props } = this.props;
    const { integer } = this.state;
    return (
      <Input
        {...props}
        value={controlled ? value : integer}
        onChange={this.onChange}
        maxLength={25}
      />
    );
  }
}

export default NumberInput;

NumberInput.propTypes = {
  controlled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
