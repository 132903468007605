import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, message } from 'antd';

class InstallBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInstallBlock: false,
      deferredPrompt: null,
      isInstalled: !!window?.matchMedia('(display-mode: standalone)')?.matches,
    };
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.setState({ showInstallBlock: true, deferredPrompt: e });
    });
    window.addEventListener('appinstalled', () => {
      console.log('a2hs installed');
      this.setState({ showInstallBlock: false, isInstalled: true });
    });
  }

  installClick = () => {
    this.setState({ showInstallBlock: false });
    const { deferredPrompt } = this.state;
    // Show the prompt
    try {
      deferredPrompt.prompt();
    } catch (e) {
      console.log(e);
      message.error('Something went wrong!');
    }
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        this.setState({ showInstallBlock: false, isInstalled: true });
      } else {
        this.setState({ showInstallBlock: true });
      }
      this.setState({ deferredPrompt: null });
    });
  }

  render() {
    const { t } = this.props;
    const { showInstallBlock, isInstalled } = this.state;
    return (
      <div className={`install-block ${showInstallBlock && !isInstalled ? 'visible' : ''}`}>
        <div className="install-block-img">
          <img src="/assets/logo.png" alt="Shoptoria Logo" />
        </div>
        <div className="install-block-body">
          <h3>{t('landing.installTitle')}</h3>
          <p>{t('landing.installText')}</p>
          <Button type="primary" onClick={this.installClick}>{t('landing.installButton')}</Button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(InstallBlock);
