import React from 'react';
import { StepInfo } from '../index';

export const consumptionTourSteps = (t, goToNextStep, lastStepEvent) => [
  {
    selector: '[data-tour="consumption-tour-1"]',
    stepInteraction: false,
    content: () => (
      <StepInfo
        title={t('reactour.consumption.step1.title')}
        text={t('reactour.consumption.step1.text')}
        buttonText={t('reactour.consumption.step1.buttonText')}
        buttonAction={() => goToNextStep(false)}
      />
    ),
  }, {
    selector: '[data-tour="consumption-tour-2"]',
    stepInteraction: false,
    position: 'right',
    content: () => (
      <StepInfo
        title={t('reactour.consumption.step2.title')}
        text={t('reactour.consumption.step2.text')}
        buttonText={t('reactour.consumption.step2.buttonText')}
        buttonAction={() => lastStepEvent()}
      />
    ),
  },
];
