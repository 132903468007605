import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ClientsModal from './ClientsModal';
import { addClientToBill, removeClientFromBill } from '../../redux/statistic/actions';

function mapStateToProps(state) {
  return {
    marketing: state.marketing,
    currency: state.business.currency,
    activeBills: state.statistic.activeBills,
    activeKey: state.statistic.activeKey,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        addClientToBill,
        removeClientFromBill,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClientsModal));
