import i18n from '../../utils/translation';
import { generateId } from '../../utils/helpers';
import { showErrorMessage, showMessage, showNetworkError } from '../message/actions';
import {
  addEmployerRequest, addJobRequest,
  editEmployerRequest, editJobRequest, getEmployersRequest, getJobsRequest,
  removeEmployerRequest, removeJobRequest,
} from '../../api/db/access';
import {
  getPersistedAccessEmployers, getPersistedAccessJobs, isOnline, persistWithKey, removeDataFromPersist,
} from '../../persistance';
import { idbAccessEmployers, idbAccessJobs } from '../../config';

export const GET_EMPLOYERS = 'GET_EMPLOYERS';
export const EDIT_EMPLOYER = 'EDIT_EMPLOYER';
export const ADD_EMPLOYER = 'ADD_EMPLOYER';
export const REMOVE_EMPLOYER = 'REMOVE_EMPLOYER';
export const GET_JOBS = 'GET_JOBS';
export const EDIT_JOB = 'EDIT_JOB';
export const ADD_JOB = 'ADD_JOB';
export const REMOVE_JOB = 'REMOVE_JOB';

export function getEmployers(persisted = false) {
  return (dispatch) => {
    if (persisted || !isOnline()) {
      return getPersistedAccessEmployers()
        .then(data => dispatch({
          type: GET_EMPLOYERS, payload: data.map(el => ({ ...el, id: parseInt(el.id, 10) })),
        }));
    }
    return getEmployersRequest()
      .then(res => dispatch({ type: GET_EMPLOYERS, payload: res.data }));
  };
}
export function editEmployer(employer) {
  return async (dispatch) => {
    if (!isOnline()) return showNetworkError();
    const newEmployer = { ...employer };
    delete newEmployer.id;

    return editEmployerRequest(employer.id, newEmployer)
      .then((res) => {
        const newEmployer = { ...res.data, id: parseInt(employer.id, 10) };

        dispatch({ type: EDIT_EMPLOYER, payload: newEmployer });

        persistWithKey(newEmployer.id, newEmployer, idbAccessEmployers);

        dispatch(showMessage({ text: i18n.t('actions.employerEditedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function addEmployer(employer, withNotification = true) {
  return (dispatch) => {
    if (!isOnline()) return showNetworkError();
    const id = generateId();
    addEmployerRequest(id, employer)
      .then((res) => {
        const employer = { ...res.data, id };

        dispatch({ type: ADD_EMPLOYER, payload: employer });

        persistWithKey(employer.id, employer, idbAccessEmployers);

        if (withNotification) dispatch(showMessage({ text: i18n.t('actions.employerCreatedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function removeEmployer(id) {
  return (dispatch) => {
    if (!isOnline()) return showNetworkError();

    return removeEmployerRequest(id)
      .then(() => {
        dispatch({ type: REMOVE_EMPLOYER, payload: id });

        removeDataFromPersist(id, idbAccessEmployers);

        dispatch(showMessage({ text: i18n.t('actions.employerDeletedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function getJobs(persisted = false) {
  return (dispatch) => {
    if (persisted || !isOnline()) {
      return getPersistedAccessJobs()
        .then(data => dispatch({ type: GET_JOBS, payload: data }));
    }
    return getJobsRequest()
      .then(res => dispatch({ type: GET_JOBS, payload: res.data }))
      .catch(showErrorMessage);
  };
}

export function editJob(job) {
  return async (dispatch) => {
    if (!isOnline()) return showNetworkError();

    const newJob = { ...job };
    delete newJob.id;
    delete newJob.salaryPerDay;
    delete newJob.salaryPerHour;

    return editJobRequest(job.id, newJob)
      .then((res) => {
        const newJob = { ...res.data, id: parseInt(job.id, 10) };

        dispatch({ type: EDIT_JOB, payload: newJob });

        persistWithKey(newJob.id, newJob, idbAccessJobs);

        dispatch(showMessage({ text: i18n.t('actions.jobEditedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function addJob(data) {
  return async (dispatch) => {
    if (!isOnline()) return showNetworkError();

    const id = generateId();
    addJobRequest(id, data)
      .then((res) => {
        const newJob = { ...res.data, id };

        dispatch({ type: ADD_JOB, payload: newJob });

        persistWithKey(newJob.id, newJob, idbAccessJobs);

        dispatch(showMessage({ text: i18n.t('actions.jobCreatedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function removeJob(id) {
  return (dispatch) => {
    if (!isOnline()) return showNetworkError();

    return removeJobRequest(id)
      .then(() => {
        dispatch({ type: REMOVE_JOB, payload: id });

        removeDataFromPersist(id, idbAccessJobs);

        dispatch(showMessage({ text: i18n.t('actions.jobDeletedTitle') }));
      })
      .catch(showErrorMessage);
  };
}
