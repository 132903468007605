import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  Button, Input, Modal, Select, Tag,
} from 'antd';
import {
  Sizes, GeneralTable, ComponentsTable, EquipmentTable,
} from '../../common';
import { Column, FileUpload, Row } from '../../components';
import { getCleanState, isProductValid, updateSizes } from './helper';
import { generateId, getDefaultImageByType, getSizeName } from '../../utils/helpers';
import { deleteModal } from '../../utils/modals';
import './style.scss';
import { isOnline } from '../../persistance';
import { showNetworkError } from '../../redux/message/actions';

const { CheckableTag } = Tag;
const { Option } = Select;

class ProductModal extends Component {
  constructor(props) {
    super(props);
    const {
      product = getCleanState(props.t),
    } = props;
    this.state = {
      ...product,
      consumables: product.consumables ? product.consumables : [],
      volume: product.volume ? product.volume : product.sizes.reduce((res, key) => ({ ...res, [key]: 0 }), {}),
      inEdit: false,
      loading: false,
    };
  }

  edit = () => {
    const {
      t, product, actions: { editProduct, showMessage },
    } = this.props;
    const {
      components, name, price, category, sizes, photo, volume, consumables,
    } = this.state;
    if (isProductValid({
      t, showMessage, components, name, price, volume, sizes,
    })) {
      this.setState({ loading: true });
      editProduct({
        ...product,
        consumables,
        components,
        name,
        price,
        category: category === t('global.all') ? null : category,
        sizes,
        volume,
        photo,
      }).then((data) => {
        this.setState({
          ...product,
          components,
          consumables,
          name,
          price,
          category,
          sizes,
          volume,
          ...data,
          inEdit: false,
          loading: false,
        });
      });
    }
  };

  close = () => {
    const { close } = this.props;
    close();
    this.cleanState();
  };

  cleanState = () => {
    const { product } = this.props;
    this.setState({ ...product, inEdit: false });
  };

  delete = () => {
    const { product, close, actions: { removeProduct } } = this.props;
    removeProduct(product);
    close();
  };

  manageSizes = sizes => updateSizes(sizes, this);

  createConsumable = item => this.setState(state => ({
    consumables: [...state.consumables, { ...item, id: generateId(), type: 'equipment' }],
  }));

  editConsumable = item => this.setState(state => ({
    consumables: [...state.consumables.filter(el => el.id !== item.id), item],
  }));

  removeConsumable = id => this.setState(state => ({
    consumables: state.consumables.filter(el => el.id !== id),
  }));

  UNSAFE_componentWillReceiveProps(nextProps) {
    if ((!this.props.product || nextProps.product.name !== this.props.product.name) && nextProps.product) {
      this.setState({
        ...nextProps.product,
        volume: nextProps.product.sizes.reduce((res, key) => ({ ...res, [key]: 0 }), {}),
      });
    }
  }

  render() {
    const {
      t, menu, visible, product, equipment, makeCopy,
    } = this.props;
    const {
      loading, name, price, components, sizes, category, photo, inEdit, volume, consumables,
    } = this.state;

    if (product == null) return <div />;

    return (
      <Modal
        maskClosable={false}
        centered
        width="700px"
        onCancel={this.close}
        visible={visible}
        footer={(
          <Row width="100%" justifyContent="flex-end">
            {inEdit ? (
              <Fragment>
                <Button
                  style={{ marginRight: '10px' }}
                  onClick={this.cleanState}
                  type="default"
                  size="large"
                >{t('control.cancel')}
                </Button>
                <Button size="large" loading={loading} type="primary" onClick={this.edit}>{t('control.save')}</Button>
              </Fragment>
            ) : (
              <Fragment>
                <Button
                  size="large"
                  style={{ marginRight: '10px' }}
                  onClick={() => deleteModal(this.delete)}
                  icon={<DeleteOutlined />}
                />
                <Button
                  size="large"
                  style={{ marginRight: 'auto' }}
                  onClick={() => {
                    if (!isOnline()) return showNetworkError();
                    return makeCopy();
                  }}
                  icon={<CopyOutlined />}
                />
                <Button
                  size="large"
                  onClick={() => {
                    if (!isOnline()) return showNetworkError();
                    this.setState({ inEdit: true });
                  }}
                >{t('control.edit')}
                </Button>
              </Fragment>
            )}
          </Row>
        )}
        className="create-product product-modal"
        title={t('global.product')}
      >
        <Column width="100%" alignItems="flex-start">
          {inEdit ? (
            <Row
              className="default-card mobile-item-column"
              width="100%"
              justifyContent="flex-start"
              alignItems="flex-start"
              margin="0 0 10px"
            >
              <Column>
                <h3>{t('global.image')}</h3>
                <FileUpload imageUrl={photo} callback={photo => this.setState({ photo })} />
              </Column>
              <Column alignItems="flex-start" margin="0 0 0 18px" className="mobile-full-width mobile-product-column">
                <Row alignItems="flex-start" className="mobile-product-column mobile-full-width">
                  <Column
                    alignItems="flex-start"
                    margin="0 16px 0 0"
                    className="mobile-full-width mobile-product-column"
                  >
                    <h3>{t('global.name')}</h3>
                    <Input
                      className="create-product-input item-input"
                      placeholder={t('product.modalNamePlaceholder')}
                      onChange={e => this.setState({ name: e.target.value })}
                      value={name}
                    />
                  </Column>
                  <Column alignItems="flex-start" className="mobile-full-width">
                    <h3>{t('global.category')}</h3>
                    <Select
                      defaultValue={category || t('global.all')}
                      placeholder={t('product.modalChooseCategory')}
                      className="product-modal-select"
                      onChange={el => this.setState({ category: el })}
                    >
                      {menu.categories && [{ name: t('global.all') }, ...menu.categories].map(el => (
                        <Option key={el.name} value={el.name}>{el.name}</Option>
                      ))}
                    </Select>
                  </Column>
                </Row>
                <Column alignItems="flex-start" margin="12px 0 0">
                  <h3>{t('product.modalSizes')}</h3>
                  <Sizes
                    tags={sizes}
                    callback={sizes => this.manageSizes(sizes)}
                  />
                </Column>
              </Column>
            </Row>
          ) : (
            <Row
              className="default-card mobile-item-column"
              width="100%"
              justifyContent="flex-start"
              alignItems="flex-start"
              margin="0 0 10px"
            >
              <Column width="180px">
                <h3>{t('global.image')}</h3>
                <img
                  src={photo || getDefaultImageByType('product')}
                  alt="avatar"
                  style={{
                    opacity: photo ? 1 : '0.5',
                    borderRadius: '4px',
                    width: '180px',
                    height: '110px',
                    border: '1px solid #d9d9d9',
                  }}
                />
              </Column>
              <Column alignItems="flex-start" margin="0 0 0 18px" className="mobile-full-width mobile-product-column">
                <Row alignItems="flex-start" className="mobile-product-column mobile-full-width">
                  <Column
                    alignItems="flex-start"
                    margin="0 20px 0 10"
                    className="mobile-full-width mobile-product-column"
                    width="200px"
                  >
                    <h3>{t('global.name')}</h3>
                    <h4 className="product-modal-subtitle item-name">{name}</h4>
                  </Column>
                  <Column alignItems="flex-start">
                    <h3>{t('global.category')}</h3>
                    <h4 className="product-modal-subtitle">{category || t('global.all')}</h4>
                  </Column>
                </Row>
                <h3 style={{ marginTop: '12px' }}>{t('product.modalSizes')}</h3>
                <Row flexWrap="wrap" margin="0 0 10px" alignItems="flex-start" justifyContent="flex-start">
                  {sizes.map(size => (
                    <CheckableTag key={`components-${JSON.stringify(size)}`} className="create-product-tag" checked>
                      {getSizeName(size)}
                    </CheckableTag>
                  ))}
                </Row>
              </Column>
            </Row>
          )}
          <GeneralTable
            volume={volume}
            price={price}
            sizes={sizes}
            inEdit={inEdit}
            components={components}
            consumables={consumables}
            callback={data => this.setState({ ...data })}
          />
          <ComponentsTable
            components={components}
            sizes={sizes}
            volume={volume}
            inEdit={inEdit}
            callback={data => this.setState({ ...data })}
          />
          <EquipmentTable
            sizes={sizes}
            edit={this.editConsumable}
            create={this.createConsumable}
            remove={this.removeConsumable}
            equipment={{ idMap: equipment.idMap, items: consumables }}
            inEdit={inEdit}
            showEmpty
          />
        </Column>
      </Modal>
    );
  }
}

export default withTranslation()(ProductModal);
