import React, { Component } from 'react';
import {
  CloseOutlined, SearchOutlined, UserOutlined, PhoneOutlined, BarcodeOutlined, MobileOutlined, MailOutlined,
} from '@ant-design/icons';
import {
  Empty, Input, Modal, Button,
} from 'antd';
import { Column, Row } from '../Grid';
import { castToNormalFloat, filterFunc, prepareCardNumber } from '../../utils/helpers';
import AddClient from '../../view/Marketing/Clients/AddClient';
import { isOnline } from '../../persistance';
import { showNetworkError } from '../../redux/message/actions';
import './style.scss';

class ClientsModal extends Component {
  constructor(props) {
    super(props);
    const { activeBills, activeKey } = props;
    const bill = activeBills.find(bill => bill.id === activeKey);
    this.state = {
      itemSearch: '',
      selectedClient: bill.client || null,
    };
  }

  applyUserToBill = () => {
    const { actions: { addClientToBill }, close } = this.props;
    const { selectedClient } = this.state;
    addClientToBill({ ...selectedClient, cardNumber: parseInt(selectedClient.cardNumber, 10) });
    close();
  }

  removeUser = () => {
    const { actions: { removeClientFromBill }, activeKey } = this.props;
    removeClientFromBill(activeKey);
    this.setState({ selectedClient: null });
  }

  render() {
    const {
      t, close, currency, marketing: { clients, groups }, activeBills, activeKey,
    } = this.props;
    const { itemSearch, selectedClient } = this.state;
    const filteredItems = filterFunc(
      clients.map(client => ({ ...client, cardNumber: prepareCardNumber(client.cardNumber) })),
      ['name', 'cardNumber', 'phone'],
      itemSearch,
    );
    const selectedGroup = groups.find(el => el.id === selectedClient?.group) || { name: '-', count: 0 };
    const bill = activeBills.find(bill => bill.id === activeKey);

    return (
      <Modal
        centered
        visible
        onCancel={close}
        footer={null}
        width="99%"
        style={{ height: '95vh' }}
        title={t('global.clients')}
        className="clients-modal"
      >
        <Row width="100%" height="100%" justifyContent="flex-start" alignItems="flex-start">

          <Column height="100%" justifyContent="flex-start" width="30%" className="list-wrapper">
            <Input
              prefix={<SearchOutlined />}
              value={itemSearch}
              onChange={e => this.setState({ itemSearch: e.target.value })}
              style={{ width: '100%' }}
              suffix={<CloseOutlined onClick={() => this.setState({ itemSearch: '' })} />}
            />
            {filteredItems.length > 0 ? (
              <div className="list-short">
                {filteredItems.map(client => (
                  <Column
                    key={client.id}
                    width="100%"
                    className={`list-cell ${selectedClient && selectedClient.id === client.id
                      ? 'active'
                      : ''}`}
                    margin="0 0 12px"
                    onClick={() => this.setState({ selectedClient: client })}
                  >
                    <Row width="100%" justifyContent="space-between">
                      <Row alignItems="center" margin="0 0 5px">
                        <UserOutlined />
                        <h3>{client.name}</h3>
                      </Row>
                      <Row alignItems="center" margin="0 0 5px">
                        <BarcodeOutlined />
                        <h3>#{prepareCardNumber(client.cardNumber)}</h3>
                      </Row>
                    </Row>
                    <Row alignItems="center" margin="0 0 5px">
                      <PhoneOutlined />
                      <h4>{client.phone || '-'}</h4>
                    </Row>
                  </Column>
                ))}
              </div>
            ) : (
              <Empty style={{ margin: '20px auto' }} />
            )}
            <Button
              style={{ marginTop: '12px' }}
              className="add-button"
              size="large"
              onClick={() => {
                if (!isOnline()) return showNetworkError();
                this.addClient.show();
              }}
            >
              {t('control.add')}
            </Button>
          </Column>
          <Column width="70%" className="client-page-wrapper" height="100%" justifyContent="flex-start">
            {selectedClient ? (
              <Column width="100%" className="client-page" height="100%" justifyContent="flex-start">
                <Row
                  width="100%"
                  justifyContent="space-between"
                  margin="0 0 12px"
                >
                  <Column
                    width="calc(100% - 237px)"
                    className="default-card client-page-header"
                    justifyContent="space-between"
                  >
                    <h2>{t('global.client')}</h2>
                    <h3>{selectedClient.name}</h3>
                  </Column>
                  <Column justifyContent="space-between" className="default-card client-page-header" width="225px">
                    <h2>{t('global.cardNumber')}</h2>
                    <h3>#{prepareCardNumber(selectedClient.cardNumber)}</h3>
                  </Column>
                </Row>
                <Row
                  width="100%"
                  justifyContent="space-between"
                  margin="0 0 12px"
                >
                  <Column className="default-card client-page-content" width="calc(100% - 237px)">
                    <h2>
                      {
                      selectedGroup?.type === 'sale'
                        ? t('marketing.groups.sale')
                        : t('marketing.groups.bonus')
                    }
                    </h2>
                    <h3>{selectedGroup?.name}</h3>
                  </Column>
                  <Column justifyContent="space-between" className="default-card client-page-header" width="225px">
                    <h2>
                      {
                        selectedGroup?.type === 'sale'
                          ? t('marketing.groups.salePercent')
                          : t('marketing.groups.bonusPercent')
                    }
                    </h2>
                    <h3>{selectedGroup?.count}%</h3>
                  </Column>
                </Row>
                <Row
                  width="100%"
                  justifyContent="space-between"
                  margin="0 0 12px"
                >
                  <Column className="default-card client-page-content" width="calc(100% - 237px)">
                    <h2>
                      {t('landing.phone')}
                    </h2>
                    <Row alignItems="center">
                      <MobileOutlined style={{ fontSize: '20px', marginRight: '8px' }} />
                      <h3>{selectedClient.phone || '-'}</h3>
                    </Row>
                  </Column>
                  <Column justifyContent="space-between" className="default-card client-page-header" width="225px">
                    <h2>
                      {t('marketing.clients.profitCount')}
                    </h2>
                    <h3>{castToNormalFloat(selectedClient.profitCount)}{currency}</h3>
                  </Column>
                </Row>
                <Row
                  width="100%"
                  justifyContent="space-between"
                  margin="0 0 12px"
                >
                  <Column className="default-card client-page-content" width="calc(100% - 237px)">
                    <h2>
                      {t('login.email')}
                    </h2>
                    <Row alignItems="center">
                      <MailOutlined style={{ fontSize: '22px', marginRight: '8px' }} />
                      <h3>{selectedClient.email || '-'}</h3>
                    </Row>
                  </Column>
                  <Column justifyContent="space-between" className="default-card client-page-header" width="225px">
                    <h2>
                      {t('marketing.clients.bonusAmount')}
                    </h2>
                    <h3>{selectedClient.bonusCount}{currency}</h3>
                  </Column>
                </Row>
                {bill.client && bill.client.id === selectedClient.id ? (
                  <Button
                    type="primary"
                    size="large"
                    className="client-page-submit"
                    onClick={this.removeUser}
                  >{t('control.remove')}
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    size="large"
                    className="client-page-submit"
                    onClick={this.applyUserToBill}
                  >{t('control.apply')}
                  </Button>
                )}
              </Column>
            ) : (
              <Empty style={{ margin: '20px auto' }} />
            )}
          </Column>
        </Row>

        <AddClient ref={(ref) => { this.addClient = ref; }} />

      </Modal>
    );
  }
}

export default ClientsModal;
