import React from 'react';
import { Select } from 'antd';
import { getUnits } from '../utils/helpers';

const { Option } = Select;

const units = ['g', 'ml', 'pc'];

const UnitsDropdown = ({
  callback = () => {}, excludeList = [], readOnly = false, value = null,
}) => (
  <Select
    defaultValue="g"
    style={{ width: 65 }}
    {...(value ? { value } : {})}
    onChange={e => callback(e)}
    disabled={readOnly}
  >
    {units.filter(unit => !excludeList.includes(unit)).map(unit => (
      <Option key={unit} value={unit}>{getUnits(unit)}</Option>
    ))}
  </Select>
);

export default UnitsDropdown;
