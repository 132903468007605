/* eslint-disable */

export default {
  general: {
    question1Title: 'Що таке пробний період?',
    question1Answer: 'Після реєстрації перші 15 днів ви безкоштовно працюєте в тарифі Startup. Ми допоможемо заповнити меню та почати продавати, надішлемо навчальні відео й статті, відповімо на всі запитання.',
    question2Title: 'Як можна оплатити?',
    question2Answer: 'Здійсніть передплату банківською карткою в адмін-панелі.',
    question3Title: 'Я зможу потім перейти до іншого тарифного плану?',
    question3Answer: 'Так, ви завжди можете змінити тариф. Для цього напишіть нам.',
    question4Title: 'Скільки коштує встановити Shoptoria?',
    question4Answer: 'Ви сплачуєте лише за передплату. Прихованих або додаткових платежів за налаштування в нас немає.',
    question5Title: 'Якщо я оплачу за рік та невдовзі закрию заклад, ви повернете гроші?',
    question5Answer: 'Так, якщо ви закриєте заклад, ми повернемо гроші. Якщо у вас ремонт або переїзд, ми зупинимо передплату на цей час.',
    question6Title: 'Чи потрібно укладати договір?',
    question6Answer: 'Ми працюємо за публічною офертою, тому укладати паперовий договір необов’язково. Напишіть нам, якщо вам потрібно підписати окремий договір.',
  }
}
