import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { ModalHOC } from '../../common';
import ModalMenu from './ModalMenu';
import { showMessage } from '../../redux/message/actions';
import { getStorage } from '../../redux/storage/actions';
import { getClients } from '../../redux/marketing/actions';

function mapStateToProps(state) {
  return {
    tariff: state.business.tariff,
    isAdmin: state.business.isAdmin,
    activePlace: state.business.activePlace,
    shift: state.shift,
    resolvedBills: state.statistic.resolvedBills,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        getStorage,
        getClients,
      },
      dispatch,
    ),
  };
}

export default ModalHOC(withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalMenu)));
