export const monthsLocale = {
  en: [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November',
    'December',
  ],
  ru: [
    'января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября',
    'декабря',
  ],
  ua: [
    'січня', 'лютого', 'березня', 'квітня', 'травня', 'червня', 'липня', 'серпня', 'вересня', 'жовтня', 'листопада',
    'лютого',
  ],
};
export const getMonthWithLocale = (locale, i) => (
  ['en', 'ru', 'ua'].includes(locale)
    ? monthsLocale[locale][i - 1]
    : monthsLocale['en'][i - 1]
);
