import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createCategory, removeCategory, editCategory } from '../../redux/menu/actions';
import ManageCategories from './ManageCategories';
import { showMessage } from '../../redux/message/actions';
import { ModalHOC } from '../../common';
import { toggleCategoryVisible } from '../../redux/menu/actions/categories';

function mapStateToProps(state) {
  return {
    menu: state.menu,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        createCategory,
        editCategory,
        removeCategory,
        showMessage,
        toggleCategoryVisible,
      },
      dispatch,
    ),
  };
}

export default ModalHOC(connect(mapStateToProps, mapDispatchToProps)(ManageCategories));
