import tour from './tours/en';
import faq from './faq/en';

/* eslint-disable */
export default {
  validation: {
    bonusRangeNoGroup: 'Please, select group for bonus range',
    bonusRangeNoAmount: 'Please, select amount for bonus range',
    saleRangeNoGroup: 'Please, select group for sale range',
    saleRangeNoAmount: 'Please, select amount for sale range',
    promotionDays: 'Please select at least 1 promotion day!',
    saleItems: 'Please selected at least 1 category or item!',
    text: 'Enter some text!',
    forgotPassword: 'Email us to solve this problem!',
    something: 'Something went wrong!',
    email: 'The email address is badly formatted!',
    password: 'Please fill a password field',
    passwordRepeat: 'The passwords you entered doesn\'t match!',
    passwordLength: 'The password should be at least 6 characters!',
    chooseComponent: 'Please, choose component!',
    addonCalculation: 'Please, add addon calculation!',
    addonPrice: 'Please, add addon price!',
    productName: 'Please, add product name!',
    pickSize: 'Please, pick at least one size!',
    addConsumable: 'Please, add at least one consumable',
    productComponents: 'Please, add product components!',
    productPrice: 'Please, add product price!',
    productVolume: 'Please, add product volume!',
    componentCount: 'Please, add component count!',
    addCount: 'Please, add count!',
    itemName: 'Please, add item name!',
    itemPrice: 'Please, add item price!',
    alreadyHaveConsumable: 'You can\'t manage sizes after adding a consumables!',
    emptyName: 'Please, add name!',
    emptyPhone: 'Please, add phone!',
    emptySizes: 'Please, check sizes!',
    emptyCategory: 'Please, add category!',
    emptyGroup: 'Please, add group!',
    emptyTransactionType: 'Please, select at least 1 transaction type!',
    emptyAccount: 'Please, add account!',
    emptySum: 'Please, add total!',
    wrongIncome: 'Expense cannot exceed income',
    pickConsumables: 'Please, pick at least one consumable!',
    consumableName: 'Please, add consumable name!',
    uniqueProductName: 'Product with this name already exist!',
    uniqueComponentName: 'Component with this name already exist!',
    uniqueItemName: 'Item with this name already exist!',
    uniqueAddonName: 'Addon with this name already exist!',
    uniqueConsumableName: 'Consumable with this name already exist',
    uniqueGroupName: 'Group with this name already exist!',
    choose: 'Choose...',
    passwordInUse: 'This password already in use!',
    noSuchEmployer: 'No such employer with this password!',
    emptySalary: 'Please, add at least one type of salary calculation!',
    addJob: 'Please, add a working position!',
    componentInUse: 'You can`t delete component, that already in use as addon or part of receipt!',
    itemInUse: 'You can`t delete consumable, that already in use as part of receipt!',
    zeroValue: 'Salary should be greater then 0!',
    saleMoreThen100: 'Sale percent can\'t be more then 100%!',
    emptyInviteBonus: 'Add invite bonus amount',
    emptyMaxBonus: 'Add maximum bonus percent of bill payment!',
    networkRequired: 'Для роботи з пРРО ви маєте бути онлайн!',
  },
  actions: {
    needNetworkTitle: 'You should be online to perform this action!',
    needNetworkDescription: 'Check you Internet connection.',
    employerEditedTitle: 'Employer successfully edited!',
    employerCreatedTitle: 'Employer successfully created!',
    employerDeletedTitle: 'Employer successfully deleted!',
    jobEditedTitle: 'Job successfully edited!',
    jobCreatedTitle: 'Job successfully created!',
    jobDeletedTitle: 'Job successfully deleted!',
    consumableEditedTitle: 'Consumable successfully edited!',
    consumableCreatedTitle: 'Consumable successfully created!',
    consumableDeletedTitle: 'Consumable successfully deleted!',
    equipmentEditedTitle: 'Equipment successfully edited!',
    equipmentCreatedTitle: 'Equipment successfully created!',
    equipmentDeletedTitle: 'Equipment successfully deleted!',
    financialAccountEditedTitle: 'Financial account successfully edited!',
    financialAccountCreatedTitle: 'Financial account successfully created!',
    financialAccountDeletedTitle: 'Financial account successfully deleted!',
    financialCategoryEditedTitle: 'Financial category successfully edited!',
    financialCategoryCreatedTitle: 'Financial category successfully created!',
    financialCategoryDeletedTitle: 'Financial category successfully deleted!',
    financialTransactionEditedTitle: 'Financial transaction successfully edited!',
    financialTransactionCreatedTitle: 'Financial transaction successfully created!',
    financialTransactionDeletedTitle: 'Financial transaction successfully deleted!',
    keeperEditedTitle: 'Keeper settings successfully edited!',
    marketingLoyaltyEditedTitle: 'Loyalty system successfully edited!',
    marketingClientEditedTitle: 'Client data successfully edited!',
    marketingClientCreatedTitle: 'Client successfully created!',
    marketingClientDeletedTitle: 'Client successfully deleted!',
    marketingGroupEditedTitle: 'Group data successfully edited!',
    marketingGroupCreatedTitle: 'Group successfully created!',
    marketingGroupDeletedTitle: 'Group successfully deleted!',
    marketingSaleEditedTitle: 'Sale data successfully edited!',
    marketingSaleCreatedTitle: 'Sale successfully created!',
    marketingSaleDeletedTitle: 'Sale successfully deleted!',
    menuAddonEditedTitle: 'Addon data successfully edited!',
    menuAddonCreatedTitle: 'Addon successfully created!',
    menuAddonDeletedTitle: 'Addon successfully deleted!',
    menuCategoryEditedTitle: 'Category data successfully edited!',
    menuCategoryCreatedTitle: 'Category successfully created!',
    menuCategoryDeletedTitle: 'Category successfully deleted!',
    menuComponentEditedTitle: 'Component data successfully edited!',
    menuComponentCreatedTitle: 'Component successfully created!',
    menuComponentDeletedTitle: 'Component successfully deleted!',
    menuItemEditedTitle: 'Item data successfully edited!',
    menuItemCreatedTitle: 'Item successfully created!',
    menuItemDeletedTitle: 'Item successfully deleted!',
    menuProductEditedTitle: 'Product data successfully edited!',
    menuProductCreatedTitle: 'Product successfully created!',
    menuProductDeletedTitle: 'Product successfully deleted!',
    storageSupplierEditedTitle: 'Supplier data successfully edited!',
    storageSupplierCreatedTitle: 'Supplier successfully created!',
    storageSupplierDeletedTitle: 'Supplier successfully deleted!',
    storageSupplyEditedTitle: 'Supply data successfully edited!',
    storageSupplyCreatedTitle: 'Supply successfully created!',
    storageSupplyDeletedTitle: 'Supply successfully deleted!',
    storageWasteEditedTitle: 'Waste data successfully edited!',
    storageWasteCreatedTitle: 'Waste successfully created!',
    storageWasteDeletedTitle: 'Waste successfully deleted!',
    storageInventoryEditedTitle: 'Inventory data successfully edited!',
    storageInventoryCreatedTitle: 'Inventory successfully created!',
    storageInventoryDeletedTitle: 'Inventory successfully deleted!',
  },
  feedback: {
    title: 'Send us your feedback!'
  },
  textPlaceholder: 'Shoptoria for me is a...',
  loading: {
    employers: 'Fetching employers data...',
  },
  modals: {
    deleteTitle: 'Are you sure, you want to delete item?',
  },
  actionDrawer: {
    title: 'Action list',
    createProduct: 'Create product',
    createItem: 'Create item',
    createAddon: 'Create addon',
    manageCategories: 'Categories',
    createConsumable: 'Create consumable',
    createModalTitle: 'What you want to create?',
    createReceipt: 'Create a receipt',
  },
  logOut: 'Log out',
  login: {
    header: 'Login',
    email: 'Email',
    password: 'Password',
    submit: 'Log in',
    createAccount: 'Create a account',
    mainPage: 'Back to main page',
    forgotPassword: 'Forgot your password?',
    title: 'Don\'t have an account?',
    description: 'Join Shoptoria today and get a 14 days of free trial!',
  },
  signUp: {
    header: 'Sign Up',
    email: 'Email',
    password: 'Password',
    passwordRepeat: 'Repeat Password',
    submit: 'Create Account',
    logIn: 'Log in exist account',
    terms: 'By clicking on Create Account, I declare that I am over 18 and I agree to',
    termsLink: 'Shoptoria Terms & Conditions',
    title: 'Already have an account?',
    description: 'We are glad to see you there! Log in to continue.',
  },
  layout: {
    keeper: 'Terminal',
    menu: 'Menu',
    statistic: 'Statistic',
    storage: 'Storage',
    store: 'Store',
    inventory: 'Inventory',
    equipment: 'Equipment',
    kits: 'Kits',
    kit: 'Kit',
    items: 'Items',
    bills: 'Bills',
    profit: 'Sales',
    category: 'Categories',
    payments: 'Payments',
    marketing: 'Marketing',
    clients: 'Clients',
    groups: 'Client groups',
    loyalty: 'Loyalty program',
    sales: 'Sales',
    loyaltyExclude: 'Exclusion',
    financial: 'Financial',
    transactions: 'Transactions',
    account: 'Bank Accounts',
    financialReport: 'Report',
    salary: 'Salary',
    shifts: 'Cash Shifts',
    access: 'Access',
    jobs: 'Jobs',
    employers: 'Employers',
    settings: 'Settings',
    general: 'General',
    notifications: 'Notification',
    updates: 'Updates',
    subscription: 'Subscription',
    checkbox: 'Checkbox',
  },
  keeper: {
    promotions: 'Promotions',
    menuSearch: 'Search in menu',
    hi: 'Hi,',
    openCashShift: 'Cash shift opened, we wish you good sales! (＾◡＾)',
  },
  components: {
    new: 'New component',
    modalTitle: 'Create new component',
    modalPlaceholder: 'Component name',
    edit: 'Edit component',
    replacer: 'Can be replaced with',
    add: 'Add component',
  },
  addon: {
    modalTitle: 'Create new addon',
    modalSelect: 'Choose component',
  },
  product: {
    modalTitle: 'Create new product',
    modalNamePlaceholder: 'Product Name',
    modalChooseCategory: 'Choose category',
    modalSizes: 'Product sizes',
  },
  item: {
    modalTitle: 'Create new item',
    modalNamePlaceholder: 'Item Name',
    modalSizes: 'Item sizes',
    forWeight: 'Sale on weight',
    saleCost: 'Sale price',
    price: 'Price per',
    weight: 'Enter item quantity',
    add: 'Add items',
  },
  consumable: {
    modalTitle: 'Create new consumable',
    modalNamePlaceholder: 'Consumable Name',
    add: 'Add consumable',
    edit: 'Edit consumable',
    select: 'Select consumables',
  },
  equipment: {
    add: 'Add kit',
    manage: 'Manage equipment',
    edit: 'Edit kit',
    kitName: 'Kit name',
    helpText: 'All selected materials will be available for replacing material in the product.',
    predefined: 'Predefined kits',
    empty: 'Empty',
  },
  storage: {
    onStore: 'On store',
    supply: 'Supply',
    waste: 'Waste',
    inventory: 'Inventory',
    consumption: 'Consumption',
    suppliers: 'Suppliers',
  },
  waste: {
    modalTitle: 'Add new waste',
    modalEdit: 'Edit waste',
  },
  inventory: {
    modalTitle: 'Add new inventory',
    modalEdit: 'Edit inventory',
    before: 'Before',
    now: 'Now',
    diff: 'Difference',
    profit: 'Income',
    expenses: 'Expenses',
    result: 'Result',
  },
  supply: {
    modalTitle: 'Add new supply',
    modalEdit: 'Edit supply',
    supplier: 'Supplier',
    date: 'Date',
    comment: 'Comment',
    addSupply: 'Add supply',
  },
  supplier: {
    modalTitle: 'Add new supplier',
    modalEdit: 'Edit supplier',
    addSupply: 'Add supplier',
  },
  cost: {
    title: 'Cost',
    count: 'Quantity',
    one: 'Cost',
    price: 'Total',
    foodCost: 'Food cost',
    recommended: 'Recommended price',
    principe: 'We calculate recommended price as a 100% profit from cost.',
    missedItem: 'To get valid product cost calculation, please provide cost for all consumable',
  },
  units: {
    pc: 'pc',
    ml: 'ml',
    g: 'g',
    l: 'l',
    kg: 'kg',
  },
  count: 'pc',
  categories: {
    modalTitle: 'Manage categories',
    add: 'Add category',
    edit: 'Edit category',
  },
  control: {
    print: 'Print',
    create: 'Create',
    cancel: 'Cancel',
    add: 'Add',
    edit: 'Edit',
    save: 'Save',
    delete: 'Delete',
    separate: 'Separate',
    pay: 'Pay',
    close: 'Close',
    apply: 'Apply',
    remove: 'Remove',
    collapse: 'Collapse',
    validate: 'Validate',
    clear: 'Clear',
    submit: 'Submit',
    try: 'Try it',
    open: 'Open',
    update: 'Update',
    start: 'Start',
    back: 'Back',
    next: 'Next',
    check: 'Check',
  },
  sale: {
    modalPercent: 'Percent of sale',
    modalAmount: 'Amount of sale',
    firstMonth: 'The first month of use is only ONE dollar',
  },
  pay: {
    cash: 'Cash',
    card: 'Card',
    bonus: 'Bonuses',
    byBonus: 'With bonus',
    bonusAmount: 'Available bonuses',
    maxBonusAmount: 'Maximum amount to pay with bonuses',
    change: 'Change',
  },
  category: {
    name: 'Category name',
    list: 'Categories list',
    alreadyExist: 'This category already exist!',
  },
  bill: {
    modalTitleSeparate: 'Separate bill',
    nodalActiveBill: 'Active bill',
    modalNewBill: 'New bill',
    count: 'Bills count',
    clean: 'Clean',
    addComment: 'Add comment',
    commentForBill: 'Comment for bill',
  },
  image: {
    modalTitle: 'Select part of image',
    croppedImg: 'Cropped image',
    cardExample: 'Card example',
    cardName: 'Example',
  },
  notification: {
    settingsTitle: 'Notifications',
    telegramToken: 'Telegram Token',
    telegramBot: 'Telegram Bot',
    telegramHelpText1: 'To get your Telegram token, write',
    telegramHelpText2: 'to',
    telegramHelpText3: 'Bot will send You your',
    telegramHelpText4: 'Copy your token to field above.',
    allowedEvents: 'Select events, about what we will send notification to You:',
    openShift: 'Cash shift opened',
    closeShift: 'Cash shift closed',
    addTransaction: 'Financial transaction added',
    addSupply: 'Storage supply added',
    received: 'Message successfully posted!',
    tokenAdded: 'Telegram token saved!',
    formSaved: 'Successfully saved!',
    comingSoon: 'Coming soon!',
    comingSoonDescription: 'We are working hard on this feature right now!',
  },
  marketing: {
    clients: {
      addClient: 'Add new client',
      editClient: 'Edit client',
      name: 'Name',
      gender: 'Gender',
      male: 'Male',
      female: 'Female',
      number: 'Card number',
      profitCount: 'Purchase amount',
      creationDate: 'Creation date',
      bonusAmount: 'Bonus amount',
    },
    groups: {
      createGroup: 'Create sale group',
      editGroup: 'Edit sale group',
      name: 'Group name',
      type: 'Loyalty program',
      sale: 'Discount system',
      bonus: 'Bonus system',
      selectGroup: 'Select group',
      salePercent: 'Sale percent',
      bonusPercent: 'Bonus percent',
    },
    loyalty: {
      bonusDescription1: 'Customers will accumulate bonuses and pay for orders or part of them. To do this, set up a different size of bonuses for your customer groups.',
      bonusDescription2: 'And to increase the percentage of bonuses, turn on automatic switching between groups and indicate the amount of payment for orders, upon reaching which the client will be transferred to the group with a higher percentage of bonuses.',
      saleDescription1: 'Customers will receive a discount on the amount of the check. To do this, set up a different discount rate for your customer groups.',
      saleDescription2: 'And to increase the discount percentage, turn on automatic switching between groups and specify the amount of payment for orders, upon reaching which the client will be transferred to the group with a higher discount percentage.',
      maxBonus: 'Maximum possible % of bill payment with bonuses',
      inviteBonus: 'Invite bonus',
      groupTransit: 'Transition between groups',
      transitCondition: 'Transition conditions',
      yes: 'yes',
      no: 'no',
      excludedCategories: 'Excluded categories',
      excludedCategoriesDescription: 'Categories that are not subject to payment by bonuses, but promotions apply:',
      excludedItems: 'Excluded items',
      excludedItemsDescription: 'Items that are not subject to payment by bonuses, but promotions apply:',
      selectCategories: 'Select categories',
    },
    sales: {
      addSale: 'Add new sale',
      editSale: 'Edit sale',
      saleDate: 'Sale period',
      startDate: 'Sale start date',
      endDate: 'Sale end date',
      saleTime: 'Sale time',
      endTime: 'Sale end time',
      includedCategories: 'Categories',
      includedItems: 'Items',
      saleType: 'Sale type',
      percent: 'Sale percent',
      amount: 'Sale amount',
      activeDays: 'Promotion days',
      needBill: 'Please, open a bill to continue!',
    }
  },
  days: {
    mon: 'Mon',
    tue: 'Tue',
    wed: 'Wed',
    thu: 'Thu',
    fri: 'Fri',
    sat: 'Sat',
    sun: 'Sun',
  },
  global: {
    contacts: 'Contacts',
    nothing: 'Nothing',
    group: 'Group',
    favourites: 'Favourites',
    image: 'Image',
    name: 'Name',
    timeRange: 'Time range',
    startDate: 'Start date',
    endDate: 'End date',
    sale: 'Sale',
    bonus: 'Bonus',
    category: 'Category',
    product: 'Product',
    products: 'Products',
    item: 'Item',
    items: 'Items',
    component: 'Component',
    components: 'Components',
    addon: 'Addon',
    addons: 'Addons',
    consumable: 'Consumable',
    consumables: 'Consumables',
    materials: 'Materials',
    all: 'All',
    examples: 'Examples',
    receipt: 'Receipt',
    size: 'Size',
    bonuses: 'Bonuses',
    total: 'Total',
    price: 'Price',
    pricing: 'Pricing',
    volume: 'Volume',
    calculation: 'Calculation',
    subtotal: 'Sub-total',
    quantity: 'Quantity',
    shortQuantity: 'Quantity',
    search: 'Search',
    cardNumber: 'Card number',
    client: 'Client',
    clients: 'Clients',
    language: 'Language',
    type: 'Type',
    units: 'Units',
    photo: 'Photo',
    profit: 'Profit',
    weightProfit: 'Profit per 100',
    excludeConsumableCost: 'Exclude consumables cost',
    sum: 'Total',
    balance: 'Balance',
    select: 'Select...',
    other: 'Other',
    gross: 'Gross',
    extra: 'Extra charge',
    description: 'Description',
    revenue: 'Revenue',
    sales: 'Sales',
    month: 'per month',
    owner: 'Owner',
  },
  optional: {
    title: 'optional',
    addSoon: 'can be added later',
  },
  admin: {
    signUp: 'Fill your business account data',
    shopName: 'Shop name',
    businessType: 'Type of your business',
    coffee: 'Coffee House',
    cafe: 'Cafe',
    restaurant: 'Restaurant',
    customType: 'Other',
    modalTitle: 'Add admin password',
    adminPassword: 'Admin Password',
    modalDescription: 'Admin password will be used for strict access to inventory, statistic and menu pages.',
    checkPassword: 'Submit',
    checkTitle: 'Your should input admin password!',
    checkType: 'Your should choose shop type!',
    removeAccess: 'Remove Permissions',
    wrongPassword: 'Wrong password!',
    notAdminUser: 'Your should have admin access to use this!',
  },
  time: {
    today: 'Today',
    yesterday: 'Yesterday',
    week: 'Last Week',
    month: 'Last Month',
    custom: 'Custom',
    all: 'All',
    range: 'Time range',
    sortByTime: 'By time',
    sortByDay: 'Per day of week',
    mon: 'Mon',
    tue: 'Tue',
    wed: 'Wed',
    thu: 'Thu',
    fri: 'Fri',
    sat: 'Sat',
    sun: 'Sun',
    offset: 'Timezone'
  },
  bills: {
    new: 'New bill',
    openTime: 'Bill open at',
    closeTime: 'Bill closed at',
  },
  financial: {
    report: 'Financial report',
    safeAccount: 'Safe',
    cashbox: 'Cashbox',
    cardAccount: 'PE card',
    addNewCategory: 'Add new transactions category',
    editCategory: 'Edit transactions category',
    addNewAccount: 'Add financial account',
    editAccount: 'Edit financial account',
    addNewTransaction: 'Create transaction',
    editTransaction: 'Edit transaction',
    categories: 'Transactions categories',
    income: 'Income',
    waste: 'Waste',
    transfer: 'Transfer',
    onlyIncome: 'Only income',
    onlyWaste: 'Only waste',
    allowedOperation: 'Allowed transactions',
    actual: 'Actualization',
    bank: 'Bank transactions',
    supply: 'Supply',
    shift: 'Cash shift',
    exchange: 'Exchange',
    cash: 'Cash',
    card: 'Bank card',
    account: 'Bank account',
    account2: 'Account',
    operation: 'Operation',
    from: 'From account',
    to: 'For account',
    type: 'Type',
    delta: 'Delta',
  },
  settings: {
    title: 'Company settings',
    general: 'General settings',
    shiftAutoCloseTitle: 'Shift closing time',
    shiftAutoCloseSubtitle: 'We will close your active shift automatically at selected time.',
    currentVersion: 'Current version',
    newVersion: 'New version',
    releaseDate: 'Release date',
    newVersionAvailable: 'New version available!',
    managePlace: 'Places',
    choosePlace: 'Choose place',
    currency: 'Currency',
    keeper: 'Keeper Settings',
    keeperDescription: 'Here you can setup which global categories will be shown in',
    keeperDescription2: 'Keeper',
    keeperDescription3: 'tabs.',
    keeperTabs: 'Tabs',
    other: 'Other',
    showCategoryInCard: 'Show category in item card',
    allowBonusPayment: 'Allow payment by bonuses',
    hideEmptyItem: 'Hide item with 0 pc',
    cashier: 'Кассир',
    cashierName: 'Ім\'я кассиру',
    cashierToken: 'Токен кассиру',
    cashierLoginTitle: 'Логін',
    cashierLoginPlaceholder: 'Логін',
    cashierPasswordTitle: 'Логін кассиру',
    cashierPasswordPlaceholder: 'Логін кассиру',
    cashKeyTitle: 'Ключ каси',
    cashKeyPlaceholder: 'Ключ каси',
    resetCashier: 'Інший кассир',
    getQRCodeForBill: 'Показати QR код',
    useTaxesWebhook: 'Подавати данні в ДФС',
    taxTitle: 'ДФС',
    checkboxForceBillSync: 'Синхронізувати',
    checkboxCashShift: 'Кассова зміна в ДФС',
    checkboxCashShiftEmpty: 'Кассова зміна відсутня',
    syncWithTaxService: 'Синхронізовано з ДФС',
    onlySynced: 'Лише синхронізовані',
    onlyUnSynced: 'Не синхроніховані',
    forceOpenCheckboxShift: 'Відкрити кассову зміну в ДФС',
  },
  access: {
    name: 'Name',
    employer: 'Employer',
    addNewEmployer: 'Add employer',
    editEmployer: 'Edit employer',
    passwordDescription: 'Only 4 digits',
  },
  job: {
    jobName: 'Job name',
    selectJob: 'Select a worker position',
    addNewJob: 'Add job',
    editJob: 'Edit job',
    salaryCalculation: 'Salary calculation',
    fixedRate: 'Fixed Rate',
    salaryPerShift: 'Per shift',
    salaryPerMonth: 'Per month',
    flexRate: 'Percentage of sales',
    flexRatePersonal: 'Personal sales',
    flexRateTotal: 'Sales per shift',
    terminalPassword: 'Terminal password',
  },
  statistic: {
    middleBill: 'Average bill',
    popularItem: 'Popular items',
    orders: 'Orders',
  },
  modalMenu: {
    title: 'Functions',
    getShiftReport: 'Shift report',
    getDayReport: 'Day report',
    shiftReport: 'Shift report',
    dayReport: 'Day report',
    closeShift: 'Close shift',
    openShift: 'Open cash shift',
    cashBalance: 'Cash balance',
    sumBalance: 'Cash balance',
    openCashBalance: 'Cash balance at opening',
    closeCashBalance: 'Cash balance on closing',
    expectedCashBalance: 'Expected cash balance',
    afterCollection: 'Cash balance',
    afterCollection2: 'Should be the same with cash balance at opening',
    collectionHelp: 'Before closing the shift, make cash collection and leave only cash money for exchange',
    openBy: 'Was open by',
    closeBy: 'Close by',
    closedBy: 'Close by',
    openTime: 'Open time',
    closeTime: 'Close time',
    openReport: 'Open report',
    employerSales: 'Sales by employer',
    bills: 'Bills',
    manageBills: 'Manage bills',
    changePayments: 'Change payments',
    checkSync: 'Synchronize',
    syncProcess: 'Synchronization...',
    syncProcessDescription: 'We are try to synchronize data!',
    syncSuccess: 'Synchronized!',
    syncSuccessDescription: 'All your data are synchronized.',
    syncFailed: 'Attention!',
    syncFailedDescription: 'You can\'t synchronize data while device is offline!',
    cleanCache: 'Clean cache',
    cacheCleaned: 'Success!',
    cacheCleanedDescription: 'We clean up your caches.',
    consumptionCount: 'Consumption',
  },
  landing: {
    installTitle: 'Install Shoptoria POS!',
    installText: 'You can add Shoptoria POS app to a home screen!',
    installButton: 'Install App',
    capability: 'Capability',
    prices: 'Prices',
    terms: 'Terms and Conditions',
    joinTelegram: 'We are in touch!',
    joinTelegramText1: 'Follow the news, ask questions, share feedback - all in out',
    joinTelegramText2: 'channel.',
    joinTelegramButton: 'Join',
    openPlayMarket: 'In Play Market too!',
    openPlayMarketDescription: 'now available for download in a Play Market!',
    openPlayMarketButton: 'Open App',
    openApp: 'Open App',
    readyToStart: 'Ready to try?',
    readyToStartDescription1: 'Open',
    readyToStartDescription2: 'app to start selling right now',
    joinUs: 'Let\'s work together!',
    workTogetherText1: 'Become part of',
    workTogetherText2: 'right now and get a 2 week of subscription',
    workTogetherText3: 'for free',
    company: 'Company name',
    phone: 'Phone',
    why: 'Why business like Shoptoria',
    heroText: 'cloud automation system',
    heroContent1: 'Shoptoria POS - program of automation and warehouse accounting of catering establishments and HoReCa.',
    heroContent2: 'Convenient and reliable online cash desk for iPad and Android.',
    testimonialTitle1: 'Access from anywhere',
    testimonialDescription1: 'Shoptoria POS is a cloud-based system that gives you online access to your inventory, finances and analytics from anywhere in the world.',
    testimonialTitle2: 'Easy to install and use',
    testimonialDescription2: 'At Shoptoria, we believe in simplicity and convenience. That’s why we’ve designed a system that doesn’t require any special training. Your employees will start selling in 5 minutes.',
    testimonialTitle3: 'Prices that scale with your business',
    testimonialDescription3: 'Android or iPad POS software is meant to be a tool that saves you money by optimizing your business operations, so it shouldn’t cost you a fortune.',
    learnMore: 'Learn more',
    featureTitle1: 'Robust and intuitive POS application',
    featureText1: 'Your waiters, bartenders, baristas and cashiers should be engaging your guests, not staring at your POS. That’s why Shoptoria is designed to be as fast, reliable and convenient as possible.',
    feature1Slide1Title: 'Take orders faster',
    feature1Slide1Text: 'Create categories and products with photos assigned to them, so your staff can sell even faster.',
    feature1Slide2Title: 'Customize dishes',
    feature1Slide2Text: 'Use our modifier feature to sell your dishes in different sizes or add extras.',
    feature1Slide3Title: 'Accept all types of payment',
    feature1Slide3Text: 'Guests can split payment with cash and credit card.',
    feature1Slide4Title: 'Serve as much as you can',
    feature1Slide4Text: 'Create, separate and delete as much bill, as you can.',
    featureTitle2: 'Manage your business from home',
    featureText2: 'Shoptoria food service POS System offers you a feature-rich admin tool that you can use in your browser. Control the workflow of your restaurant from any laptop or tablet around the world.',
    feature2Slide1Title: 'Recipe-based inventory',
    feature2Slide1Text: 'Ingredients are automatically deducted from the inventory after every sale.',
    feature2Slide2Title: 'Fast inventory check',
    feature2Slide2Text: 'Keep your sitting inventory under control, and quickly identify mistakes and discrepancies.',
    feature2Slide3Title: 'Finances',
    feature2Slide3Text: 'Keep track of your transactions, safe drops, and cash flow all in one place.',
    feature2Slide4Title: 'Detailed sales reports',
    feature2Slide4Text: 'Simple and powerful business tools that help you visualize your progress.',
    title: 'Shoptoria benefits',
    advantages: {
      menu: {
        title: 'Menu',
        example: 'Menu example',
        consumable: 'Product consumable',
        componentReplace: 'Component replacement flow',
        modifier: 'Addons for your product',
        sizes: 'Manage product sizes',
      },
      store: {
        title: 'Storage',
        control: 'Purchase cost control',
        onStore: 'Stock balance',
        supply: 'Supply reminder',
        inventory: 'Convenient inventory',
        cost: 'Automatic food cost calculation',
        waste: 'Waste control',
      },
      statistic: {
        title: 'Statistic',
        dashboard: 'Dashboard with basic indicators',
        salesPerDay: 'Sales reports by day',
        salesPerGroup: 'Sales reports by product and category',
        payments: 'Payments flow',
        receipt: 'Receipt history',
        cost: 'Food cost',
      },
      financial: {
        title: 'Financial',
        shifts: 'Cash shifts',
        balance: 'Accounts balance',
        cashFlow: 'Cash flow',
        payroll: 'Payroll',
        transactions: 'Transactions',
      },
      secure: {
        title: 'Security',
        access: 'Manager password to access sensitive information',
        settings: 'Detailed access settings',
      },
    },
    docs: {
      menu: {
        subtitle: 'Intuitive interface for managing your menu',
        hero: 'Add dishes and preparations, analyze your food cost, combine dishes into categories. With Shoptoria you spend minimal time on routine.',
        slide1Text1: 'We will prepare some items, based on Your business type, so You can explore how Terminal works without any time spending.',
        slide1Text2: 'We are try to do as much as we can to simplify your start with Shoptoria!',
        slide2Text1: 'Manage your products sizes, control product cost for different product variations and select different consumable for each size.',
        slide2Text2: 'Make your product as much flexible, as You want!',
        slide3Text1: 'Set the menu and earn more with dish modifiers. Give your customers the opportunity to choose different toppings for pizza, the level of steak doneness, or the sauces to go with it. Using modifiers, the waiter will process any order much faster.',
      },
      store: {
        subtitle: 'Flexible and usable inventory',
        hero: 'Manage inventory balances in real-time mode, make inventory checks, and add supplies using a convenient and intuitive interface.',
        slide1Text1: 'Control your supply - supply price, items count, product cost changing.',
        slide1Text2: 'When you enter a supply into the system, you will see the previous purchase price and variance from the norm.',
        slide2Text1: 'Shoptoria supports all types of inventory operation and report: supply, inventory check, waste, transfer, etc.',
        slide2Text2: 'You will always know how exactly your store changing.',
        slide3Text1: 'We guarantee you will fall in love with inventory checks! All you need do is enter the actual balances into the system and Shoptoria will take care of the rest.',
        slide4Text1: 'Take in touch with info about your consuming count. All component and consumable, that you add to product will be calculate together.',
        slide4Text2: 'You can plan your supplies to always be ready for new clients!',
      },
      financial: {
        subtitle: 'Simple, clear financial reports',
        hero: 'With Shoptoria you’ll easily find every number. For example, the net profit for the previous quarter or how much you spent on salaries in March last year.',
        slide1Text1: 'Take into account rent, water, deliveries or cleaning services. You’ll have a complete report on all your transactions.',
        slide1Text2: 'Sort your transaction by type, control your money transfer and be sure - you wouldn`t miss something!',
        slide2Text1: 'Control your cash drawer. If there is a difference between cash shift balances, you’ll easily find the gap.',
      },
      secure: {
        subtitle: 'You data',
        hero: 'The databases are stored in TIER 3 data centers in Europe and the USA. Management console uses secure HTTPS protocol.',
        slide1Text1: 'Set an admin password for certain actions on the point of sale. For example, for closing an order without payment or viewing sales reports.',
        slide1Text2: 'The only one, who can access to your business data - its You.',
        slide2Text1: 'All you employers get it own terminal access password.',
        slide2Text2: 'All things, that your employers do in Terminal will be identified, so you will always who had open cash shift, close this bill or add this transaction.'
      },
      statistic: {
        subtitle: 'All reports at your fingertips',
        hero: 'Sales data is stored in a secure cloud and is available to you in the browser anytime and everywhere.',
        slide1Text1: 'Analyze your sales by time and days of the week and predict the strain on the wait and kitchen staff.',
        slide1Text2: 'All your sales are grouped by usability tables, so You can analyze it quickly, make some decision or find answer for your question.',
        slide2Text1: 'Keep your eye on your business everywhere — during a vacation, on the run or before you sleep. All your data stored in your phone. All analytics is accessible in the browser from anywhere around the world.',
      },
    },
    sendMessage: 'Send us a message or ask your question by phone',
    contact: 'Contacts',
    hasQuestion: 'Have a question?',
    needHelp: 'Need help?',
    socials: 'Social networks',
    navigation: 'Navigation',
    plan: {
      allInOne: 'Maximum possibilities in one tariff!',
      point: 'Coffee Point, Food truck',
      coffee: 'Coffee House',
      cafe: 'Cafe, Bar',
      restaurant: 'Restaurant',
      items: 'Item count: ∞',
      menu: 'Manage menu',
      statistic: 'Reports and analytics',
      statisticShort: 'Statistic up to 40 days',
      store: 'Store flow',
      financial: 'Cash flow',
      shifts: 'Cash shifts',
      addons: 'Product addons',
      loyalty: 'Loyalty systems',
      promotional: 'Promotional',
      booking: 'Table reservation',
      notify: 'Promotional notification',
      try: 'Join <b>Shoptoria POS</b> right now',
      subscribe: 'Shoptoria works on a subscription model.',
      dollar: 'dollar',
      allFromQuarantine: 'All from <b>QUARANTINE</b>, plus:',
      allFromLite: 'All from <b>LITE</b>, plus:',
      allFromBusiness: 'All from <b>BUSINESS</b>, plus:',
    },
    successSubmit: 'Contact request successfully submitted!',
    faq: {
      title: 'Need some help?',
      subtitle: 'We\'ve got you covered',
      generalBlock: 'General',
      ...faq,
    },
    install: {
      link: 'Installation',
      title: 'How to install Shoptoria POS?',
      subtitle: 'Shoptoria POS is a PWA-based system, so the only thing your should do, is adding shoptoria.net page to your home screen',
      subtitle2: 'Just follow instruction',
      step: 'Step',
      step1: 'Add Shoptoria POS to your Home Screen.',
      step1_1: 'For IOS click on "Share" button.',
      step2: 'Wait a bit for installation finish, then check your Home screen.',
      step3: 'Launch Shoptoria POS as a regular application.',
      finish: 'Installation completed!',
      finish2: 'Shoptoria POS is ready to use',
    }
  },
  payments: {
    cantInteract: 'You cannot use the application until your tariff plan is paid.',
    payedFor: 'Tariff payed until',
    subscription: 'Subscription',
    payments: 'Payments',
    tariff: 'Tariff',
    everyMonth: 'month',
    contactUsLink: 'Email us',
    contactUsText: 'to change the tariff',
    amount: 'Amount',
    status: 'Status',
    Refunded: 'Refunded',
    Approved: 'Approved',
    Pending: 'Pending',
    Declined: 'Declined',
    payForMonth: 'Pay for 1 month',
    upgradeTariff: 'Upgrade to Lite'
  },
  permission: {
    upgradeTariff: 'My Subscription',
    notGrantedTitle: 'You can\'t access this feature!',
    notGrantedDescription: 'Please, upgrade your tariff to use this feature.',
  },
  sales: {
    quarantine: {
      teaser: 'Support for restaurateurs during the coronavirus pandemic. More details >>',
      banner: {
        title: 'in 2020 we don\'t take money!',
        subtitle: 'New tariff "Quarantine - 2020"',
        link: 'We guarantee easy and quick quarantine exit!'
      },
      help: {
        title: 'hurries to the rescue!',
        content1: 'simplifies restaurant business.',
        content2: 'Mobile POS-system solves all issues: online cashier, menu and price management, finance, analytics.',
        content3: 'Works in the cloud, installs in 10 minutes.',
        content4: 'Only for <b>0,00$</b> per month, instead of 15$ until September.',
        content5: 'All features are available in the <b> minimum </b> tariff plan..',
      },
      tariff: {
        title: 'In 2020 we don\'t take money!',
        content1: 'New tariff “<b>Quarantine</b>” - <b>0,00$</b> until <b>31.08.2020</b>.',
        content2: 'Special offer for <b>HoReCa</b> business for quick and easy quarantine exit.',
        content3: 'Take full advantage of the mobile POS system <b>for free</b> until September:',
        testimonial1: 'run a <b>sales cycle</b>',
        testimonial2: 'inspect daily or <b>cash shift</b> reporting',
        testimonial3: 'control <b>finance</b>',
        testimonial4: 'add <b>addons</b> to your products',
        testimonial5: 'analyze <b>sales and top products</b>',
        new: 'Business report available in the Telegram!',
        end: '* until 31.08.2020',
      },
      testimonials: {
        title1: 'Manage menu',
        text1: 'Change prices and composition of products',
        title2: 'Product addons',
        text2: 'Earn more with addons',
        title3: 'Profit analytics',
        text3: 'Business in the form of understandable indicators, tables and graphs',
        title4: 'Cash shift',
        text4: 'Your financial report every day',
      },
      joinUs: 'Become a part of <b>Shoptoria POS</b> now and use <b>for free</b> until September',
    }
  },
  ...tour,
};
