import axios from '../index';
import { getTodayKey } from '../../utils/helpers';

// Shifts flow

export const getTodayShiftsRequest = () => axios.get('/db/shift');

export const getShiftsByDayRequest = date => axios.get(`/db/shift/day/${date}`);

export const updateCashShiftRequest = (id, date, data) => axios.put(`/db/shift/day/${date}`, { id, data });

export const closeCashShiftRequest = (id, date, data) => axios.post(`/db/shift/close/day/${date}`, { id, data });

export const addCashShiftRequest = (id, data) => axios.post(`/db/shift/day/${getTodayKey()}`, { id, data });

export const addCashShiftToCheckboxRequest = () => axios.post('/db/shift/sync-tax', { });
