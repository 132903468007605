import { combineReducers } from 'redux';
import userReducer from './user/reducer';
import keeperReducer from './keeper/reducer';
import messageReducer from './message/reducer';
import menuReducer from './menu/reducer';
import statisticReducer from './statistic/reducer';
import businessReducer from './business/reducer';
import equipmentReducer from './equipment/reducer';
import storageReducer from './storage/reducer';
import financialReducer from './financial/reducer';
import accessReducer from './access/reducer';
import shiftReducer from './shift/reducer';
import communicationReducer from './communication/reducer';
import marketingReducer from './marketing/reducer';

const reducers = {
  user: userReducer,
  keeper: keeperReducer,
  message: messageReducer,
  menu: menuReducer,
  statistic: statisticReducer,
  business: businessReducer,
  equipment: equipmentReducer,
  storage: storageReducer,
  financial: financialReducer,
  access: accessReducer,
  shift: shiftReducer,
  communication: communicationReducer,
  marketing: marketingReducer,
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
