import {
  deleteImage, generateId, uploadImage,
} from '../../../utils/helpers';
import { getPlaceRef } from '../../business/actions';
import {
  addAddonRequest, editAddonRequest, getAddonsDataRequest, removeAddonRequest,
} from '../../../api/db/menu';
import { showErrorMessage, showMessage } from '../../message/actions';
import i18n from '../../../utils/translation';
import {
  getPersistedMenuAddons, isOnline, persist, removeDataFromPersist,
} from '../../../persistance';
import { idbMenuAddons } from '../../../config';

export const GET_ADDONS = 'GET_ADDONS';
export const ADD_ADDONS = 'ADD_ADDONS';
export const EDIT_ADDONS = 'EDIT_ADDONS';
export const REMOVE_ADDONS = 'REMOVE_ADDONS';

export function getAddons(persisted = false) {
  return (dispatch) => {
    if (persisted || !isOnline()) {
      return getPersistedMenuAddons()
        .then(data => dispatch({ type: GET_ADDONS, payload: data.map(el => ({ ...el, type: 'addon' })) }));
    }
    return getAddonsDataRequest()
      .then(res => dispatch({ type: GET_ADDONS, payload: res.data.map(el => ({ ...el, type: 'addon' })) }))
      .catch(showErrorMessage);
  };
}

export function editAddon({
  id, price, count, photo, ref, ...addon
}) {
  return async (dispatch) => {
    const placeRef = await dispatch(getPlaceRef());

    let url = photo;
    let newRef = ref;
    if (typeof photo !== 'string' && photo !== null) {
      newRef = `${placeRef}/addon-${Date.now()}`;
      url = await uploadImage(newRef, photo);
    }

    const newAddon = {
      ...addon, price, count, photo: url, ref: newRef,
    };

    delete newAddon.type;
    delete newAddon.key;
    delete newAddon.cost;
    delete newAddon.replacer;
    delete newAddon.supplyCount;
    delete newAddon.supplyPrice;
    delete newAddon.units;
    delete newAddon.id;

    return editAddonRequest(id, newAddon)
      .then((res) => {
        dispatch({ type: EDIT_ADDONS, payload: ({ ...res.data, id, type: 'addon' }) });

        persist({ ...res.data, id, type: 'addon' }, idbMenuAddons);

        dispatch(showMessage({ text: i18n.t('actions.menuAddonEditedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function createAddon(item) {
  return async (dispatch) => {
    const placeRef = await dispatch(getPlaceRef());

    let url = null;
    let ref = null;
    if (item.photo !== null) {
      ref = `${placeRef}/addon-${Date.now()}`;
      url = await uploadImage(ref, item.photo);
    }

    const id = generateId();
    const { count, price, name } = item;

    const addon = {
      photo: url, ref, componentId: item.id, count, price, name,
    };

    return addAddonRequest(id, addon)
      .then((res) => {
        dispatch({ type: ADD_ADDONS, payload: ({ ...res.data, id, type: 'addon' }) });

        persist({ ...res.data, id, type: 'addon' }, idbMenuAddons);

        dispatch(showMessage({ text: i18n.t('actions.menuAddonCreatedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function removeAddon(item) {
  return dispatch => removeAddonRequest(item.id)
    .then(() => {
      dispatch({ type: REMOVE_ADDONS, payload: item.id });

      if (item.photo) deleteImage(item.ref);

      removeDataFromPersist(item.id, idbMenuAddons);

      dispatch(showMessage({ text: i18n.t('actions.menuAddonDeletedTitle') }));
    })
    .catch(showErrorMessage);
}
