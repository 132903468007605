import React, { Component } from 'react';
import {
  Button, Checkbox, Input, Spin,
} from 'antd';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import { firebase } from '../../../config';
import {
  PageHeader, Column, Row, Link,
} from '../../../components';
import { notificationTypes, showNetworkError } from '../../../redux/message/actions';
import { isOnline } from '../../../persistance';

class Notifications extends Component {
  constructor(props) {
    super(props);
    const { communication } = props;
    this.state = {
      communication,
      pendingSave: false,
      pendingPing: false,
      pendingAddPersonalId: false,
      showConfirm: false,
    };
  }

  submit = () => {
    if (!isOnline()) return showNetworkError();
    const { t, actions: { showMessage, editCommunication } } = this.props;
    const { communication: { allowedEvents } } = this.state;
    this.setState({ pendingSave: true });
    editCommunication({ allowedEvents }).then(() => {
      showMessage({ text: t('notification.formSaved'), type: notificationTypes.SUCCESS });
      this.setState({ pendingSave: false });
    });
  };

  updateCommunicationToken = value => this.setState(state => ({
    communication: { ...state.communication, personalId: value },
  }));

  toggleCommunicationEvents = key => this.setState(state => ({
    communication: {
      ...state.communication,
      allowedEvents: {
        ...state.communication.allowedEvents,
        [key]: !state.communication.allowedEvents[key],
      },
    },
  }));

  ping = () => {
    const { t, actions: { showMessage } } = this.props;
    const { communication } = this.state;
    const apiUrl = 'https://shoptoria-be.herokuapp.com';
    this.setState({ pendingPing: true });
    axios.post(`${apiUrl}/pingBot`, {
      token: communication.personalId,
    })
      .then(() => {
        this.setState({ showConfirm: true, pendingPing: false });
        showMessage({ type: notificationTypes.SUCCESS, text: t('notification.received') });
      })
      .catch((err) => {
        this.setState({ pendingPing: false });
        showMessage({
          type: notificationTypes.ERROR,
          text: err.response.data.error,
        });
      });
  };

  savePersonalId = () => {
    const { t, actions: { showMessage, addPersonalId } } = this.props;
    const { communication: { personalId } } = this.state;
    this.setState({ pendingAddPersonalId: true });
    addPersonalId(personalId).then(() => {
      this.setState({ pendingAddPersonalId: false, showConfirm: false });
      showMessage({ type: notificationTypes.SUCCESS, text: t('notification.tokenAdded') });
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.communication.personalId !== this.props.communication.personalId) {
      this.setState({ communication: nextProps.communication });
    }
  }

  render() {
    const {
      t, user: { version },
    } = this.props;
    const {
      pendingSave, communication, showConfirm, pendingPing, pendingAddPersonalId,
    } = this.state;
    return (
      <Spin wrapperClassName="storage-page settings-page" spinning={!firebase.auth().currentUser}>
        <PageHeader />
        <div className="storage-page-content">
          <Column
            className="mobile-block"
            style={{ maxWidth: '600px' }}
            width="100%"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Column
              margin="0 0 10px"
              width="100%"
              className="default-card settings-block-notification"
            >
              <h2>{t('notification.settingsTitle')}</h2>
              <h3 style={{ margin: '10px 0 5px' }}>{t('notification.telegramToken')}</h3>
              <Row width="100%" justifyContent="flex-start" alignItems="center">
                <Input
                  style={{ width: '100%' }}
                  placeholder="Token"
                  value={communication.personalId}
                  onChange={e => this.updateCommunicationToken(e.target.value)}
                />
                <Button
                  loading={pendingPing}
                  style={{ margin: '0 12px' }}
                  onClick={this.ping}
                  type="primary"
                >{t('control.check')}
                </Button>
                {showConfirm && (
                <Button
                  loading={pendingAddPersonalId}
                  onClick={this.savePersonalId}
                  type="primary"
                >{t('control.save')} token
                </Button>
                )}
              </Row>
              <Column width="100%">
                <h4 style={{ margin: '10px 0 16px', fontWeight: 'normal' }}>
                  <span className="help-text">*</span>{t('notification.telegramHelpText1')} <b>/start</b>&nbsp;
                  {t('notification.telegramHelpText2')}&nbsp;<a target="_blank" href="https://t.me/shoptoria_personal_bot">{t('notification.telegramBot')}</a>.&nbsp;
                  {t('notification.telegramHelpText3')} <b>token</b>.
                  <br />{t('notification.telegramHelpText4')}
                </h4>
                <h3>{t('notification.allowedEvents')}</h3>
                <Checkbox
                  className="checkbox-large"
                  style={{ margin: '5px 0' }}
                  onClick={() => this.toggleCommunicationEvents('open_shift')}
                  checked={communication.allowedEvents.open_shift}
                >{t('notification.openShift')}
                </Checkbox>
                <Checkbox
                  className="checkbox-large"
                  style={{ margin: '5px 0' }}
                  onClick={() => this.toggleCommunicationEvents('close_shift')}
                  checked={communication.allowedEvents.close_shift}
                >{t('notification.closeShift')}
                </Checkbox>
                <Checkbox
                  className="checkbox-large"
                  style={{ margin: '5px 0' }}
                  onClick={() => this.toggleCommunicationEvents('add_transaction')}
                  checked={communication.allowedEvents.add_transaction}
                >{t('notification.addTransaction')}
                </Checkbox>
                <Checkbox
                  className="checkbox-large"
                  style={{ margin: '5px 0' }}
                  onClick={() => this.toggleCommunicationEvents('add_supply')}
                  checked={communication.allowedEvents.add_supply}
                >{t('notification.addSupply')}
                </Checkbox>
              </Column>
            </Column>
            <Button
              loading={pendingSave}
              type="primary"
              size="large"
              className="settings-save"
              onClick={this.submit}
            >{t('control.save')}
            </Button>
            <p className="settings-text">Version:&nbsp;
              <Link to="/app/settings/devCenter" className="settings-invisible-link">
                #{version}@{process.env.REACT_APP_MINOR_VERSION}
              </Link>
            </p>
          </Column>
        </div>
      </Spin>
    );
  }
}

export default withTranslation()(Notifications);
