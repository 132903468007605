import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
  CheckOutlined, CloseOutlined, MinusOutlined, PlusOutlined, SearchOutlined,
} from '@ant-design/icons';
import {
  Modal, Tag, Radio, Button, Typography, Select, Empty, Input,
} from 'antd';
import { Column, Row, ManageEquipments } from '../index';
import { getDefaultImageByType, getEmptyAddon } from '../../utils/helpers';
import './style.scss';

const { Group } = Radio;
const { Text } = Typography;
const { CheckableTag } = Tag;

class AddToBill extends Component {
  constructor(props) {
    super(props);
    const { item } = props;
    const size = item.sizes[0];
    this.state = {
      addons: [],
      size,
      quantity: 1,
      addonSearch: '',
      consumables: this.getConsumableBySize(item.consumables, size),
      components: item.components ? item.components.map(el => el.id) : [],
    };
  }

  close = () => {
    const { close = () => {}, item } = this.props;

    close();

    this.setState({ size: item.sizes[0], addons: [] });
  };

  create = () => {
    const {
      activeKey, item, close = () => {}, actions: { addToBill }, idMap,
    } = this.props;

    const {
      size, addons, quantity, consumables, components,
    } = this.state;

    const componentsDiff = item.components
      ? item.components.map((el, i) => (el.id === components[i] ? null : components[i])).filter(el => el !== null)
      : [];

    addToBill(activeKey, {
      name: item.name,
      id: item.id,
      displayName: item.name + componentsDiff.filter(el => el !== 0)
        .reduce((res, id) => `${res} | ${idMap[idMap[id].componentId].name}`, ''),
      size,
      addons,
      quantity,
      components,
      componentsDiff,
      consumables,
      category: item.category,
      type: item.type,
      price: parseInt(item.price[size], 10) + this.calculateComponents(),
    });

    close();

    this.setState({ size: item.sizes[0], addons: [] });
  };

  getResultPrice = () => {
    const { item } = this.props;

    const { addons, size, quantity } = this.state;

    return (
      parseInt(item.price[size], 10)
      + this.calculateAddons(addons)
      + this.calculateComponents()
    ) * quantity;
  };

  calculateAddons = addons => addons.reduce((sum, el) => sum + parseInt(el.price, 10), 0);

  calculateComponents = () => {
    const { item, idMap } = this.props;

    const { components } = this.state;

    if (!item.components) {
      return 0;
    }

    return item.components.reduce(
      (sum, el, index) => (sum + (el.id === components[index]
        ? 0
        : components[index] === 0
          ? 0
          : idMap[components[index]].price)), 0,
    );
  };

  increase = () => this.setState(state => ({ quantity: state.quantity < 9 ? state.quantity + 1 : 9 }));

  decrease = () => this.setState(state => ({ quantity: state.quantity === 1 ? 1 : state.quantity - 1 }));

  toggleAddons = (addon) => {
    const { addons } = this.state;

    const isIncluded = addons.find(el => el.id === addon.id);

    this.setState(state => ({
      addons: isIncluded ? state.addons.filter(el => el.id !== addon.id) : [...state.addons, addon],
    }));
  };

  getConsumableBySize = (consumables, size) => (consumables
    ? consumables.reduce((res, el) => ({ ...res, [el.name]: el.sizes[size] }), {}) : {});

  changeComponent = (id, index) => this.setState(state => ({
    components: state.components.map((el, i) => (i === index ? id : el)),
  }));

  getFilteredAddons = () => {
    const { addonsProp, idMap } = this.props;

    const { addonSearch } = this.state;

    if (addonSearch.trim() !== '') {
      return addonsProp
        .filter(addon => addon.name.toLowerCase().includes(addonSearch.toLowerCase())
        || idMap[addon.componentId].name.toLowerCase().includes(addonSearch.toLowerCase()));
    }

    return addonsProp;
  };

  render() {
    const {
      item, idMap, t, currency,
    } = this.props;

    const {
      quantity, addons, consumables, size, components, addonSearch,
    } = this.state;

    const addonsIds = addons.map(addon => addon.id);
    const filteredAddons = this.getFilteredAddons();

    return (
      <Modal
        maskClosable={false}
        onCancel={this.close}
        centered
        visible
        style={{ maxHeight: '95vh' }}
        width="95vw"
        height="100vh"
        className="add-item"
        title={item.name}
        footer={(
          <Row width="100%" justifyContent="flex-end">
            <Button
              size="large"
              onClick={this.close}
              style={{ marginLeft: 'auto' }}
              type="secondary"
            >{t('control.cancel')}
            </Button>

            <Button size="large" onClick={this.create} type="primary">{t('control.add')}</Button>
          </Row>
        )}
      >
        <Row width="100%" alignItems="flex-start" className="mobile-column-reverse">
          {item.components && (
            <Column
              alignItems="flex-start"
              justifyContent="flex-start"
              width="322px"
              margin="0 0 10px"
              className="mobile-full-width"
            >
              <h3 className="add-item-subtitle">{t('global.addons')}</h3>

              <Input
                prefix={<SearchOutlined />}
                value={addonSearch}
                onChange={e => this.setState({ addonSearch: e.target.value })}
                style={{ width: '100%' }}
                suffix={<CloseOutlined onClick={() => this.setState({ addonSearch: '' })} />}
              />

              {filteredAddons.length > 0 ? (
                <Row
                  width="100%"
                  justifyContent="flex-start"
                  flexWrap="wrap"
                  margin="15px 0 0"
                  style={{ maxHeight: '290px', overflowY: 'scroll' }}
                >
                  {filteredAddons.map((el) => {
                    const addon = { ...idMap[el.componentId], ...el };

                    return (
                      <div
                        className={`add-item-addon-card ${addonsIds.includes(addon.id) ? 'checked' : ''}`}
                        key={`addon-card-${addon.id}`}
                        onClick={() => this.toggleAddons(el)}
                      >
                        <h3>{addon.name}</h3>

                        <img
                          src={addon.photo || getDefaultImageByType('addon')}
                          style={{ opacity: addon.photo ? 1 : 0.5 }}
                          alt=""
                          className="card-img"
                        />

                        <CheckOutlined style={{ fontSize: '18px' }} className="add-item-addon-card-check" />
                      </div>
                    );
                  })}
                </Row>
              ) : (
                <Empty />
              )}
            </Column>
          )}

          <Column
            alignItems="flex-start"
            width="100%"
            style={{ maxWidth: 'calc(100% - 352px)' }}
            margin="0 0 10px 30px"
            className="default-card mobile-full-size mobile-bottom-margin"
          >
            <h3 className="add-item-subtitle">{t('global.receipt')}</h3>

            {item.components && (
              <Row alignItems="flex-end" flexWrap="wrap" justifyContent="flex-start" margin="0 0 7px">
                {item.components.map((el, index) => {
                  const component = idMap[el.id];

                  return component.replacer && component.replacer.length > 0 ? (
                    <Select
                      key={`select-${el.id}`}
                      value={components[index]}
                      className="add-item-select"
                      style={{ margin: '0 8px 8px 0', minWidth: '100px', width: 'fit-content' }}
                      onChange={id => this.changeComponent(id, index)}
                    >
                      <Select.Option value={component.id}>
                        <p style={{ marginRight: '5px' }}>{component.name}</p>
                      </Select.Option>

                      {component.replacer.map(replacerId => (idMap[replacerId] || replacerId === 0 ? (
                        <Select.Option value={replacerId} key={replacerId}>
                          <p>{replacerId === 0 ? getEmptyAddon(t).name : idMap[replacerId].name}</p>
                        </Select.Option>
                      ) : null))}
                    </Select>
                  ) : (
                    <Tag className="add-item-tag" style={{ margin: '0 8px 8px 0' }} key={el.id}>{component.name}</Tag>
                  );
                })}
              </Row>
            )}

            <h3 className="add-item-subtitle">{t('global.size')}</h3>

            <Group
              onChange={(e) => {
                const size = e.target.value;
                return this.setState({
                  size, consumables: this.getConsumableBySize(this.props.item.consumables, size),
                });
              }}
              style={{ margin: '0 0 15px', width: '100%' }}
              size="large"
              className={item.components ? 'add-item-radio' : ''}
              defaultValue={item.sizes[0]}
              buttonStyle="solid"
            >
              {item.sizes.map(size => (
                <Radio.Button key={size} value={size}>
                  <Text style={{ marginTop: item.components ? '5px' : 0 }}>{size}</Text>

                  <Text style={{ fontSize: '13px' }}>
                    {item.components ? `${item.volume[size]}  ml` : ''}
                  </Text>
                </Radio.Button>
              ))}
            </Group>

            <h3 className="add-item-subtitle">{t('global.quantity')}</h3>

            <Row width="100%" justifyContent="flex-start" margin="0 0 15px">
              <Button onClick={() => this.decrease()} icon={<MinusOutlined />} />

              <span className="add-item-quantity">{quantity}</span>

              <Button onClick={() => this.increase()} icon={<PlusOutlined />} />
            </Row>

            {addons.length > 0 && <h3 className="add-item-subtitle">{t('global.addons')}</h3>}

            {addons.length > 0 && (
              <Row
                width="100%"
                justifyContent="flex-start"
                flexWrap="wrap"
                margin="0 0 10px"
              >
                {item.components && addons.map(el => (
                  <CheckableTag
                    key={`addons-${el.id}`}
                    className="create-product-tag"
                    onChange={() => this.toggleAddons(el)}
                    checked
                  >
                    {el.name || idMap[el.componentId].name}
                  </CheckableTag>
                ))}
              </Row>
            )}

            {item.consumables && item.consumables.length > 0 && (
              <ManageEquipments
                callback={consumables => this.setState({ consumables })}
                consumables={item.consumables}
                selectedConsumables={consumables}
                size={size}
              />
            )}

            <div className="separator">
              <span>{t('global.total')}:</span>

              <span className="price">{this.getResultPrice()} {currency}</span>
            </div>
          </Column>
        </Row>
      </Modal>
    );
  }
}

export default withTranslation()(AddToBill);
