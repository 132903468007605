import {
  deleteImage, generateId, uploadImage,
} from '../../utils/helpers';
import { ADD_STORE_ITEM, REMOVE_STORE_ITEM } from '../storage/actions';
import { getPlaceRef } from '../business/actions';
import {
  addConsumableRequest, addEquipmentRequest,
  editConsumableRequest, editEquipmentRequest, getConsumableRequest, getEquipmentRequest,
  removeConsumableRequest, removeEquipmentRequest,
} from '../../api/db/equipment';
import { showErrorMessage, showMessage } from '../message/actions';
import i18n from '../../utils/translation';
import {
  getPersistedConsumables, getPersistedEquipment, isOnline, persistWithKey, removeDataFromPersist,
} from '../../persistance';
import { idbConsumables, idbEquipment } from '../../config';

export const GET_CONSUMABLES = 'GET_CONSUMABLES';
export const EDIT_CONSUMABLE = 'EDIT_CONSUMABLE';
export const ADD_CONSUMABLE = 'ADD_CONSUMABLE';
export const REMOVE_CONSUMABLE = 'REMOVE_CONSUMABLE';

export const GET_EQUIPMENTS = 'GET_EQUIPMENTS';
export const EDIT_EQUIPMENT = 'EDIT_EQUIPMENT';
export const ADD_EQUIPMENT = 'ADD_EQUIPMENT';
export const REMOVE_EQUIPMENT = 'REMOVE_EQUIPMENT';

export function getConsumables(persisted = false) {
  return (dispatch) => {
    if (persisted || !isOnline()) {
      return getPersistedConsumables()
        .then(data => dispatch({ type: GET_CONSUMABLES, payload: data.map(el => ({ ...el, type: 'consumable' })) }));
    }
    return getConsumableRequest()
      .then(res => dispatch({ type: GET_CONSUMABLES, payload: res.data.map(el => ({ ...el, type: 'consumable' })) }))
      .catch(showErrorMessage);
  };
}

export function editConsumable({
  photo, ref, id, ...consumable
}) {
  return async (dispatch) => {
    const placeRef = await dispatch(getPlaceRef());

    let url = photo;
    let newRef = ref;
    if (typeof photo !== 'string' && photo !== null) {
      newRef = `${placeRef}/consumable-${Date.now()}`;
      url = await uploadImage(newRef, photo);
    }

    const newConsumable = {
      ...consumable, photo: url, ref: newRef,
    };

    delete newConsumable.type;

    return editConsumableRequest(id, newConsumable)
      .then((res) => {
        const consumable = { ...res.data, id, type: 'consumable' };

        dispatch({ type: EDIT_CONSUMABLE, payload: consumable });

        persistWithKey(id, consumable, idbConsumables);

        dispatch(showMessage({ text: i18n.t('actions.consumableEditedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function createConsumable({
  name, photo, count, ...props
}) {
  return async (dispatch) => {
    const placeRef = await dispatch(getPlaceRef());

    let url = null;
    let ref = null;
    if (photo !== null) {
      ref = `${placeRef}/consumable-${Date.now()}`;
      url = await uploadImage(ref, photo);
    }

    const id = generateId();
    const consumable = {
      ...props, name, photo: url, ref, count,
    };

    return addConsumableRequest(id, consumable)
      .then((res) => {
        const newConsumable = { ...res.data, id, type: 'consumable' };
        dispatch({ type: ADD_CONSUMABLE, payload: newConsumable });

        dispatch({
          type: ADD_STORE_ITEM,
          payload: {
            type: 'consumable', count: parseInt(count, 10), id: parseInt(id, 10),
          },
        });

        persistWithKey(id, newConsumable, idbConsumables);

        dispatch(showMessage({ text: i18n.t('actions.consumableCreatedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function removeConsumable(item) {
  return async dispatch => removeConsumableRequest(item.id)
    .then(() => {
      dispatch({ type: REMOVE_CONSUMABLE, payload: item.id });

      if (item.photo) deleteImage(item.ref);

      dispatch({ type: REMOVE_STORE_ITEM, payload: item.id });

      removeDataFromPersist(item.id, idbConsumables);

      dispatch(showMessage({ text: i18n.t('actions.consumableDeletedTitle') }));
    })
    .catch(showErrorMessage);
}

export function getEquipments(persisted = false) {
  return (dispatch) => {
    if (persisted || !isOnline()) {
      return getPersistedEquipment()
        .then(data => dispatch({ type: GET_EQUIPMENTS, payload: data.map(el => ({ ...el, type: 'equipment' })) }));
    }
    return getEquipmentRequest()
      .then(res => dispatch({ type: GET_EQUIPMENTS, payload: res.data.map(el => ({ ...el, type: 'equipment' })) }))
      .catch(showErrorMessage);
  };
}

export function editEquipment({ id, ...equipment }) {
  return (dispatch) => {
    const newEquipment = { ...equipment };
    delete newEquipment.id;
    delete newEquipment.type;

    return editEquipmentRequest(id, newEquipment)
      .then((res) => {
        const equipment = { ...res.data, id, type: 'equipment' };

        dispatch({ type: EDIT_EQUIPMENT, payload: equipment });

        persistWithKey(id, equipment, idbEquipment);

        dispatch(showMessage({ text: i18n.t('actions.equipmentEditedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function createEquipment(item) {
  return (dispatch) => {
    const id = generateId();
    const equipment = { ...item };

    return addEquipmentRequest(id, equipment)
      .then((res) => {
        const newEquipment = { ...res.data, id, type: 'equipment' };
        dispatch({ type: ADD_EQUIPMENT, payload: newEquipment });

        persistWithKey(id, newEquipment, idbEquipment);

        dispatch(showMessage({ text: i18n.t('actions.equipmentCreatedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function removeEquipment(id) {
  return dispatch => removeEquipmentRequest(id)
    .then(() => {
      dispatch({ type: REMOVE_EQUIPMENT, payload: id });

      removeDataFromPersist(id, idbEquipment);

      dispatch(showMessage({ text: i18n.t('actions.equipmentDeletedTitle') }));
    })
    .catch(showErrorMessage);
}
