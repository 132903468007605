import { Modal } from 'antd';
import moment from 'moment';
import { firebase } from '../../config';
import i18n from '../../utils/translation';
import {
  notificationTypes, showErrorMessage, showMessage, showNetworkError,
} from '../message/actions';
import { initRedux, refetchData } from '../user/actions';
import {
  createPlace, getAdminPasswordModalProps, getPlaceData, getPlaceRefFromDB, selectPlaceModalProps,
  setPlaceRefToDB,
} from './helpers';
import {
  getBusinessDataRequest, getPaymentsRequest, updateBusinessRequest, updatePlaceRequest,
} from '../../api/db/business';
import { setAppMigrationRequest } from '../../api/db/user';
import {
  cleanAllPersistedCollection, getPersistedPayments, getPersistedPlace, isOnline,
} from '../../persistance';
import axiosInstance from '../../api';

export const GET_BUSINESS_INFO = 'GET_BUSINESS_INFO';
export const GET_PLACE_INFO = 'GET_PLACE_INFO';
export const GET_ADMIN_ACCESS = 'GET_ADMIN_ACCESS';
export const GET_PAYMENTS = 'GET_PAYMENTS';
export const REMOVE_ADMIN_ACCESS = 'REMOVE_ADMIN_ACCESS';
export const ADD_ADMIN_ACCESS = 'ADD_ADMIN_ACCESS';
export const SET_ACTIVE_PLACE = 'SET_ACTIVE_PLACE';
export const LOGIN_CASHIER = 'LOGIN_CASHIER';

/**
 * Get Place ref from store
 * @returns {function(*, *): Promise<string>}
 */

export function getPlaceRef() {
  return (dispatch, getState) => new Promise((resolve) => {
    const { activePlace } = getState().business;

    resolve(activePlace);
  });
}

/**
 * Get place info and set it to redux
 * @param id
 * @returns {function(*): *}
 */
export function getPlaceInfo(id) {
  return (dispatch) => {
    if (!isOnline()) {
      return getPersistedPlace(id)
        .then(data => dispatch({
          type: GET_PLACE_INFO,
          payload: {
            checkbox: {
              login: '',
              password: '',
              cashKey: '',
              token: '',
            },
            ...data,
          },
        }));
    }
    return getPlaceData(id)
      .then((res) => {
        i18n.changeLanguage(res.data.language);
        return dispatch({
          type: GET_PLACE_INFO,
          payload: {
            checkbox: {
              login: '',
              password: '',
              cashKey: '',
              token: '',
            },
            ...res.data,
          },
        });
      })
      .catch(showErrorMessage);
  };
}

/**
 * Get place Payments
 * @returns {function(...[*]=)}
 */
export function getPayments(persisted = false) {
  return (dispatch) => {
    if (persisted || !isOnline()) {
      return getPersistedPayments()
        .then(data => dispatch({ type: GET_PAYMENTS, payload: data }));
    }
    return getPaymentsRequest()
      .then(res => dispatch({ type: GET_PAYMENTS, payload: res.data }))
      .catch(showErrorMessage);
  };
}

/**
 * Get adminPassword from firebase and match it with param
 * @param password
 * @returns {function(*): *}
 */
export function getAdminAccess(password) {
  return (dispatch, getState) => new Promise((resolve) => {
    const { adminPassword } = getState().business;
    const isValid = adminPassword === password;
    dispatch({ type: GET_ADMIN_ACCESS, payload: isValid });
    resolve(isValid);
  });
}

/**
 * Set active Place to Store and IDB
 * @param placeId
 * @returns {function(*): Promise<void>}
 */
export function setPlace(placeId) {
  return dispatch => new Promise((resolve) => {
    dispatch({ type: SET_ACTIVE_PLACE, payload: placeId });

    dispatch(getPlaceInfo(placeId));

    return setPlaceRefToDB(placeId).then(resolve);
  });
}

/**
 * Update place data at redux and firebase
 * @param data
 * @param withMessage
 * @returns {function(...[*]=)}
 */
export function updatePlaceInfo(data, withMessage = false) {
  return async (dispatch, getState) => {
    if (!isOnline()) return showNetworkError();

    const { business } = getState();
    const newData = { ...business, ...data };

    delete newData.payments;
    delete newData.dataLoaded;
    delete newData.isAdmin;
    delete newData.canInteract;
    delete newData.activePlace;
    delete newData.places;
    delete newData.tariff;

    i18n.changeLanguage(data.language);

    return updatePlaceRequest(business.activePlace, { ...newData, id: business.activePlace })
      .then((res) => {
        dispatch({ type: GET_PLACE_INFO, payload: res.data });
        if (withMessage) {
          dispatch(showMessage({ text: i18n.t('notification.formSaved'), type: notificationTypes.SUCCESS }));
        }
      })
      .catch(showErrorMessage);
  };
}

/**
 * Update business data at redux and firebase
 * @param data
 * @returns {function(...[*]=)}
 */
export function updateBusinessInfo(data) {
  return (dispatch, getState) => {
    const user = firebase.auth().currentUser;
    const { business: { email, places } } = getState();
    const newData = { email, places, ...data };

    return updateBusinessRequest(user.uid, newData)
      .then((res) => {
        dispatch({ type: GET_BUSINESS_INFO, payload: res.data });
      })
      .catch(showErrorMessage);
  };
}

/**
 * Show addPlaceModal, then create new place
 * @returns {function(*=, *=): Promise<{}>}
 */
export function addPlace(closable = false) {
  return (dispatch, getState) => new Promise((resolve) => {
    const user = firebase.auth().currentUser;

    Modal.info(getAdminPasswordModalProps(closable, (businessData) => {
      const data = {
        checkbox: {
          login: '',
          password: '',
          cashKey: '',
          token: '',
          useTaxesWebhook: false,
        },
        ...businessData,
      };

      createPlace(user.uid, data).then(async (place) => {
        // TODO: finish this flow
        // if (false) return;
        const { places } = getState().business;

        dispatch(updateBusinessInfo({ places: [...places, place] }));
        dispatch({
          type: GET_PLACE_INFO,
          payload: {
            ...data,
            shiftAutoClose: '00.00',
            currency: '₴',
            activePlace: place,
            isFreeTrial: true,
            isAdmin: true,
            payedFor: moment().add(2, 'week').format('DD_MM_YYYY'),
          },
        });

        dispatch({ type: 'CLEAN_REDUCERS' });

        await cleanAllPersistedCollection();

        await setPlaceRefToDB(place);

        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${place}`;

        dispatch(refetchData());

        Modal.destroyAll();
        resolve({ id: place, ...data });
      });
    }, data => dispatch(showMessage(data))));
  });
}

/**
 * Search for placeId in IDB, if not - show selectPlaceModal
 * @returns {function(*=, *=): Promise<{}>}
 */
export function selectPlace() {
  return (dispatch, getState) => new Promise(resolve => getPlaceRefFromDB()
    .then((key) => {
      if (key) {
        dispatch(setPlace(key)).then(resolve);
      } else {
        const { places } = getState().business;

        Modal.info(
          selectPlaceModalProps(places, place => dispatch(setPlace(place.id))
            .then(() => {
              dispatch(initRedux());
              Modal.destroyAll();

              resolve();
            })),
        );
      }
    }));
}

/**
 * Get business info from firebase and check for place
 * @returns {function(*=, *=): Promise<void>}
 */
export function getBusinessInfo() {
  return (dispatch, getState) => new Promise((resolve) => {
    const user = firebase.auth().currentUser;

    getBusinessDataRequest(user.uid)
      .then((res) => {
        const { places, email } = res.data;
        const { activePlace } = getState().business;

        dispatch({ type: GET_BUSINESS_INFO, payload: { places, email } });

        if (activePlace) return resolve();

        switch (places ? places.length : 0) {
          case 0: dispatch(addPlace()).then(resolve); break;
          case 1: dispatch(setPlace(places[0])).then(resolve); break;
          default: dispatch(selectPlace()).then(resolve);
        }
      })
      .catch(showErrorMessage);
  });
}

/**
 * Set idAdmin: false in redux
 * @param callback
 * @returns {function(...[*]=)}
 */
export function removeAdminAccess(callback = () => {}) {
  return (dispatch) => {
    dispatch({ type: REMOVE_ADMIN_ACCESS });

    callback();
  };
}

/**
 * Set idAdmin: true in redux
 * @param callback
 * @returns {function(...[*]=)}
 */
export function addAdminAccess(callback = () => {}) {
  return (dispatch) => {
    dispatch({ type: ADD_ADMIN_ACCESS });

    callback();
  };
}
/*
const setToCollectionById = (ref, el) => ref.doc(el.id.toString()).set(el);

export function migrateShifts() {
  return async (dispatch) => {
    const placeRef = await dispatch(getPlaceRef());

    const datesRefs = db.collection('shifts').doc(placeRef).collection('date');
    const shiftsRef = db.collection('Shifts').doc(placeRef).collection('date');

    await datesRefs.get().then(snapshot => snapshot.docs.forEach((doc) => {
      const data = doc.data();
      data.items.forEach((el) => {
        if (el.id) {
          return setToCollectionById(shiftsRef.doc(doc.id)
            .collection('items'), el);
        }
      });
    }));
  };
} */

export function migrateDBv2() {
  return async () => {
    // await dispatch(migrateShifts());
  };
}

/**
 * Check migration by version
 * If not, trigger migration script
 * @returns {function(...[*]=)}
 */
export function checkMigration(migration) {
  return async (dispatch) => {
    if (migration && !migration.v2) {
      console.log('Need migration');
      console.log(migration);
      await dispatch(migrateDBv2());

      await setAppMigrationRequest({ v2: true });
    }
  };
}
