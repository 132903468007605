import React, { Component, lazy, Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { FireOutlined } from '@ant-design/icons';

import { Column } from '../../components';
import i18 from '../../utils/translation';
import Header from './Header';
import Hero from './Hero';
import {
  JoinUs, Spinner, OpenApp, JoinTelegram, OpenPlayMarket,
} from './components';
import './style.scss';
import InstallBlock from './InstallBlock';

const LazyWhy = lazy(() => import('./Why/index'));
const LazyFeatures = lazy(() => import('./Features/index'));
const LazyAdvantages = lazy(() => import('./Advantages/index'));
const LazyPricing = lazy(() => import('./Pricing/index'));
const LazyContactUs = lazy(() => import('./ContactUs/index'));
const LazyFooter = lazy(() => import('./Footer/index'));

export const getLanguage = () => {
  switch (i18.language) {
    case 'ru': return 'ru';
    case 'en': return 'en';
    case 'ua': return 'ua';
    default: return 'en';
  }
};

const SaleCTA = ({ t, history }) => (
  <div className="sales-cta" onClick={() => history.push('/sales/quarantine')}>
    <FireOutlined style={{ fontSize: '18px', color: 'white' }} />
    <p>{t('sales.quarantine.teaser')}</p>
  </div>
);

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t, history } = this.props;
    return (
      <div className="landing" id="top">
        <Header />
        <InstallBlock />
        <Column width="100%" className="landing-wrapper" margin="0 auto">
          {false && <SaleCTA history={history} t={t} />}
          <Hero />
          <OpenApp t={t} />
          <Suspense fallback={<Spinner />}>
            <LazyWhy />
          </Suspense>
          <JoinUs t={t} />
          <Suspense fallback={<Spinner />}>
            <LazyFeatures />
          </Suspense>
          <OpenPlayMarket t={t} />
          <Suspense fallback={<Spinner />}>
            <LazyAdvantages />
          </Suspense>
          <JoinTelegram t={t} />
          <Suspense fallback={<Spinner />}>
            <LazyPricing />
          </Suspense>
          <Suspense fallback={<Spinner />}>
            <LazyContactUs />
          </Suspense>
        </Column>
        <Suspense fallback={<Spinner />}>
          <LazyFooter />
        </Suspense>
      </div>
    );
  }
}

export default withRouter(withTranslation()(Landing));
