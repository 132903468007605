import axios from 'axios';

export const CHECKBOX_API_URL = 'https://api.checkbox.in.ua/api/v1';

export const loginCashierRequest = (login, password) => axios.post(`${CHECKBOX_API_URL}/cashier/signin`, {
  login, password,
});

export const getBillUrl = uuid => `${CHECKBOX_API_URL}/receipts/${uuid}/png`;

export const getBillReceipt = uuid => axios.get(`${CHECKBOX_API_URL}/receipts/${uuid}/text`);
