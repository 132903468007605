import axios from '../index';
import { persistWithKey } from '../../persistance';
import { idbCommunication } from '../../config';

// Communication flow
export const getCommunicationDataRequest = () => new Promise(resolve => axios.get('/db/communication')
  .then((res) => {
    persistWithKey('communication', res.data, idbCommunication);
    resolve(res);
  }));


export const updateCommunicationRequest = data => axios.put('/db/communication', { data });
/*
export const setCommunicationRequest = async (data) => {
  const placeRef = await getPlaceRefFromDB();
  return db.collection('communication').doc(placeRef).set(data);
}; */

// Notification flow

export const getNotificationRequest = () => axios.get('/db/notification');

export const updateNotificationRequest = (id, data) => axios.put('/db/notification', { id, data });
