import React, { Component } from 'react';
import Chart from 'react-chartjs-2';

import moment from 'moment';
import { withTranslation } from 'react-i18next';

class SalesPerHour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeSales: this.separateBillsByTime(this.props.bills),
    };
  }

  separateBillsByTime = (bills) => {
    const timeSales = {};
    for (let i = 0; i < 24; i++) {
      timeSales[i] = 0;
    }
    bills.forEach((bill) => {
      const hour = moment(bill.id).format('H');
      timeSales[hour] = parseFloat(bill.pay.total) + timeSales[hour];
    });
    return timeSales;
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { bills } = nextProps;
    if (bills.length !== this.props.bills.length) {
      this.setState({ timeSales: this.separateBillsByTime(bills) });
    }
  }

  render() {
    const { t, currency } = this.props;
    const { timeSales } = this.state;
    return (
      <div id="time-chart" className="chart-wrapper chart-hour">
        <h3>{t('time.sortByTime')}</h3>
        <div style={{ width: '100%', height: '170px' }} className="chart-content">
          <Chart
            data={{
              labels: Object.keys(timeSales),
              datasets: [{
                data: Object.keys(timeSales).map(hour => timeSales[hour].toFixed(2)),
                backgroundColor: 'rgba(0, 143, 251, 0.8)',
              }],
            }}
            options={{
              maintainAspectRatio: false,
              scales: {
                xAxes: [{ gridLines: { display: false } }],
                yAxes: [{
                  lineHeight: 2,
                  gridLines: { tickMarkLength: 6 },
                  ticks: { min: 0, padding: 3, lineHeight: '2.2' },
                }],
              },
              title: { display: false },
              tooltips: {
                callbacks: {
                  title: tooltipItem => `${tooltipItem[0].xLabel}:00 - ${tooltipItem[0].xLabel}:59`,
                  label: tooltipItem => `${tooltipItem.yLabel.toFixed(2)} ${currency}`,
                },
              },
              legend: { display: false },
              animation: { duration: 0 },
              hover: { animationDuration: 0 },
              responsiveAnimationDuration: 0,
            }}
            height={170}
            type="bar"
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(SalesPerHour);
