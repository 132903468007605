import React, { Component } from 'react';
import { TeamOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { notificationTypes, showMessage } from '../../../redux/message/actions';
import { ClientsModal } from '../../../components';
import { checkIsBillOpen } from '../../../redux/statistic/actions';

class OpenClientsModalButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showClientsModal: false,
    };
  }

  clientsButtonClick = async () => {
    const { t, actions: { showMessage, checkIsBillOpen } } = this.props;
    const isBillOpen = await checkIsBillOpen();
    if (isBillOpen) {
      this.setState({ showClientsModal: true });
    } else {
      showMessage({ text: t('marketing.sales.needBill'), type: notificationTypes.INFO });
    }
  }

  render() {
    const { t } = this.props;
    const { showClientsModal } = this.state;

    return (
      <>
        <Button
          size="large"
          icon={<TeamOutlined />}
          onClick={this.clientsButtonClick}
          className="keeper-clients"
        >
          {t('layout.clients')}
        </Button>

        {showClientsModal && (
          <ClientsModal close={() => this.setState({ showClientsModal: false })} />
        )}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        checkIsBillOpen,
      },
      dispatch,
    ),
  };
}

export default withRouter(connect(null, mapDispatchToProps)(withTranslation()(OpenClientsModalButton)));
