import axios from 'axios';

export const productionAPI = 'https://shoptoria-be.herokuapp.com';
export const developmentAPI = 'https://shoptoria-be-dev.herokuapp.com';
export const apiUrl = productionAPI; // 'http://localhost:8000'; // productionAPI;

export const getAPISettings = token => ({ headers: { Authorization: `Bearer ${token}` } });

const axiosInstance = axios.create({
  baseURL: apiUrl, // `http://cors-anywhere.herokuapp.com/${apiUrl}`,
});

export default axiosInstance;
