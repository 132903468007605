import React, { Component, Fragment, memo } from 'react';
import {
  AppstoreOutlined, CoffeeOutlined, ExperimentOutlined, LeftOutlined, RightOutlined, ShoppingOutlined, StarOutlined,
} from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const getTabs = ({
  t, withFavourites, withAddons, categories, withRestriction, keeper,
}) => {
  const favouritesTab = {
    img: <StarOutlined style={{ fontSize: '16px' }} />,
    title: t('global.favourites'),
    key: 'card-favourites',
  };
  const addonTab = {
    img: <ExperimentOutlined style={{ fontSize: '16px' }} />,
    title: t('global.addons'),
    key: 'card-addon',
  };
  const productTab = {
    img: <CoffeeOutlined style={{ fontSize: '16px' }} />,
    title: t('global.products'),
    key: 'card-product',
  };
  const itemsTab = {
    img: <ShoppingOutlined style={{ fontSize: '16px' }} />,
    title: t('global.items'),
    key: 'card-item',
  };
  const allTab = {
    img: <AppstoreOutlined style={{ fontSize: '16px' }} />,
    title: t('global.all'),
    key: 'card-all',
  };
  let tabs = [];
  if (withRestriction) {
    const {
      showAll, showProducts, showItems, showFavourites, showAddons,
    } = keeper;
    if (showFavourites) tabs.push(favouritesTab);
    if (showProducts) tabs.push(productTab);
    if (showItems) tabs.push(itemsTab);
    if (showAll) tabs.push(allTab);
    if (showAddons && withAddons) {
      tabs.push(addonTab);
    }
  } else {
    if (withFavourites) {
      tabs.push(favouritesTab);
    }
    tabs = tabs.concat([productTab, itemsTab]);

    if (withAddons) {
      tabs.push(addonTab);
    }
    tabs.push(allTab);
  }
  tabs = tabs.concat((categories || []).map(category => ({
    title: category.name,
    key: `card-category-${category.name.replace(/\s/g, '_')}`,
    img: null,
  })));
  return tabs;
};


const MenuTab = memo(({
  isActive, callback, img, title, tabKey,
}) => (
  <div
    onClick={() => callback(tabKey)}
    className={`tabs-cell ${isActive ? 'active' : ''}`}
  >
    {img || null}

    <span>{title}</span>
  </div>
), (prevProps, nextProps) => prevProps.isActive === nextProps.isActive);

class TabsControl extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  changeActiveKey = (key) => {
    const { callback } = this.props;

    callback(key);
  };

  scrollLeft = () => {
    const container = document.getElementById('tabs-scroll');
    let el = null;
    let counter = 0;
    const currentOffset = container.scrollLeft;
    const tabs = Array.from(document.getElementsByClassName('tabs-cell'));
    tabs.forEach((tab) => {
      if (tab.offsetLeft < currentOffset) {
        counter += 1;
      }
      if (counter > 3 && !el) {
        el = tab;
      }
    });
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', inline: 'end' });
    } else {
      tabs[0].scrollIntoView({ behavior: 'smooth', inline: 'end' });
    }
  };

  scrollRight = () => {
    const container = document.getElementById('tabs-scroll');
    let el = null;
    let counter = 0;
    const currentOffset = container.offsetWidth + container.scrollLeft;
    const tabs = Array.from(document.getElementsByClassName('tabs-cell'));
    tabs.forEach((tab) => {
      if (tab.offsetWidth + tab.offsetLeft > currentOffset) {
        counter += 1;
      }
      if (counter > 3 && !el) {
        el = tab;
      }
    });
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', inline: 'end' });
    } else {
      tabs[tabs.length - 1].scrollIntoView({ behavior: 'smooth', inline: 'end' });
    }
  };

  render() {
    const {
      activeTab, categories, t, withFavourites, withAddons, withRestriction, keeper, excludeCategories,
    } = this.props;
    const container = document.getElementById('tabs-scroll');
    const showArrows = container && container.offsetWidth < container.scrollWidth;
    return (
      <div className="tabs-wrapper">
        <div className="tabs-row" id="tabs-scroll">
          {getTabs({
            t,
            withFavourites,
            withAddons,
            categories: excludeCategories ? (categories || []).filter(el => el.visible) : categories,
            withRestriction,
            keeper,
          }).map((tab) => {
            const { img, title, key } = tab;
            return (
              <MenuTab
                img={img}
                title={title}
                callback={this.changeActiveKey}
                isActive={activeTab === key}
                tabKey={key}
                key={key}
              />
            );
          })}
        </div>
        {showArrows && (
          <Fragment>
            <LeftOutlined onClick={this.scrollLeft} style={{ fontSize: '16px' }} className="arrow arrow-left" />
            <RightOutlined onClick={this.scrollRight} style={{ fontSize: '16px' }} className="arrow arrow-right" />
          </Fragment>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    categories: state.menu.categories,
    keeper: state.keeper,
  };
}

export default connect(mapStateToProps, null)(withTranslation()(TabsControl));
