import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import { Store } from 'idb-keyval';
import { createBrowserHistory } from 'history';

export const appHistory = createBrowserHistory();

const config = {
  apiKey: 'AIzaSyDIl8acdMmtnFRvYNB_kH7mmwiog5TCdTY',
  authDomain: 'talon-app.firebaseapp.com',
  databaseURL: 'https://talon-app.firebaseio.com',
  projectId: 'talon-app',
  storageBucket: 'talon-app.appspot.com',
  messagingSenderId: '181976239914',
  appId: '1:181976239914:web:059bd8d6a73a038c',
};

const app = firebase.initializeApp(config);
export { app as firebase };

// eslint-disable-next-line import/no-mutable-exports
app.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

export const idb = new Store(`db-${process.env.REACT_APP_IDB_VERSION}`, 'keyval');

export const idbBills = new Store(`db-bills-${process.env.REACT_APP_IDB_VERSION}`, 'bills');

export const idbShifts = new Store(`db-shifts-${process.env.REACT_APP_IDB_VERSION}`, 'shifts');

export const idbUpdates = new Store(`db-updates-${process.env.REACT_APP_IDB_VERSION}`, 'updates');

export const idbInfo = new Store(`db-info-${process.env.REACT_APP_IDB_VERSION}`, 'info');

export const idbAccessEmployers = new Store(
  `db-access-employer-${process.env.REACT_APP_IDB_VERSION}`, 'access-employers',
);

export const idbAccessJobs = new Store(`db-access-job-${process.env.REACT_APP_IDB_VERSION}`, 'access-jobs');

export const idbBusiness = new Store(`db-business-${process.env.REACT_APP_IDB_VERSION}`, 'business');

export const idbPlace = new Store(`db-place-${process.env.REACT_APP_IDB_VERSION}`, 'place');

export const idbPayments = new Store(`db-payments-${process.env.REACT_APP_IDB_VERSION}`, 'payments');

export const idbCommunication = new Store(`db-communication-${process.env.REACT_APP_IDB_VERSION}`, 'communication');

export const idbMarketingLoyalty = new Store(
  `db-marketing-loyalty-${process.env.REACT_APP_IDB_VERSION}`, 'marketing-loyalty',
);

export const idbMarketingClients = new Store(
  `db-marketing-clients-${process.env.REACT_APP_IDB_VERSION}`, 'marketing-clients',
);

export const idbMarketingGroups = new Store(
  `db-marketing-groups-${process.env.REACT_APP_IDB_VERSION}`, 'marketing-groups',
);

export const idbMarketingSales = new Store(
  `db-marketing-sales-${process.env.REACT_APP_IDB_VERSION}`, 'marketing-sales',
);

export const idbKeeper = new Store(`db-keeper-${process.env.REACT_APP_IDB_VERSION}`, 'keeper');

export const idbConsumables = new Store(`db-consumables-${process.env.REACT_APP_IDB_VERSION}`, 'consumables');

export const idbEquipment = new Store(`db-equipment-${process.env.REACT_APP_IDB_VERSION}`, 'equipments');

export const idbFinancialAccount = new Store(
  `db-financial-account-${process.env.REACT_APP_IDB_VERSION}`, 'financial-account',
);

export const idbFinancialTransaction = new Store(
  `db-financial-transaction-${process.env.REACT_APP_IDB_VERSION}`, 'financial-transaction',
);

export const idbFinancialCategory = new Store(
  `db-financial-category-${process.env.REACT_APP_IDB_VERSION}`, 'financial-category',
);

export const idbMenuAddons = new Store(
  `db-menu-addons-${process.env.REACT_APP_IDB_VERSION}`, 'menu-addons',
);

export const idbMenuCategories = new Store(
  `db-menu-categories-${process.env.REACT_APP_IDB_VERSION}`, 'menu-categories',
);

export const idbMenuComponents = new Store(
  `db-menu-components-${process.env.REACT_APP_IDB_VERSION}`, 'menu-components',
);

export const idbMenuProducts = new Store(
  `db-menu-products-${process.env.REACT_APP_IDB_VERSION}`, 'menu-products',
);

export const idbMenuItems = new Store(
  `db-menu-items-${process.env.REACT_APP_IDB_VERSION}`, 'menu-items',
);

export const idbStoreSuppliers = new Store(
  `db-store-suppliers-${process.env.REACT_APP_IDB_VERSION}`, 'store-suppliers',
);

export const idbStoreSupply = new Store(
  `db-store-supply-${process.env.REACT_APP_IDB_VERSION}`, 'store-supply',
);

export const idbStoreWaste = new Store(
  `db-store-waste-${process.env.REACT_APP_IDB_VERSION}`, 'store-waste',
);

export const idbStoreInventory = new Store(
  `db-store-inventory-${process.env.REACT_APP_IDB_VERSION}`, 'store-inventory',
);

export const idbStoreStorage = new Store(
  `db-store-storage-${process.env.REACT_APP_IDB_VERSION}`, 'store-storage',
);
