import React, { Component } from 'react';
import {
  Button, Input, Modal, Select,
} from 'antd';
import moment from 'moment';
import { Column, Row, LanguageRow } from '../components';
import i18n from '../utils/translation';
import { notificationTypes } from '../redux/message/actions';
import ADDON_IMAGE from '../assets/icon_addon.png';
import PRODUCT_IMAGE from '../assets/icon_product.png';
import ITEM_IMAGE from '../assets/icon_item.png';
import CONSUMABLE_IMAGE from '../assets/icon_consumable.png';
import { getLanguage } from '../view/Landing';
import { timezones } from '../view/Settings/General/General';

const businessTypes = [
  { name: 'admin.coffee', img: ADDON_IMAGE, type: 'coffee' },
  { name: 'admin.cafe', img: PRODUCT_IMAGE, type: 'cafe' },
  { name: 'admin.restaurant', img: ITEM_IMAGE, type: 'restaurant' },
  { name: 'admin.customType', img: CONSUMABLE_IMAGE, type: 'customType' },
];

class AdminPasswordModal extends Component {
  constructor(props) {
    super(props);
    const offset = moment().utcOffset() / 60;
    const { value = '2', text = '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius' } = timezones
      .find(el => el.value === offset.toString());
    this.state = {
      password: '',
      name: '',
      type: null,
      offset: `${value};${text}`,
      language: getLanguage(),
    };
  }

  updatePassword = password => this.setState({ password });

  submit = () => {
    const { callback, showMessage } = this.props;
    const {
      password, name, type, language, offset,
    } = this.state;
    if (name.trim() === '') {
      showMessage({ text: i18n.t('validation.emptyName'), type: notificationTypes.ERROR });
      return;
    }
    if (type === null) {
      showMessage({ text: i18n.t('admin.checkType'), type: notificationTypes.ERROR });
      return;
    }
    if (password.trim() === '') {
      showMessage({ text: i18n.t('admin.checkTitle'), type: notificationTypes.ERROR });
      return;
    }
    callback({
      adminPassword: password, name, type, language, completeFirstView: false, offset,
    });
  };

  render() {
    const { closable } = this.props;
    const { type, offset } = this.state;
    return (
      <Column alignItems="flex-start" style={{ maxWidth: '400px' }} width="100%" margin="0 auto">
        <h2>{i18n.t('admin.signUp')}</h2>
        <h3 style={{ margin: '0 0 10px' }}>{i18n.t('admin.shopName')}</h3>
        <Input
          style={{ maxWidth: '400px', width: '100%' }}
          placeholder={i18n.t('global.name')}
          onChange={e => this.setState({ name: e.target.value })}
        />

        <h3 style={{ margin: '15px 0 10px' }}>{i18n.t('admin.businessType')}</h3>
        <Row width="100%" flexWrap="wrap" justifyContent="flex-start" className="mobile-column">
          {businessTypes.map(businessType => (
            <div
              className={`business-type ${type === businessType.type ? 'active' : ''}`}
              key={JSON.stringify(businessType)}
              onClick={() => this.setState({ type: businessType.type })}
            >
              <img src={businessType.img} alt={businessType.name} />
              <p>{i18n.t(businessType.name)}</p>
            </div>
          ))}
        </Row>
        <h3 style={{ margin: '15px 0 10px' }}>{i18n.t('time.offset')}</h3>
        <Select
          style={{ width: '100%' }}
          value={offset}
          onChange={e => this.setState({ offset: e })}
        >
          {timezones.map(zone => (
            <Select.Option value={`${zone.value};${zone.text}`} key={zone.text}>
              {zone.text}
            </Select.Option>
          ))}
        </Select>
        <h3 style={{ margin: '5px 0 10px' }}>{i18n.t('admin.modalTitle')}</h3>
        <Input
          style={{ maxWidth: '400px', width: '100%' }}
          onChange={e => this.updatePassword(e.target.value)}
          placeholder={i18n.t('admin.adminPassword')}
        />
        <h4 style={{ margin: '10px 0 32px' }}>
          <span className="help-text">*</span>{i18n.t('admin.modalDescription')}
        </h4>
        <LanguageRow i18n={i18n} callback={language => this.setState({ language })} />
        <Row width="100%" margin="32px 0 0" justifyContent={closable ? 'space-between' : 'flex-end'}>
          {closable && (
            <Button size="large" style={{ width: '120px' }} onClick={() => Modal.destroyAll()}>
              {i18n.t('control.cancel')}
            </Button>
          )}
          <Button size="large" type="primary" style={{ width: '120px' }} onClick={this.submit}>
            {i18n.t('control.save')}
          </Button>
        </Row>
      </Column>
    );
  }
}

export default AdminPasswordModal;
