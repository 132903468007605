import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input, Modal, Select } from 'antd';
import { ModalHOC, NumberInput } from '../../../common';
import { Column } from '../../../components';
import { notificationTypes, showMessage } from '../../../redux/message/actions';
import { addFinancialAccount } from '../../../redux/financial/actions';
import { validateNumberInput } from '../../../utils/helpers';

class AddFinancialAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      type: 'cash',
      count: '',
    };
  }

  create = () => {
    const { t, close, actions: { addFinancialAccount, showMessage } } = this.props;
    const { name, type, count } = this.state;
    if (name.trim() === '') {
      showMessage({ text: t('validation.emptyName'), type: notificationTypes.ERROR });
      return;
    }
    if (count.trim() === '') {
      showMessage({ text: t('validation.emptySum'), type: notificationTypes.ERROR });
      return;
    }
    addFinancialAccount({
      name, type, count: parseInt(count, 10),
    });
    close();
  };

  render() {
    const {
      t, close, visible, business,
    } = this.props;
    const { name, type, count } = this.state;
    return (
      <Modal
        maskClosable={false}
        centered
        visible={visible}
        onOk={this.create}
        onCancel={close}
        width="100%"
        style={{ maxWidth: '450px' }}
        okText={t('control.create')}
        okButtonProps={{ size: 'large' }}
        cancelButtonProps={{ size: 'large' }}
        cancelText={t('control.cancel')}
        title={t('financial.addNewAccount')}
      >
        <Column
          width="100%"
          alignItems="flex-start"
          margin="0 auto"
          className="default-card"
          style={{ maxWidth: '324px' }}
        >
          <h3 style={{ margin: '0 0 5px' }}>{t('global.name')}</h3>
          <Input
            style={{ width: '100%' }}
            placeholder={t('global.name')}
            value={name}
            onChange={e => this.setState({ name: e.target.value })}
          />
          <h3 style={{ margin: '10px 0 5px' }}>{t('global.type')}</h3>
          <Select style={{ width: '100%' }} value={type} onChange={type => this.setState({ type })}>
            <Select.Option value="cash">{t('financial.cash')}</Select.Option>
            <Select.Option value="card">{t('financial.card')}</Select.Option>
            <Select.Option value="account">{t('financial.account')}</Select.Option>
          </Select>
          <h3 style={{ margin: '10px 0 5px' }}>{t('global.balance')}</h3>
          <NumberInput
            onKeyUp={validateNumberInput}
            style={{ width: '100%' }}
            placeholder="0"
            type="tel"
            onChange={e => this.setState({ count: e.target.value })}
            value={count}
            addonAfter={business.currency}
          />
        </Column>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    business: state.business,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        addFinancialAccount,
      },
      dispatch,
    ),
  };
}
export default ModalHOC(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddFinancialAccount)));
