import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { notificationTypes, showMessage } from '../redux/message/actions';
import { addToBill } from '../redux/statistic/actions';
import { getUnits, validateNumberInput } from '../utils/helpers';
import { Column } from '../components';
import NumberInput from './NumberInput';

class WeightCount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
    };
  }

  submit = () => {
    const {
      activeKey, actions: { addToBill }, weightItem, close, t,
    } = this.props;
    const { count } = this.state;
    if (count === 0 || count === '') {
      showMessage({ text: t('validation.addCount'), type: notificationTypes.ERROR });
      return;
    }
    addToBill(activeKey, {
      name: weightItem.name,
      id: weightItem.id,
      quantity: count,
      displayName: weightItem.name,
      type: weightItem.type,
      weightItem: true,
      units: weightItem.units,
      price: weightItem.saleCost,
      category: weightItem.category,
    });
    close();
  };

  render() {
    const { t, close, weightItem } = this.props;
    return (
      <Modal
        maskClosable={false}
        visible
        width="100%"
        style={{ maxWidth: '500px' }}
        title={t('item.weight')}
        onOk={this.submit}
        onCancel={close}
        okButtonProps={{ size: 'large' }}
        cancelButtonProps={{ size: 'large' }}
        okText={t('control.add')}
        cancelText={t('control.cancel')}
      >
        <Column width="100%" className="default-card">
          <h2 style={{ margin: '15px 0 10px', fontSize: '20px' }}>{t('global.quantity')}</h2>
          <NumberInput
            onKeyUp={validateNumberInput}
            placeholder="0"
            addonAfter={getUnits(weightItem.units)}
            onPressEnter={this.submit}
            type="tel"
            size="large"
            onChange={e => this.setState({ count: e.target.value })}
          />
        </Column>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    activeKey: state.statistic.activeKey,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        addToBill,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WeightCount));
