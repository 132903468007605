import { consumptionTourSteps } from './steps';

export const getConsumptionTours = (t, goToNextStep, lastStepEvent) => [
  {
    pageRegExp: /\w/g,
    id: 3,
    isComplete: false,
    redirect: '/app/storage/consumption',
    title: t('reactour.consumption.title'),
    beforeEnter: () => {
      const layoutSelector = document.querySelector('[data-tour="store-tour-0"]');
      if (layoutSelector.classList.contains('active-parent')) {
        layoutSelector.click();
      }
    },
    steps: consumptionTourSteps(t, goToNextStep, lastStepEvent),
  },
];
