import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Modal } from 'antd';
import { withTranslation } from 'react-i18next';
import {
  Column, FileUpload, Row, Cost,
} from '../../../../components';
import { notificationTypes } from '../../../../redux/message/actions';
import { checkIsNameExist, validateNumberInput } from '../../../../utils/helpers';
import { NumberInput } from '../../../../common';

class CreateConsumable extends Component {
  constructor(props) {
    super(props);
    const {
      consumable = {
        name: '', supplyPrice: 0, photo: null, count: 0, supplyCount: 0, cost: 0,
      },
    } = props;
    this.state = { ...consumable, loading: false };
  }

  create = () => {
    const {
      t, close, consumables, actions: { createConsumable, showMessage },
    } = this.props;
    const {
      supplyPrice, name, photo, count, supplyCount, cost,
    } = this.state;
    if (name.trim() === '') {
      showMessage({ text: t('validation.consumableName'), type: notificationTypes.ERROR });
      return;
    }

    if (checkIsNameExist(name, consumables)) {
      showMessage({ text: t('validation.uniqueConsumableName'), type: notificationTypes.ERROR });
      return;
    }
    this.setState({ loading: true });
    createConsumable({
      name, supplyPrice: parseInt(supplyPrice, 10), photo, count, supplyCount, cost,
    }).then(() => close());
  };

  updateCount = e => this.setState({ count: e.target.value });

  render() {
    const { visible, t, close } = this.props;
    const {
      name, loading,
    } = this.state;
    return (
      <Modal
        maskClosable={false}
        centered
        width="600px"
        visible={visible}
        onOk={this.create}
        okButtonProps={{ loading, size: 'large' }}
        onCancel={close}
        okText={t('control.create')}
        cancelText={t('control.cancel')}
        cancelButtonProps={{ size: 'large' }}
        className="create-addon"
        title={t('consumable.modalTitle')}
      >
        <Column>
          <Row
            margin="0 0 12px"
            width="100%"
            justifyContent="flex-start"
            alignItems="flex-start"
            className="default-card mobile-consumable-column"
          >
            <Column>
              <h3>{t('global.image')}</h3>
              <FileUpload callback={photo => this.setState({ photo })} />
            </Column>
            <Column margin="0 10px" className="mobile-consumable-content">
              <h3 style={{ margin: '0 20px 9px 0' }}>{t('global.name')}</h3>
              <Input
                className="mobile-full-width"
                style={{ width: '200px' }}
                placeholder={t('consumable.modalNamePlaceholder')}
                onChange={e => this.setState({ name: e.target.value })}
                value={name}
              />
              <h3 style={{ margin: '10px 20px 9px 0' }}>{t('storage.onStore')}</h3>
              <NumberInput
                className="mobile-full-width"
                placeholder="0"
                type="tel"
                onKeyUp={validateNumberInput}
                onChange={this.updateCount}
                style={{ width: '200px' }}
                addonAfter={t('count')}
              />
            </Column>
          </Row>
          <Cost callback={data => this.setState({ ...data })} />
        </Column>
      </Modal>
    );
  }
}

export default withTranslation()(CreateConsumable);

CreateConsumable.propTypes = {
  close: PropTypes.func,
};
