import React from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { Column, Row } from '../index';
import Header from '../Header';

export const Page = ({
  wrapperClassName = '', spinning = false, children, ...props
}) => (
  <Spin wrapperClassName={`storage-page ${wrapperClassName}`} spinning={spinning} {...props}>
    {children}
  </Spin>
);

export const Content = ({ children, ...props }) => (
  <Column className="storage-page-content" {...props}>
    {children}
  </Column>
);

export const TopBar = ({ children, ...props }) => (
  <Row className="storage-page-header mobile-block" {...props}>
    {children}
  </Row>
);

const DefaultHeader = ({ name }) => (
  <Header><h2 className="header-name">{name}</h2></Header>
);

function mapStateToProps(state) {
  return {
    name: state.business.name,
  };
}

export default connect(mapStateToProps, null)(DefaultHeader);
