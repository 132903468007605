import React, { Component } from 'react';
import {
  CloseOutlined, SearchOutlined,
} from '@ant-design/icons';
import { Empty, Input, Modal } from 'antd';
import { Column, Row } from '../Grid';
import Card from '../Card';
import { alphabeticalSort } from '../../utils/helpers';
import './style.scss';

class SearchModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemSearch: '',
    };
  }

  getFilteredItems = () => {
    const { menu: { products, items } } = this.props;

    const allData = alphabeticalSort([
      ...products, ...items],
    'name');
    const { itemSearch } = this.state;
    if (itemSearch.trim() !== '') {
      return allData.filter(consumable => consumable.name.toLowerCase().includes(itemSearch.toLowerCase()));
    }
    return allData;
  };

  render() {
    const {
      t, callback, close, showCategoryInCard,
    } = this.props;
    const { itemSearch } = this.state;
    const filteredConsumables = this.getFilteredItems();
    return (
      <Modal
        centered
        visible
        onCancel={close}
        footer={null}
        width="536px"
        title={t('keeper.menuSearch')}
        className="add-consumable search-modal"
      >
        <Column
          alignItems="flex-start"
          justifyContent="flex-start"
          width="100%"
          height="90vh"
          style={{ padding: '0 7px' }}
        >
          <Column
            alignItems="flex-start"
            justifyContent="flex-start"
            width="100%"
            style={{ padding: '0 5px' }}
          >
            <h3 className="add-item-subtitle">{t('global.search')}</h3>
            <Input
              className="equipment-input"
              prefix={<SearchOutlined />}
              value={itemSearch}
              onChange={e => this.setState({ itemSearch: e.target.value })}
              style={{ width: '100%' }}
              suffix={<CloseOutlined onClick={() => this.setState({ itemSearch: '' })} />}
            />
          </Column>
          {filteredConsumables.length > 0 ? (
            <Row
              width="100%"
              justifyContent="flex-start"
              alignItems="flex-start"
              flexWrap="wrap"
              margin="15px 0 0"
              height="calc(90vh - 82px)"
              style={{ minHeight: '335px', overflowY: 'scroll' }}
            >
              {filteredConsumables.map(el => (
                <Card withCategory={showCategoryInCard} item={el} callback={() => callback(el)} key={el.id} />
              ))}
            </Row>
          ) : (
            <Empty />
          )}
        </Column>
      </Modal>
    );
  }
}

export default SearchModal;
