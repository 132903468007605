import moment from 'moment';
import i18n from './translation';
import { firebase } from '../config';
import ADDON_IMAGE from '../assets/icon_addon.png';
import PRODUCT_IMAGE from '../assets/icon_product.png';
import ITEM_IMAGE from '../assets/icon_item.png';
import CONSUMABLE_IMAGE from '../assets/icon_consumable.png';
import { getBillItems } from '../redux/statistic/helpers';

/**
 * Filter collection items, search in selected keys a query
 * @param collection
 * @param keys
 * @param query
 * @returns []
 */
export const filterFunc = (collection, keys, query) => {
  if (query.trim() === '') return collection;

  return collection.filter((el) => {
    const record = keys.reduce((res, key) => `${res} ${el[key]}`, '');
    return record.toLowerCase().includes(query.toLowerCase());
  });
};

export const printPage = () => {
  try {
    // Print for Safari browser
    document.execCommand('print', false, null);
  } catch {
    window.print();
  }
};

export const getUnits = units => i18n.t(`units.${units}`);

export const prepareCardNumber = (number = 0) => `${new Array(5 - number.toString().length).fill(0).join('')}${number}`;

export const toggleOverscroll = (shouldOverscroll) => {
  if (shouldOverscroll) {
    document.body.classList.remove('no-overscroll-modal');
  } else {
    document.body.classList.add('no-overscroll-modal');
  }
};

export const getRangeOfDates = (start, end, key = 'day', arr = [start.startOf(key)]) => {
  if (start.isAfter(end)) throw new Error('start must precede end');
  const next = moment(start).add(1, key).startOf(key);
  if (next.isAfter(end, key)) return arr;
  return getRangeOfDates(next, end, key, arr.concat(next));
};

export const sortBillsByDate = (bills, start = moment(), end = moment()) => getRangeOfDates(start, end).map(date => ({
  bills: bills.filter(bill => date.startOf('day').valueOf() < bill.id && bill.id < date.endOf('day').valueOf()),
  date,
}));

export const sortShiftsByDate = (shifts, start = moment(), end = moment()) => getRangeOfDates(start, end).map(date => ({
  shifts: shifts.filter(shift => date.startOf('day').valueOf() < shift.startTime
    && shift.startTime < date.endOf('day').valueOf()),
  date,
}));

export const calculateSubTotal = bill => bill.items.reduce((sum, el) => sum + (el.quantity * el.price), 0);

export const calculateTotal = bill => (
  (calculateSubTotal(bill) - (bill.sale.amount || 0)) / 100 * (100 - bill.sale.percent))
  .toFixed(2);

export const calculateSale = bill => parseInt(bill.sale.amount || 0, 10)
  + parseFloat((calculateSubTotal(bill) - (bill.sale.amount || 0)) / 100 * bill.sale.percent);

export const getDayStatisticFromSortedBills = dates => dates.map(({ date, bills }, key) => ({
  date: moment(date).format('DD.MM.YYYY'),
  key,
  count: bills.length,
  bills,
  cash: bills.reduce((acc, bill) => acc + parseFloat(bill.pay.cash) - parseFloat(bill.pay.change), 0)
    .toFixed(2),
  card: bills.reduce((acc, bill) => acc + parseFloat(bill.pay.card), 0).toFixed(2),
  bonus: bills.reduce((acc, bill) => acc + parseFloat(bill.pay.bonus || 0), 0).toFixed(2),
  total: bills.reduce((acc, bill) => acc + parseFloat(calculateTotal(bill)), 0).toFixed(2),
  sale: bills.reduce((acc, bill) => acc + parseFloat(calculateSale(bill)), 0).toFixed(2),
}));

export const getDefaultImageByType = (type) => {
  switch (type) {
    case 'addon': return ADDON_IMAGE;
    case 'component': return ADDON_IMAGE;
    case 'product': return PRODUCT_IMAGE;
    case 'item': return ITEM_IMAGE;
    case 'consumable': return CONSUMABLE_IMAGE;
    default: return '/assets/default.png';
  }
};

export const generateId = () => Date.now();

export const generateIdString = () => Date.now().toString();

export const generateBillUUID = () => ([1e7] + -1e3 + -4e3 + -8e3 + -1e11)
  .replace(/[018]/g, c =>
    // eslint-disable-next-line
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));

/* Cost */
export const calculateComponentsCost = (components = [], idMap, size) => parseFloat(
  components.map(el => ({ ...el, ...idMap[el.id] }))
    .filter(el => el.count)
    .reduce((sum, el) => sum + parseFloat((el.count[size] || 0) * parseFloat(el.cost)), 0)
    .toFixed(2),
);

export const calculateConsumablesCost = (consumables = [], idMap, size) => parseFloat(
  consumables.reduce((sum, el) => sum
    + (idMap[el.sizes[size]] ? parseFloat(idMap[el.sizes[size]].cost) : 0), 0).toFixed(2),
);
// TODO: Replace consumables.length array check
export const getItemsCost = (items = [], menu, equipment) => items.reduce(
  (acc, { consumables = [], components = [], size }) => acc + calculateComponentsCost(components, menu.idMap, size)
    + calculateConsumablesCost(consumables.length ? consumables : [], equipment.idMap, size), 0,
).toFixed(2);

/* * */

/* Validation */

export const validateNumberInput = (e) => {
  const { value } = e.target;
  if (!/^[0-9]*$/.test(value)) {
    e.preventDefault();
  }
  return true;
};

export const checkIsNameExist = (name, collection) => collection
  .reduce((isExist, el) => el.name === name || isExist, false);

export const hasZeroKey = collection => Object.keys(collection)
  .reduce((res, key) => collection[key] === 0 || res, false);

export const hasNullKey = collection => Object.keys(collection)
  .reduce((res, key) => collection[key] == null || res, false);

export const hasEmptyString = collection => Object.keys(collection)
  .reduce((res, key) => collection[key].toString().trim() === '' || res, false);

/* * */

export const cashPrediction = (total) => {
  const fifth = Math.floor(parseFloat(total) / 50);
  return { low: 50 * (fifth + 1), high: 50 * (fifth + 2 + (fifth % 2)) };
};

export const alphabeticalSort = (collection, key = 'name') => collection.sort((a, b) => {
  if (!a[key] || !b[key]) return 0;
  return a[key].localeCompare(b[key]);
});

/* Statistic */

export const getTodayKey = () => moment().format('DD_MM_YYYY');

export const getTodayKeyTimestamp = () => moment().startOf('day').valueOf();

export const getYesterdayKeyTimestamp = () => moment().subtract(1, 'day').startOf('day').valueOf();
/* * */

export const calculateCost = ({ count, price }) => {
  const cost = (price / count).toFixed(3);
  return (!isFinite(cost) || isNaN(cost)) ? 0 : cost;
};

export const calculateCostFloat = ({ count, price }) => {
  const cost = (parseFloat(price) / count).toFixed(3);
  return (!isFinite(cost) || isNaN(cost)) ? 0 : cost;
};

/* Firebase */
export const uploadImage = (ref, image) => firebase.storage().ref(ref).put(image).then(res => res.ref.getDownloadURL());

export const deleteImage = (ref) => {
  if (!/^examples/.test(ref) && ref !== 'duplicate') {
    return firebase.storage().ref(ref).delete();
  }
};

/* * */

/* Size */
const getSizePriority = (size) => {
  switch (size) {
    case 'S': return 1;
    case 'M': return 2;
    case 'L': return 3;
    case 'XL': return 4;
    case 'XXL': return 5;
    default: return 6;
  }
};

export const sizesSort = (a, b) => getSizePriority(a) - getSizePriority(b);

export const getSizeName = (name) => {
  switch (name) {
    case 'S': return 'Small';
    case 'M': return 'Medium';
    case 'L': return 'Large';
    case 'XL': return 'Extra Large';
    case 'XXL': return 'Double Extra Large';
    default: return '';
  }
};

/* * */

export const calculateStoreConsumptionFromBills = (bills, menu) => bills.map(bill => getBillItems(bill, menu)).flat();

export const getEmptyMaterial = t => ({
  id: 0, name: t('equipment.empty'), cost: 0, photo: '/assets/icon_kit.png',
});

export const getEmptyAddon = t => ({
  id: 0, componentId: 0, price: 0, name: t('global.nothing'), key: 999,
});

export const castToNormalFloat = unformattedFloat => parseFloat(unformattedFloat.toFixed(2));
