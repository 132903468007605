import React, { Component } from 'react';
import { CheckOutlined, PlusOutlined } from '@ant-design/icons';
import { Modal, Spin } from 'antd';
import { withTranslation } from 'react-i18next';
import { Row } from '../index';
import ADDON_IMAGE from '../../assets/icon_addon.png';
import PRODUCT_IMAGE from '../../assets/icon_product.png';
import ITEM_IMAGE from '../../assets/icon_item.png';
import CONSUMABLE_IMAGE from '../../assets/icon_consumable.png';
import { getPlacesData } from '../../redux/business/helpers';
import './style.scss';

export const getPhotoByType = (type) => {
  switch (type) {
    case 'coffee': return ADDON_IMAGE;
    case 'cafe': return PRODUCT_IMAGE;
    case 'restaurant': return ITEM_IMAGE;
    case 'customType': return CONSUMABLE_IMAGE;
    default: return PRODUCT_IMAGE;
  }
};

export const PlaceCard = ({
  place, activePlace, togglePlace,
}) => (
  <div
    className={`add-item-addon-card managePlace-card ${activePlace === place.id ? 'checked' : ''}`}
    key={`addon-card-${place.id}`}
    onClick={() => togglePlace(place)}
  >
    <h3 style={{ height: 34 }}>{place.name || ' '}</h3>
    <img
      src={getPhotoByType(place.type)}
      alt=""
      className="card-img"
    />
    <CheckOutlined style={{ fontSize: '18px' }} className="add-item-addon-card-check" />
  </div>
);

class ManagePlaces extends Component {
  constructor(props) {
    super(props);
    this.state = {
      places: null,
    };
  }

  componentDidMount() {
    const { places } = this.props;
    getPlacesData(places, (placesData => this.setState({ places: placesData })));
  }

  addPlace = () => {
    const { actions: { addPlace }, close } = this.props;
    addPlace(true).then(() => close());
  };

  togglePlace = (place) => {
    const { actions: { changePlace } } = this.props;
    changePlace(place);
  };

  render() {
    const {
      t, visible, close, activePlace,
    } = this.props;
    const { places } = this.state;
    return (
      <Modal
        maskClosable={false}
        centered
        width="528px"
        visible={visible}
        onCancel={close}
        footer={null}
        className="places-modal"
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        title={t('settings.choosePlace')}
      >
        <Spin spinning={places == null}>
          <Row width="100%" flexWrap="wrap" justifyContent="flex-start">
            {(places || []).map(place => (
              <div
                className={`business-type large ${activePlace === place.id ? 'active' : ''}`}
                key={JSON.stringify(place)}
                onClick={() => this.togglePlace(place)}
              >
                <img src={getPhotoByType(place.type)} alt={place.name} />
                <p>{place.name}</p>
              </div>
            ))}
            <div className="business-type large" onClick={this.addPlace}>
              <PlusOutlined className="managePlace-add-icon small" />
              <p>{t('control.create')}</p>
            </div>
          </Row>
        </Spin>
      </Modal>
    );
  }
}

export default withTranslation()(ManagePlaces);
