import React, { Component, lazy, Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { Column } from '../../../components';
import { Spinner } from '../../Landing/components';
import Header from '../../Landing/Header';
import DocsTab from '../DocsTab';

const LazyFooter = lazy(() => import('../../Landing/Footer/index'));
const LazyContactUs = lazy(() => import('../ContactUsRow/index'));
const LazyNavigation = lazy(() => import('../Navigation/index'));

class SecureDoc extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <div className="landing docs" id="top">
        <Header />
        <Column width="100%" className="landing-wrapper" margin="0 auto">
          <DocsTab />
          <div className="docs-hero secure">
            <h1>{t('landing.advantages.secure.title')}</h1>
            <p>{t('landing.docs.secure.hero')}</p>
            <img src="/assets/docs/docs-secure-hero.png" alt="secure hero img" />
          </div>
          <div className="docs-content">
            <h2>{t('landing.advantages.secure.access')}</h2>
            <div className="docs-content-wrapper">
              <div className="docs-content-text">
                <p>{t('landing.docs.secure.slide1Text1')}</p>
                <p>{t('landing.docs.secure.slide1Text2')}</p>
              </div>
              <img src="/assets/docs/secure-access.png" alt="secure-hero" />
            </div>
          </div>
          <div className="docs-content">
            <h2>{t('landing.advantages.secure.settings')}</h2>
            <div className="docs-content-wrapper reverse">
              <img src="/assets/docs/secure-employers.png" alt="secure-hero" />
              <div className="docs-content-text">
                <p>{t('landing.docs.secure.slide2Text1')}</p>
                <p>{t('landing.docs.secure.slide2Text2')}</p>
              </div>
            </div>
          </div>
        </Column>
        <Suspense fallback={<Spinner />}>
          <LazyContactUs />
        </Suspense>
        <Column width="100%" className="landing-wrapper" margin="0 auto">
          <Suspense fallback={<Spinner />}>
            <LazyNavigation prevKey="financial" nextKey="menu" />
          </Suspense>
        </Column>
        <Suspense fallback={<Spinner />}>
          <LazyFooter />
        </Suspense>
      </div>
    );
  }
}

export default withTranslation()(SecureDoc);
