import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Row } from '../index';
import './style.scss';

const languages = [
  { key: 'en', img: 'united-kingdom.png' },
  { key: 'ru', img: 'russia.png' },
  { key: 'ua', img: 'ukraine.png' },
];

class LanguageRow extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  changeLanguage = (language) => {
    const { i18n, callback = () => {} } = this.props;
    i18n.changeLanguage(language);
    callback(language);
  };

  render() {
    const { i18n } = this.props;
    return (
      <Row width="100%" justifyContent="center">
        {languages.map(el => (
          <div
            className={`language ${i18n.language === el.key ? 'active' : ''}`}
            onClick={() => this.changeLanguage(el.key)}
            key={JSON.stringify(el)}
          >
            <img
              style={{ width: '25px', height: '25px', cursor: 'pointer' }}
              src={`/assets/flags/${el.img}`}
              alt={el.key}
            />
          </div>
        ))}
      </Row>
    );
  }
}

export default withTranslation()(LanguageRow);
