import React, { Component, lazy, Suspense } from 'react';
import Header from '../Landing/Header';
import Footer from '../Landing/Footer';
import './style.scss';
import { Spinner } from '../Landing/components';

const LazyTermsContent = lazy(() => import('./TermsContent'));

class Terms extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="landing terms-wrapper" id="top">
        <Header />
        <Suspense fallback={<Spinner />}>
          <LazyTermsContent />
        </Suspense>
        <Footer />
      </div>
    );
  }
}

export default Terms;
