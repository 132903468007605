import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button, Input, Modal,
} from 'antd';
import { Column, Row } from '../index';

class CommentForBill extends Component {
  constructor(props) {
    super(props);
    const { activeBills, activeKey } = this.props;
    this.state = {
      comment: activeBills.find(bill => bill.id === activeKey).comment,
    };
  }

  close = () => {
    const {
      close, actions: { addCommentToBill }, activeKey,
    } = this.props;
    const { comment } = this.state;
    addCommentToBill(activeKey, comment);
    close();
  };

  render() {
    const { t, close, visible } = this.props;
    const { comment } = this.state;
    return (
      <Modal
        maskClosable={false}
        visible={visible}
        onCancel={close}
        footer={null}
        width="520px"
      >
        <Column style={{ maxWidth: '400px' }} width="100%" margin="0 auto" alignItems="flex-start">
          <h3 style={{ margin: '10px 0 5px' }}>{t('bill.commentForBill')}</h3>
          <Input
            value={comment}
            style={{ width: '100%' }}
            onChange={e => this.setState({ comment: e.target.value })}
          />
          <Row width="100%" justifyContent="flex-end" margin="20px 0 0">
            <Button size="large" type="primary" onClick={this.close}>{t('control.add')}</Button>
          </Row>
        </Column>
      </Modal>
    );
  }
}

export default withTranslation()(CommentForBill);
