import { createSelector } from 'reselect';

const hideEmptyItemSelect = state => state.keeper.hideEmptyItem;

export const hideEmptyItemSelector = createSelector(
  hideEmptyItemSelect,
  items => items,
);

export const getFilteredByZeroCountItems = createSelector(
  state => state.keeper.hideEmptyItem,
  state => state.menu.items,
  state => state.storage.store,
  (hideEmptyItem, items, store) => (hideEmptyItem ? items
    .filter((el) => {
      const storeItem = store.find(item => item.id === el.id);
      return !!storeItem && storeItem?.count > 0;
    }) : items),
);
