import React, { Component } from 'react';
import { GiftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { notificationTypes, showMessage } from '../../../redux/message/actions';
import { SaleDrawer } from '../../../common';
import { checkIsBillOpen } from '../../../redux/statistic/actions';

class OpenPromotionsModalButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPromotions: false,
    };
  }

  promotionsClick = async () => {
    const { t, actions: { showMessage, checkIsBillOpen } } = this.props;
    const isBillOpen = await checkIsBillOpen();
    if (isBillOpen) {
      this.setState({ showPromotions: true });
    } else {
      showMessage({ text: t('marketing.sales.needBill'), type: notificationTypes.INFO });
    }
  }

  render() {
    const { t } = this.props;
    const { showPromotions } = this.state;
    return (
      <>
        <Button
          icon={<GiftOutlined />}
          onClick={this.promotionsClick}
          size="large"
          type="secondary"
          className="keeper-promotions"
        >
          {t('keeper.promotions')}
        </Button>

        {showPromotions && (<SaleDrawer hide={() => this.setState({ showPromotions: false })} />)}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        checkIsBillOpen,
      },
      dispatch,
    ),
  };
}

export default withRouter(connect(null, mapDispatchToProps)(withTranslation()(OpenPromotionsModalButton)));
