import React, { Component } from 'react';
import { Input } from 'antd';
import { connect } from 'react-redux';
import i18 from '../../utils/translation';
import { Row, Column } from '../index';
import { calculateCostFloat, getUnits, validateNumberInput } from '../../utils/helpers';
import { FloatInput, UnitsDropdown } from '../../common';
import './style.scss';

class SaleCost extends Component {
  constructor(props) {
    super(props);
    const { saleCost = 0, saleCount = 0, salePrice = 0 } = props;
    this.state = {
      saleCost: saleCost === 0 && (saleCount !== 0 && salePrice !== 0)
        ? calculateCostFloat({ price: salePrice, count: saleCount }) : saleCost,
      saleCount,
      salePrice,
    };
  }

  reset = () => {
    const { saleCost = 0, saleCount = 0, salePrice = 0 } = this.props;
    this.setState({
      saleCost: saleCost === 0 && (saleCount !== 0 && salePrice !== 0)
        ? calculateCostFloat({ price: salePrice, count: saleCount }) : saleCost,
      saleCount,
      salePrice,
    });
  };

  calculateCost = () => {
    const { callback } = this.props;
    this.setState(state => ({
      saleCost: calculateCostFloat({ price: state.salePrice, count: state.saleCount }),
    }), () => callback(this.state));
  };

  updatePrice = price => this.setState({ salePrice: price || 0 }, () => this.calculateCost());

  updateCount = saleCount => this.setState({ saleCount: parseInt(saleCount, 10) || 0 },
    () => this.calculateCost());

  calculateProfit = () => {
    const { price } = this.props;
    const { saleCost } = this.state;
    if (price === 0) return 0;
    if (saleCost === 0) return 0;
    const profit = parseFloat((parseInt(price, 10) - parseFloat(saleCost)) / parseFloat(saleCost) * 100).toFixed(2);
    return isFinite(profit) ? profit : 0;
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.inEdit !== this.props.inEdit) {
      const { saleCost, saleCount, salePrice } = nextProps;
      this.setState(state => ({ saleCost: saleCost || state.saleCost, saleCount, salePrice }));
    }
  }

  render() {
    const {
      units, inEdit = true, business, callback,
    } = this.props;
    const { saleCost, salePrice, saleCount } = this.state;
    return (
      <Column className="default-card cost" width="100%" justifyContent="flex-start" alignItems="flex-start">
        <h3 style={{ margin: '0 0 5px' }}>{i18.t('item.saleCost')}</h3>
        <Row
          className="mobile-cost-column"
          width="100%"
          justifyContent="space-between"
          alignItems="flex-end"
          margin="5px 0 0"
        >
          <Column className="cost-column" alignItems="flex-start" margin="0 5px 0 0">
            <h4 style={{ margin: '0 20px 9px 0' }}>{i18.t('cost.price')}</h4>
            <FloatInput
              className="cost-input"
              style={{ width: '150px' }}
              placeholder="0"
              type="tel"
              value={salePrice}
              onChange={(value) => {
                // eslint-disable-next-line eqeqeq
                if (value[0] == 0 && value[1] !== '.') {
                  this.updatePrice(value.slice(1, value.length));
                } else {
                  this.updatePrice(value);
                }
              }}
              addonAfter={business.currency}
              readOnly={!inEdit}
            />
          </Column>
          <span className="cost-helper">/</span>
          <Column className="cost-column" alignItems="flex-start" margin="0 5px">
            <h4 style={{ margin: '0 20px 9px 0' }}>{i18.t('cost.count')}</h4>
            <Input
              className="cost-input"
              onKeyUp={validateNumberInput}
              style={{ width: '150px' }}
              placeholder="0"
              type="tel"
              value={saleCount}
              onChange={e => this.updateCount(e.target.value)}
              readOnly={!inEdit}
              addonAfter={(
                <UnitsDropdown
                  readOnly={!inEdit}
                  excludeList={['pc']}
                  value={units}
                  callback={e => callback({ units: e })}
                />
              )}
            />
          </Column>
          <span className="cost-helper">=</span>
          <Column className="cost-column" alignItems="flex-start" margin="0 0 0 5px">
            <h4 style={{ margin: '0 20px 9px 0' }}>{i18.t('item.price')} 1{getUnits(units)}</h4>
            <Input
              className="cost-input"
              style={{ width: '150px' }}
              readOnly
              value={saleCost}
              addonAfter={business.currency}
            />
          </Column>
        </Row>
      </Column>
    );
  }
}

function mapStateToProps(state) {
  return {
    business: state.business,
  };
}


export default connect(mapStateToProps, null, null, { forwardRef: true })(SaleCost);
