import { SET_EMPLOYER, GET_KEEPER_SETTINGS, SET_KEEPER_SETTINGS } from './actions';

const initialState = {
  activeEmployer: null,
  showAll: true,
  showProducts: true,
  showItems: true,
  showFavourites: true,
  showCategoryInCard: true,
  showAddons: true,
  allowBonusPayment: true,
};

function accessReducer(state = initialState, action) {
  switch (action.type) {
    case SET_EMPLOYER:
      return {
        ...state,
        activeEmployer: action.payload,
      };
    case GET_KEEPER_SETTINGS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_KEEPER_SETTINGS:
      return {
        ...state,
        ...action.payload,
      };
    case 'CLEAN_REDUCERS': {
      return initialState;
    }
    default: return state;
  }
}

export default accessReducer;
