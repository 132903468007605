import React, { Component } from 'react';
import { CaretDownOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Table, Button } from 'antd';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import printJS from 'print-js';

import { firebase } from '../../../config';
import {
  Column, Content, Page, PageHeader, TopBar,
} from '../../../components';
import { ItemsTable, RangePicker } from '../../../common';
import {
  alphabeticalSort,
  calculateComponentsCost, calculateConsumablesCost, calculateSubTotal, calculateTotal, prepareCardNumber, printPage,
} from '../../../utils/helpers';
import { getBillsFromDayRange, prepareBillData } from '../../../redux/statistic/helpers';
import { getSaleString } from '../../../components/Clients/Clients';
import { isOnline } from '../../../persistance';
import { notificationTypes, showNetworkError } from '../../../redux/message/actions';
import { getBillUrl } from '../../../api/checkbox';
import { syncBillWithCheckboxRequest } from '../../../api/db/statistic';
import './style.scss';

class Bills extends Component {
  constructor(props) {
    super(props);
    const { resolvedBills = [] } = this.props;
    this.state = {
      bills: resolvedBills,
      pending: false,
      filteredInfo: {
      },
    };
  }


  updateRange = ({ rangeStart, rangeEnd }) => {
    if (!isOnline()) return showNetworkError();
    this.setState({ pending: true });
    getBillsFromDayRange(rangeStart, rangeEnd)
      .then((bills = []) => this.setState({ bills, pending: false }));
  };

  getCost = (el) => {
    const { menu: { idMap }, equipment } = this.props;
    if (!firebase.auth().currentUser || equipment.loading) return;
    switch (el.type) {
      case 'addon': return idMap[el.src.componentId].cost * el.src.count;
      case 'product': {
        return calculateComponentsCost(el.src.components, idMap, el.size)
          + calculateConsumablesCost(el.src.consumables, equipment.idMap, el.size);
      }
      default: return el.src.cost;
    }
  };

  handleChange = (pagination, filters) => this.setState({ filteredInfo: filters });

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.resolvedBills.length !== nextProps.resolvedBills.length) {
      this.setState({ bills: nextProps.resolvedBills });
    }
  }

  render() {
    const {
      t, currency, menu: { idMap }, employers, actions: { showMessage, getResolvedBills },
    } = this.props;
    const {
      bills, pending, filteredInfo,
    } = this.state;

    const billsColumns = [
      {
        title: t('supply.date'),
        width: 160,
        dataIndex: 'date',
        sorter: (a, b) => {
          const delta = parseInt(a.id, 10) - parseInt(b.id, 10);
          if (delta > 0) return 1;
          if (delta < 0) return -1;
          return 0;
        },
        sortDirections: ['descend'],
      }, {
        title: t('bills.closeTime'),
        dataIndex: 'closeTime',
        width: 200,
      }, {
        title: t('access.employer'),
        dataIndex: 'employer',
        render: text => text.name,
        sorter: (a, b) => (-1) * a.employer.name.localeCompare(b.employer.name),
        sortDirections: ['descend', 'ascend'],
        filters: alphabeticalSort(employers, 'name').map(el => ({
          text: el.name, value: el.name,
        })),
        filteredValue: filteredInfo.employer || null,
        onFilter: (value, record) => record.employer.name === value,
      }, {
        title: t('pay.cash'),
        dataIndex: 'cash',
        width: 140,
        render: (text, record) => `${parseFloat(record.pay.cash - record.pay.change).toFixed(2)} ${currency}`,
        sorter: (a, b) => {
          const delta = (a.pay.cash - a.pay.change) - (b.pay.cash - b.pay.change);
          if (delta > 0) return 1;
          if (delta < 0) return -1;
          return 0;
        },
        sortDirections: ['descend'],
      },
      {
        title: t('pay.card'),
        dataIndex: 'card',
        width: 140,
        render: (text, record) => `${record.pay.card || 0} ${currency}`,
        sorter: (a, b) => {
          const delta = (a.pay.card || 0) - (b.pay.card || 0);
          if (delta > 0) return 1;
          if (delta < 0) return -1;
          return 0;
        },
        sortDirections: ['descend'],
      },
      {
        title: t('pay.byBonus'),
        dataIndex: 'bonus',
        width: 140,
        render: (text, record) => `${record.pay.bonus || 0} ${currency}`,
        sorter: (a, b) => {
          const delta = (a.pay.bonus || 0) - (b.pay.bonus || 0);
          if (delta > 0) return 1;
          if (delta < 0) return -1;
          return 0;
        },
        sortDirections: ['descend'],
      },
      {
        title: t('global.subtotal'),
        dataIndex: 'price',
        width: 140,
        render: price => `${price} ${currency}`,
        sorter: (a, b) => {
          const delta = parseFloat(a.price) - parseFloat(b.price);
          if (delta > 0) return 1;
          if (delta < 0) return -1;
          return 0;
        },
        sortDirections: ['descend'],
      },
      {
        title: t('global.sale'),
        dataIndex: 'sale',
        width: 140,
        render: sale => getSaleString(sale, currency),
        sorter: (a, b) => {
          const delta = a.sale.percent - b.sale.percent;
          if (delta > 0) return 1;
          if (delta < 0) return -1;
          return 0;
        },
        sortDirections: ['descend'],
      }, {
        title: t('global.client'),
        dataIndex: 'client',
        width: 180,
        render: client => (client ? `#${prepareCardNumber(client.cardNumber)} ${client.name}` : '-'),
      },
      {
        title: t('global.revenue'),
        dataIndex: 'profit',
        width: 140,
        render: price => `${price.replace(',', '.')} ${currency}`,
        sorter: (a, b) => {
          const delta = parseFloat(a.price) - parseFloat(b.price);
          if (delta > 0) return 1;
          if (delta < 0) return -1;
          return 0;
        },
        sortDirections: ['descend'],
      },
      {
        title: t('settings.syncWithTaxService'),
        width: 100,
        dataIndex: 'syncedWithCheckbox',
        className: 'table-header-center',
        filters: [
          { text: t('settings.onlySynced'), value: true },
          { text: t('settings.onlyUnSynced'), value: false },
        ],
        filteredValue: filteredInfo.syncedWithCheckbox || null,
        onFilter: (value, record) => record.syncedWithCheckbox === value,
        render: syncedWithCheckbox => (
          syncedWithCheckbox
            ? <PlusOutlined />
            : <MinusOutlined />
        ),
      },
      {
        className: 'table-header-center',
        render: record => (record.syncedWithCheckbox ? (
          <Button onClick={(e) => {
            e.stopPropagation();

            printJS(getBillUrl(record.uuid), 'image');
          }}
          >{t('control.print')}
          </Button>
        ) : (
          <Button onClick={(e) => {
            e.stopPropagation();

            this.setState({ pending: true });

            syncBillWithCheckboxRequest(record.id)
              .then(() => {
                this.setState({ pending: false });

                getResolvedBills();
              })
              .catch(() => {
                console.log('in');

                showMessage({ text: 'Щось пішло не так, спробуйте ще раз.', type: notificationTypes.ERROR });

                this.setState({ pending: false });
              });
          }}
          >{t('settings.checkboxForceBillSync')}
          </Button>
        )
        ),
      },
    ];

    return (
      <Page spinning={!firebase.auth().currentUser || pending}>
        <PageHeader />
        <Content>
          <TopBar data-tour="component-tour-2">
            <h3 className="storage-page-title">{t('layout.bills')}: {bills.length}</h3>

            <Button
              className="no-print mobile-bottom-margin mobile-right-margin"
              size="large"
              onClick={() => printPage()}
              type="secondary"
            >{t('control.print')}
            </Button>

            <RangePicker shouldCheckPermission callback={this.updateRange} />
          </TopBar>

          <Table
            onChange={this.handleChange}
            bordered
            pagination={false}
            footer={null}
            size="default"
            className="storage-page-table"
            columns={billsColumns}
            expandRowByClick
            expandIcon={
              ({ expanded }) => <CaretDownOutlined className={`table-icon ${expanded ? 'rotate' : ''}`} />
            }
            expandedRowRender={bill => (
              <Column width="100%">
                {bill.comment && bill.comment.trim() !== '' && (
                  <h3 style={{ margin: 0 }}>{t('supply.comment')}: {bill.comment}</h3>
                )}
                <ItemsTable
                  t={t}
                  className="no-margin"
                  currency={currency}
                  data={prepareBillData(bill.items)
                    .map(el => (el.sizes.map(size => ({
                      ...el,
                      size,
                      price: el.prices[size],
                      quantity: el.quantity[size],
                      type: idMap[el.id] ? idMap[el.id].type : '',
                    })))).flat()
                    .map((item, key) => {
                      const data = { src: idMap[item.id] ? idMap[item.id] : { cost: 0 }, ...item, key };
                      const cost = parseFloat(this.getCost(data)).toFixed(2);
                      return ({
                        ...data,
                        cost,
                        sum: parseFloat(data.quantity * data.price).toFixed(2),
                        gross: (data.quantity * parseFloat(data.price - cost)).toFixed(2),
                      });
                    })}
                />
              </Column>
            )}
            dataSource={bills.map((bill, key) => ({
              ...bill,
              key,
              profit: calculateTotal(bill),
              price: calculateSubTotal(bill).toFixed(2),
              date: moment(bill.id).format('DD.MM.YYYY HH:mm'),
              closeTime: moment(bill.closeTime || bill.id).format('DD.MM HH:mm'),
            })).reverse()}
          />
        </Content>
      </Page>
    );
  }
}

export default withTranslation()(Bills);
