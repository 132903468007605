import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Input, Modal, Select, Radio,
} from 'antd';
import { ModalHOC } from '../../../common';
import { Column } from '../../../components';
import { notificationTypes, showMessage } from '../../../redux/message/actions';
import { editClient } from '../../../redux/marketing/actions';
import './styles.scss';

const { Option } = Select;

class EditClient extends Component {
  constructor(props) {
    super(props);
    const { item } = props;
    this.state = {
      ...item,
    };
  }

  create = () => {
    const {
      item, t, close, actions: { editClient, showMessage },
    } = this.props;
    const {
      name, gender, phone, email, comment, group, cardNumber,
    } = this.state;
    if (name.trim() === '') {
      showMessage({ text: t('validation.emptyName'), type: notificationTypes.ERROR });
      return;
    }
    editClient({
      ...item, name, gender, phone, email, comment, group, cardNumber,
    });
    close();
  };

  render() {
    const {
      t, close, visible, groups,
    } = this.props;
    const {
      name, gender, phone, email, comment, group, cardNumber,
    } = this.state;
    return (
      <Modal
        maskClosable={false}
        centered
        visible={visible}
        onOk={this.create}
        onCancel={close}
        width="100%"
        style={{ maxWidth: '450px' }}
        okText={t('control.save')}
        okButtonProps={{ size: 'large' }}
        cancelButtonProps={{ size: 'large' }}
        cancelText={t('control.cancel')}
        title={t('marketing.clients.editClient')}
      >
        <Column
          width="100%"
          alignItems="flex-start"
          margin="0 auto"
          className="default-card"
          style={{ maxWidth: '324px' }}
        >
          <h3 style={{ margin: '0 0 5px' }}>{t('global.name')}</h3>
          <Input
            style={{ width: '100%' }}
            placeholder={t('global.name')}
            value={name}
            onChange={e => this.setState({ name: e.target.value })}
          />
          <h3 style={{ margin: '10px 0 5px' }}>{t('global.group')}</h3>
          <Select
            defaultValue={groups.find(el => el.id === group) ? group : ''}
            placeholder={t('marketing.groups.selectGroup')}
            className="create-product-select"
            style={{ width: '100%' }}
            onChange={group => this.setState({ group })}
          >
            {groups.map(el => (
              <Option key={el.id} value={el.id}>{el.name} ({el.count}%)</Option>
            ))}
          </Select>
          <h3 style={{ margin: '10px 0 5px' }}>{t('marketing.clients.gender')}</h3>
          <Radio.Group
            className="add-client-gender"
            options={[
              {
                label: t('marketing.clients.male'),
                value: 'male',
              },
              {
                label: t('marketing.clients.female'),
                value: 'female',
              },
            ]}
            onChange={e => this.setState({ gender: e.target.value })}
            value={gender}
            optionType="button"
          />
          <h3 style={{ margin: '10px 0 5px' }}>{t('marketing.clients.number')}</h3>
          <Input
            style={{ width: '100%' }}
            disabled
            value={cardNumber}
          />
          <h3 style={{ margin: '10px 0 5px' }}>{t('login.email')}</h3>
          <Input
            style={{ width: '100%' }}
            placeholder={t('login.email')}
            value={email}
            onChange={e => this.setState({ email: e.target.value })}
          />
          <h3 style={{ margin: '10px 0 5px' }}>{t('landing.phone')}</h3>
          <Input
            style={{ width: '100%' }}
            placeholder="+380..."
            value={phone}
            onChange={e => this.setState({ phone: e.target.value })}
          />
          <h3 style={{ margin: '10px 0 5px' }}>{t('supply.comment')}</h3>
          <Input
            style={{ width: '100%' }}
            placeholder="..."
            value={comment}
            onChange={e => this.setState({ comment: e.target.value })}
          />
        </Column>
      </Modal>
    );
  }
}
function mapStateToProps(state) {
  return {
    groups: state.marketing.groups,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        editClient,
      },
      dispatch,
    ),
  };
}
export default ModalHOC(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditClient)));
