import React, { Component, Fragment } from 'react';
import { Empty } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row } from '../Grid';
import { Card } from '../index';
import { toggleFavouritesItem, toggleFavouritesProduct } from '../../redux/menu/actions';

const getCardClasses = (item) => {
  const cardType = `card-${item.type}`;
  const cardCategory = item.category ? `card-category-${item.category.replace(/\s/g, '_')}` : '';
  const cardInFavourites = item.inFavourites ? 'card-favourites' : '';
  return `tabs-card ${cardType} ${cardCategory} ${cardInFavourites}`;
};


class TabsList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  render() {
    const {
      items = [], callback = () => {}, withFavourites = true, withCategories,
      actions: { toggleFavouritesProduct, toggleFavouritesItem },
    } = this.props;

    return (
      <Fragment>
        {items.length === 0 ? (
          <Row justifyContent="center" alignItems="center" width="100%" style={{ height: '100%' }}>
            <Empty description="" />
          </Row>
        ) : (
          <div className="tabs-list">
            {items.map((el) => {
              const {
                name, category, photo, inFavourites, type,
              } = el;
              return (
                <Card
                  withCategory={withCategories}
                  withFavourites={withFavourites}
                  className={getCardClasses(el)}
                  callback={() => callback(el)}
                  item={{
                    name, category, photo, inFavourites, type,
                  }}
                  toggleFavourites={() => {
                    if (type === 'product') {
                      toggleFavouritesProduct(el);
                    } else {
                      toggleFavouritesItem(el);
                    }
                  }}
                  key={el.id}
                />
              );
            })}
            <div className="empty-screen hidden">
              <Empty description="" />
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        toggleFavouritesProduct,
        toggleFavouritesItem,
      },
      dispatch,
    ),
  };
}

export default connect(null, mapDispatchToProps)(TabsList);
