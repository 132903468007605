import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { addToBill } from '../../redux/statistic/actions';
import AddToBill from './AddToBill';

function mapStateToProps(state) {
  return {
    addonsProp: state.menu.addons,
    idMap: state.menu.idMap,
    currency: state.business.currency,
    activeKey: state.statistic.activeKey,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        addToBill,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToBill);
