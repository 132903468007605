/* eslint-disable */

export default {
  reactour: {
    popover: {
      title: 'Нужна помощь?',
    },
    firstView: {
      title: 'С чего начать?',
      step1: {
        title: 'Добро пожаловать в Shoptoria!',
        text: 'Мы подготовили <b>3 минутное</b> обучения для Вас.<br /> Вы можете пропустить его и просмотреть потом, выбрав его на странице <b>Настройки</b>.',
        buttonText: 'Дальше',
        skipText: 'Пропустить',
      },
      step2: {
        title: 'Терминал',
        text: 'Для доступа к <b>Терминалу</b> каждый ваш сотрудник имеет персональный <b>Пароль доступа к терминалу</b>.<br /> Давайте узнаем Ваш пароль, перейдя во вкладку <b>Сотрудники</b>.',
        buttonText: 'Дальше',
      },
      step3: {
        title: 'Сотрудники',
        text: 'Вы, как и любой ваш сотрудник, имеете персональный <b>Пароль доступа к терминалу</b>.<br /> Как видите, ваш пароль <b>0000</b>.',
        buttonText: 'Дальше',
      },
      step4: {
        title: 'Доступ к терминалу',
        text: 'Теперь Вы знаете ваш <b>Пароль доступа</b> - <b>0000</b>.<br /> Вы можете поменять его в любое время во вкладке <b>Сотрудники</b>.',
        buttonText: 'Дальше',
      },
      step5: {
        title: 'Меню',
        text: 'Кажется, мы готовы к открытию!<br />Давайте просмотрим Ваше <b>Меню</b>.<br />',
        buttonText: 'Дальше',
      },
      step6: {
        title: 'Вкладка Меню',
        text: 'Мы подготовили несколько товаров, исходя из типа Вашего заведения, так что Вы можете опробовать роботу в <b>Терминале</b> без траты времени на подготовку.',
        buttonText: 'Дальше',
      },
      step7: {
        title: 'Нужна помощь?',
        text: 'Если вы не знаете что делать на странице, нажмите на иконку <b>вопроса</b>!',
        buttonText: 'Понятно',
      },
      step8: {
        title: 'Завершение',
        text: 'На этом у нас все!<br /> Создавайте новые <b>Продукты</b>, заполняйте <b>Склад</b>, просматривайте <b>Статистику</b>, управляйте вашими <b>Финансами</b> - делайте всё это вместе с <b>Shoptoria</b>.',
        buttonText: 'Завершить',
      },
    },
    menu: {
      title: 'Что это за страница?',
      step1: {
        title: 'Меню',
        text: 'Во вкладке <b>Меню</b> Вы настраиваете меню вашего заведения.<br /> Вы можете создавать продукты, товары, модификаторы и добавлять собственные категории товаров.',
        buttonText: 'Дальше',
      },
      step2: {
        title: 'Управление категориями',
        text: 'Во вкладке <b>Управление категориями</b> Вы можете добавлять и удалять собственные категории товаров.<br /><br /> Подробнее в <b>"Что такое управление категориями?"</b>',
        buttonText: 'Дальше',
      },
      step21: {
        title: 'Создать рецепт',
        text: 'В окне <b>Создать рецепт</b> Вы выбираете, какой тип рецепта Вы хотите создать.<br /><br />  Подробнее в <b>"Чем отличаются рецепты?"</b>',
        buttonText: 'Дальше',
      },
      step3: {
        title: 'Создание продукта',
        text: 'Во вкладке <b>Создание продукта</b> Вы можете создать собственный продукт с рецепта.<br /><br /> Подробнее в <b>"Как создать продукт?"</b>',
        buttonText: 'Дальше',
      },
      step4: {
        title: 'Создание товара',
        text: 'Во вкладке <b>Создание товара</b> Вы можете создать товар на продажу.<br /><br /> Подробнее в <b>"Как создать товар?"</b>',
        buttonText: 'Дальше',
      },
      step5: {
        title: 'Создание модификатора',
        text: 'Во вкладке <b>Создание модификатора</b> Вы можете создать модификатор для тех. карт.<br /><br /> Подробнее в <b>"Как создать модификатор?"</b>',
        buttonText: 'Дальше',
      },
      step6: {
        title: 'Вкладки меню',
        text: 'В этих вкладках Вы видите продукты, товары и модификаторы тех. карт, разделённые по типам и категориям.<br />Вы можете добавить собственные категори товаров при помощи <b>Управление категориями</b>.',
        buttonText: 'Завершить',
      },
    },
    equipment: {
      title: 'Что это за страница?',
      step1: {
        title: 'Материалы',
        text: 'Во вкладке <b>Материалы</b> Вы управляете расходными материалами, которые используете.<br /> Вы можете добавлять расходные материалы к своим рецептам и товарам.<br /><br /> Материалы также отображаются во вкладке <b>Склад</b>, могут быть добавлены в поставку, списание и доступны при инвентаризации.',
        buttonText: 'Дальше',
      },
      step2: {
        title: 'Расходные материалы',
        text: 'Здесь вы можете добавить, редактировать или удалить ваши расходные материалы.<br /><br /> Подробнее в <b>"Что такое расходные материалы?"</b>',
        buttonText: 'Завершить',
      },
    },
    component: {
      title: 'Что это за страница?',
      step1: {
        title: 'Компоненты',
        text: 'Во вкладке <b>Компоненты</b> Вы управляете компонентами, которые используете.<br /> Вы можете добавлять компоненты к своим рецептам.<br /><br /> Компоненты также отображаются во вкладке <b>Склад</b>, могут быть добавлены в поставку, списание и доступны при инвентаризации.',
        buttonText: 'Дальше',
      },
      step2: {
        title: 'Компоненты',
        text: 'Здесь вы можете добавить, редактировать или удалить ваши компоненты.<br /><br /> Подробнее в <b>"Что такое компоненты?"</b>',
        buttonText: 'Завершить',
      },
    },
    supply: {
      title: 'Что это за страница?',
      step1: {
        title: 'Вкладка Поставки',
        text: 'Во вкладке <b>Поставки</b> Вы можете добавить поставку на ваш <b>Склад</b>.<br /><br /> Товары, компоненты и расходные материалы, которые вы добавляете в поставку увеличат свое количество на складе.<br />',
        buttonText: 'Дальше',
      },
      step2: {
        title: 'Поставки',
        text: 'Здесь вы можете добавить, редактировать или удалить ваши поставки.<br /><br /> Подробнее в <b>"Что такое поставка?"</b>',
        buttonText: 'Завершить',
      },
    },
    waste: {
      title: 'Что это за страница?',
      step1: {
        title: 'Вкладка Списания',
        text: 'Во вкладке <b>Списания</b> Вы можете добавить списание на ваш <b>Склад</b>.<br /><br /> Товары, компоненты и расходные материалы, которые вы добавляете в списание уменьшат свое количество на складе.<br />',
        buttonText: 'Дальше',
      },
      step2: {
        title: 'Списания',
        text: 'Здесь вы можете добавить, редактировать или удалить ваши списания.<br /><br /> Подробнее в <b>"Что такое списание?"</b>',
        buttonText: 'Завершить',
      },
    },
    inventory: {
      title: 'Что это за страница?',
      step1: {
        title: 'Вкладка Инвентаризации',
        text: 'Во вкладке <b>Инвентаризации</b> Вы можете провести инвентаризацию вашего <b>Склада</b>.<br /><br /> Товары, компоненты и расходные материалы, которые вы добавляете в инвентаризацию получат новое количество на складе.<br />',
        buttonText: 'Дальше',
      },
      step2: {
        title: 'Инвентаризации',
        text: 'Здесь вы можете добавить, редактировать или удалить ваши инвентаризации.<br /><br /> Подробнее в <b>"Что такое инвентаризация?"</b>',
        buttonText: 'Завершить',
      },
    },
    consumption: {
      title: 'Что это за страница?',
      step1: {
        title: 'Вкладка Расход',
        text: 'Во вкладке <b>Расход</b> Вы можете увидеть, сколько вы расходуете материалов.<br />',
        buttonText: 'Дальше',
      },
      step2: {
        title: 'Временной промежуток',
        text: 'Вы можете выбрать любой временной промежуток.<br/><br /> Товары, компоненты и расходные материалы, которые вы расходуете высчитываются, исходя из созданых вами чеков.',
        buttonText: 'Завершить',
      },
    },
    financialAccounts: {
      title: 'Что это за страница?',
      step1: {
        title: 'Вкладка Счета',
        text: 'Во вкладке <b>Счета</b> Вы можете создать финансовый счет.',
        buttonText: 'Дальше',
      },
      step2: {
        title: 'Счета',
        text: 'Здесь вы можете добавить, редактировать или удалить ваши финансовые счета.<br /><br /> Подробнее в <b>"Что такое финансовые счета?"</b>',
        buttonText: 'Завершить',
      },
    },
    financialCategories: {
      title: 'Что это за страница?',
      step1: {
        title: 'Вкладка Категории',
        text: 'Во вкладке <b>Категории</b> Вы можете создать финансовую категорию.<br /><br /> Финансовые категории используются для создания финансовых транзакций.',
        buttonText: 'Дальше',
      },
      step2: {
        title: 'Категории',
        text: 'Здесь вы можете добавить, редактировать или удалить ваши финансовые категории.<br /><br /> Подробнее в <b>"Что такое финансовые категории?"</b>',
        buttonText: 'Завершить',
      },
    },
    financialShifts: {
      title: 'Что это за страница?',
      step1: {
        title: 'Вкладка Кассовые смены',
        text: 'Во вкладке <b>Кассовые смены</b> Вы можете увидеть отчеты по вашим кассовым сменам.',
        buttonText: 'Дальше',
      },
      step2: {
        title: 'Кассовые смены',
        text: 'Вы можете развернуть таблицу, чтобы увидеть больше информации.',
        buttonText: 'Завершить',
      },
    },
    financialTransactions: {
      title: 'Что это за страница?',
      step1: {
        title: 'Вкладка Транзакции',
        text: 'Во вкладке <b>Транзакции</b> Вы можете создать финансовую транзакцию.<br /><br /> Финансовые транзакции позволяют вам контролировать финансовые потоки Вашего бизнеса.',
        buttonText: 'Дальше',
      },
      step2: {
        title: 'Транзакции',
        text: 'Здесь вы можете добавить, редактировать или удалить ваши финансовые транзакции.<br /><br /> Подробнее в <b>"Что такое финансовые транзакции?"</b>',
        buttonText: 'Завершить',
      },
    },
    employers: {
      title: 'Что это за страница?',
      step1: {
        title: 'Вкладка Сотрудники',
        text: 'Во вкладке <b>Сотрудники</b> Вы управляете своими сотрудниками.<br /><br /> Одна из ключевых вещей здесь это <b>Пароль доступа к Терминалу</b>, при помощи которого сотрудник взаимодействует с <b>Терминалом</b>.',
        buttonText: 'Дальше',
      },
      step2: {
        title: 'Сотрудники',
        text: 'Здесь вы можете добавить, редактировать или удалить ваших сотрудников.<br /><br /> Подробнее в <b>"Как управлять сотрудниками?"</b>',
        buttonText: 'Завершить',
      },
    },
    jobs: {
      title: 'Что это за страница?',
      step1: {
        title: 'Вкладка Должности',
        text: 'Во вкладке <b>Должности</b> Вы управляете должностями вашего заведения.<br /><br /> Должности помогут вас при расчёте зарплаты ваших сотрудников.',
        buttonText: 'Дальше',
      },
      step2: {
        title: 'Должности',
        text: 'Здесь вы можете добавить, редактировать или удалить должности.<br /><br /> Подробнее в <b>"Как управлять должностями?"</b>',
        buttonText: 'Завершить',
      },
    },
    subscription: {
      title: 'Что это за страница?',
      step1: {
        title: 'Оплата подписки',
        text: 'Во вкладке <b>Оплата подписки</b> Вы управляете своей подпиской.<br /><br /> Здесь представлена информация о тарифном плане, история платежей и возможность оплатить 1 месяц подписки.<br />',
        buttonText: 'Дальше',
      },
      step2: {
        title: 'Аккаунт',
        text: 'Здесь Вы видите данные о <b>Вашем аккаунте</b>: актуальный тарифный план, цена подписки, последний день подписки.<br />',
        buttonText: 'Дальше',
      },
      step3: {
        title: 'Платежи',
        text: 'Здесь Вы видите историю ваших платежей.<br /><br>Вы можете оплатить 1 месяц подписки при помощи кнопки.',
        buttonText: 'Завершить',
      },
    },
  },
};
