import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { showMessage } from '../../redux/message/actions';
import ManageEquipments from './ManageEquipments';

function mapStateToProps(state) {
  return {
    menu: state.menu,
    equipment: state.equipment,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
      },
      dispatch,
    ),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageEquipments));
