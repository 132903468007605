import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Layout from './Layout';
import { showMessage } from '../../redux/message/actions';

function mapStateToProps(state) {
  return {
    isAdmin: state.business.isAdmin,
    user: state.user,
    business: state.business,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
      },
      dispatch,
    ),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
