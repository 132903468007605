import React, { Component } from 'react';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { withTranslation } from 'react-i18next';
import {
  Column, Row, PageHeader, Content, TopBar, Page,
} from '../../../components';
import ConsumableModal from './ConsumableModal';
import CreateConsumable from './Create';
import { alphabeticalSort, getDefaultImageByType } from '../../../utils/helpers';
import { deleteModal } from '../../../utils/modals';
import { notificationTypes, showNetworkError } from '../../../redux/message/actions';
import { isOnline } from '../../../persistance';

class Consumables extends Component {
  constructor(props) {
    super(props);
    const { equipment: { idMap }, history: { location: { state } } } = props;
    const selectedConsumable = state && state.equipmentId ? idMap[state.equipmentId] : null;
    this.state = {
      selectedConsumable,
    };
  }

  componentDidMount() {
    const { selectedConsumable } = this.state;
    if (selectedConsumable) {
      this.consumableModal.show();
    }
  }

  selectConsumable = (selectedConsumable) => {
    if (!isOnline()) return showNetworkError();
    this.setState({ selectedConsumable });
    this.consumableModal.show();
  };

  render() {
    const {
      t, equipment, menu, currency, actions: { removeConsumable, showMessage },
    } = this.props;
    const { selectedConsumable } = this.state;
    const consumablesTableColumns = [{
      title: t('global.photo'),
      dataIndex: 'photo',
      width: '115px',
      render: (photo, record) => (
        <Column alignItems="center" justifyContent="center" width="100%">
          <img
            src={photo || getDefaultImageByType('consumable')}
            style={{ opacity: photo ? 1 : 0.5, margin: 0 }}
            alt={record.name}
            className="default-img"
          />
        </Column>
      ),
    }, {
      title: t('global.name'),
      dataIndex: 'name',
      render: name => <h3>{name}</h3>,
    }, {
      title: t('cost.title'),
      dataIndex: 'cost',
      width: '200px',
      render: text => `${text} ${currency}`,
    }, {
      title: '',
      width: '30px',
      render: (text, record) => (
        <Row width="100%" justifyContent="center">
          <Button onClick={() => this.selectConsumable(record)} type="link" icon={<EyeOutlined />} />
        </Row>
      ),
    }, {
      title: '',
      width: '30px',
      render: (text, record) => (
        <Row width="100%" justifyContent="center">
          <Button
            onClick={() => {
              const canBeDeleted = !menu.products
                .map(el => el.consumables.map(e => e.materials).flat()).flat().includes(record.id);
              if (canBeDeleted) {
                deleteModal(() => removeConsumable(record));
              } else {
                showMessage({ text: t('validation.itemInUse'), type: notificationTypes.ERROR });
              }
            }}
            type="link"
            icon={<DeleteOutlined />}
          />
        </Row>
      ),
    },
    ];
    return (
      <Page>
        <PageHeader />
        <Content>
          <TopBar data-tour="equipment-tour-2">
            <h3 className="storage-page-title">
              {t('global.consumables')}: {equipment.consumables.length}
            </h3>
            <Button
              className="add-button"
              size="large"
              onClick={() => {
                if (!isOnline()) return showNetworkError();
                this.createConsumableModal.show();
              }}
            >
              {t('control.add')}
            </Button>
          </TopBar>
          <Table
            bordered
            pagination={false}
            size="default"
            className="storage-page-table"
            columns={consumablesTableColumns}
            dataSource={alphabeticalSort(equipment.consumables, 'name').map((el, index) => ({
              ...el, key: index,
            }))}
          />
        </Content>
        <ConsumableModal consumable={selectedConsumable} ref={(ref) => { this.consumableModal = ref; }} />
        <CreateConsumable ref={(ref) => { this.createConsumableModal = ref; }} />
      </Page>
    );
  }
}

export default withTranslation()(Consumables);
