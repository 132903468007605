import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Input, Modal } from 'antd';
import { Column, FileUpload, Row } from '../../components';
import {
  checkIsNameExist, getDefaultImageByType, getUnits, validateNumberInput,
} from '../../utils/helpers';
import { deleteModal } from '../../utils/modals';
import './style.scss';
import { NumberInput } from '../../common';
import { notificationTypes, showNetworkError } from '../../redux/message/actions';
import { isOnline } from '../../persistance';

class AddonModal extends Component {
  constructor(props) {
    super(props);
    const { addon, menu: { idMap } } = props;
    this.state = {
      inEdit: false,
      photo: null,
      ...idMap[addon.componentId],
      ...addon,
      loading: false,
    };
  }

  submit = () => {
    if (!isOnline()) return showNetworkError();
    const {
      t, addon, menu: { addons }, actions: { editAddon, showMessage },
    } = this.props;
    const {
      count, price, photo, name,
    } = this.state;
    if (name.trim() === '') {
      showMessage({ text: t('validation.emptyName'), type: notificationTypes.ERROR });
      return;
    }
    if (checkIsNameExist(name, addons.filter(el => el.id !== addon.id))) {
      showMessage({ text: t('validation.uniqueAddonName'), type: notificationTypes.ERROR });
      return;
    }
    if (count === 0) {
      showMessage({ text: t('validation.addonCalculation'), type: notificationTypes.ERROR });
      return;
    }
    if (price === 0) {
      showMessage({ text: t('validation.addonPrice'), type: notificationTypes.ERROR });
      return;
    }
    this.setState({ loading: true });
    editAddon({
      ...addon, price: parseInt(price, 10), photo, count, name,
    }).then(() => this.setState({ loading: false, inEdit: false }));
  };

  updateCount = e => this.setState({ count: e.target.value });

  updatePrice = e => this.setState({ price: e.target.value });

  reset = () => {
    const { addon, menu: { idMap } } = this.props;
    return this.setState({
      ...idMap[addon.componentId],
      ...addon,
      inEdit: false,
    });
  };

  delete = () => {
    const { addon, close, actions: { removeAddon } } = this.props;
    removeAddon(addon);
    close();
  };

  render() {
    const {
      t, visible, addon, close, business, menu: { idMap },
    } = this.props;
    if (addon == null) return <div />;
    const {
      inEdit, price, count, photo, loading, name, units,
    } = this.state;
    return (
      <Modal
        maskClosable={false}
        visible={visible}
        onOk={this.submit}
        onCancel={close}
        width="100%"
        style={{ maxWidth: '446px' }}
        footer={(
          <Row width="100%" justifyContent="flex-end">
            {inEdit ? (
              <Fragment>
                <Button
                  size="large"
                  style={{ marginRight: '10px' }}
                  onClick={this.reset}
                  type="default"
                >{t('control.cancel')}
                </Button>
                <Button size="large" loading={loading} type="primary" onClick={this.submit}>{t('control.save')}</Button>
              </Fragment>
            ) : (
              <Fragment>
                <Button
                  size="large"
                  style={{ marginRight: 'auto' }}
                  onClick={() => deleteModal(this.delete)}
                  icon={<DeleteOutlined />}
                />
                <Button
                  size="large"
                  onClick={() => {
                    if (!isOnline()) return showNetworkError();
                    this.setState({ inEdit: true });
                  }}
                >{t('control.edit')}
                </Button>
              </Fragment>
            )}
          </Row>
        )}
        title={`${t('global.component')}: ${(idMap[addon.componentId] || {}).name}`}
        className="addon-modal"
      >
        <Column
          justifyContent="flex-start"
          width="100%"
          alignItems="flex-start"
          className="default-card mobile-addon-modal"
        >
          <h3 style={{ margin: '0 0 5px' }}>{t('global.name')}</h3>
          <Input
            style={{ width: '100%', marginBottom: '10px' }}
            placeholder={t('global.name')}
            value={name}
            readOnly={!inEdit}
            onChange={e => this.setState({ name: e.target.value })}
          />
          <Row
            justifyContent="flex-start"
            width="100%"
            alignItems="flex-start"
            className="mobile-addon-modal"
          >
            <Column>
              <h3>{t('global.image')}</h3>
              {inEdit ? (
                <FileUpload imageUrl={photo} callback={photo => this.setState({ photo })} />
              ) : (
                <img
                  src={typeof photo === 'string' ? photo : photo
                    ? URL.createObjectURL(photo) : getDefaultImageByType('addon')}
                  alt="avatar"
                  style={{
                    opacity: photo ? 1 : '0.5',
                    borderRadius: '4px',
                    width: '180px',
                    height: '110px',
                    border: '1px solid #d9d9d9',
                  }}
                />
              )}
            </Column>
            <Column alignItems="flex-start" margin="0 0 0 18px" className="addon-modal-calculation">
              <h3>{t('global.calculation')}</h3>
              <NumberInput
                placeholder="0"
                type="tel"
                onKeyUp={validateNumberInput}
                onChange={this.updateCount}
                value={count}
                style={{ width: '100%' }}
                addonAfter={getUnits(units)}
                readOnly={!inEdit}
              />
              <h3 style={{ marginTop: '13px' }}>{t('global.price')}</h3>
              <NumberInput
                onKeyUp={validateNumberInput}
                style={{ width: '100%' }}
                placeholder="0"
                type="tel"
                value={price}
                onChange={this.updatePrice}
                addonAfter={business.currency}
                readOnly={!inEdit}
              />
            </Column>
          </Row>
        </Column>
      </Modal>
    );
  }
}

export default withTranslation()(AddonModal);
