import React, { Component } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { withTranslation } from 'react-i18next';
import { firebase } from '../../../config';
import {
  Row, PageHeader, Content, TopBar, Page,
} from '../../../components';
import AddSale from './AddSale';
import EditSale from './EditSale';
import { alphabeticalSort } from '../../../utils/helpers';
import { deleteModal } from '../../../utils/modals';
import { isOnline } from '../../../persistance';
import { showNetworkError } from '../../../redux/message/actions';

class Sales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
    };
  }

  selectItem = (record) => {
    if (!isOnline()) return showNetworkError();
    const { sales } = this.props;
    const selectedItem = sales.find(el => el.id === record.id);
    this.setState({ selectedItem });
    this.editSale.show();
  };

  render() {
    const {
      t, sales, salesLoaded, actions: { removeSale },
    } = this.props;
    const { selectedItem } = this.state;

    const clientsTableColumns = [{
      title: t('global.name'),
      dataIndex: 'name',
      width: 200,
    }, {
      title: t('global.startDate'),
      dataIndex: 'startDate',
      width: 150,
      render: (id, record) => record.startDate,
      sorter: (a, b) => {
        const delta = parseInt(a.startDate, 10) - parseInt(b.startDate, 10);
        if (delta > 0) return 1;
        if (delta < 0) return -1;
        return 0;
      },
      sortDirections: ['descend', 'ascend'],
    }, {
      title: t('global.endDate'),
      dataIndex: 'endDate',
      width: 150,
      render: (id, record) => record.endDate,
      sorter: (a, b) => {
        const delta = parseInt(a.endDate, 10) - parseInt(b.endDate, 10);
        if (delta > 0) return 1;
        if (delta < 0) return -1;
        return 0;
      },
      sortDirections: ['descend', 'ascend'],
    }, {
      title: t('global.timeRange'),
      dataIndex: 'test',
      width: 150,
      render: (id, record) => `${record.startTime} - ${record.endTime}`,
    },
    {
      title: '',
      width: '30px',
      render: (text, record) => (
        <Row width="100%" justifyContent="center">
          <Button onClick={() => this.selectItem(record)} type="link" icon={<EditOutlined />} />
        </Row>
      ),
    }, {
      title: '',
      width: '30px',
      render: (text, record) => (
        <Row width="100%" justifyContent="center">
          <Button
            onClick={() => deleteModal(() => removeSale(record.id))}
            type="link"
            icon={<DeleteOutlined />}
          />
        </Row>
      ),
    }];
    return (
      <Page spinning={!firebase.auth().currentUser || !salesLoaded}>
        <PageHeader />
        <Content>
          <TopBar data-tour="clients-tour-2">
            <h3 className="storage-page-title">{t('layout.sales')}: {sales.length}</h3>
            <Button
              className="add-button"
              size="large"
              onClick={() => {
                if (!isOnline()) return showNetworkError();
                this.addSale.show();
              }}
            >
              {t('control.add')}
            </Button>
          </TopBar>
          <Table
            bordered
            pagination={false}
            size="default"
            style={{ width: '100%', overflowX: 'scroll', margin: '10px 0 0' }}
            columns={clientsTableColumns}
            dataSource={alphabeticalSort(sales, 'name')
              .map((el, index) => ({ ...el, key: index }))}
          />
        </Content>
        <AddSale ref={(ref) => { this.addSale = ref; }} />
        <EditSale sale={selectedItem} ref={(ref) => { this.editSale = ref; }} />
      </Page>
    );
  }
}

export default withTranslation()(Sales);
