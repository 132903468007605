/* eslint-disable */

export default {
  general: {
    question1Title: 'Что такое бесплатный период?',
    question1Answer: 'После регистрации первые 15 дней вы бесплатно работаете в тарифе Startup. Мы поможем заполнить меню и начать продавать, пришлем обучающие видео и статьи, ответим на все вопросы.',
    question2Title: 'Как можно оплатить?',
    question2Answer: 'Оплачивайте подписку банковской картой в админ-панели.',
    question3Title: 'Я смогу потом перейти на другой тарифный план?',
    question3Answer: 'Да, вы всегда можете поменять тариф. Для этого напишите нам.',
    question4Title: 'Сколько стоит установить Shoptoria?',
    question4Answer: 'Вы оплачиваете только подписку. Скрытых или дополнительных платежей за установку у нас нет.',
    question5Title: 'Если я оплачу на год и закрою заведение, вы вернете деньги?',
    question5Answer: 'Да, если вы закроете заведение, мы вернем деньги. Если у вас ремонт или переезд, мы приостановим подписку на это время.',
    question6Title: 'Нужно ли заключать договор?',
    question6Answer: 'Мы работаем по публичной оферте, поэтому заключать бумажный договор необязательно. Напишите нам, если хотите подписать отдельный договор.',
  }
}
