import React, { Component } from 'react';
import moment from 'moment';
import { Spin, Table } from 'antd';
import { withTranslation } from 'react-i18next';
import { firebase } from '../../../config';
import {
  Header, Row, PageHeader, Content, Page,
} from '../../../components';
import { RangePicker } from '../../../common';
import { getBillsFromDayRange } from '../../../redux/statistic/helpers';
import { alphabeticalSort } from '../../../utils/helpers';

class Salary extends Component {
  constructor(props) {
    super(props);
    const { resolvedBills = [] } = this.props;
    this.state = {
      bills: resolvedBills,
      pending: false,
      rangeStart: moment().subtract(1, 'week'),
      rangeEnd: moment(),
      inProgress: true,
    };
  }

  componentDidMount() {
    if (firebase.auth().currentUser) {
      this.updateRange(this.state);
    } else {
      firebase.auth().onAuthStateChanged(() => this.updateRange(this.state));
    }
  }

  updateRange = ({ rangeStart, rangeEnd }) => {
    this.setState({ rangeStart, rangeEnd, pending: true });
    getBillsFromDayRange(rangeStart, rangeEnd)
      .then((bills = []) => this.setState({ bills, pending: false }));
  };

  render() {
    const { t, business, access: { jobs, employers } } = this.props;
    const { inProgress } = this.state;
    const jobsTableColumns = [{
      title: t('global.name'),
      dataIndex: 'name',
      sorter: (a, b) => (-1) * a.name.localeCompare(b.name),
      sortDirections: ['descend'],
    },
    {
      title: t('job.jobName'),
      dataIndex: 'job',
      sorter: (a, b) => (-1) * a.name.localeCompare(b.name),
      sortDirections: ['descend'],
      render: job => job.name,
    },
    {
      title: t('job.salaryCalculation'),
      children: [
        {
          title: t('job.salaryPerHour'),
          dataIndex: 'salaryPerHour',
          render: text => (text ? `${text} ${business.currency}` : '-'),
        },
        {
          title: t('job.salaryPerDay'),
          dataIndex: 'salaryPerDay',
          render: text => (text ? `${text} ${business.currency}` : '-'),
        },
        {
          title: t('job.salaryPerMonth'),
          dataIndex: 'salaryPerMonth',
          render: text => (text ? `${text} ${business.currency}` : '-'),
        },
        { title: t('job.flexRatePersonal'), dataIndex: 'flexRatePersonal', render: text => (text ? `${text} %` : '-') },
        { title: t('job.flexRateTotal'), dataIndex: 'flexRateTotal', render: text => (text ? `${text} %` : '-') },
      ],
    }, {
      title: t('global.sum'),
      dataIndex: 'total',
      render: text => `${text} ${business.currency}`,
      sorter: (a, b) => {
        const delta = a.sale.percent - b.sale.percent;
        if (delta > 0) return 1;
        if (delta < 0) return -1;
        return 0;
      },
      sortDirections: ['descend'],
    }];
    if (inProgress) {
      return (
        <Page spinning={!firebase.auth().currentUser}>
          <PageHeader />
          <Content>
            <h2 style={{ margin: '0 auto' }}>Coming soon!</h2>
          </Content>
        </Page>
      );
    }
    return (
      <Spin wrapperClassName="statistic" spinning={!firebase.auth().currentUser}>
        <Header><h2 className="header-name">{business.name}</h2></Header>
        <Row alignItems="flex-end" margin="10px" width="calc(100% - 20px)">
          <h2 style={{ margin: 0 }}>{t('layout.salary')}: {employers.length}</h2>
          <RangePicker callback={this.updateRange} rangeStart={moment().subtract(1, 'week')} />
        </Row>
        <Table
          bordered
          pagination={false}
          size="default"
          className="storage-page-table"
          columns={jobsTableColumns}
          dataSource={alphabeticalSort(employers).map((el, index) => ({
            ...el, key: index, job: jobs.find(job => job.id === el.position) || {}, total: 0,
          }))}
        />
      </Spin>
    );
  }
}

export default withTranslation()(Salary);
