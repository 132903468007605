import { notification } from 'antd';
import i18n from '../../utils/translation';

export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const CLEAN_MESSAGE = 'CLEAN_MESSAGE';

export const notificationTypes = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO',
};

export const showErrorMessage = (e) => {
  console.log(e);
  notification.error({ message: e.response.data.error });
};

export const showNetworkError = () => notification.warning({
  message: i18n.t('actions.needNetworkTitle'),
  description: i18n.t('actions.needNetworkDescription'),
});

export function cleanMessage() {
  return (dispatch) => {
    dispatch({
      type: CLEAN_MESSAGE,
    });
  };
}

/**
 * Show notification message
 * @param text
 * @param type
 * @param description
 * @returns {function(...[*]=)}
 */
export const showMessage = ({ text, type = notificationTypes.SUCCESS, description = '' }) => (dispatch) => {
  dispatch({
    type: SHOW_MESSAGE,
    payload: {
      text,
      type,
    },
  });
  switch (type) {
    case notificationTypes.SUCCESS:
      notification.success({
        message: text,
        description,
        duration: 3,
        top: 36,
      });
      break;
    case notificationTypes.ERROR:
      notification.error({
        message: text,
        description,
        duration: 3,
        top: 36,
      });
      break;
    case notificationTypes.WARNING:
      notification.warning({
        message: text,
        description,
        duration: 3,
        top: 36,
      });
      break;
    case notificationTypes.INFO:
      notification.info({
        message: text,
        description,
        duration: 3,
        top: 36,
      });
      break;
    default:
      notification.info({
        message: text,
        description,
        duration: 3,
        top: 36,
      });
      break;
  }
  dispatch(cleanMessage());
};
