import React, { Component } from 'react';
import {
  Button, Checkbox, Spin,
} from 'antd';
import { withTranslation } from 'react-i18next';
import { firebase } from '../../../config';
import { PageHeader, Column } from '../../../components';
import { isOnline } from '../../../persistance';
import { showNetworkError } from '../../../redux/message/actions';

class KeeperSettings extends Component {
  constructor(props) {
    super(props);
    const { keeper } = props;
    this.state = {
      showAll: true,
      showProducts: true,
      showItems: true,
      showCategoryInCard: true,
      showFavourites: true,
      showAddons: true,
      allowBonusPayment: true,
      hideEmptyItem: false,
      ...keeper,
    };
  }

  submit = () => {
    if (!isOnline()) return showNetworkError();
    const { actions: { editKeeperSettings } } = this.props;
    const {
      showAll, showProducts, showItems, showCategoryInCard, showFavourites, showAddons, hideEmptyItem,
      allowBonusPayment,
    } = this.state;
    this.setState({ pendingSave: true });
    editKeeperSettings({
      showAll,
      showProducts,
      showItems,
      showCategoryInCard,
      showFavourites,
      showAddons,
      hideEmptyItem,
      allowBonusPayment,
    }).then(() => {
      this.setState({ pendingSave: false });
    });
  };

  toggleState = key => this.setState(state => ({ [key]: !state[key] }))

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { keeper } = nextProps;
    if (JSON.stringify(keeper) !== JSON.stringify(this.props.keeper)) {
      this.setState({ ...keeper });
    }
  }

  render() {
    const { t } = this.props;
    const {
      pendingSave, showAll, showProducts, showItems, showFavourites, showCategoryInCard, showAddons, hideEmptyItem,
      allowBonusPayment,
    } = this.state;
    return (
      <Spin wrapperClassName="storage-page settings-page" spinning={!firebase.auth().currentUser}>
        <PageHeader />
        <div className="storage-page-content">
          <Column
            className="mobile-block"
            style={{ maxWidth: '600px' }}
            width="100%"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Column
              margin="0 0 10px"
              width="100%"
              className="default-card settings-block-notification"
            >
              <h2>{t('settings.keeper')}</h2>
              <Column width="100%">
                <h4 style={{ fontWeight: 'normal' }}>
                  {t('settings.keeperDescription')}
                  &nbsp;<b>{t('settings.keeperDescription2')}</b>&nbsp;
                  {t('settings.keeperDescription3')}
                </h4>
                <h3>{t('settings.keeperTabs')}:</h3>
                <Checkbox
                  className="checkbox-large"
                  style={{ margin: '5px 0' }}
                  onClick={() => this.toggleState('showAll')}
                  checked={showAll}
                >{t('global.all')}
                </Checkbox>
                <Checkbox
                  className="checkbox-large"
                  style={{ margin: '5px 0' }}
                  onClick={() => this.toggleState('showProducts')}
                  checked={showProducts}
                >{t('global.products')}
                </Checkbox>
                <Checkbox
                  className="checkbox-large"
                  style={{ margin: '5px 0' }}
                  onClick={() => this.toggleState('showItems')}
                  checked={showItems}
                >{t('global.items')}
                </Checkbox>
                <Checkbox
                  className="checkbox-large"
                  style={{ margin: '5px 0' }}
                  onClick={() => this.toggleState('showFavourites')}
                  checked={showFavourites}
                >{t('global.favourites')}
                </Checkbox>
                <Checkbox
                  className="checkbox-large"
                  style={{ margin: '5px 0' }}
                  onClick={() => this.toggleState('showAddons')}
                  checked={showAddons}
                >{t('global.addons')}
                </Checkbox>
                <h3 style={{ marginTop: '10px' }}>{t('settings.other')}:</h3>
                <Checkbox
                  className="checkbox-large"
                  style={{ margin: '5px 0' }}
                  onClick={() => this.toggleState('allowBonusPayment')}
                  checked={allowBonusPayment}
                >{t('settings.allowBonusPayment')}
                </Checkbox>
                <Checkbox
                  className="checkbox-large"
                  style={{ margin: '5px 0' }}
                  onClick={() => this.toggleState('showCategoryInCard')}
                  checked={showCategoryInCard}
                >{t('settings.showCategoryInCard')}
                </Checkbox>
                <Checkbox
                  className="checkbox-large"
                  style={{ margin: '5px 0' }}
                  onClick={() => this.toggleState('hideEmptyItem')}
                  checked={hideEmptyItem}
                >{t('settings.hideEmptyItem')}
                </Checkbox>
              </Column>
            </Column>
            <Button
              loading={pendingSave}
              type="primary"
              size="large"
              className="settings-save"
              onClick={this.submit}
            >{t('control.save')}
            </Button>
          </Column>
        </div>
      </Spin>
    );
  }
}

export default withTranslation()(KeeperSettings);
