import React, { Component } from 'react';
import { Button } from 'antd';
import { withTranslation } from 'react-i18next';
import { firebase } from '../../../config';
import {
  Content, Page, PageHeader, TopBar,
} from '../../../components';
import { ItemsTable, RangePicker } from '../../../common';
import { getBillsFromDayRange, prepareBillData } from '../../../redux/statistic/helpers';
import { calculateComponentsCost, calculateConsumablesCost, printPage } from '../../../utils/helpers';
import { isOnline } from '../../../persistance';
import { showNetworkError } from '../../../redux/message/actions';

class Items extends Component {
  constructor(props) {
    super(props);
    const { resolvedBills = [] } = this.props;
    this.state = {
      bills: resolvedBills,
      pending: false,
    };
  }

  getAllItems = bills => bills.reduce((res, bill) => res.concat(bill.items), []);

  getCost = (el) => {
    const { menu: { idMap }, equipment } = this.props;
    if (!firebase.auth().currentUser || equipment.loading) return;
    switch (el.type) {
      case 'addon': return idMap[el.src.componentId].cost * el.src.count;
      case 'product': {
        return calculateComponentsCost(el.src.components, idMap, el.size)
          + calculateConsumablesCost(el.src.consumables, equipment.idMap, el.size);
      }
      default: return el.src.cost;
    }
  };

  updateRange = ({ rangeStart, rangeEnd }) => {
    if (!isOnline()) return showNetworkError();
    this.setState({ pending: true });
    getBillsFromDayRange(rangeStart, rangeEnd)
      .then((bills = []) => this.setState({ bills, pending: false }));
  };

  getItemsData = (bills) => {
    const { menu } = this.props;
    // Data for items calculation
    const allItems = this.getAllItems(bills);
    return prepareBillData(allItems).filter(el => menu.idMap[el.id]).map(el => (el.sizes.map(size => ({
      ...el, size, price: el.prices[size], quantity: el.quantity[size], type: menu.idMap[el.id].type,
    })))).flat()
      .map((item, key) => {
        const data = { src: menu.idMap[item.id], ...item, key };
        const cost = parseFloat(this.getCost(data)).toFixed(2);
        return ({
          ...data,
          cost,
          sum: parseFloat(data.quantity * data.price).toFixed(2),
          gross: (data.quantity * parseFloat(data.price - cost)).toFixed(2),
        });
      });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.resolvedBills.length !== nextProps.resolvedBills.length) {
      this.setState({ bills: nextProps.resolvedBills });
    }
  }

  render() {
    const { t, currency, equipment } = this.props;
    const { bills, pending } = this.state;
    const items = this.getItemsData(bills);
    return (
      <Page spinning={!firebase.auth().currentUser || equipment.loading || pending}>
        <PageHeader />
        <Content>
          <TopBar data-tour="component-tour-2">
            <h3 className="storage-page-title">
              {t('layout.items')}: {items.length}
            </h3>
            <Button
              className="no-print mobile-bottom-margin mobile-right-margin"
              size="large"
              onClick={() => printPage()}
              type="secondary"
            >{t('control.print')}
            </Button>
            <RangePicker shouldCheckPermission callback={this.updateRange} />
          </TopBar>
          <ItemsTable showSize t={t} data={items} currency={currency} />
        </Content>
      </Page>
    );
  }
}

export default withTranslation()(Items);
