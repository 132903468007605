import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { children, className = '' } = this.props;
    return (
      <div className={`header ${className}`}>
        {children}
      </div>
    );
  }
}

export default Header;

Header.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};
