import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { firebase } from '../../../config';
import {
  PageHeader, Content, Page, Column, Row,
} from '../../../components';
import { SelectItemForSale, SelectCategory } from '../../../common';
import { showNetworkError } from '../../../redux/message/actions';
import { isOnline } from '../../../persistance';
import './styles.scss';

class LoyaltyExclude extends Component {
  constructor(props) {
    super(props);
    const { loyalty } = props;
    this.state = {
      pending: false,
      showExcludeItem: false,
      showExcludeCategories: false,
      excludedItems: [],
      excludedCategories: [],
      ...loyalty,
    };
  }

  submit = () => {
    if (!isOnline()) return showNetworkError();
    const { loyalty, actions: { editLoyalty } } = this.props;
    const { excludedItems, excludedCategories } = this.state;

    this.setState({ pending: true });
    editLoyalty({
      ...loyalty, excludedCategories, excludedItems,
    }).then(() => {
      this.setState({ pending: false });
    });
  }

  removeCategoryFromExclude = id => this.setState(state => ({
    excludedCategories: state.excludedCategories.filter(el => el !== id),
  }))

  removeItemFromExclude = id => this.setState(state => ({
    excludedItems: state.excludedItems.filter(el => el !== id),
  }))

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { loyalty } = nextProps;
    if (loyalty !== this.props.loyalty) {
      this.setState({ ...loyalty });
    }
  }

  render() {
    const {
      t, menu: {
        categories, products, items, addons,
      },
    } = this.props;
    const {
      excludedItems, excludedCategories, pending, showExcludeItem, showExcludeCategories,
    } = this.state;
    const categoriesColumns = [
      {
        title: '',
        dataIndex: 'name',
      },
      {
        title: '',
        width: '30px',
        render: (text, record) => (
          <Row width="100%" justifyContent="center">
            <Button
              onClick={() => this.removeCategoryFromExclude(record.id)}
              type="link"
              icon={<DeleteOutlined />}
            />
          </Row>
        ),
      },
    ];
    const itemsColumns = [
      {
        title: '',
        dataIndex: 'name',
      },
      {
        title: '',
        width: '30px',
        render: (text, record) => (
          <Row width="100%" justifyContent="center">
            <Button
              onClick={() => this.removeItemFromExclude(record.id)}
              type="link"
              icon={<DeleteOutlined />}
            />
          </Row>
        ),
      },
    ];
    return (
      <Page spinning={!firebase.auth().currentUser && false}>
        <PageHeader />
        <Content>
          <Column className="default-card" width="100%" margin="0 0 20px">
            <Row width="100%" data-tour="loyalty-exclude-categories-tour-2" margin="0 0 10px">
              <Column margin="0 10px 0 0" width="auto">
                <h2 className="loyalty-title">{t('marketing.loyalty.excludedCategories')}</h2>
                <p className="loyalty-description">
                  {t('marketing.loyalty.excludedCategoriesDescription')}
                </p>
              </Column>
              <Button
                className="add-button"
                size="large"
                onClick={() => {
                  if (!isOnline()) return showNetworkError();
                  this.setState({ showExcludeCategories: true });
                }}
              >{t('control.add')}
              </Button>
            </Row>
            <Table
              columns={categoriesColumns}
              pagination={false}
              dataSource={(categories || []).filter(el => excludedCategories.includes(el.id)).map(el => ({
                ...el, key: el.id,
              }))}
              style={{ width: '100%' }}
            />
          </Column>
          <Column className="default-card" width="100%">
            <Row width="100%" data-tour="loyalty-exclude-categories-tour-2" margin="0 0 10px">
              <Column margin="0 10px 0 0" width="auto">
                <h2 className="loyalty-title">{t('marketing.loyalty.excludedItems')}</h2>
                <p className="loyalty-description">
                  {t('marketing.loyalty.excludedItemsDescription')}
                </p>
              </Column>
              <Button
                className="add-button"
                size="large"
                onClick={() => {
                  if (!isOnline()) return showNetworkError();
                  this.setState({ showExcludeItem: true });
                }}
              >{t('control.add')}
              </Button>
            </Row>
            <Table
              columns={itemsColumns}
              pagination={false}
              dataSource={[...products, ...items, ...addons].filter(el => excludedItems.includes(el.id)).map(el => ({
                ...el, key: el.id,
              }))}
              style={{ width: '100%' }}
            />
          </Column>
          <Button
            loading={pending}
            type="primary"
            size="large"
            style={{ margin: '20px auto 20px 0' }}
            className="settings-save"
            onClick={this.submit}
          >{t('control.save')}
          </Button>
        </Content>
        {showExcludeItem && (
          <SelectItemForSale
            selectedItems={excludedItems}
            callback={data => this.setState({ excludedItems: data, showExcludeItem: false })}
            close={() => this.setState({ showExcludeItem: false })}
            visible
          />
        )}
        <SelectCategory
          excludeList={excludedCategories}
          callback={data => this.setState(state => ({
            excludedCategories: state.excludedCategories.concat(data), showExcludeCategories: false,
          }))}
          close={() => this.setState({ showExcludeCategories: false })}
          visible={showExcludeCategories}
        />
      </Page>
    );
  }
}

export default withTranslation()(LoyaltyExclude);
