import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Select } from 'antd';
import { withTranslation } from 'react-i18next';

const { Option } = Select;

class SupplierDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChange = (id) => {
    const { suppliers, callback } = this.props;
    callback(suppliers.find(el => el.id === id)?.name);
  }

  render() {
    const { t, suppliers, defaultValue = null } = this.props;
    return (
      <Select
        showSearch
        defaultValue={defaultValue}
        style={{ width: '100%', maxWidth: '300px' }}
        placeholder={t('supply.supplier')}
        optionFilterProp="children"
        onChange={this.onChange}
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {suppliers.map(el => (
          <Option value={el.id} key={el.id}>{el.name}</Option>
        ))}
      </Select>
    );
  }
}

function mapStateToProps(state) {
  return {
    suppliers: state.storage.suppliers,
  };
}
export default connect(mapStateToProps, null)(withTranslation()(SupplierDropdown));
