/* eslint-disable */

export default {
  reactour: {
    popover: {
      title: 'Потрібна допомога?',
    },
    firstView: {
      title: 'З чого почати?',
      step1: {
        title: 'Вітаємо у Shoptoria!',
        text: 'Ми підготували <b>3 хвилинне</b> навчання для Вас.<br /> Ви можете пропустити його і проглянути пізніше, обравши його на сторінці <b>Налаштування</b>.',
        buttonText: 'Далі',
        skipText: 'Пропустити',
      },
      step2: {
        title: 'Термінал',
        text: 'Для доступа до <b>Терміналу</b> кожен ваш співробітник має персональний <b>Пароль доступу до терміналу</b>.<br /> Давайте дізнаємось Ваш пароль, перейшовши у вкладку <b>Співробітники</b>.',
        buttonText: 'Далі',
      },
      step3: {
        title: 'Співробітники',
        text: 'Ви, як і будь-який ваш співробітник, маєте персональний <b>Пароль доступа до терміналу</b>.<br /> Як бачите, ваш пароль <b>0000</b>.',
        buttonText: 'Далі',
      },
      step4: {
        title: 'Доступ до терміналу',
        text: 'Тепер Ви знаєте ваш <b>Пароль доступу</b> - <b>0000</b>.<br /> Ви можете змінити его в будь-який час у вкладці <b>Співробітники</b>.',
        buttonText: 'Далі',
      },
      step5: {
        title: 'Меню',
        text: 'Здається, ми готові починати!<br />Давайте переглянемо Ваше <b>Меню</b>.<br />',
        buttonText: 'Далі',
      },
      step6: {
        title: 'Вкладка Меню',
        text: 'Ми підготували декілька товарів, базуючись на типі Вашого закладу, так що Ви можете спробувати роботу в <b>Терміналі</b> без витрат часу на підготовку.',
        buttonText: 'Далі',
      },
      step7: {
        title: 'Потрібна допомога?',
        text: 'Якщо ви не знаєте, що робити на сторінці, натисніть на іконку <b>питання</b>!',
        buttonText: 'Зрозуміло',
      },
      step8: {
        title: 'Завершення',
        text: 'На цьому у нас все!<br /> Створюйте нові <b>Продукти</b>, поповнюйте <b>Склад</b>, переглядайте <b>Статистику</b>, управляйте вашими <b>Фінансами</b> - робіть все це разом з <b>Shoptoria</b>.',
        buttonText: 'Завершити',
      },
    },
    menu: {
      title: 'Що це за сторінка?',
      step1: {
        title: 'Меню',
        text: 'У вкладці <b>Меню</b> Ви налаштовуєте меню вашого закладу.<br /> Ви можете створювати продукти, товари, модифікатори та додавати власні категорії товарів.',
        buttonText: 'Далі',
      },
      step2: {
        title: 'Управління категоріями',
        text: 'У вкладці <b>Управління категоріями</b> Ви можете додавати та видаляти власні категорії товарів.<br /><br /> Детальніше у <b>"Що таке управління категорями?"</b>',
        buttonText: 'Далі',
      },
      step21: {
        title: 'Створити рецепт',
        text: 'У вікні <b>Створити рецепт</b> Ви обираєте, який тип рецепту Ви хочете створити.<br /><br /> Детальніше у <b>"Чим відрізняються рецепти?"</b>',
        buttonText: 'Далі',
      },
      step3: {
        title: 'Створення продукту',
        text: 'У вкладці <b>Створення продукту</b> Ви можете створити власний продукт з рецепту.<br /><br /> Детальніше у <b>"Як створити продукт?"</b>',
        buttonText: 'Далі',
      },
      step4: {
        title: 'Створення товару',
        text: 'У вкладці <b>Створення товару</b> Ви можете створити товар для продажу.<br /><br /> Детальніше у <b>"Як створити товар?"</b>',
        buttonText: 'Далі',
      },
      step5: {
        title: 'Створення модифікатору',
        text: 'У вкладці <b>Створення модифікатору</b> Ви можете створити модифікатор для тех. карт.<br /><br /> Детальніше у <b>"Як створити модифікатор?"</b>',
        buttonText: 'Далі',
      },
      step6: {
        title: 'Вкладки меню',
        text: 'У поданих вкладках Ви бачите продукти, товари та модифікатори для тех. карт, розділенні за типами та категоріями.<br />Ви можете додати власні категорії за допомогою <b>Управління категоріями</b>.',
        buttonText: 'Завершити',
      },
    },
    equipment: {
      title: 'Що це за сторінка?',
      step1: {
        title: 'Матеріали',
        text: 'У вкладці <b>Матеріали</b> Ви управляєте витратними матеріалами, які використовуєте.<br /> Ви можете додавати витратні матеріали до своїх рецептів або товарів.<br /><br /> Матеріали також відображені у вкладкі <b>Склад</b>, можуть бути додані в поставкі, списані і доступні при інвентаризації.',
        buttonText: 'Далі',
      },
      step2: {
        title: 'Витратні матеріали',
        text: 'Тут Ви можете створити, редагувати та видалити ваші витратні матеріали.<br /><br /> Детальніше у <b>"Що таке витратні матеріали?"</b>',
        buttonText: 'Завершити',
      },
    },
    component: {
      title: 'Що це за сторінка?',
      step1: {
        title: 'Компоненти',
        text: 'У вкладці <b>Компоненти</b> Ви управляєте компонентами, які використовуєте.<br /> Ви можете додавати компоненти до своїх рецептів.<br /><br /> Компоненти також відображені у вкладкі <b>Склад</b>, можуть бути додані в поставкі, списані і доступні при інвентаризації.',
        buttonText: 'Далі',
      },
      step2: {
        title: 'Компоненти',
        text: 'Тут Ви можете створити, редагувати та видалити ваші компоненти.<br /><br /> Детальніше у <b>"Що таке компоненти?"</b>',
        buttonText: 'Завершити',
      },
    },
    supply: {
      title: 'Що це за сторінка?',
      step1: {
        title: 'Вкладка Поставки',
        text: 'У вкладці <b>Поставки</b> Ви можете додати поставку на ваш <b>Склад</b>.<br /><br /> Товари, компоненти та витратні матеріали, які ви додаєте в поставку збільшать свою кількість на складі.<br />',
        buttonText: 'Далі',
      },
      step2: {
        title: 'Поставки',
        text: 'Тут Ви можете створити, редагувати та видалити ваші поставки.<br /><br /> Детальніше у <b>"Що таке поставки?"</b>',
        buttonText: 'Завершити',
      },
    },
    waste: {
      title: 'Що це за сторінка?',
      step1: {
        title: 'Вкладка Списання',
        text: 'У вкладці <b>Списання</b> Ви можете додати списання на ваш <b>Склад</b>.<br /><br /> Товари, компоненти та витратні матеріали, які ви додаєте в списання зменшать свою кількість на складі.<br />',
        buttonText: 'Далі',
      },
      step2: {
        title: 'Списання',
        text: 'Тут Ви можете створити, редагувати та видалити ваші списання.<br /><br /> Детальніше у <b>"Що таке списання?"</b>',
        buttonText: 'Завершити',
      },
    },
    inventory: {
      title: 'Що це за сторінка?',
      step1: {
        title: 'Вкладка Інвентаризації',
        text: 'У вкладці <b>Інвентаризації</b> Ви можете провести інвентаризацію вашого <b>Складу</b>.<br /><br /> Товари, компоненти та витратні матеріали, які ви додаєте в інвентаризацію отримають нову кількість на складі.<br />',
        buttonText: 'Далі',
      },
      step2: {
        title: 'Інвентаризації',
        text: 'Тут Ви можете створити, редагувати та видалити ваші інвентаризації.<br /><br /> Детальніше у <b>"Що таке інвентаризація?"</b>',
        buttonText: 'Завершити',
      },
    },
    consumption: {
      title: 'Що це за сторінка?',
      step1: {
        title: 'Вкладка Витрати',
        text: 'У вкладці <b>Витрати</b> Ви можете побачити, скільки ви витрачаєте матеріалів.<br />',
        buttonText: 'Далі',
      },
      step2: {
        title: 'Часовий проміжок',
        text: 'Ви можете обрати будь-який часовий проміжок.<br/><br /> Товари, компоненти та витратні матеріали, які ви витрачаєте підраховуються зі створених вами чеків.',
        buttonText: 'Завершити',
      },
    },
    financialAccounts: {
      title: 'Що це за сторінка?',
      step1: {
        title: 'Вкладка Рахунки',
        text: 'У вкладці <b>Рахунки</b> Ви можете створити фінансовий рахунок.',
        buttonText: 'Далі',
      },
      step2: {
        title: 'Фінансові рахунки',
        text: 'Тут Ви можете створити, редагувати та видалити ваші фінансові рахунки.<br /><br /> Детальніше у <b>"Що таке фінансові рахунки?"</b>',
        buttonText: 'Завершити',
      },
    },
    financialCategories: {
      title: 'Що це за сторінка?',
      step1: {
        title: 'Вкладка Категорії',
        text: 'У вкладці <b>Категорії</b> Ви можете створити фінансову категорію.<br /><br /> Фінансові категорію необхідні для створення фінансових транзакцій.',
        buttonText: 'Далі',
      },
      step2: {
        title: 'Фінансові категорії',
        text: 'Тут Ви можете створити, редагувати та видалити ваші фінансові категорії.<br /><br /> Детальніше у <b>"Що таке фінансові категорії?"</b>',
        buttonText: 'Завершити',
      },
    },
    financialShifts: {
      title: 'Що це за сторінка?',
      step1: {
        title: 'Вкладка Кассові зміни',
        text: 'У вкладці <b>Кассові зміни</b> Ви можете побачити звіти по вашим кассовим змінам.',
        buttonText: 'Далі',
      },
      step2: {
        title: 'Кассові зміни',
        text: 'Ви можете розвернути таблицю, щоб отримати більше інформації.',
        buttonText: 'Завершити',
      },
    },
    financialTransactions: {
      title: 'Що це за сторінка?',
      step1: {
        title: 'Вкладка Транзакції',
        text: 'У вкладці <b>Транзакції</b> Ви можете створити фінансову транзакцію.<br /><br />Фінансові транзакції дозволяють вам контролювати фінансові потоки Вашого бізнесу.',
        buttonText: 'Далі',
      },
      step2: {
        title: 'Транзакції',
        text: 'Тут Ви можете створити, редагувати та видалити ваші фінансові транзакції.<br /><br /> Детальніше у <b>"Що таке фінансові транзакції?"</b>',
        buttonText: 'Завершити',
      },
    },
    employers: {
      title: 'Що це за сторінка?',
      step1: {
        title: 'Вкладка Співробітники',
        text: 'У вкладці <b>Співробітники</b> Ви управляєте своїми співробітниками.<br /><br /> Одна з ключових речей тут - це <b>Пароль доступу до Терміналу</b>, за допомогою якого співробітник взаємодіє з <b>Терміналом</b>.',
        buttonText: 'Далі',
      },
      step2: {
        title: 'Співробітники',
        text: 'Тут Ви можете створити, редагувати та видалити ваших співробітників.<br /><br /> Детальніше у <b>"Як управляти співробітниками?"</b>',
        buttonText: 'Завершити',
      },
    },
    jobs: {
      title: 'Що це за сторінка?',
      step1: {
        title: 'Вкладка Посади',
        text: 'У вкладці <b>Посади</b> Ви управляєте посадами вашого бізнесу.<br /><br /> Посади допоможуть вам при розрахунку заробітньої платні ваших співробітників.',
        buttonText: 'Далі',
      },
      step2: {
        title: 'Посади',
        text: 'Тут Ви можете створити, редагувати та видалити посади.<br /><br /> Детальніше у <b>"Як управляти посадами?"</b>',
        buttonText: 'Завершити',
      },
    },
    subscription: {
      title: 'Що це за сторінка?',
      step1: {
        title: 'Оплата підписки',
        text: 'У вкладці <b>Оплата підписки</b> Ви управляєте вашою підпискою.<br /><br /> Тут представлена інформація про ваш тарифний план, історію платежів і можливість оплатити 1 місяць підписки.<br />',
        buttonText: 'Далі',
      },
      step2: {
        title: 'Акаунт',
        text: 'Тут ви бачите дані <b>Вашого акаунту</b>: актуальний тарифний план, ціна підписки, останній день підписки.<br />',
        buttonText: 'Далі',
      },
      step3: {
        title: 'Платежі',
        text: 'Тут ви бачите історію ваших платежів.<br /><br>Ви можете оплатити 1 місяць підписки за допомогою кнопки.',
        buttonText: 'Завершити',
      },
    },
  },
};
