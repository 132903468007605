import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Input, Select } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { firebase } from '../../../config';
import {
  PageHeader, Content, TopBar, Page, Column, Row,
} from '../../../components';
import { NumberInput } from '../../../common';
import { validateNumberInput } from '../../../utils/helpers';
import './styles.scss';
import { notificationTypes, showNetworkError } from '../../../redux/message/actions';
import { isOnline } from '../../../persistance';

const TransitionCondition = ({
  groups, ranges, callback, t, currency,
}) => (
  <Column className="loyalty-ranges">
    <h3 style={{ margin: '10px 0 5px' }}>{t('marketing.loyalty.transitCondition')}</h3>
    {(ranges).map((range, i) => (
      <Row className="loyalty-item mobile-column loyalty-condition" margin="0 0 10px" key={range.id}>
        <b>#{i + 1}</b>
        <NumberInput
          onKeyUp={validateNumberInput}
          className="loyalty-condition-input"
          type="tel"
          value={range.amount}
          placeholder="0"
          onChange={e => callback(ranges.map(el => (el.id !== range.id
            ? el : { ...range, amount: parseInt(e.target.value, 10) }
          )))}
          addonAfter={currency}
        />
        <Select
          value={range.group}
          placeholder={t('marketing.groups.selectGroup')}
          className="loyalty-condition-input"
          onChange={group => callback(ranges.map(el => (el.id !== range.id ? el : { ...range, group })))}
        >
          {groups.map(el => (
            <Select.Option key={el.id} value={el.id}>{el.name} ({el.count}%)</Select.Option>
          ))}
        </Select>
        <Button
          className="loyalty-condition-button"
          size="large"
          type="link"
          icon={<DeleteOutlined />}
          onClick={() => callback(ranges.filter(el => el.id !== range.id))}
        />
      </Row>
    ))}
    <Button
      type="link"
      onClick={() => callback([...ranges, { amount: '', group: null, id: Date.now() }])}
    >
      {t('control.add')}
    </Button>
  </Column>
);

class LoyaltyProgram extends Component {
  constructor(props) {
    super(props);
    const { loyalty } = props;

    this.state = {
      pending: false,
      ...loyalty,
      bonusRanges: loyalty.bonusRanges.length === 0
        ? [{ amount: '', group: null, id: Date.now() }] : loyalty.bonusRanges,
      saleRanges: loyalty.saleRanges.length === 0
        ? [{ amount: '', group: null, id: Date.now() }] : loyalty.saleRanges,
    };
  }

  submit = () => {
    if (!isOnline()) return showNetworkError();
    const { t, loyalty, actions: { showMessage, editLoyalty } } = this.props;
    const {
      inviteBonus, maxBonusPayment, bonusTransition, saleTransition, bonusRanges, saleRanges,
    } = this.state;
    if (inviteBonus.toString().trim() === '') {
      showMessage({ text: t('validation.emptyInviteBonus'), type: notificationTypes.ERROR });
      return;
    }
    if (maxBonusPayment.toString().trim() === '') {
      showMessage({ text: t('validation.emptyMaxBonus'), type: notificationTypes.ERROR });
      return;
    }
    let isBonusRangesValid = true;
    let isSaleRangesValid = true;
    if (bonusTransition === 1) {
      isBonusRangesValid = bonusRanges.reduce((res, range, i) => {
        if (range.group === null) {
          showMessage({
            type: notificationTypes.ERROR,
            text: `${t('validation.bonusRangeNoGroup')} #${i + 1}`,
          });
          return false;
        }
        if (range.amount === '') {
          showMessage({
            type: notificationTypes.ERROR,
            text: `${t('validation.bonusRangeNoAmount')} #${i + 1}`,
          });
          return false;
        }
        return res;
      }, true);
    }
    if (saleTransition === 1) {
      isSaleRangesValid = saleRanges.reduce((res, range, i) => {
        if (range.group === null) {
          showMessage({
            type: notificationTypes.ERROR,
            text: `${t('validation.saleRangeNoGroup')} #${i + 1}`,
          });
          return false;
        }
        if (range.amount === '') {
          showMessage({
            type: notificationTypes.ERROR,
            text: `${t('validation.saleRangeNoAmount')} #${i + 1}`,
          });
          return false;
        }
        return res;
      }, true);
    }
    if (!isBonusRangesValid || !isSaleRangesValid) return;
    this.setState({ pending: true });
    editLoyalty({
      ...loyalty,
      inviteBonus,
      maxBonusPayment,
      bonusTransition: !!bonusTransition,
      saleTransition: !!saleTransition,
      bonusRanges,
      saleRanges,
    }).then(() => {
      this.setState({ pending: false });
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { loyalty } = nextProps;
    if (loyalty !== this.props.loyalty) {
      this.setState({
        ...loyalty,
        bonusRanges: loyalty.bonusRanges.length === 0
          ? [{ amount: '', group: null, id: Date.now() }] : loyalty.bonusRanges,
        saleRanges: loyalty.saleRanges.length === 0
          ? [{ amount: '', group: null, id: Date.now() }] : loyalty.saleRanges,
      });
    }
  }

  render() {
    const {
      t, groups, groupsLoaded, currency,
    } = this.props;
    const {
      pending, inviteBonus, maxBonusPayment, bonusTransition, saleTransition, bonusRanges, saleRanges,
    } = this.state;
    return (
      <Page spinning={!firebase.auth().currentUser || !groupsLoaded}>
        <PageHeader />
        <Content>
          <TopBar data-tour="loyalty-tour-2">
            <h1 className="storage-page-title">{t('layout.loyalty')}</h1>
          </TopBar>
          <Column margin="20px 0 0" width="100%" className="default-card">
            <h2 className="loyalty-title">{t('marketing.groups.bonus')}</h2>
            <p className="loyalty-description">
              {t('marketing.loyalty.bonusDescription1')}<br />
              {t('marketing.loyalty.bonusDescription2')}
            </p>
            <Column width="100%" className="loyalty-wrapper">
              <h3 style={{ margin: '10px 0 5px' }}>{t('marketing.loyalty.maxBonus')}</h3>
              <Input
                className="loyalty-input"
                placeholder="%"
                value={maxBonusPayment}
                addonAfter="%"
                onChange={e => this.setState({ maxBonusPayment: e.target.value })}
              />
              <h3 style={{ margin: '10px 0 5px' }}>{t('marketing.loyalty.inviteBonus')}</h3>
              <Input
                className="loyalty-input"
                placeholder="0"
                value={inviteBonus}
                addonAfter={currency}
                onChange={e => this.setState({ inviteBonus: e.target.value })}
              />
              <h3 style={{ margin: '10px 0 5px' }}>{t('marketing.loyalty.groupTransit')}</h3>
              <Select
                value={bonusTransition ? 1 : 0}
                placeholder={t('global.select')}
                className="loyalty-input"
                onChange={bonusTransition => this.setState({
                  bonusTransition, bonusRanges: bonusTransition ? bonusRanges || [] : [],
                })}
              >
                <Select.Option key="yes" value={1}>{t('marketing.loyalty.yes')}</Select.Option>
                <Select.Option key="no" value={0}>{t('marketing.loyalty.no')}</Select.Option>
              </Select>
              {(bonusTransition === 1 || bonusTransition === true) && (
              <TransitionCondition
                t={t}
                currency={currency}
                groups={groups.filter(el => el.type === 'bonus')}
                ranges={bonusRanges || []}
                callback={range => this.setState({ bonusRanges: range })}
              />
              )}
            </Column>
          </Column>
          <Column margin="20px 0 0" width="100%" className="default-card">
            <h2 className="loyalty-title">{t('marketing.groups.sale')}</h2>
            <p className="loyalty-description">
              {t('marketing.loyalty.saleDescription1')}<br />
              {t('marketing.loyalty.saleDescription2')}
            </p>
            <Column width="100%" className="loyalty-wrapper">
              <h3 style={{ margin: '10px 0 5px' }}>{t('marketing.loyalty.groupTransit')}</h3>
              <Select
                value={saleTransition ? 1 : 0}
                placeholder={t('global.select')}
                className="loyalty-input"
                onChange={saleTransition => this.setState({
                  saleTransition, saleRanges: saleTransition ? saleRanges || [] : [],
                })}
              >
                <Select.Option key="yes" value={1}>{t('marketing.loyalty.yes')}</Select.Option>
                <Select.Option key="no" value={0}>{t('marketing.loyalty.no')}</Select.Option>
              </Select>
              {(saleTransition === 1 || saleTransition === true) && (
              <TransitionCondition
                t={t}
                currency={currency}
                groups={groups.filter(el => el.type === 'sale')}
                ranges={saleRanges || []}
                callback={range => this.setState({ saleRanges: range })}
              />
              )}
            </Column>
          </Column>
          <Button
            loading={pending}
            type="primary"
            size="large"
            style={{ margin: '20px auto 20px 0' }}
            className="settings-save"
            onClick={this.submit}
          >{t('control.save')}
          </Button>
        </Content>
      </Page>
    );
  }
}

export default withTranslation()(LoyaltyProgram);
