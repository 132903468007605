import React, { Component, lazy, Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { Column } from '../../../components';
import { Spinner } from '../../Landing/components';
import Header from '../../Landing/Header';
import DocsTab from '../DocsTab';

const LazyFooter = lazy(() => import('../../Landing/Footer/index'));
const LazyContactUs = lazy(() => import('../ContactUsRow/index'));
const LazyNavigation = lazy(() => import('../Navigation/index'));

class StoreDoc extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <div className="landing docs" id="top">
        <Header />
        <Column width="100%" className="landing-wrapper" margin="0 auto">
          <DocsTab />
          <div className="docs-hero store">
            <h1>{t('landing.advantages.store.title')}</h1>
            <p>{t('landing.docs.menu.hero')}</p>
            <img src="/assets/docs/docs-store-hero.png" alt="menu hero img" />
          </div>
          <div className="docs-content">
            <h2>{t('landing.advantages.store.control')}</h2>
            <div className="docs-content-wrapper">
              <div className="docs-content-text">
                <p>{t('landing.docs.store.slide1Text1')}</p>
                <p>{t('landing.docs.store.slide1Text2')}</p>
              </div>
              <img src="/assets/docs/store-supply.png" alt="store-hero" />
            </div>
          </div>
          <div className="docs-content">
            <h2>{t('landing.advantages.store.onStore')}</h2>
            <div className="docs-content-wrapper reverse">
              <img src="/assets/docs/store-waste.png" alt="store-hero" />
              <div className="docs-content-text">
                <p>{t('landing.docs.store.slide2Text1')}</p>
                <p>{t('landing.docs.store.slide2Text2')}</p>
              </div>
            </div>
          </div>
          <div className="docs-content">
            <h2>{t('landing.advantages.store.inventory')}</h2>
            <div className="docs-content-wrapper">
              <div className="docs-content-text">
                <p>{t('landing.docs.store.slide3Text1')}</p>
              </div>
              <img src="/assets/docs/store-inventory.png" alt="store-hero" />
            </div>
          </div>
          <div className="docs-content">
            <h2>{t('landing.advantages.store.waste')}</h2>
            <div className="docs-content-wrapper reverse">
              <img src="/assets/docs/store-consume.png" alt="store-hero" />
              <div className="docs-content-text">
                <p>{t('landing.docs.store.slide4Text1')}</p>
                <p>{t('landing.docs.store.slide4Text2')}</p>
              </div>
            </div>
          </div>
        </Column>
        <Suspense fallback={<Spinner />}>
          <LazyContactUs />
        </Suspense>
        <Column width="100%" className="landing-wrapper" margin="0 auto">
          <Suspense fallback={<Spinner />}>
            <LazyNavigation prevKey="menu" nextKey="statistic" />
          </Suspense>
        </Column>
        <Suspense fallback={<Spinner />}>
          <LazyFooter />
        </Suspense>
      </div>
    );
  }
}

export default withTranslation()(StoreDoc);
