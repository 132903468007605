import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { showMessage } from '../../../redux/message/actions';
import Accounts from './Accounts';
import { removeFinancialAccount } from '../../../redux/financial/actions';

function mapStateToProps(state) {
  return {
    financial: state.financial,
    currency: state.business.currency,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        removeFinancialAccount,
      },
      dispatch,
    ),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Accounts));
