import React, { Component, lazy, Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import Header from '../Landing/Header';
import { JoinUs, Spinner } from '../Landing/components';
import { Column } from '../../components';
import './style.scss';

const LazyFooter = lazy(() => import('../Landing/Footer/index'));
const LazyContactUs = lazy(() => import('../Landing/ContactUs/index'));

class InstallHelp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <div className="landing" id="top">
        <Header />
        <Column width="100%" className="landing-wrapper faq" margin="0 auto">
          <Column className="install-header" width="100%" alignItems="center" justifyContent="flex-start">
            <h2>{t('landing.install.title')}</h2>
            <p>{t('landing.install.subtitle')}</p>
            <h3>{t('landing.install.subtitle2')}</h3>
          </Column>

          <h3 className="install-step-number">{t('landing.install.step')} I</h3>
          <div className="install-step">
            <h4 className="install-step-title">{t('landing.install.step1')}</h4>
            <img src="/assets/install/step1.jpg" alt="install step 1" />
            <img src="/assets/install/step1_2.jpg" alt="install step 1_2" />
            <h4 className="install-step-title">{t('landing.install.step1_1')}</h4>
            <img src="/assets/install/step1_3.png" alt="install step 1_3" />
            <img src="/assets/install/step1_4.png" alt="install step 1_4" />
          </div>

          <h3 className="install-step-number">{t('landing.install.step')} II</h3>
          <div className="install-step white">
            <h4 className="install-step-title">{t('landing.install.step2')}</h4>
            <img src="/assets/install/step2.jpg" alt="install step 2" />
          </div>

          <h3 className="install-step-number">{t('landing.install.step')} III</h3>
          <div className="install-step">
            <h4 className="install-step-title">{t('landing.install.step3')}</h4>
            <img src="/assets/install/step3.jpg" alt="install step 3" />
          </div>

          <Column className="install-footer" width="100%" alignItems="center" justifyContent="flex-start">
            <h3>{t('landing.install.finish')}</h3>
            <h4>{t('landing.install.finish2')}</h4>
          </Column>
          <Suspense fallback={<Spinner />}>
            <LazyContactUs />
          </Suspense>
          <JoinUs t={t} className="install-join" />
        </Column>
        <Suspense fallback={<Spinner />}>
          <LazyFooter />
        </Suspense>
      </div>
    );
  }
}

export default withTranslation()(InstallHelp);
