import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { Link } from 'react-router-dom';
import {
  AddEquipment, Column, Content, EditEquipment, Page, PageHeader, Row, TopBar,
} from '../../../components';
import { getDefaultImageByType, getEmptyMaterial, getSizeName } from '../../../utils/helpers';
import { deleteModal } from '../../../utils/modals';
import './style.scss';
import { isOnline } from '../../../persistance';
import { showNetworkError } from '../../../redux/message/actions';

const sizes = ['S', 'M', 'L', 'XL', 'XXL'];

class Kits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
    };
  }

  selectItem = (record) => {
    if (!isOnline()) return showNetworkError();
    const { equipment: { items } } = this.props;
    const selectedItem = items.find(el => el.name === record.name);
    this.setState({ selectedItem });
    this.editEquipmentModal.show();
  };

  removeConsumable = (record) => {
    const { equipment: { items }, actions: { removeEquipment } } = this.props;
    const item = items.find(el => el.name === record.name);
    removeEquipment(item.id);
  };

  render() {
    const {
      t, equipment, equipment: { items, idMap }, actions: {
        createEquipment, editEquipment,
      },
    } = this.props;

    const { selectedItem } = this.state;
    const itemsColumns = [
      {
        title: t('global.name'), dataIndex: 'name', width: 120, className: 'no-break',
      },
      ...sizes.map(size => ({
        title: getSizeName(size),
        className: 'table-header-center',
        dataIndex: size,
        render: (text, record) => {
          const { sizes } = record;
          const material = idMap[sizes[size]] || getEmptyMaterial(t);
          return (
            <Column width="100%" justifyContent="flex-start" alignItems="center">
              <img
                style={{ margin: '0 0 5px' }}
                className="default-img default-img-small"
                src={material.photo || getDefaultImageByType('consumable')}
                alt={material.name}
              />
              {material.id === 0 ? (
                <p>{material.name}</p>
              ) : (
                <Link to={{ pathname: '/app/equipment/consumables', state: { equipmentId: material.id } }}>
                  {material.name}
                </Link>
              )}
            </Column>
          );
        },
      })),
      {
        title: '',
        width: '30px',
        render: (text, record) => (
          <Row width="100%" justifyContent="center">
            <Button onClick={() => this.selectItem(record)} type="link" icon={<EditOutlined />} />
          </Row>
        ),
      }, {
        title: '',
        width: '30px',
        render: (text, record) => (
          <Row width="100%" justifyContent="center">
            <Button
              onClick={() => deleteModal(() => this.removeConsumable(record))}
              type="link"
              icon={<DeleteOutlined />}
            />
          </Row>
        ),
      },
    ];

    return (
      <Page>
        <PageHeader />
        <Content>
          <TopBar data-tour="equipment-tour-2">
            <h3 className="storage-page-title">
              {t('layout.kits')}: {equipment.items.length}
            </h3>
            <Button
              className="add-button"
              size="large"
              onClick={() => {
                if (!isOnline()) return showNetworkError();
                this.addEquipmentModal.show();
              }}
            >
              {t('control.add')}
            </Button>
          </TopBar>
          <Table
            bordered
            pagination={false}
            size="default"
            className="storage-page-table"
            columns={itemsColumns}
            dataSource={items.map((el, index) => ({ ...el, key: index }))}
          />
          <AddEquipment
            existedItems={items}
            sizes={sizes}
            withPredefined={false}
            callback={createEquipment}
            ref={(ref) => { this.addEquipmentModal = ref; }}
          />
          <EditEquipment
            existedItems={items}
            sizes={sizes}
            item={selectedItem}
            callback={editEquipment}
            ref={(ref) => { this.editEquipmentModal = ref; }}
          />
        </Content>
      </Page>
    );
  }
}

export default withTranslation()(Kits);
