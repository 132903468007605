import {
  GET_TRANSACTIONS, ADD_TRANSACTION, REMOVE_TRANSACTION, GET_FINANCIAL_CATEGORIES, EDIT_FINANCIAL_CATEGORY,
  ADD_FINANCIAL_CATEGORY, REMOVE_FINANCIAL_CATEGORY, GET_FINANCIAL_ACCOUNTS, EDIT_FINANCIAL_ACCOUNT,
  ADD_FINANCIAL_ACCOUNT, REMOVE_FINANCIAL_ACCOUNT, EDIT_TRANSACTION, GET_TRANSACTIONS_DELTA,
} from './actions';

const initialState = {
  transactions: [],
  categories: [],
  accounts: [],
  transactionsLoaded: false,
};

function financialReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload,
        transactionsLoaded: true,
      };
    case GET_TRANSACTIONS_DELTA:
      const existTransactionList = state.transactions.map(el => el.id);
      return {
        ...state,
        transactions: [...state.transactions, ...action.payload.filter(el => !existTransactionList.includes(el.id))],
      };
    case EDIT_TRANSACTION:
      return {
        ...state,
        transactions: [...state.transactions.filter(el => el.id !== action.payload.id), action.payload],
      };
    case ADD_TRANSACTION:
      return {
        ...state,
        transactions: [...state.transactions, action.payload],
      };
    case REMOVE_TRANSACTION:
      return {
        ...state,
        transactions: state.transactions.filter(el => el.id !== action.payload),
      };
    case GET_FINANCIAL_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
        categoriesLoaded: true,
      };
    case EDIT_FINANCIAL_CATEGORY:
      return {
        ...state,
        categories: state.categories.map(el => (el.id === action.payload.id ? action.payload : el)),
      };
    case ADD_FINANCIAL_CATEGORY:
      return {
        ...state,
        categories: [...state.categories, action.payload],
      };
    case REMOVE_FINANCIAL_CATEGORY:
      return {
        ...state,
        categories: state.categories.filter(el => el.id !== action.payload),
      };
    case GET_FINANCIAL_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload,
      };
    case EDIT_FINANCIAL_ACCOUNT:
      return {
        ...state,
        accounts: state.accounts.map(el => (el.id === action.payload.id ? action.payload : el)),
      };
    case ADD_FINANCIAL_ACCOUNT:
      return {
        ...state,
        accounts: [...state.accounts, action.payload],
      };
    case REMOVE_FINANCIAL_ACCOUNT:
      return {
        ...state,
        accounts: state.accounts.filter(el => el.id !== action.payload),
      };
    case 'CLEAN_REDUCERS': {
      return initialState;
    }
    default: return state;
  }
}

export default financialReducer;
