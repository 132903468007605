import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StarFilled } from '@ant-design/icons';
import { Row } from '../index';
import { getDefaultImageByType } from '../../utils/helpers';
import './style.scss';

class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      item, withFavourites = true, withCategory = true, callback = () => {}, className = '',
      toggleFavourites = () => {},
    } = this.props;
    const {
      name, category, photo, inFavourites, type,
    } = item;

    return (
      <div
        className={`card ${className} ${withCategory ? '' : 'short'}`}
        onClick={callback}
      >
        <Row width="100%" style={{ padding: '5px 5px 5px 10px' }}>
          <h3 style={{ width: withFavourites ? 'calc(100% - 24px)' : '100%' }}>{name}</h3>
          {withFavourites && type !== 'addon' && (
            <StarFilled
              onClick={(e) => {
                e.stopPropagation();
                toggleFavourites();
              }}
              style={{ color: inFavourites ? '#fadb14' : '#e8e8e8', fontSize: '24px' }}
            />
          )}
        </Row>
        <img
          src={photo || getDefaultImageByType(type)}
          style={{ opacity: photo ? 1 : 0.5 }}
          alt={name}
          className="card-img"
        />
        {withCategory && <div className="card-category"><p>{category !== 'All' ? category : ''}</p></div>}
      </div>
    );
  }
}

export default Card;

Card.propTypes = {
  callback: PropTypes.func,
  className: PropTypes.string,
  withFavourites: PropTypes.bool,
};
