import { del } from 'idb-keyval';
import {
  firebase, idb, idbUpdates, idbInfo,
} from '../../config';
import axiosInstance from '../../api';
import { getConsumables, getEquipments } from '../equipment/actions';
import { getResolvedBills } from '../statistic/actions';
import {
  getClients, getGroups, getLoyalty, getSales,
} from '../marketing/actions';
import { getStorage, getSuppliers } from '../storage/actions';
import {
  getFinancialAccounts, getFinancialCategories, getTransactions,
} from '../financial/actions';
import {
  getEmployers, getJobs,
} from '../access/actions';
import { getCashShifts } from '../shift/actions';
import {
  getAddons, getCategories, getComponents, getItems, getProducts,
} from '../menu/actions';
import { initialState as businessInitialState } from '../business/reducer';
import { initialState as communicationInitialState } from '../communication/reducer';
import {
  checkMigration,
  GET_BUSINESS_INFO, getBusinessInfo, getPayments, getPlaceInfo, getPlaceRef, removeAdminAccess,
  SET_ACTIVE_PLACE,
} from '../business/actions';
import { setPlaceRefToDB } from '../business/helpers';
import { GET_COMMUNICATION, getCommunication } from '../communication/actions';
import { getAppInfoRequest, getAppMigrationRequest, getAppUpdatesRequest } from '../../api/db/user';
import { getKeeperSettings } from '../keeper/actions';
import {
  cleanAllPersistedCollection,
  getPersistedCommunication, getPersistedInfo, getPersistedUpdates, isOnline, persistWithKey,
} from '../../persistance';
import { infoMock } from '../../constants/mock';
import { showErrorMessage } from '../message/actions';

export const SET_USER_DATA = 'SET_USER_DATA';
export const CHECK_VERSION = 'CHECK_VERSION';
export const GET_MIGRATION = 'GET_MIGRATION';
export const FINISH_MIGRATION = 'FINISH_MIGRATION';
export const GET_UPDATES = 'GET_UPDATES';
export const SET_UPDATES = 'SET_UPDATES';

/**
 * Login in firebase
 * @param data
 * @returns {function(...[*]=)}
 */
export function login(data) {
  return (dispatch) => {
    dispatch({ type: SET_USER_DATA, payload: data });
  };
}

/**
 * Get build version from db
 * @returns {function(*=): Promise<unknown>}
 */
export function getInfo() {
  return (dispatch) => {
    if (!isOnline()) {
      return getPersistedInfo()
        .then(data => dispatch({ type: CHECK_VERSION, payload: data || infoMock }));
    }
    return getAppInfoRequest()
      .then((res) => {
        dispatch({ type: CHECK_VERSION, payload: res.data });
        persistWithKey('info', res.data, idbInfo);
      })
      .catch(showErrorMessage);
  };
}

export function getUpdates() {
  return (dispatch) => {
    if (!isOnline()) {
      return getPersistedUpdates().then(data => dispatch({ type: GET_UPDATES, payload: data }));
    }
    return getAppUpdatesRequest()
      .then((res) => {
        dispatch({ type: GET_UPDATES, payload: res.data });
        persistWithKey('updates', res.data, idbUpdates);
      })
      .catch(showErrorMessage);
  };
}

export function getMigration(persisted = false) {
  return (dispatch) => {
    if (persisted || !isOnline()) {
      return getPersistedCommunication()
        .then(data => dispatch({ type: GET_MIGRATION, payload: data }));
    }
    return getAppMigrationRequest()
      .then((res) => {
        dispatch({ type: GET_MIGRATION, payload: res.data });

        dispatch(checkMigration(res.data));
      })
      .catch(showErrorMessage);
  };
}

const isKeyEqual = (obj1, obj2, key) => {
  if (!obj2) return false;
  return obj1[key] === obj2[key];
};

/**
 * Get business data, then init all db listeners
 * @returns {function(...[*]=)}
 */
export function initRedux() {
  return (dispatch, getState) => {
    dispatch(getBusinessInfo()).then(async () => {
      const placeRef = await dispatch(getPlaceRef());
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${placeRef}`;

      const persistedUpdates = await getPersistedUpdates();

      await dispatch(getUpdates());

      const { updates } = getState().user;
      dispatch(getCashShifts(isKeyEqual(updates, persistedUpdates, 'Shifts')));
      dispatch(getEmployers(isKeyEqual(updates, persistedUpdates, 'Employers')));
      dispatch(getInfo());
      dispatch(getMigration(isKeyEqual(updates, persistedUpdates, 'Migration')));
      dispatch(getPayments(isKeyEqual(updates, persistedUpdates, 'Payments')));
      dispatch(getResolvedBills(isKeyEqual(updates, persistedUpdates, 'Bills')));
      dispatch(getCategories(isKeyEqual(updates, persistedUpdates, 'Categories')));
      dispatch(getClients(isKeyEqual(updates, persistedUpdates, 'MarketingClients')));
      dispatch(getSales(isKeyEqual(updates, persistedUpdates, 'MarketingSales')));
      dispatch(getProducts(isKeyEqual(updates, persistedUpdates, 'Products')));
      dispatch(getAddons(isKeyEqual(updates, persistedUpdates, 'Addons')));
      dispatch(getItems(isKeyEqual(updates, persistedUpdates, 'Items')));
      dispatch(getLoyalty(isKeyEqual(updates, persistedUpdates, 'MarketingLoyalty')));
      dispatch(getComponents(isKeyEqual(updates, persistedUpdates, 'Components')));
      dispatch(getEquipments(isKeyEqual(updates, persistedUpdates, 'Equipments')));
      dispatch(getCommunication(isKeyEqual(updates, persistedUpdates, 'communication')));
      dispatch(getConsumables(isKeyEqual(updates, persistedUpdates, 'Consumables')));
      dispatch(getGroups(isKeyEqual(updates, persistedUpdates, 'MarketingGroups')));
      dispatch(getSuppliers(isKeyEqual(updates, persistedUpdates, 'Suppliers')));
      dispatch(getStorage(isKeyEqual(updates, persistedUpdates, 'Storage')));
      dispatch(getFinancialCategories(isKeyEqual(updates, persistedUpdates, 'FinancialCategories')));
      dispatch(getFinancialAccounts(isKeyEqual(updates, persistedUpdates, 'FinancialAccounts')));
      dispatch(getJobs(isKeyEqual(updates, persistedUpdates, 'Jobs')));
      dispatch(getKeeperSettings(isKeyEqual(updates, persistedUpdates, 'Keeper')));
      // dispatch(getNotifications());
      dispatch(getTransactions(isKeyEqual(updates, persistedUpdates, 'Transactions')));
    });
  };
}

const fetchMap = {
  Shifts: getCashShifts,
  Employers: getEmployers,
  info: getInfo,
  Migration: getMigration,
  Payments: getPayments,
  Bills: getResolvedBills,
  MarketingClients: getClients,
  Products: getProducts,
  MarketingSales: getSales,
  Addons: getAddons,
  Items: getItems,
  MarketingLoyalty: getLoyalty,
  Components: getComponents,
  Equipments: getEquipments,
  communication: getCommunication,
  Consumables: getConsumables,
  MarketingGroups: getGroups,
  Suppliers: getSuppliers,
  Storage: getStorage,
  Categories: getCategories,
  FinancialCategories: getFinancialCategories,
  FinancialAccounts: getFinancialAccounts,
  Jobs: getJobs,
  Keeper: getKeeperSettings,
  business: getBusinessInfo,
};

export function refetchData() {
  return async (dispatch, getState) => {
    const persistedUpdates = await getPersistedUpdates();
    const placeRef = await dispatch(getPlaceRef());
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${placeRef}`;

    await dispatch(getUpdates());

    const { updates } = getState().user;

    Object.keys(fetchMap).forEach((key) => {
      if (!isKeyEqual(updates, persistedUpdates, key)) dispatch(fetchMap[key]());
    });

    if (!isKeyEqual(updates, persistedUpdates, 'places')) {
      const placeRef = await dispatch(getPlaceRef());
      dispatch(getPlaceInfo(placeRef));
    }
  };
}

/**
 * Unsubscribe from db, change activePlace, set activePlace to IDB, reinit Redux
 * @param place
 * @returns {function(...[*]=)}
 */
export function changePlace(place) {
  return (dispatch) => {
    dispatch({ type: 'CLEAN_REDUCERS' });

    cleanAllPersistedCollection();

    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${place.id}`;


    dispatch({ type: SET_ACTIVE_PLACE, payload: place.id });

    setPlaceRefToDB(place.id);

    dispatch(getPlaceInfo(place.id));

    dispatch(refetchData());
  };
}

/**
 * Unsubscribe from db, remove AdminAccess, do firebase logout, clean up redux store
 * @returns {function(...[*]=)}
 */
export function logout() {
  return (dispatch) => {
    dispatch(removeAdminAccess());
    del('placeId', idb);
    firebase.auth().signOut();
    dispatch({ type: 'CLEAN_REDUCERS' });

    cleanAllPersistedCollection();
    dispatch({ type: GET_BUSINESS_INFO, payload: businessInitialState });
    dispatch({ type: GET_COMMUNICATION, payload: communicationInitialState });
  };
}
