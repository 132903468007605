import {
  GET_CONSUMABLES,
  EDIT_CONSUMABLE,
  ADD_CONSUMABLE,
  REMOVE_CONSUMABLE,
  GET_EQUIPMENTS,
  EDIT_EQUIPMENT,
  ADD_EQUIPMENT,
  REMOVE_EQUIPMENT,
} from './actions';

const initialState = {
  consumables: [],
  items: [],
  idMap: {},
  loading: true,
};

function equipmentReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CONSUMABLES:
      return {
        ...state,
        consumables: action.payload,
        loading: false,
        idMap: { ...state.idMap, ...action.payload.reduce((res, el) => ({ ...res, [el.id]: el }), {}) },
      };
    case EDIT_CONSUMABLE:
      return {
        ...state,
        idMap: { ...state.idMap, [action.payload.id]: action.payload },
        consumables: state.consumables.map(el => (el.id === action.payload.id ? action.payload : el)),
      };
    case ADD_CONSUMABLE:
      return {
        ...state,
        consumables: [...state.consumables, action.payload],
        idMap: { ...state.idMap, [action.payload.id]: action.payload },
      };
    case REMOVE_CONSUMABLE:
      const idMapWithoutConsumable = { ...state.idMap };
      delete idMapWithoutConsumable[action.payload];
      return {
        ...state,
        consumables: state.consumables.filter(el => el.id !== action.payload),
        idMap: { ...idMapWithoutConsumable },
      };
    case GET_EQUIPMENTS:
      return {
        ...state,
        items: action.payload,
        idMap: { ...state.idMap, ...action.payload.reduce((res, el) => ({ ...res, [el.id]: el }), {}) },
      };
    case EDIT_EQUIPMENT:
      return {
        ...state,
        items: state.items.map(el => (el.id === action.payload.id ? action.payload : el)),
        idMap: { ...state.idMap, [action.payload.id]: action.payload },
      };
    case ADD_EQUIPMENT:
      return {
        ...state,
        items: [...state.items, action.payload],
        idMap: { ...state.idMap, [action.payload.id]: action.payload },
      };
    case REMOVE_EQUIPMENT:
      const idMapWithoutEquipment = { ...state.idMap };
      delete idMapWithoutEquipment[action.payload];
      return {
        ...state,
        items: state.items.filter(el => el.id !== action.payload),
        idMap: { ...idMapWithoutEquipment },
      };
    case 'CLEAN_REDUCERS': {
      return initialState;
    }
    default:
      return state;
  }
}
export default equipmentReducer;
