import React from 'react';

const DeltaText = ({ value, meta = '', itemRender = null }) => (value === 0
  ? <span className="color-gray">0 {meta}</span>
  : value > 0
    ? <span className="color-green">+{itemRender ? itemRender(value) : value} {meta}</span>
    : <span className="color-red">{itemRender ? itemRender(value) : value} {meta}</span>
);

export default DeltaText;
