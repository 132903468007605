import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { showMessage } from '../../../redux/message/actions';
import Jobs from './Jobs';
import { removeJob } from '../../../redux/access/actions';

function mapStateToProps(state) {
  return {
    access: state.access,
    currency: state.business.currency,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        removeJob,
      },
      dispatch,
    ),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Jobs));
