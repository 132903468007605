import React, { Component } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { withTranslation } from 'react-i18next';
import { firebase } from '../../../config';
import {
  Row, PageHeader, Content, TopBar, Page,
} from '../../../components';
import AddFinancialAccount from './AddFinancialAccount';
import EditFinancialAccount from './EditFinancialAccount';
import { alphabeticalSort } from '../../../utils/helpers';
import { deleteModal } from '../../../utils/modals';
import { isOnline } from '../../../persistance';
import { showNetworkError } from '../../../redux/message/actions';

class Accounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
    };
  }

  selectItem = (record) => {
    if (!isOnline()) return showNetworkError();
    const { financial: { accounts } } = this.props;
    const selectedItem = accounts.find(el => el.name === record.name);
    this.setState({ selectedItem });
    this.editFinancialAccount.show();
  };

  render() {
    const {
      t, financial: { accounts }, currency, actions: { removeFinancialAccount },
    } = this.props;
    const { selectedItem } = this.state;
    const accountsTableColumns = [{
      title: t('global.name'),
      dataIndex: 'name',
      sorter: (a, b) => (-1) * a.name.localeCompare(b.name),
      sortDirections: ['descend'],
    },
    {
      title: t('global.type'),
      dataIndex: 'type',
      sorter: (a, b) => (-1) * a.type.localeCompare(b.type),
      sortDirections: ['descend'],
      render: type => t(`financial.${type}`),
    }, {
      title: t('global.balance'),
      dataIndex: 'count',
      sorter: (a, b) => {
        const delta = a.count - b.count;
        if (delta > 0) return -1;
        if (delta < 0) return 1;
        return 0;
      },
      sortDirections: ['descend'],
      render: text => `${text || 0} ${currency}`,
    }, {
      title: '',
      width: '30px',
      render: (text, record) => (
        <Row width="100%" justifyContent="center">
          <Button onClick={() => this.selectItem(record)} type="link" icon={<EditOutlined />} />
        </Row>
      ),
    }, {
      title: '',
      width: '30px',
      render: (text, record) => record.id !== 1 && record.id !== 2 && (
        <Row width="100%" justifyContent="center">
          <Button
            onClick={() => deleteModal(() => removeFinancialAccount(record.id))}
            type="link"
            icon={<DeleteOutlined />}
          />
        </Row>
      ),
    }];
    return (
      <Page spinning={!firebase.auth().currentUser}>
        <PageHeader />
        <Content>
          <TopBar data-tour="financialAccounts-tour-2">
            <h3 className="storage-page-title">{t('layout.account')}: {accounts.length}</h3>
            <Button
              className="add-button"
              size="large"
              onClick={() => {
                if (!isOnline()) return showNetworkError();
                this.addFinancialAccount.show();
              }}
            >
              {t('control.add')}
            </Button>
          </TopBar>
          <Table
            bordered
            pagination={false}
            size="default"
            className="storage-page-table"
            columns={accountsTableColumns}
            dataSource={alphabeticalSort(accounts).map((el, index) => ({ ...el, key: index }))}
          />
        </Content>
        <AddFinancialAccount ref={(ref) => { this.addFinancialAccount = ref; }} />
        <EditFinancialAccount item={selectedItem} ref={(ref) => { this.editFinancialAccount = ref; }} />
      </Page>
    );
  }
}

export default withTranslation()(Accounts);
