import React, { Component, Fragment } from 'react';
import {
  SettingOutlined, LockOutlined, ShopOutlined, MenuOutlined, InboxOutlined, DatabaseOutlined, LineChartOutlined,
  DollarCircleOutlined, RightCircleOutlined, LeftCircleOutlined, PieChartOutlined,
} from '@ant-design/icons';
import { Popover } from 'antd';
import { withTranslation } from 'react-i18next';
import { Column } from '../index';
import CheckAdminAccess from '../CheckAdminAccess';
import './style.scss';
import { isPermissionGranted, showNotGrantedMessage } from '../../constants/tariff';

const getMenuItems = data => [
  {
    name: 'layout.keeper',
    route: '/app/dashboard',
    icon: <ShopOutlined
      className="item-icon"
      style={{ color: '#FFFFFF', fontSize: '20px' }}
    />,
  },
  {
    name: 'layout.menu',
    route: '/app/menu',
    icon: <MenuOutlined
      className="item-icon"
      style={{ color: '#FFFFFF', fontSize: '20px' }}
    />,
    extra: { 'data-tour': 'menu-tour-1' },
  },
  {
    name: 'layout.inventory',
    icon: <InboxOutlined
      className="item-icon"
      style={{ color: '#FFFFFF', fontSize: '20px' }}
    />,
    extra: { 'data-tour': 'equipment-tour-0' },
    children: [
      { name: 'global.components', route: '/app/equipment/components', extra: { 'data-tour': 'component-tour-1' } },
      { name: 'global.materials', route: '/app/equipment/consumables', extra: { 'data-tour': 'equipment-tour-1' } },
      { name: 'layout.kits', route: '/app/equipment/kits', extra: { 'data-tour': 'kit-tour-1' } },
    ],
  }, {
    name: 'layout.store',
    icon: <DatabaseOutlined
      className="item-icon"
      style={{ color: '#FFFFFF', fontSize: '20px' }}
    />,
    extra: { 'data-tour': 'store-tour-0' },
    children: [
      {
        permission: 'store',
        name: 'storage.onStore',
        route: '/app/storage/store',
      },
      {
        permission: 'store',
        name: 'storage.suppliers',
        route: '/app/storage/suppliers',
      },
      {
        permission: 'store',
        name: 'storage.supply',
        route: '/app/storage/supply',
        extra: { 'data-tour': 'supply-tour-1' },
      },
      {
        permission: 'store',
        name: 'storage.waste',
        route: '/app/storage/waste',
        extra: { 'data-tour': 'waste-tour-1' },
      },
      {
        permission: 'store',
        name: 'storage.inventory',
        route: '/app/storage/inventory',
        extra: { 'data-tour': 'inventory-tour-1' },
      },
      {
        permission: 'store',
        name: 'storage.consumption',
        route: '/app/storage/consumption',
        extra: { 'data-tour': 'consumption-tour-1' },
      },
    ],
  }, {
    name: 'layout.statistic',
    icon: <LineChartOutlined
      className="item-icon"
      style={{ color: '#FFFFFF', fontSize: '20px' }}
    />,
    children: [
      { name: 'layout.profit', route: '/app/statistic/profit' },
      { name: 'layout.payments', route: '/app/statistic/payments' },
      { name: 'layout.bills', route: '/app/statistic/bills' },
      { name: 'layout.items', route: '/app/statistic/items' },
      { name: 'layout.employers', route: '/app/statistic/employers' },
      { name: 'layout.category', route: '/app/statistic/categories' },
    ],
  }, {
    name: 'layout.financial',
    icon: <DollarCircleOutlined
      className="item-icon"
      style={{ color: '#FFFFFF', fontSize: '20px' }}
    />,
    extra: { 'data-tour': 'financial-tour-0' },
    children: [
      {
        name: 'layout.transactions',
        route: '/app/financial/transactions',
        extra: { 'data-tour': 'financialTransactions-tour-1' },
      },
      { name: 'layout.salary', route: '/app/financial/salary', permission: 'salaryCalculation' },
      {
        name: 'layout.shifts',
        route: '/app/financial/shifts',
        extra: { 'data-tour': 'financialShifts-tour-1' },
      },
      {
        name: 'layout.category',
        route: '/app/financial/categories',
        extra: { 'data-tour': 'financialCategories-tour-1' },
      },
      { name: 'layout.account', route: '/app/financial/accounts', extra: { 'data-tour': 'financialAccounts-tour-1' } },
      { name: 'layout.financialReport', route: '/app/financial/report' },
    ],
  }, {
    name: 'layout.marketing',
    icon: <PieChartOutlined
      className="item-icon"
      style={{ color: '#FFFFFF', fontSize: '20px' }}
    />,
    extra: { 'data-tour': 'marketing-tour-0' },
    children: [
      {
        name: 'layout.clients',
        route: '/app/marketing/clients',
        extra: { 'data-tour': 'clients-tour-1' },
      },
      {
        name: 'layout.groups',
        route: '/app/marketing/groups',
        extra: { 'data-tour': 'groups-tour-1' },
      },
      {
        name: 'layout.loyalty',
        route: '/app/marketing/loyalty',
        extra: { 'data-tour': 'loyalty-tour-1' },
      },
      {
        name: 'layout.sales',
        route: '/app/marketing/sales',
        extra: { 'data-tour': 'sales-tour-1' },
      },
      {
        name: 'layout.loyaltyExclude',
        route: '/app/marketing/exclusion',
        extra: { 'data-tour': 'loyalty-exclude-tour-1' },
      },
    ],
  }, {
    name: 'layout.access',
    icon: <LockOutlined
      className="item-icon"
      style={{ color: '#FFFFFF', fontSize: '20px' }}
    />,
    extra: { 'data-tour': 'access-tour-0' },
    children: [
      { name: 'layout.employers', route: '/app/access/employers', extra: { 'data-tour': 'employers-tour-1' } },
      { name: 'layout.jobs', route: '/app/access/jobs', extra: { 'data-tour': 'jobs-tour-1' } },
    ],
  }, {
    name: 'layout.settings',
    icon: <SettingOutlined
      className="item-icon"
      style={{ color: '#FFFFFF', fontSize: '20px' }}
    />,
    extra: { 'data-tour': 'settings-tour-0' },
    children: [
      { name: 'layout.general', route: '/app/settings/general', withDot: !data.isActualVersion },
      { name: 'layout.checkbox', route: '/app/settings/checkbox' },
      { name: 'layout.keeper', route: '/app/settings/keeper' },
      { name: 'layout.notifications', route: '/app/settings/notifications' },
      {
        name: 'layout.subscription',
        route: '/app/settings/subscription',
        extra: {
          'data-tour': 'subscription-tour-1',
        },
      },
      { name: 'layout.updates', route: '/app/settings/updates' },
    ],
  },
];

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      selectedItem: null,
      menuItems: getMenuItems({ ...props.user, canInteract: props.business.canInteract }),
    };
    document.body.classList.add('no-overflow');
    /* document.querySelector('meta[name=viewport]')
      .setAttribute('content', 'width=768, initial-scale=1, shrink-to-fit=no'); */
  }

  componentWillUnmount() {
    document.body.classList.remove('no-overflow');
    document.querySelector('meta[name=viewport]')
      .setAttribute('content', 'width=device-width, initial-scale=1, shrink-to-fit=no');
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isAdmin !== this.props.isAdmin) {
      this.setState({
        collapsed: !nextProps.isAdmin,
        menuItems: getMenuItems({ ...nextProps.user, canInteract: nextProps.business.canInteract }),
      });
    }
    if (nextProps.user.isActualVersion !== this.props.user.isActualVersion) {
      this.setState({ menuItems: getMenuItems({ ...nextProps.user, canInteract: nextProps.business.canInteract }) });
    }
  }

  render() {
    const {
      children, history, t, isAdmin, business,
    } = this.props;
    const { collapsed, selectedItem, menuItems } = this.state;
    const isCollapsed = collapsed && isAdmin;
    return (
      <div className="layout">
        <aside className={`layout-menu no-print ${collapsed ? 'collapsed' : ''}`}>
          <div className="layout-menu-logo">
            <img src="/images/icons/icon-192x192.png" alt="shoptoria" />

            {!collapsed && <h3>hoptoria</h3> }
          </div>
          <nav className="layout-nav">
            {(isAdmin ? menuItems : [menuItems[0]]).map((el) => {
              const content = (
                <Column
                  width="100%"
                  height={selectedItem === el ? 'auto' : '0'}
                  alignItems="flex-start"
                  className={`layout-menu-item-children ${selectedItem === el ? 'visible' : ''}`}
                >
                  {el.children && el.children.map(item => (
                    <div
                      key={item.route}
                      style={{ padding: '10px 0 10px 10px' }}
                      className={`layout-menu-item ${item.route === history.location.pathname ? 'active' : ''} 
                      ${item.withDot ? 'child-dot' : ''}`}
                      onClick={() => {
                        if (item.route) {
                          if (item.permission) {
                            if (isPermissionGranted(business.tariff, item.permission)) {
                              history.push(item.route);
                            } else {
                              showNotGrantedMessage(t);
                            }
                          } else {
                            history.push(item.route);
                          }
                        }
                      }}
                      {...item.extra}
                    >
                      <h4 className="layout-menu-item-link" style={{ margin: '0 0 0 10px' }}>{t(item.name)}</h4>
                    </div>
                  ))}
                </Column>
              );
              const main = (
                <div
                  {...el.extra}
                  className={`layout-menu-item ${el.route === history.location.pathname ? 'active' : ''}
                  ${el.withDot || (el.children && el.children.reduce((r, i) => (i.withDot || r), false)) ? 'dot' : ''} 
                  ${el.children && (!selectedItem || collapsed)
                  && el.children.find(el => el.route === history.location.pathname) ? 'active-parent' : ' '}`}
                  onClick={() => {
                    if (el.route) history.push(el.route);
                    this.setState(state => ({ selectedItem: state.selectedItem === el && !collapsed ? null : el }));
                  }}
                >
                  {el.icon}

                  <h4 className="layout-menu-item-link">{t(el.name)}</h4>
                </div>
              );
              return (
                <Column width="100%" alignItems="flex-start" key={JSON.stringify(el)}>
                  {collapsed ? el.children
                    ? <Popover placement="right" title={t(el.name)} trigger="click" content={content}>{main}</Popover>
                    : <Fragment>{main}</Fragment>
                    : <Fragment>{main}{content}</Fragment>
                }
                </Column>
              );
            })}
            <div style={{ marginTop: 'auto' }} />

            <CheckAdminAccess wrappedComponentRef={(ref) => { this.accessModal = ref; }} />

            {isAdmin ? (
              <div
                className="layout-menu-item layout-collapse-button"
                onClick={() => this.setState(state => ({ collapsed: !state.collapsed }))}
              >
                {isCollapsed ? (
                  <RightCircleOutlined style={{ color: '#FFFFFF', fontSize: '20px' }} />
                ) : (
                  <LeftCircleOutlined style={{ color: '#FFFFFF', fontSize: '20px' }} />
                )}
                <h4 className="layout-menu-item-link">{t('control.collapse')}</h4>
              </div>
            ) : (
              <div className="layout-menu-item">
                <LockOutlined
                  onClick={() => this.accessModal.show()}
                  style={{ color: '#FFFFFF', fontSize: '20px' }}
                />
              </div>
            )}
          </nav>
        </aside>

        <main className={`layout-content ${collapsed ? 'full' : ''}`}>
          {children}
        </main>

        {!collapsed && <div className="layout-blur" onClick={() => this.setState({ collapsed: true })} />}
      </div>
    );
  }
}

export default withTranslation()(Layout);
