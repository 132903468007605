import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { createBill } from '../../../redux/statistic/actions';

const NewBillButton = ({ actions: { createBill }, t }) => (
  <Button
    style={{ margin: '0 63px 0 15px', color: 'white' }}
    onClick={() => createBill()}
    icon={<PlusOutlined />}
    type="link"
  >
    {t('bills.new')}
  </Button>
);

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        createBill,
      },
      dispatch,
    ),
  };
}

export default connect(null, mapDispatchToProps)(withTranslation()(NewBillButton));
