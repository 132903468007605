/* eslint-disable */

export default {
  reactour: {
    popover: {
      title: 'Need help?',
    },
    firstView: {
      title: 'How to get started?',
      step1: {
        title: 'Welcome to Shoptoria!',
        text: 'We prepare a <b>3 minutes</b> introduction tour for You.<br /> You can skip this tour and get back later from <b>Settings</b> page.',
        buttonText: 'Next',
        skipText: 'Skip',
      },
      step2: {
        title: 'Terminal',
        text: 'To access to <b>Terminal</b> all Your employers get it own <b>Terminal access password</b>.<br /> Let`s find Your password in <b>Employers</b> tab.',
        buttonText: 'Next',
      },
      step3: {
        title: 'Employer',
        text: 'You, as a employer get your own password for <b>Terminal access</b>.<br /> As you can see, Your password is <b>0000</b>.',
        buttonText: 'Next',
      },
      step4: {
        title: 'Terminal access',
        text: 'Now You know your <b>Terminal access</b> password - <b>0000</b>.<br />You can change your password at <b>Employers</b> tab.',
        buttonText: 'Next',
      },
      step5: {
        title: 'Menu',
        text: 'Looks like we are ready to open!<br />Let`s explore your <b>Menu</b>.<br />',
        buttonText: 'Next',
      },
      step6: {
        title: 'Menu Tab',
        text: 'We prepare some items, based on Your business type, so You can explore how <b>Terminal</b> works without any time spending.',
        buttonText: 'Next',
      },
      step7: {
        title: 'Need some help?',
        text: 'If You don`t know what exactly do on this page, just click on a <b>question</b> icon!',
        buttonText: 'Got it',
      },
      step8: {
        title: 'Finish',
        text: 'That all for now!<br /> Create new <b>Products</b>, fill your <b>Storage</b>, investigate in <b>Statistic</b>, control you <b>Financial</b> - do it all with <b>Shoptoria</b>.',
        buttonText: 'Finish',
      },
    },
    menu: {
      title: 'What is this page?',
      step1: {
        title: 'Menu',
        text: 'In <b>Menu</b> tab You can setup your restaurant menu.<br /> You can add product, items, addons and manage categories.',
        buttonText: 'Next',
      },
      step2: {
        title: 'Manage categories',
        text: 'In <b>Manage categories</b> tab You can manage your menu categories.<br /><br /> More details in <b>"What is manage categories?"</b>',
        buttonText: 'Next',
      },
      step21: {
        title: 'Create receipt',
        text: 'In <b>Create receipt</b> modal You can select what type of receipt You want to create.<br /><br /> More details in <b>"What difference between receipts?"</b>',
        buttonText: 'Next',
      },
      step3: {
        title: 'Create product',
        text: 'In <b>Create product</b> tab You can create product from receipt.<br /><br /> More details in <b>"How to create a product?"</b>',
        buttonText: 'Next',
      },
      step4: {
        title: 'Create item',
        text: 'In <b>Create item</b> tab You can create item for sales.<br /><br /> More details in <b>"How to create a item?"</b>',
        buttonText: 'Next',
      },
      step5: {
        title: 'Create addon',
        text: 'In <b>Create addon</b> tab You can create addons for your products.<br /><br /> More details in <b>"How to create a addon?"</b>',
        buttonText: 'Next',
      },
      step6: {
        title: 'Menu tabs',
        text: 'In this tabs You can see products, items and addons, sorted by types and categories.<br />You can add your own categories with <b>Manage categories</b>.',
        buttonText: 'Finish',
      },
    },
    equipment: {
      title: 'What is this page?',
      step1: {
        title: 'Materials',
        text: 'In <b>Materials</b> tab You can manage your consumables.<br /> You can add consumables to your receipts or items.<br /><br /> Materials also shown in <b>Store</b> tab and can be added to supply, waste and inventory.',
        buttonText: 'Next',
      },
      step2: {
        title: 'Consumables',
        text: 'Here You can add, edit or delete your consumables.<br /><br /> More details in <b>"What is consumables?"</b>',
        buttonText: 'Finish',
      },
    },
    component: {
      title: 'What is this page?',
      step1: {
        title: 'Components',
        text: 'In <b>Components</b> tab You can manage your components.<br /> You can add components to your receipts.<br /><br /> Components also shown in <b>Store</b> tab and can be added to supply, waste and inventory.',
        buttonText: 'Next',
      },
      step2: {
        title: 'Components',
        text: 'Here You can add, edit or delete your components.<br /><br /> More details in <b>"What is components?"</b>',
        buttonText: 'Finish',
      },
    },
    supply: {
      title: 'What is this page?',
      step1: {
        title: 'Supply tab',
        text: 'In <b>Supply</b> tab You can add supply to your <b>Store</b>.<br /> Items, components and consumables, that your add to supply will increase its on store quantity.<br />',
        buttonText: 'Next',
      },
      step2: {
        title: 'Supplies',
        text: 'Here You can add, edit or delete your supply.<br /><br /> More details in <b>"What is supply?"</b>',
        buttonText: 'Finish',
      },
    },
    waste: {
      title: 'What is this page?',
      step1: {
        title: 'Waste tab',
        text: 'In <b>Waste</b> tab You can add waste to your <b>Store</b>.<br /> Items, components and consumables, that your add to inventory will decrease its on store quantity.<br />',
        buttonText: 'Next',
      },
      step2: {
        title: 'Wastes',
        text: 'Here You can add, edit or delete your wastes.<br /><br /> More details in <b>"What is waste?"</b>',
        buttonText: 'Finish',
      },
    },
    inventory: {
      title: 'What is this page?',
      step1: {
        title: 'Inventory tab',
        text: 'In <b>Inventory</b> tab You can add inventory to your <b>Store</b>.<br /> Items, components and consumables, that your add to supply will override its on store quantity.<br />',
        buttonText: 'Next',
      },
      step2: {
        title: 'Inventories',
        text: 'Here You can add, edit or delete your inventories.<br /><br /> More details in <b>"What is inventory?"</b>',
        buttonText: 'Finish',
      },
    },
    consumption: {
      title: 'What is this page?',
      step1: {
        title: 'Consumption tab',
        text: 'In <b>Consumption</b> tab You can see how much You consume.<br />',
        buttonText: 'Next',
      },
      step2: {
        title: 'Time range',
        text: 'You can select any time range You want.<br/><br /> Items, components and consumables, that your consume You`ll see calculated from bills.',
        buttonText: 'Finish',
      },
    },
    financialAccounts: {
      title: 'What is this page?',
      step1: {
        title: 'Accounts tab',
        text: 'In <b>Accounts</b> tab You can add financial account to your <b>Financial</b>.',
        buttonText: 'Next',
      },
      step2: {
        title: 'Accounts',
        text: 'Here You can add, edit or delete your financial accounts.<br /><br /> More details in <b>"What is financial account?"</b>',
        buttonText: 'Finish',
      },
    },
    financialCategories: {
      title: 'What is this page?',
      step1: {
        title: 'Categories tab',
        text: 'In <b>Categories</b> tab You can add financial category to your <b>Financial</b>.<br /><br> Financial categories used for financial transaction creation.',
        buttonText: 'Next',
      },
      step2: {
        title: 'Accounts',
        text: 'Here You can add, edit or delete your financial categories.<br /><br /> More details in <b>"What is financial categories?"</b>',
        buttonText: 'Finish',
      },
    },
    financialShifts: {
      title: 'What is this page?',
      step1: {
        title: 'Shifts tab',
        text: 'In <b>Shifts</b> tab You can see Cash shift reports.',
        buttonText: 'Next',
      },
      step2: {
        title: 'Cash Shifts',
        text: 'Your can expand table row to get more details.',
        buttonText: 'Finish',
      },
    },
    financialTransactions: {
      title: 'What is this page?',
      step1: {
        title: 'Transactions tab',
        text: 'In <b>Transactions</b> tab You can add financial transaction to your <b>Financial</b>.<br /><br /> Financial transactions allow you to control the financial flows of your business.',
        buttonText: 'Next',
      },
      step2: {
        title: 'Transactions',
        text: 'Here You can add, edit or delete your financial transactions.<br /><br /> More details in <b>"What is financial transactions?"</b>',
        buttonText: 'Finish',
      },
    },
    employers: {
      title: 'What is this page?',
      step1: {
        title: 'Employers tab',
        text: 'In <b>Employers</b> tab You can manage your Employers.<br /><br /> The major things here is a <b>Terminal access password</b>, that use to access to <b>Terminal</b>.',
        buttonText: 'Next',
      },
      step2: {
        title: 'Employers',
        text: 'Here You can add, edit or delete your employer.<br /><br /> More details in <b>"How to manage employers?"</b>',
        buttonText: 'Finish',
      },
    },
    jobs: {
      title: 'What is this page?',
      step1: {
        title: 'Jobs tab',
        text: 'In <b>Jobs</b> tab You can manage your business jobs.<br /><br /> Job help You manage your employers salary calculation.',
        buttonText: 'Next',
      },
      step2: {
        title: 'Jobs',
        text: 'Here You can add, edit or delete your jobs.<br /><br /> More details in <b>"How to manage jobs?"</b>',
        buttonText: 'Finish',
      },
    },
    subscription: {
      title: 'What is this page?',
      step1: {
        title: 'Subscription',
        text: 'In <b>Subscription</b> tab You can manage your subscription.<br /><br /> You can get info about your tariff, see payments history and pay for 1 month.<br />',
        buttonText: 'Next',
      },
      step2: {
        title: 'Account',
        text: 'Here You can see your <b>Account data</b>: actual tariff, subscription price, last subscription day.<br />',
        buttonText: 'Next',
      },
      step3: {
        title: 'Payments',
        text: 'Here You can see your Payments history.<br /><br>You can pay for a 1 month with Pay button.',
        buttonText: 'Finish',
      },
    },
  },
};
