import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { showMessage } from '../../../redux/message/actions';
import { updatePlaceInfo, getPlaceInfo } from '../../../redux/business/actions';

import Checkbox from './Checkbox';

function mapStateToProps(state) {
  return {
    business: state.business,
    version: state.user.version,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        updatePlaceInfo,
        getPlaceInfo,
      },
      dispatch,
    ),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Checkbox));
