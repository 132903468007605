import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Button, Empty, Input, Modal, Select, Table,
} from 'antd';
import { Column, Row } from '../index';
import { notificationTypes } from '../../redux/message/actions';
import {
  checkIsNameExist, getDefaultImageByType, getEmptyMaterial, getSizeName, hasNullKey, sizesSort,
} from '../../utils/helpers';
import './style.scss';

const { Option } = Select;

const sizes = ['S', 'M', 'L', 'XL', 'XXL'];

class AddEquipment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consumables: [],
      name: '',
      consumableSearch: '',
      sizes: sizes.reduce((res, el) => ({ ...res, [el]: 0 }), {}),
      sizeList: sizes,
    };
  }

  submit = () => {
    const {
      callback, existedItems, close, t, actions: { showMessage },
    } = this.props;
    const {
      consumables, name, sizes, sizeList,
    } = this.state;
    if (name.trim() === '') {
      showMessage({ text: t('validation.emptyName'), type: notificationTypes.ERROR });
      return;
    }
    if (hasNullKey(sizes)) {
      showMessage({ text: t('validation.emptySizes'), type: notificationTypes.ERROR });
      return;
    }
    if (consumables.length === 0) {
      showMessage({ text: t('validation.pickConsumables'), type: notificationTypes.ERROR });
      return;
    }
    if (checkIsNameExist(name, existedItems)) {
      showMessage({ text: t('validation.uniqueConsumableName'), type: notificationTypes.ERROR });
      return;
    }
    callback({
      materials: consumables.map(el => el.id),
      name,
      sizes: Object.keys(sizes).reduce((res, key) => ({
        ...res, [key]: sizeList.includes(key) ? sizes[key] : 0,
      }), {}),
    });
    close();
  };

  chooseFromExist = (item) => {
    const { close, callback } = this.props;
    callback(item);
    close();
  };

  toggleConsumable = (consumable) => {
    const { consumables } = this.state;
    const isIncluded = consumables.find(el => el.name === consumable.name);
    this.setState(state => ({
      consumables: isIncluded
        ? state.consumables.filter(el => el.name !== consumable.name)
        : [...state.consumables, consumable],
    }));
  };

  updateSize = (size, id) => {
    this.setState(state => ({ sizes: { ...state.sizes, [size]: id } }));
  };

  getFilteredConsumables = () => {
    const { equipment: { consumables } } = this.props;
    const { consumableSearch } = this.state;
    if (consumableSearch.trim() !== '') {
      return consumables.filter(consumable => consumable.name.toLowerCase().includes(consumableSearch.toLowerCase()));
    }
    return consumables;
  };

  render() {
    const {
      visible, t, equipment, close, withPredefined = true,
    } = this.props;
    const {
      consumables, name, consumableSearch, sizeList, sizes,
    } = this.state;

    const consumablesNames = consumables.map(consumable => consumable.name);
    const filteredConsumables = this.getFilteredConsumables();
    const calculationColumns = [
      {
        title: t('global.size'), dataIndex: 'name', width: 100, render: e => <span className="default-color">{e}</span>,
      },
      {
        title: name,
        dataIndex: 'size',
        className: 'table-header-center',
        render: (value, record) => (
          <Select
            style={{ width: '100%' }}
            placeholder={t('global.consumable')}
            onChange={e => this.updateSize(record.size, e)}
            value={sizes[value]}
          >
            {[getEmptyMaterial(t), ...consumables].map(el => (
              <Option value={el.id} key={`size-${record.size}-consumable-${el.name}`}>{el.name}</Option>
            ))}
          </Select>
        ),
      },
    ];
    return (
      <Modal
        maskClosable={false}
        centered
        width="730px"
        visible={visible}
        onOk={this.submit}
        onCancel={close}
        okButtonProps={{ size: 'large' }}
        cancelButtonProps={{ size: 'large' }}
        okText={t('control.add')}
        cancelText={t('control.cancel')}
        title={t('equipment.add')}
        className="add-consumable"
      >
        <Row width="100%" alignItems="flex-start" className="mobile-equipment-column">
          <Column
            alignItems="flex-start"
            justifyContent="flex-start"
            width="328px"
            height="560px"
            className="equipment-content"
          >
            <h3 className="add-item-subtitle">{t('consumable.select')}</h3>
            <Input
              className="equipment-input"
              prefix={<SearchOutlined />}
              value={consumableSearch}
              onChange={e => this.setState({ consumableSearch: e.target.value })}
              style={{ width: '100%' }}
              suffix={<CloseOutlined onClick={() => this.setState({ consumableSearch: '' })} />}
            />
            {filteredConsumables.length > 0 ? (
              <Row
                width="100%"
                justifyContent="flex-start"
                flexWrap="wrap"
                margin="15px 0 0"
                style={{ maxHeight: '480px', overflowY: 'scroll' }}
              >
                {filteredConsumables.map(el => (
                  <div
                    className={`add-consumable-card ${consumablesNames.includes(el.name) ? 'checked' : ''}`}
                    key={`consumable-card-${JSON.stringify(el)}`}
                    onClick={() => this.toggleConsumable(el)}
                  >
                    <h3>{el.name}</h3>
                    <img
                      src={el.photo || getDefaultImageByType('consumable')}
                      style={{ opacity: el.photo ? 1 : 0.5 }}
                      alt=""
                      className="card-img"
                    />
                  </div>
                ))}
              </Row>
            ) : (
              <Empty />
            )}
          </Column>
          <Column
            alignItems="flex-start"
            justifyContent="flex-start"
            width="calc(100% - 358px)"
            margin="0 0 0 30px"
            style={{ paddingBottom: 0, minHeight: '560px' }}
            className="default-card equipment-content"
          >
            <h3 style={{ margin: '0 0 10px' }}>{t('equipment.kitName')}</h3>
            <Input
              className="equipment-input"
              style={{ width: '100%' }}
              placeholder={t('global.name')}
              onChange={e => this.setState({ name: e.target.value })}
              value={name}
            />
            <Table
              bordered
              pagination={false}
              size="default"
              style={{ width: '100%', overflowX: 'scroll', marginTop: '8px' }}
              columns={calculationColumns}
              dataSource={[
                ...sizeList.sort(sizesSort).map(size => ({
                  key: size,
                  size,
                  name: getSizeName(size),
                })),
              ]}
            />
          </Column>
        </Row>
        <h4 style={{ margin: '12px 0 0' }}><span className="help-text">*</span>{t('equipment.helpText')}</h4>
        {withPredefined && equipment.items.length > 0 && (
          <Column width="100%" className="default-card" margin="12px 0 0">
            <h3 style={{ margin: '0 0 5px' }}>{t('equipment.predefined')}</h3>
            <Row width="100%" alignItems="flex-start" justifyContent="flex-start" flexWrap="wrap">
              {equipment.items.map(item => (
                <Button
                  style={{ margin: '5px' }}
                  key={JSON.stringify(item)}
                  type="primary"
                  onClick={() => this.chooseFromExist(item)}
                >{item.name}
                </Button>
              ))}
            </Row>
          </Column>
        )}
      </Modal>
    );
  }
}

export default withTranslation()(AddEquipment);
