import React, { Component } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { Button, Drawer, Select } from 'antd';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import { Column, Row, Link } from '../../../components';
import { getLanguage } from '../index';
import i18 from '../../../utils/translation';
import './style.scss';
import './media.scss';
import { Capabilities } from '../components';
import { sendEvent } from '../../../utils/analytics';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerVisible: false,
    };
  }

  setLanguage = language => i18.changeLanguage(language);

  render() {
    const { t, history } = this.props;
    const { drawerVisible } = this.state;
    return (
      <header className="landing-header" id="header">
        <Row
          className="landing-header-logo"
          height="100%"
          width="25%"
          alignItems="flex-end"
          justifyContent="center"
          onClick={() => history.push('/')}
        >
          <img src="/images/icons/icon-512x512.png" alt="shoptoria" />
          <h3>hoptoria</h3>
        </Row>
        <Capabilities hideOnMobile t={t} />
        <HashLink
          onClick={() => sendEvent({ category: 'Landing', name: 'Header click to pricing' })}
          smooth
          to="/#pricing"
          className="mobile-hide"
        >{t('landing.prices')}
        </HashLink>
        <Link className="mobile-hide" to="/faq">FAQ</Link>
        <Select
          value={getLanguage()}
          getPopupContainer={() => document.getElementById('header')}
          className="language-wrapper"
          dropdownClassName="language-wrapper-menu"
          onChange={(e => this.setLanguage(e))}
          showArrow={false}
          style={{ width: 54, margin: '0 0 0 auto' }}
        >
          <Select.Option value="en">
            <img
              style={{ width: '30px', height: '30px' }}
              src="/assets/flags/united-kingdom.png"
              alt="EN flag"
            />
          </Select.Option>
          <Select.Option value="ru">
            <img
              style={{ width: '30px', height: '30px' }}
              src="/assets/flags/russia.png"
              alt="RU flag"
            />
          </Select.Option>
          <Select.Option value="ua">
            <img
              style={{ width: '30px', height: '30px' }}
              src="/assets/flags/ukraine.png"
              alt="UA flag"
            />
          </Select.Option>
        </Select>
        <Button onClick={() => history.push('/app/login')} className="login" type="link">{t('login.submit')}</Button>
        <Button
          size="large"
          type="link"
          className="drawer"
          icon={<MenuOutlined />}
          onClick={() => this.setState({ drawerVisible: true })}
        />
        <Drawer
          placement="right"
          onClose={() => this.setState({ drawerVisible: false })}
          visible={drawerVisible}
          className="drawer-menu"
        >
          <Column width="100%" height="calc(100% - 40px)" margin="40px 0 0">
            <Capabilities t={t} />
            <HashLink
              to="/#pricing"
              onClick={() => {
                sendEvent({ category: 'Landing', name: 'Drawer click to pricing' });
                this.setState({ drawerVisible: false });
              }}
            >
              {t('landing.prices')}
            </HashLink>
            <Link
              to="/install"
              onClick={() => {
                sendEvent({ category: 'Landing', name: 'Drawer click to install' });
                this.setState({ drawerVisible: false });
              }}
            >{t('landing.install.link')}
            </Link>
            <Link
              to="/terms"
              onClick={() => {
                sendEvent({ category: 'Landing', name: 'Drawer click to terms' });
                this.setState({ drawerVisible: false });
              }}
            >{t('landing.terms')}
            </Link>
            <Link
              to="/faq"
              onClick={() => {
                sendEvent({ category: 'Landing', name: 'Drawer click to faq' });
                this.setState({ drawerVisible: false });
              }}
            >FAQ
            </Link>
            <Link
              to="/feedback"
              onClick={() => {
                sendEvent({ category: 'Landing', name: 'Drawer click to feedback' });
                this.setState({ drawerVisible: false });
              }}
            >Feedback
            </Link>
            <Select
              value={getLanguage()}
              className="language-wrapper drawer-language"
              dropdownClassName="language-wrapper-menu"
              bordered={false}
              onChange={(e => this.setLanguage(e))}
              showArrow={false}
              style={{ width: '100%', margin: '10px 0', color: 'white' }}
            >
              <Select.Option value="en" style={{ color: 'white' }}>
                <img
                  style={{ width: '30px', height: '30px', marginRight: '10px' }}
                  src="/assets/flags/united-kingdom.png"
                  alt="EN flag"
                /><span>English</span>
              </Select.Option>
              <Select.Option value="ru" style={{ color: 'white' }}>
                <img
                  style={{ width: '30px', height: '30px', marginRight: '10px' }}
                  src="/assets/flags/russia.png"
                  alt="RU flag"
                /><span>Русский</span>
              </Select.Option>
              <Select.Option value="ua" style={{ color: 'white' }}>
                <img
                  style={{ width: '30px', height: '30px', marginRight: '10px' }}
                  src="/assets/flags/ukraine.png"
                  alt="UA flag"
                /><span>Українська</span>
              </Select.Option>
            </Select>
            <Button
              style={{ margin: '10px 0' }}
              onClick={() => {
                sendEvent({ category: 'Landing', name: 'Go to login' });
                history.push('/app/login');
              }}
              className="login"
              type="link"
            >{t('login.submit')}
            </Button>
            <Row width="100%" margin="auto 0 0" justifyContent="center" className="social-row">
              <a href="https://t.me/shoptoriaPos" target="_blank"><img src="/assets/telegram.png" alt="Telegram icon" /></a>
              <a href="https://www.instagram.com/shoptoria_pos/" target="_blank"><img src="/assets/instagram.png" alt="Instagram icon" /></a>
              <a href="https://www.facebook.com/shoptoria.pos" target="_blank"><img src="/assets/facebook.png" alt="Facebook icon" /></a>
            </Row>
          </Column>
        </Drawer>
      </header>
    );
  }
}

export default withRouter(withTranslation()(Header));
