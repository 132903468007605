import {
  idbMarketingClients, idbMarketingGroups, idbMarketingLoyalty, idbMarketingSales,
} from '../../config';
import axios from '../index';
import { persist, persistWithKey } from '../../persistance';

// Loyalty flow

export const getLoyaltyDataRequest = () => new Promise(resolve => axios.get('/db/loyalty')
  .then((res) => {
    persistWithKey('marketing-loyalty', res.data, idbMarketingLoyalty);
    resolve(res);
  }));

export const editLoyaltyRequest = data => axios.put('/db/loyalty', { data });

// Clients flow

export const getClientsDataRequest = () => new Promise(resolve => axios.get('/db/clients')
  .then((res) => {
    res.data.map(el => persist(el, idbMarketingClients));
    resolve(res);
  }));

export const editClientRequest = (id, data) => axios.put('/db/clients', { id, data });

export const addClientRequest = (id, data) => axios.post('/db/clients', { id, data });

export const removeClientRequest = id => axios.delete(`/db/clients/${id}`);

// Sales flow

export const getSalesDataRequest = () => new Promise(resolve => axios.get('/db/sales')
  .then((res) => {
    res.data.map(el => persist(el, idbMarketingSales));
    resolve(res);
  }));

export const editSaleRequest = (id, data) => axios.put('/db/sales', { id, data });

export const addSaleRequest = (id, data) => axios.post('/db/sales', { id, data });

export const removeSaleRequest = id => axios.delete(`/db/sales/${id}`);

// Groups flow

export const getGroupsDataRequest = () => new Promise(resolve => axios.get('/db/group')
  .then((res) => {
    res.data.map(el => persist(el, idbMarketingGroups));
    resolve(res);
  }));

export const editGroupRequest = (id, data) => axios.put('/db/group', { id, data });

export const addGroupRequest = (id, data) => axios.post('/db/group', { id, data });

export const removeGroupRequest = id => axios.delete(`/db/group/${id}`);
