import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const getAlign = (alignItems) => {
  switch (alignItems) {
    case 'center': return 'align-center';
    case 'flex-start': return 'align-start';
    case 'flex-end': return 'align-end';
    case 'stretch': return 'align-stretch';
    case 'baseline': return 'align-baseline';
    default: return 'normal';
  }
};

const getJustify = (justifyContent) => {
  switch (justifyContent) {
    case 'flex-start': return 'justify-start';
    case 'center': return 'justify-center';
    case 'flex-end': return 'justify-end';
    case 'space-around': return 'justify-around';
    case 'space-between': return 'justify-between';
    case 'space-evenly': return 'justify-evenly';
    default: return 'normal';
  }
};

export const Row = ({
  style = {}, className = '', flexWrap = 'nowrap', justifyContent = 'space-between', alignItems = 'center',
  children, margin = 0, width = 'auto', height = 'auto', ...props
}) => (
  <div
    className={`shoptoria-row ${getAlign(alignItems)} ${getJustify(justifyContent)} ${flexWrap} ${className}`}
    style={{
      ...style, margin, width, height,
    }}
    {...props}
  >
    {children}
  </div>
);
export const Column = ({
  style = {}, className = '', flexWrap = 'nowrap', justifyContent = 'space-between', alignItems = 'flex-start',
  children, margin = 0, width = null, height = null, ...props
}) => (
  <div
    className={`shoptoria-column ${getAlign(alignItems)} ${getJustify(justifyContent)} ${flexWrap} ${className}`}
    style={{
      ...style, margin, width, height,
    }}
    {...props}
  >
    {children}
  </div>
);

Row.propTypes = {
  alignItems: PropTypes.oneOf([
    'flex-start', 'center', 'space-between', 'space-evenly', 'flex-end', 'stretch', 'baseline',
  ]),
  children: PropTypes.any,
  className: PropTypes.string,
  flexWrap: PropTypes.oneOf(['wrap', 'nowrap', 'wrap-reverse']),
  justifyContent: PropTypes.oneOf([
    'flex-start', 'space-around', 'center', 'space-between', 'space-evenly', 'flex-end', 'stretch', 'baseline',
  ]),
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
};

Column.propTypes = {
  alignItems: PropTypes.oneOf([
    'flex-start', 'center', 'space-between', 'space-evenly', 'flex-end', 'stretch', 'baseline',
  ]),
  children: PropTypes.any,
  className: PropTypes.string,
  flexWrap: PropTypes.oneOf(['wrap', 'nowrap', 'wrap-reverse']),
  justifyContent: PropTypes.oneOf([
    'flex-start', 'space-around', 'center', 'space-between', 'space-evenly', 'flex-end', 'stretch', 'baseline',
  ]),
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
};
