import React, { Component } from 'react';
import { Input } from 'antd';

class FloatInput extends Component {
  constructor(props) {
    super(props);
    const { value = '', controlled = false } = props;
    this.state = {
      integer: controlled || value !== '' ? value : '',
    };
  }

  onChange = (e) => {
    const { value } = e.target;
    const reg = /^[0-9]*(\.)?([0-9]{1,2})?$/;
    if ((!isNaN(value) && reg.test(value)) || value === '') {
      this.props.onChange(value);
      this.setState({ integer: value });
    }
  };

  // '.' at the end or only '-' in the input box.
  onBlur = () => {
    const { onBlur, onChange } = this.props;
    const { integer } = this.state;
    let valueTemp = integer.toString();
    if (integer.toString().charAt(integer.length - 1) === '.') {
      valueTemp = integer.slice(0, -1);
    }

    const result = valueTemp.replace(/0*(\d+)/, '$1');
    onChange(result);
    this.setState({ integer: result });

    if (onBlur) {
      onBlur();
    }
  };

  render() {
    const {
      value = '', controlled = false, className = '', ...props
    } = this.props;
    const { integer } = this.state;
    return (
      <Input
        className={className}
        {...props}
        onChange={this.onChange}
        onBlur={this.onBlur}
        value={controlled ? value : integer}
        maxLength={25}
      />
    );
  }
}

export default FloatInput;
