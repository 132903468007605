import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { CaretDownOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import {
  Content, Page, PageHeader, Row, TopBar,
} from '../../../components';
import AddSupply from './AddSupply';
import SupplyTable from './SupplyTable';
import EditSupply from './EditSupply';
import { notificationTypes, showNetworkError } from '../../../redux/message/actions';
import { deleteModal } from '../../../utils/modals';
import { alphabeticalSort, getUnits, printPage } from '../../../utils/helpers';
import { isOnline } from '../../../persistance';

export const calculateSubtotal = item => parseInt(item.price || 0, 10) * parseInt(item.count || 0, 10);

class Supply extends Component {
  constructor(props) {
    super(props);
    const { supplyLoaded, dataLoaded } = props;

    this.state = {
      selectedSupply: null,
      loadingStarted: false,
      filteredInfo: {},
    };

    if (!supplyLoaded && dataLoaded) {
      this.loadData();
    }
  }

  loadData = () => {
    if (!isOnline()) return showNetworkError();
    const { actions: { getSupply } } = this.props;
    const { loadingStarted } = this.state;
    if (!loadingStarted) {
      this.setState({ loadingStarted: true });
      getSupply();
    }
  }

  calculateTotal = items => items.reduce((sum, item) => sum + parseInt(item.price, 10), 0);

  selectSupply = (selectedSupply) => {
    if (!isOnline()) return showNetworkError();
    this.setState({ selectedSupply });
    this.editSupplyModal.show();
  };

  addSupply = () => {
    if (!isOnline()) return showNetworkError();
    const { t, canInteract, actions: { showMessage } } = this.props;
    if (canInteract) {
      this.addSupplyModal.show();
    } else {
      showMessage({ type: notificationTypes.ERROR, text: t('payments.cantInteract') });
    }
  };

  handleChange = (pagination, filters) => this.setState({ filteredInfo: filters });

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { supplyLoaded, dataLoaded } = nextProps;

    if (!supplyLoaded && dataLoaded) {
      this.loadData();
    }
  }

  render() {
    const {
      t, supply, currency, menu, equipment, suppliers, actions: { removeSupply }, supplyLoaded,
    } = this.props;
    const { selectedSupply, filteredInfo } = this.state;

    const allIdMap = { ...menu.idMap, ...equipment.idMap };
    const supplyColumns = [
      {
        title: t('supply.date'),
        dataIndex: 'date',
        sorter: (a, b) => {
          const momentA = moment(`${a.date} ${a.time}`, 'DD_MM_YYYY HH:mm');
          const momentB = moment(`${b.date} ${b.time}`, 'DD_MM_YYYY HH:mm');
          return momentA.isBefore(momentB) ? -1 : 1;
        },
        sortDirections: ['descend'],
        defaultSortOrder: 'descend',
        render: (text, record) => `${text.replace(/_/g, '.')} ${record.time}`,
      }, {
        title: t('supply.supplier'),
        dataIndex: 'supplier',
        sorter: (a, b) => (-1) * a.supplier.localeCompare(b.supplier),
        sortDirections: ['descend'],
        defaultSortOrder: 'descend',
        filters: alphabeticalSort(suppliers, 'name').map(el => ({
          text: el.name, value: el.name,
        })),
        filteredValue: filteredInfo.supplier || null,
        onFilter: (value, record) => record.supplier === value,
      }, {
        title: t('supply.comment'),
        dataIndex: 'comment',
      }, {
        title: t('global.subtotal'),
        dataIndex: 'total',
        render: text => `${text} ${currency}`,
      }, {
        title: '',
        width: '30px',
        render: (text, record) => (
          <Row width="100%" justifyContent="center">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                this.selectSupply(record);
              }}
              type="link"
              icon={<EditOutlined />}
            />
          </Row>
        ),
      }, {
        title: '',
        dataIndex: 'action',
        width: '50px',
        render: (text, record) => (
          <DeleteOutlined
            onClick={(e) => {
              e.stopPropagation();
              deleteModal(() => removeSupply(record.id));
            }}
          />
        ),
      },
    ];
    return (
      <Page spinning={!supplyLoaded}>
        <PageHeader />
        <Content>
          <TopBar data-tour="supply-tour-2">
            <h3 className="storage-page-title">{t('storage.supply')}: {supply.length}</h3>
            <Button
              className="no-print right-margin"
              size="large"
              onClick={() => printPage()}
              type="secondary"
            >{t('control.print')}
            </Button>
            <Button className="add-button" size="large" onClick={this.addSupply}>{t('control.add')}</Button>
          </TopBar>
          <Table
            onChange={this.handleChange}
            bordered
            pagination={false}
            size="default"
            className="storage-page-table"
            columns={supplyColumns}
            expandRowByClick
            expandIcon={
              ({ expanded }) => <CaretDownOutlined className={`table-icon ${expanded ? 'rotate' : ''}`} />
            }
            expandedRowRender={r => (
              <SupplyTable
                items={r.items.filter(el => allIdMap[el.item]).map(el => ({
                  ...el,
                  name: allIdMap[el.item].name,
                  count: `${el.count} ${allIdMap[el.item].units ? getUnits(allIdMap[el.item].units) : t('count')}`,
                }))}
                currency={currency}
              />
            )}
            dataSource={supply.map((el, i) => ({ ...el, key: i, total: this.calculateTotal(el.items) }))}
          />
        </Content>
        <AddSupply ref={(ref) => { this.addSupplyModal = ref; }} />
        <EditSupply supply={selectedSupply} ref={(ref) => { this.editSupplyModal = ref; }} />
      </Page>
    );
  }
}

export default withTranslation()(Supply);
