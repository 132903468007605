import {
  ADD_INVENTORY, ADD_SUPPLIER, ADD_SUPPLY, ADD_WASTE, ADD_STORE_ITEM, GET_INVENTORY,
  GET_STORE, GET_SUPPLIERS, GET_SUPPLY, GET_WASTE, REMOVE_INVENTORY, REMOVE_STORE_ITEM, REMOVE_SUPPLIER,
  REMOVE_SUPPLY, REMOVE_WASTE, EDIT_WASTE, EDIT_SUPPLY, EDIT_SUPPLIER, EDIT_INVENTORY,
} from './actions';

const initialState = {
  store: [],
  supply: [],
  suppliers: [],
  waste: [],
  inventories: [],
  suppliersLoaded: false,
  supplyLoaded: false,
  wasteLoaded: false,
  inventoriesLoaded: false,
};

function storageReducer(state = initialState, action) {
  switch (action.type) {
    case GET_STORE:
      return {
        ...state,
        store: action.payload,
      };
    case ADD_STORE_ITEM:
      return {
        ...state,
        store: [...state.store, action.payload],
      };
    case REMOVE_STORE_ITEM:
      return {
        ...state,
        store: state.store.filter(el => el.id !== action.payload),
      };
    case GET_SUPPLY:
      return {
        ...state,
        supply: action.payload,
        supplyLoaded: true,
      };
    case ADD_SUPPLY:
      return {
        ...state,
        supply: [...state.supply, action.payload],
      };
    case EDIT_SUPPLY:
      return {
        ...state,
        supply: state.supply.map(el => (el.id === action.payload.id ? action.payload : el)),
      };
    case REMOVE_SUPPLY:
      return {
        ...state,
        supply: state.supply.filter(el => el.id !== action.payload),
      };
    case GET_SUPPLIERS:
      return {
        ...state,
        suppliers: action.payload,
        suppliersLoaded: true,
      };
    case ADD_SUPPLIER:
      return {
        ...state,
        suppliers: [...state.suppliers, action.payload],
      };
    case EDIT_SUPPLIER:
      return {
        ...state,
        suppliers: state.suppliers.map(el => (el.id === action.payload.id ? action.payload : el)),
      };
    case REMOVE_SUPPLIER:
      return {
        ...state,
        suppliers: state.suppliers.filter(el => el.id !== action.payload),
      };
    case GET_WASTE:
      return {
        ...state,
        waste: action.payload,
        wasteLoaded: true,
      };
    case ADD_WASTE:
      return {
        ...state,
        waste: [...state.waste, action.payload],
      };
    case EDIT_WASTE:
      return {
        ...state,
        waste: state.waste.map(el => (el.id === action.payload.id ? action.payload : el)),
      };
    case REMOVE_WASTE:
      return {
        ...state,
        waste: state.waste.filter(el => el.id !== action.payload),
      };
    case GET_INVENTORY:
      return {
        ...state,
        inventories: action.payload,
        inventoriesLoaded: true,
      };
    case ADD_INVENTORY:
      return {
        ...state,
        inventories: [...state.inventories, action.payload],
      };
    case EDIT_INVENTORY:
      return {
        ...state,
        inventories: state.inventories.map(el => (el.id === action.payload.id ? action.payload : el)),
      };
    case REMOVE_INVENTORY:
      return {
        ...state,
        inventories: state.inventories.filter(el => el.id !== action.payload),
      };
    case 'CLEAN_REDUCERS': {
      return initialState;
    }
    default: return state;
  }
}

export default storageReducer;
