import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { showMessage } from '../../../redux/message/actions';
import Subscriptions from './Subscriptions';
import { updateBusinessInfo } from '../../../redux/business/actions';
import { logout } from '../../../redux/user/actions';

function mapStateToProps(state) {
  return {
    business: state.business,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        updateBusinessInfo,
        logout,
      },
      dispatch,
    ),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Subscriptions));
