import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Column } from '../components';
import {
  getSizeName, getUnits, sizesSort, validateNumberInput,
} from '../utils/helpers';
import SelectComponent from './SelectComponent';
import NumberInput from './NumberInput';

class ComponentsTable extends Component {
  constructor(props) {
    super(props);
    const { volume, components, menu: { idMap } } = props;
    this.state = {
      components: components.map(el => ({ ...el, ...idMap[el.id] })),
      volume,
      showSelectComponent: false,
    };
  }

  toggleComponent = (component) => {
    const { callback, sizes } = this.props;
    this.setState((state) => {
      const included = state.components.map(el => el.name)
        .includes(component.name);
      return ({
        components: included
          ? state.components.filter(el => el.name !== component.name)
          : [...state.components, {
            ...component,
            count: sizes.reduce((res, key) => ({ ...res, [key]: 0 }), {}),
          }],
      });
    }, () => callback(this.state));
  };

  updateVolume = (value, size) => {
    const { callback } = this.props;
    this.setState(state => ({
      volume: { ...state.volume, [size]: parseInt(value, 10) || 0 },
    }), () => callback(this.state));
  };

  updateCount = (value, record, size) => {
    const { callback } = this.props;
    this.setState(state => ({
      components: state.components.map(item => (item.name === record.name
        ? { ...item, count: { ...item.count, [size]: parseInt(value, 10) || 0 } }
        : item
      )),
    }), () => callback(this.state));
  };

  getRecordByName = (record, size) => {
    const { t } = this.props;
    const { volume } = this.state;
    switch (record.name) {
      case t('global.volume'): return (<span className="product-modal-table-text">{`${volume[size]} ml`}</span>);
      default: return (
        <span className="product-modal-table-text">{`${record.count[size]} ${getUnits(record.units)}`}</span>
      );
    }
  };

  getFormByName = (record, size) => {
    const { volume } = this.state;
    const { t } = this.props;
    switch (record.name) {
      case t('global.volume'): return (
        <NumberInput
          onKeyUp={validateNumberInput}
          style={{ width: '100%', minWidth: '100px' }}
          placeholder="0"
          type="tel"
          onChange={e => this.updateVolume(e.target.value, size)}
          value={volume[size] === 0 ? '' : volume[size]}
          addonAfter="ml"
        />
      );
      default: return (
        <NumberInput
          onKeyUp={validateNumberInput}
          placeholder="0"
          style={{ width: '100%', minWidth: '100px' }}
          type="tel"
          onChange={e => this.updateCount(e.target.value, record, size)}
          addonAfter={getUnits(record.units)}
          value={record.count[size]}
        />
      );
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.sizes.length !== this.props.sizes.length || nextProps.inEdit !== this.props.inEdit) {
      const { components, menu: { idMap } } = nextProps;
      this.setState(state => ({
        volume: nextProps.sizes
          .reduce((res, key) => ({ ...res, [key]: state.volume[key] ? state.volume[key] : 0 }), {}),
        components: components.map(el => ({ ...el, ...idMap[el.id] })),
      }));
    }
  }

  render() {
    const {
      sizes, inEdit, t, menu, volume,
    } = this.props;
    const { components, showSelectComponent } = this.state;
    const receiptColumns = [
      {
        title: t('global.receipt'),
        dataIndex: 'name',
        width: 170,
        render: (name, record) => (record.id
          ? <Link to={{ pathname: '/app/equipment/components', state: { componentId: record.id } }}>{name}</Link>
          : name),
      },
      ...sizes.sort(sizesSort).map(size => ({
        title: getSizeName(size),
        className: 'table-header-center',
        dataIndex: size,
        // Magic for same column width, where 0.95 is 95vw, 170px is a name cell width and 48px is a container padding and 40px is a delete button cell
        width: window.innerWidth > 768 ? (window.innerWidth * 0.95 - 170 - 48 - 40) / sizes.length : 200,
        render: (text, record) => (inEdit
          ? this.getFormByName(record, size, text)
          : this.getRecordByName(record, size, text)),
      })),
    ];
    if (inEdit) {
      receiptColumns.push(
        {
          width: 40,
          render: (el, record) => (el.id ? <DeleteOutlined onClick={() => this.toggleComponent(record)} /> : ''),
        },
      );
    }
    const selectedItems = components.map(el => el.name);
    return (
      <Column width="100%" alignItems="flex-start">
        <SelectComponent
          selectedItems={selectedItems}
          callback={data => this.toggleComponent(data)}
          close={() => this.setState({ showSelectComponent: false })}
          visible={showSelectComponent}
          menu={menu}
        />
        <Table
          bordered
          pagination={false}
          size="default"
          style={{ width: '100%', overflowX: 'scroll' }}
          columns={receiptColumns}
          dataSource={[
            ...components.map(el => ({ ...el, key: el.name, name: el.name })),
            { key: t('global.volume'), name: t('global.volume'), ...volume },
          ]}
          footer={(inEdit ? () => components.length < menu.components.length && (
            <Button
              icon={<PlusOutlined />}
              onClick={() => this.setState({ showSelectComponent: true })}
            >{t('components.add')}
            </Button>
          ) : undefined)}
        />
      </Column>
    );
  }
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
  };
}

export default connect(mapStateToProps, {})(withTranslation()(ComponentsTable));
