import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Empty, Input, Modal, Select, Table,
} from 'antd';
import { Column, Row } from '../index';
import { notificationTypes } from '../../redux/message/actions';
import {
  checkIsNameExist, getDefaultImageByType, getEmptyMaterial, getSizeName, hasNullKey, sizesSort,
} from '../../utils/helpers';

const { Option } = Select;

const sizes = ['S', 'M', 'L', 'XL', 'XXL'];

class EditEquipment extends Component {
  constructor(props) {
    super(props);
    const { item } = props;
    this.state = {
      ...item,
      consumableSearch: '',
      sizeList: sizes,
      sizes: sizes.reduce((res, el) => ({ ...res, [el]: item.sizes[el] || 0 }), {}),
    };
  }

  submit = () => {
    const {
      item, callback, existedItems, close, t, actions: { showMessage },
    } = this.props;
    const {
      materials, name, sizes, sizeList,
    } = this.state;
    if (name.trim() === '') {
      showMessage({ text: t('validation.emptyName'), type: notificationTypes.ERROR });
      return;
    }
    if (hasNullKey(sizes)) {
      showMessage({ text: t('validation.emptySizes'), type: notificationTypes.ERROR });
      return;
    }
    if (materials.length === 0) {
      showMessage({ text: t('validation.pickConsumables'), type: notificationTypes.ERROR });
      return;
    }
    if (checkIsNameExist(name, existedItems.filter(el => el.id !== item.id))) {
      showMessage({ text: t('validation.uniqueConsumableName'), type: notificationTypes.ERROR });
      return;
    }

    callback({
      ...item,
      materials,
      sizes: Object.keys(sizes).reduce((res, key) => ({
        ...res, [key]: sizeList.includes(key) ? sizes[key] : 0,
      }), {}),
      name,
    });
    close();
  };

  toggleConsumable = consumable => this.setState(state => ({
    materials: state.materials.includes(consumable.id)
      ? state.materials.filter(id => id !== consumable.id)
      : [...state.materials, consumable.id],
    sizes: Object.keys(state.sizes).reduce((res, size) => ({
      ...res, [size]: state.sizes[size] === consumable.id ? null : state.sizes[size],
    }), {}),
  }));

  updateSize = (size, id) => {
    this.setState(state => ({ sizes: { ...state.sizes, [size]: id } }));
  };

  getFilteredConsumables = () => {
    const { equipment: { consumables } } = this.props;
    const { consumableSearch } = this.state;
    if (consumableSearch.trim() !== '') {
      return consumables.filter(consumable => consumable.name.toLowerCase().includes(consumableSearch.toLowerCase()));
    }
    return consumables;
  };

  render() {
    const {
      visible, t, equipment, close,
    } = this.props;
    const {
      materials, name, consumableSearch, sizeList, sizes,
    } = this.state;
    const consumables = materials.map(id => equipment.idMap[id]);
    const consumablesNames = consumables.map(consumable => consumable.name);
    const filteredConsumables = this.getFilteredConsumables();

    const calculationColumns = [
      {
        title: t('global.size'), dataIndex: 'name', width: 100, render: e => <span className="default-color">{e}</span>,
      },
      {
        title: name,
        dataIndex: 'size',
        className: 'table-header-center',
        render: (value, record) => (
          <Select
            style={{ width: '100%' }}
            placeholder={t('global.consumable')}
            onChange={e => this.updateSize(record.size, e)}
            value={sizes[value]}
          >
            {[getEmptyMaterial(t), ...consumables].map(el => (
              <Option value={el.id} key={`size-${record.size}-consumable-${el.name}`}>{el.name}</Option>
            ))}
          </Select>
        ),
      },
    ];
    return (
      <Modal
        maskClosable={false}
        centered
        width="730px"
        visible={visible}
        onOk={this.submit}
        onCancel={close}
        okButtonProps={{ size: 'large' }}
        cancelButtonProps={{ size: 'large' }}
        okText={t('control.save')}
        cancelText={t('control.cancel')}
        title={t('equipment.edit')}
        className="add-consumable"
      >
        <Row width="100%" alignItems="flex-start" className="mobile-equipment-column">
          <Column
            alignItems="flex-start"
            justifyContent="flex-start"
            width="328px"
            height="560px"
            className="equipment-content"
          >
            <h3 className="add-item-subtitle">{t('consumable.select')}</h3>
            <Input
              className="equipment-input"
              prefix={<SearchOutlined />}
              value={consumableSearch}
              onChange={e => this.setState({ consumableSearch: e.target.value })}
              style={{ width: '100%' }}
              suffix={<CloseOutlined onClick={() => this.setState({ consumableSearch: '' })} />}
            />
            {filteredConsumables.length > 0 ? (
              <Row
                width="100%"
                justifyContent="flex-start"
                flexWrap="wrap"
                margin="15px 0 0"
                style={{ maxHeight: '480px', overflowY: 'scroll' }}
              >
                {filteredConsumables.map(el => (
                  <div
                    className={`add-consumable-card ${consumablesNames.includes(el.name) ? 'checked' : ''}`}
                    key={`consumable-card-${JSON.stringify(el)}`}
                    onClick={() => this.toggleConsumable(el)}
                  >
                    <h3>{el.name}</h3>
                    <img
                      src={el.photo || getDefaultImageByType('consumable')}
                      style={{ opacity: el.photo ? 1 : 0.5 }}
                      alt=""
                      className="card-img"
                    />
                  </div>
                ))}
              </Row>
            ) : (
              <Empty />
            )}
          </Column>
          <Column
            alignItems="flex-start"
            justifyContent="flex-start"
            width="calc(100% - 358px)"
            margin="0 0 0 30px"
            style={{ paddingBottom: 0, minHeight: '560px' }}
            className="default-card equipment-content"
          >
            <h3 style={{ margin: '0 0 10px' }}>{t('equipment.kitName')}</h3>
            <Input
              style={{ width: '300px' }}
              placeholder={t('global.name')}
              onChange={e => this.setState({ name: e.target.value })}
              value={name}
              className="equipment-input"
            />
            <Table
              bordered
              pagination={false}
              size="default"
              style={{ width: '100%', overflowX: 'scroll', marginTop: '8px' }}
              columns={calculationColumns}
              dataSource={[
                ...sizeList.sort(sizesSort).map(size => ({
                  key: size,
                  size,
                  name: getSizeName(size),
                })),
              ]}
            />
          </Column>
        </Row>
        <h4 style={{ margin: '12px 0 0' }}><span className="help-text">*</span>{t('equipment.helpText')}</h4>
      </Modal>
    );
  }
}

export default withTranslation()(EditEquipment);
