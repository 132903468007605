import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Chart from 'react-chartjs-2';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { getItemsCost } from '../../../utils/helpers';

class RevenueChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartKey: 'total',
    };
  }

  getChartTitle = (key) => {
    const { t } = this.props;
    switch (key) {
      case 'total': return t('global.revenue');
      case 'gross': return t('global.gross');
      case 'bills': return t('layout.bills');
      case 'middleBill': return t('statistic.middleBill');
      default: return t('global.revenue');
    }
  };

  getChartValue = (key, data) => {
    switch (key) {
      case 'total': return data.total;
      case 'gross': return parseFloat(data.total - this.getDayCost(data.bills)).toFixed(2);
      case 'bills': return data.count;
      case 'middleBill': return parseFloat((data.total || 0) / (data.count || 1)).toFixed(2);
      default: return 0;
    }
  };

  getDayCost = (bills) => {
    const { menu, equipment } = this.props;
    const items = bills.map(bill => bill.items).flat()
      .map(el => ({ ...el, ...menu.idMap[el.id] }))
      .filter(el => menu.idMap[el.id]);
    return equipment.loading ? 0 : getItemsCost(items, menu, equipment);
  };

  getData = (canvas) => {
    const { series } = this.props;
    const { chartKey } = this.state;
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 350);
    gradient.addColorStop(0, 'rgba(0, 143, 251, 0.6)');
    gradient.addColorStop(1, 'rgba(0, 143, 251, 0)');
    return {
      labels: series.map(el => moment(el.date, 'DD.MM.YYYY').format('DD MMM')),
      datasets: [{
        lineTension: 0,
        borderColor: 'rgb(0, 143, 251)',
        pointBackgroundColor: 'rgb(0, 143, 251)',
        pointBorderColor: 'white',
        pointBorderWidth: 2,
        pointRadius: 5,
        backgroundColor: gradient,
        data: series.map(el => this.getChartValue(chartKey, el)),
      }],
    };
  };

  render() {
    const { t, series, currency } = this.props;
    const { chartKey } = this.state;

    return (
      <Fragment>
        <div id="chart" className="chart-wrapper no-bottom">
          <h3>{this.getChartTitle(chartKey)}</h3>
          <div style={{ height: '320px', width: '100%' }} className="chart-content-profit">
            <Chart
              data={this.getData}
              options={{
                datasetStrokeWidth: 3,
                pointDotStrokeWidth: 4,
                maintainAspectRatio: false,
                scales: {
                  xAxes: [{ gridLines: { display: false } }],
                  yAxes: [{
                    lineHeight: 2,
                    gridLines: { tickMarkLength: 6 },
                    ticks: { padding: 3, lineHeight: '2.2' },
                  }],
                },
                title: { display: false },
                tooltips: {
                  callbacks: {
                    title: tooltipItem => tooltipItem[0].xLabel,
                    label: tooltipItem => `${tooltipItem.yLabel.toFixed(2)} ${currency}`,
                  },
                },
                legend: { display: false },
                animation: { duration: 0 },
                hover: { animationDuration: 0 },
                responsiveAnimationDuration: 0,
              }}
              style={{ height: '100%', width: '100%' }}
              type="line"
            />
          </div>
        </div>
        <div className="stats-wrapper">
          <div
            className={`stats-cell ${chartKey === 'total' ? 'active' : ''}`}
            onClick={() => this.setState({ chartKey: 'total' })}
          >
            <h3>{series.reduce((sum, el) => parseFloat(this.getChartValue('total', el)) + sum, 0).toFixed(2)}&nbsp;
              {currency}
            </h3>
            <span>{t('global.revenue')}</span>
          </div>
          <div
            className={`stats-cell ${chartKey === 'gross' ? 'active' : ''}`}
            onClick={() => this.setState({ chartKey: 'gross' })}
          >
            <h3>{series.reduce((sum, el) => parseFloat(this.getChartValue('gross', el)) + sum, 0).toFixed(2)}&nbsp;
              {currency}
            </h3>
            <span>{t('global.gross')}</span>
          </div>
          <div
            className={`stats-cell ${chartKey === 'bills' ? 'active' : ''}`}
            onClick={() => this.setState({ chartKey: 'bills' })}
          >
            <h3>{series.reduce((sum, el) => parseInt(this.getChartValue('bills', el), 10) + sum, 0)} {t('count')}</h3>
            <span>{t('layout.bills')}</span>
          </div>
          <div
            className={`stats-cell ${chartKey === 'middleBill' ? 'active' : ''}`}
            onClick={() => this.setState({ chartKey: 'middleBill' })}
          >
            <h3>
              {(
                series.reduce((sum, el) => parseFloat(this.getChartValue('middleBill', el)) + sum, 0)
                   / (series.length - 1)
              ).toFixed(2)
              }&nbsp;
              {currency}
            </h3>
            <span>{t('statistic.middleBill')}</span>
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    equipment: state.equipment,
  };
}

export default connect(mapStateToProps, null)(withTranslation()(RevenueChart));
