import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'antd';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { ModalHOC, NumberInput } from './index';
import { notificationTypes, showMessage, showNetworkError } from '../redux/message/actions';
import { Column } from '../components';
import { addCashShift } from '../redux/shift/actions';
import { getMonthWithLocale } from '../constants/time';
import i18 from '../utils/translation';
import { validateNumberInput } from '../utils/helpers';
import { isOnline } from '../persistance';

class CloseShift extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: '',
    };
  }

  close = () => {
    if (!isOnline()) return showNetworkError();

    const {
      t, close, employer, actions: { addCashShift, showMessage }, dropEmployer,
    } = this.props;

    const { count } = this.state;

    if (count.trim() === '') {
      showMessage({ text: t('validation.emptySum'), type: notificationTypes.ERROR });
      return;
    }

    addCashShift({ endTime: Date.now(), employer, closeCount: count });

    dropEmployer();

    close();
  };

  render() {
    const {
      t, close, visible, shift: { activeShift }, employer, access: { employers }, business, resolvedBills,
    } = this.props;

    const { count } = this.state;

    const momentOpen = moment(activeShift.startTime);

    const bills = resolvedBills.filter(el => (el.id > (!activeShift ? Date.now() : activeShift.startTime)));

    const cash = bills.reduce((acc, bill) => acc + parseFloat(bill.pay.cash) - parseFloat(bill.pay.change), 0)
      .toFixed(2);

    const cashBoxConsumption = activeShift.transactions.filter(el => el.account === 0)
      .reduce((count, el) => count + parseInt(el.price, 10), 0);

    return (
      <Modal
        maskClosable={false}
        visible={visible}
        onCancel={close}
        title={t('modalMenu.closeShift')}
        width="424px"
        onOk={this.close}
        okText={t('control.apply')}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ disabled: count === 0, size: 'large', type: 'primary' }}
      >
        <Column style={{ maxWidth: '400px' }} width="100%" margin="0 auto" alignItems="flex-start">
          <Column width="100%" className="default-card">
            <h2 style={{ margin: '0', fontSize: '20px' }}>{t('modalMenu.afterCollection')}</h2>

            <h3 style={{ margin: '0 0 5px', fontSize: '14px' }}>{t('modalMenu.afterCollection2')}</h3>

            <NumberInput
              onKeyUp={validateNumberInput}
              placeholder="0"
              onPressEnter={this.close}
              type="tel"
              size="large"
              addonAfter={business.currency}
              onChange={e => this.setState({ count: e.target.value })}
            />
          </Column>

          <Column width="100%" className="default-card" margin="10px 0 0">
            <Column width="100%" margin="0 0 25px">
              <h3>{t('modalMenu.openBy')}: <b>{(employers.find(el => el.id === activeShift.openBy) || {}).name}</b></h3>

              <h3>{t('modalMenu.openTime')}:&nbsp;
                <b>{momentOpen.format('HH:mm, DD')} {getMonthWithLocale(i18.language, momentOpen.format('M'))}</b>
              </h3>

              <h3>{t('modalMenu.openCashBalance')}: <b>{activeShift.openCount} {business.currency}</b></h3>
            </Column>

            <Column width="100%">
              <h3>{t('modalMenu.closeBy')}: <b>{employer.name}</b></h3>

              <h3>{t('modalMenu.closeTime')}:&nbsp;
                <b>{moment().format('HH:mm, DD')} {getMonthWithLocale(i18.language, moment().format('M'))}</b>
              </h3>

              <h3>{t('modalMenu.expectedCashBalance')}:&nbsp;
                <b>{(parseInt(activeShift.openCount, 10)
                + parseFloat(cash) - cashBoxConsumption).toFixed(2)} {business.currency}
                </b>
              </h3>
            </Column>
          </Column>
        </Column>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    equipment: state.equipment,
    shift: state.shift,
    access: state.access,
    business: state.business,
    resolvedBills: state.statistic.resolvedBills,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        addCashShift,
      },
      dispatch,
    ),
  };
}

export default ModalHOC(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CloseShift)));
