import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button, DatePicker, Input, Modal, Table, TimePicker,
} from 'antd';
import {
  ModalHOC, NumberInput, SelectConsumableItem, DeltaText,
} from '../../../common';
import { Column, Row } from '../../../components';
import { notificationTypes, showMessage } from '../../../redux/message/actions';
import { addInventory } from '../../../redux/storage/actions';
import {
  generateId, getUnits, hasEmptyString, validateNumberInput,
} from '../../../utils/helpers';

class AddInventory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment(),
      time: moment(),
      comment: '',
      items: [],
      showSelectItem: false,
    };
  }

  getClearItem = (item = null) => {
    const { t } = this.props;
    return ({
      item: item.id,
      count: '',
      countBefore: item.count,
      timestamp: generateId(),
      units: item.units ? getUnits(item.units) : t('count'),
    });
  };

  create = () => {
    const { t, close, actions: { addInventory, showMessage } } = this.props;
    const {
      date, comment, items, time,
    } = this.state;
    if (items.length === 0) {
      showMessage({ text: t('validation.addConsumable'), type: notificationTypes.ERROR });
      return;
    }
    if (hasEmptyString(items.map(el => el.count))) {
      showMessage({ text: t('validation.addCount'), type: notificationTypes.ERROR });
      return;
    }
    addInventory({
      date: date.format('DD_MM_YYYY'),
      time: time.format('HH:MM'),
      comment,
      items: items.map(el => ({ ...el, diff: el.count === '' ? 0 : el.countBefore - parseInt(el.count, 10) })),
    });
    close();
  };

  setDate = date => this.setState({ date });

  setTime = time => this.setState({ time });

  addItem = item => this.setState(state => ({
    items: [...state.items, this.getClearItem(item)],
  }));

  updateItem = (key, value, record) => this.setState(state => ({
    items: state.items.map(el => (el.timestamp === record.key ? ({ ...el, [key]: value }) : el)),
  }));

  removeItem = timestamp => this.setState(state => ({ items: state.items.filter(el => el.timestamp !== timestamp) }));

  render() {
    const {
      t, close, menu, equipment, visible = false, currency,
    } = this.props;
    const {
      date, comment, items, time, showSelectItem,
    } = this.state;
    const allIdMap = { ...menu.idMap, ...equipment.idMap };
    const selectedItems = items.map(el => el.item);
    const inventoryColumns = [{
      title: t('global.name'),
      dataIndex: 'name',
      className: 'no-break',
      width: 'calc(100% - 400px)',
      render: (text, record) => allIdMap[record.item].name,
    }, {
      title: t('inventory.before'),
      width: '140px',
      render: (text, record) => `${record.countBefore} ${record.units}`,
    }, {
      title: t('inventory.now'),
      dataIndex: 'count',
      width: '140px',
      render: (text, record) => (
        <NumberInput
          placeholder="0"
          type="tel"
          onKeyUp={validateNumberInput}
          onChange={e => this.updateItem('count', e.target.value, record)}
          style={{ width: '140px' }}
          addonAfter={record.units}
        />
      ),
    }, {
      title: t('inventory.diff'),
      dataIndex: 'diff',
      width: '140px',
      render: (delta, record) => <DeltaText value={(-1) * delta} meta={record.units} />,
    }, {
      title: t('cost.price'),
      width: '140px',
      render: ({ diff, cost }) => {
        const price = parseFloat(-diff * cost);
        return <DeltaText value={price} meta={currency} itemRender={el => el.toFixed(2)} />;
      },
    }, {
      title: '',
      dataIndex: 'action',
      width: '50px',
      render: (text, record) => <DeleteOutlined onClick={() => this.removeItem(record.timestamp)} />,
    }];
    const filledItems = items.map(el => ({
      ...el,
      key: el.timestamp,
      diff: el.count === '' ? 0 : el.countBefore - parseInt(el.count, 10),
      cost: allIdMap[el.item].cost,
    }));
    const profit = filledItems.reduce((res, el) => res + (el.diff < 0 ? (-1) * el.diff * el.cost : 0), 0);
    const expenses = filledItems.reduce((res, el) => res + (el.diff > 0 ? (-1) * el.diff * el.cost : 0), 0);
    const result = expenses + profit;
    return (
      <Modal
        maskClosable={false}
        centered
        visible={visible}
        onOk={this.create}
        onCancel={close}
        width="100%"
        style={{ maxWidth: '800px' }}
        okText={t('control.create')}
        okButtonProps={{ size: 'large' }}
        cancelButtonProps={{ size: 'large' }}
        cancelText={t('control.cancel')}
        title={t('inventory.modalTitle')}
      >
        <Column width="100%" alignItems="flex-start">
          <Column width="100%" className="default-card">
            <h3>{t('supply.date')}</h3>
            <Row width="100%" justifyContent="flex-start">
              <DatePicker
                allowClear={false}
                style={{ width: '100%', maxWidth: '300px' }}
                value={date}
                onChange={this.setDate}
              />
              <TimePicker
                allowClear={false}
                style={{ marginLeft: '10px' }}
                value={time}
                onChange={this.setTime}
                format="HH:mm"
              />
            </Row>
            <h3 style={{ margin: '5px 0' }}>{t('supply.comment')}</h3>
            <Input
              placeholder={`${t('supply.comment')}...`}
              value={comment}
              style={{ width: '100%', maxWidth: '438px' }}
              onChange={e => this.setState({ comment: e.target.value })}
            />
          </Column>
          <Column width="100%" className="default-card" margin="12px 0 0">
            <h3>{t('layout.financial')}</h3>
            <Row width="100%" justifyContent="space-between">
              <div className="inventory-result-cell">
                <h4>{t('inventory.profit')}</h4>
                <DeltaText value={profit} meta={currency} itemRender={el => el.toFixed(2)} />
              </div>
              <div className="inventory-result-cell">
                <h4>{t('inventory.expenses')}</h4>
                <DeltaText value={expenses} meta={currency} itemRender={el => el.toFixed(2)} />
              </div>
              <div className="inventory-result-cell">
                <h4>{t('inventory.result')}</h4>
                <DeltaText value={result} meta={currency} itemRender={el => el.toFixed(2)} />
              </div>
            </Row>
          </Column>
          <Table
            bordered
            pagination={false}
            size="default"
            style={{ width: '100%', overflowX: 'scroll', margin: '12px 0 0' }}
            columns={inventoryColumns}
            dataSource={filledItems}
          />
          <Button
            icon={<PlusOutlined />}
            onClick={() => this.setState({ showSelectItem: true })}
          >{t('consumable.add')}
          </Button>


        </Column>
        <SelectConsumableItem
          selectedItems={selectedItems}
          callback={data => this.addItem(data)}
          close={() => this.setState({ showSelectItem: false })}
          visible={showSelectItem}
        />
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    equipment: state.equipment,
    currency: state.business.currency,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        addInventory,
      },
      dispatch,
    ),
  };
}

export default ModalHOC(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddInventory)));
