import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button, Input, Modal, Table,
} from 'antd';
import { Column, Row, Cost } from '../../../../components';
import { notificationTypes, showNetworkError } from '../../../../redux/message/actions';
import { getEmptyAddon, getUnits } from '../../../../utils/helpers';
import { UnitsDropdown } from '../../../../common';
import { isOnline } from '../../../../persistance';

class ComponentModal extends Component {
  constructor(props) {
    super(props);
    const { component } = props;
    this.state = {
      ...component,
      loading: false,
      inEdit: false,
      replacer: component.replacer || [],
    };
  }

  submit = () => {
    if (!isOnline()) return showNetworkError();
    const { t, component, actions: { editComponent, showMessage } } = this.props;
    const {
      name, units, replacer, cost, supplyCount, supplyPrice,
    } = this.state;
    if (name.trim() === '') {
      showMessage({ text: t('validation.emptyName'), type: notificationTypes.ERROR });
      return;
    }
    this.setState({ loading: true });
    editComponent({
      ...component, name, units, replacer, cost, supplyCount, supplyPrice,
    }).then(() => this.setState({ loading: false, inEdit: false }));
  };

  reset = () => this.setState({ ...this.props.component, loading: false, inEdit: false }, () => this.cost.reset());

  toggleAddons = (addon) => {
    const { replacer } = this.state;
    this.setState(state => ({
      replacer: replacer.includes(addon.id)
        ? state.replacer.filter(id => id !== addon.id) : [...state.replacer, addon.id],
    }));
  };

  render() {
    const {
      t, visible, close, menu: { idMap, addons }, business,
    } = this.props;
    const {
      name, units, loading, replacer, id, inEdit,
    } = this.state;
    const columns = [
      {
        title: t('global.name'),
        dataIndex: 'name',
      },
      {
        title: t('global.price'),
        dataIndex: 'price',
        width: 100,
        className: 'table-header-right',
        render: price => `${price} ${business.currency}`,
      },
    ];
    const data = [
      ...addons.filter(el => el.componentId !== id).map((el, key) => ({ ...idMap[el.componentId], ...el, key })),
      getEmptyAddon(t),
    ];
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ replacer: selectedRows.map(el => el.id) });
      },
      hideDefaultSelections: true,
      getCheckboxProps: () => ({ disabled: !inEdit }),
      selectedRowKeys: data.filter(el => replacer.includes(el.id)).map(el => el.key),
    };
    return (
      <Modal
        maskClosable={false}
        visible={visible}
        onOk={this.submit}
        onCancel={close}
        footer={null}
        title={t('global.component')}
        className="addon-modal"
        width="600px"
      >
        <Column width="100%" className="default-card">
          <h3>{t('global.name')}</h3>
          <Input
            addonAfter={
              <UnitsDropdown readOnly={!inEdit} value={units} callback={e => this.setState({ units: e })} />}
            placeholder={t('components.modalPlaceholder')}
            value={name}
            disabled={!inEdit}
            onChange={e => this.setState({ name: e.target.value })}
          />
        </Column>
        <Column alignItems="flex-start" width="100%" margin="12px 0">
          <Cost
            inEdit={inEdit}
            ref={(ref) => { this.cost = ref; }}
            {...this.state}
            addon={getUnits(units)}
            callback={data => this.setState({ ...data })}
          />
        </Column>
        <Column width="100%" className="default-card">
          {(addons.length > 0 || replacer.length > 0) && (
          <h3>{t('components.replacer')}</h3>
          )}
          <Table
            rowSelection={rowSelection}
            columns={columns}
            pagination={false}
            dataSource={data}
            style={{ width: '100%', overflowX: 'scroll' }}
            scroll={{ y: 232 }}
          />
        </Column>
        <Row width="100%" justifyContent="flex-end" margin="12px 0 0">
          {inEdit ? (
            <Fragment>
              <Button
                size="large"
                style={{ marginRight: '10px' }}
                onClick={this.reset}
                type="default"
              >{t('control.cancel')}
              </Button>
              <Button size="large" loading={loading} type="primary" onClick={this.submit}>{t('control.save')}</Button>
            </Fragment>
          ) : (
            <Button
              size="large"
              onClick={() => {
                if (!isOnline()) return showNetworkError();
                this.setState({ inEdit: true });
              }}
            >{t('control.edit')}
            </Button>
          )}
        </Row>
      </Modal>
    );
  }
}

export default withTranslation()(ComponentModal);
