import React, { Component } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { firebase } from '../../../config';
import {
  Row, PageHeader, Content, TopBar, Page,
} from '../../../components';
import AddTransaction from './AddTransaction';
import EditTransaction from './EditTransaction';
import { INCOME, TRANSFER, WASTE } from '../../../constants/transactions';
import { deleteModal } from '../../../utils/modals';
import { notificationTypes, showNetworkError } from '../../../redux/message/actions';
import { getDefaultFinancialCategories } from '../../../redux/financial/actions';
import { alphabeticalSort, printPage } from '../../../utils/helpers';
import { isOnline } from '../../../persistance';

class Transactions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItem: null,
      filteredInfo: {},
      loadingStarted: false,
    };
  }

  componentDidMount() {
    const { transactionsLoaded, dataLoaded } = this.props;

    if (!transactionsLoaded && dataLoaded) {
      this.loadData();
    }
  }

  loadData = () => {
    const { actions: { getTransactions } } = this.props;
    const { loadingStarted } = this.state;
    if (!loadingStarted) {
      this.setState({ loadingStarted: true });
      getTransactions();
    }
  }

  handleChange = (pagination, filters) => this.setState({ filteredInfo: filters });

  selectItem = (record) => {
    if (!isOnline()) return showNetworkError();
    const { financial: { transactions } } = this.props;
    const selectedItem = transactions.find(el => el.id === record.id);
    this.setState({ selectedItem });
    this.editTransactionModal.show();
  };

  addTransaction = () => {
    if (!isOnline()) return showNetworkError();
    const { t, canInteract, actions: { showMessage } } = this.props;
    if (canInteract) {
      this.addTransactionModal.show();
    } else {
      showMessage({ type: notificationTypes.ERROR, text: t('payments.cantInteract') });
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { transactionsLoaded, dataLoaded } = nextProps;

    if (!transactionsLoaded && dataLoaded) {
      this.loadData();
    }
  }

  render() {
    const {
      t, financial: {
        transactions, categories, accounts, transactionsLoaded,
      }, currency, actions: { removeTransaction },
    } = this.props;
    const { selectedItem, filteredInfo } = this.state;
    const transactionsTableColumns = [
      {
        title: t('supply.date'),
        dataIndex: 'date',
      }, {
        title: t('global.category'),
        dataIndex: 'category',
        filters: alphabeticalSort([...getDefaultFinancialCategories(t), ...categories], 'name').map(el => ({
          text: el.name, value: el.name,
        })),
        filteredValue: filteredInfo.category || null,
        onFilter: (value, record) => record.category === value,
      }, {
        title: t('supply.comment'),
        dataIndex: 'comment',
      }, {
        title: t('financial.type'),
        dataIndex: 'type',
        filters: [
          { text: t('financial.income'), value: INCOME },
          { text: t('financial.waste'), value: WASTE },
          { text: t('financial.transfer'), value: TRANSFER },
        ],
        render: type => t(`financial.${type}`),
        filteredValue: filteredInfo.type || null,
        onFilter: (value, record) => record.type === value,
      }, {
        title: t('global.sum'),
        dataIndex: 'sum',
        className: 'table-header-right',
        render: (el, record) => {
          switch (record.type) {
            case WASTE: return <span className="color-red">- {record.wasteCount} {currency}</span>;
            case INCOME: return <span className="color-green">+ {record.incomeCount} {currency}</span>;
            case TRANSFER: {
              const delta = parseInt(record.wasteCount - record.incomeCount, 10);
              return delta === 0 ? <span className="color-gray">0,00 {currency}</span> : delta < 0
                ? <span className="color-green">+ {delta} {currency}</span>
                : <span className="color-red">- {delta} {currency}</span>;
            }
            default: return <span className="color-red">+ {record.incomeCount} {currency}</span>;
          }
        },
      }, {
        title: t('financial.account2'),
        dataIndex: 'account',
        render: (el, record) => {
          switch (record.type) {
            case WASTE: return record.from.name;
            case INCOME: return record.to.name;
            case TRANSFER: return `${record.from.name}  ->  ${record.to.name}`;
            default: return record.to;
          }
        },
        filters: accounts.map(el => ({
          text: el.name, value: el.name,
        })),
        filteredValue: filteredInfo.account || null,
        onFilter: (value, record) => `${record.to ? record.to.name : ''} ${record.from ? record.from.name : ''}`
          .includes(value),

      }, {
        title: '',
        width: '30px',
        render: (text, record) => (
          <Row width="100%" justifyContent="center">
            <Button onClick={() => this.selectItem(record)} type="link" icon={<EditOutlined />} />
          </Row>
        ),
      }, {
        title: '',
        width: '30px',
        render: (text, record) => (
          <Row width="100%" justifyContent="center">
            <Button
              onClick={() => deleteModal(() => removeTransaction(record.id))}
              type="link"
              icon={<DeleteOutlined />}
            />
          </Row>
        ),
      },
    ];
    return (
      <Page spinning={!firebase.auth().currentUser || !transactionsLoaded}>
        <PageHeader />
        <Content>
          <TopBar data-tour="financialTransactions-tour-2">
            <h3 className="storage-page-title">{t('layout.transactions')}: {transactions.length}</h3>
            <Button
              className="no-print right-margin"
              size="large"
              onClick={printPage}
              type="secondary"
            >{t('control.print')}
            </Button>
            <Button
              className="add-button"
              size="large"
              type="default"
              onClick={this.addTransaction}
            >
              {t('control.add')}
            </Button>
          </TopBar>
          <Table
            bordered
            pagination={{
              pageSize: 20,
              hideOnSinglePage: true,
              showSizeChanger: false,
            }}
            size="default"
            className="storage-page-table"
            columns={transactionsTableColumns}
            dataSource={transactions.map((el, index) => ({
              ...el, category: el.type === TRANSFER ? t(`financial.${TRANSFER}`) : el.category, key: index,
            })).sort((a, b) => {
              const delta = moment(a.date, 'DD.MM.YYYY').valueOf() - moment(b.date, 'DD.MM.YYYY').valueOf();
              if (delta > 0) return -1;
              if (delta < 0) return 1;
              if (a.id > b.id) return -1;
              return 1;
            })}
            onChange={this.handleChange}
          />
        </Content>
        <AddTransaction ref={(ref) => { this.addTransactionModal = ref; }} />
        <EditTransaction item={selectedItem} ref={(ref) => { this.editTransactionModal = ref; }} />
      </Page>
    );
  }
}

export default withTranslation()(Transactions);
