import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { IdcardOutlined, MessageOutlined, PhoneOutlined } from '@ant-design/icons';
import { Input, Modal } from 'antd';
import { ModalHOC } from '../../../common';
import { Column } from '../../../components';
import { notificationTypes, showMessage } from '../../../redux/message/actions';
import { addSupplier } from '../../../redux/storage/actions';

class AddSupplier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      phone: '',
      meta: '',
    };
  }

  create = () => {
    const { t, close, actions: { addSupplier, showMessage } } = this.props;
    const { name, phone, meta } = this.state;
    if (name.trim() === '') {
      showMessage({ text: t('validation.emptyName'), type: notificationTypes.ERROR });
      return;
    }
    addSupplier({ name, phone, meta });
    close();
  };

  render() {
    const {
      t, close, visible = false,
    } = this.props;
    const { name, phone, meta } = this.state;

    return (
      <Modal
        maskClosable={false}
        centered
        visible={visible}
        onOk={this.create}
        onCancel={close}
        width="100%"
        style={{ maxWidth: '450px' }}
        okText={t('control.create')}
        okButtonProps={{ size: 'large' }}
        cancelButtonProps={{ size: 'large' }}
        cancelText={t('control.cancel')}
        title={t('supplier.modalTitle')}
      >
        <Column width="100%" alignItems="flex-start">
          <Column width="100%" className="default-card">
            <h3 style={{ margin: '5px 0' }}>{t('supply.supplier')}</h3>
            <Input
              style={{ width: '100%' }}
              addonAfter={<IdcardOutlined />}
              placeholder={t('global.name')}
              value={name}
              onChange={e => this.setState({ name: e.target.value })}
            />
            <h3 style={{ margin: '5px 0' }}>{t('landing.phone')}</h3>
            <Input
              style={{ width: '100%' }}
              addonAfter={<PhoneOutlined />}
              placeholder="+380.."
              value={phone}
              onChange={e => this.setState({ phone: e.target.value })}
            />
            <h3 style={{ margin: '5px 0' }}>{t('supply.comment')}</h3>
            <Input
              style={{ width: '100%' }}
              addonAfter={<MessageOutlined />}
              value={meta}
              placeholder={`${t('supply.comment')}...`}
              onChange={e => this.setState({ meta: e.target.value })}
            />
          </Column>
        </Column>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        addSupplier,
      },
      dispatch,
    ),
  };
}

export default ModalHOC(connect(null, mapDispatchToProps)(withTranslation()(AddSupplier)));
