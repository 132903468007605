import React from 'react';
import moment from 'moment';
import { get as dbGet, set as dbSet } from 'idb-keyval';
import AdminPasswordModal from '../../common/AdminPasswordModal';
import { idb } from '../../config';
import i18n from '../../utils/translation';
import ChoosePlace from '../../common/ChoosePlace';
import { createPlaceRequest, getPlaceDataRequest } from '../../api/db/business';
import { showErrorMessage } from '../message/actions';

/**
 * Create new place in firebase
 * @param uid
 * @param data
 * @returns {Promise<unknown>}
 */
export const createPlace = (uid, data) => new Promise((resolve) => {
  const cloneData = { ...data };
  delete cloneData.places;
  createPlaceRequest({
    ...cloneData,
    completeFirstView: false,
    businessId: uid,
    isFreeTrial: true,
    shiftAutoClose: '00.00',
    payedFor: moment().add(2, 'week').format('DD_MM_YYYY'),
  })
    .then(res => resolve(res.data))
    .catch(showErrorMessage);
});

/**
 * Admin password modal
 * @param closable
 * @param callback
 * @param showMessage
 * @returns {{keyboard: boolean, okButtonProps: {style: {display: string}}, centered: boolean, cancelText: string, width: string, className: string, title: string, maskClosable: boolean, okText: string, content: *}}
 */
export const getAdminPasswordModalProps = (closable = false, callback, showMessage) => ({
  title: '',
  maskClosable: false,
  centered: true,
  keyboard: false,
  className: 'modal-signUp',
  cancelText: '',
  okText: '',
  width: '440px',
  okButtonProps: { style: { display: 'none' } },
  content: <AdminPasswordModal closable={closable} callback={callback} showMessage={showMessage} />,
});

/**
 * Select place modal
 * @param places
 * @param callback
 * @returns {{keyboard: boolean, okButtonProps: {style: {display: string}}, centered: boolean, cancelText: string, width: string, className: string, title: string, maskClosable: boolean, okText: string, content: *}}
 */
export const selectPlaceModalProps = (places, callback) => ({
  title: i18n.t('settings.choosePlace'),
  maskClosable: false,
  centered: true,
  keyboard: false,
  className: 'modal-signUp places-modal',
  cancelText: '',
  okText: '',
  width: '528px',
  okButtonProps: { style: { display: 'none' } },
  content: <ChoosePlace places={places} callback={callback} />,
});

/**
 * Get place data from firebase
 * @param id
 * @returns {Promise<{}>}
 */
export const getPlaceData = id => getPlaceDataRequest(id);

/**
 * Get places data from places id
 * @param places
 * @param callback
 * @returns {Promise<[{}]>}
 */
export const getPlacesData = (places, callback = () => {}) => Promise.all(places.map(place => getPlaceData(place)))
  .then(res => callback(res.map(el => el.data)));

/**
 * Get placeId from IDB
 * @returns {Promise<string>}
 */
export const getPlaceRefFromDB = () => dbGet('placeId', idb);

/**
 * Set placeId to IDB
 * @param id
 * @returns {Promise<void>}
 */
export const setPlaceRefToDB = id => dbSet('placeId', id, idb);
