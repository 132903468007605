import React, { Component } from 'react';
import { Button, Spin, Table } from 'antd';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { firebase } from '../../../config';
import {
  Column, Content, Page, PageHeader, Row, TopBar,
} from '../../../components';
import { RangePicker } from '../../../common';
import { MONTH } from '../../../common/RangePicker';
import {
  calculateSale, calculateTotal, getDayStatisticFromSortedBills, printPage, sortBillsByDate,
} from '../../../utils/helpers';
import { getBillsFromDayRange } from '../../../redux/statistic/helpers';
import { isOnline } from '../../../persistance';
import { showNetworkError } from '../../../redux/message/actions';

class Payments extends Component {
  constructor(props) {
    super(props);
    const { resolvedBills = [] } = this.props;
    this.state = {
      bills: resolvedBills,
      pending: false,
      rangeStart: moment().subtract(1, 'week'),
      rangeEnd: moment(),
    };
  }

  componentDidMount() {
    const { activePlace } = this.props;

    if (activePlace) {
      this.updateRange(this.state);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { activePlace, dataLoaded } = nextProps;
    if ((this.props.activePlace !== activePlace && dataLoaded) || this.props.dataLoaded !== dataLoaded) {
      this.updateRange({ ...this.state });
    }
  }

  updateRange = ({ rangeStart, rangeEnd }) => {
    if (!isOnline()) return showNetworkError();
    this.setState({ rangeStart, rangeEnd, pending: true });
    getBillsFromDayRange(rangeStart, rangeEnd)
      .then((bills = []) => this.setState({ bills, pending: false }));
  };

  render() {
    const { t, currency } = this.props;
    const {
      bills, pending, rangeStart, rangeEnd,
    } = this.state;
    const billsColumns = [
      {
        title: t('supply.date'), dataIndex: 'date', width: 120,
      },
      {
        title: t('bill.count'), dataIndex: 'count', width: 120, render: count => `${count} ${t('count')}`,
      },
      {
        title: t('pay.cash'),
        dataIndex: 'cash',
        width: 120,
        render: text => `${text} ${currency}`,
      },
      {
        title: t('pay.card'), dataIndex: 'card', width: 120, render: text => `${text || 0} ${currency}`,
      },
      {
        title: t('pay.byBonus'), dataIndex: 'bonus', width: 120, render: text => `${text || 0} ${currency}`,
      },
      {
        title: t('global.sale'), dataIndex: 'sale', width: 80, render: sale => `${sale} ${currency}`,
      },
      {
        title: t('global.sum'), dataIndex: 'total', width: 120, render: price => `${price} ${currency}`,
      },
    ];
    const uniqBills = Array.from(new Set(bills.map(el => el.id))).map(id => bills.find(el => el.id === id));
    const dates = sortBillsByDate(uniqBills, rangeStart, rangeEnd);
    const statistic = {
      count: uniqBills.length,
      cash: uniqBills.reduce((acc, bill) => acc + parseFloat(bill.pay.cash) - parseFloat(bill.pay.change), 0)
        .toFixed(2),
      card: uniqBills.reduce((acc, bill) => acc + parseFloat(bill.pay.card), 0).toFixed(2),
      bonus: bills.reduce((acc, bill) => acc + parseFloat(bill.pay.bonus || 0), 0).toFixed(2),
      total: uniqBills.reduce((acc, bill) => acc + parseFloat(calculateTotal(bill)), 0).toFixed(2),
      sale: uniqBills.reduce((acc, bill) => acc + parseFloat(calculateSale(bill)), 0).toFixed(2),
    };

    return (
      <Page spinning={!firebase.auth().currentUser}>
        <PageHeader />
        <Content>
          <TopBar data-tour="component-tour-2">
            <h3 className="storage-page-title">{t('layout.payments')}</h3>
            <Button
              className="no-print mobile-bottom-margin mobile-right-margin"
              size="large"
              onClick={() => printPage()}
              type="secondary"
            >{t('control.print')}
            </Button>
            <RangePicker
              shouldCheckPermission
              callback={this.updateRange}
              ranges={[MONTH]}
              rangeStart={moment().subtract(1, 'week')}
            />
          </TopBar>
          <Spin wrapperClassName="storage-page-section" spinning={pending}>
            <Row className="today mobile-column-payments" width="100%" margin="0 0 10px" height="74px">
              <h3>{t('global.sum')}</h3>
              <Column className="mobile-row-payments">
                <h3>{statistic.count} {t('count')}</h3>
                <span>{t('bill.count')}</span>
              </Column>
              <Column className="mobile-row-payments">
                <h3>{statistic.cash} {currency}</h3>
                <span>{t('pay.cash')}</span>
              </Column>
              <Column className="mobile-row-payments">
                <h3>{statistic.card || 0} {currency}</h3>
                <span>{t('pay.card')}</span>
              </Column>
              <Column className="mobile-row-payments">
                <h3>{statistic.bonus || 0} {currency}</h3>
                <span>{t('pay.byBonus')}</span>
              </Column>
              <Column className="mobile-row-payments">
                <h3>{statistic.sale} {currency}</h3>
                <span>{t('global.sale')}</span>
              </Column>
              <Column className="mobile-row-payments">
                <h3>{statistic.total} {currency}</h3>
                <span>{t('global.sum')}</span>
              </Column>
            </Row>
            <Table
              bordered
              pagination={false}
              size="default"
              className="storage-page-table"
              columns={billsColumns}
              dataSource={getDayStatisticFromSortedBills(dates.reverse())}
            />
          </Spin>
        </Content>
      </Page>
    );
  }
}

export default withTranslation()(Payments);
