import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
  DeleteOutlined, EditOutlined, EyeInvisibleOutlined, EyeOutlined,
} from '@ant-design/icons';
import {
  Button, Input, Modal, Table,
} from 'antd';
import { Row, Column } from '../index';
import { notificationTypes, showNetworkError } from '../../redux/message/actions';
import './style.scss';
import { alphabeticalSort } from '../../utils/helpers';
import { deleteModal } from '../../utils/modals';
import { isOnline } from '../../persistance';

class ManageCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
      loadingCreation: false,
      loadingEdit: false,
      selectedCategory: null,
      newCategoryName: '',
      showEditModal: false,
    };
  }

  handleInputChange = e => this.setState({ inputValue: e.target.value });

  handleInputConfirm = () => {
    if (!isOnline()) return showNetworkError();
    const { t, menu: { categories }, actions: { showMessage } } = this.props;
    const { inputValue } = this.state;
    if (!inputValue || inputValue.trim() === '') {
      showMessage({ text: t('validation.emptyName'), type: notificationTypes.ERROR });
      return;
    }
    if (categories.find(el => el.name === inputValue)) {
      showMessage({ text: t('category.alreadyExist'), type: notificationTypes.ERROR });
      return;
    }
    this.setState({ loadingCreation: true });
    const { actions: { createCategory } } = this.props;
    createCategory({ name: inputValue, visible: true }).then(
      () => this.setState({ loadingCreation: false, inputValue: '' }),
    );
  };

  saveInputRef = (input) => { this.input = input; };

  close = () => {
    const { close } = this.props;
    close();
  };

  showCategoryModal = category => this.setState({
    selectedCategory: category, newCategoryName: category.name, showEditModal: true,
  });

  editCategory = () => {
    if (!isOnline()) return showNetworkError();
    const { actions: { editCategory, showMessage }, t, menu: { categories } } = this.props;
    const { selectedCategory, newCategoryName } = this.state;
    if (!newCategoryName || newCategoryName.trim() === '') {
      showMessage({ text: t('validation.emptyName'), type: notificationTypes.ERROR });
      return;
    }
    if (categories.find(el => el.name === newCategoryName)) {
      showMessage({ text: t('category.alreadyExist'), type: notificationTypes.ERROR });
      return;
    }
    this.setState({ loadingEdit: true });
    editCategory(selectedCategory, { ...selectedCategory, name: newCategoryName }).then(
      () => this.setState({ loadingEdit: false, showEditModal: false }),
    );
  };

  render() {
    const {
      t, visible, menu: { categories }, actions: { removeCategory, toggleCategoryVisible },
    } = this.props;
    const {
      inputValue, loadingCreation, newCategoryName, showEditModal, loadingEdit,
    } = this.state;
    const categoriesTableColumns = [{
      title: t('global.name'),
      dataIndex: 'name',
      width: 'calc(100% - 400px)',
    }, {
      title: '',
      width: '60px',
      render: (text, record) => (
        <Row width="100%" justifyContent="center">
          <Button onClick={() => this.showCategoryModal(record)} type="link" icon={<EditOutlined />} />
        </Row>
      ),
    }, {
      title: '',
      width: '60px',
      render: (text, record) => (
        <Row width="100%" justifyContent="center">
          <Button
            onClick={() => {
              if (!isOnline()) return showNetworkError();
              toggleCategoryVisible(record, !record.visible);
            }}
            type="link"
            icon={record.visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          />
        </Row>
      ),
    }, {
      title: '',
      width: '60px',
      render: (text, record) => (
        <Row width="100%" justifyContent="center">
          <Button
            onClick={() => deleteModal(() => removeCategory(record))}
            type="link"
            icon={<DeleteOutlined />}
          />
        </Row>
      ),
    },
    ];
    return (
      <Modal
        maskClosable={false}
        visible={visible}
        onCancel={this.close}
        cancelText={t('control.close')}
        cancelButtonProps={{ size: 'large' }}
        okButtonProps={{ style: { display: 'none' } }}
        width="520px"
        style={{ top: '50px' }}
        title={t('categories.modalTitle')}
      >
        <Column style={{ maxWidth: '400px' }} width="100%" alignItems="flex-start" margin="0 auto">
          <h2 style={{ margin: '0 0 10px' }}>{t('categories.add')}</h2>
          <Row width="100%">
            <Input
              style={{ width: '300px' }}
              placeholder={t('global.name')}
              onChange={this.handleInputChange}
              value={inputValue}
              size="large"
            />
            <Button
              type="primary"
              size="large"
              style={{ margin: '0 0 0 24px', width: '120px' }}
              onClick={this.handleInputConfirm}
              loading={loadingCreation}
            >
              {t('control.save')}
            </Button>
          </Row>
          <h2 style={{ margin: '18px 0 10px' }}>{t('category.list')}</h2>

          <Table
            bordered
            pagination={false}
            size="default"
            className="manage-categories-table"
            showHeader={false}
            style={{ width: '100%', overflowX: 'scroll', margin: '0 0 10px' }}
            columns={categoriesTableColumns}
            dataSource={alphabeticalSort(categories || [], 'name').map((el, index) => ({
              ...el, key: index,
            }))}
          />
          <Modal
            maskClosable={false}
            style={{ top: '50px' }}
            visible={showEditModal}
            onCancel={() => this.setState({ showEditModal: false })}
            width="520px"
            title={t('categories.modalTitle')}
            footer={null}
          >
            <Column style={{ maxWidth: '400px' }} width="100%" alignItems="flex-start" margin="0 auto">
              <h2 style={{ margin: '0 0 10px' }}>{t('categories.edit')}</h2>
              <Row width="100%">
                <Input
                  style={{ width: 'calc(100% - 144px)' }}
                  placeholder={t('global.name')}
                  onChange={e => this.setState({ newCategoryName: e.target.value })}
                  value={newCategoryName}
                  size="large"
                />
                <Button
                  type="primary"
                  size="large"
                  style={{ margin: '0 0 0 24px', width: '120px' }}
                  onClick={this.editCategory}
                  loading={loadingEdit}
                >
                  {t('control.save')}
                </Button>
              </Row>
            </Column>
          </Modal>
        </Column>
      </Modal>
    );
  }
}

export default withTranslation()(ManageCategories);
