import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { addCommentToBill } from '../../redux/statistic/actions';
import CommentForBill from './CommentForBill';
import ModalHOC from '../../common/ModalHOC';

function mapStateToProps(state) {
  return {
    activeKey: state.statistic.activeKey,
    activeBills: state.statistic.activeBills,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        addCommentToBill,
      },
      dispatch,
    ),
  };
}

export default ModalHOC(withRouter(connect(mapStateToProps, mapDispatchToProps)(CommentForBill)));
