import {
  del, get as dbGet, keys, set as dbSet,
} from 'idb-keyval';
import axios from 'axios';
import {
  idbAccessEmployers,
  idbAccessJobs,
  idbBills,
  idbBusiness,
  idbCommunication,
  idbConsumables,
  idbEquipment,
  idbFinancialAccount,
  idbFinancialCategory, idbFinancialTransaction,
  idbInfo,
  idbKeeper,
  idbMarketingClients,
  idbMarketingGroups,
  idbMarketingLoyalty,
  idbMarketingSales,
  idbMenuAddons,
  idbMenuCategories,
  idbMenuComponents,
  idbMenuItems,
  idbMenuProducts,
  idbPayments,
  idbPlace,
  idbShifts,
  idbStoreInventory,
  idbStoreStorage,
  idbStoreSuppliers,
  idbStoreSupply,
  idbStoreWaste,
  idbUpdates,
} from '../config';
import { apiUrl } from '../api';

// Interface
export const isOnline = () => !!navigator?.onLine;

export const persist = (data, db) => dbSet(data.id, data, db);

export const persistWithKey = (key, data, db) => dbSet(key, data, db);

export const removeDataFromPersist = (id, db) => del(id, db);

export const getDataFromPersist = (id, db) => dbGet(id, db);

export const getPersistedKeys = db => keys(db);

export const cleanPersistedDB = db => getPersistedKeys(db)
  .then(keys => Promise.all(keys.map(key => removeDataFromPersist(key, db))));

// Bills
export const persistBill = bill => persist(bill, idbBills);

export const removeBillFromPersist = id => removeDataFromPersist(id, idbBills);

export const getBillFromPersist = id => getDataFromPersist(id, idbBills);

export const getPersistedBills = () => getPersistedKeys(idbBills);

export const cleanBillPersist = () => cleanPersistedDB(idbBills);

// Shifts
export const persistShifts = shift => persist(shift, idbShifts);

export const removeShiftsFromPersist = id => removeDataFromPersist(id, idbShifts);

export const getShiftsFromPersist = id => getDataFromPersist(id, idbShifts);

export const getPersistedShifts = () => getPersistedKeys(idbShifts);

export const cleanShiftsPersist = () => cleanPersistedDB(idbShifts);

export const cleanPersistedBillsShifts = () => Promise.all([cleanBillPersist, cleanShiftsPersist].map(func => func()));

// All

export const syncBillsData = async (placeRef) => {
  const billsKeys = await getPersistedBills();
  const bills = await Promise.all(billsKeys.map(key => getBillFromPersist(key)));
  const filteredBills = bills.filter(el => !el.sync);
  // Slice bills array to 10 pc per request to decrease 1 request size
  const slices = [];
  for (let i = 0; i < filteredBills.length; i += 10) {
    slices.push(filteredBills.slice(i, i + 10));
  }

  return new Promise((resolve) => {
    Promise
      .all(slices.map(slice => axios.post(`${apiUrl}/persist-bills/${placeRef}`, { bills: slice })))
      .then((res) => {
        res.forEach(response => response.data.forEach(async (id) => {
          const bill = await getBillFromPersist(id);
          persistBill({ ...bill, sync: true, modified: false });
        }));
        resolve();
      })
      .catch((e) => {
        console.log(e);
      });
  });
};

export const syncShiftsData = async (placeRef) => {
  const shiftsKeys = await getPersistedShifts();
  const shifts = await Promise.all(shiftsKeys.map(key => getShiftsFromPersist(key)));
  return new Promise((resolve) => {
    axios.post(`${apiUrl}/persist-shifts/${placeRef}`, { shifts })
      .then((res) => {
        res.data.forEach(el => removeDataFromPersist(el, idbShifts));
        resolve();
      })
      .catch((e) => {
        console.log(e);
      });
  });
};

export const getPersistedData = async (iDB) => {
  const keys = await getPersistedKeys(iDB);
  return Promise.all(keys.map(key => getDataFromPersist(key, iDB)));
};

export const getPersistedShiftsData = () => getPersistedData(idbShifts);

export const getPersistedBillsData = () => getPersistedData(idbBills);

export const getPersistedAccessEmployers = () => getPersistedData(idbAccessEmployers);

export const getPersistedAccessJobs = () => getPersistedData(idbAccessJobs);

export const getPersistedPayments = () => getPersistedData(idbPayments);

export const getPersistedMarketingClients = () => getPersistedData(idbMarketingClients);

export const getPersistedMarketingGroups = () => getPersistedData(idbMarketingGroups);

export const getPersistedMarketingSales = () => getPersistedData(idbMarketingSales);

export const getPersistedConsumables = () => getPersistedData(idbConsumables);

export const getPersistedEquipment = () => getPersistedData(idbEquipment);

export const getPersistedFinancialAccount = () => getPersistedData(idbFinancialAccount);

export const getPersistedFinancialTransaction = () => getPersistedData(idbFinancialTransaction);

export const getPersistedFinancialCategory = () => getPersistedData(idbFinancialCategory);

export const getPersistedMenuAddons = () => getPersistedData(idbMenuAddons);

export const getPersistedMenuCategories = () => getPersistedData(idbMenuCategories);

export const getPersistedMenuComponents = () => getPersistedData(idbMenuComponents);

export const getPersistedMenuProducts = () => getPersistedData(idbMenuProducts);

export const getPersistedMenuItems = () => getPersistedData(idbMenuItems);

export const getPersistedStoreSuppliers = () => getPersistedData(idbStoreSuppliers);

export const getPersistedStoreSupply = () => getPersistedData(idbStoreSupply);

export const getPersistedStoreWaste = () => getPersistedData(idbStoreWaste);

export const getPersistedStoreInventory = () => getPersistedData(idbStoreInventory);

export const getPersistedStoreStorage = () => getPersistedData(idbStoreStorage);

export const getPersistedCommunication = () => getDataFromPersist('communication', idbCommunication);

export const getPersistedKeeperSettings = () => getDataFromPersist('keeper', idbKeeper);

export const getPersistedMarketingLoyalty = () => getDataFromPersist('marketing-loyalty', idbMarketingLoyalty);

export const getPersistedUpdates = () => getDataFromPersist('updates', idbUpdates);

export const getPersistedInfo = () => getDataFromPersist('info', idbInfo);

export const getPersistedBusiness = () => getDataFromPersist('business', idbBusiness);

export const getPersistedPlace = key => getDataFromPersist(key, idbPlace);

const collections = [idbAccessEmployers, idbAccessJobs, idbBusiness, idbCommunication, idbConsumables, idbEquipment,
  idbFinancialAccount, idbFinancialCategory, idbInfo, idbKeeper, idbMarketingClients, idbMarketingGroups,
  idbMarketingLoyalty, idbMenuAddons, idbMenuCategories, idbMenuComponents, idbMenuItems, idbMenuProducts,
  idbPayments, idbPlace, idbStoreInventory, idbStoreStorage, idbStoreSuppliers, idbMarketingSales,
  idbStoreSupply, idbStoreWaste, idbUpdates];

export const cleanAllPersistedCollection = () => Promise.all(collections
  .map(el => cleanPersistedDB(el)));
