import {
  GET_COMMUNICATION, EDIT_COMMUNICATION, ADD_PERSONAL_ID, GET_NOTIFICATIONS, ADD_NOTIFICATION,
} from './actions';

export const initialState = {
  personalId: null,
  allowedEvents: {
    open_shift: false,
    close_shift: false,
    add_transaction: false,
    add_supply: false,
  },
  notifications: [],
};

function communicationReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COMMUNICATION:
      return {
        ...state,
        ...action.payload,
      };
    case EDIT_COMMUNICATION:
      return {
        ...state,
        ...action.payload,
      };
    case ADD_PERSONAL_ID:
      return {
        ...state,
        personalId: action.payload,
      };
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
      };
    case 'CLEAN_REDUCERS': {
      return initialState;
    }
    default: return state;
  }
}

export default communicationReducer;
