import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  removeProduct, editProduct, toggleFavouritesProduct,
} from '../../redux/menu/actions';
import { ModalHOC } from '../../common';
import ProductModal from './ProductModal';
import { showMessage } from '../../redux/message/actions';

function mapStateToProps(state) {
  return {
    menu: state.menu,
    equipment: state.equipment,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        toggleFavourites: toggleFavouritesProduct,
        removeProduct,
        editProduct,
        showMessage,
      },
      dispatch,
    ),
  };
}

export default ModalHOC(connect(mapStateToProps, mapDispatchToProps)(ProductModal));
