import React, { Component, Fragment } from 'react';
import { Empty, Modal } from 'antd';
import { withTranslation } from 'react-i18next';
import ItemList from './ItemList';
import { alphabeticalSort } from '../utils/helpers';

class SelectComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  selectItem = (item) => {
    const { callback } = this.props;
    callback(item);
  };

  render() {
    const {
      t, menu, visible = false, close, selectedItems,
    } = this.props;
    const componentsToSelect = alphabeticalSort(menu.components.filter(el => !selectedItems.includes(el.name)));
    return (
      <Modal
        maskClosable={false}
        footer={null}
        title={t('addon.modalSelect')}
        visible={visible}
        width="100%"
        style={{ maxWidth: '800px' }}
        onCancel={close}
      >
        <Fragment>
          {componentsToSelect.length > 0 && (
          <ItemList
            items={componentsToSelect}
            withFavourites={false}
            callback={data => this.selectItem(data)}
          />
          )}
        </Fragment>
        {componentsToSelect.length === 0 && (
          <Empty />
        )}
      </Modal>
    );
  }
}

export default withTranslation()(SelectComponent);
