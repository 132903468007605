import axios from '../index';

// Bills flow

export const getResolvedBillsRequest = () => axios.get('/db/bills');

export const getBillsByDateRequest = date => axios.get(`/db/bills/day/${date}`);

export const editBillRequest = (id, date, data) => axios.put(`/db/bills/day/${date}`, { id, data });

export const addBillRequest = (id, date, data) => axios.post(`/db/bills/day/${date}`, { id, data });

export const syncBillWithCheckboxRequest = (id, date, billId) => axios
  .post('/db/bills/checkbox/sync', { id, date, billId });
