import React, { Component } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { setEmployer } from '../../../redux/keeper/actions';
import { SearchModal } from '../../../components';

class MenuSearchButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearchModal: false,
    };
  }

  toggleSearch = () => this.setState(state => ({ showSearchModal: !state.showSearchModal }));

  render() {
    const { t, selectItem } = this.props;
    const { showSearchModal } = this.state;

    return (
      <>
        <Button
          size="large"
          icon={<SearchOutlined />}
          onClick={this.toggleSearch}
          className="keeper-search"
        >
          {t('global.search')}
        </Button>
        {showSearchModal && (
          <SearchModal callback={selectItem} close={this.toggleSearch} />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    activeEmployer: state.keeper.activeEmployer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setEmployer,
      },
      dispatch,
    ),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MenuSearchButton)));
