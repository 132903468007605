import React from 'react';
import { StepInfo } from '../index';

export const checkForElementExist = (selector, callback, interval = 100, callbackTimeout = 200) => {
  const isModalOpen = document.querySelectorAll(selector).length > 0;
  if (isModalOpen) {
    setTimeout(() => callback(), callbackTimeout);
  } else {
    setTimeout(() => checkForElementExist(selector, callback), interval);
  }
};

export const firstViewTourSteps = (t, goToNextStep, lastStepEvent, history) => [
  {
    selector: '[data-tour="firstView-tour-1"]',
    stepInteraction: false,
    content: () => (
      <StepInfo
        title={t('reactour.firstView.step1.title')}
        text={t('reactour.firstView.step1.text')}
        buttonText={t('control.start')}
        buttonAction={() => goToNextStep(false)}
        skipText={t('reactour.firstView.step1.skipText')}
        skipAction={() => lastStepEvent()}
      />
    ),
  }, {
    selector: '.ant-spin-container',
    stepInteraction: false,
    content: () => (
      <StepInfo
        title={t('reactour.firstView.step2.title')}
        text={t('reactour.firstView.step2.text')}
        buttonText={t('reactour.firstView.step2.buttonText')}
        buttonAction={() => {
          const layoutSelector = document.querySelector('[data-tour="access-tour-0"]');
          layoutSelector.click();
          const layoutMenu = document.querySelector('.layout-menu');
          if (layoutMenu.classList.contains('collapsed')) {
            document.querySelector('.layout-collapse-button').click();
          }
          setTimeout(() => goToNextStep(false), 300);
        }}
      />
    ),
  }, {
    selector: '[data-tour="employers-tour-1"]',
    stepInteraction: false,
    content: () => (
      <StepInfo
        title={t('reactour.employers.step1.title')}
        text={t('reactour.employers.step1.text')}
        buttonText={t('reactour.employers.step1.buttonText')}
        buttonAction={() => {
          history.push('/app/access/employers');
          const layoutMenu = document.querySelector('.layout-menu');
          if (!layoutMenu.classList.contains('collapsed')) {
            document.querySelector('.layout-collapse-button').click();
          }
          setTimeout(() => goToNextStep(false), 300);
        }}
      />
    ),
  }, {
    selector: '.storage-page-content',
    stepInteraction: false,
    position: 'bottom',
    content: () => (
      <StepInfo
        title={t('reactour.firstView.step3.title')}
        text={t('reactour.firstView.step3.text')}
        buttonText={t('reactour.firstView.step3.buttonText')}
        buttonAction={() => {
          history.push('/app/dashboard');
          setTimeout(() => goToNextStep(false), 200);
        }}
      />
    ),
  }, /* {
    selector: '.ant-spin-container',
    stepInteraction: true,
    position: 'left',
    action: () => {
      document.querySelector('.reactour__helper > button').style.visibility = 'hidden';
      if (document.getElementsByClassName('ant-modal').length === 0) {
        checkForElementExist('.ant-modal .ant-btn-lg', () => goToNextStep(false));
      }
    },
    content: () => (
      <StepInfo
        title={t('reactour.firstView.step4.title')}
        text={t('reactour.firstView.step4.text')}
      />
    ),
  }, */{
    selector: '.layout-content',
    stepInteraction: false,
    position: 'left',
    content: () => (
      <StepInfo
        title={t('reactour.firstView.step4.title')}
        text={t('reactour.firstView.step4.text')}
        buttonText={t('reactour.firstView.step3.buttonText')}
        buttonAction={() => {
          history.push('/app/dashboard');
          setTimeout(() => goToNextStep(false), 200);
        }}
      />
    ),
  }, {
    selector: '[data-tour="menu-tour-1"]',
    stepInteraction: false,
    content: () => (
      <StepInfo
        title={t('reactour.firstView.step5.title')}
        text={t('reactour.firstView.step5.text')}
        buttonText={t('reactour.firstView.step5.buttonText')}
        buttonAction={() => {
          history.push('/app/menu');
          setTimeout(() => goToNextStep(false), 200);
        }}
      />
    ),
  }, {
    selector: '.layout-content',
    stepInteraction: false,
    content: () => (
      <StepInfo
        title={t('reactour.firstView.step6.title')}
        text={t('reactour.firstView.step6.text')}
        buttonText={t('reactour.firstView.step6.buttonText')}
        buttonAction={() => goToNextStep(false)}
      />
    ),
  }, {
    selector: '.tour-guide-wrapper',
    stepInteraction: false,
    content: () => (
      <StepInfo
        title={t('reactour.firstView.step7.title')}
        text={t('reactour.firstView.step7.text')}
        buttonText={t('reactour.firstView.step7.buttonText')}
        buttonAction={() => {
          history.push('/app/dashboard');
          setTimeout(() => goToNextStep(false), 200);
        }}
      />
    ),
  }, {
    selector: '.layout-content',
    stepInteraction: false,
    content: () => (
      <StepInfo
        title={t('reactour.firstView.step8.title')}
        text={t('reactour.firstView.step8.text')}
        buttonText={t('reactour.firstView.step8.buttonText')}
        buttonAction={() => lastStepEvent()}
      />
    ),
  },
];
