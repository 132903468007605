import moment from 'moment';
import { getBillsByDateRequest } from '../../api/db/statistic';

const DATE_FORMAT = 'DD_MM_YYYY';

export const getBillsFromDayRange = (from, to) => {
  const start = moment(from).startOf('day');
  const end = moment(to).endOf('day');
  if (start.valueOf() > end.valueOf()) return new Promise(resolve => resolve([]));

  const dates = [end.format(DATE_FORMAT)];

  while (start.format(DATE_FORMAT) !== end.format(DATE_FORMAT)) {
    dates.push(start.format(DATE_FORMAT));
    start.add(1, 'day');
  }

  return Promise.all(dates.map(date => getBillsByDateRequest(date)))
    .then(response => response.reduce((result, res) => (res ? result.concat(res.data) : result), []));
};

export const removeFromBillItems = (items, el, size) => {
  let isItemRemoved = false;
  let itemsToFilter = [...items];
  // Get full el data, because of absent addons;
  const fullDataEl = itemsToFilter.find(item => item.id === el.id && item.size === size) || el;
  // Remove all related addons if exist;
  if (fullDataEl && fullDataEl.addons) {
    fullDataEl.addons.forEach((addon) => {
      itemsToFilter = removeFromBillItems(itemsToFilter, addon);
    });
  }
  //
  return itemsToFilter.map((item) => {
    if (item.id === fullDataEl.id && item.size === size && item.displayName === el.displayName
      && (!isItemRemoved || item.weightItem)) {
      isItemRemoved = true;
      if (item.weightItem) {
        return { ...item, quantity: 0 };
      }
      return { ...item, quantity: item.quantity - 1 };
    }
    return item;
  }).filter(item => item.quantity !== 0);
};

export const prepareBillData = (items) => {
  const namesSet = new Set(items.map(el => el.displayName));
  const map = new Map();

  namesSet.forEach((el) => {
    const {
      name, id, type, weightItem, units,
    } = items.find(item => item.displayName === el);
    return map.set(el, {
      name,
      displayName: el,
      sizes: [],
      quantity: {},
      prices: {},
      type,
      id,
      meta: { weightItem, units: units || 'pc' },
    });
  });
  items.forEach(({
    size, quantity, price, displayName,
  }) => {
    const mapItem = map.get(displayName);
    map.set(displayName, {
      ...mapItem,
      ...(mapItem.sizes.includes(size) ? {
        sizes: mapItem.sizes,
        quantity: { ...mapItem.quantity, [size]: parseInt(quantity, 10) + parseInt(mapItem.quantity[size], 10) },
        prices: mapItem.prices,
      } : {
        sizes: [...mapItem.sizes, size],
        quantity: { ...mapItem.quantity, [size]: quantity },
        prices: { ...mapItem.prices, [size]: price },
      }),
    });
  });
  return Array.from(map.values());
};

export const getBillItems = (bill, menu) => {
  const res = [];
  bill.items.forEach((el) => {
    // Check is Addon
    if (el.componentId) {
      res.push({ item: el.componentId, count: el.count * el.quantity });
      return;
    }
    // Check is cocktail
    if (el.components) {
      // Add consumables
      const calculation = menu.idMap[el.id];
      Object.keys(el.consumables).map(key => el.consumables[key]).forEach((item) => {
        res.push({ item, count: el.quantity });
      });
      // Add components
      if (calculation) {
        el.components.filter(id => id !== 0).forEach((id) => {
          const item = calculation.components.find(e => e.id === id) || menu.idMap[id];
          res.push({
            item: item.componentId || id,
            count: (item.componentId ? item.count : item.count[el.size]) * el.quantity,
          });
        });
      }
    }
    // In other case this is a item, so we just need to decrease stored items count
    res.push({ item: el.id, count: el.quantity });
  });

  const namesSet = new Set(res.map(el => el.item));
  const map = new Map();

  namesSet.forEach(el => map.set(el, { item: el, count: 0 }));
  res.forEach(({ item, count }) => {
    const mapItem = map.get(item);
    map.set(item, {
      ...mapItem,
      count: parseFloat(mapItem ? mapItem.count : 0) + parseFloat(count),
    });
  });
  return Array.from(map.values());
};
