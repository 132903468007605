import {
  ADD_CASH_SHIFT, GET_CASH_SHIFT, OPEN_CASH_SHIFT, ADD_CASH_SHIFT_DATA, GET_TODAY_CASH_SHIFT,
} from './actions';

const initialState = {
  shiftsLoaded: false,
  cashShifts: [],
  activeShift: null,
};

function shiftReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CASH_SHIFT:
      return {
        ...state,
        cashShifts: action.payload,
        activeShift: action.payload.find((shift => shift.isOpen)) || null,
      };
    case GET_TODAY_CASH_SHIFT:
      const newShiftsIds = action.payload.map(el => el.id);
      return {
        ...state,
        shiftsLoaded: true,
        cashShifts: [
          ...state.cashShifts.filter(el => !newShiftsIds.includes(el.id)),
          ...action.payload,
        ],
        activeShift: action.payload.find((shift => shift.isOpen)) || state.activeShift || null,
      };
    case ADD_CASH_SHIFT:
      return { ...state, cashShifts: action.payload, activeShift: null };
    case ADD_CASH_SHIFT_DATA:
      return { ...state, activeShift: action.payload };
    case OPEN_CASH_SHIFT:
      return { ...state, cashShifts: [...state.cashShifts, action.payload], activeShift: action.payload };
    case 'CLEAN_REDUCERS': {
      return initialState;
    }
    default: return state;
  }
}

export default shiftReducer;
