import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button, Input, Spin, Checkbox as AntdCheckbox, notification,
} from 'antd';

import { firebase } from '../../../config';
import { loginCashierRequest } from '../../../api/checkbox';

import { Column, Header, Row } from '../../../components';

import './style.scss';
import { addCashShiftToCheckboxRequest } from '../../../api/db/shifts';

const Checkbox = ({ business, t, actions: { updatePlaceInfo, getPlaceInfo } }) => {
  const [login, setLogin] = useState(business.checkbox?.login || '');
  const [password, setPassword] = useState(business.checkbox?.password || '');
  const [cashKey, setCashKey] = useState(business.checkbox?.cashKey || '');
  const [useTaxesWebhook, setUseTaxesWebhook] = useState(business.checkbox?.useTaxesWebhook || false);

  const [pendingSave, setPendingSave] = useState(false);
  const [pendingReset, setPendingReset] = useState(false);
  const [pendingForceOpenShift, setPendingForceOpenShift] = useState(false);

  useEffect(() => {
    setCashKey(business.checkbox?.cashKey);
    setUseTaxesWebhook(business.checkbox?.useTaxesWebhook);
  }, [business.checkbox.cashKey, business.checkbox.useTaxesWebhook]);

  const handleSubmitCashier = () => {
    setPendingReset(true);

    loginCashierRequest(login, password)
      .then((res) => {
        console.log(res.data.access_token);

        updatePlaceInfo({
          checkbox: {
            ...business.checkbox,
            token: res.data.access_token,
          },
        });

        setPendingReset(false);
      })
      .catch(() => {
        notification.error({ message: 'Something went wrong!' });

        setPendingReset(false);
      });
  };

  const handleReset = () => {
    setPendingSave(true);

    updatePlaceInfo({
      checkbox: {
        ...business.checkbox,
        token: '',
        cashKey: '',
        useTaxesWebhook: '',
      },
    });

    setPendingSave(false);
  };

  const handleSubmitCashKey = () => {
    setPendingSave(true);

    updatePlaceInfo({
      checkbox: {
        ...business.checkbox,
        cashKey,
        useTaxesWebhook,
      },
    })
      .then(() => {
        setPendingSave(false);
      });
  };

  const handleForceOpenShift = () => {
    setPendingForceOpenShift(true);

    addCashShiftToCheckboxRequest()
      .then(() => {
        getPlaceInfo();

        setPendingForceOpenShift(false);
      });
  };

  return (
    <Spin wrapperClassName="storage-page settings-page" spinning={!firebase.auth().currentUser}>
      <Header>
        <h2 className="header-name">{business.name}</h2>
      </Header>
      <div className="storage-page-content">
        <Column
          className="mobile-block"
          style={{ maxWidth: '600px' }}
          width="100%"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Column
            width="100%"
            alignItems="flex-start"
            justifyContent="flex-start"
            className="default-card settings-block"
          >


            <h2 style={{ margin: '10px 0 5px' }}>{t('settings.cashier')}</h2>

            {!business.checkbox?.token ? (
              <>
                <h3 style={{ margin: '10px 0 5px' }}>{t('settings.cashierLoginTitle')}</h3>

                <Input
                  placeholder={t('settings.cashierLoginPlaceholder')}
                  value={login}
                  onChange={e => setLogin(e.target.value)}
                />

                <h3 style={{ margin: '10px 0 5px' }}>{t('login.password')}</h3>

                <Input
                  placeholder={t('login.password')}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />

                <Button
                  loading={pendingSave}
                  type="primary"
                  size="large"
                  style={{ margin: '20px 0 0 auto' }}
                  className="settings-save"
                  onClick={handleSubmitCashier}
                >{t('login.submit')}
                </Button>
              </>
            ) : (
              <>
                <h3 style={{ margin: '10px 0 5px' }}>{t('settings.cashierToken')}</h3>

                <Input
                  value={business.checkbox.token}
                  disabled
                />

                <h3 style={{ margin: '10px 0 5px' }}>{t('settings.cashKeyTitle')}</h3>

                <Input
                  placeholder={t('settings.cashKeyPlaceholder')}
                  value={cashKey}
                  onChange={e => setCashKey(e.target.value)}
                />

                <AntdCheckbox
                  className="checkbox-large"
                  style={{ margin: '20px 0 5px' }}
                  onClick={() => setUseTaxesWebhook(!useTaxesWebhook)}
                  checked={useTaxesWebhook}
                >{t('settings.useTaxesWebhook')}
                </AntdCheckbox>

                <Row width="100%" justifyContent="space-between" margin="40px 0 0">
                  <Button
                    loading={pendingReset}
                    type="secondary"
                    size="large"
                    onClick={handleReset}
                  >{t('settings.resetCashier')}
                  </Button>

                  <Button
                    loading={pendingSave || pendingReset}
                    type="primary"
                    size="large"
                    onClick={handleSubmitCashKey}
                  >{t('control.save')}
                  </Button>
                </Row>
              </>
            )}

          </Column>

          {business.checkbox?.token && (
            <Column
              width="100%"
              alignItems="flex-start"
              justifyContent="flex-start"
              margin="20px 0 0"
              className="default-card settings-block"
            >


              <h2 style={{ margin: '10px 0 5px' }}>{t('settings.taxTitle')}</h2>
              <h3 style={{ margin: '10px 0 5px' }}>{t('settings.checkboxCashShift')}</h3>
              {business.checkboxShiftId ? (
                <p>ID: <span>{business.checkboxShiftId}</span></p>
              ) : (<p>{t('settings.checkboxCashShiftEmpty')}</p>)}

              {!business.checkboxShiftId && (
                <Button
                  loading={pendingForceOpenShift}
                  type="primary"
                  size="large"
                  style={{ margin: '20px 0 0 auto' }}
                  onClick={handleForceOpenShift}
                >{t('settings.forceOpenCheckboxShift')}
                </Button>
              )}
            </Column>
            )}
        </Column>
      </div>

    </Spin>
  );
};

export default withTranslation()(Checkbox);
