import React from 'react';
import { Table } from 'antd';
import { withTranslation } from 'react-i18next';

const supplyTableColumns = t => [{
  title: t('global.name'),
  dataIndex: 'name',
  width: 'calc(100% - 400px)',
}, {
  title: t('global.quantity'),
  dataIndex: 'count',
  width: '200px',
}];

const WasteTable = ({ items, t }) => (
  <Table
    bordered
    pagination={false}
    size="default"
    className="supply-table storage-page-table"
    columns={supplyTableColumns(t)}
    dataSource={items.map((el, index) => ({ ...el, key: index }))}
  />
);
export default withTranslation()(WasteTable);
