import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { editAddon, removeAddon } from '../../redux/menu/actions';
import { showMessage } from '../../redux/message/actions';
import { ModalHOC } from '../../common';
import AddonModal from './AddonModal';

function mapStateToProps(state) {
  return {
    menu: state.menu,
    business: state.business,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        editAddon,
        removeAddon,
      },
      dispatch,
    ),
  };
}

export default ModalHOC(connect(mapStateToProps, mapDispatchToProps)(AddonModal));
