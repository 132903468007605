import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Input, Form } from 'antd';
import { firebase } from '../../config';
import {
  Link, Column, Row, LanguageRow,
} from '../../components';
import { notificationTypes } from '../../redux/message/actions';
import './style.scss';
import { createBusinessRequest } from '../../api/db/business';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isEmailValid: true,
      password: '',
      newPassword: '',
      repeatPassword: '',
      loading: false,
      showSignUp: false,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    const user = firebase.auth().currentUser;
    if (user) {
      history.replace('/app/dashboard');
    }
  }

  toggleView = () => this.setState(state => ({ showSignUp: !state.showSignUp }));

  handleLogin = () => {
    const { actions: { showMessage }, t } = this.props;
    const { email, password, isEmailValid } = this.state;
    if (!isEmailValid || email.trim() === '') {
      showMessage({ text: t('validation.email'), type: notificationTypes.ERROR });
      return;
    }
    if (password === '') {
      showMessage({ text: t('validation.password'), type: notificationTypes.ERROR });
      return;
    }
    this.setState({ loading: true });
    firebase.auth().signInWithEmailAndPassword(email, password)
      .then(() => this.props.history.replace('/app/dashboard'))
      .catch((error) => {
        this.setState({ loading: false });
        showMessage({
          text: error.message,
          type: notificationTypes.ERROR,
        });
      });
  };

  validateEmail = (email) => {
    const emailRegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    this.setState({ isEmailValid: emailRegExp.test(email.trim()), email: email.trim() });
  };

  handleRegistration = () => {
    const { t, actions: { showMessage } } = this.props;
    const {
      email, newPassword, isEmailValid, repeatPassword,
    } = this.state;
    if (!isEmailValid || email.trim() === '') {
      showMessage({ text: t('validation.email'), type: notificationTypes.ERROR });
      return;
    }
    if (newPassword === '' || newPassword !== repeatPassword) {
      showMessage({ text: t('validation.passwordRepeat'), type: notificationTypes.ERROR });
      return;
    }
    if (newPassword.length < 6) {
      showMessage({ text: t('validation.passwordLength'), type: notificationTypes.ERROR });
      return;
    }
    this.setState({ loading: true });
    firebase.auth().createUserWithEmailAndPassword(email, newPassword)
      .then(() => {
        const user = firebase.auth().currentUser;
        createBusinessRequest(user.uid, email).then(() => this.props.history.replace('/app/dashboard'));
      })
      .catch((error) => {
        this.setState({ loading: false });
        showMessage({
          text: error.message,
          type: notificationTypes.ERROR,
        });
      });
  };

  forgotPassword = () => {
    const { t, actions: { showMessage } } = this.props;
    showMessage({ text: t('validation.forgotPassword'), type: notificationTypes.ERROR });
  };

  render() {
    const { t } = this.props;
    const { loading, showSignUp } = this.state;
    return (
      <div className="login-page-wrapper">
        <Column alignItems="flex-start" className="login-page-title">
          <h1>Shoptoria POS</h1>
        </Column>
        <section className={`user ${showSignUp && 'signUp-part'}`}>
          <div className="user_options-container">
            <div className="user_options-text">
              <div className="user_options-unregistered">
                <h2 className="user_unregistered-title">{t('login.title')}</h2>
                <p className="user_unregistered-text">{t('login.description')}</p>
                <button
                  onClick={this.toggleView}
                  type="button"
                  className="user_unregistered-signup"
                  id="signup-button"
                >{t('signUp.header')}
                </button>
              </div>

              <div className="user_options-registered">
                <h2 className="user_registered-title">{t('signUp.title')}</h2>
                <p className="user_registered-text">{t('signUp.description')}</p>
                <button
                  onClick={this.toggleView}
                  type="button"
                  className="user_registered-login"
                  id="login-button"
                >{t('login.header')}
                </button>
              </div>
            </div>
            <h4 className="login-page-terms">{t('signUp.terms')} <a href="/terms">{t('signUp.termsLink')}</a></h4>
            <div className={`user_options-forms ${!showSignUp ? 'bounceRight' : 'bounceLeft'}`} id="user_options-forms">
              <div className="user_forms-login">
                <h2 className="forms_title">{t('login.header')}</h2>
                <Form className="login-page-form">
                  <Form.Item>
                    <Input
                      onChange={e => this.validateEmail(e.target.value)}
                      prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder={t('login.email')}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Input
                      onChange={e => this.setState({ password: e.target.value })}
                      prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                      type="password"
                      placeholder={t('login.password')}
                    />
                  </Form.Item>
                  <Form.Item style={{ marginBottom: '10px' }}>
                    <Button
                      loading={loading}
                      style={{ margin: '56px 0 10px' }}
                      type="primary"
                      htmlType="submit"
                      className="login-page-button mobile-margin"
                      onClick={() => this.handleLogin()}
                    >
                      {t('login.submit')}
                    </Button>
                    <Button
                      style={{ margin: '24px 0 10px' }}
                      htmlType="submit"
                      className="login-page-button mobile-visible"
                      onClick={this.toggleView}
                    >
                      {t('login.createAccount')}
                    </Button>
                    <Row width="100%" className="mobile-column">
                      <Link to="/" type="primary">
                        {t('login.mainPage')}
                      </Link>
                      <Button style={{ padding: 0 }} type="link" onClick={this.forgotPassword}>
                        {t('login.forgotPassword')}
                      </Button>
                    </Row>
                  </Form.Item>
                </Form>
                <LanguageRow />
              </div>
              <div className="user_forms-signup">
                <h2 className="forms_title">{t('signUp.header')}</h2>
                <Form className="login-page-form">
                  <Form.Item>
                    <Input
                      onChange={e => this.validateEmail(e.target.value)}
                      prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder={t('signUp.email')}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Input
                      onChange={e => this.setState({ newPassword: e.target.value })}
                      prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                      type="password"
                      autoComplete="off"
                      placeholder={t('signUp.password')}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Input
                      onChange={e => this.setState({ repeatPassword: e.target.value })}
                      prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                      type="password"
                      autoComplete="off"
                      placeholder={t('signUp.passwordRepeat')}
                    />
                  </Form.Item>
                  <Form.Item style={{ marginBottom: '10px' }}>
                    <Button
                      loading={loading}
                      type="primary"
                      style={{ margin: 'auto 0 10px' }}
                      htmlType="submit"
                      className="login-page-button"
                      onClick={() => this.handleRegistration()}
                    >
                      {t('signUp.submit')}
                    </Button>
                    <Button
                      style={{ margin: '24px 0 10px' }}
                      htmlType="submit"
                      className="login-page-button mobile-visible"
                      onClick={this.toggleView}
                    >
                      {t('signUp.logIn')}
                    </Button>
                    <Link to="/" type="primary">
                      {t('login.mainPage')}
                    </Link>
                  </Form.Item>
                </Form>
                <LanguageRow />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withTranslation()(Login);
