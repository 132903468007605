import React, { Component } from 'react';
import { Spin } from 'antd';
import { Row } from '../components';
import { getPhotoByType } from '../components/ManagePlaces/ManagePlaces';
import { getPlacesData } from '../redux/business/helpers';

class ChoosePlace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      places: null,
    };
  }

  componentDidMount() {
    const { places } = this.props;
    getPlacesData(places, (placesData => this.setState({ places: placesData })));
  }

  selectPlace = (place) => {
    const { callback } = this.props;
    callback(place);
  };

  render() {
    const { places } = this.state;
    return (
      <Spin spinning={places == null}>
        <Row width="100%" justifyContent="flex-start" flexWrap="wrap" alignItems="flex-start" margin="20px 0 0">
          {(places || []).map(place => (
            <div
              className="business-type large"
              key={JSON.stringify(place)}
              onClick={() => this.selectPlace(place)}
            >
              <img src={getPhotoByType(place.type)} alt={place.name} />
              <p>{place.name}</p>
            </div>
          ))}
        </Row>
      </Spin>
    );
  }
}

export default ChoosePlace;
