import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { showMessage } from '../../../redux/message/actions';
import { getWaste, removeWaste } from '../../../redux/storage/actions';
import Waste from './Waste';

function mapStateToProps(state) {
  return {
    menuIdMap: state.menu.idMap,
    equipmentIdMap: state.equipment.idMap,
    waste: state.storage.waste,
    wasteLoaded: state.storage.wasteLoaded,
    dataLoaded: state.business.dataLoaded,
    canInteract: state.business.canInteract,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        removeWaste,
        getWaste,
      },
      dispatch,
    ),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Waste));
