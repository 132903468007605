import React, { Component } from 'react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Column, Row } from '../../../components';
import { getMonthWithLocale } from '../../../constants/time';
import i18 from '../../../utils/translation';
import { getDayStatisticFromSortedBills, getItemsCost, sortBillsByDate } from '../../../utils/helpers';

class TodayReport extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getTodayStatistic = (resolvedBills) => {
    const today = sortBillsByDate(resolvedBills, moment(), moment()).find(el => moment().isSame(el.date, 'day'));
    const todayStatistic = { ...getDayStatisticFromSortedBills([today])[0], ...today };
    const cost = this.getDayCost(today.bills);
    const { total, count } = todayStatistic;
    return {
      total,
      gross: (total - cost).toFixed(2),
      count,
      middleBill: parseFloat((total || 0) / (count || 1)).toFixed(2),
    };
  };

  getDayCost = (bills) => {
    const { menu, equipment } = this.props;
    const items = bills.map(bill => bill.items).flat().map(el => ({ ...el, ...menu.idMap[el.id] }));
    return equipment.loading ? 0 : getItemsCost(items, menu, equipment);
  };

  render() {
    const { t, resolvedBills, business } = this.props;
    const today = this.getTodayStatistic(resolvedBills);

    return (
      <Row className="today mobile-column-payments" width="100%" margin="10px 0 0" height="74px">
        <h3>
          {t('time.today')},<br />
          {moment().format('DD')} {getMonthWithLocale(i18.language, moment().format('M'))}
        </h3>
        <Column className="mobile-row-payments">
          <h3>{today.total} {business.currency}</h3>
          <span>{t('global.revenue')}</span>
        </Column>
        <Column className="mobile-row-payments">
          <h3>{today.gross} {business.currency}</h3>
          <span>{t('global.gross')}</span>
        </Column>
        <Column className="mobile-row-payments">
          <h3>{today.count} {t('count')}</h3>
          <span>{t('layout.bills')}</span>
        </Column>
        <Column className="mobile-row-payments">
          <h3>{today.middleBill} {business.currency}</h3>
          <span>{t('statistic.middleBill')}</span>
        </Column>
      </Row>
    );
  }
}
function mapStateToProps(state) {
  return {
    resolvedBills: state.statistic.resolvedBills,
    menu: state.menu,
    equipment: state.equipment,
    business: state.business,
  };
}

export default connect(mapStateToProps, null)(withTranslation()(TodayReport));
