import React, { Component } from 'react';
import { Button, Spin } from 'antd';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { firebase } from '../../../config';
import {
  Content, Page, PageHeader, TopBar,
} from '../../../components';
import { RangePicker } from '../../../common';
import { MONTH, WEEK } from '../../../common/RangePicker';
import { getBillsFromDayRange } from '../../../redux/statistic/helpers';
import { getDayStatisticFromSortedBills, printPage, sortBillsByDate } from '../../../utils/helpers';
import RevenueChart from './RevenueChart';
import TodayReport from './TodayReport';
import SalesPerHour from './SalesPerHour';
import SalesPerDay from './SalesPerDay';
import PopularItem from './PopularItem';
import './style.scss';
import { isOnline } from '../../../persistance';
import { showNetworkError } from '../../../redux/message/actions';

class Profit extends Component {
  constructor(props) {
    super(props);
    const { resolvedBills } = this.props;
    this.state = {
      pending: false,
      series: [],
      bills: resolvedBills,
    };
  }

  componentDidMount() {
    const { activePlace } = this.props;
    if (activePlace) {
      this.updateRange({});
    }
  }

  updateRange = ({ rangeStart = moment().subtract(1, 'week'), rangeEnd = moment() }) => {
    if (!isOnline()) return showNetworkError();
    this.setState({ pending: true });
    getBillsFromDayRange(rangeStart, rangeEnd)
      .then((bills = []) => {
        const dates = sortBillsByDate(bills, rangeStart, rangeEnd);
        this.setState({ bills, series: getDayStatisticFromSortedBills(dates), pending: false });
      });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { activePlace, dataLoaded } = nextProps;
    if ((this.props.activePlace !== activePlace && dataLoaded) || this.props.dataLoaded !== dataLoaded) {
      this.updateRange({ ...this.state });
    }
  }

  render() {
    const { t, currency } = this.props;
    const { pending, bills, series } = this.state;
    return (
      <Page wrapperClassName="statistic" spinning={!firebase.auth().currentUser}>
        <PageHeader />
        <Content>
          <TopBar>
            <h3 className="storage-page-title">{t('global.sales')}</h3>
            <Button
              className="no-print mobile-bottom-margin mobile-right-margin"
              size="large"
              onClick={() => printPage()}
              type="secondary"
            >{t('control.print')}
            </Button>
            <RangePicker
              shouldCheckPermission
              ranges={[WEEK, MONTH]}
              rangeStart={moment().subtract(1, 'week')}
              callback={this.updateRange}
            />
          </TopBar>
          <TodayReport />
          <Spin wrapperClassName="storage-page-section" spinning={pending}>
            <RevenueChart series={series} currency={currency} />
            <div className="chart-container">
              <SalesPerHour bills={bills} currency={currency} />
              <SalesPerDay bills={bills} currency={currency} />
            </div>
            <PopularItem bills={bills} />
          </Spin>
        </Content>
      </Page>
    );
  }
}

export default withTranslation()(Profit);
