import React, { Component } from 'react';
import { LockOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Clients, Header, Row } from '../../components';
import { NetworkStatus } from '../../common';

import NewBillButton from './Buttons/NewBillButton';
import OpenModalMenuButton from './Buttons/OpenModalMenuButton';
import './style.scss';
import KeeperContent from './KeeperContent';

class Keeper extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    const { history, activeEmployer } = props;
    if (!activeEmployer) history.replace('/app/dashboard');
    document.body.classList.add('no-overscroll-page');
  }

  componentWillUnmount() {
    document.body.classList.remove('no-overscroll-page');
  }

  dropEmployer = () => {
    const { history, actions: { setEmployer } } = this.props;
    setEmployer(null);
    history.replace('/app/dashboard');
  };

  render() {
    const { activeEmployer, name } = this.props;

    if (!activeEmployer) return <div />;


    return (
      <div className="keeper">
        <Header className="keeper-header">
          <NewBillButton />

          <h2 className="header-name">{name}</h2>

          <OpenModalMenuButton />

          <Button
            onClick={this.dropEmployer}
            type="link"
            style={{ margin: '15px', color: 'white' }}
          >
            <LockOutlined />

            {activeEmployer.name}
          </Button>

          <NetworkStatus />
        </Header>

        <Row justifyContent="flex-start" alignItems="flex-start" width="100%">
          <Clients />

          <KeeperContent />
        </Row>
      </div>
    );
  }
}

export default Keeper;
