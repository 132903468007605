import React, { Fragment } from 'react';
import { Empty } from 'antd';
import { Card, Row } from '../components';

const ItemList = ({
  items, callback, withFavourites = true, withCategories = true,
}) => (
  <Fragment>
    {items.length === 0 ? (
      <Row justifyContent="center" alignItems="center" width="100%" style={{ height: '100%' }}>
        <Empty description="" />
      </Row>
    ) : (
      <Row width="100%" justifyContent="flex-start" flexWrap="wrap">
        {items.map(el => (
          <Card
            withCategory={withCategories}
            callback={() => callback(el)}
            withFavourites={withFavourites}
            item={el}
            key={JSON.stringify(el)}
          />
        ))}
      </Row>
    )}
  </Fragment>
);
export default ItemList;
