import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { PercentageOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Input, Modal, DatePicker, TimePicker, Tag, Button, Select,
} from 'antd';
import { ModalHOC, NumberInput } from '../../../common';
import { Column, Row } from '../../../components';
import { notificationTypes, showMessage } from '../../../redux/message/actions';
import { editSale } from '../../../redux/marketing/actions';
import SelectItemForSale from '../../../common/SelectItemForSale';
import './styles.scss';
import { validateNumberInput } from '../../../utils/helpers';

const { CheckableTag } = Tag;

const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

class EditSale extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      description: '',
      startTime: '00:00',
      endTime: '23:59',
      startDate: moment().format('DD.MM.YYYY'),
      endDate: moment().add(1, 'day').format('DD.MM.YYYY'),
      salePercent: 10,
      saleAmount: 10,
      saleType: 'salePercent',
      selectedCategories: [],
      showSelectItem: false,
      promotionDays: days,
      selectedItems: [],
      ...props.sale,
    };
  }

  removeItem = id => this.setState(state => ({ selectedItems: state.selectedItems.filter(el => el !== id) }))

  create = () => {
    const {
      t, close, sale, actions: { editSale, showMessage },
    } = this.props;
    const {
      name, startTime, endTime, startDate, endDate, salePercent, saleAmount, saleType,
      selectedItems, selectedCategories, promotionDays, description,
    } = this.state;
    if (name.trim() === '') {
      showMessage({ text: t('validation.emptyName'), type: notificationTypes.ERROR });
      return;
    }
    if (promotionDays.length === 0) {
      showMessage({ text: t('validation.promotionDays'), type: notificationTypes.ERROR });
      return;
    }
    if (selectedCategories.length === 0 && selectedItems.length === 0) {
      showMessage({ text: t('validation.saleItems'), type: notificationTypes.ERROR });
      return;
    }
    editSale({
      ...sale,
      name,
      description,
      startTime,
      endTime,
      startDate,
      endDate,
      salePercent,
      saleAmount,
      saleType,
      selectedItems,
      selectedCategories,
      promotionDays,
    });
    close();
  };

  toggleCategory = (id) => {
    this.setState(state => ({
      selectedCategories: state.selectedCategories.includes(id)
        ? state.selectedCategories.filter(el => el !== id)
        : [...state.selectedCategories, id],
    }));
  }

  toggleDay = (day) => {
    this.setState(state => ({
      promotionDays: state.promotionDays.includes(day)
        ? state.promotionDays.filter(el => el !== day)
        : [...state.promotionDays, day],
    }));
  }

  render() {
    const {
      t, close, visible, currency, menu: { categories, idMap },
    } = this.props;
    const {
      name, startDate, endDate, startTime, endTime, selectedCategories, showSelectItem, selectedItems, promotionDays,
      saleType, description,
    } = this.state;
    return (
      <Modal
        maskClosable={false}
        centered
        visible={visible}
        onOk={this.create}
        onCancel={close}
        width="100%"
        style={{ maxWidth: '450px' }}
        okText={t('control.save')}
        okButtonProps={{ size: 'large' }}
        cancelButtonProps={{ size: 'large' }}
        cancelText={t('control.cancel')}
        title={t('marketing.sales.editSale')}
      >
        <Column
          width="100%"
          alignItems="flex-start"
          margin="0 auto"
          className="default-card"
          style={{ maxWidth: '420px' }}
        >
          <h3 style={{ margin: '0 0 5px' }}>{t('marketing.clients.name')}</h3>
          <Input
            style={{ width: '100%' }}
            placeholder={t('marketing.clients.name')}
            value={name}
            onChange={e => this.setState({ name: e.target.value })}
          />
          <h3 style={{ margin: '0 0 5px' }}>{t('global.description')}</h3>
          <Input
            style={{ width: '100%' }}
            placeholder="..."
            value={description}
            onChange={e => this.setState({ description: e.target.value })}
          />
          <h3 style={{ margin: '10px 0 5px' }}>{t('marketing.sales.saleDate')}</h3>
          <Row width="100%">
            <DatePicker
              style={{ width: '47%' }}
              format="DD.MM.YYYY"
              defaultValue={moment(startDate, 'DD.MM.YYYY')}
              onChange={(date, dateString) => this.setState({ startDate: dateString })}
            />
            <DatePicker
              style={{ width: '47%' }}
              format="DD.MM.YYYY"
              defaultValue={moment(endDate, 'DD.MM.YYYY')}
              onChange={(date, dateString) => this.setState({ endDate: dateString })}
            />
          </Row>
          <h3 style={{ margin: '10px 0 5px' }}>{t('marketing.sales.saleTime')}</h3>
          <Row width="100%">
            <TimePicker
              style={{ width: '47%' }}
              format="HH:mm"
              value={moment(startTime, 'HH:mm')}
              onChange={(time, timeString) => this.setState({ startTime: timeString })}
            />
            <TimePicker
              style={{ width: '47%' }}
              format="HH:mm"
              value={moment(endTime, 'HH:mm')}
              onChange={(time, timeString) => this.setState({ endTime: timeString })}
            />
          </Row>
          <h3 style={{ margin: '10px 0 5px' }}>{t('marketing.sales.activeDays')}</h3>
          <Row width="100%" flexWrap="wrap" justifyContent="flex-start">
            {days.map(el => (
              <CheckableTag
                className="create-product-tag"
                key={el}
                checked={promotionDays.includes(el)}
                onClick={() => this.toggleDay(el)}
              >{t(`days.${el}`)}
              </CheckableTag>
            ))}
          </Row>
          <h3 style={{ margin: '10px 0 5px' }}>{t('marketing.sales.includedCategories')}</h3>
          <Row width="100%" flexWrap="wrap" justifyContent="flex-start">
            {(categories || []).map(el => (
              <CheckableTag
                className="create-product-tag"
                key={el.id}
                checked={selectedCategories.includes(el.id)}
                onClick={() => this.toggleCategory(el.id)}
              >{el.name}
              </CheckableTag>
            ))}
          </Row>
          <h3 style={{ margin: '10px 0 5px' }}>{t('marketing.sales.includedItems')}</h3>
          <Row width="100%" flexWrap="wrap" justifyContent="flex-start">
            {(selectedItems || []).map(el => (
              <CheckableTag
                className="create-product-tag"
                key={el}
                checked
                onClick={() => this.removeItem(el)}
              >{idMap[el].name}
              </CheckableTag>
            ))}
          </Row>
          <Button
            style={{ marginTop: '10px' }}
            icon={<PlusOutlined />}
            onClick={() => this.setState({ showSelectItem: true })}
          >{t('item.add')}
          </Button>
          <h3 style={{ margin: '10px 0 5px' }}>{t('marketing.sales.saleType')}</h3>
          <Select
            style={{ width: '100%' }}
            value={saleType}
            className="add-item-select"
            onChange={saleType => this.setState({ saleType })}
          >
            <Select.Option value="salePercent">
              <p style={{ marginRight: '5px' }}>{t('marketing.sales.percent')}</p>
            </Select.Option>
            <Select.Option value="saleAmount">
              <p style={{ marginRight: '5px' }}>{t('marketing.sales.amount')}</p>
            </Select.Option>
          </Select>
          <h3 style={{ margin: '10px 0 5px' }}>
            {saleType === 'salePercent' ? t('marketing.sales.percent') : t('marketing.sales.amount')}
          </h3>
          <NumberInput
            onKeyUp={validateNumberInput}
            addonAfter={saleType === 'salePercent' ? <PercentageOutlined /> : currency}
            value={this.state[saleType]}
            controlled
            style={{ width: '100%' }}
            placeholder="10"
            type="tel"
            onChange={e => this.setState({ [saleType]: e.target.value })}
          />
        </Column>
        <SelectItemForSale
          selectedItems={selectedItems}
          callback={data => this.setState({ selectedItems: data, showSelectItem: false })}
          close={() => this.setState({ showSelectItem: false })}
          visible={showSelectItem}
        />
      </Modal>
    );
  }
}
function mapStateToProps(state) {
  return {
    menu: state.menu,
    currency: state.business.currency,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        editSale,
      },
      dispatch,
    ),
  };
}
export default ModalHOC(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditSale)));
