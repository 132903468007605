import React, { Component, Fragment } from 'react';
import {
  CalculatorOutlined, HourglassOutlined, PlusOutlined, SolutionOutlined, CloudSyncOutlined,
  FileExcelOutlined,
} from '@ant-design/icons';
import {
  Spin, Button, Modal, notification,
} from 'antd';
import { withTranslation } from 'react-i18next';
import { Column, Row } from '../index';
import { CloseShift, AddTransactionToShift, OpenShift } from '../../common';
import TodayReport from '../TodayReport';
import EditBills from '../EditBills';
import AddSupply from '../../view/Storage/Supply/AddSupply';
import { isPermissionGranted, showNotGrantedMessage } from '../../constants/tariff';
import {
  cleanPersistedBillsShifts, isOnline, syncBillsData, syncShiftsData,
} from '../../persistance';
import { notificationTypes, showNetworkError } from '../../redux/message/actions';

class ModalMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pendingPersistCleaning: false,
    };
  }

  close = () => {
    const { shift: { activeShift }, dropEmployer, close } = this.props;
    if (!activeShift) {
      dropEmployer();
    }
    close();
  };

  cleanCaches = () => {
    this.setState({ pendingPersistCleaning: true });
    cleanPersistedBillsShifts().then(() => {
      const { actions: { showMessage }, t } = this.props;
      this.setState({ pendingPersistCleaning: false });
      showMessage({
        type: notificationTypes.SUCCESS,
        text: t('modalMenu.cacheCleaned'),
        description: t('modalMenu.cacheCleanedDescription'),
      });
    });
  }

  synchronizeData = () => {
    const { t, activePlace, actions: { getStorage, getClients } } = this.props;
    if (!isOnline()) {
      return notification.warning({
        message: t('modalMenu.syncFailed'),
        description: t('modalMenu.syncFailedDescription'),
      });
    }
    notification.open({
      message: t('modalMenu.syncProcess'),
      description: t('modalMenu.syncProcessDescription'),
      icon: <Spin style={{ color: '#0026CA' }} />,
      duration: 0,
    });
    Promise.all([syncShiftsData, syncBillsData].map(func => func(activePlace))).then(() => {
      notification.destroy();
      notification.success({
        message: t('modalMenu.syncSuccess'),
        description: t('modalMenu.syncSuccessDescription'),
      });
      setTimeout(() => {
        getStorage();
        getClients();
      }, 1000);
    })
      .catch((e) => {
        console.log(e);
        notification.destroy();
        notification.error({
          message: 'Something went wrong',
        });
      });
  }

  render() {
    const {
      t, close, visible, employer, dropEmployer, shift: { cashShifts, activeShift }, resolvedBills, tariff,
    } = this.props;
    const { pendingPersistCleaning } = this.state;
    return (
      <Modal
        maskClosable={false}
        visible={visible}
        onCancel={this.close}
        footer={null}
        width="600px"
        title={t('modalMenu.title')}
      >
        <Column width="100%" alignItems="center">
          <h3>{t('financial.shift')}</h3>

          <Row width="100%" justifyContent="center" margin="5px 0" flexWrap="wrap">
            {!activeShift ? (
              <Fragment>
                <OpenShift callback={() => close()} employer={employer} ref={(ref) => { this.openShift = ref; }} />

                <Button
                  style={{ width: '230px', margin: '0 10px 15px' }}
                  size="large"
                  icon={<CalculatorOutlined />}
                  className="open-shift-button"
                  onClick={() => {
                    if (isPermissionGranted(tariff, 'terminal')) {
                      this.openShift.show();
                    } else {
                      showNotGrantedMessage(t);
                    }
                  }}
                >{t('modalMenu.openShift')}
                </Button>

                {cashShifts.length > 0 && (
                  <Button
                    style={{ width: '230px', margin: '0 10px 15px' }}
                    size="large"
                    icon={<HourglassOutlined />}
                    onClick={() => this.todayReport.show()}
                  >{t('modalMenu.getDayReport')}
                  </Button>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <Button
                  style={{ width: '230px', margin: '0 10px 15px' }}
                  size="large"
                  icon={<PlusOutlined />}
                  onClick={() => this.addTransactionToShift.show()}
                >{t('financial.addNewTransaction')}
                </Button>

                <Button
                  style={{ width: '230px', margin: '0 10px 15px' }}
                  size="large"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    if (isPermissionGranted(tariff, 'store')) {
                      this.addSupplyModal.show();
                    } else {
                      showNotGrantedMessage(t);
                    }
                  }}
                >{t('supply.addSupply')}
                </Button>

                <Button
                  style={{ width: '230px', margin: '0 10px 15px' }}
                  size="large"
                  icon={<HourglassOutlined />}
                  onClick={() => this.shiftReport.show()}
                >{t('modalMenu.getShiftReport')}
                </Button>

                {cashShifts.length > 0 && (
                  <Button
                    style={{ width: '230px', margin: '0 10px 15px' }}
                    size="large"
                    icon={<HourglassOutlined />}
                    onClick={() => this.todayReport.show()}
                  >{t('modalMenu.getDayReport')}
                  </Button>
                )}

                <Button
                  style={{ width: '230px', margin: '0 10px 15px' }}
                  size="large"
                  icon={<CalculatorOutlined />}
                  onClick={() => {
                    if (!isOnline()) return showNetworkError();
                    this.closeShift.show();
                  }}
                >{t('modalMenu.closeShift')}
                </Button>

                <Button
                  style={{ width: '230px', margin: '0 10px 15px' }}
                  size="large"
                  icon={<SolutionOutlined />}
                  onClick={() => this.editBills.show()}
                >{t('modalMenu.bills')}
                </Button>

                <TodayReport
                  showActiveShift
                  bills={resolvedBills.filter(el => (el.id > (!activeShift ? Date.now() : activeShift.startTime)))}
                  ref={(ref) => { this.shiftReport = ref; }}
                />

                <AddTransactionToShift ref={(ref) => { this.addTransactionToShift = ref; }} employer={employer} />

                <CloseShift dropEmployer={dropEmployer} employer={employer} ref={(ref) => { this.closeShift = ref; }} />

                <EditBills ref={(ref) => { this.editBills = ref; }} />

                <AddSupply ref={(ref) => { this.addSupplyModal = ref; }} />
              </Fragment>
            )}
          </Row>

          <h3>{t('global.other')}</h3>

          <Row width="100%" justifyContent="center" margin="5px 0" flexWrap="wrap">
            <Button
              style={{ width: '230px', margin: '0 10px 15px' }}
              size="large"
              icon={<CloudSyncOutlined />}
              onClick={this.synchronizeData}
            >{t('modalMenu.checkSync')}
            </Button>

            <Button
              style={{ width: '230px', margin: '0 10px 15px' }}
              size="large"
              icon={<FileExcelOutlined />}
              onClick={this.cleanCaches}
              loading={pendingPersistCleaning}
            >{t('modalMenu.cleanCache')}
            </Button>
          </Row>

          {cashShifts.length > 0 && (
            <TodayReport ref={(ref) => { this.todayReport = ref; }} />
          )}
        </Column>
      </Modal>
    );
  }
}

export default withTranslation()(ModalMenu);
