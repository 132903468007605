import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { ConfigProvider } from 'antd';
import ua from 'antd/lib/locale-provider/uk_UA';
import ru from 'antd/lib/locale-provider/ru_RU';
import en from 'antd/lib/locale-provider/en_GB';
import Keeper from './Keeper';
import KeeperLogin from './Keeper/KeeperLogin';

import Menu from './Menu';
import Storage from './Storage';
import Consumables from './Equipment/Consumables';
import Kits from './Equipment/Kits';
import Components from './Equipment/Components';
import Login from './Login';
import Loading from './Loading';
import Layout from '../components/Layout';
import StatisticItems from './Statistic/Items';
import Bills from './Statistic/Bills';
import Profit from './Statistic/Profit';
import Payments from './Statistic/Payments';
import Categories from './Statistic/Categories';
import StatisticEmployers from './Statistic/Employers';
import Supply from './Storage/Supply';
import Suppliers from './Storage/Suppliers';
import Waste from './Storage/Waste';
import Inventory from './Storage/Inventory';
import Consumption from './Storage/Consumption';
import Clients from './Marketing/Clients';
import Groups from './Marketing/Groups';
import LoyaltyProgram from './Marketing/LoyaltyProgram';
import Sales from './Marketing/Sales';
import LoyaltyExclude from './Marketing/LoyaltyExclude';
import Transactions from './Financial/Transactions';
import FinancialCategories from './Financial/Categories';
import Salary from './Financial/Salary';
import FinancialAccounts from './Financial/Accounts';
import Shifts from './Financial/Shifts';
import Report from './Financial/Report';
import Employers from './Access/Employers';
import Jobs from './Access/Jobs';
import SettingsSubscription from './Settings/Subscriptions';
import SettingsGeneral from './Settings/General';
import DevCenter from './Settings/DevCenter';
import Notifications from './Settings/Notifications';
import Versions from './Settings/Versions';
import KeeperSettings from './Settings/KeeperSettings';
import Checkbox from './Settings/Checkbox';

import Landing from './Landing';
import Terms from './Terms';
import FAQ from './FAQ';
import InstallHelp from './InstallHelp';
import Feedback from './Feedback';
import Quarantine from './SalesPages/Quarantine';

import {
  MenuDoc, StoreDoc, FinancialDoc, SecureDoc, StatisticDoc,
} from './Docs';

import { firebase } from '../config';
import { initRedux, login, refetchData } from '../redux/user/actions';

import { TourGuide } from '../components';
import i18n from '../utils/translation';
import { syncBillsData, syncShiftsData } from '../persistance';
import { getPlaceRefFromDB } from '../redux/business/helpers';
import { getStorage } from '../redux/storage/actions';
import { getClients } from '../redux/marketing/actions';

const locales = { ua, ru, en };
const MainRouting = () => (
  <Switch>
    <Route exact path="/app/" component={() => <Loading full />} />
    <Layout>
      <Route exact path="/app/dashboard" component={KeeperLogin} />
      <Route exact path="/app/keeper" component={Keeper} />
      <Route exact path="/app/menu" component={Menu} />
      <Route exact path="/app/equipment/consumables" component={Consumables} />
      <Route exact path="/app/equipment/kits" component={Kits} />
      <Route exact path="/app/equipment/components" component={Components} />
      <Route exact path="/app/statistic/items" component={StatisticItems} />
      <Route exact path="/app/statistic/bills" component={Bills} />
      <Route exact path="/app/statistic/profit" component={Profit} />
      <Route exact path="/app/statistic/payments" component={Payments} />
      <Route exact path="/app/statistic/categories" component={Categories} />
      <Route exact path="/app/statistic/employers" component={StatisticEmployers} />
      <Route exact path="/app/storage/store" component={Storage} />
      <Route exact path="/app/storage/supply" component={Supply} />
      <Route exact path="/app/storage/suppliers" component={Suppliers} />
      <Route exact path="/app/storage/waste" component={Waste} />
      <Route exact path="/app/storage/inventory" component={Inventory} />
      <Route exact path="/app/storage/consumption" component={Consumption} />
      <Route exact path="/app/marketing/clients" component={Clients} />
      <Route exact path="/app/marketing/groups" component={Groups} />
      <Route exact path="/app/marketing/sales" component={Sales} />
      <Route exact path="/app/marketing/loyalty" component={LoyaltyProgram} />
      <Route exact path="/app/marketing/exclusion" component={LoyaltyExclude} />
      <Route exact path="/app/financial/transactions" component={Transactions} />
      <Route exact path="/app/financial/categories" component={FinancialCategories} />
      <Route exact path="/app/financial/accounts" component={FinancialAccounts} />
      <Route exact path="/app/financial/salary" component={Salary} />
      <Route exact path="/app/financial/shifts" component={Shifts} />
      <Route exact path="/app/financial/report" component={Report} />
      <Route exact path="/app/access/employers" component={Employers} />
      <Route exact path="/app/access/jobs" component={Jobs} />
      <Route exact path="/app/settings/general" component={SettingsGeneral} />
      <Route exact path="/app/settings/subscription" component={SettingsSubscription} />
      <Route exact path="/app/settings/devCenter" component={DevCenter} />
      <Route exact path="/app/settings/notifications" component={Notifications} />
      <Route exact path="/app/settings/updates" component={Versions} />
      <Route exact path="/app/settings/keeper" component={KeeperSettings} />
      <Route exact path="/app/settings/checkbox" component={Checkbox} />
      <TourGuide />
    </Layout>
  </Switch>
);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    firebase.auth().onAuthStateChanged((user) => {
      const { actions: { initRedux, refetchData } } = props;
      const { history } = this.props;
      if (user !== null && /\/app\//.test(history.location.pathname)) {
        const { actions: { login, getStorage, getClients } } = this.props;
        login({ email: user.email, token: user.uid });
        initRedux();
        window.addEventListener('offline', () => {
          document.body.classList.add('no-overscroll');
        });
        window.addEventListener('online', async () => {
          document.body.classList.remove('no-overscroll');
          console.log('Try to go online');
          // db.enableNetwork();
          const placeRef = await getPlaceRefFromDB();
          Promise.all([syncBillsData, syncShiftsData].map(func => func(placeRef))).then(() => {
            getStorage();
            getClients();
          });
        });
        setInterval(() => {
          refetchData();
        }, 300000);
      } else if (/\/app\//.test(history.location.pathname)) {
        history.push('/app/login');
      }
    });
  }

  render() {
    const { business } = this.props;
    return (
      <ConfigProvider locale={locales[business && business.language ? business.language : i18n.language]}>
        <Switch>
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/faq" component={FAQ} />
          <Route exact path="/install" component={InstallHelp} />
          <Route exact path="/feedback" component={Feedback} />
          <Route exact path="/sales/quarantine" component={Quarantine} />
          <Route exact path="/docs/menu" component={MenuDoc} />
          <Route exact path="/docs/store" component={StoreDoc} />
          <Route exact path="/docs/statistic" component={StatisticDoc} />
          <Route exact path="/docs/financial" component={FinancialDoc} />
          <Route exact path="/docs/secure" component={SecureDoc} />
          <Route exact path="/app/login" component={() => <Login />} />
          <Route path="/app/" component={MainRouting} />
          <Route path="/" component={Landing} />
        </Switch>
      </ConfigProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    business: state.business,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      login, initRedux, refetchData, getStorage, getClients,
    }, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
