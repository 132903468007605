import React from 'react';
import { Button, notification } from 'antd';
import { appHistory } from '../config';

export const LITE = 'LITE';
export const UNIVERSAL = 'UNIVERSAL';
export const BUSINESS = 'BUSINESS';
export const PRO = 'PRO';
export const QUARANTINE = 'QUARANTINE';
export const NOT_PAYED = 'NOT_PAYED';

export const normalizeName = name => `${name[0]}${name.slice(1).toLowerCase()}`;

const permissionList = ['terminal', 'store', 'unlimitedStatistic', 'tgBot', 'salaryCalculation', 'empty'];

export const showNotGrantedMessage = (t) => {
  const key = `open${Date.now()}`;
  const btn = (
    <Button
      type="primary"
      onClick={() => {
        appHistory.push('/app/settings/subscription');
        notification.close(key);
      }}
    >
      {t('permission.upgradeTariff')}
    </Button>
  );
  notification.open({
    message: t('permission.notGrantedTitle'),
    description: t('permission.notGrantedDescription'),
    btn,
    key,
  });
};

export const tariffPermissions = {
  NOT_PAYED: {
    terminal: false, store: false, unlimitedStatistic: false, tgBot: false, salaryCalculation: false, empty: true,
  },
  LITE: {
    terminal: true, store: true, unlimitedStatistic: true, tgBot: true, salaryCalculation: true, empty: true,
  },
  BUSINESS: {
    terminal: true, store: true, unlimitedStatistic: true, tgBot: true, salaryCalculation: false, empty: true,
  },
  PRO: {
    terminal: true, store: true, unlimitedStatistic: true, tgBot: true, salaryCalculation: false, empty: true,
  },
  UNIVERSAL: {
    terminal: true, store: true, unlimitedStatistic: true, tgBot: true, salaryCalculation: false, empty: true,
  },
  QUARANTINE: {
    terminal: true, store: false, unlimitedStatistic: false, tgBot: false, salaryCalculation: false, empty: true,
  },
};

export const getTariffPermissions = tariff => tariffPermissions[tariff];

/**
 * Check is tariff granted permission to access feature
 * @param {(LITE | BUSINESS | PRO | QUARANTINE)} tariff
 * @param {string} permission
 * @returns {boolean}
 */
export const isPermissionGranted = (tariff = LITE, permission = '') => {
  // Check is tariff exist
  if (!tariffPermissions[tariff]) {
    console.warn(`Unknown tariff presented ! (${tariff})`);
    return false;
  }
  // Check is permission exist
  if (!permissionList.includes(permission)) {
    console.warn(`Unknown permission presented ! (${permission})`);

    return false;
  }
  return tariffPermissions[tariff][permission] || false;
};
