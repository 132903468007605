import { generateId } from '../../utils/helpers';
import i18n from '../../utils/translation';
import {
  addFinancialAccountRequest, addFinancialCategoryRequest, addFinancialTransactionRequest, editFinancialAccountRequest,
  editFinancialCategoryRequest, editFinancialTransactionRequest, getFinancialAccountRequest,
  getFinancialCategoryRequest, getFinancialTransactionDeltaRequest, getFinancialTransactionsRequest,
  removeFinancialAccountRequest, removeFinancialCategoryRequest, removeFinancialTransactionRequest,
} from '../../api/db/financial';
import { showErrorMessage, showMessage } from '../message/actions';
import {
  getPersistedFinancialAccount,
  getPersistedFinancialCategory,
  getPersistedFinancialTransaction,
  isOnline,
  persistWithKey, removeDataFromPersist,
} from '../../persistance';
import { idbFinancialAccount, idbFinancialCategory, idbFinancialTransaction } from '../../config';

export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const GET_TRANSACTIONS_DELTA = 'GET_TRANSACTIONS_DELTA';
export const EDIT_TRANSACTION = 'EDIT_TRANSACTION';
export const ADD_TRANSACTION = 'ADD_TRANSACTION';
export const REMOVE_TRANSACTION = 'REMOVE_TRANSACTION';

export const GET_FINANCIAL_CATEGORIES = 'GET_FINANCIAL_CATEGORIES';
export const ADD_FINANCIAL_CATEGORY = 'ADD_FINANCIAL_CATEGORY';
export const REMOVE_FINANCIAL_CATEGORY = 'REMOVE_FINANCIAL_CATEGORY';
export const EDIT_FINANCIAL_CATEGORY = 'EDIT_FINANCIAL_CATEGORY';

export const GET_FINANCIAL_ACCOUNTS = 'GET_FINANCIAL_ACCOUNTS';
export const ADD_FINANCIAL_ACCOUNT = 'ADD_FINANCIAL_ACCOUNT';
export const REMOVE_FINANCIAL_ACCOUNT = 'REMOVE_FINANCIAL_ACCOUNT';
export const EDIT_FINANCIAL_ACCOUNT = 'EDIT_FINANCIAL_ACCOUNT';

export const getDefaultFinancialCategories = t => [
  {
    name: t('financial.actual'), id: 1, canIncrease: true, canDecrease: true,
  },
  {
    name: t('financial.bank'), id: 2, canIncrease: true, canDecrease: true,
  },
  {
    name: t('financial.supply'), id: 3, canIncrease: false, canDecrease: true,
  },
  {
    name: t('financial.shift'), id: 4, canIncrease: true, canDecrease: true,
  },
];

export function getTransactions(persisted = false) {
  return (dispatch) => {
    if (persisted || !isOnline()) {
      return getPersistedFinancialTransaction()
        .then(data => dispatch({ type: GET_TRANSACTIONS, payload: data }));
    }
    return getFinancialTransactionsRequest()
      .then(res => dispatch({ type: GET_TRANSACTIONS, payload: res.data }))
      .catch(showErrorMessage);
  };
}

export function getTransactionsDelta(delta = 10) {
  return dispatch => getFinancialTransactionDeltaRequest(delta)
    .then(res => dispatch({ type: GET_TRANSACTIONS_DELTA, payload: res.data }))
    .catch(showErrorMessage);
}

export function editFinancialAccount(account, withNotification = true) {
  return dispatch => editFinancialAccountRequest(account.id, account)
    .then((res) => {
      const newAccount = { ...res.data, id: parseInt(account.id, 10) };

      dispatch({ type: EDIT_FINANCIAL_ACCOUNT, payload: newAccount });

      persistWithKey(newAccount.id, newAccount, idbFinancialAccount);

      if (withNotification) dispatch(showMessage({ text: i18n.t('actions.financialAccountEditedTitle') }));
    })
    .catch(showErrorMessage);
}

export function getFinancialAccounts(persisted = false) {
  return (dispatch) => {
    if (persisted || !isOnline()) {
      return getPersistedFinancialAccount()
        .then(data => dispatch({ type: GET_FINANCIAL_ACCOUNTS, payload: data }));
    }
    return getFinancialAccountRequest()
      .then(res => dispatch({ type: GET_FINANCIAL_ACCOUNTS, payload: res.data }))
      .catch(showErrorMessage);
  };
}

export function addTransaction(data) {
  return async (dispatch) => {
    const id = generateId();
    addFinancialTransactionRequest(id, data).then((res) => {
      // Create app notification
      dispatch(showMessage({ text: i18n.t('actions.financialTransactionCreatedTitle') }));

      persistWithKey(id, { ...res.data, id }, idbFinancialTransaction);

      dispatch(getFinancialAccounts());

      return dispatch({ type: ADD_TRANSACTION, payload: { ...res.data, id } });
    })
      .catch(showErrorMessage);
  };
}

export function removeTransaction(id) {
  return dispatch => removeFinancialTransactionRequest(id).then(() => {
    // Create app notification
    dispatch(showMessage({ text: i18n.t('actions.financialTransactionDeletedTitle') }));

    dispatch(getFinancialAccounts());

    removeDataFromPersist(id, idbFinancialTransaction);

    return dispatch({ type: REMOVE_TRANSACTION, payload: id });
  })
    .catch(showErrorMessage);
}

export function editTransaction(transaction) {
  return dispatch => editFinancialTransactionRequest(transaction.id, transaction).then(() => {
    dispatch(showMessage({ text: i18n.t('actions.financialTransactionEditedTitle') }));

    persistWithKey(transaction.id, transaction, idbFinancialTransaction);

    dispatch(getFinancialAccounts());

    dispatch({ type: EDIT_TRANSACTION, payload: transaction });
  })
    .catch(showErrorMessage);
}

export function getFinancialCategories(persisted = false) {
  return (dispatch) => {
    if (persisted || !isOnline()) {
      return getPersistedFinancialCategory()
        .then(data => dispatch({ type: GET_FINANCIAL_CATEGORIES, payload: data }));
    }
    return getFinancialCategoryRequest()
      .then(res => dispatch({ type: GET_FINANCIAL_CATEGORIES, payload: res.data }))
      .catch(showErrorMessage);
  };
}

export function editFinancialCategory(category) {
  return (dispatch) => {
    const newCategory = { ...category };
    delete newCategory.id;
    editFinancialCategoryRequest(category.id, newCategory)
      .then((res) => {
        const data = { ...res.data, id: category.id };

        dispatch({ type: EDIT_FINANCIAL_CATEGORY, payload: data });

        persistWithKey(data.id, data, idbFinancialCategory);

        dispatch(showMessage({ text: i18n.t('actions.financialCategoryEditedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function addFinancialCategory(data) {
  return (dispatch) => {
    const id = generateId();
    addFinancialCategoryRequest(id, data)
      .then((res) => {
        const category = { ...res.data, id };

        dispatch({ type: ADD_FINANCIAL_CATEGORY, payload: category });

        persistWithKey(id, category, idbFinancialCategory);

        dispatch(showMessage({ text: i18n.t('actions.financialCategoryCreatedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function removeFinancialCategory(id) {
  return dispatch => removeFinancialCategoryRequest(id)
    .then(() => {
      dispatch({ type: REMOVE_FINANCIAL_CATEGORY, payload: id });

      removeDataFromPersist(id, idbFinancialCategory);

      dispatch(showMessage({ text: i18n.t('actions.financialCategoryDeletedTitle') }));
    })
    .catch(showErrorMessage);
}

export function addFinancialAccount(account) {
  return (dispatch) => {
    const id = generateId();
    addFinancialAccountRequest(id, account)
      .then((res) => {
        const newAccount = { ...res.data, id };

        dispatch({ type: ADD_FINANCIAL_ACCOUNT, payload: newAccount });

        persistWithKey(newAccount.id, newAccount, idbFinancialAccount);

        dispatch(showMessage({ text: i18n.t('actions.financialAccountCreatedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function removeFinancialAccount(id) {
  return dispatch => removeFinancialAccountRequest(id)
    .then(() => {
      dispatch({ type: REMOVE_FINANCIAL_ACCOUNT, payload: id });

      removeDataFromPersist(id, idbFinancialAccount);

      dispatch(showMessage({ text: i18n.t('actions.financialAccountDeletedTitle') }));
    })
    .catch(showErrorMessage);
}
