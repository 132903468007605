import { financialAccountsTourSteps } from './steps';

export const getFinancialAccountsTours = (t, goToNextStep, lastStepEvent) => [
  {
    pageRegExp: /\w/g,
    id: 3,
    isComplete: false,
    redirect: '/app/financial/accounts',
    title: t('reactour.financialAccounts.title'),
    beforeEnter: () => {
      const layoutSelector = document.querySelector('[data-tour="financial-tour-0"]');
      if (layoutSelector.classList.contains('active-parent')) {
        layoutSelector.click();
      }
    },
    steps: financialAccountsTourSteps(t, goToNextStep, lastStepEvent),
  },
];
