import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input, Modal, Checkbox } from 'antd';
import { ModalHOC } from '../../../common';
import { Column, Row } from '../../../components';
import { notificationTypes, showMessage } from '../../../redux/message/actions';
import { addFinancialCategory } from '../../../redux/financial/actions';

class AddFinancialCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      canIncrease: true,
      canDecrease: true,
    };
  }

  create = () => {
    const { t, close, actions: { addFinancialCategory, showMessage } } = this.props;
    const {
      name, canIncrease, canDecrease,
    } = this.state;
    if (name.trim() === '') {
      showMessage({ text: t('validation.emptyName'), type: notificationTypes.ERROR });
      return;
    }
    if (!canIncrease && !canDecrease) {
      showMessage({ text: t('validation.emptyCategory'), type: notificationTypes.ERROR });
      return;
    }
    addFinancialCategory({
      name, canIncrease, canDecrease,
    });
    close();
  };

  render() {
    const { t, close, visible } = this.props;
    const {
      name, canIncrease, canDecrease,
    } = this.state;
    return (
      <Modal
        maskClosable={false}
        centered
        visible={visible}
        onOk={this.create}
        onCancel={close}
        width="100%"
        style={{ maxWidth: '450px' }}
        okText={t('control.create')}
        okButtonProps={{ size: 'large' }}
        cancelButtonProps={{ size: 'large' }}
        cancelText={t('control.cancel')}
        title={t('financial.addNewCategory')}
      >
        <Column
          width="100%"
          alignItems="flex-start"
          margin="0 auto"
          className="default-card"
          style={{ maxWidth: '324px' }}
        >
          <h3 style={{ margin: '0 0 5px' }}>{t('global.name')}</h3>
          <Input
            style={{ width: '100%' }}
            placeholder={t('global.name')}
            value={name}
            onChange={e => this.setState({ name: e.target.value })}
          />
          <h3 style={{ margin: '10px 0 5px' }}>{t('financial.allowedOperation')}</h3>
          <Row width="100%" justifyContent="flex-start">
            <Checkbox
              className="checkbox-large"
              style={{ margin: '5px 0' }}
              checked={canIncrease}
              onClick={() => this.setState(state => ({ canIncrease: !state.canIncrease }))}
            >{t('financial.income')}
            </Checkbox>
            <Checkbox
              className="checkbox-large"
              style={{ margin: '0 0 0 25px' }}
              checked={canDecrease}
              onClick={() => this.setState(state => ({ canDecrease: !state.canDecrease }))}
            >{t('financial.waste')}
            </Checkbox>
          </Row>
        </Column>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        addFinancialCategory,
      },
      dispatch,
    ),
  };
}
export default ModalHOC(connect(null, mapDispatchToProps)(withTranslation()(AddFinancialCategory)));
