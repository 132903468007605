import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { showMessage } from '../../../redux/message/actions';
import FinancialCategories from './Categories';
import { removeFinancialCategory } from '../../../redux/financial/actions';

function mapStateToProps(state) {
  return {
    financial: state.financial,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        removeFinancialCategory,
      },
      dispatch,
    ),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FinancialCategories));
