import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { showMessage } from '../../../redux/message/actions';
import { removeSupplier } from '../../../redux/storage/actions';
import Suppliers from './Suppliers';

function mapStateToProps(state) {
  return {
    suppliers: state.storage.suppliers,
    suppliersLoaded: state.storage.suppliersLoaded,
    canInteract: state.business.canInteract,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        removeSupplier,
      },
      dispatch,
    ),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Suppliers));
