import { generateId } from '../../utils/helpers';
import {
  addInventoryRequest, addSupplierRequest, addSupplyRequest, addWasteRequest, editInventoryRequest, editSupplierRequest,
  editSupplyRequest, editWasteRequest, getInventoryRequest, getStorageRequest, getSuppliersRequest, getSupplyRequest,
  getWasteRequest, removeInventoryRequest, removeStoreItemRequest, removeSupplierRequest, removeSupplyRequest,
  removeWasteRequest, setStoreItemRequest,
} from '../../api/db/storage';
import { editConsumableRequest } from '../../api/db/equipment';
import { editComponentRequest, editItemRequest } from '../../api/db/menu';
import { showErrorMessage, showMessage } from '../message/actions';
import i18n from '../../utils/translation';
import {
  getPersistedStoreInventory, getPersistedStoreStorage, getPersistedStoreSuppliers, getPersistedStoreSupply,
  getPersistedStoreWaste, isOnline, persist, removeDataFromPersist,
} from '../../persistance';
import {
  idbStoreInventory, idbStoreStorage, idbStoreSuppliers, idbStoreSupply, idbStoreWaste,
} from '../../config';

export const GET_STORE = 'GET_STORE';
export const ADD_STORE_ITEM = 'ADD_STORE_ITEM';
export const REMOVE_STORE_ITEM = 'REMOVE_STORE_ITEM';
export const GET_SUPPLIERS = 'GET_SUPPLIERS';
export const ADD_SUPPLIER = 'ADD_SUPPLIER';
export const EDIT_SUPPLIER = 'EDIT_SUPPLIER';
export const REMOVE_SUPPLIER = 'REMOVE_SUPPLIER';
export const GET_SUPPLY = 'GET_SUPPLY';
export const ADD_SUPPLY = 'ADD_SUPPLY';
export const EDIT_SUPPLY = 'EDIT_SUPPLY';
export const REMOVE_SUPPLY = 'REMOVE_SUPPLY';
export const GET_WASTE = 'GET_WASTE';
export const EDIT_WASTE = 'EDIT_WASTE';
export const ADD_WASTE = 'ADD_WASTE';
export const REMOVE_WASTE = 'REMOVE_WASTE';
export const GET_INVENTORY = 'GET_INVENTORY';
export const ADD_INVENTORY = 'ADD_INVENTORY';
export const REMOVE_INVENTORY = 'REMOVE_INVENTORY';
export const EDIT_INVENTORY = 'EDIT_INVENTORY';

export function calculateConsumption(data, action = '+') {
  return (dispatch, getState) => {
    const { store } = getState().storage;
    const consumptions = data.map(el => el.item);

    const newStore = store.map(el => (consumptions.includes(el.id)
      ? {
        ...el,
        count: parseInt(el.count, 10) + parseInt(
          (action === '+' ? 1 : -1) * data.find(consumption => consumption.item === el.id).count, 10,
        ),
      } : el));
    dispatch({ type: GET_STORE, payload: newStore });

    newStore.forEach(item => setStoreItemRequest(item.id, item));
  };
}

function updateOnStore(data) {
  return (dispatch, getState) => {
    const { menu: { components, items }, equipment: { consumables } } = getState();

    const itemsMap = data.items.reduce((res, el) => ({ ...res, [el.item]: el }), {});
    const newComponents = components.map((component) => {
      let data = {};
      const item = itemsMap[component.id];
      if (item) {
        const { count, price, cost } = item;
        data = { supplyCount: count, cost };
        if (price) {
          data.supplyPrice = price;
        }
      }
      return { ...component, ...data };
    });
    const newItems = items.map((el) => {
      let data = {};
      const item = itemsMap[el.id];
      if (item) {
        const { count, price, cost } = item;
        data = { supplyCount: count, cost };
        if (price) {
          data.supplyPrice = price;
        }
      }
      return { ...el, ...data };
    });
    const newConsumables = consumables.map((consumable) => {
      let data = {};
      const item = itemsMap[consumable.id];
      if (item) {
        const { count, price, cost } = item;
        data = { supplyCount: count, cost };
        if (price) {
          data.supplyPrice = price;
        }
      }
      return { ...consumable, ...data };
    });

    dispatch(calculateConsumption(data.items));
    newComponents.forEach(component => editComponentRequest(component.id, component));
    newConsumables.forEach(consumable => editConsumableRequest(consumable.id, consumable));
    newItems.forEach(item => editItemRequest(item.id, item));
  };
}

export function getStorage(persisted = false) {
  return (dispatch) => {
    if (persisted || !isOnline()) {
      return getPersistedStoreStorage()
        .then(data => dispatch({ type: GET_STORE, payload: data }));
    }
    return getStorageRequest()
      .then(res => dispatch({ type: GET_STORE, payload: res.data }))
      .catch(showErrorMessage);
  };
}

export function addStoreItem(id, type, count) {
  return (dispatch) => {
    const storeItem = { id, type, count: parseInt(count, 10) };

    return setStoreItemRequest(id, storeItem)
      .then((res) => {
        const item = { ...res.data, id: parseInt(id, 10) };

        dispatch({ type: ADD_STORE_ITEM, payload: item });

        persist(item, idbStoreStorage);
      })
      .catch(showErrorMessage);
  };
}

export function removeStoreItem(id) {
  return dispatch => removeStoreItemRequest(id)
    .then(() => {
      dispatch({ type: REMOVE_STORE_ITEM, payload: id });

      removeDataFromPersist(id, idbStoreStorage);
    })
    .catch(showErrorMessage);
}

export function getSuppliers(persisted = false) {
  return (dispatch) => {
    if (persisted || !isOnline()) {
      return getPersistedStoreSuppliers()
        .then(data => dispatch({ type: GET_SUPPLIERS, payload: data }));
    }
    return getSuppliersRequest()
      .then(res => dispatch({ type: GET_SUPPLIERS, payload: res.data }))
      .catch(showErrorMessage);
  };
}

export function addSupplier(data) {
  return (dispatch) => {
    const id = generateId();

    return addSupplierRequest(id, data)
      .then((res) => {
        const supplier = { ...res.data, id };

        dispatch({ type: ADD_SUPPLIER, payload: supplier });

        persist(supplier, idbStoreSuppliers);

        dispatch(showMessage({ text: i18n.t('actions.storageSupplierCreatedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function editSupplier(data) {
  return (dispatch) => {
    const supplier = { ...data };
    delete supplier.key;
    delete supplier.id;

    return editSupplierRequest(data.id, supplier)
      .then((res) => {
        const supplier = { ...res.data, id: data.id };

        dispatch({ type: EDIT_SUPPLIER, payload: supplier });

        persist(supplier, idbStoreSuppliers);

        dispatch(showMessage({ text: i18n.t('actions.storageSupplierEditedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function removeSupplier(id) {
  return dispatch => removeSupplierRequest(id)
    .then(() => {
      dispatch({ type: REMOVE_SUPPLIER, payload: id });

      removeDataFromPersist(id, idbStoreSuppliers);

      dispatch(showMessage({ text: i18n.t('actions.storageSupplierDeletedTitle') }));
    })
    .catch(showErrorMessage);
}

export function getSupply(persisted = false) {
  return (dispatch) => {
    if (persisted || !isOnline()) {
      return getPersistedStoreSupply()
        .then(data => dispatch({ type: GET_SUPPLY, payload: data }));
    }
    return getSupplyRequest()
      .then(res => dispatch({ type: GET_SUPPLY, payload: res.data }))
      .catch(showErrorMessage);
  };
}

export function addSupply(supply) {
  return (dispatch) => {
    const id = generateId();

    return addSupplyRequest(id, supply)
      .then((res) => {
        dispatch({ type: ADD_SUPPLY, payload: { ...res.data, id } });
        // TODO: move to BE
        dispatch(updateOnStore(supply));

        persist({ ...res.data, id }, idbStoreSupply);

        dispatch(showMessage({ text: i18n.t('actions.storageSupplyCreatedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function removeSupply(id) {
  return async (dispatch, getState) => {
    const { supply } = getState().storage;
    const supplyForRemove = supply.find(el => el.id === id);

    return removeSupplyRequest(id)
      .then(() => {
        dispatch({ type: REMOVE_SUPPLY, payload: id });
        // TODO: move to BE
        dispatch(calculateConsumption(supplyForRemove.items, '-'));

        removeDataFromPersist(id, idbStoreSupply);

        dispatch(showMessage({ text: i18n.t('actions.storageSupplyDeletedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function editSupply(data) {
  return async (dispatch, getState) => {
    const { supply } = getState().storage;

    const supplyForRemove = supply.find(el => el.id === data.id);
    const newSupply = { ...data };

    delete newSupply.id;

    return editSupplyRequest(data.id, newSupply)
      .then((res) => {
        const supply = { ...res.data, id: parseInt(data.id, 10) };

        dispatch({ type: EDIT_SUPPLY, payload: supply });
        // TODO: refactor with BE
        dispatch(calculateConsumption(supplyForRemove.items, '-'));

        dispatch(updateOnStore(data));

        persist(supply, idbStoreSupply);

        dispatch(showMessage({ text: i18n.t('actions.storageSupplyEditedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function getWaste(persisted = false) {
  return (dispatch) => {
    if (persisted || !isOnline()) {
      return getPersistedStoreWaste()
        .then(data => dispatch({ type: GET_WASTE, payload: data }));
    }
    return getWasteRequest()
      .then(res => dispatch({ type: GET_WASTE, payload: res.data }))
      .catch(showErrorMessage);
  };
}

export function addWaste(waste) {
  return (dispatch) => {
    const id = generateId();

    return addWasteRequest(id, waste)
      .then((res) => {
        dispatch({ type: ADD_WASTE, payload: { ...res.data, id } });

        dispatch(calculateConsumption(waste.items, '-'));

        persist({ ...res.data, id }, idbStoreWaste);

        dispatch(showMessage({ text: i18n.t('actions.storageWasteCreatedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function removeWaste(id) {
  return async (dispatch, getState) => {
    const { waste } = getState().storage;
    const wasteForRemove = waste.find(el => el.id === id);

    return removeWasteRequest(id)
      .then(() => {
        dispatch({ type: REMOVE_WASTE, payload: id });
        // TODO: move to BE
        dispatch(calculateConsumption(wasteForRemove.items));

        removeDataFromPersist(id, idbStoreWaste);

        dispatch(showMessage({ text: i18n.t('actions.storageWasteDeletedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function editWaste(data) {
  return async (dispatch, getState) => {
    const { waste } = getState().storage;

    const wasteForRemove = waste.find(el => el.id === data.id);

    const newWaste = { ...data };

    delete newWaste.id;

    return editWasteRequest(data.id, newWaste)
      .then((res) => {
        dispatch(calculateConsumption(data.items, '-'));

        dispatch(calculateConsumption(wasteForRemove.items));

        persist(data, idbStoreWaste);

        dispatch({ type: EDIT_WASTE, payload: { ...res.data, id: data.id } });

        dispatch(showMessage({ text: i18n.t('actions.storageWasteEditedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function getInventory(persisted = false) {
  return (dispatch) => {
    if (persisted || !isOnline()) {
      return getPersistedStoreInventory()
        .then(data => dispatch({ type: GET_INVENTORY, payload: data }));
    }
    return getInventoryRequest()
      .then(res => dispatch({ type: GET_INVENTORY, payload: res.data }))
      .catch(showErrorMessage);
  };
}

export function addInventory(data) {
  return async (dispatch, getState) => {
    const { store } = getState().storage;
    const id = generateId();
    const newInventory = { ...data };

    delete newInventory.key;

    return addInventoryRequest(id, newInventory)
      .then((res) => {
        // TODO: Move to BE
        const idMap = data.items.map(el => el.item);

        const newStore = store.map(el => (idMap.includes(el.id)
          ? { ...el, count: data.items.find(inventory => inventory.item === el.id).count } : el));

        dispatch({ type: ADD_INVENTORY, payload: { ...res.data, id } });

        newStore.forEach(item => setStoreItemRequest(item.id, item));

        persist({ ...res.data, id }, idbStoreInventory);

        dispatch({ type: GET_STORE, payload: newStore });

        dispatch(showMessage({ text: i18n.t('actions.storageInventoryCreatedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function removeInventory(id) {
  return async (dispatch, getState) => {
    const { inventories } = getState().storage;
    const inventoryToRemove = inventories.find(el => el.id === id);

    return removeInventoryRequest(id)
      .then(() => {
        dispatch({ type: REMOVE_INVENTORY, payload: id });
        // TODO: move to BE
        dispatch(calculateConsumption(
          inventoryToRemove.items.map(el => ({ ...el, count: parseInt(el.diff, 10) })), '+',
        ));

        removeDataFromPersist(id, idbStoreInventory);

        dispatch(showMessage({ text: i18n.t('actions.storageInventoryDeletedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function editInventory(inventory) {
  return async (dispatch, getState) => {
    const newInventory = { ...inventory };

    delete newInventory.id;
    delete newInventory.key;

    const { store } = getState().storage;

    return editInventoryRequest(inventory.id, newInventory)
      .then((res) => {
        // TODO: move to BE

        const idMap = inventory.items.map(el => el.item);

        const newStore = store.map(el => (idMap.includes(el.id)
          ? { ...el, count: inventory.items.find(inventory => inventory.item === el.id).count } : el));

        dispatch({ type: EDIT_INVENTORY, payload: { ...res.data, id: parseInt(inventory.id, 10) } });

        newStore.forEach(item => setStoreItemRequest(item.id, item));

        persist({ ...res.data, id: parseInt(inventory.id, 10) }, idbStoreInventory);

        dispatch({ type: GET_STORE, payload: newStore });

        dispatch(showMessage({ text: i18n.t('actions.storageInventoryEditedTitle') }));
      })
      .catch(showErrorMessage);
  };
}
