import React, { Component } from 'react';
import { Button, Table } from 'antd';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { firebase } from '../../../config';
import {
  PageHeader, Content, TopBar, Page,
} from '../../../components';
import { RangePicker } from '../../../common';
import { INCOME, TRANSFER, WASTE } from '../../../constants/transactions';
import { printPage } from '../../../utils/helpers';
import { isOnline } from '../../../persistance';
import { showNetworkError } from '../../../redux/message/actions';

class Report extends Component {
  constructor(props) {
    super(props);
    const { financial: { accounts } } = props;
    this.state = {
      pending: false,
      rangeStart: moment().subtract(1, 'week'),
      rangeEnd: moment(),
      operations: [],
      dataLoaded: accounts.length > 0,
      loadingStarted: false,
    };
  }

  componentDidMount() {
    const { activePlace, dataLoaded, financial: { transactions, transactionsLoaded } } = this.props;
    if (!isOnline()) return showNetworkError();
    if (activePlace) {
      this.updateRange(this.state, transactions);
    }
    if (!transactionsLoaded && dataLoaded) {
      this.loadData();
    }
  }

  loadData = () => {
    const { actions: { getTransactions } } = this.props;
    const { loadingStarted } = this.state;
    if (!loadingStarted) {
      this.setState({ loadingStarted: true });
      getTransactions();
    }
  }

  updateRange = ({ rangeStart, rangeEnd }, transactions = null) => {
    if (!isOnline()) return showNetworkError();
    const { financial } = this.props;
    const start = moment(rangeStart).startOf('day');
    const end = moment(rangeEnd).endOf('day');
    this.setState({ pending: true });
    this.setState({
      rangeStart,
      rangeEnd,
      pending: false,
      operations: (transactions || financial.transactions).filter((transaction) => {
        const date = moment(transaction.date, 'DD.MM.YYYY');
        return date > start && date < end;
      }),
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { activePlace, dataLoaded, financial: { transactions, transactionsLoaded } } = nextProps;
    if (this.props.activePlace !== activePlace) {
      this.updateRange(this.state, transactions);
    }
    if (this.props.financial.transactions.length !== nextProps.financial.transactions.length) {
      this.updateRange(this.state, transactions);
    }
    if (this.props.financial.accounts.length !== nextProps.financial.accounts.length) {
      this.setState({ dataLoaded: true });
    }

    if (!transactionsLoaded && dataLoaded) {
      this.loadData();
    }
  }

  render() {
    const {
      t, financial: { accounts, transactionsLoaded }, currency,
    } = this.props;
    const { operations, pending, dataLoaded } = this.state;
    const transactionsTableColumns = [
      {
        title: t('financial.account2'),
        dataIndex: 'name',
      },
      {
        title: t('global.type'),
        dataIndex: 'type',
        sorter: (a, b) => (-1) * a.type.localeCompare(b.type),
        sortDirections: ['descend'],
        render: type => t(`financial.${type}`),
      },
      {
        title: t('financial.income'),
        dataIndex: 'income',
        render: income => (income === 0 ? (
          <span className="color-gray">0 {currency}</span>
        ) : (
          <span className="color-green">+{income} {currency}</span>
        )),
      }, {
        title: t('financial.waste'),
        dataIndex: 'waste',
        render: waste => (waste === 0 ? (
          <span className="color-gray">0 {currency}</span>
        ) : (
          <span className="color-red">-{waste} {currency}</span>
        )),
      }, {
        title: t('financial.delta'),
        dataIndex: 'sum',
        className: 'table-header-right',
        render: (el, record) => {
          const delta = parseInt(record.income - record.waste, 10);
          return delta === 0 ? <span className="color-gray">0 {currency}</span> : delta > 0
            ? <span className="color-green">+{delta} {currency}</span>
            : <span className="color-red">{delta} {currency}</span>;
        },
      }, {
        title: t('global.balance'),
        dataIndex: 'count',
        sorter: (a, b) => {
          const delta = a.count - b.count;
          if (delta > 0) return -1;
          if (delta < 0) return 1;
          return 0;
        },
        sortDirections: ['descend'],
        render: text => `${text} ${currency}`,
      }];
    const accountsStatistic = {};
    accounts.forEach((el) => {
      accountsStatistic[el.id] = {
        ...el,
        count: el.count || 0,
        waste: 0,
        income: 0,
      };
    });
    if (dataLoaded) {
      operations.forEach((el) => {
        switch (el.type) {
          case INCOME: {
            const account = accountsStatistic[el.to.id];
            accountsStatistic[account.id] = {
              ...account, income: account.income + parseInt(el.incomeCount, 10),
            };
            break;
          }
          case WASTE: {
            const account = accountsStatistic[el.from.id];
            accountsStatistic[account.id] = {
              ...account, waste: account.waste + parseInt(el.wasteCount, 10),
            };
            break;
          }
          case TRANSFER: {
            const accountTo = accountsStatistic[el.to.id];
            const accountFrom = accountsStatistic[el.from.id];
            accountsStatistic[accountTo.id] = {
              ...accountTo, income: accountTo.income + parseInt(el.incomeCount, 10),
            };
            accountsStatistic[accountFrom.id] = {
              ...accountFrom, waste: accountFrom.waste + parseInt(el.wasteCount, 10),
            };
            break;
          }
          default:
        }
      });
    }
    return (
      <Page spinning={!firebase.auth().currentUser || pending || !transactionsLoaded}>
        <PageHeader />
        <Content>
          <TopBar data-tour="financialTransactions-tour-2">
            <h3 className="storage-page-title">{t('financial.report')}</h3>
            <Button
              className="no-print mobile-bottom-margin mobile-right-margin"
              size="large"
              onClick={() => printPage()}
              type="secondary"
            >{t('control.print')}
            </Button>
            <RangePicker callback={this.updateRange} rangeStart={moment().subtract(1, 'week')} />
          </TopBar>
          <Table
            bordered
            pagination={false}
            size="default"
            className="storage-page-table"
            columns={transactionsTableColumns}
            dataSource={Object.keys(accountsStatistic).map(key => accountsStatistic[key]).map(el => ({
              ...el, key: el.id,
            }))}
            onChange={this.handleChange}
          />
        </Content>
      </Page>
    );
  }
}

export default withTranslation()(Report);
