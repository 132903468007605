import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import KeeperLogin from './KeeperLogin';
import { showMessage } from '../../../redux/message/actions';
import { initRedux } from '../../../redux/user/actions';
import { setEmployer } from '../../../redux/keeper/actions';

function mapStateToProps(state) {
  return {
    activeShift: state.shift.activeShift,
    activeKey: state.statistic.activeKey,
    name: state.business.name,
    tariff: state.business.tariff,
    dataLoaded: state.business.dataLoaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setEmployer,
        showMessage,
        initRedux,
      },
      dispatch,
    ),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(KeeperLogin));
