import { menuTourSteps } from './steps';

export const getMenuTours = (t, goToNextStep, lastStepEvent) => [
  {
    pageRegExp: /\w/g,
    id: 3,
    isComplete: false,
    redirect: '/app/menu',
    title: t('reactour.menu.title'),
    steps: menuTourSteps(t, goToNextStep, lastStepEvent),
  },
];
