import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input, Modal, Select } from 'antd';
import { ModalHOC } from '../../../common';
import { Column } from '../../../components';
import { notificationTypes, showMessage } from '../../../redux/message/actions';
import { editFinancialAccount } from '../../../redux/financial/actions';

class EditFinancialAccount extends Component {
  constructor(props) {
    super(props);
    const { item: { name, type, count } } = props;
    this.state = {
      name, type, count,
    };
  }

  create = () => {
    const {
      item, t, close, actions: { editFinancialAccount, showMessage },
    } = this.props;
    const { name, type, count } = this.state;
    if (name.trim() === '') {
      showMessage({ text: t('validation.emptyName'), type: notificationTypes.ERROR });
      return;
    }
    editFinancialAccount({
      ...item, name, type, count,
    });
    close();
  };

  render() {
    const {
      t, close, visible, item: { id },
    } = this.props;
    const { name, type } = this.state;
    return (
      <Modal
        maskClosable={false}
        centered
        visible={visible}
        onOk={this.create}
        onCancel={close}
        width="100%"
        style={{ maxWidth: '450px' }}
        okText={t('control.save')}
        okButtonProps={{ size: 'large' }}
        cancelButtonProps={{ size: 'large' }}
        cancelText={t('control.cancel')}
        title={t('financial.editAccount')}
      >
        <Column
          width="100%"
          style={{ maxWidth: '324px' }}
          alignItems="flex-start"
          className="default-card"
          margin="0 auto"
        >
          <h3 style={{ margin: '0 0 5px' }}>{t('global.name')}</h3>
          <Input
            style={{ width: '100%' }}
            placeholder={t('global.name')}
            value={name}
            onChange={e => this.setState({ name: e.target.value })}
          />
          <h3 style={{ margin: '10px 0 5px' }}>{t('global.type')}</h3>
          <Select
            style={{ width: '100%' }}
            disabled={id === 1 || id === 2}
            value={type}
            onChange={type => this.setState({ type })}
          >
            <Select.Option value="cash">{t('financial.cash')}</Select.Option>
            <Select.Option value="card">{t('financial.card')}</Select.Option>
            <Select.Option value="account">{t('financial.account')}</Select.Option>
          </Select>
        </Column>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        editFinancialAccount,
      },
      dispatch,
    ),
  };
}
export default ModalHOC(connect(null, mapDispatchToProps)(withTranslation()(EditFinancialAccount)));
