import React, { Component } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { withTranslation } from 'react-i18next';
import { firebase } from '../../../config';
import {
  Content, Page, PageHeader, Row, TopBar,
} from '../../../components';
import AddJobs from './AddJobs';
import EditJob from './EditJob';
import { alphabeticalSort } from '../../../utils/helpers';
import { deleteModal } from '../../../utils/modals';
import './style.scss';
import { isOnline } from '../../../persistance';
import { showNetworkError } from '../../../redux/message/actions';

class Jobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
    };
  }

  selectItem = (record) => {
    if (!isOnline()) return showNetworkError();
    const { access: { jobs } } = this.props;
    const selectedItem = jobs.find(el => el.name === record.name);
    this.setState({ selectedItem });
    this.editJob.show();
  };

  render() {
    const {
      t, access: { jobs, employers }, currency, actions: { removeJob },
    } = this.props;
    const { selectedItem } = this.state;
    const jobsTableColumns = [{
      title: t('global.name'),
      dataIndex: 'name',
      sorter: (a, b) => (-1) * a.name.localeCompare(b.name),
      sortDirections: ['descend'],
      width: 120,
    },
    {
      title: t('job.salaryPerShift'),
      dataIndex: 'salaryPerShift',
      render: text => (text ? `${text} ${currency}` : '-'),
    },
    {
      title: t('job.salaryPerMonth'),
      dataIndex: 'salaryPerMonth',
      render: text => (text ? `${text} ${currency}` : '-'),
    },
    { title: t('job.flexRatePersonal'), dataIndex: 'flexRatePersonal', render: text => (text ? `${text} %` : '-') },
    { title: t('job.flexRateTotal'), dataIndex: 'flexRateTotal', render: text => (text ? `${text} %` : '-') },
    {
      title: '',
      width: '30px',
      render: (text, record) => (
        <Row width="100%" justifyContent="center">
          <Button onClick={() => this.selectItem(record)} type="link" icon={<EditOutlined />} />
        </Row>
      ),
    }, {
      title: '',
      render: (text, record) => !employers.find(employer => employer.position === record.id) && (
        <Row width="100%" justifyContent="center">
          <Button onClick={() => deleteModal(() => removeJob(record.id))} type="link" icon={<DeleteOutlined />} />
        </Row>
      ),
    }];
    return (
      <Page spinning={!firebase.auth().currentUser}>
        <PageHeader />
        <Content>
          <TopBar data-tour="jobs-tour-2">
            <h3 className="storage-page-title">{t('layout.jobs')}: {jobs.length}</h3>
            <Button
              className="add-button"
              size="large"
              onClick={() => {
                if (!isOnline()) return showNetworkError();
                this.addJob.show();
              }}
            >{t('control.add')}
            </Button>
          </TopBar>
          <Table
            bordered
            pagination={false}
            size="default"
            className="storage-page-table"
            columns={jobsTableColumns}
            dataSource={alphabeticalSort(jobs).map((el, index) => ({ ...el, key: index }))}
          />
        </Content>
        <AddJobs ref={(ref) => { this.addJob = ref; }} />
        <EditJob job={selectedItem} ref={(ref) => { this.editJob = ref; }} />
      </Page>
    );
  }
}

export default withTranslation()(Jobs);
