import React, { Component, Fragment } from 'react';
import { Button, Modal } from 'antd';
import { withTranslation } from 'react-i18next';
import { Column, Row } from '../index';
import { getDefaultImageByType, getEmptyMaterial } from '../../utils/helpers';

class ManageEquipments extends Component {
  constructor(props) {
    super(props);
    const { selectedConsumables } = props;
    this.state = {
      modalVisible: false,
      selectedConsumables,
    };
  }

  submit = () => {
    const { callback } = this.props;
    const { selectedConsumables } = this.state;
    callback(selectedConsumables);
    this.setState({ modalVisible: false });
  };

  setConsumable = (consumableName, id) => this.setState(state => ({
    selectedConsumables: { ...state.selectedConsumables, [consumableName]: id },
  }));

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.size !== this.props.size) {
      this.setState({ selectedConsumables: nextProps.selectedConsumables });
    }
  }

  render() {
    const { t, consumables, equipment: { idMap } } = this.props;
    const { modalVisible, selectedConsumables } = this.state;
    return (
      <Column width="100%" alignItems="flex-start">
        <Button onClick={() => this.setState({ modalVisible: true })}>{t('equipment.manage')}</Button>
        <Modal
          maskClosable={false}
          visible={modalVisible}
          footer={null}
          width="95vw"
          centered
          title={t('consumable.select')}
          onCancel={() => this.setState({ modalVisible: false })}
        >
          <Column width="100%" alignItems="flex-start">
            {consumables.map(consumable => (
              <Fragment key={JSON.stringify(consumable)}>
                <h4>{consumable.name}</h4>
                <Row
                  width="100%"
                  justifyContent="flex-start"
                  flexWrap="wrap"
                  margin="0 0 10px"
                >
                  {[...consumable.materials, 0].map((id) => {
                    // 0 is a id of empty material
                    const item = idMap[id] || getEmptyMaterial(t);
                    return (
                      <div
                        style={{ margin: '0 10px 10px 0' }}
                        className={`add-item-addon-card 
                        ${selectedConsumables[consumable.name] === id ? 'checked' : ''}`}
                        key={`consumable-card-${consumable.name}-${id}`}
                        onClick={() => this.setConsumable(consumable.name, id)}
                      >
                        <h3>{item.name}</h3>
                        <img
                          src={item.photo || getDefaultImageByType('consumable')}
                          style={{ opacity: item.photo ? 1 : 0.5 }}
                          alt=""
                          className="card-img"
                        />
                      </div>
                    );
                  })}
                </Row>
              </Fragment>
            ))}
            <Button style={{ marginLeft: 'auto' }} onClick={this.submit} type="primary">{t('control.save')}</Button>
          </Column>
        </Modal>
      </Column>
    );
  }
}

export default withTranslation()(ManageEquipments);
