import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Input, Modal, Select, Tag,
} from 'antd';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { ModalHOC } from './index';
import { notificationTypes, showMessage } from '../redux/message/actions';
import { Column, Row } from '../components';
import { addCashShiftData } from '../redux/shift/actions';
import { validateNumberInput } from '../utils/helpers';
import { WASTE } from '../constants/transactions';
import NumberInput from './NumberInput';
import { addTransaction, getDefaultFinancialCategories } from '../redux/financial/actions';

const { CheckableTag } = Tag;

class AddTransactionToShift extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      description: '',
      price: 0,
      type: WASTE,
      account: 0,
      category: t('financial.shift'),
    };
  }

  close = () => {
    const {
      t, close, employer: { id, name }, actions: { addCashShiftData, addTransaction, showMessage },
      shift: { activeShift },
    } = this.props;
    const {
      description, price, type, account, category,
    } = this.state;

    if (price === '' || price === 0) {
      showMessage({ text: t('validation.emptySum'), type: notificationTypes.ERROR });
      return;
    }

    if (account === '1') {
      const { accounts } = this.props;
      addTransaction({
        type,
        from: accounts.find(account => account.id === 1),
        comment: description,
        date: moment().format('DD.MM.YYYY'),
        to: '',
        category,
        wasteCount: price,
        incomeCount: '',
      });
    }
    addCashShiftData({
      ...activeShift,
      transactions: [...activeShift.transactions, {
        employer: { id, name },
        time: Date.now(),
        description,
        price,
        type,
        account,
        category,
        date: moment().format('DD.MM.YYYY'),
      }],
    });
    close();
  };

  updatePrice = price => this.setState({ price });

  render() {
    const {
      t, close, visible, business, categories,
    } = this.props;
    const { description, type } = this.state;
    return (
      <Modal
        maskClosable={false}
        visible={visible}
        onCancel={close}
        onOk={this.close}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ size: 'large', type: 'primary' }}
        width="424px"
        okText={t('control.add')}
        title={t('financial.addNewTransaction')}
      >
        <Column
          style={{ maxWidth: '400px' }}
          width="100%"
          margin="0 auto"
          alignItems="flex-start"
          className="default-card"
        >
          <h3>{t('financial.operation')}</h3>
          <Row width="100%" justifyContent="flex-start">
            {[WASTE].map(el => (
              <CheckableTag
                className="create-product-tag statistic-tag"
                onChange={() => this.setState({ type: el })}
                key={el}
                checked={type === el}
              >
                {t(`financial.${el}`)}
              </CheckableTag>
            ))}
          </Row>
          <h3 style={{ margin: '5px 0' }}>{t('global.sum')}</h3>
          <NumberInput
            onKeyUp={validateNumberInput}
            style={{ width: '100%' }}
            type="tel"
            placeholder="0"
            onChange={e => this.updatePrice(e.target.value)}
            addonBefore={type === WASTE ? '-' : '+'}
            addonAfter={business.currency}
          />
          <h3 style={{ margin: '10px 0 5px' }}>{t('financial.from')}</h3>
          <Select
            defaultValue={t('financial.cashbox')}
            placeholder={t('global.select')}
            style={{ width: '100%' }}
            onChange={account => this.setState({ account })}
          >
            {[{ name: t('financial.cashbox'), id: 0 }, { name: t('financial.safeAccount'), id: 1 }].map(account => (
              <Select.Option key={account.id} value={account.id}>{account.name}</Select.Option>
            ))}
          </Select>
          <h3 style={{ margin: '10px 0 5px' }}>{t('global.category')}</h3>
          <Select
            placeholder={t('global.select')}
            defaultValue={t('financial.shift')}
            style={{ width: '100%' }}
            onChange={name => this.setState({ category: name })}
          >
            {[...getDefaultFinancialCategories(t), ...categories].filter(el => el.canDecrease).map(category => (
              <Select.Option key={category.name} value={category.name}>{category.name}</Select.Option>
            ))}
          </Select>
          <h3 style={{ margin: '10px 0 5px' }}>{t('supply.comment')}</h3>
          <Input
            value={description}
            style={{ width: '100%' }}
            onChange={e => this.setState({ description: e.target.value })}
          />
        </Column>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    shift: state.shift,
    business: state.business,
    categories: state.financial.categories,
    accounts: state.financial.accounts,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        addCashShiftData,
        addTransaction,
      },
      dispatch,
    ),
  };
}

export default ModalHOC(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddTransactionToShift)));
