import React from 'react';
import { InfoCircleOutlined, QuestionOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  getMenuTours, getEquipmentTours, getComponentTours, getSupplyTours, getWasteTours, getInventoryTours,
  getSubscriptionTours, getConsumptionTours, getFinancialAccountsTours, getFinancialCategoriesTours,
  getFinancialShiftsTours, getFinancialTransactionsTours, getEmployersTours, getJobsTours, getFirstViewTour,
} from './tours';
import { addAdminAccess, updatePlaceInfo } from '../../redux/business/actions';
import './style.scss';

const PopoverContent = ({ selectTour, courses }) => (
  <div className="tour-guide-popover">
    {courses.map(course => (
      <div
        key={course.title}
        onClick={() => {
          if (course.beforeEnter) {
            course.beforeEnter();
            setTimeout(() => selectTour(course), 200);
          } else {
            selectTour(course);
          }
        }}
        className="courseEntity"
      >
        <InfoCircleOutlined style={{ fontSize: '14px', color: '#303641' }} />
        <span>{course.title}</span>
      </div>
    ))}
  </div>
);

class TourGuide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTourOpen: false,
      selectedTour: null,
      currentStep: 0,
      withInteraction: true,
      updateHandler: '',
      visiblePopover: false,
    };
  }

  completeFirstTour = () => {
    const { actions: { updatePlaceInfo } } = this.props;
    updatePlaceInfo({ completeFirstView: true }).then(() => this.setState({ isTourOpen: false }));
  };

  getToursByPage = () => {
    const { t, history } = this.props;
    switch (history.location.pathname) {
      case '/app/menu': return getMenuTours(t, this.goToNextStep, this.finishTour);
      case '/app/equipment/consumables': return getEquipmentTours(t, this.goToNextStep, this.finishTour);
      case '/app/equipment/components': return getComponentTours(t, this.goToNextStep, this.finishTour);
      case '/app/storage/supply': return getSupplyTours(t, this.goToNextStep, this.finishTour);
      case '/app/storage/waste': return getWasteTours(t, this.goToNextStep, this.finishTour);
      case '/app/storage/inventory': return getInventoryTours(t, this.goToNextStep, this.finishTour);
      case '/app/storage/consumption': return getConsumptionTours(t, this.goToNextStep, this.finishTour);
      case '/app/financial/accounts': return getFinancialAccountsTours(t, this.goToNextStep, this.finishTour);
      case '/app/financial/categories': return getFinancialCategoriesTours(t, this.goToNextStep, this.finishTour);
      case '/app/financial/shifts': return getFinancialShiftsTours(t, this.goToNextStep, this.finishTour);
      case '/app/financial/transactions': return getFinancialTransactionsTours(t, this.goToNextStep, this.finishTour);
      case '/app/access/employers': return getEmployersTours(t, this.goToNextStep, this.finishTour);
      case '/app/access/jobs': return getJobsTours(t, this.goToNextStep, this.finishTour);
      case '/app/settings/subscription': return getSubscriptionTours(t, this.goToNextStep, this.finishTour);
      case '/app/settings/general': return [getFirstViewTour(t, this.goToNextStep, this.completeFirstTour, history)];
      default: return [];
    }
  };

  selectTour = selectedTour => this.setState({
    selectedTour, currentStep: 0, isTourOpen: true, visiblePopover: false,
  });

  disableBody = target => disableBodyScroll(target);

  checkPage = (target) => {
    const { location, history } = this.props;
    const { selectedTour } = this.state;
    this.disableBody(target);
    if (!selectedTour.pageRegExp.test(location.pathname)) {
      history.push(selectedTour.redirect);
      this.setState({ updateHandler: Date.now().toString() });
    }
  };

  enableBody = (target) => {
    enableBodyScroll(target);
    this.setState({ currentStep: 0 });
  };

  goToNextStep = () => this.setState(state => ({ currentStep: state.currentStep + 1 }));

  finishTour = () => {
    this.setState({ isTourOpen: false });
  };

  handleVisibleChange = visiblePopover => this.setState({ visiblePopover });

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { business: { completeFirstView, isAdmin } } = nextProps;
    if (!completeFirstView) {
      const { t, history } = this.props;
      const { isTourOpen } = this.state;
      if (history.location.pathname !== '/app/dashboard' && !isTourOpen) {
        history.push('/app/dashboard');
      }
      if (!isAdmin) {
        const { actions: { addAdminAccess } } = this.props;
        addAdminAccess(() => {
          this.setState({
            isTourOpen: true,
            selectedTour: getFirstViewTour(t, this.goToNextStep, this.completeFirstTour, history),
          });
        });
      } else {
        this.setState({
          isTourOpen: true,
          selectedTour: getFirstViewTour(t, this.goToNextStep, this.completeFirstTour, history),
        });
      }
    }
  }

  render() {
    const { t } = this.props;
    const {
      isTourOpen, selectedTour, currentStep, updateHandler, showAnimation, withInteraction, visiblePopover,
    } = this.state;
    const courses = this.getToursByPage();
    return (
      <div className="tour-guide-wrapper no-print" style={{ visibility: courses.length > 0 ? 'visible' : 'hidden' }}>
        <Popover
          placement="topLeft"
          content={<PopoverContent selectTour={this.selectTour} courses={courses} />}
          overlayStyle={{ position: 'fixed' }}
          title={t('reactour.popover.title')}
          trigger="click"
          className="no-print"
          visible={visiblePopover}
          onVisibleChange={this.handleVisibleChange}
        >
          <div className="tour-guide-entry">
            <QuestionOutlined style={{ fontSize: '16px', color: '#303641' }} />
          </div>
        </Popover>
        <Tour
          onAfterOpen={this.checkPage}
          onBeforeClose={this.enableBody}
          startAt={0}
          disableInteraction={withInteraction}
          update={updateHandler}
          disableKeyboardNavigation
          maskSpace={0}
          closeWithMask={false}
          disableDotsNavigation
          showButtons={false}
          showNavigation={!showAnimation}
          showNavigationNumber={false}
          steps={(selectedTour && selectedTour.steps) || []}
          isOpen={isTourOpen}
          onRequestClose={() => {
            const { selectedTour: { id } } = this.state;
            if (id === 'firstView') {
              this.completeFirstTour();
            } else {
              this.setState({ isTourOpen: false });
            }
          }}
          goToStep={currentStep}
          getCurrentStep={curr => this.setState({ currentStep: curr })}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    business: state.business,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        updatePlaceInfo,
        addAdminAccess,
      },
      dispatch,
    ),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TourGuide)));
