import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { showMessage } from '../../redux/message/actions';
import Storage from './Storage';

function mapStateToProps(state) {
  return {
    menuIdMap: state.menu.idMap,
    equipmentIdMap: state.equipment.idMap,
    store: state.storage.store,
    currency: state.business.currency,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
      },
      dispatch,
    ),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Storage));
