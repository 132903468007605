import React, { Component } from 'react';
import { Empty, Modal, Tag } from 'antd';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { alphabeticalSort } from '../utils/helpers';
import { Column, Row } from '../components';

const { CheckableTag } = Tag;

class SelectItemForSale extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategories: [],
    };
  }

  selectItem = (id) => {
    this.setState(state => ({
      selectedCategories: state.selectedCategories.includes(id)
        ? state.selectedCategories.filter(el => el !== id)
        : [...state.selectedCategories, id],
    }));
  };

  submit = () => {
    const { callback } = this.props;
    const { selectedCategories } = this.state;
    callback(selectedCategories);
  };

  close = () => {
    const { close } = this.props;
    close();
  };

  render() {
    const {
      t, visible = false, excludeList, menu,
    } = this.props;
    const { selectedCategories } = this.state;
    const items = alphabeticalSort((menu.categories || []).filter(el => !excludeList.includes(el.id)));
    return (
      <Modal
        maskClosable={false}
        title={t('marketing.loyalty.selectCategories')}
        visible={visible}
        width="100%"
        className="loyalty-exclude"
        okText={t('control.add')}
        onOk={this.submit}
        style={{ maxWidth: '600px', top: 20 }}
        onCancel={this.close}
      >
        <Column width="100%">
          {items.length > 0 && (
            <Column width="100%" style={{ maxHeight: 'calc(100vh - 270px)', overflowY: 'scroll' }}>
              <h3>{t('layout.category')}</h3>
              <Row width="100%" flexWrap="wrap" justifyContent="flex-start">
                {items.map(item => (
                  <CheckableTag
                    key={`category-${JSON.stringify(item)}`}
                    className="create-product-tag"
                    onChange={() => this.selectItem(item.id)}
                    checked={selectedCategories.includes(item.id)}
                  >
                    {item.name}
                  </CheckableTag>
                )) }
              </Row>
            </Column>
          )}
          {items.length === 0 && (
          <Empty style={{ margin: '50px auto' }} />
          )}
        </Column>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    equipment: state.equipment,
    storage: state.storage,
  };
}

export default connect(mapStateToProps, {})(withTranslation()(SelectItemForSale));
