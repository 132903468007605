import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import {
  Content, Page, PageHeader, Row, TopBar,
} from '../../../components';
import AddSupplier from './AddSupplier';
import EditSupplier from './EditSupplier';
import { notificationTypes, showNetworkError } from '../../../redux/message/actions';
import { deleteModal } from '../../../utils/modals';
import { printPage } from '../../../utils/helpers';
import { isOnline } from '../../../persistance';

export const calculateSubtotal = item => parseInt(item.price || 0, 10) * parseInt(item.count || 0, 10);

class Suppliers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSupplier: null,
    };
  }

  selectSupplier = (selectedSupplier) => {
    if (!isOnline()) return showNetworkError();
    this.setState({ selectedSupplier });
    this.editSupplierModal.show();
  };

  addSupplier = () => {
    if (!isOnline()) return showNetworkError();
    const { t, canInteract, actions: { showMessage } } = this.props;
    if (canInteract) {
      this.addSupplierModal.show();
    } else {
      showMessage({ type: notificationTypes.ERROR, text: t('payments.cantInteract') });
    }
  };

  render() {
    const {
      t, suppliers, actions: { removeSupplier }, suppliersLoaded,
    } = this.props;
    const { selectedSupplier } = this.state;

    const supplyColumns = [
      {
        title: t('supply.supplier'),
        dataIndex: 'name',
        sorter: (a, b) => {
          if (!a.name || !b.name) return 0;
          return (-1) * a.name.localeCompare(b.name);
        },
        sortDirections: ['descend'],
        defaultSortOrder: 'descend',
      }, {
        title: t('landing.phone'),
        dataIndex: 'phone',
        render: text => <a href={`tel:${text}`}>{text}</a>,
      },
      {
        title: t('supply.comment'),
        dataIndex: 'meta',
      }, {
        title: '',
        width: '30px',
        render: (text, record) => (
          <Row width="100%" justifyContent="center">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                this.selectSupplier(record);
              }}
              type="link"
              icon={<EditOutlined />}
            />
          </Row>
        ),
      }, {
        title: '',
        dataIndex: 'action',
        width: '50px',
        render: (text, record) => (
          <DeleteOutlined
            onClick={(e) => {
              e.stopPropagation();
              deleteModal(() => removeSupplier(record.id));
            }}
          />
        ),
      },
    ];
    return (
      <Page spinning={!suppliersLoaded}>
        <PageHeader />
        <Content>
          <TopBar data-tour="supply-tour-2">
            <h3 className="storage-page-title">{t('storage.suppliers')}: {suppliers.length}</h3>
            <Button
              className="no-print right-margin"
              size="large"
              onClick={() => printPage()}
              type="secondary"
            >{t('control.print')}
            </Button>
            <Button className="add-button" size="large" onClick={this.addSupplier}>{t('control.add')}</Button>
          </TopBar>
          <Table
            bordered
            pagination={false}
            size="default"
            className="storage-page-table"
            columns={supplyColumns}
            dataSource={suppliers.map((el, i) => ({ ...el, key: i }))}
          />
        </Content>
        <AddSupplier ref={(ref) => { this.addSupplierModal = ref; }} />
        <EditSupplier supplier={selectedSupplier} ref={(ref) => { this.editSupplierModal = ref; }} />
      </Page>
    );
  }
}

export default withTranslation()(Suppliers);
