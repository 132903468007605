import {
  deleteImage, generateId, uploadImage,
} from '../../../utils/helpers';
import { ADD_STORE_ITEM, REMOVE_STORE_ITEM } from '../../storage/actions';
import { getPlaceRef } from '../../business/actions';
import {
  addItemRequest, editItemRequest, getItemsDataRequest, removeItemRequest,
} from '../../../api/db/menu';
import { showErrorMessage, showMessage } from '../../message/actions';
import i18n from '../../../utils/translation';
import {
  getPersistedMenuItems, isOnline, persist, removeDataFromPersist,
} from '../../../persistance';
import { idbMenuItems } from '../../../config';

export const GET_ITEMS = 'GET_ITEMS';
export const ADD_ITEMS = 'ADD_ITEMS';
export const REMOVE_ITEMS = 'REMOVE_ITEMS';
export const EDIT_ITEMS = 'EDIT_ITEMS';

export function getItems(persisted = false) {
  return (dispatch) => {
    if (persisted || !isOnline()) {
      return getPersistedMenuItems()
        .then(data => dispatch({ type: GET_ITEMS, payload: data.map(el => ({ ...el, type: 'item' })) }));
    }
    return getItemsDataRequest()
      .then(res => dispatch({ type: GET_ITEMS, payload: res.data.map(el => ({ ...el, type: 'item' })) }))
      .catch(showErrorMessage);
  };
}

export function editItem({
  photo, ref, id, ...item
}, withNotification = true) {
  return async (dispatch) => {
    const placeRef = await dispatch(getPlaceRef());
    let url = photo;
    let newRef = ref;

    if (typeof photo !== 'string' && photo !== null) {
      newRef = `${placeRef}/item-${Date.now()}`;
      url = await uploadImage(newRef, photo);
    }

    const newItem = {
      ...item, photo: url, ref: newRef,
    };

    delete newItem.id;
    delete newItem.type;
    delete newItem.consumables;

    return editItemRequest(id, newItem)
      .then((res) => {
        dispatch({ type: EDIT_ITEMS, payload: ({ ...res.data, id, type: 'item' }) });

        if (withNotification) dispatch(showMessage({ text: i18n.t('actions.menuItemEditedTitle') }));

        persist({ ...res.data, id, type: 'item' }, idbMenuItems);

        return { ...res.data, id, type: 'item' };
      })
      .catch(showErrorMessage);
  };
}

export function toggleFavouritesItem(item) {
  return dispatch => dispatch(editItem({ ...item, inFavourites: !item.inFavourites }, false));
}

export function createItem(item) {
  return async (dispatch) => {
    const placeRef = await dispatch(getPlaceRef());
    let url = null;
    let ref = null;

    if (typeof item.photo === 'string') {
      ref = `${placeRef}/item-${Date.now()}`;
      url = item.photo;
    } else if (item.photo !== null) {
      ref = `${placeRef}/item-${Date.now()}`;
      url = await uploadImage(ref, item.photo);
    }

    const id = generateId();
    const newItem = {
      ...item, photo: url, ref, inFavourites: false,
    };

    return addItemRequest(id, newItem)
      .then((res) => {
        dispatch({ type: ADD_ITEMS, payload: ({ ...res.data, id, type: 'item' }) });

        dispatch({
          type: ADD_STORE_ITEM,
          payload: {
            type: 'item', count: 0, id,
          },
        });

        persist({ ...res.data, id, type: 'item' }, idbMenuItems);

        dispatch(showMessage({ text: i18n.t('actions.menuItemCreatedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function removeItem(item) {
  return dispatch => removeItemRequest(item.id)
    .then(() => {
      dispatch({ type: REMOVE_ITEMS, payload: item.id });

      if (item.photo) deleteImage(item.ref);

      dispatch({ type: REMOVE_STORE_ITEM, payload: item.id });

      removeDataFromPersist(item.id, idbMenuItems);

      dispatch(showMessage({ text: i18n.t('actions.menuItemDeletedTitle') }));
    })
    .catch(showErrorMessage);
}
