import moment from 'moment';
import { getShiftsByDayRequest } from '../../api/db/shifts';

const DATE_FORMAT = 'DD_MM_YYYY';

export const getShiftsFromTimeRange = (from, to = moment()) => {
  const start = moment(from).startOf('day');
  const end = moment(to).endOf('day');
  if (start.valueOf() > end.valueOf()) return new Promise(resolve => resolve([]));

  const dates = [end.format(DATE_FORMAT)];

  while (start.format(DATE_FORMAT) !== end.format(DATE_FORMAT)) {
    dates.push(start.format(DATE_FORMAT));
    start.add(1, 'day');
  }

  return Promise.all(dates.map(date => getShiftsByDayRequest(date)))
    .then(results => results.map(el => el.data).flat());
};
