import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ModalHOC } from '../../common';
import { showMessage } from '../../redux/message/actions';
import AddEquipment from './AddEquipment';

function mapStateToProps(state) {
  return {
    equipment: state.equipment,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
      },
      dispatch,
    ),
  };
}

export default ModalHOC(connect(mapStateToProps, mapDispatchToProps)(AddEquipment));
