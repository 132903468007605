import { wasteTourSteps } from './steps';

export const getWasteTours = (t, goToNextStep, lastStepEvent) => [
  {
    pageRegExp: /\w/g,
    id: 3,
    isComplete: false,
    redirect: '/app/storage/waste',
    title: t('reactour.waste.title'),
    beforeEnter: () => {
      const layoutSelector = document.querySelector('[data-tour="store-tour-0"]');
      if (layoutSelector.classList.contains('active-parent')) {
        layoutSelector.click();
      }
    },
    steps: wasteTourSteps(t, goToNextStep, lastStepEvent),
  },
];
