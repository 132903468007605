import React, { Component } from 'react';
import { CheckOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Empty, Input, Modal } from 'antd';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { alphabeticalSort, getDefaultImageByType } from '../utils/helpers';
import { Column, Row } from '../components';

class SelectItemForSale extends Component {
  constructor(props) {
    super(props);
    const { selectedItems = [] } = props;
    this.state = {
      consumableSearch: '',
      selectedItems,
    };
  }

  selectItem = (id) => {
    this.setState(state => ({
      selectedItems: state.selectedItems.includes(id)
        ? state.selectedItems.filter(el => el !== id)
        : [...state.selectedItems, id],
    }));
  };

  submit = () => {
    const { callback } = this.props;
    const { selectedItems } = this.state;
    this.setState({ consumableSearch: '' });
    callback(selectedItems);
  };

  close = () => {
    const { close } = this.props;
    this.setState({ consumableSearch: '' });
    close();
  };

  render() {
    const {
      t, visible = false, menu,
    } = this.props;
    const {
      consumableSearch, selectedItems,
    } = this.state;
    let itemsToSelect = alphabeticalSort([...menu.products, ...menu.items, ...menu.addons]);

    if (consumableSearch.trim() !== '') {
      itemsToSelect = itemsToSelect
        .filter(el => !!el.name)
        .filter(consumable => consumable.name.toLowerCase().includes(consumableSearch.toLowerCase()));
    }
    return (
      <Modal
        maskClosable={false}
        title={t('consumable.select')}
        visible={visible}
        width="100%"
        okText={t('control.add')}
        onOk={this.submit}
        style={{ maxWidth: '600px', top: 20 }}
        onCancel={this.close}
      >
        <Column width="100%">
          <h3 className="add-item-subtitle">{t('global.search')}</h3>
          <Input
            prefix={<SearchOutlined />}
            value={consumableSearch}
            onChange={e => this.setState({ consumableSearch: e.target.value })}
            style={{ width: '100%', maxWidth: '300px', marginBottom: '12px' }}
            suffix={<CloseOutlined onClick={() => this.setState({ consumableSearch: '' })} />}
          />
          {itemsToSelect.length > 0 && (
            <Column style={{ maxHeight: 'calc(100vh - 270px)', overflowY: 'scroll' }}>
              <h3>{t('global.items')}</h3>
              <Row width="100%" flexWrap="wrap" justifyContent="flex-start">
                {itemsToSelect.map(item => (
                  <div
                    className={`add-item-addon-card ${selectedItems.includes(item.id) ? 'checked' : ''}`}
                    key={`addon-card-${item.id}`}
                    onClick={() => this.selectItem(item.id)}
                  >
                    <h3>{item.name}</h3>
                    <img
                      src={item.photo || getDefaultImageByType('addon')}
                      style={{ opacity: item.photo ? 1 : 0.5 }}
                      alt=""
                      className="card-img"
                    />
                    <CheckOutlined style={{ fontSize: '18px' }} className="add-item-addon-card-check" />
                  </div>
                )) }
              </Row>
            </Column>
          )}
          {itemsToSelect.length === 0 && (
          <Empty style={{ margin: '50px auto' }} />
          )}
        </Column>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    equipment: state.equipment,
    storage: state.storage,
  };
}

export default connect(mapStateToProps, {})(withTranslation()(SelectItemForSale));
