import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  Button, Empty, Modal, Select, Table,
} from 'antd';
import moment from 'moment';
import { firebase } from '../../config';
import { Row, Column } from '../index';
import { ItemsTable, ModalHOC } from '../../common';
import Pay from '../PayModal/Pay';
import { prepareBillData } from '../../redux/statistic/helpers';
import { showMessage } from '../../redux/message/actions';
import { changeBillPayments } from '../../redux/statistic/actions';
import {
  calculateComponentsCost, calculateConsumablesCost, calculateSubTotal, calculateTotal, prepareCardNumber,
} from '../../utils/helpers';
import './style.scss';
import { getSaleString } from '../Clients/Clients';

const { Option } = Select;

class EditBills extends Component {
  constructor(props) {
    super(props);
    const { shift: { activeShift }, resolvedBills } = this.props;
    const bills = resolvedBills.filter(bill => bill.id > activeShift.startTime).reverse();
    this.state = {
      bills,
      activeBill: bills.length > 0 ? this.getBillData(bills[0]) : null,
      payModalOpen: false,
      qrCodeModalOpen: false,
    };
  }

  setActiveBill = bill => this.setState({ activeBill: this.getBillData(bill) });

  getBillData = bill => ({
    ...bill,
    profit: calculateTotal(bill),
    price: calculateSubTotal(bill).toFixed(2),
    date: moment(bill.id).format('DD.MM.YYYY HH:mm'),
  });

  getCost = (el) => {
    const { menu: { idMap }, equipment } = this.props;
    if (!firebase.auth().currentUser || equipment.loading) return 0;
    switch (el.type) {
      case 'addon': return idMap[el.src.componentId].cost * el.src.count;
      case 'product': {
        return calculateComponentsCost(el.src.components, idMap, el.size)
          + calculateConsumablesCost(el.src.consumables, equipment.idMap, el.size);
      }
      default: return el.src.cost;
    }
  };

  showPayModal = () => {
    this.setState({ payModalOpen: true });
  };

  showQRCodeModal = () => {
    this.setState({ qrCodeModalOpen: true });
  };

  closePayModal = () => this.setState({ payModalOpen: false });

  updateBill = ({ cash, card, bill }) => {
    const { actions: { changeBillPayments } } = this.props;
    const { activeBill, bills } = this.state;
    const updatedBill = {
      ...bills.find(el => el.id === activeBill.id),
      pay: {
        card: card === '' ? 0 : parseFloat(card).toFixed(2),
        cash: cash === '' ? 0 : parseFloat(cash).toFixed(2),
        total: bill.total,
        change: parseFloat(cash || '0') + parseFloat(card || '0') - parseFloat(bill.total),
      },
    };
    changeBillPayments(updatedBill).then(this.closePayModal);
    this.setState({
      activeBill: { ...activeBill, pay: updatedBill.pay },
      bills: bills.map(el => (el.id === updatedBill.id ? updatedBill : el)),
    });
  };

  render() {
    const {
      close, visible, t, menu: { idMap }, business,
    } = this.props;
    const {
      bills, activeBill, payModalOpen, qrCodeModalOpen,
    } = this.state;
    return (
      <Modal
        maskClosable={false}
        visible={visible}
        onCancel={close}
        footer={null}
        width="95vh"
        style={{ padding: '12px 16px 30px', width: '95wv', maxWidth: '920px' }}
        title={t('modalMenu.manageBills')}
      >
        <Row width="100%" alignItems="flex-start" height="400px">
          {bills.length === 0 ? (
            <Empty style={{ margin: '0', width: '100%' }} />
          ) : (
            <Fragment>
              <Column
                width="100%"
                className="bills-column"
                height="100%"
                justifyContent="flex-start"
              >
                <Select
                  placeholder={t('global.select')}
                  style={{ width: '185px', marginBottom: '12px' }}
                  defaultValue={activeBill ? activeBill.id : null}
                  defaultActiveFirstOption
                  onChange={id => this.setActiveBill(bills.find(el => el.id === id))}
                >
                  {bills.map(el => (
                    <Option key={el.id} value={el.id}>
                      <Row>
                        <p>{moment(el.id).format('HH:mm')}</p>
                        -
                        <span>{el.pay.total} {business.currency}</span>
                      </Row>
                    </Option>
                  ))}
                </Select>

                {activeBill !== null && (
                  <Column width="100%" className="default-card" style={{ overflowY: 'scroll' }}>
                    <Table
                      bordered
                      className="table-bold-last-row"
                      pagination={false}
                      showHeader={false}
                      size="default"
                      style={{ width: '100%' }}
                      columns={[{ dataIndex: 'name' }, { dataIndex: 'value', className: 'table-header-right' }]}
                      dataSource={[
                        { key: '2', name: t('bills.openTime'), value: activeBill.date },
                        {
                          key: '3',
                          name: t('bills.closeTime'),
                          value: moment(activeBill.closeTime || activeBill.id).format('DD.MM.YYYY HH:mm'),
                        },
                        {
                          key: '4',
                          name: t('pay.cash'),
                          // eslint-disable-next-line max-len
                          value: `${parseFloat(activeBill.pay.cash - activeBill.pay.change).toFixed(2)} ${business.currency}`,
                        },
                        { key: '5', name: t('pay.card'), value: `${activeBill.pay.card} ${business.currency}` },
                        {
                          key: '6',
                          name: t('pay.byBonus'),
                          value: `${activeBill.pay.bonus || 0} ${business.currency}`,
                        },
                        { key: '7', name: t('global.subtotal'), value: `${activeBill.price} ${business.currency}` },
                        { key: '8', name: t('global.sale'), value: getSaleString(activeBill.sale, business.currency) },
                        {
                          key: '9',
                          name: t('global.client'),
                          value: activeBill.client
                            ? `#${prepareCardNumber(activeBill.client.cardNumber)} ${activeBill.client.name}`
                            : '-',
                        },
                        { key: '10', name: t('supply.comment'), value: activeBill.comment },
                        {
                          key: '11',
                          name: t('global.revenue'),
                          // eslint-disable-next-line max-len
                          value: `${calculateTotal(activeBill)} ${business.currency}`,
                        },
                      ]}
                    />
                    <Row width="100%" justifyContent="space-between" margin="20px 0 10px">
                      {activeBill.uuid && activeBill.syncedWithCheckbox && (
                        <Button
                          type="secondary"
                          size="large"
                          onClick={this.showQRCodeModal}
                        >
                          {t('settings.getQRCodeForBill')}
                        </Button>
                      )}

                      <Button
                        type="primary"
                        size="large"
                        onClick={this.showPayModal}
                      >
                        {t('modalMenu.changePayments')}
                      </Button>
                    </Row>

                    <ItemsTable
                      currency={business.currency}
                      t={t}
                      isAdmin={false}
                      data={prepareBillData(activeBill.items)
                        .map(el => (el.sizes.map(size => ({
                          ...el,
                          size,
                          price: el.prices[size],
                          quantity: el.quantity[size],
                          type: idMap[el.id] ? idMap[el.id].type : '',
                        })))).flat()
                        .map((item, key) => {
                          const data = { src: idMap[item.id] ? idMap[item.id] : { cost: 0 }, ...item, key };
                          const cost = parseFloat(this.getCost(data)).toFixed(2) || 0;
                          return ({
                            ...data,
                            cost,
                            sum: parseFloat(data.quantity * data.price).toFixed(2),
                            gross: (data.quantity * parseFloat(data.price - cost)).toFixed(2),
                          });
                        })}
                    />
                  </Column>
                )}
              </Column>
            </Fragment>
          )}
        </Row>
        {payModalOpen && (
          <Modal
            maskClosable={false}
            centered
            visible
            className="pay"
            footer={null}
            onCancel={this.closePayModal}
            title={t('control.pay')}
            width="95vw"
          >
            <Pay callback={this.updateBill} bill={activeBill} />
          </Modal>
        )}

        {qrCodeModalOpen && (
          <Modal
            centered
            visible
            className="pay"
            footer={null}
            onCancel={() => this.setState({ qrCodeModalOpen: false })}
            width="95vw"
          >
            <div className="qr-code">
              <img src={`https://api.checkbox.in.ua/api/v1/receipts/${activeBill.uuid}/qrcode`} alt="" />
            </div>
          </Modal>
        )}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    resolvedBills: state.statistic.resolvedBills,
    menu: state.menu,
    business: state.business,
    equipment: state.equipment,
    shift: state.shift,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        changeBillPayments,
      },
      dispatch,
    ),
  };
}

export default ModalHOC(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditBills)));
