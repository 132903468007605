import React, { Component } from 'react';
import { Badge, Button } from 'antd';
import { isOnline } from '../persistance';

class NetworkStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOnline: isOnline(),
    };
  }

  componentDidMount() {
    window.addEventListener('offline', () => this.setState({ isOnline: false }));
    window.addEventListener('online', () => this.setState({ isOnline: true }));
  }

  render() {
    const { isOnline } = this.state;
    return (
      <Button type="link" className="network-button">
        <Badge color={isOnline ? 'green' : 'red'} />
      </Button>
    );
  }
}

export default NetworkStatus;
