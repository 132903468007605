import React, { Component, lazy, Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { Collapse } from 'antd';
import Header from '../Landing/Header';
import { JoinUs, Spinner } from '../Landing/components';
import { Column } from '../../components';
import './style.scss';

const { Panel } = Collapse;
const LazyFooter = lazy(() => import('../Landing/Footer/index'));

const panelStyle = {
  background: 'white',
};

const global = [
  { title: 'question1Title', answer: 'question1Answer' },
  { title: 'question2Title', answer: 'question2Answer' },
  { title: 'question3Title', answer: 'question3Answer' },
  { title: 'question4Title', answer: 'question4Answer' },
  { title: 'question5Title', answer: 'question5Answer' },
  { title: 'question6Title', answer: 'question6Answer' },
];

class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <div className="landing" id="top">
        <Header />
        <Column width="100%" className="landing-wrapper faq" margin="0 auto">
          <Column className="faq-header" width="100%" alignItems="center" justifyContent="flex-start">
            <h2>{t('landing.faq.title')}</h2>
            <h3>{t('landing.faq.subtitle')}</h3>
          </Column>
          <div className="faq-collapse">
            <div className="faq-collapse-header">{t('landing.faq.generalBlock')}</div>
            <Collapse
              expandIconPosition="right"
              bordered={false}
            >
              {global.map((el, index) => (
                <Panel header={t(`landing.faq.general.${el.title}`)} key={`${index + 1}`} style={panelStyle}>
                  <div>{t(`landing.faq.general.${el.answer}`)}</div>
                </Panel>
              ))}
            </Collapse>
          </div>
          <JoinUs t={t} />
        </Column>
        <Suspense fallback={<Spinner />}>
          <LazyFooter />
        </Suspense>
      </div>
    );
  }
}

export default withTranslation()(FAQ);
