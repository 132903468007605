import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input, Modal, Select } from 'antd';
import { PercentageOutlined } from '@ant-design/icons';
import { ModalHOC, NumberInput } from '../../../common';
import { Column } from '../../../components';
import { notificationTypes, showMessage } from '../../../redux/message/actions';
import { editGroup } from '../../../redux/marketing/actions';
import { checkIsNameExist, validateNumberInput } from '../../../utils/helpers';
import './styles.scss';

class EditMarketingGroup extends Component {
  constructor(props) {
    super(props);
    const { item } = props;
    this.state = {
      name: '',
      count: '',
      type: 'sale',
      ...item,
    };
  }

  submit = () => {
    const {
      t, close, groups, actions: { editGroup, showMessage }, item,
    } = this.props;
    const { name, count, type } = this.state;
    if (name.trim() === '') {
      showMessage({ text: t('validation.emptyName'), type: notificationTypes.ERROR });
      return;
    }
    if (checkIsNameExist(name, groups.filter(el => el.id !== item.id))) {
      showMessage({ text: t('validation.uniqueGroupName'), type: notificationTypes.ERROR });
      return;
    }
    if (count.toString().trim() === '') {
      showMessage({ text: t('validation.addCount'), type: notificationTypes.ERROR });
      return;
    }
    if (parseInt(count, 10) > 100) {
      showMessage({ text: t('validation.saleMoreThen100'), type: notificationTypes.ERROR });
      return;
    }
    editGroup({
      ...item, name, count: parseInt(count, 10), type,
    });
    close();
  };

  render() {
    const { t, close, visible } = this.props;
    const { name, count, type } = this.state;
    return (
      <Modal
        maskClosable={false}
        centered
        visible={visible}
        onOk={this.submit}
        onCancel={close}
        width="100%"
        style={{ maxWidth: '450px' }}
        okText={t('control.save')}
        okButtonProps={{ size: 'large' }}
        cancelButtonProps={{ size: 'large' }}
        cancelText={t('control.cancel')}
        title={t('marketing.groups.editGroup')}
      >
        <Column
          width="100%"
          alignItems="flex-start"
          margin="0 auto"
          className="default-card"
          style={{ maxWidth: '324px' }}
        >
          <h3 style={{ margin: '0 0 5px' }}>{t('global.name')}</h3>
          <Input
            style={{ width: '100%' }}
            placeholder={t('global.name')}
            value={name}
            onChange={e => this.setState({ name: e.target.value })}
          />
          <h3 style={{ margin: '10px 0 5px' }}>{t('marketing.groups.type')}</h3>
          <Select
            value={type}
            placeholder={t('global.select')}
            style={{ width: '100%' }}
            onChange={type => this.setState({ type })}
          >
            <Select.Option key="sale" value="sale">{t('marketing.groups.sale')}</Select.Option>
            <Select.Option key="bonus" value="bonus">{t('marketing.groups.bonus')}</Select.Option>
          </Select>
          <h3 style={{ margin: '10px 0 5px' }}>{t('marketing.groups.salePercent')}</h3>
          <NumberInput
            onKeyUp={validateNumberInput}
            addonAfter={<PercentageOutlined />}
            value={count}
            style={{ margin: '0 0 8px auto', width: '100%' }}
            placeholder="0"
            type="tel"
            onChange={e => this.setState({ count: e.target.value })}
          />
        </Column>
      </Modal>
    );
  }
}
function mapStateToProps(state) {
  return {
    groups: state.marketing.groups,
    clients: state.marketing.clients,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        editGroup,
      },
      dispatch,
    ),
  };
}
export default ModalHOC(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditMarketingGroup)));
