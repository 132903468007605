import React, { Component } from 'react';
import { Table, Button } from 'antd';
import { withTranslation } from 'react-i18next';
import { firebase } from '../../../config';
import {
  Content, Page, PageHeader, TopBar,
} from '../../../components';
import { RangePicker } from '../../../common';
import { getBillsFromDayRange } from '../../../redux/statistic/helpers';
import { calculateTotal, getItemsCost, printPage } from '../../../utils/helpers';
import { isOnline } from '../../../persistance';
import { showNetworkError } from '../../../redux/message/actions';

class StatisticCategories extends Component {
  constructor(props) {
    super(props);
    const { resolvedBills = [] } = this.props;
    this.state = {
      bills: resolvedBills,
      pending: false,
    };
  }

  updateRange = ({ rangeStart, rangeEnd }) => {
    if (!isOnline()) return showNetworkError();
    this.setState({ pending: true });
    getBillsFromDayRange(rangeStart, rangeEnd)
      .then((bills = []) => this.setState({ bills, pending: false }));
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.resolvedBills.length !== nextProps.resolvedBills.length) {
      this.setState({ bills: nextProps.resolvedBills });
    }
  }

  render() {
    const {
      t, currency, menu: { categories, idMap }, equipment,
    } = this.props;
    const { bills, pending } = this.state;
    const billsColumns = [
      {
        title: t('global.name'),
        dataIndex: 'name',
        width: 120,
        sorter: (a, b) => (-1) * a.name.localeCompare(b.name),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('cost.count'),
        dataIndex: 'count',
        width: 120,
        render: text => `${text} ${t('count')}`,
        sorter: (a, b) => {
          const delta = parseFloat(a.count) - parseFloat(b.count);
          if (delta > 0) return 1;
          if (delta < 0) return -1;
          return 0;
        },
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('cost.title'),
        dataIndex: 'cost',
        width: 120,
        render: text => (text !== 0 ? `${text} ${currency}` : '-'),
        sorter: (a, b) => {
          const delta = parseFloat(a.cost) - parseFloat(b.cost);
          if (delta > 0) return 1;
          if (delta < 0) return -1;
          return 0;
        },
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('global.revenue'),
        dataIndex: 'sum',
        width: 120,
        render: text => `${text} ${currency}`,
        sorter: (a, b) => {
          const delta = parseFloat(a.sum) - parseFloat(b.sum);
          if (delta > 0) return 1;
          if (delta < 0) return -1;
          return 0;
        },
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('global.gross'),
        dataIndex: 'profit',
        width: 120,
        render: text => `${text} ${currency}`,
        sorter: (a, b) => {
          const delta = parseFloat(a.profit) - parseFloat(b.profit);
          if (delta > 0) return 1;
          if (delta < 0) return -1;
          return 0;
        },
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('cost.foodCost'),
        dataIndex: 'foodCost',
        width: 120,
        render: text => (text !== 0 ? `${text} %` : '-'),
        sorter: (a, b) => {
          const delta = parseFloat(a.foodCost) - parseFloat(b.foodCost);
          if (delta > 0) return 1;
          if (delta < 0) return -1;
          return 0;
        },
        sortDirections: ['descend', 'ascend'],
      },
    ];
    const items = bills.map(bill => bill.items).flat();
    const categoriesItems = (categories || []).concat({ name: null }).map(({ name }, key) => {
      const filteredItems = items.filter(el => idMap[el.id] && idMap[el.id].category === name);
      const filledItems = filteredItems.map(el => ({ ...el, ...idMap[el.id] }));
      const cost = getItemsCost(filledItems, this.props.menu, equipment);
      const sum = bills
        .map(bill => ({
          ...bill,
          items: bill.items.filter(item => idMap[item.id] && idMap[item.id].category === name),
        }))
        .map(bill => calculateTotal(bill))
        .reduce((sum, price) => sum + parseFloat(price.replace(',', '.')), 0).toFixed(2);
      const foodCost = parseFloat(sum / cost * 100).toFixed(2);
      return ({
        key,
        name: name || t('global.other'),
        count: filteredItems.length,
        cost,
        sum,
        profit: parseFloat(sum - cost).toFixed(2),
        foodCost: !isNaN(foodCost) && isFinite(foodCost) ? foodCost : 0,
      });
    });
    return (
      <Page spinning={!firebase.auth().currentUser || pending}>
        <PageHeader />
        <Content>
          <TopBar data-tour="component-tour-2">
            <h3 className="storage-page-title">
              {t('layout.category')}: {(categories || []).length}
            </h3>
            <Button
              className="no-print mobile-bottom-margin mobile-right-margin"
              size="large"
              onClick={() => printPage()}
              type="secondary"
            >{t('control.print')}
            </Button>
            <RangePicker shouldCheckPermission callback={this.updateRange} />
          </TopBar>
          <Table
            bordered
            pagination={false}
            size="default"
            className="storage-page-table"
            columns={billsColumns}
            dataSource={categoriesItems}
          />
        </Content>
      </Page>
    );
  }
}

export default withTranslation()(StatisticCategories);
