import React, { Component, Fragment } from 'react';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Empty, Input, Modal } from 'antd';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ItemList from './ItemList';
import { alphabeticalSort } from '../utils/helpers';
import { Column } from '../components';

class SelectConsumableItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consumableSearch: '',
      items: this.getStorageItems('item'),
      consumables: this.getStorageItems('consumable'),
      components: this.getStorageItems('component'),
    };
  }

  getStorageItems = (type) => {
    const { menu, equipment, storage: { store } } = this.props;
    const allIdMap = { ...menu.idMap, ...equipment.idMap };
    return alphabeticalSort(store
      .filter(el => el.type === type)
      .map(el => ({ ...el, ...allIdMap[el.id] }))
      .filter(el => el.name));
  };

  selectItem = (item) => {
    const { callback } = this.props;
    this.setState({ consumableSearch: '' });
    callback(item);
  };

  close = () => {
    const { close } = this.props;
    this.setState({ consumableSearch: '' });
    close();
  };

  render() {
    const {
      t, menu, visible = false, selectedItems,
    } = this.props;
    const {
      consumableSearch, items, consumables, components,
    } = this.state;
    let itemsToSelect = items.filter(el => !selectedItems.includes(el.id));
    let consumablesToSelect = consumables.filter(el => !selectedItems.includes(el.id));
    let componentsToSelect = components.filter(el => !selectedItems.includes(el.id))
      .map(el => ({
        ...el, photo: (menu.addons.find(addon => addon.componentId === el.id) || {}).photo,
      }));
    if (consumableSearch.trim() !== '') {
      itemsToSelect = itemsToSelect
        .filter(consumable => consumable.name.toLowerCase().includes(consumableSearch.toLowerCase()));
      consumablesToSelect = consumablesToSelect
        .filter(consumable => consumable.name.toLowerCase().includes(consumableSearch.toLowerCase()));
      componentsToSelect = componentsToSelect
        .filter(consumable => consumable.name.toLowerCase().includes(consumableSearch.toLowerCase()));
    }
    return (
      <Modal
        maskClosable={false}
        footer={null}
        title={t('consumable.select')}
        visible={visible}
        width="100%"
        style={{ maxWidth: '800px' }}
        onCancel={this.close}
      >
        <Column width="100%">
          <h3 className="add-item-subtitle">{t('global.search')}</h3>
          <Input
            prefix={<SearchOutlined />}
            value={consumableSearch}
            onChange={e => this.setState({ consumableSearch: e.target.value })}
            style={{ width: '100%', maxWidth: '300px', marginBottom: '12px' }}
            suffix={<CloseOutlined onClick={() => this.setState({ consumableSearch: '' })} />}
          />
          {consumablesToSelect.length > 0 && (
            <Fragment>
              <h3>{t('global.consumables')}</h3>
              <ItemList
                withCategories={false}
                items={consumablesToSelect}
                withFavourites={false}
                callback={data => this.selectItem(data)}
              />
            </Fragment>
          )}
          {componentsToSelect.length > 0 && (
            <Fragment>
              <h3>{t('global.components')}</h3>
              <ItemList
                withCategories={false}
                items={componentsToSelect}
                withFavourites={false}
                callback={data => this.selectItem(data)}
              />
            </Fragment>
          )}
          {itemsToSelect.length > 0 && (
            <Fragment>
              <h3>{t('global.items')}</h3>
              <ItemList
                withCategories={false}
                items={itemsToSelect}
                withFavourites={false}
                callback={data => this.selectItem(data)}
              />
            </Fragment>
          )}
          {itemsToSelect.length === 0 && componentsToSelect.length === 0 && consumablesToSelect.length === 0 && (
          <Empty style={{ margin: '50px auto' }} />
          )}
        </Column>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    equipment: state.equipment,
    storage: state.storage,
  };
}

export default connect(mapStateToProps, {})(withTranslation()(SelectConsumableItem));
