import { firstViewTourSteps } from './steps';

export const getFirstViewTour = (t, goToNextStep, lastStepEvent, history) => ({
  pageRegExp: new RegExp(/^\/app\/dashboard\/$/),
  id: 'firstView',
  closable: false,
  isComplete: false,
  redirect: '/app/dashboard',
  title: t('reactour.firstView.title'),
  steps: firstViewTourSteps(t, goToNextStep, lastStepEvent, history),
});
