import { Modal } from 'antd';
import i18n from './translation';
import { isOnline } from '../persistance';
import { showNetworkError } from '../redux/message/actions';

const { confirm } = Modal;
export const deleteModal = (callback) => {
  if (!isOnline()) return showNetworkError();
  confirm({
    title: i18n.t('modals.deleteTitle'),
    maskClosable: true,
    centered: true,
    keyboard: false,
    onOk: callback,
    cancelText: i18n.t('control.cancel'),
    okText: i18n.t('control.delete'),
    okButtonProps: { type: 'danger' },
  });
};
