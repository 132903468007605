import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button, DatePicker, Input, Modal, Table, TimePicker,
} from 'antd';
import { ModalHOC, NumberInput, SelectConsumableItem } from '../../../common';
import { Column, Row } from '../../../components';
import { notificationTypes, showMessage } from '../../../redux/message/actions';
import { addWaste } from '../../../redux/storage/actions';
import {
  generateId, getUnits, hasZeroKey, validateNumberInput,
} from '../../../utils/helpers';

class AddWaste extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment(),
      time: moment(),
      comment: '',
      items: [],
      showSelectItem: false,
    };
  }

  getClearItem = (item = null) => ({ item, count: '', timestamp: generateId() });

  create = () => {
    const { t, close, actions: { addWaste, showMessage } } = this.props;
    const {
      date, comment, items, time,
    } = this.state;
    if (items.length === 0) {
      showMessage({ text: t('validation.addConsumable'), type: notificationTypes.ERROR });
      return;
    }
    if (hasZeroKey(items.map(el => el.count || 0))) {
      showMessage({ text: t('validation.addCount'), type: notificationTypes.ERROR });
      return;
    }
    addWaste({
      date: date.format('DD_MM_YYYY'), comment, items, time: time.format('HH:MM'),
    });
    close();
  };

  setDate = date => this.setState({ date });

  setTime = time => this.setState({ time });

  addItem = item => this.setState(state => ({
    items: [...state.items, this.getClearItem(item)],
  }));

  updateItem = (key, value, record) => this.setState(state => ({
    items: state.items.map((el, index) => (index === record.key ? ({ ...el, [key]: value }) : el)),
  }));

  removeItem = timestamp => this.setState(state => ({ items: state.items.filter(el => el.timestamp !== timestamp) }));

  render() {
    const {
      t, close, menu, equipment, visible = false,
    } = this.props;
    const {
      date, comment, items, time, showSelectItem,
    } = this.state;
    const allIdMap = { ...menu.idMap, ...equipment.idMap };
    const selectedItems = items.map(el => el.item);
    const wasteColumns = [{
      title: t('global.name'),
      dataIndex: 'name',
      className: 'no-break',
      width: 'calc(100% - 400px)',
      render: (text, record) => allIdMap[record.item].name,
    }, {
      title: t('global.quantity'),
      dataIndex: 'count',
      width: '150px',
      render: (text, record) => (
        <NumberInput
          placeholder="0"
          type="tel"
          addonAfter={allIdMap[record.item].units ? getUnits(allIdMap[record.item].units) : t('count')}
          onKeyUp={validateNumberInput}
          onChange={e => this.updateItem('count', e.target.value, record)}
          style={{ width: '150px' }}
        />
      ),
    }, {
      title: '',
      dataIndex: 'action',
      width: '50px',
      render: (text, record) => <DeleteOutlined onClick={() => this.removeItem(record.timestamp)} />,
    }];
    return (
      <Modal
        maskClosable={false}
        centered
        visible={visible}
        onOk={this.create}
        onCancel={close}
        width="100%"
        style={{ maxWidth: '800px' }}
        okText={t('control.add')}
        okButtonProps={{ size: 'large' }}
        cancelButtonProps={{ size: 'large' }}
        cancelText={t('control.cancel')}
        title={t('waste.modalTitle')}
      >
        <Column width="100%" alignItems="flex-start">
          <Column width="100%" className="default-card">
            <h3>{t('supply.date')}</h3>
            <Row width="100%" justifyContent="flex-start">
              <DatePicker
                allowClear={false}
                style={{ width: '100%', maxWidth: '300px' }}
                value={date}
                onChange={this.setDate}
              />
              <TimePicker
                allowClear={false}
                style={{ marginLeft: '10px' }}
                value={time}
                onChange={this.setTime}
                format="HH:mm"
              />
            </Row>
            <h3 style={{ margin: '5px 0' }}>{t('supply.comment')}</h3>
            <Input
              style={{ width: '100%', maxWidth: '438px' }}
              value={comment}
              placeholder={`${t('supply.comment')}...`}
              onChange={e => this.setState({ comment: e.target.value })}
            />
          </Column>
          <Table
            bordered
            pagination={false}
            size="default"
            style={{ width: '100%', overflowX: 'scroll', margin: '12px 0 0' }}
            columns={wasteColumns}
            dataSource={items.map((el, index) => ({ ...el, key: index }))}
            footer={() => (
              <Button
                icon={<PlusOutlined />}
                onClick={() => this.setState({ showSelectItem: true })}
              >{t('consumable.add')}
              </Button>
            )}
          />
        </Column>
        <SelectConsumableItem
          selectedItems={selectedItems}
          callback={data => this.addItem(data.id)}
          close={() => this.setState({ showSelectItem: false })}
          visible={showSelectItem}
        />
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    equipment: state.equipment,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        addWaste,
      },
      dispatch,
    ),
  };
}

export default ModalHOC(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddWaste)));
