import { financialShiftsTourSteps } from './steps';

export const getFinancialShiftsTours = (t, goToNextStep, lastStepEvent) => [
  {
    pageRegExp: /\w/g,
    id: 3,
    isComplete: false,
    redirect: '/app/financial/shifts',
    title: t('reactour.financialShifts.title'),
    beforeEnter: () => {
      const layoutSelector = document.querySelector('[data-tour="financial-tour-0"]');
      if (layoutSelector.classList.contains('active-parent')) {
        layoutSelector.click();
      }
    },
    steps: financialShiftsTourSteps(t, goToNextStep, lastStepEvent),
  },
];
