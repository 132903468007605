import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { ArrowRightOutlined } from '@ant-design/icons';
import {
  DatePicker, Input, Modal, Select, Tag,
} from 'antd';
import { ModalHOC, NumberInput } from '../../../common';
import { Column, Row } from '../../../components';
import { notificationTypes, showMessage } from '../../../redux/message/actions';
import { addTransaction, getDefaultFinancialCategories } from '../../../redux/financial/actions';
import { validateNumberInput } from '../../../utils/helpers';
import {
  INCOME, TRANSFER, WASTE, transactionTypes,
} from '../../../constants/transactions';

const { CheckableTag } = Tag;

const WasteTransaction = ({
  t, setState, wasteCount, accounts, categories, comment, currency,
}) => (
  <Fragment>
    <h3 style={{ margin: '5px 0' }}>{t('global.sum')}</h3>
    <NumberInput
      onKeyUp={validateNumberInput}
      style={{ width: '100%' }}
      type="tel"
      placeholder="0"
      onChange={e => setState({ wasteCount: e.target.value })}
      value={wasteCount}
      addonBefore="-"
      addonAfter={currency}
    />
    <h3 style={{ margin: '10px 0 5px' }}>{t('financial.from')}</h3>
    <Select
      placeholder={t('global.select')}
      style={{ width: '100%' }}
      onChange={name => setState({ from: accounts.find(el => el.name === name) })}
    >
      {accounts.map(account => (
        <Select.Option key={account.name} value={account.name}>{account.name}</Select.Option>
      ))}
    </Select>
    <h3 style={{ margin: '10px 0 5px' }}>{t('global.category')}</h3>
    <Select
      placeholder={t('global.select')}
      style={{ width: '100%' }}
      onChange={name => setState({ category: name })}
    >
      {categories.filter(el => el.canDecrease).map(category => (
        <Select.Option key={category.name} value={category.name}>{category.name}</Select.Option>
      ))}
    </Select>
    <h3 style={{ margin: '10px 0 5px' }}>{t('supply.date')}</h3>
    <DatePicker
      defaultValue={moment()}
      disabledDate={date => date && date > moment().endOf('day')}
      style={{ width: '100%' }}
      onChange={date => setState({ date })}
    />
    <h3 style={{ margin: '10px 0 5px' }}>{t('supply.comment')}</h3>
    <Input
      value={comment}
      placeholder={`${t('supply.comment')}...`}
      style={{ width: '100%' }}
      onChange={e => setState({ comment: e.target.value })}
    />
  </Fragment>
);
const IncomeTransaction = ({
  t, setState, accounts, categories, comment, incomeCount, currency,
}) => (
  <Fragment>
    <h3 style={{ margin: '5px 0' }}>{t('global.sum')}</h3>
    <NumberInput
      type="tel"
      onKeyUp={validateNumberInput}
      style={{ width: '100%' }}
      placeholder="0"
      onChange={e => setState({ incomeCount: e.target.value })}
      value={incomeCount}
      addonBefore="+"
      addonAfter={currency}
    />
    <h3 style={{ margin: '10px 0 5px' }}>{t('financial.to')}</h3>
    <Select
      placeholder={t('global.select')}
      style={{ width: '100%' }}
      onChange={name => setState({ to: accounts.find(el => el.name === name) })}
    >
      {accounts.map(account => (
        <Select.Option key={account.name} value={account.name}>{account.name}</Select.Option>
      ))}
    </Select>
    <h3 style={{ margin: '10px 0 5px' }}>{t('global.category')}</h3>
    <Select
      placeholder={t('global.select')}
      style={{ width: '100%' }}
      onChange={name => setState({ category: name })}
    >
      {categories.filter(el => el.canIncrease).map(category => (
        <Select.Option key={category.name} value={category.name}>{category.name}</Select.Option>
      ))}
    </Select>
    <h3 style={{ margin: '10px 0 5px' }}>{t('supply.date')}</h3>
    <DatePicker
      defaultValue={moment()}
      disabledDate={date => date && date > moment().endOf('day')}
      style={{ width: '100%' }}
      onChange={date => setState({ date })}
    />
    <h3 style={{ margin: '10px 0 5px' }}>{t('supply.comment')}</h3>
    <Input
      value={comment}
      placeholder={`${t('supply.comment')}...`}
      style={{ width: '100%' }}
      onChange={e => setState({ comment: e.target.value })}
    />
  </Fragment>
);
const TransferTransaction = ({
  t, setState, accounts, comment, incomeCount, wasteCount, currency,
}) => (
  <Fragment>
    <h3 style={{ margin: '5px 0' }}>{t('global.sum')}</h3>
    <Row width="100%" justifyContent="space-between">
      <NumberInput
        type="tel"
        onKeyUp={validateNumberInput}
        style={{ width: '200px' }}
        placeholder="0"
        onChange={e => setState({ wasteCount: e.target.value, incomeCount: e.target.value })}
        value={wasteCount}
        addonBefore="-"
        addonAfter={currency}
      />
      <ArrowRightOutlined style={{ margin: '0 10px' }} />
      <NumberInput
        onKeyUp={validateNumberInput}
        style={{ width: '200px' }}
        placeholder="0"
        onChange={e => setState({ incomeCount: e.target.value })}
        value={incomeCount}
        addonBefore="+"
        type="tel"
        addonAfter={currency}
      />
    </Row>
    <h3 style={{ margin: '10px 0 5px' }}>{t('financial.from')}</h3>
    <Select
      placeholder={t('global.select')}
      style={{ width: '100%' }}
      onChange={name => setState({ from: accounts.find(el => el.name === name) })}
    >
      {accounts.map(account => (
        <Select.Option key={account.name} value={account.name}>{account.name}</Select.Option>
      ))}
    </Select>
    <h3 style={{ margin: '10px 0 5px' }}>{t('financial.to')}</h3>
    <Select
      placeholder={t('global.select')}
      style={{ width: '100%' }}
      onChange={name => setState({ to: accounts.find(el => el.name === name) })}
    >
      {accounts.map(account => (
        <Select.Option key={account.name} value={account.name}>{account.name}</Select.Option>
      ))}
    </Select>
    <h3 style={{ margin: '10px 0 5px' }}>{t('supply.date')}</h3>
    <DatePicker
      defaultValue={moment()}
      disabledDate={date => date && date > moment().endOf('day')}
      style={{ width: '100%' }}
      onChange={date => setState({ date })}
    />
    <h3 style={{ margin: '10px 0 5px' }}>{t('supply.comment')}</h3>
    <Input
      value={comment}
      placeholder={`${t('supply.comment')}...`}
      style={{ width: '100%' }}
      onChange={e => setState({ comment: e.target.value })}
    />
  </Fragment>
);

class AddTransaction extends Component {
  constructor(props) {
    super(props);
    this.state = { ...this.getCleanState() };
  }

  getCleanState = () => ({
    type: WASTE,
    comment: '',
    date: moment(),
    from: '',
    to: '',
    category: '',
    wasteCount: '',
    incomeCount: '',
  });

  create = () => {
    const { t, close, actions: { addTransaction, showMessage } } = this.props;
    const {
      date, from, to, category, comment, type, incomeCount, wasteCount,
    } = this.state;
    if (type === WASTE) {
      if (wasteCount === '') {
        return showMessage({ text: t('validation.emptySum'), type: notificationTypes.ERROR });
      }
      if (from === '') {
        return showMessage({ text: t('validation.emptyAccount'), type: notificationTypes.ERROR });
      }
      if (category === '') {
        return showMessage({ text: t('validation.emptyCategory'), type: notificationTypes.ERROR });
      }
    }
    if (type === INCOME) {
      if (incomeCount === '') {
        return showMessage({ text: t('validation.emptySum'), type: notificationTypes.ERROR });
      }
      if (to === '') {
        return showMessage({ text: t('validation.emptyAccount'), type: notificationTypes.ERROR });
      }
      if (category === '') {
        return showMessage({ text: t('validation.emptyCategory'), type: notificationTypes.ERROR });
      }
    }
    if (type === TRANSFER) {
      if (incomeCount === '' || wasteCount === '') {
        return showMessage({ text: t('validation.emptySum'), type: notificationTypes.ERROR });
      }
      if (parseInt(incomeCount, 10) > parseInt(wasteCount, 10)) {
        return showMessage({ text: t('validation.wrongIncome'), type: notificationTypes.ERROR });
      }
      if (to === '' || from === '') {
        return showMessage({ text: t('validation.emptyAccount'), type: notificationTypes.ERROR });
      }
    }
    addTransaction({
      date: date.format('DD.MM.YYYY'), from, to, category, comment, type, incomeCount, wasteCount,
    });
    close();
  };


  render() {
    const {
      t, close, visible, financial: { accounts, categories }, business,
    } = this.props;
    const {
      type, wasteCount, incomeCount, from, category, comment, to,
    } = this.state;
    return (
      <Modal
        maskClosable={false}
        centered
        visible={visible}
        onOk={this.create}
        onCancel={close}
        width="498px"
        okButtonProps={{ size: 'large' }}
        cancelButtonProps={{ size: 'large' }}
        okText={t('control.add')}
        cancelText={t('control.cancel')}
        title={t('financial.addNewTransaction')}
      >
        <Column style={{ maxWidth: '474px' }} width="100%" alignItems="flex-start" className="default-card">
          <h3>{t('financial.operation')}</h3>
          <Row width="100%" justifyContent="flex-start">
            {transactionTypes.map(el => (
              <CheckableTag
                className="create-product-tag statistic-tag"
                onChange={() => this.setState({ ...this.getCleanState(), type: el })}
                key={el}
                checked={type === el}
              >
                {t(`financial.${el}`)}
              </CheckableTag>
            ))}
          </Row>
          {type === WASTE && (
          <WasteTransaction
            wasteCount={wasteCount}
            setState={data => this.setState({ ...data })}
            t={t}
            from={from}
            accounts={accounts}
            category={category}
            comment={comment}
            categories={[...getDefaultFinancialCategories(t), ...categories]}
            currency={business.currency}
          />
          )}
          {type === INCOME && (
            <IncomeTransaction
              incomeCount={incomeCount}
              setState={data => this.setState({ ...data })}
              t={t}
              to={to}
              accounts={accounts}
              category={category}
              comment={comment}
              categories={[...getDefaultFinancialCategories(t), ...categories]}
              currency={business.currency}
            />
          )}
          {type === TRANSFER && (
            <TransferTransaction
              wasteCount={wasteCount}
              incomeCount={incomeCount}
              setState={data => this.setState({ ...data })}
              t={t}
              to={to}
              from={from}
              accounts={accounts}
              comment={comment}
              categories={[...getDefaultFinancialCategories(t), ...categories]}
              currency={business.currency}
            />
          )}
        </Column>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    financial: state.financial,
    business: state.business,
  };
}


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        addTransaction,
      },
      dispatch,
    ),
  };
}
export default ModalHOC(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddTransaction)));
