import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showMessage } from '../../../redux/message/actions';
import { editLoyalty } from '../../../redux/marketing/actions';
import LoyaltyExclude from './LoyaltyExclude';

function mapStateToProps(state) {
  return {
    dataLoaded: state.business.dataLoaded,
    loyalty: state.marketing.loyalty,
    menu: state.menu,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        editLoyalty,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoyaltyExclude);
