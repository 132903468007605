import React, { Component, lazy, Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import Header from '../../Landing/Header';
import Footer from '../../Landing/Footer';
import { Spinner } from '../../Landing/components';
import Banner from './Banner';
import { Link, Column } from '../../../components';
import './style.scss';

const LazyAboutUs = lazy(() => import('./AboutUs/index'));
const LazyTariff = lazy(() => import('./Tariff/index'));
const LazyTariffTestimonials = lazy(() => import('./TariffTestimonials/index'));
const LazyPricing = lazy(() => import('../../Landing/Pricing/index'));

class Quarantine extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t, history } = this.props;

    return (
      <div className="landing terms-wrapper" id="top">
        <Header />
        <Column className="sales-page" width="100%" alignItems="flex-start" justifyContent="flex-start">
          <Banner t={t} history={history} />
          <Suspense fallback={<Spinner />}>
            <LazyAboutUs t={t} />
          </Suspense>
          <Suspense fallback={<Spinner />}>
            <LazyTariff t={t} history={history} />
          </Suspense>
          <Suspense fallback={<Spinner />}>
            <LazyTariffTestimonials t={t} />
          </Suspense>
          <div className="landing-join sales-page-join">
            <div className="landing-join-text">
              <h2>{t('landing.joinUs')}</h2>
              <h3 dangerouslySetInnerHTML={{ __html: t('sales.quarantine.joinUs') }} />
            </div>
            <Link
              to="/app/login"
            >{t('control.try')}
            </Link>
          </div>
          <Suspense fallback={<Spinner />}>
            <LazyPricing />
          </Suspense>
        </Column>
        <Footer />
      </div>
    );
  }
}

export default withRouter(withTranslation()(Quarantine));
