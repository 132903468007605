import React from 'react';
import { Table } from 'antd';
import { getUnits } from '../utils/helpers';

const itemsColumns = (t, isAdmin, currency, showSize) => [
  {
    title: t('global.name'),
    dataIndex: 'displayName',
    width: 130,
    sorter: (a, b) => (-1) * a.displayName.localeCompare(b.displayName),
    sortDirections: ['descend', 'ascend'],
  },
  showSize ? {
    title: t('global.size'),
    dataIndex: 'size',
    render: size => size || '-',
    width: 100,
  } : null,
  {
    title: t('global.shortQuantity'),
    dataIndex: 'quantity',
    render: (quantity, record) => `${quantity} ${getUnits(record.meta?.units)}`,
    width: 90,
    sorter: (a, b) => {
      const delta = parseFloat(a.quantity) - parseFloat(b.quantity);
      if (delta > 0) return 1;
      if (delta < 0) return -1;
      return 0;
    },
    sortDirections: ['descend', 'ascend'],
  }, {
    title: t('global.price'),
    dataIndex: 'price',
    render: price => `${price} ${currency}`,
    width: 80,
    sorter: (a, b) => {
      const delta = parseFloat(a.price) - parseFloat(b.price);
      if (delta > 0) return 1;
      if (delta < 0) return -1;
      return 0;
    },
    sortDirections: ['descend', 'ascend'],
  }, isAdmin ? {
    title: t('cost.title'),
    dataIndex: 'cost',
    width: 150,
    render: text => `${text} ${currency}`,
    sorter: (a, b) => {
      const delta = parseFloat(a.cost) - parseFloat(b.cost);
      if (delta > 0) return 1;
      if (delta < 0) return -1;
      return 0;
    },
    sortDirections: ['descend', 'ascend'],
  } : null, {
    title: t('global.sum'),
    dataIndex: 'sum',
    render: text => `${text} ${currency}`,
    sorter: (a, b) => {
      const delta = parseFloat(a.sum) - parseFloat(b.sum);
      if (delta > 0) return 1;
      if (delta < 0) return -1;
      return 0;
    },
    sortDirections: ['descend', 'ascend'],
    width: 90,
  }, isAdmin ? {
    title: t('global.gross'),
    dataIndex: 'gross',
    width: 120,
    render: text => `${text} ${currency}`,
  } : null,
];

const ItemsTable = ({
  data, t, currency, isAdmin = true, showSize = false, className = '',
}) => (
  <Table
    bordered
    className={`supply-table ${className}`}
    pagination={false}
    size="default"
    scroll={{ x: 'true' }}
    style={{ width: '100%', marginTop: '10px' }}
    columns={itemsColumns(t, isAdmin, currency, showSize).filter(el => !!el)}
    /* TODO: Add fixed header */
    /* scroll={{ y: window.innerHeight - 50 - 52 - 33 - 80, x: '100%' }} */
    dataSource={data}
  />
);
export default ItemsTable;
