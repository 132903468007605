import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Keeper from './Keeper';
import { addToBill } from '../../redux/statistic/actions';
import { setEmployer } from '../../redux/keeper/actions';
import { showMessage } from '../../redux/message/actions';

function mapStateToProps(state) {
  return {
    activeKey: state.statistic.activeKey,
    name: state.business.name,
    activeEmployer: state.keeper.activeEmployer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        addToBill,
        setEmployer,
        showMessage,
      },
      dispatch,
    ),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Keeper));
