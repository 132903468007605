import { supplyTourSteps } from './steps';

export const getSupplyTours = (t, goToNextStep, lastStepEvent) => [
  {
    pageRegExp: /\w/g,
    id: 3,
    isComplete: false,
    redirect: '/app/storage/supply',
    title: t('reactour.supply.title'),
    beforeEnter: () => {
      const layoutSelector = document.querySelector('[data-tour="store-tour-0"]');
      if (layoutSelector.classList.contains('active-parent')) {
        layoutSelector.click();
      }
    },
    steps: supplyTourSteps(t, goToNextStep, lastStepEvent),
  },
];
