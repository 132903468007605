import React, { Component } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import {
  Button, Input, Select, Spin,
} from 'antd';
import { withTranslation } from 'react-i18next';
import { firebase } from '../../../config';
import {
  Header, Column, UpdateVersion, ManagePlaces, Link,
} from '../../../components';
import { notificationTypes, showNetworkError } from '../../../redux/message/actions';
import i18n from '../../../utils/translation';
import './style.scss';
import { isOnline } from '../../../persistance';

export const languages = [
  { key: 'en', img: 'united-kingdom.png', name: 'English' },
  { key: 'ru', img: 'russia.png', name: 'Русский' },
  { key: 'ua', img: 'ukraine.png', name: 'Українська' },
];

export const currencies = [
  { key: 'eur', value: '€', name: 'EUR €' },
  { key: 'dol', value: '$', name: 'USD $' },
  { key: 'rub', value: '₽', name: 'RUB ₽' },
  { key: 'grn', value: '₴', name: 'UAH ₴' },
];

export const autoCloseTimes = [
  { key: '00.00' },
  { key: '01.00' },
  { key: '02.00' },
  { key: '03.00' },
  { key: '04.00' },
  { key: '05.00' },
  { key: '06.00' },
];

export const timezones = [
  { value: '-12', text: '(GMT-12:00) International Date Line West' },
  { value: '-11', text: '(GMT-11:00) Midway Island, Samoa' },
  { value: '-10', text: '(GMT-10:00) Hawaii' },
  { value: '-9', text: '(GMT-09:00) Alaska' },
  { value: '-8', text: '(GMT-08:00) Pacific Time (US & Canada)' },
  { value: '-8', text: '(GMT-08:00) Tijuana, Baja California' },
  { value: '-7', text: '(GMT-07:00) Arizona' },
  { value: '-7', text: '(GMT-07:00) Chihuahua, La Paz, Mazatlan' },
  { value: '-7', text: '(GMT-07:00) Mountain Time (US & Canada)' },
  { value: '-6', text: '(GMT-06:00) Central America' },
  { value: '-6', text: '(GMT-06:00) Central Time (US & Canada)' },
  { value: '-6', text: '(GMT-06:00) Guadalajara, Mexico City, Monterrey' },
  { value: '-6', text: '(GMT-06:00) Saskatchewan' },
  { value: '-5', text: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco' },
  { value: '-5', text: '(GMT-05:00) Eastern Time (US & Canada)' },
  { value: '-5', text: '(GMT-05:00) Indiana (East)' },
  { value: '-4', text: '(GMT-04:00) Atlantic Time (Canada)' },
  { value: '-4', text: '(GMT-04:00) Caracas, La Paz' },
  { value: '-4', text: '(GMT-04:00) Manaus' },
  { value: '-4', text: '(GMT-04:00) Santiago' },
  { value: '-3', text: '(GMT-03:00) Newfoundland' },
  { value: '-3', text: '(GMT-03:00) Brasilia' },
  { value: '-3', text: '(GMT-03:00) Buenos Aires, Georgetown' },
  { value: '-3', text: '(GMT-03:00) Greenland' },
  { value: '-3', text: '(GMT-03:00) Montevideo' },
  { value: '-2', text: '(GMT-02:00) Mid-Atlantic' },
  { value: '-1', text: '(GMT-01:00) Cape Verde Is.' },
  { value: '-1', text: '(GMT-01:00) Azores' },
  { value: '0', text: '(GMT+00:00) Casablanca, Monrovia, Reykjavik' },
  { value: '0', text: '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London' },
  { value: '1', text: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna' },
  { value: '1', text: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague' },
  { value: '1', text: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris' },
  { value: '1', text: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb' },
  { value: '1', text: '(GMT+01:00) West Central Africa' },
  { value: '2', text: '(GMT+02:00) Amman' },
  { value: '2', text: '(GMT+02:00) Athens, Bucharest, Istanbul' },
  { value: '2', text: '(GMT+02:00) Beirut' },
  { value: '2', text: '(GMT+02:00) Cairo' },
  { value: '2', text: '(GMT+02:00) Harare, Pretoria' },
  { value: '2', text: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius' },
  { value: '2', text: '(GMT+02:00) Jerusalem' },
  { value: '2', text: '(GMT+02:00) Minsk' },
  { value: '2', text: '(GMT+02:00) Windhoek' },
  { value: '3', text: '(GMT+03:00) Kuwait, Riyadh, Baghdad' },
  { value: '3', text: '(GMT+03:00) Moscow, St. Petersburg, Volgograd' },
  { value: '3', text: '(GMT+03:00) Nairobi' },
  { value: '3', text: '(GMT+03:00) Tbilisi' },
  { value: '3', text: '(GMT+03:00) Tehran' },
  { value: '4', text: '(GMT+04:00) Abu Dhabi, Muscat' },
  { value: '4', text: '(GMT+04:00) Baku' },
  { value: '4', text: '(GMT+04:00) Yerevan' },
  { value: '4', text: '(GMT+04:00) Kabul' },
  { value: '5', text: '(GMT+05:00) Yekaterinburg' },
  { value: '5', text: '(GMT+05:00) Islamabad, Karachi, Tashkent' },
  { value: '5', text: '(GMT+05:00) Chennai, Kolkata, Mumbai, New Delhi' },
  { value: '5', text: '(GMT+05:00) Sri Jayawardenapura' },
  { value: '5', text: '(GMT+05:05) Kathmandu' },
  { value: '6', text: '(GMT+06:00) Almaty, Novosibirsk' },
  { value: '6', text: '(GMT+06:00) Astana, Dhaka' },
  { value: '6', text: '(GMT+06:00) Yangon (Rangoon)' },
  { value: '7', text: '(GMT+07:00) Bangkok, Hanoi, Jakarta' },
  { value: '7', text: '(GMT+07:00) Krasnoyarsk' },
  { value: '8', text: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi' },
  { value: '8', text: '(GMT+08:00) Kuala Lumpur, Singapore' },
  { value: '8', text: '(GMT+08:00) Irkutsk, Ulaan Bataar' },
  { value: '8', text: '(GMT+08:00) Perth' },
  { value: '8', text: '(GMT+08:00) Taipei' },
  { value: '9', text: '(GMT+09:00) Osaka, Sapporo, Tokyo' },
  { value: '9', text: '(GMT+09:00) Seoul' },
  { value: '9', text: '(GMT+09:00) Yakutsk' },
  { value: '9', text: '(GMT+09:00) Adelaide' },
  { value: '9', text: '(GMT+09:00) Darwin' },
  { value: '10', text: '(GMT+10:00) Brisbane' },
  { value: '10', text: '(GMT+10:00) Canberra, Melbourne, Sydney' },
  { value: '10', text: '(GMT+10:00) Hobart' },
  { value: '10', text: '(GMT+10:00) Guam, Port Moresby' },
  { value: '10', text: '(GMT+10:00) Vladivostok' },
  { value: '11', text: '(GMT+11:00) Magadan, Solomon Is., New Caledonia' },
  { value: '12', text: '(GMT+12:00) Auckland, Wellington' },
  { value: '12', text: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.' },
  { value: '13', text: '(GMT+13:00) Nuku\'alofa' },
];

class General extends Component {
  constructor(props) {
    super(props);
    const { business } = props;
    this.state = {
      ...business,
      pendingSave: false,
    };
  }

  submit = () => {
    const { t, actions: { showMessage, updatePlaceInfo } } = this.props;
    const {
      adminPassword, name, language, shiftAutoClose, currency, offset,
    } = this.state;
    if (adminPassword.trim() === '') {
      showMessage({ text: t('admin.checkTitle'), type: notificationTypes.ERROR });
      return;
    }
    if (name.trim() === '') {
      showMessage({ text: i18n.t('validation.emptyName'), type: notificationTypes.ERROR });
      return;
    }
    this.setState({ pendingSave: true });
    updatePlaceInfo({
      adminPassword, name, language, shiftAutoClose, currency, offset,
    }, true).then(() => {
      this.setState({ pendingSave: false });
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { adminPassword, activePlace } = nextProps.business;
    if (adminPassword !== this.props.business.adminPassword || activePlace !== this.props.activePlace) {
      this.setState({ ...nextProps.business });
    }
  }

  render() {
    const {
      t, actions: { logout }, version, business,
    } = this.props;
    const {
      adminPassword, name, language, pendingSave, currency, offset,
    } = this.state;
    return (
      <Spin wrapperClassName="storage-page settings-page" spinning={!firebase.auth().currentUser}>
        <Header>
          <Button
            onClick={() => {
              if (!isOnline()) return showNetworkError();
              return this.managePlaces.show();
            }}
            icon={<MenuOutlined />}
            type="link"
          >
            {t('settings.managePlace')}
          </Button>
          <h2 className="header-name">{business.name}</h2>
          <Button
            style={{ margin: '15px 15px 15px 0' }}
            onClick={() => logout()}
            className="logout"
            type="link"
          >
            {t('logOut')}
          </Button>
        </Header>
        <div className="storage-page-content">
          <UpdateVersion />
          <Column
            className="mobile-block"
            style={{ maxWidth: '600px' }}
            width="100%"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <div className="settings-content">
              <Column
                width="295px"
                style={{ minHeight: '285px' }}
                alignItems="flex-start"
                justifyContent="flex-start"
                className="default-card settings-block"
              >
                <h2>{t('settings.title')}</h2>
                <h3 style={{ margin: '10px 0 5px' }}>{t('global.name')}</h3>
                <Input
                  style={{ width: '100%' }}
                  placeholder={t('global.name')}
                  value={name}
                  onChange={e => this.setState({ name: e.target.value })}
                />
                <h3 style={{ margin: '10px 0 5px' }}>{t('admin.adminPassword')}</h3>
                <Input
                  style={{ width: '100%' }}
                  placeholder={t('admin.adminPassword')}
                  value={adminPassword}
                  type={business.isAdmin ? 'text' : 'password'}
                  onChange={e => this.setState({ adminPassword: e.target.value })}
                />
                <h3 style={{ margin: '10px 0 5px' }}>{t('login.email')}</h3>
                <Input style={{ width: '100%' }} value={business.email} disabled />
              </Column>
              <Column
                width="295px"
                style={{ minHeight: '285px' }}
                margin="0 0 0 10px"
                alignItems="flex-start"
                justifyContent="flex-start"
                className="default-card settings-block"
              >
                <h2>{t('settings.general')}</h2>
                <h3 style={{ margin: '10px 0 5px' }}>{t('global.language')}</h3>
                <Select style={{ width: '100%' }} value={language} onChange={e => this.setState({ language: e })}>
                  {languages.map(el => (
                    <Select.Option value={el.key} key={el.name}>
                      <img
                        style={{
                          width: '25px', height: '25px', cursor: 'pointer', marginRight: '10px',
                        }}
                        src={`/assets/flags/${el.img}`}
                        alt={el.key}
                      />{el.name}
                    </Select.Option>
                  ))}
                </Select>
                <h3 style={{ margin: '10px 0 5px' }}>{t('settings.currency')}</h3>
                <Select style={{ width: '100%' }} value={currency} onChange={e => this.setState({ currency: e })}>
                  {currencies.map(el => (
                    <Select.Option value={el.value} key={el.key}>{el.name}</Select.Option>
                  ))}
                </Select>
                <h3 style={{ margin: '10px 0 5px' }}>{t('time.offset')}</h3>
                <Select
                  style={{ width: '100%' }}
                  value={offset}
                  onChange={e => this.setState({ offset: e })}
                >
                  {timezones.map(zone => (
                    <Select.Option value={`${zone.value};${zone.text}`} key={zone.text}>
                      {zone.text}
                    </Select.Option>
                  ))}
                </Select>
                {/* <span className="settings-help-text">{t('settings.shiftAutoCloseSubtitle')}</span> */}
              </Column>
            </div>
            <Button
              loading={pendingSave}
              type="primary"
              size="large"
              className="settings-save"
              onClick={this.submit}
            >{t('control.save')}
            </Button>
            <p className="settings-text">Version:&nbsp;
              <Link to="/app/settings/devCenter" className="settings-invisible-link">
                #{version}@{process.env.REACT_APP_MINOR_VERSION}
              </Link>
            </p>
          </Column>
        </div>
        <ManagePlaces ref={(ref) => { this.managePlaces = ref; }} />
      </Spin>
    );
  }
}

export default withTranslation()(General);
