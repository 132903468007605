import {
  getCommunicationDataRequest, getNotificationRequest, updateCommunicationRequest, updateNotificationRequest,
} from '../../api/db/communication';
import { getPersistedCommunication, isOnline, persistWithKey } from '../../persistance';
import { showErrorMessage } from '../message/actions';
import { idbCommunication } from '../../config';

export const GET_COMMUNICATION = 'GET_COMMUNICATION';
export const EDIT_COMMUNICATION = 'EDIT_COMMUNICATION';
export const ADD_PERSONAL_ID = 'ADD_PERSONAL_ID';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';

export function getCommunication(persisted = false) {
  return (dispatch) => {
    if (persisted || !isOnline()) {
      return getPersistedCommunication()
        .then(data => dispatch({ type: GET_COMMUNICATION, payload: data }));
    }
    return getCommunicationDataRequest()
      .then((res) => {
        dispatch({ type: GET_COMMUNICATION, payload: res.data });
      })
      .catch(showErrorMessage);
  };
}

export function editCommunication(data) {
  return (dispatch, getState) => updateCommunicationRequest(data)
    .then((res) => {
      dispatch({ type: EDIT_COMMUNICATION, payload: res.data });

      const { communication: { personalId } } = getState();

      persistWithKey('communication', { personalId, ...res.data }, idbCommunication);
    })
    .catch(showErrorMessage);
}

export function addPersonalId(id) {
  return (dispatch, getState) => updateCommunicationRequest({ personalId: id })
    .then(() => {
      dispatch({ type: ADD_PERSONAL_ID, payload: id });

      const { communication: { allowedEvents } } = getState();

      persistWithKey('communication', { personalId: id, allowedEvents }, idbCommunication);
    })
    .catch(showErrorMessage);
}

export function getNotifications() {
  return async (dispatch) => {
    getNotificationRequest()
      .then((res) => {
        dispatch({ type: GET_COMMUNICATION, payload: res.data });
      })
      .catch(showErrorMessage);
  };
}

/**
 *
 * @param data: notification data
 * @param {string} data.type - Notification type
 * @param {string=} data.author - Shift event, author
 * @param {integer=} data.meta.count - Count of money, that was used in event
 * @param {string=} data.meta.time - Event time
 * @param {string=} data.meta.supplier - Storage event actor
 * @param {string=} data.meta.to - Financial event, transaction to account
 * @param {string=} data.meta.from - Financial event, transaction from account
 * @returns {function(...[*]=)}
 */
export function addNewNotification(data) {
  return (dispatch) => {
    const notification = {
      ...data, processed: false, id: Date.now(), viewed: false,
    };

    return updateNotificationRequest(notification.id, notification)
      .then((res) => {
        dispatch({ type: ADD_NOTIFICATION, payload: res.data });
      })
      .catch(showErrorMessage);
  };
}
