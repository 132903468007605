import tour from './tours/ua';
import faq from './faq/ua';

/* eslint-disable */
export default {
  validation: {
    bonusRangeNoGroup: 'Оберіть бонусну систему для групи',
    bonusRangeNoAmount: 'Оберіть кількість бонусів для групи',
    saleRangeNoGroup: 'Оберіть систему знижок для групи',
    saleRangeNoAmount: 'Оберіть розмір знижки для групи',
    promotionDays: 'Оберіть хоча б 1 день роботи акції!',
    saleItems: 'Оберіть хоча б 1 категорію або товар!',
    text: 'Додайте трохи тексту!',
    forgotPassword: 'Напишіть нам щоб вирішити цю проблему!',
    something: 'Щось пішло не так!',
    email: 'Невірно відформатований емейл!',
    password: 'Додайте пароль!',
    passwordRepeat: 'Введені різні паролі!',
    passwordLength: 'Пароль має бути не менше 6 символів!',
    chooseComponent: 'Оберіть компонент!',
    addonCalculation: 'Додайте калькуляцію товару!',
    addonPrice: 'Додайте ціну товару!',
    productName: 'Додайте назву продукту!',
    pickSize: 'Оберіть хоча б один розмір!',
    addConsumable: 'Додайте хоча б один матеріал!',
    productComponents: 'Додайте компоненти продукту!',
    productPrice: 'Додайте ціну продукту!',
    productVolume: 'Додайте об\'єм продукту!',
    componentCount: 'Додайте кількість компоненту!',
    addCount: 'Додайте кількість!',
    itemName: 'Додайте назву товару!',
    itemPrice: 'Додайте ціну товару!',
    emptyName: 'Додайте назву!',
    emptyPhone: 'Додайте номер телефону!',
    emptySizes: 'Перевірте розміри!',
    emptyCategory: 'Додайте категорію!',
    emptyGroup: 'Оберіть групу!',
    emptyTransactionType: 'Оберіть хоча б 1 тип транзакцій!',
    emptyAccount: 'Додайте рахунок!',
    emptySum: 'Додайте суму!',
    wrongIncome: 'Не можна отримати більше, ніж було відправлено!',
    alreadyHaveConsumable: 'Ви не можете змінювати розміри після того, як обрали витратні матеріали!',
    pickConsumables: 'Оберіть хоча б один матеріал!',
    consumableName: 'Додайте назву матеріалу!',
    uniqueProductName: 'Продукт з такою назвою вже існує!',
    uniqueComponentName: 'Компонент з такою назвою вже існує!',
    uniqueItemName: 'Товар з такою назвою вже існує!',
    uniqueAddonName: 'Модифікатор з такою назвою вже існує!',
    uniqueConsumableName: 'Матеріал з такою назвою вже існує!',
    uniqueGroupName: 'Група з такою назвою вже існує!',
    choose: 'Оберіть...',
    passwordInUse: 'Цей пароль вже використовується!',
    noSuchEmployer: 'Не знайдено співробітника з таким паролем!',
    emptySalary: 'Додайте хоча б один тип нарахування зарплати!',
    addJob: 'Оберіть посаду!',
    componentInUse: 'Ви не можете видалити компонент, який є модифікатором, або частиною рецепту!',
    itemInUse: 'Ви не можете видалити матеріал, який є частиною рецепту!',
    zeroValue: 'Зарплата повинна бути більша за 0!',
    saleMoreThen100: 'Розмір знижки не може бути більше 100%!',
    emptyInviteBonus: 'Додайте розмір бонуса за реєстрацію',
    emptyMaxBonus: 'Додайте максимальний можливий % оплати чека бонусами!',
  },
  actions: {
    needNetworkTitle: 'Ви маєте бути онлайн для виконання цієї дії!',
    needNetworkDescription: 'перевірте ваше підключення до Інтернету.',
    employerEditedTitle: 'Дані співробітника успішно збережені!',
    employerCreatedTitle: 'Співробітника створений успішно!',
    employerDeletedTitle: 'Співробітник успішно видалений!',
    jobEditedTitle: 'Дані посади успішно збережені!',
    jobCreatedTitle: 'Посаду створено успішно!',
    jobDeletedTitle: 'Посада успішно видалена!',
    consumableEditedTitle: 'Дані матеріалу успішно збережені!',
    consumableCreatedTitle: 'Матеріал створено успішно!',
    consumableDeletedTitle: 'Матеріал успішно видалено!',
    equipmentEditedTitle: 'Дані комплекту успішно збережені!',
    equipmentCreatedTitle: 'Комплект створено успішно!',
    equipmentDeletedTitle: 'Комплект успішно видалено!',
    financialAccountEditedTitle: 'Дані фінансового акаунту успішно збережені!',
    financialAccountCreatedTitle: 'Фінансовий акаунт створено успішно!',
    financialAccountDeletedTitle: 'Фінансовий акаунт успішно видалено!',
    financialCategoryEditedTitle: 'Дані фінансової категорії успішно збережені!',
    financialCategoryCreatedTitle: 'Фінансова категорія створена успішно!',
    financialCategoryDeletedTitle: 'Фінансова категорія успішно видалена!',
    financialTransactionEditedTitle: 'Дані фінансової транзакції успішно збережені!',
    financialTransactionCreatedTitle: 'Фінансова транзакція створена успішно!',
    financialTransactionDeletedTitle: 'Фінансова транзакція успішно видалена!',
    keeperEditedTitle: 'Налаштування терміналу успішно збережені!',
    marketingLoyaltyEditedTitle: 'Система лояльності успішно збережена!',
    marketingClientEditedTitle: 'Дані клієнта успішно збережені!',
    marketingClientCreatedTitle: 'Клієнта створено успішно!',
    marketingClientDeletedTitle: 'Клієнта успішно видалена!',
    marketingGroupEditedTitle: 'Дані групи успішно збережені!',
    marketingGroupCreatedTitle: 'Група створена успішно!',
    marketingGroupDeletedTitle: 'Група успішно видалена!',
    marketingSaleEditedTitle: 'Дані акції успішно збережені!',
    marketingSaleCreatedTitle: 'Акція створена успішно!',
    marketingSaleDeletedTitle: 'Акція успішно видалена!',
    menuAddonEditedTitle: 'Дані модифікатора успішно збережені!',
    menuAddonCreatedTitle: 'Модифікатор створено успішно!',
    menuAddonDeletedTitle: 'Модифікатор успішно видалено!',
    menuCategoryEditedTitle: 'Дані категорії успішно збережені!',
    menuCategoryCreatedTitle: 'Категорія створена успішно!',
    menuCategoryDeletedTitle: 'Категорія успішно видалена!',
    menuComponentEditedTitle: 'Дані компоненту успішно збережені!',
    menuComponentCreatedTitle: 'Компонент створено успішно!',
    menuComponentDeletedTitle: 'Компонент успішно видалено!',
    menuItemEditedTitle: 'Дані товару успішно збережені!',
    menuItemCreatedTitle: 'Товар створено успішно!',
    menuItemDeletedTitle: 'Товар успішно видалено!',
    menuProductEditedTitle: 'Дані продукту успішно збережені!',
    menuProductCreatedTitle: 'Продукт створено успішно!',
    menuProductDeletedTitle: 'Продукт успішно видалено!',
    storageSupplierEditedTitle: 'Дані постачальника успішно збережені!',
    storageSupplierCreatedTitle: 'Постачальника створено успішно!',
    storageSupplierDeletedTitle: 'Постачальника успішно видалено!',
    storageSupplyEditedTitle: 'Дані поставки успішно збережені!',
    storageSupplyCreatedTitle: 'Поставку створено успішно!',
    storageSupplyDeletedTitle: 'Поставку успішно видалено!',
    storageWasteEditedTitle: 'Дані списання успішно збережені!',
    storageWasteCreatedTitle: 'Списання створено успішно!',
    storageWasteDeletedTitle: 'Списання успішно видалено!',
    storageInventoryEditedTitle: 'Дані інвентаризації успішно збережені!',
    storageInventoryCreatedTitle: 'Інвентаризацію створено успішно!',
    storageInventoryDeletedTitle: 'Інвентаризацію успішно видалено!',
  },
  feedback: {
    title: 'Поділіться з нами вашими враженнями!'
  },
  textPlaceholder: 'Для мене Shoptoria це...',
  loading: {
    employers: 'Отримуємо данні про співробітників...',
  },
  modals: {
    deleteTitle: 'Ви певні, що хочете видалити позицію?',
  },
  actionDrawer: {
    title: 'Список дій',
    createProduct: 'Створити продукт',
    createItem: 'Створити товар',
    createAddon: 'Створити модифікатор',
    manageCategories: 'Категорії',
    createConsumable: 'Додати витратний матеріал',
    createModalTitle: 'Що ви хочете створити?',
    createReceipt: 'Створити рецепт',
  },
  logOut: 'Вихід',
  login: {
    header: 'Вхід',
    email: 'Емейл',
    password: 'Пароль',
    submit: 'Увійти',
    createAccount: 'Створити аккаунт',
    mainPage: 'На головну сторінку',
    forgotPassword: 'Забули пароль?',
    title: 'Ви не маєте акаунту?',
    description: 'Приєднуйтесь до Shoptoria вже сьогодні і отримайте 14 днів безкоштовного користування!',
  },
  signUp: {
    header: 'Реєстрація',
    email: 'Емейл',
    password: 'Пароль',
    passwordRepeat: 'Повторіть пароль',
    submit: 'Створити Акаунт',
    logIn: 'Увійти в існуючий аккаунт',
    terms: 'Натискаючи Створити Акаунт ви підтверджуєте, що ви старше 18 років і погоджуєтесь з',
    termsLink: 'Угодою користувача',
    title: 'Вже маєте акаунт?',
    description: 'Ми раді знову вас бачити! Увійдіть до акаунту, щоб продовжити.',
  },
  layout: {
    keeper: 'Термінал',
    menu: 'Меню',
    statistic: 'Статистика',
    store: 'Склад',
    inventory: 'Інвентар',
    equipment: 'Equipment',
    kits: 'Комплекти',
    kit: 'Комплект',
    items: 'Товари',
    bills: 'Чеки',
    profit: 'Продажі',
    category: 'Категорії',
    payments: 'Оплати',
    marketing: 'Маркетинг',
    clients: 'Клієнти',
    loyalty: 'Програми лояльності',
    sales: 'Акції',
    groups: 'Групи клієнтів',
    loyaltyExclude: 'Виключення',
    financial: 'Фінанси',
    transactions: 'Транзакції',
    account: 'Рахунки',
    financialReport: 'Звіт',
    salary: 'Зарплата',
    shifts: 'Кассові зміни',
    access: 'Доступ',
    jobs: 'Посади',
    employers: 'Співробітники',
    settings: 'Налаштування',
    general: 'Загальні',
    notifications: 'Сповіщення',
    updates: 'Оновлення',
    subscription: 'Оплата підписки',
    checkbox: 'Checkbox',
  },
  keeper: {
    promotions: 'Акції',
    menuSearch: 'Пошук по меню',
    hi: 'Привіт,',
    openCashShift: 'Кассова зміна відкрита, бажаємо гарного дня! (＾◡＾)',
  },
  components: {
    new: 'Новий компонент',
    modalTitle: 'Створити новий компонент',
    modalPlaceholder: 'Назва компоненту',
    edit: 'Редагування компоненту',
    replacer: 'Можна замінити на',
    add: 'Додати компонент',
  },
  addon: {
    modalTitle: 'Створити новий модифікатор',
    modalSelect: 'Оберіть компонент',
  },
  product: {
    modalTitle: 'Створити новий продукт',
    modalNamePlaceholder: 'Назва продукту',
    modalChooseCategory: 'Оберіть категорію',
    modalSizes: 'Розміри продукту',
  },
  item: {
    modalTitle: 'Створити новий товар',
    modalNamePlaceholder: 'Назва товару',
    modalSizes: 'Розміри товару',
    forWeight: 'Продавати на вагу',
    saleCost: 'Ціна продажу',
    price: 'Ціна за',
    weight: 'Додайте кількість товару',
    add: 'Додати товари',
  },
  consumable: {
    modalTitle: 'Додати матеріал',
    modalNamePlaceholder: 'Назва матеріалу',
    add: 'Додати матеріал',
    edit: 'Редагування матеріалу',
    select: 'Оберіть матеріали',
  },
  equipment: {
    add: 'Додати комплект',
    manage: 'Змінити інвентар',
    edit: 'Редагування комплекту',
    kitName: 'Назва комплекту',
    helpText: 'Усі обрані матеріали будуть доступні при заміні матеріалу в продукті.',
    predefined: 'Готові комплекти',
    empty: 'Пусто',
  },
  storage: {
    onStore: 'Залишки',
    supply: 'Поставки',
    waste: 'Списання',
    inventory: 'Інвентаризації',
    consumption: 'Витрати',
    suppliers: 'Постачальники',
  },
  waste: {
    modalTitle: 'Нове списання',
    modalEdit: 'Редагувати списання',
  },
  inventory: {
    modalTitle: 'Додати інвентаризацію',
    modalEdit: 'Редагувати інвентаризацію',
    before: 'Було',
    now: 'На складі',
    diff: 'Різниця',
    profit: 'Прибуток',
    expenses: 'Витрати',
    result: 'Результат',
  },
  supply: {
    modalTitle: 'Додати нову поставку',
    modalEdit: 'Редагувати поставку',
    supplier: 'Постачальник',
    date: 'Дата',
    comment: 'Коментар',
    addSupply: 'Додати поставку',
  },
  supplier: {
    modalTitle: 'Додати нового постачальника',
    modalEdit: 'Редагувати постачальника',
    addSupply: 'Додати постачальника',
  },
  cost: {
    title: 'Собівартість',
    count: 'Кількість',
    one: 'Ціна одиниці',
    price: 'Сума',
    recommended: 'Рекомендована ціна',
    principe: 'Рекомендована ціна вираховується як 100% прибуток над собівартістю.',
    missedItem: 'Для правильного розрахунку собівартості продукту, додайте собівартість всіх матеріалів',
  },
  units: {
    pc: 'шт',
    ml: 'мл',
    g: 'г',
    l: 'л',
    kg: 'кг',
  },
  count: 'шт',
  categories: {
    modalTitle: 'Управління категоріями',
    add: 'Додати категорію',
    edit: 'Редагування категорії',
  },
  control: {
    print: 'Друк',
    create: 'Створити',
    cancel: 'Відміна',
    add: 'Додати',
    edit: 'Змінити',
    save: 'Зберегти',
    delete: 'Видалити',
    separate: 'Розділити',
    pay: 'Оплата',
    close: 'Закрити',
    apply: 'Застосувати',
    remove: 'Прибрати',
    collapse: 'Сховати',
    validate: 'Validate',
    clear: 'Видалити',
    submit: 'Надіслати',
    try: 'Спробувати',
    open: 'Відкрити',
    update: 'Оновити',
    start: 'Почати',
    back: 'Назад',
    next: 'Далі',
    check: 'Перевірити',
  },
  sale: {
    modalPercent: 'Відсоток знижки',
    modalAmount: 'Сума знижки',
    firstMonth: 'Перший місяць підписки лише за 1 долар',
  },
  pay: {
    cash: 'Готівкою',
    card: 'Карткою',
    bonus: 'Бонуси',
    byBonus: 'Бонусами',
    bonusAmount: 'Доступно бонусів',
    maxBonusAmount: 'Максимальная сумма оплаты бонусами',
    change: 'Решта',
  },
  category: {
    name: 'Назва категорії',
    list: 'Список категорій',
    alreadyExist: 'Категорія з такою назвою вже існує!',
  },
  bill: {
    modalTitleSeparate: 'Розділити чек',
    nodalActiveBill: 'Активний чек',
    modalNewBill: 'Новий чек',
    count: 'Кількість чеків',
    clean: 'Очистити',
    addComment: 'Додати коментар',
    commentForBill: 'Коментар до чеку',
  },
  image: {
    modalTitle: 'Оберіть частину зображення',
    croppedImg: 'Частина зображення',
    cardExample: 'Приклад карточки',
    cardName: 'Приклад',
  },
  notification: {
    settingsTitle: 'Сповіщення',
    telegramToken: 'Telegram token',
    telegramBot: 'Telegram боту',
    telegramHelpText1: 'Щоб отримати Telegram token, напишіть',
    telegramHelpText2: '',
    telegramHelpText3: 'Бот надішле Вам ваш',
    telegramHelpText4: 'Скопіюйте ваш token в поле вище.',
    allowedEvents: 'Оберіть події, про які Ви хочете отримувати повідомлення:',
    openShift: 'Відкриття касової зміни',
    closeShift: 'Закриття касової зміни',
    addTransaction: 'Додано фінансову транзакцію',
    addSupply: 'Додано поставку на склад',
    received: 'Повідомлення успішно відправлено!',
    tokenAdded: 'Telegram token збережено!',
    formSaved: 'Успішно збережено!',
    comingSoon: 'Скоро!',
    comingSoonDescription: 'Ми працюємо над цим прямо зараз!',
  },
  marketing: {
    clients: {
      addClient: 'Додати нового клієнта',
      editClient: 'Редагування данних кдієнта',
      name: 'Ім\'я',
      gender: 'Стать',
      male: 'Чоловік',
      female: 'Жінка',
      number: 'Номер картки',
      profitCount: 'Сума покупок',
      creationDate: 'Дата створення',
      bonusAmount: 'Кількість бонусів',
    },
    groups: {
      createGroup: 'Додати групу клієнтів',
      editGroup: 'Редагування групи клієнтів',
      name: 'Назва групи',
      type: 'Програма лояльності',
      sale: 'Система знижок',
      bonus: 'Система бонусів',
      selectGroup: 'Оберіть групу',
      salePercent: 'Розмір знижки',
      bonusPercent: 'Розмір бонусів',
    },
    loyalty: {
      bonusDescription1: 'Клієнти будуть накопичувати бонуси и оплачувати ними замовлення або їх частини. Для цього налаштуйте різний розмір бонусів для ваших груп клієнтів.',
      bonusDescription2: 'А щоб відсоток бонусів збільшувався, включіть автоматичний перехід між групами і вкажіть суми оплати замовлень, при досягненні яких клієнт перейде в групу з більш високим відсотком бонусів.',
      saleDescription1: 'Клієнти будуть отримувати знижку від суми чека. Для цього налаштуйте різний розмір знижки для ваших груп клієнтів.',
      saleDescription2: 'А щоб відсоток знижки збільшувався, включіть автоматичний перехід між групами і вкажіть суми оплати замовлень, при досягненні яких клієнт перейде в групу з більш високим відсотком знижки.',
      maxBonus: 'Максимально можливий % оплати чека бонусами',
      inviteBonus: 'Бонус за реєстрацію',
      groupTransit: 'Перехід між групами',
      transitCondition: 'Умови переходу',
      yes: 'є',
      no: 'немає',
      excludedCategories: 'Категорії-виключення',
      excludedCategoriesDescription: 'Категорії товарів, на які не розповсюджеється оплата бонусами, але приміняються акції:',
      excludedItems: 'Товари-виключення',
      excludedItemsDescription: 'Товары, на які не розповсюджеється оплата бонусами, але приміняються акції:',
      selectCategories: 'Оберіть категорії',
    },
    sales: {
      addSale: 'Створення акції',
      editSale: 'Редагування акції',
      saleDate: 'Період роботи акції',
      startDate: 'Дата початку акції',
      endDate: 'Дата кінця акції',
      saleTime: 'Час роботи акції',
      endTime: 'Час кінця акції',
      includedCategories: 'Категорії',
      includedItems: 'Товари',
      saleType: 'Тип знижки',
      percent: 'Відсоток знижки',
      amount: 'Сума знижки',
      activeDays: 'Дні роботи акції',
      needBill: 'Відкрийте чек щоб продовжити!',
    }
  },
  days: {
    mon: 'Пн',
    tue: 'Вт',
    wed: 'Сер',
    thu: 'Чт',
    fri: 'Пт',
    sat: 'Сб',
    sun: 'Нд',
  },
  global: {
    contacts: 'Контакти',
    nothing: 'Нічого',
    group: 'Група',
    favourites: 'Обрані',
    image: 'Зображення',
    name: 'Назва',
    timeRange: 'Часовий проміжок',
    startDate: 'Дата початку',
    endDate: 'Дата кінця',
    sale: 'Знижка',
    bonus: 'Бонус',
    category: 'Категорія',
    all: 'Всі',
    product: 'Продукт',
    products: 'Продукти',
    item: 'Товар',
    items: 'Товари',
    component: 'Компонент',
    components: 'Компоненти',
    addon: 'Модифікатор',
    addons: 'Модифікатори',
    consumable: 'Витратний матеріал',
    consumables: 'Витратні матеріали',
    materials: 'Матеріали',
    examples: 'Приклади',
    receipt: 'Рецепт',
    size: 'Розмір',
    bonuses: 'Бонуси',
    total: 'До сплати',
    price: 'Ціна',
    pricing: 'Ціноутворення',
    volume: 'Об\'єм',
    calculation: 'Калькуляція',
    subtotal: 'Сума',
    quantity: 'Кількість',
    shortQuantity: 'Кіл-ть',
    search: 'Пошук',
    cardNumber: 'Номер карти',
    client: 'Клієнт',
    clients: 'Клієнти',
    language: 'Мова',
    type: 'Тип',
    units: 'Од. вимірювання',
    photo: 'Зображення',
    profit: 'Прибуток',
    weightProfit: 'Прибуток за 100',
    excludeConsumableCost: 'Без урахування собівартості матеріалів',
    sum: 'Всього',
    balance: 'Баланс',
    select: 'Оберіть...',
    other: 'Інші',
    gross: 'Валовий прибуток',
    extra: 'Націнка',
    description: 'Додаткова інформація',
    revenue: 'Виручка',
    sales: 'Продажі',
    month: 'за місяць',
    owner: 'Власник',
  },
  optional: {
    title: 'опціонально',
    addSoon: 'можна додати пізніше',
  },
  admin: {
    signUp: 'Додайте інформацію про ваш бізнес',
    shopName: 'Назва закладу',
    businessType: 'Тип вашого закладу',
    coffee: 'Кав\'яpня',
    cafe: 'Кафе',
    restaurant: 'Ресторан',
    customType: 'Інше',
    modalTitle: 'Додайте пароль адміністратора',
    adminPassword: 'Пароль адміністратора',
    modalDescription: 'Пароль адміністратора потрібен для доступу до меню, інвентаризації та статистики.',
    checkPassword: 'Готово',
    checkTitle: 'Введіть пароль адміністратора!',
    checkType: 'Оберіть тип закладу!',
    removeAccess: 'Прибрати доступ',
    wrongPassword: 'Невірний пароль!',
    notAdminUser: 'Ви маєте мати доступ адміністратора, щоб зробити це!',
  },
  time: {
    today: 'Сьогодні',
    yesterday: 'Вчора',
    week: 'За тиждень',
    month: 'За місяць',
    custom: 'Часовий проміжок',
    all: 'За весь час',
    range: 'Часовий проміжок',
    sortByTime: 'По часу',
    sortByDay: 'По дням тижня',
    mon: 'Пн',
    tue: 'Вт',
    wed: 'Ср',
    thu: 'Чт',
    fri: 'Пт',
    sat: 'Сб',
    sun: 'Нд',
    offset: 'Часовий пояс'
  },
  bills: {
    new: 'Новий чек',
    openTime: 'Час відкриття чеку',
    closeTime: 'Час закриття чеку',
  },
  financial: {
    report: 'Фінансовий звіт',
    safeAccount: 'Сейф',
    cashbox: 'Каса',
    cardAccount: 'Рахунок ФОП',
    addNewCategory: 'Додавання категорї транзакцій',
    editCategory: 'Редагування категорї транзакцій',
    addNewAccount: 'Створення фінансового рахунку',
    editAccount: 'Редагування фінансового рахунку',
    addNewTransaction: 'Додати транзакцію',
    editTransaction: 'Редагувати транзакцію',
    categories: 'Категорії транзакцій',
    income: 'Прибуток',
    waste: 'Витрати',
    transfer: 'Перевод',
    onlyIncome: 'Лише прибуток',
    onlyWaste: 'Лише витрати',
    allowedOperation: 'Дозволені типи транзакції',
    actual: 'Актуалізація',
    bank: 'Банківські послуги',
    supply: 'Поставки',
    shift: 'Касові зміни',
    exchange: 'Розмін',
    cash: 'Готівка',
    card: 'Банківська картка',
    account: 'Банківський рахунок',
    account2: 'Рахунок',
    operation: 'Транзакція',
    from: 'З рахунку',
    to: 'На рахунок',
    type: 'Тип',
    delta: 'Різниця',
  },
  settings: {
    title: 'Налаштування компанії',
    general: 'Загальні налаштування',
    shiftAutoCloseTitle: 'Закриття кассової зміни',
    shiftAutoCloseSubtitle: 'Ми автоматично закриємо активну кассову зміну в обранний час.',
    currentVersion: 'Поточна версія',
    newVersion: 'Нова версія',
    releaseDate: 'Дата виходу',
    newVersionAvailable: 'Нова версія доступна!',
    managePlace: 'Заклади',
    choosePlace: 'Оберіть заклад',
    currency: 'Валюта',
    keeper: 'Налаштування Терміналу',
    keeperDescription: 'Тут ви можете обрати які глобальні вкладки будуть показані в',
    keeperDescription2: 'Терміналі',
    keeperDescription3: '.',
    keeperTabs: 'Вкладки',
    other: 'Інші',
    showCategoryInCard: 'Показувати категорію в карточках товару',
    allowBonusPayment: 'Дозволити оплату бонусами',
    hideEmptyItem: 'Не показувати товари з 0 залишком',
    cashier: 'Кассир',
    cashierName: 'Ім\'я кассиру',
    cashierToken: 'Токен кассиру',
    cashierLoginTitle: 'Логін',
    cashierLoginPlaceholder: 'Логін',
    cashierPasswordTitle: 'Логін кассиру',
    cashierPasswordPlaceholder: 'Логін кассиру',
    cashKeyTitle: 'Ключ каси',
    cashKeyPlaceholder: 'Ключ каси',
    useTaxesWebhook: 'Подавати данні в ДФС',
  },
  access: {
    name: 'Ім\'я',
    employer: 'Співробітник',
    addNewEmployer: 'Додати нового співробітника',
    editEmployer: 'Редагування співробітника',
    passwordDescription: 'Лише 4 цифри',
  },
  job: {
    jobName: 'Назва посади',
    selectJob: 'Оберіть посаду',
    addNewJob: 'Додати посаду',
    editJob: 'Редагування посади',
    salaryCalculation: 'Розрахунок зарплати',
    fixedRate: 'Фіксована ставка',
    salaryPerShift: 'За зміну',
    salaryPerMonth: 'За місяць',
    flexRate: 'Відсоток від продаж',
    flexRatePersonal: 'Особистих продаж',
    flexRateTotal: 'Продаж за зміну',
    terminalPassword: 'Пароль до терміналу',
  },
  statistic: {
    middleBill: 'Середній чек',
    popularItem: 'Популярні товари',
    orders: 'Замовлення',
  },
  modalMenu: {
    title: 'Функції',
    getShiftReport: 'Звіт за зміну',
    getDayReport: 'Звіт за день',
    shiftReport: 'Звіт за кассову зміну',
    dayReport: 'Звіт за день',
    closeShift: 'Закрити зміну',
    openShift: 'Відкрити зміну',
    cashBalance: 'Залишок в касі',
    sumBalance: 'Сума в касі',
    openCashBalance: 'Сума в кассі при відкритті',
    closeCashBalance: 'Сумма в кассе при закритті',
    expectedCashBalance: 'Очікувана сума в касі',
    afterCollection: 'Залишок готівки в касі',
    afterCollection2: 'Має бути рівним сумі в касі при відкритті зміни',
    collectionHelp: 'Перед закриттям зміни зробіть інкасацію готівки і залиште в касі тільки розмінні гроші',
    openBy: 'Відкрив зміну',
    closeBy: 'Закриває зміну',
    closedBy: 'Закрив зміну',
    openTime: 'Час відкриття',
    closeTime: 'Час закриття',
    openReport: 'Відкрити звіт',
    employerSales: 'Продажі по співробітникам',
    bills: 'Чеки',
    manageBills: 'Управління чеками',
    changePayments: 'Змінити оплату',
    checkSync: 'Синхронізувати',
    syncProcess: 'Синзронізація...',
    syncProcessDescription: 'Ми синхронізуємо ваші данні!',
    syncSuccess: 'Синхронізовано!',
    syncSuccessDescription: 'Ваші данні синхронізовано.',
    syncFailed: 'Увага!',
    syncFailedDescription: 'Ви не можете синхронізувати данні поки пристрій офлайн.',
    cleanCache: 'Очистити кеш',
    cacheCleaned: 'Готово!',
    cacheCleanedDescription: 'Кеш очищено.',
    consumptionCount: 'Витрати',
  },
  landing: {
    installTitle: 'Встановіть Shoptoria POS!',
    installText: 'Ви можете встановити додаток Shoptoria POS на домашній екран!',
    installButton: 'Встановити',
    capability: 'Можливості',
    prices: 'Ціни',
    terms: 'Угода',
    joinTelegram: 'Ми на зв\'язку!',
    joinTelegramText1: 'Слідкуйте за новинами, задавайте питання, діліться фідбеком - все це у нашому',
    joinTelegramText2: 'каналі.',
    joinTelegramButton: 'Приєднатись',
    joinUs: 'Давайте працювати разом!',
    openPlayMarket: 'Тепер і в Play Market!',
    openPlayMarketDescription: 'вже готова для завантаження з Play Market!',
    openPlayMarketButton: 'Перейти',
    openApp: 'Відкрити додаток',
    readyToStart: 'Готові розпочати?',
    readyToStartDescription1: 'Відкрийте додаток',
    readyToStartDescription2: 'і почніть продавати вже зараз!',
    workTogetherText1: 'Стань частиною',
    workTogetherText2: 'вже зараз и отримай 2 тижні підписки',
    workTogetherText3: 'безкоштовно',
    company: 'Назва закладу',
    phone: 'Телефон',
    why: 'Чому бізнес любить Shoptoria',
    heroText: 'хмарна система обліку',
    heroContent1: 'Shoptoria POS - програма автоматизації та складського обліку закладів харчування і HoReCa.',
    heroContent2: 'Зручна і надійна онлайн-каса на планшет iPad і Android.',
    testimonialTitle1: 'Доступ з будь-якої точки світу',
    testimonialDescription1: 'Shoptoria — хмарна система обліку. Це означає, що статистика, склад і фінанси вашого бізнесу доступні вам з будь-якого місця, де є інтернет.',
    testimonialTitle2: 'Просте встановлення та навчання',
    testimonialDescription2: 'Ми фанати простих й зручних продуктів. Тому Shoptoria не вимагає додаткового навчання — все й так зрозуміло. Ваші працівники почнуть продавати вже через 5 хвилин.',
    testimonialTitle3: 'Доступна ціна',
    testimonialDescription3: 'Shoptoria працює за моделлю передплати, вам не потрібно заморожувати велику суму в автоматизації.',
    learnMore: 'Дізнатись більше',
    featureTitle1: 'Зручне робоче місце офіціанта',
    featureText1: 'Ваші офіціанти, бармени або бариста повинні спілкуватися з гостями, а не з програмним забезпеченням. Саме тому ми робимо POS-систему максимально простою, швидкою та зрозумілою.',
    feature1Slide1Title: 'Швидке опрацювання замовлень',
    feature1Slide1Text: 'Категоріям чи товарам можна призначити фото, щоб швидше працювати із замовленням.',
    feature1Slide2Title: 'Модифікатори страв',
    feature1Slide2Text: 'Стравам можна надати модифікатори: розмір, спеції або добавки.',
    feature1Slide3Title: 'Змішана оплата',
    feature1Slide3Text: 'Замовлення можна оплатити готівкою або карткою. Або всім одразу.',
    feature1Slide4Title: 'Обслуговуйте кілька клієнів одночасно',
    feature1Slide4Text: 'Створюйте, розділяйте та видаляйте стільки чеків, скільки потрібно.',
    featureTitle2: 'Керуйте закладом з дому',
    featureText2: 'У Shoptoria повнофункціональний бек-офіс у браузері. Це означає, що ви зможете контролювати роботу свого закладу з будь-якого місця та з будь-якого ноутбука або планшета.',
    feature2Slide1Title: 'Технологічні карти',
    feature2Slide1Text: 'Під час продажу страви зі складу списується автоматично інгредієнти за тех. карткою.',
    feature2Slide2Title: 'Швидка інвентаризація',
    feature2Slide2Text: 'Проводьте інвентаризацію складу та знаходьте помилки технології чи крадіжки.',
    feature2Slide3Title: 'Фінанси',
    feature2Slide3Text: 'Звіти з кожної транзакції, інкасації, закриттю чи відкриттю касових змін.',
    feature2Slide4Title: 'Детальна статистика',
    feature2Slide4Text: 'Весь ваш бізнес у вигляді зрозумілих показників, таблиць та графіків.',
    title: 'Переваги Shoptoria',
    advantages: {
      menu: {
        title: 'Меню',
        example: 'Приклад меню',
        consumable: 'Витратні матеріали',
        componentReplace: 'Заміна компонентів',
        modifier: 'Модифікатори для ваших продуктів',
        sizes: 'Розміри продукту',
      },
      store: {
        title: 'Складський облік',
        control: 'Контроль ціни поставок',
        onStore: 'Залишок на складі',
        supply: 'Нагадування про закупки',
        inventory: 'Зручна інвентаризація',
        cost: 'Автоматичне підрахування собівартості',
        waste: 'Контроль списань',
      },
      statistic: {
        title: 'Статистика',
        dashboard: 'Дешборд з основними показниками',
        salesPerDay: 'Продажі по дням тижня',
        salesPerGroup: 'Продажі по продуктам та категоріям',
        payments: 'Сегментування оплат',
        receipt: 'Продажі по чекам',
        cost: 'Собівартість продаж',
      },
      financial: {
        title: 'Фінанси',
        shifts: 'Кассові зміни',
        balance: 'Залишки на рахунках',
        cashFlow: 'Cash flow',
        payroll: 'Зарплатний проект',
        transactions: 'Транзакції',
      },
      secure: {
        title: 'Безпека',
        access: 'Пароль на «небезпечні» дії',
        settings: 'Налаштування доступу працівників',
      },
    },
    docs: {
      menu: {
        subtitle: 'Простий інтерфейс для управління вашим меню',
        hero: 'Додавайте тех. картки та модифікатори, враховуйте втрати під час обробки, об’єднуйте страви в категорії — в Shoptoria це займе у вас мінімум часу.',
        slide1Text1: 'Ми підготуємо декілька товарів, базуючись на типі Вашого закладу, так що Ви можете спробувати роботу в Терміналі без витрат часу на підготовку.',
        slide1Text2: 'Ми намагаємось зробити чим більше роботи за вас, щоб ваш початок роботи з Shoptoria був максимально легким!',
        slide2Text1: 'Керуйте розмірами ваших продуктів, контролюйте собівартість різних варіацій продуктів і обирайте різні витратні матеріали для кожного розміру.',
        slide2Text2: 'Зробіть ваші продукти на стільки гнучкими, на скільки забажаєте!',
        slide3Text1: 'Упорядкуйте меню та заробляйте більше з модифікаторами тех. карток. Пропонуйте гостям різні топінги для піци, ступінь просмаження стейка чи соуси до нього — з модифікаторами офіціант швидко прийме будь-яке таке замовлення.',
      },
      store: {
        subtitle: 'Гнучка та зручна робота зі складом',
        hero: 'Слідкуйте за залишками на складі в реальному часі, проводьте інвентаризацію та додавайте постачання в зручному інтерфейсі — ми продумали все до дрібниць.',
        slide1Text1: 'Контролюйте ваші поставки - вартість поставок, кількість товару, зміну собівартості продуктів.',
        slide1Text2: 'У разі додавання накладної до системи, ви будете бачити попередню ціну закупівлі і відхилення від норми.',
        slide2Text1: 'Shoptoria підтримує всі види складських операцій та звітів: постачання, інвентаризація, списання, переміщення тощо.',
        slide2Text2: 'Ви завжди будете знати, що відбувається зі складом Вашого закладу.',
        slide3Text1: 'З Shoptoria Ви полюбите робити інвентаризацію. Просто вкажіть фактичні залишки продуктів, страв та напівфабрикатів — все інше система зробить сама.',
        slide4Text1: 'Інформація про витрати з ваших складів завжди під рукою. Всі компоненти и витратні матеріали, які ви додали в рецепт продукту будуть підраховані в зручний звіт.',
        slide4Text2: 'Ви можете планувати ваші поставки и бути завжди готовими до нових клієнтів!',
      },
      financial: {
        subtitle: 'Простий та зрозумілий фінансовий облік',
        hero: 'В Shoptoria ви легко знайдете будь-яку цифру. Наприклад, чистий прибуток за колишній квартал чи скільки ви витратили на зарплату в березні минулого року.',
        slide1Text1: 'Враховуйте витрати на оренду, доставку води чи на прибирання помешкання. У вас буде повний звіт за всіма транзакціями.',
        slide1Text2: 'Сортуйте ваші транзакції по типам, контролюйте финансові потоки и будьте певні - Ви нічого не пропустите!',
        slide2Text1: 'Контролюйте готівку в касі. Якщо баланс за зміну не зійдеться, ви легко знайдете розбіжності.',
      },
      secure: {
        subtitle: 'Ваші данні у безпеці',
        hero: 'Бази даних зберігаються в дата-центрах рівня TIER 3 в Європі та США. Для з\'єднання використовується HTTPS.',
        slide1Text1: 'Встановіть пароль адміністратора на деякі дії на терміналі. Наприклад, на закриття замовлення без оплати чи на перегляд звіту за зміну.',
        slide1Text2: 'Єдиний, у кого є доступ до вашої бізнес інформації - це Ви.',
        slide2Text1: 'Кожен ваш співробітник має свій особистий пароль доступу до Терміналу.',
        slide2Text2: 'Все, що ваші співробітники роблять в Терміналі має ідентифікатор користувача, тому Ви завжди знатимете, хто відкрив касову зміну, закрив цей чек або додав транзакцію.'
      },
      statistic: {
        subtitle: 'Потрібна статистика завжди під рукою',
        hero: 'Всі дані продажів зберігаються в безпечному хмарному сховищі й доступні у браузері, в будь-який час та з будь-якого місця.',
        slide1Text1: 'Аналізуйте продажу за порою доби та дням тижня, прогнозуйте навантаження на співробітників залу й кухні.',
        slide1Text2: 'Всі ваші продажі згруповані у зручні таблиці, так що Ви можете швидко провести аналіз, прийняти рішення або знайти відповідь на цікавляче вас питання.',
        slide2Text1: 'Завжди тримайте руку на пульсі свого бізнесу — у відпустці, під час прогулянки або перед сном. Вся информація доступна у вашому смартфоні. Аналітика доступна в браузері, з будь-якої точки світу з доступом до інтернету.',
      },
    },
    contact: 'Контакти',
    sendMessage: 'Напишіть нам повідомлення або зв\'яжіться по телефону',
    hasQuestion: 'Лишились питання?',
    needHelp: 'Потрібна допомога?',
    socials: 'Соціальні мережі',
    navigation: 'Навігація',
    plan: {
      allInOne: 'Максимальні можливості в одному тарифі!',
      point: 'Кофеточка, фудтрак',
      coffee: 'Кав\'ярня',
      cafe: 'Кафе, Бар',
      restaurant: 'Ресторан',
      items: 'Кількість товарів: ∞',
      menu: 'Управління меню',
      statistic: 'Звіти та аналітика',
      statisticShort: 'Статистика за 40 днів',
      store: 'Складський облік',
      financial: 'Розрахунок зарплати',
      shifts: 'Кассові зміни',
      addons: 'Модифікатори продуктів',
      loyalty: 'Система лояльності',
      promotional: 'Акції',
      booking: 'Бронювання столиків',
      notify: 'Сповіщення про акції',
      try: 'Спробуйте <b>Shoptoria POS</b> вже зараз',
      subscribe: 'Shoptoria працює по моделі підписки.',
      dollar: 'доларів',
      allFromQuarantine: 'Все з <b>QUARANTINE</b>, плюс:',
      allFromLite: 'Все з <b>LITE</b>, плюс:',
      allFromBusiness: 'Все з <b>BUSINESS</b>, плюс:',
    },
    successSubmit: 'Запит успішно відправлений!',
    faq: {
      title: 'Потрібна допомога?',
      subtitle: 'Шукай своє питання нижче',
      generalBlock: 'Загальні',
      ...faq,
    },
    install: {
      link: 'Установка',
      title: 'Як установити Shoptoria POS?',
      subtitle: 'Shoptoria POS - це система, що базується на PWA, тому для установки вам необхідно лише додати shoptoria.net на ваш головний екран.',
      subtitle2: 'Просто дотримуйтесь інструкцій',
      step: 'Крок',
      step1: 'Додайте Shoptoria POS на головний екран.',
      step1_1: 'Для IOS натисніть кнопку "Поділитись".',
      step2: 'Почекайте заврешення установки, потім перейдіть на головний екран.',
      step3: 'Відкрийте Shoptoria POS як звичайний додаток.',
      finish: 'Установка завершена!',
      finish2: 'Shoptoria POS готова до використання',
    }
  },
  payments: {
    cantInteract: 'Ви не можете використовувати додаток, поки не буде оплачений ваш тарифний план!',
    payedFor: 'Тариф оплачено до',
    subscription: 'Підписка',
    payments: 'Платежі',
    tariff: 'Тариф',
    everyMonth: 'місяць',
    contactUsLink: 'Напишіть нам',
    contactUsText: 'для зміни тарифу',
    amount: 'Сума',
    status: 'Статус',
    Refunded: 'Повернено',
    Approved: 'Сплачено',
    Pending: 'В обробці',
    Declined: 'Відхилено',
    payForMonth: 'Оплатити 1 місяць',
    upgradeTariff: 'Підвищити до Lite'
  },
  permission: {
    upgradeTariff: 'Моя підписка',
    notGrantedTitle: 'Не доступно в Вашому тарифному плані!',
    notGrantedDescription: 'Будь-ласка, оберіть іншу підписку, щоб користуватись цією функцією.',
  },
  sales: {
    quarantine: {
      teaser: 'Підтримка рестораторів під час пандемії коронавіруса. Докладніше >>',
      banner: {
        title: 'в 2020 грошей не беремо!',
        subtitle: 'Новий тариф "Quarantine - 2020"',
        link: 'Гарантуємо легкий та швидкий вихід з карантину!'
      },
      help: {
        title: 'поспішає на допомогу!',
        content1: 'спрощує ведення ресторанного бізнесу.',
        content2: 'Мобільна POS-система вирішує всі питання: онлайн-каса, управління меню та цінами, фінанси, аналітика.',
        content3: 'Працює в хмарному сховищі, встановлюється за 10 хвилин.',
        content4: 'Всього за <b>0,00$</b> в місяць, замість 15$ до Вересня.',
        content5: 'Всі функції доступні в <b>мінімальному</b> тарифному плані.',
      },
      tariff: {
        title: 'В 2020 грошей не беремо!',
        content1: 'Новий тариф “<b>Quarantine</b>” - <b>0,00$</b> до <b>31.08.2020</b>.',
        content2: 'Спеціальна пропозиція для бізнесу <b>HoReCa</b> для швидкого і легкого виходу з карантину.',
        content3: 'Використовуйте всі переваги мобільної POS-системы <b>безкоштовно</b> до Вересня:',
        testimonial1: 'ведіть <b>цикл продаж</b>',
        testimonial2: 'переглядайте <b>звітність</b> за день або <b>касову зміну</b>',
        testimonial3: 'контролюйте <b>фінанси</b>',
        testimonial4: 'додавайте <b>модифікатори продуктів</b>',
        testimonial5: 'аналізуйте <b>продажи та ходовіе товари</b>',
        new: 'Бізнес звіти тепер і в Telegram!',
        end: '* до 31.08.2020',
      },
      testimonials: {
        title1: 'Управління меню',
        text1: 'Змінюйте ціни і склад продуктів',
        title2: 'Модифікатори продуктів',
        text2: 'Заробляйте більше з модифікаторами',
        title3: 'Аналітика прибутку',
        text3: 'Бізнес у вигляді зрозумілих показників, таблиць і графіків',
        title4: 'Касові зміни',
        text4: 'Ваш фінансовий звіт кожного дня',
      },
      joinUs: 'Станьте частиною <b>Shoptoria POS</b> вже зараз і використовуйте <b>безкоштовно</b> до Вересня',
    }
  },
  ...tour,
};
