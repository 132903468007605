import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './style.scss';
import './media.scss';

class Hero extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <div id="hero" className="landing-hero">
        <div className="landing-hero-main-wrapper">
          <div className="landing-hero-main">
            <h1>Shoptoria POS</h1>
            <h2>{t('landing.heroText')}</h2>
          </div>
        </div>
        <div className="landing-hero-content">
          <p>{t('landing.heroContent1')}</p>
          <p>{t('landing.heroContent2')}</p>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Hero);
