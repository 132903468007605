import React, { Component } from 'react';
import {
  CreditCardFilled, DollarOutlined, LeftOutlined, WalletOutlined,
} from '@ant-design/icons';
import { Button, Checkbox, Typography } from 'antd';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Column, Row } from '../index';
import { calculateTotal, cashPrediction, castToNormalFloat } from '../../utils/helpers';

const { Text } = Typography;

const calculateExcludedItemsPrice = (items = [], excludedItemsList = [], excludeCategoriesList = []) => items
  .reduce((price, item) => {
    if (excludedItemsList.includes(item.id) || excludeCategoriesList.includes(item.category)) {
      return castToNormalFloat(price + (item.weightItem
        ? parseFloat(item.price)
        : parseFloat(item.quantity * item.price)));
    }
    return price;
  }, 0);

class Pay extends Component {
  constructor(props) {
    super(props);
    const { bill } = props;
    const total = calculateTotal(bill).replace(',', '.');
    this.state = {
      bill: { ...bill, total },
      payMethod: 'cash',
      cash: '',
      card: '',
      bonus: '',
      prediction: cashPrediction(total),
      passToCheckbox: true,
    };
  }

  handleZero = () => this.setState((state) => {
    const afterDot = state[state.payMethod].split('.')[1];
    return ({
      [state.payMethod]: afterDot && afterDot.length === 2
        ? state[state.payMethod]
        : state[state.payMethod] === ''
          ? ''
          : state[state.payMethod] + 0,
    });
  });

  buttonPress = value => this.setState((state) => {
    const afterDot = state[state.payMethod].split('.')[1];
    return ({
      [state.payMethod]: afterDot && afterDot.length === 2 ? state[state.payMethod] : state[state.payMethod] + value,
    });
  });

  removeLastSymbol = () => this.setState(state => ({
    [state.payMethod]: `${state[state.payMethod]}`.slice(0, -1),
  }));

  pay = () => {
    const { callback = () => {} } = this.props;
    callback(this.state);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.bill && this.props.bill.id !== nextProps.bill.id) {
      this.setState({
        payMethod: 'cash',
        cash: '',
        card: '',
        bonus: '',
        passToCheckbox: true,
      });
    }
  }

  render() {
    const {
      t, business, keeper, loyalty, categories,
    } = this.props;
    const {
      bill, payMethod, card, cash, prediction, bonus, passToCheckbox,
    } = this.state;
    const payDiff = parseInt(bill.total * 100, 10)
      - parseInt(card * 100, 10)
      - parseInt(cash * 100, 10)
      - parseInt(bonus * 100, 10);

    // Decrease bill total amount with price of excluded items
    const excludeAmount = calculateExcludedItemsPrice(bill.items, loyalty.excludedItems, loyalty.excludedCategories
      .map(id => (categories.find(category => category.id === id) || { name: '' }).name));

    const maxBonusAmount = castToNormalFloat(((bill.total - excludeAmount < 0
      ? 0
      : bill.total - excludeAmount)) / 100 * (loyalty.maxBonusPayment || 0));

    const isBonusCountAvailable = bill.client
      ? (parseInt(bill.client.bonusCount * 100, 10) - parseInt(bonus * 100, 10) >= 0)
      && (parseInt(bonus * 100, 10) <= maxBonusAmount * 100)
      : true;

    return (
      <Row width="100%" alignItems="space-between" height="100%" style={{ background: 'white' }}>
        <Column
          height="100%"
          width="45%"
          className="pay-calculator"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Row width="100%" height="18%" style={{ padding: '20px 20px 0 20px' }}>
            <button
              type="button"
              className="pay-calculator-button top"
              onClick={() => this.setState(state => ({ [state.payMethod]: `${prediction.low}` }))}
            >{prediction.low}
            </button>
            <button
              type="button"
              className="pay-calculator-button top"
              onClick={() => this.setState(state => ({ [state.payMethod]: `${prediction.high}` }))}
            >{prediction.high}
            </button>
          </Row>
          <Column alignItems="flex-start" width="100%" height="80%" style={{ padding: '20px 20px 0 20px' }}>
            <Row width="100%" justifyContent="space-between" height="21%" margin="0 0 8px">
              <button
                type="button"
                className="pay-calculator-button"
                onClick={() => this.buttonPress(7)}
              >7
              </button>
              <button
                type="button"
                className="pay-calculator-button"
                onClick={() => this.buttonPress(8)}
              >8
              </button>
              <button
                type="button"
                className="pay-calculator-button"
                onClick={() => this.buttonPress(9)}
              >9
              </button>
            </Row>
            <Row width="100%" justifyContent="space-between" height="21%" margin="0 0 8px">
              <button
                type="button"
                className="pay-calculator-button"
                onClick={() => this.buttonPress(4)}
              >4
              </button>
              <button
                type="button"
                className="pay-calculator-button"
                onClick={() => this.buttonPress(5)}
              >5
              </button>
              <button
                type="button"
                className="pay-calculator-button"
                onClick={() => this.buttonPress(6)}
              >6
              </button>
            </Row>
            <Row width="100%" justifyContent="space-between" height="21%" margin="0 0 8px">
              <button
                type="button"
                className="pay-calculator-button"
                onClick={() => this.buttonPress(1)}
              >1
              </button>
              <button
                type="button"
                className="pay-calculator-button"
                onClick={() => this.buttonPress(2)}
              >2
              </button>
              <button
                type="button"
                className="pay-calculator-button"
                onClick={() => this.buttonPress(3)}
              >3
              </button>
            </Row>
            <Row width="100%" justifyContent="space-between" height="21%" margin="0 0 8px">
              <button
                type="button"
                className="pay-calculator-button"
                onClick={() => {
                  if (this.state[payMethod].split('.').length < 2) {
                    this.buttonPress('.');
                  }
                }}
              >.
              </button>
              <button
                type="button"
                className="pay-calculator-button"
                onClick={this.handleZero}
              >0
              </button>
              <button type="button" className="pay-calculator-button" onClick={this.removeLastSymbol}>
                <LeftOutlined style={{ fontSize: '28px' }} />
              </button>
            </Row>
            <button
              type="button"
              className="pay-calculator-button full"
              onClick={() => this.setState(state => ({ [state.payMethod]: bill.total }))}
            >
              {bill.total}
            </button>
          </Column>
        </Column>
        <Column width="50%" margin="0 2.5% 0 0" height="100%" alignItems="flex-start" justifyContent="flex-start">
          <Row width="100%" justifyContent="space-between" className="pay-title" margin="10px 0 25px">
            <h3>{t('global.total')}</h3>
            <h3>{bill.total} {business.currency}</h3>
          </Row>
          <div className="pay-table">
            <div className="pay-table-item" onClick={() => this.setState({ payMethod: 'cash' })}>
              <div className={`pay-table-item-field ${payMethod === 'cash' ? 'focused' : ''}`}>
                <DollarOutlined style={{ fontSize: '18px', marginRight: '10px' }} />
                <Text style={{ fontSize: '18px' }}>{t('pay.cash')}</Text>
                <span
                  className={`pay-table-item-field-number 
                    ${cash > 0 && 'hasValue'} ${payMethod === 'cash' && 'focused'}`}
                >
                  {cash !== '' ? cash : 0}
                </span>
                <span
                  className={`pay-table-item-field-symbol 
                    ${cash > 0 && 'hasValue'} ${payMethod === 'cash' && 'focused'}`}
                >
                  {business.currency}
                </span>
              </div>
            </div>
            <div className="pay-table-item" onClick={() => this.setState({ payMethod: 'card' })}>
              <div className={`pay-table-item-field ${payMethod === 'card' ? 'focused' : ''}`}>
                <CreditCardFilled style={{ fontSize: '18px', marginRight: '10px' }} />
                <Text style={{ fontSize: '18px' }}>{t('pay.card')}</Text>
                <span
                  className={`pay-table-item-field-number 
                    ${card > 0 && 'hasValue'} ${payMethod === 'card' && 'focused'}`}
                >
                  {card !== '' ? card : 0}
                </span>
                <span className={`pay-table-item-field-symbol
                  ${card > 0 && 'hasValue'} ${payMethod === 'card' && 'focused'}`}
                >
                  {business.currency}
                </span>
              </div>
            </div>
            {keeper.allowBonusPayment && bill.client && (
              <div className="pay-table-item" onClick={() => this.setState({ payMethod: 'bonus' })}>
                <div
                  className={
                    `pay-table-item-field 
                    ${payMethod === 'bonus' ? 'focused' : ''} 
                    ${!isBonusCountAvailable ? 'error' : ''}`
                  }
                >
                  <WalletOutlined style={{ fontSize: '18px', marginRight: '10px' }} />
                  <Text style={{ fontSize: '18px' }}>{t('pay.bonus')}</Text>
                  <span
                    className={`pay-table-item-field-number 
                    ${bonus > 0 && 'hasValue'} ${payMethod === 'bonus' && 'focused'}`}
                  >
                    {bonus !== '' ? bonus : 0}
                  </span>
                  <span className={`pay-table-item-field-symbol
                  ${bonus > 0 && 'hasValue'} ${payMethod === 'bonus' && 'focused'}`}
                  >
                    {business.currency}
                  </span>
                </div>
              </div>
            )}
          </div>
          {payDiff < 0 && (
            <Row width="100%" margin="20px 0 0" justifyContent="space-between">
              <Text style={{ fontSize: '20px' }}>{t('pay.change')}</Text>
              <Text style={{ fontSize: '20px' }}>{(payDiff / -100).toFixed(2)} {business.currency}</Text>
            </Row>
          )}
          {bill.client && (
            <Row width="100%" margin="20px 0 0" justifyContent="space-between">
              <Text style={{ fontSize: '20px' }}>{t('global.client')}</Text>
              <Text style={{ fontSize: '20px' }}>{bill.client.name}</Text>
            </Row>
          )}
          {bill.client && (
            <Row width="100%" margin="0" justifyContent="space-between">
              <Text style={{ fontSize: '20px' }}>{t('pay.bonusAmount')}</Text>
              <Text style={{ fontSize: '20px' }}>{bill.client.bonusCount} {business.currency}</Text>
            </Row>
          )}
          {bill.client && (
            <Row width="100%" margin="20px 0 0" justifyContent="space-between">
              <Text style={{ fontSize: '20px' }}>{t('pay.maxBonusAmount')}</Text>
              <Text style={{ fontSize: '20px', whiteSpace: 'nowrap' }}>{maxBonusAmount} {business.currency}</Text>
            </Row>
          )}
          {business.checkbox.useTaxesWebhook && (
            <Checkbox
              className="checkbox-large"
              style={{ margin: '20px 0' }}
              onClick={() => this.setState({ passToCheckbox: !passToCheckbox })}
              checked={passToCheckbox}
            >{t('settings.useTaxesWebhook')}
            </Checkbox>
          )}

          <Row width="100%" margin="auto 0 20px" justifyContent="flex-end">
            <Button
              size="large"
              style={{ width: '150px', height: '50px' }}
              onClick={this.pay}
              disabled={isNaN(payDiff) || payDiff > 0 || !isBonusCountAvailable}
              type="primary"
            >{t('control.pay')}
            </Button>
          </Row>
        </Column>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    business: state.business,
    keeper: state.keeper,
    loyalty: state.marketing.loyalty,
    categories: state.menu.categories,
  };
}

export default connect(mapStateToProps, null)(withTranslation()(Pay));
