import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Table, Tag } from 'antd';
import { firebase } from '../../../config';
import {
  Content, Page, PageHeader, TopBar,
} from '../../../components';
import { RangePicker } from '../../../common';
import { getUrlByRecord } from '../Storage';
import { getBillsFromDayRange } from '../../../redux/statistic/helpers';
import { calculateStoreConsumptionFromBills, getUnits, printPage } from '../../../utils/helpers';
import { isOnline } from '../../../persistance';
import { showNetworkError } from '../../../redux/message/actions';

class Consumption extends Component {
  constructor(props) {
    super(props);
    const { resolvedBills = [] } = this.props;
    this.state = {
      consumption: this.getConsumptionFromBills(resolvedBills),
      pending: false,
    };
  }

  updateRange = ({ rangeStart, rangeEnd }) => {
    if (!isOnline()) return showNetworkError();
    this.setState({ pending: true });
    getBillsFromDayRange(rangeStart, rangeEnd)
      .then((bills = []) => this.setState({ consumption: this.getConsumptionFromBills(bills), pending: false }));
  };

  getConsumptionFromBills = (bills) => {
    const { menu } = this.props;
    const consumption = calculateStoreConsumptionFromBills(bills, menu);
    const consumptionSet = {};
    consumption.forEach((el) => {
      if (consumptionSet[el.item]) {
        consumptionSet[el.item] = parseFloat(consumptionSet[el.item]) + parseFloat(el.count);
      } else {
        consumptionSet[el.item] = parseFloat(el.count);
      }
    });
    return Object.keys(consumptionSet).map(key => ({ item: key, count: consumptionSet[key] }));
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.resolvedBills.length !== nextProps.resolvedBills.length) {
      this.setState({ consumption: this.getConsumptionFromBills(nextProps.resolvedBills), pending: false });
    }
  }

  render() {
    const {
      t, currency, menu, equipment,
    } = this.props;
    const { consumption, pending } = this.state;
    const consumptionColumns = [
      {
        title: t('global.name'),
        dataIndex: 'name',
        width: 'calc(100% / 4)',
        sorter: (a, b) => (-1) * a.name.localeCompare(b.name),
        sortDirections: ['descend'],
        defaultSortOrder: 'descend',
        render: (name, record) => getUrlByRecord(record),
      },
      {
        title: t('global.type'),
        dataIndex: 'type',
        width: 'calc(100% / 4)',
        render: text => <Tag>{t(`global.${text}`)}</Tag>,
        sorter: (a, b) => (-1) * a.type.localeCompare(b.type),
        sortDirections: ['descend'],
      }, {
        title: t('cost.title'),
        dataIndex: 'cost',
        width: 'calc(100% / 4)',
        render: (text, record) => (record.cost ? `${record.cost} ${currency}` : '-'),
        sorter: (a, b) => {
          const delta = a.count - b.count;
          if (delta > 0) return -1;
          if (delta < 0) return 1;
          return 0;
        },
        sortDirections: ['descend'],
      }, {
        title: t('global.quantity'),
        dataIndex: 'count',
        width: 'calc(100% / 4)',
        sorter: (a, b) => {
          const delta = a.count - b.count;
          if (delta > 0) return -1;
          if (delta < 0) return 1;
          return 0;
        },
        render: (text, record) => `${text} ${record.units ? getUnits(record.units) : t('count')}`,
        sortDirections: ['descend'],
      },
    ];
    return (
      <Page spinning={!firebase.auth().currentUser || pending}>
        <PageHeader />
        <Content>
          <TopBar data-tour="consumption-tour-2">
            <h3 className="storage-page-title">{t('storage.consumption')}</h3>
            <Button
              className="no-print mobile-bottom-margin mobile-right-margin"
              size="large"
              onClick={() => printPage()}
              type="secondary"
            >{t('control.print')}
            </Button>
            <RangePicker callback={this.updateRange} />
          </TopBar>
          <Table
            bordered
            pagination={false}
            size="default"
            className="storage-page-table"
            columns={consumptionColumns}
            dataSource={consumption.map(el => ({
              ...el,
              ...(equipment.idMap[el.item] ? equipment.idMap[el.item] : menu.idMap[el.item]),
              key: el.item,
            })).filter(el => el.name && el.type !== 'product')}
          />
        </Content>
      </Page>
    );
  }
}

export default withTranslation()(Consumption);
