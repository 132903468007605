import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MenuSearchButton from './Buttons/MenuSearchButton';
import OpenClientsModalButton from './Buttons/OpenClientsModalButton';
import OpenPromotionsModalButton from './Buttons/OpenPromotionsModalButton';
import { AddToBill, MenuTabs } from '../../components';
import { addToActiveBill } from '../../redux/statistic/actions';
import { WeightCount } from '../../common';

class KeeperContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
      weightItem: null,
    };
  }

  selectItem = (item) => {
    if (item && item.type !== 'product') {
      if (item.weightItem) {
        this.setState({ weightItem: item });
        return;
      }
      const { actions: { addToActiveBill } } = this.props;
      const data = {
        name: item.name,
        id: item.id,
        quantity: 1,
        displayName: item.name,
        type: item.type,
        price: item.price,
        category: item.category,
      };
      if (item.type === 'addon') {
        const { componentId, count } = item;
        data.componentId = componentId;
        data.count = count;
      }
      addToActiveBill(data);
      return;
    }
    this.setState({ selectedItem: item });
  };

  render() {
    const { selectedItem, weightItem } = this.state;

    return (
      <>
        <div className="keeper-content">
          <div className="keeper-actions">
            <MenuSearchButton selectItem={this.selectItem} />

            <OpenClientsModalButton />

            <OpenPromotionsModalButton />

          </div>

          <MenuTabs
            withFavourites
            withAddons
            withRestriction
            hideEmptyItems
            excludeCategories
            listCallback={this.selectItem}
          />
        </div>

        {selectedItem !== null && (
          <AddToBill item={selectedItem} close={() => this.selectItem(null)} />
        )}

        {weightItem !== null && (
          <WeightCount
            close={() => this.setState({ weightItem: null })}
            weightItem={weightItem}
          />
        )}
      </>
    );
  }
}


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        addToActiveBill,
      },
      dispatch,
    ),
  };
}

export default connect(null, mapDispatchToProps)(KeeperContent);
