import moment from 'moment';
import { generateBillUUID, generateId, getYesterdayKeyTimestamp } from '../../utils/helpers';
import { getPlaceRef } from '../business/actions';
import {
  getPersistedBillsData, isOnline, persistBill, persistWithKey,
} from '../../persistance';
import { addBillRequest, editBillRequest, getResolvedBillsRequest } from '../../api/db/statistic';
import { showErrorMessage } from '../message/actions';
import { idbUpdates } from '../../config';
import { GET_STORE } from '../storage/actions';
import { increaseBonusAndSaleCount } from '../marketing/actions';

export const GET_RESOLVED_BILLS = 'GET_RESOLVED_BILLS';
export const ADD_BILL = 'ADD_BILL';
export const REMOVE_BILL = 'REMOVE_BILL';
export const EDIT_BILL = 'EDIT_BILL';
export const EDIT_BILL_PAYMENT = 'EDIT_BILL_PAYMENT';
export const ADD_TO_BILL = 'ADD_TO_BILL';
export const REMOVE_FROM_BILL = 'REMOVE_FROM_BILL';
export const CLEAR_BILL = 'CLEAR_BILL';
export const CALCULATE_BILL = 'CALCULATE_BILL';
export const ADD_SALE_TO_BILL = 'ADD_SALE_TO_BILL';
export const ADD_CLIENT_TO_BILL = 'ADD_CLIENT_TO_BILL';
export const REMOVE_CLIENT_FROM_BILL = 'REMOVE_CLIENT_FROM_BILL';
export const ADD_PROMOTION = 'ADD_PROMOTION';
export const REMOVE_PROMOTION = 'REMOVE_PROMOTION';
export const CALCULATE_PROMOTION = 'CALCULATE_PROMOTION';
export const ADD_COMMENT = 'ADD_COMMENT';
export const SET_ACTIVE_KEY = 'SET_ACTIVE_KEY';
export const CLEAN_ACTIVE_BILLS = 'CLEAN_ACTIVE_BILLS';

const calculatePromotion = (sale, items) => {
  const itemInSale = items
    .reduce((res, item) => (sale.selectedItems.includes(item.id)
      ? {
        count: res.count + (item.weightItem ? 1 : item.quantity),
        price: res.price + parseFloat(item.price),
      }
      : res),
    { count: 0, price: 0 });
  const categoryInSale = items.filter(el => !sale.selectedItems.includes(el.id))
    .reduce((res, item) => (sale.selectedCategories.includes(item.category)
      ? {
        count: res.count + (item.weightItem ? 1 : item.quantity),
        price: res.price + parseFloat(item.price),
      }
      : res),
    { count: 0, price: 0 });
  return {
    count: sale.saleType === 'salePercent'
      ? ((itemInSale.price + categoryInSale.price) / 100 * sale[sale.saleType]).toFixed(2)
      : (itemInSale.count + categoryInSale.count) * sale[sale.saleType],
    type: 'amount',
  };
};


export function calculatePromotionSale(data = null) {
  return (dispatch, getState) => {
    const { menu: { categories }, statistic: { activeKey, activeBills }, marketing: { sales } } = getState();
    const bill = activeBills.find(el => el.id === activeKey);
    if (!bill.sale.promotion && !data) return;
    const promotion = sales.find(el => el.id === bill.sale.promotion?.id);
    const items = bill.items.map(el => ({
      ...el, category: el.category ? categories.find(category => category.name === el.category).id : null,
    }));
    const { count, type } = calculatePromotion(promotion || data, items);
    dispatch({ type: CALCULATE_PROMOTION, payload: { count, type } });
  };
}

export function getResolvedBills(persisted = false) {
  return (dispatch) => {
    if (persisted || !isOnline()) {
      const startOfDay = getYesterdayKeyTimestamp();
      return getPersistedBillsData()
        .then(data => dispatch({
          type: GET_RESOLVED_BILLS,
          payload: data.filter(el => parseInt(el.id, 10) > startOfDay)
            .map(el => ({
              ...el,
              id: parseInt(el.id, 10),
            })),
        }));
    }
    return getResolvedBillsRequest()
      .then((res) => {
        res.data.map(el => persistBill(el));
        return dispatch({ type: GET_RESOLVED_BILLS, payload: res.data });
      })
      .catch(showErrorMessage);
  };
}

export function createBill(callback = () => {}, sale = { percent: 0, amount: 0, promotion: null }, comment = '') {
  return (dispatch) => {
    const id = generateId();
    dispatch({
      type: ADD_BILL,
      payload: {
        id, items: [], sale, comment, client: null, uuid: generateBillUUID(),
      },
    });
    callback(id);
  };
}

export function setActiveKey(key) {
  return {
    type: SET_ACTIVE_KEY,
    payload: parseInt(key, 10),
  };
}

export function cleanActiveBills() {
  return {
    type: CLEAN_ACTIVE_BILLS,
  };
}

export function removeBill(id) {
  return {
    type: REMOVE_BILL,
    payload: id,
  };
}

function addAddonsHelper(id, item) {
  return (dispatch, getState) => {
    if (item.addons) {
      const { idMap } = getState().menu;
      item.addons.forEach(addon => dispatch({
        type: ADD_TO_BILL,
        payload: {
          id,
          item: {
            ...addon, quantity: item.quantity, displayName: addon.name || idMap[addon.componentId].name,
          },
        },
      }));
    }
  };
}

export function addToBill(id, item) {
  return (dispatch) => {
    if (id === null) {
      dispatch(createBill((newId) => {
        dispatch({
          type: ADD_TO_BILL,
          payload: { id: newId, item },
        });
        dispatch(addAddonsHelper(newId, item));
      }));
    } else {
      dispatch({
        type: ADD_TO_BILL,
        payload: { id, item },
      });
      dispatch(addAddonsHelper(id, item));
    }
    dispatch(calculatePromotionSale());
  };
}

export function addToActiveBill(item) {
  return (dispatch, getState) => {
    const { activeKey } = getState().statistic;

    if (activeKey === null) {
      dispatch(createBill((newId) => {
        dispatch({
          type: ADD_TO_BILL,
          payload: { id: newId, item },
        });
        dispatch(addAddonsHelper(newId, item));
      }));
    } else {
      dispatch({
        type: ADD_TO_BILL,
        payload: { id: activeKey, item },
      });
      dispatch(addAddonsHelper(activeKey, item));
    }
    dispatch(calculatePromotionSale());
  };
}

export function removeFromBill(id, item, size) {
  return (dispatch) => {
    dispatch({ type: REMOVE_FROM_BILL, payload: { id, item, size } });

    dispatch(calculatePromotionSale());
  };
}

export function clearBill(id) {
  return {
    type: CLEAR_BILL,
    payload: id,
  };
}

export function addSaleToBill(id, percent, amount) {
  return {
    type: ADD_SALE_TO_BILL,
    payload: { id, sale: { percent, amount } },
  };
}

export function removePromotionFromBill(id) {
  return {
    type: REMOVE_PROMOTION,
    payload: id,
  };
}

export function addPromotionToBill(id, promotion) {
  return async (dispatch) => {
    const {
      saleType, saleAmount, salePercent, name,
    } = promotion;
    await dispatch({
      type: ADD_PROMOTION,
      payload: {
        id,
        promotion: {
          id: promotion.id, saleType, saleAmount, salePercent, name,
        },
      },
    });
    dispatch(calculatePromotionSale(promotion));
  };
}

export function addClientToBill(client) {
  return async (dispatch, getState) => {
    const { marketing: { groups }, statistic: { activeKey } } = getState();
    dispatch({ type: ADD_CLIENT_TO_BILL, payload: client });
    const clientGroup = groups.find(group => group.id === client.group);
    // Validate if client has group and it's group exist
    if (!client.group || !clientGroup) return;
    if (clientGroup.type === 'sale') {
      return dispatch({
        type: ADD_SALE_TO_BILL,
        payload: {
          id: activeKey, sale: { percent: clientGroup.count, amount: 0 },
        },
      });
    }
    dispatch({ type: ADD_SALE_TO_BILL, payload: { id: activeKey, sale: { percent: 0, amount: 0 } } });
  };
}

export function removeClientFromBill(id) {
  return {
    type: REMOVE_CLIENT_FROM_BILL,
    payload: id,
  };
}

export function checkIsBillOpen() {
  return (dispatch, getState) => {
    const { activeKey } = getState().statistic;
    return !!activeKey;
  };
}

export function addCommentToBill(id, comment) {
  return {
    type: ADD_COMMENT,
    payload: { id, comment },
  };
}

export function separateBill({ oldBill, newBill, sale }) {
  return (dispatch, getState) => {
    const { activeKey, activeBills } = getState().statistic;
    const billToSeparate = activeBills.find(bill => bill.id === activeKey);
    dispatch(removeBill(activeKey));
    dispatch(createBill((id => oldBill.forEach(item => dispatch(addToBill(id, item)))), sale, billToSeparate.comment));
    dispatch(calculatePromotionSale());
    setTimeout(
      () => dispatch(createBill((id => newBill.forEach(item => dispatch(addToBill(id, item, sale)))), sale)), 1,
    );
  };
}

export function calculateBill(id, meta = {}) {
  return async (dispatch, getState) => {
    const { statistic: { activeBills } } = getState();
    const placeRef = await dispatch(getPlaceRef());
    const bill = { ...activeBills.find(bill => bill.id === id), ...meta, closeTime: Date.now() };

    persistBill({ ...bill, place: placeRef, sync: false });

    dispatch(increaseBonusAndSaleCount(bill));

    if (!isOnline()) {
      return dispatch({ type: CALCULATE_BILL, payload: { ...bill, id, meta } });
    }


    dispatch({ type: CALCULATE_BILL, payload: { ...bill, id, meta } });

    return addBillRequest(bill.id, moment(bill.id).format('DD_MM_YYYY'), bill)
      .then((res) => {
        persistBill({ ...res.data.bill, place: placeRef });

        const { updates } = getState().user;

        dispatch({ type: GET_STORE, payload: res.data.store });

        persistWithKey('updates', { ...updates, Bills: Date.now() }, idbUpdates);
      })
      .catch(showErrorMessage);
  };
}

export function changeBillPayments(bill) {
  return async (dispatch, getState) => {
    const placeRef = await dispatch(getPlaceRef());

    if (!isOnline()) {
      dispatch({ type: EDIT_BILL_PAYMENT, payload: { ...bill, modified: true, sync: false } });

      persistBill({
        ...bill, place: placeRef, modified: true, sync: false,
      });

      const { updates } = getState().user;

      return persistWithKey('updates', { ...updates, Bills: Date.now() }, idbUpdates);
    }

    return editBillRequest(bill.id, moment(bill.date).format('DD_MM_YYYY'), bill)
      .then((res) => {
        dispatch({ type: EDIT_BILL_PAYMENT, payload: res.data });

        persistBill({ ...bill, place: placeRef });
      })
      .catch(showErrorMessage);
  };
}
