import React, { Component } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Input, Modal } from 'antd';
import i18 from '../../utils/translation';
import { Column } from '../index';
import { notificationTypes } from '../../redux/message/actions';
import './style.scss';
import { Row } from '../Grid';

const publicRoute = ['/app/keeper', '/app/dashboard', '/app/', '/app/login', '/', '/terms'];

class CheckAdminAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      loading: false,
      showModal: false,
    };
  }

  updatePassword = password => this.setState({ password });

  submit = () => {
    const { actions: { getAdminAccess, showMessage } } = this.props;
    const { password } = this.state;
    this.setState({ loading: true });
    getAdminAccess(password).then((isAdmin) => {
      if (!isAdmin) {
        showMessage({ text: i18.t('admin.wrongPassword'), type: notificationTypes.ERROR });
      }
      this.setState({ loading: false, password: '' });
    });
  };

  show = () => this.setState({ showModal: true });

  cancel = () => {
    const { history } = this.props;
    this.setState({ password: '', showModal: false });
    history.replace('/app/dashboard');
  };

  render() {
    const {
      isAdmin, dataLoaded, history, actions: { removeAdminAccess, logout },
    } = this.props;
    const { loading, showModal } = this.state;
    return isAdmin ? (
      <div
        className="layout-menu-item check-admin"
        onClick={() => removeAdminAccess(() => {
          this.setState({ showModal: false });
          history.replace('/app/dashboard');
        })}
      >
        <CloseOutlined style={{ color: '#FFFFFF', fontSize: '16px' }} />
        <h4 className="layout-menu-item-link">{i18.t('admin.removeAccess')}</h4>
      </div>
    ) : (
      <Modal
        maskClosable={false}
        visible={(!isAdmin && dataLoaded && !publicRoute.includes(history.location.pathname)) || showModal}
        title={i18.t('admin.checkTitle')}
        onCancel={this.cancel}
        onOk={this.submit}
        okButtonProps={{ loading }}
        footer={(
          <Row alignItems="center" justifyContent="space-between">
            <Button onClick={() => logout()} type="danger">{i18.t('logOut')}</Button>
            <Button onClick={this.submit} loading={loading} type="primary">{i18.t('admin.checkPassword')}</Button>
          </Row>
        )}
      >
        <Column alignItems="flex-start" style={{ padding: '0 4px' }}>
          <Input
            onChange={e => this.updatePassword(e.target.value)}
            style={{ margin: '10px 0' }}
            onPressEnter={this.submit}
            placeholder={i18.t('admin.adminPassword')}
          />
        </Column>
      </Modal>
    );
  }
}

export default CheckAdminAccess;
