import { idbConsumables, idbEquipment } from '../../config';
import axios from '../index';
import { persist } from '../../persistance';

// Consumable flow

export const getConsumableRequest = () => new Promise(resolve => axios.get('/db/consumable')
  .then((res) => {
    res.data.map(el => persist(el, idbConsumables));
    resolve(res);
  }));

export const editConsumableRequest = (id, data) => axios.put('/db/consumable', { id, data });

export const addConsumableRequest = (id, data) => axios.post('/db/consumable', { id, data });

export const removeConsumableRequest = id => axios.delete(`/db/consumable/${id}`);

// Equipment flow

export const getEquipmentRequest = () => new Promise(resolve => axios.get('/db/equipment')
  .then((res) => {
    res.data.map(el => persist(el, idbEquipment));
    resolve(res);
  }));

export const editEquipmentRequest = (id, data) => axios.put('/db/equipment', { id, data });

export const addEquipmentRequest = (id, data) => axios.post('/db/equipment', { id, data });

export const removeEquipmentRequest = id => axios.delete(`/db/equipment/${id}`);
