import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Button, Drawer } from 'antd';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { Column } from '../components';
import { addPromotionToBill, removePromotionFromBill } from '../redux/statistic/actions';

const getAllowedPromotions = (sales, bill, categories) => {
  const billItems = bill.items.map(el => ({
    ...el, category: el.category ? categories.find(category => category.name === el.category).id : null,
  }));
  const HOUR_FORMAT = 'HH:mm';
  const DATE_FORMAT = 'DD.MM.YYYY';
  const now = moment();
  const dayCode = now.format('ddd');
  const filteredByTimeSales = sales.filter((sale) => {
    const saleStartDate = moment(sale.startDate, DATE_FORMAT);
    const saleEndDate = moment(sale.endDate, DATE_FORMAT);
    const saleStartTime = moment(sale.startTime, HOUR_FORMAT);
    const saleEndTime = moment(sale.endTime, HOUR_FORMAT);
    const inDates = now.isAfter(saleStartDate) && now.isBefore(saleEndDate);
    const inTime = now.isAfter(saleStartTime) && now.isBefore(saleEndTime);
    return sale.promotionDays.includes(dayCode.toLowerCase()) && inTime && inDates;
  });
  const withUsedItems = filteredByTimeSales.filter((sale) => {
    const hasNeededItem = billItems
      .reduce((res, item) => res || sale.selectedItems.includes(item.id), false);
    const hasNeededCategory = billItems
      .reduce((res, item) => res || sale.selectedCategories.includes(item.category), false);
    return hasNeededCategory || hasNeededItem;
  });
  return withUsedItems;
};

class SaleDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  submit = (promotion) => {
    const { actions: { addPromotionToBill }, activeKey, hide } = this.props;
    addPromotionToBill(activeKey, promotion);
    hide();
  }

  removePromotion = () => {
    const { actions: { removePromotionFromBill }, activeKey, hide } = this.props;
    removePromotionFromBill(activeKey);
    hide();
  }

  render() {
    const {
      t, sales, currency, hide, activeBills, activeKey, categories,
    } = this.props;
    const bill = activeBills.find(el => el.id === activeKey);
    const promotions = getAllowedPromotions(sales, bill, categories);

    return (
      <Drawer
        title={t('keeper.promotions')}
        placement="right"
        closable={false}
        onClose={hide}
        visible
        className="promotion-drawer"
      >
        <Column>
          {promotions.map(el => (
            <Column
              key={el.id}
              className={`promotion-drawer-card ${el.id === bill.sale.promotion?.id ? 'active' : ''}`}
              margin="12px 12px 0"
              width="calc(100% - 24px)"
            >
              <h3>{el.name}</h3>
              <p>{el.description}</p>
              <p>{el.startTime} - {el.endTime}</p>
              <p>{el.startDate} - {el.endDate}</p>
              <p>
                {el.saleType === 'salePercent'
                  ? t('marketing.sales.percent')
                  : t('marketing.sales.amount')
                  }: {el[el.saleType]}{el.saleType === 'salePercent' ? '%' : currency}
              </p>
              <Button
                style={{ margin: '10px 0' }}
                size="large"
                type="primary"
                onClick={() => (el.id === bill.sale.promotion?.id ? this.removePromotion() : this.submit(el))}
              >{el.id === bill.sale.promotion?.id ? t('control.cancel') : t('control.apply')}
              </Button>
            </Column>
          ))}
        </Column>
      </Drawer>
    );
  }
}

function mapStateToProps(state) {
  return {
    activeKey: state.statistic.activeKey,
    activeBills: state.statistic.activeBills,
    currency: state.business.currency,
    sales: state.marketing.sales,
    categories: state.menu.categories,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        addPromotionToBill,
        removePromotionFromBill,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SaleDrawer));
