import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Button, Modal } from 'antd';
import moment from 'moment';

import i18 from '../utils/translation';
import { validateNumberInput } from '../utils/helpers';
import { isOnline } from '../persistance';

import { notificationTypes, showMessage } from '../redux/message/actions';
import { openCashShift } from '../redux/shift/actions';

import { Column } from '../components';
import { ModalHOC } from './index';
import NumberInput from './NumberInput';

import { getMonthWithLocale } from '../constants/time';

class OpenShift extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
    };
  }

  open = () => {
    const {
      t, close, employer, callback, history, checkbox, actions: { showMessage, openCashShift },
    } = this.props;

    const { count } = this.state;

    if (count === '') {
      showMessage({ text: t('validation.emptySum'), type: notificationTypes.ERROR });
      return;
    }

    if (checkbox.useTaxesWebhook && !isOnline()) {
      showMessage({ text: t('validation.networkRequired'), type: notificationTypes.ERROR });
      return;
    }

    openCashShift({ openCount: count, employer, history });

    close();

    callback();
  };

  render() {
    const {
      t, close, visible, employer,
    } = this.props;
    return (
      <Modal
        maskClosable={false}
        visible={visible}
        onCancel={close}
        footer={null}
        width="600px"
        title={t('modalMenu.openShift')}
        className="open-shift-modal"
      >
        <Column style={{ maxWidth: '400px' }} width="100%" margin="0 auto" alignItems="flex-start">
          <h2 style={{ margin: '15px 0 10px', fontSize: '20px' }}>{t('modalMenu.sumBalance')}</h2>

          <NumberInput
            onKeyUp={validateNumberInput}
            placeholder="0"
            onPressEnter={this.open}
            type="tel"
            size="large"
            onChange={e => this.setState({ count: e.target.value })}
          />

          <h3 style={{ fontSize: 18, margin: '35px 0 0' }}>{t('modalMenu.openBy')}: <b>{employer.name}</b></h3>

          <h3 style={{ fontSize: 18, margin: '10px 0 15px' }}>{t('modalMenu.openTime')}:&nbsp;
            <b>{moment().format('HH:mm, DD')} {getMonthWithLocale(i18.language, moment().format('M'))}</b>
          </h3>

          <Button style={{ width: '100%', margin: '20px 0 23px' }} size="large" type="primary" onClick={this.open}>
            {t('control.open')}
          </Button>
        </Column>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    shift: state.shift,
    checkbox: state.business.checkbox,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        openCashShift,
      },
      dispatch,
    ),
  };
}

export default ModalHOC(withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OpenShift))));
