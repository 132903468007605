import React, { Component } from 'react';
import { Modal } from 'antd';
import { withTranslation } from 'react-i18next';
import Pay from './Pay';
import './style.scss';


class PayModal extends Component {
  constructor(props) {
    super(props);
    const { activeKey, bills } = props;
    const bill = bills.find(bill => bill.id === activeKey);
    this.state = { bill };
  }

  pay = ({
    cash, card, bonus, bill, passToCheckbox,
  }) => {
    const {
      close, employer, actions: { calculateBill }, activeKey,
    } = this.props;
    calculateBill(activeKey, {
      pay: {
        card: card === '' ? 0 : parseFloat(card).toFixed(2),
        cash: cash === '' ? 0 : parseFloat(cash).toFixed(2),
        bonus: bonus === '' ? 0 : parseFloat(bonus).toFixed(2),
        total: bill.total,
        change: parseFloat(cash || '0') + parseFloat(bonus || '0') + parseFloat(card || '0') - parseFloat(bill.total),
      },
      employer,
      skipCheckbox: !passToCheckbox,
    });
    close();
  };

  close = () => {
    const { close } = this.props;
    close();
  };

  render() {
    const { visible, t } = this.props;
    const { bill } = this.state;
    return (
      <Modal
        maskClosable={false}
        visible={visible}
        className="pay"
        footer={null}
        onCancel={this.close}
        title={t('control.pay')}
        width="100%"
        style={{ minWidth: '575px', maxWidth: '95vw', top: '10px' }}
      >
        <Pay callback={this.pay} bill={bill} />
      </Modal>
    );
  }
}

export default withTranslation()(PayModal);
