import { castToNormalFloat, generateId, generateIdString } from '../../utils/helpers';
import {
  addClientRequest, addGroupRequest, addSaleRequest, editClientRequest, editGroupRequest, editLoyaltyRequest,
  editSaleRequest, getClientsDataRequest, getGroupsDataRequest, getLoyaltyDataRequest, getSalesDataRequest,
  removeClientRequest, removeGroupRequest, removeSaleRequest,
} from '../../api/db/marketing';
import { showErrorMessage, showMessage } from '../message/actions';
import i18n from '../../utils/translation';
import {
  getPersistedMarketingClients, getPersistedMarketingGroups, getPersistedMarketingLoyalty, getPersistedMarketingSales,
  isOnline, persist, persistWithKey, removeDataFromPersist,
} from '../../persistance';
import {
  idbMarketingClients, idbMarketingGroups, idbMarketingLoyalty, idbMarketingSales,
} from '../../config';

export const GET_LOYALTY = 'GET_LOYALTY';
export const EDIT_LOYALTY = 'EDIT_LOYALTY';
export const GET_CLIENTS = 'GET_CLIENTS';
export const EDIT_CLIENT = 'EDIT_CLIENT';
export const ADD_CLIENT = 'ADD_CLIENT';
export const REMOVE_CLIENT = 'REMOVE_CLIENT';
export const GET_GROUPS = 'GET_GROUPS';
export const EDIT_GROUP = 'EDIT_GROUP';
export const ADD_GROUP = 'ADD_GROUP';
export const REMOVE_GROUP = 'REMOVE_GROUP';
export const GET_SALES = 'GET_SALES';
export const EDIT_SALE = 'EDIT_SALE';
export const ADD_SALE = 'ADD_SALE';
export const REMOVE_SALE = 'REMOVE_SALE';

const getMaxAvailableRange = (profitCount, ranges) => ranges.reduce((max, range) => {
  if (profitCount >= range.amount && max.amount < range.amount) {
    return range;
  }
  return max;
}, { amount: -1 });

export function getLoyalty(persisted = false) {
  return (dispatch) => {
    if (persisted || !isOnline()) {
      return getPersistedMarketingLoyalty()
        .then(data => dispatch({ type: GET_LOYALTY, payload: data }));
    }
    return getLoyaltyDataRequest()
      .then(res => dispatch({ type: GET_LOYALTY, payload: res.data }))
      .catch(showErrorMessage);
  };
}

export function editLoyalty(data) {
  return dispatch => editLoyaltyRequest(data)
    .then((res) => {
      dispatch({ type: EDIT_LOYALTY, payload: res.data });

      persistWithKey('marketing-loyalty', res.data, idbMarketingLoyalty);

      dispatch(showMessage({ text: i18n.t('actions.marketingLoyaltyEditedTitle') }));
    })
    .catch(showErrorMessage);
}

export function getClients(persisted = false) {
  return (dispatch) => {
    if (persisted || !isOnline()) {
      return getPersistedMarketingClients()
        .then(data => dispatch({ type: GET_CLIENTS, payload: data }));
    }
    return getClientsDataRequest()
      .then(res => dispatch({ type: GET_CLIENTS, payload: res.data }))
      .catch(showErrorMessage);
  };
}
export function editClient(client) {
  return dispatch => editClientRequest(client.id, client)
    .then((res) => {
      dispatch({ type: EDIT_CLIENT, payload: res.data });

      persist({ ...res.data, id: parseInt(client.id, 10) }, idbMarketingClients);

      dispatch(showMessage({ text: i18n.t('actions.marketingClientEditedTitle') }));
    })
    .catch(showErrorMessage);
}

export function increaseBonusAndSaleCount(bill) {
  return (dispatch, getState) => {
    const { marketing: { groups, loyalty } } = getState();
    // Validate if bill has client
    if (!bill.client) return;

    const clientGroup = groups.find(group => group.id === bill.client.group);
    const profitCount = bill.client.profitCount + parseFloat(bill.pay.total);

    let deltaBonusAmount = 0;
    let deltaSaleAmount = 0;
    let groupForTransit = bill.client.group;

    if (!!bill.client.group || !!clientGroup) {
      if (clientGroup.type === 'bonus') {
        deltaBonusAmount = castToNormalFloat(parseFloat(bill.pay.total) / 100 * clientGroup.count);

        if (loyalty.bonusTransition) {
          const maxAvailableRange = getMaxAvailableRange(profitCount, loyalty.bonusRanges);
          const newGroup = groups.find(group => group.id === maxAvailableRange.group);
          groupForTransit = (newGroup && newGroup.count > clientGroup.count)
            ? maxAvailableRange.group
            : bill.client.group;
        }
      }
      if (clientGroup.type === 'sale') {
        deltaSaleAmount = castToNormalFloat(parseFloat(bill.pay.total) / 100 * clientGroup.count);

        if (loyalty.saleTransition) {
          const maxAvailableRange = getMaxAvailableRange(profitCount, loyalty.saleRanges);
          // Check is 'low' range exist, to not drop group or set less relevant
          const newGroup = groups.find(group => group.id === maxAvailableRange.group);
          groupForTransit = (newGroup && newGroup.count > clientGroup.count)
            ? maxAvailableRange.group
            : bill.client.group;
        }
      }
    }

    const bonusCount = castToNormalFloat(bill.client.bonusCount + deltaBonusAmount - (bill.pay.bonus || 0));
    const saleCount = castToNormalFloat(bill.client.saleCount + deltaSaleAmount);

    dispatch({
      type: EDIT_CLIENT,
      payload: {
        ...bill.client, profitCount, bonusCount, saleCount, group: groupForTransit,
      },
    });
  };
}


export function addClient(data) {
  return (dispatch, getState) => {
    const { marketing: { loyalty } } = getState();
    const id = generateIdString();
    const client = {
      ...data, profitCount: 0, saleCount: 0, creationDate: id, bonusCount: loyalty.inviteBonus || 0,
    };

    return addClientRequest(id, client)
      .then((res) => {
        dispatch({ type: ADD_CLIENT, payload: { ...res.data, id: parseInt(id, 10) } });

        persist({ ...res.data, id: parseInt(id, 10) }, idbMarketingClients);

        dispatch(showMessage({ text: i18n.t('actions.marketingClientCreatedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function removeClient(id) {
  return dispatch => removeClientRequest(id)
    .then(() => {
      dispatch({ type: REMOVE_CLIENT, payload: id });

      removeDataFromPersist(id, idbMarketingClients);

      dispatch(showMessage({ text: i18n.t('actions.marketingClientDeletedTitle') }));
    })
    .catch(showErrorMessage);
}

export function getSales(persisted = false) {
  return (dispatch) => {
    if (persisted || !isOnline()) {
      return getPersistedMarketingSales()
        .then(data => dispatch({ type: GET_SALES, payload: data }));
    }
    return getSalesDataRequest()
      .then(res => dispatch({ type: GET_SALES, payload: res.data }))
      .catch(showErrorMessage);
  };
}
export function editSale(sale) {
  return dispatch => editSaleRequest(sale.id, sale)
    .then((res) => {
      dispatch({ type: EDIT_SALE, payload: res.data });

      persist({ ...res.data, id: sale.id }, idbMarketingSales);

      dispatch(showMessage({ text: i18n.t('actions.marketingSaleEditedTitle') }));
    })
    .catch(showErrorMessage);
}

export function addSale(data) {
  return (dispatch) => {
    const id = generateId();

    return addSaleRequest(id, data)
      .then((res) => {
        dispatch({ type: ADD_SALE, payload: { ...res.data, id } });

        persist({ ...res.data, id }, idbMarketingSales);

        dispatch(showMessage({ text: i18n.t('actions.marketingSaleCreatedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function removeSale(id) {
  return dispatch => removeSaleRequest(id)
    .then(() => {
      dispatch({ type: REMOVE_SALE, payload: id });

      removeDataFromPersist(id, idbMarketingSales);

      dispatch(showMessage({ text: i18n.t('actions.marketingSaleDeletedTitle') }));
    })
    .catch(showErrorMessage);
}

export function getGroups(persisted = false) {
  return (dispatch) => {
    if (persisted || !isOnline()) {
      return getPersistedMarketingGroups()
        .then(data => dispatch({ type: GET_GROUPS, payload: data }));
    }
    return getGroupsDataRequest()
      .then(res => dispatch({ type: GET_GROUPS, payload: res.data }))
      .catch(showErrorMessage);
  };
}

export function editGroup(group) {
  return dispatch => editGroupRequest(group.id, group)
    .then((res) => {
      dispatch({ type: EDIT_GROUP, payload: res.data });

      persist({ ...res.data, id: group.id }, idbMarketingGroups);

      dispatch(showMessage({ text: i18n.t('actions.marketingGroupEditedTitle') }));
    })
    .catch(showErrorMessage);
}

export function addGroup(data) {
  return (dispatch) => {
    const id = generateId();
    return addGroupRequest(id, data)
      .then((res) => {
        dispatch({ type: ADD_GROUP, payload: { ...res.data, id } });

        persist({ ...res.data, id }, idbMarketingGroups);

        dispatch(showMessage({ text: i18n.t('actions.marketingGroupCreatedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function removeGroup(id) {
  return dispatch => removeGroupRequest(id)
    .then(() => {
      dispatch({ type: REMOVE_GROUP, payload: id });

      removeDataFromPersist(id, idbMarketingGroups);

      dispatch(showMessage({ text: i18n.t('actions.marketingGroupDeletedTitle') }));
    })
    .catch(showErrorMessage);
}
