import { generateId } from '../../../utils/helpers';
import { editItem, editProduct } from './index';
import {
  addCategoryRequest, editCategoryRequest, getCategoriesDataRequest, removeCategoryRequest,
} from '../../../api/db/menu';
import { showErrorMessage, showMessage } from '../../message/actions';
import i18n from '../../../utils/translation';
import {
  getPersistedMenuCategories, isOnline, persistWithKey, removeDataFromPersist,
} from '../../../persistance';
import { idbMenuCategories } from '../../../config';

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const EDIT_CATEGORY = 'EDIT_CATEGORY';
export const REMOVE_CATEGORY = 'REMOVE_CATEGORY';

export function getCategories(persisted = false) {
  return (dispatch) => {
    if (persisted || !isOnline()) {
      return getPersistedMenuCategories()
        .then(data => dispatch({ type: GET_CATEGORIES, payload: data }));
    }
    return getCategoriesDataRequest()
      .then(res => dispatch({
        type: GET_CATEGORIES,
        payload: res.data
          .map(el => ({ ...el, visible: el.visible === undefined ? true : el.visible })),
      }))
      .catch(showErrorMessage);
  };
}

export function createCategory(category) {
  return (dispatch) => {
    const id = generateId();

    return addCategoryRequest(id, category)
      .then((res) => {
        dispatch({ type: ADD_CATEGORY, payload: { ...res.data, id } });

        persistWithKey(id, { ...res.data, id }, idbMenuCategories);

        dispatch(showMessage({ text: i18n.t('actions.menuCategoryCreatedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function removeCategory(item) {
  return dispatch => removeCategoryRequest(item.id)
    .then(() => {
      dispatch({ type: REMOVE_CATEGORY, payload: item.id });

      removeDataFromPersist(item.id, idbMenuCategories);

      dispatch(showMessage({ text: i18n.t('actions.menuCategoryDeletedTitle') }));
    })
    .catch(showErrorMessage);
}

export function editCategory(oldCategory, item) {
  return (dispatch, getState) => {
    // TODO: move to BE and Redux
    const { products, items } = getState().menu;
    products.filter(product => product.category === oldCategory.name).forEach(product => dispatch(editProduct({
      ...product,
      category: item.name,
    }, false)));

    items.filter(item => item.category === oldCategory.name).forEach(item => dispatch(editItem({
      ...item,
      category: item.name,
    }, false)));

    return editCategoryRequest(oldCategory.id, item)
      .then(() => {
        dispatch({ type: EDIT_CATEGORY, payload: { ...item, id: oldCategory.id } });

        persistWithKey(oldCategory.id, { ...item, id: oldCategory.id }, idbMenuCategories);


        dispatch(showMessage({ text: i18n.t('actions.menuCategoryEditedTitle') }));
      })
      .catch(showErrorMessage);
  };
}

export function toggleCategoryVisible(category, visible) {
  return (dispatch) => {
    const newCategory = { ...category, visible };

    delete newCategory.id;

    return editCategoryRequest(category.id, newCategory)
      .then((res) => {
        dispatch({ type: EDIT_CATEGORY, payload: { ...res.data, id: parseInt(category.id, 10) } });

        persistWithKey(category.id, { ...res.data, id: parseInt(category.id, 10) }, idbMenuCategories);

        dispatch(showMessage({ text: i18n.t('actions.menuCategoryEditedTitle') }));
      })
      .catch(showErrorMessage);
  };
}
