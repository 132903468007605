import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { showMessage } from '../../../redux/message/actions';
import Clients from './MarketingGroups';
import { removeGroup } from '../../../redux/marketing/actions';

function mapStateToProps(state) {
  return {
    dataLoaded: state.business.dataLoaded,
    groups: state.marketing.groups,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        removeGroup,
      },
      dispatch,
    ),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Clients));
