import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Table } from 'antd';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { firebase } from '../../config';
import { ItemsTable, ModalHOC } from '../../common';
import { Column } from '../index';
import { showMessage } from '../../redux/message/actions';
import { getMonthWithLocale } from '../../constants/time';
import { prepareBillData } from '../../redux/statistic/helpers';
import {
  calculateComponentsCost, calculateConsumablesCost, calculateTotal, getDayStatisticFromSortedBills, getItemsCost,
  sortBillsByDate,
} from '../../utils/helpers';
import i18 from '../../utils/translation';
import './style.scss';

class TodayReport extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getAllItems = bills => bills.reduce((res, bill) => res.concat(bill.items), []);

  getCost = (el) => {
    const { menu: { idMap }, equipment } = this.props;
    if (!firebase.auth().currentUser || equipment.loading) return;
    switch (el.type) {
      case 'addon': return idMap[el.src.componentId].cost * el.src.count;
      case 'product': {
        return calculateComponentsCost(el.src.components, idMap, el.size)
          + calculateConsumablesCost(el.src.consumables, equipment.idMap, el.size);
      }
      default: return el.src.cost;
    }
  };

  getItemsData = (bills) => {
    const { menu } = this.props;
    // Data for items calculation
    const allItems = this.getAllItems(bills);
    return prepareBillData(allItems).filter(el => menu.idMap[el.id]).map(el => (el.sizes.map(size => ({
      ...el, size, price: el.prices[size], quantity: el.quantity[size], type: menu.idMap[el.id].type,
    })))).flat()
      .map((item, key) => {
        const data = { src: menu.idMap[item.id], ...item, key };
        const cost = parseFloat(this.getCost(data)).toFixed(2);
        return ({
          ...data,
          cost,
          sum: parseFloat(data.quantity * data.price).toFixed(2),
          gross: (data.quantity * parseFloat(data.price - cost)).toFixed(2),
        });
      });
  };

  render() {
    const {
      t, close, visible, bills = [], showActiveShift = false, resolvedBills, menu, equipment, access: { employers },
      shift: { activeShift, cashShifts }, isAdmin, business,
    } = this.props;
    // Info for sales calculation
    const billsToday = sortBillsByDate(showActiveShift ? bills : resolvedBills, moment(), moment());
    const today = billsToday.find(el => moment().isSame(el.date, 'day'));
    const todayStatistic = { ...getDayStatisticFromSortedBills([today])[0], ...today };
    const items = today.bills.map(bill => bill.items).flat().map(el => ({ ...el, ...menu.idMap[el.id] }));
    const cost = getItemsCost(items, menu, equipment);
    const filteredShifts = cashShifts.filter(el => el.startTime > moment().startOf('day').valueOf());
    // TODO: refactor default ActiveShift transactions
    const transactions = showActiveShift ? activeShift.transactions
      : filteredShifts.map(el => el.transactions).flat();

    const salesList = this.getItemsData(today.bills);
    const consumptionCount = transactions.reduce((count, el) => count + parseInt(el.price, 10), 0);
    const cashBoxConsumption = transactions.filter(el => el.account === 0)
      .reduce((count, el) => count + parseInt(el.price, 10), 0);
    return (
      <Modal
        maskClosable={false}
        visible={visible}
        onCancel={close}
        footer={null}
        width="740px"
        style={{ top: 14 }}
        title={showActiveShift ? t('modalMenu.shiftReport') : t('modalMenu.dayReport')}
      >
        <Column width="100%" alignItems="flex-start">
          <h2 style={{ margin: '0' }}>
            {t('time.today')},&nbsp;
            {moment().format('DD')} {getMonthWithLocale(i18.language, moment().format('M'))}
          </h2>

          <Column width="100%" className="default-card" margin="15px 0">
            <h2 style={{ margin: '0 0 5px' }}>{t('global.sales')}</h2>
            <Table
              pagination={false}
              style={{ width: '100%' }}
              bordered
              className="table-bold-last-row"
              columns={[
                { dataIndex: 'name', title: t('global.type') },
                {
                  dataIndex: 'value',
                  title: t('global.sum'),
                  className: 'table-header-right',
                  render: value => <span style={{ fontWeight: '600' }}>{value}</span>,
                },
              ]}
              dataSource={[
                showActiveShift
                  ? {
                    key: '0',
                    name: t('modalMenu.openCashBalance'),
                    value: `${activeShift.openCount} ${business.currency}`,
                  }
                  : null,
                { key: '1', name: t('pay.cash'), value: `${todayStatistic.cash} ${business.currency}` },
                { key: '2', name: t('pay.card'), value: `${todayStatistic.card} ${business.currency}` },
                { key: '3', name: t('pay.byBonus'), value: `${todayStatistic.bonus} ${business.currency}` },
                { key: '4', name: t('global.sale'), value: `${todayStatistic.sale} ${business.currency}` },
                { key: '7', name: t('layout.bills'), value: `${todayStatistic.count} ${t('count')}` },
                {
                  key: '8',
                  name: t('statistic.middleBill'),
                  value: `${parseFloat((todayStatistic.total || 0) / (todayStatistic.count || 1))
                    .toFixed(2)} ${business.currency}`,
                },
                {
                  key: 11,
                  name: t('modalMenu.consumptionCount'),
                  value: `${consumptionCount} ${business.currency}`,
                },
                showActiveShift
                  ? {
                    key: '10',
                    name: t('modalMenu.expectedCashBalance'),
                    value: `${(parseInt(activeShift.openCount, 10)
                      + parseFloat(todayStatistic.cash) - cashBoxConsumption)
                      .toFixed(2)} ${business.currency}`,
                  }
                  : null,
                isAdmin
                  ? {
                    key: '5',
                    name: t('global.gross'),
                    value: `${(todayStatistic.total - cost).toFixed(2)} ${business.currency}`,
                  } : null,
                isAdmin ? { key: '6', name: t('cost.title'), value: `${cost} ${business.currency}` } : null,
                {
                  key: '9',
                  name: t('global.revenue'),
                  value: `${todayStatistic.total} ${business.currency}`,
                },
              ].filter(el => !!el)}
            />
          </Column>
          {transactions.length > 0 && (
            <Column width="100%" className="default-card" margin="0 0 15px">
              <h2 style={{ margin: '0 0 15px' }}>{t('layout.transactions')}</h2>
              <Table
                pagination={false}
                style={{ width: '100%', overflow: 'scroll' }}
                bordered
                columns={[
                  {
                    title: t('access.employer'),
                    dataIndex: 'employer',
                    render: employer => (employer ? employer.name : ''),
                  }, {
                    title: t('financial.account2'),
                    dataIndex: 'account',
                    render: (account = 0) => (account === 0 ? t('financial.cashbox') : t('financial.safeAccount')),
                  }, {
                    title: t('financial.type'),
                    dataIndex: 'type',
                    render: type => t(`financial.${type}`),
                  }, {
                    title: t('supply.comment'),
                    dataIndex: 'description',
                  }, {
                    title: t('global.sum'),
                    dataIndex: 'price',
                    className: 'table-header-right',
                    render: value => (<span style={{ fontWeight: '600' }}>{value} {business.currency}</span>),
                  },
                ]}
                dataSource={transactions.map((el, i) => ({ ...el, key: `transaction-${i}` }))}
              />
            </Column>
          )}
          {today.bills.length > 0 && (
            <Column width="100%" className="default-card">
              <h2 style={{ margin: '0 0 15px' }}>{t('layout.employers')}</h2>
              <Table
                pagination={false}
                bordered
                style={{ width: '100%', overflow: 'scroll' }}
                columns={[
                  { dataIndex: 'name', title: t('access.employer') },
                  {
                    dataIndex: 'value',
                    title: t('global.sum'),
                    className: 'table-header-right',
                    render: value => <span style={{ fontWeight: '600' }}>{value} {business.currency}</span>,
                  },
                ]}
                dataSource={Array.from(new Set(today.bills.map(el => parseInt(el.employer.id, 10)))).map(id => ({
                  key: id,
                  name: employers.find(el => el.id === parseInt(id, 10)).name,
                  value: today.bills.filter(bill => parseInt(bill.employer.id, 10) === id)
                    .reduce((acc, bill) => acc + parseFloat(calculateTotal(bill)), 0)
                    .toFixed(2),
                }))}
              />
            </Column>
          )}
          <Column width="100%" className="default-card" margin="15px 0">
            <h2 style={{ margin: '0 0 5px' }}>{t('global.products')}</h2>
            <ItemsTable
              currency={business.currency}
              isAdmin={isAdmin}
              t={t}
              data={salesList.filter(el => el.type === 'product')}
            />
          </Column>
          <Column width="100%" className="default-card">

            <h2 style={{ margin: '0 0 5px' }}>{t('global.items')}</h2>
            <ItemsTable
              currency={business.currency}
              isAdmin={isAdmin}
              t={t}
              data={salesList.filter(el => el.type === 'item')}
            />
          </Column>
          <Column width="100%" className="default-card" margin="15px 0 0">
            <h2 style={{ margin: '0 0 5px' }}>{t('global.addons')}</h2>
            <ItemsTable
              currency={business.currency}
              isAdmin={isAdmin}
              t={t}
              data={salesList.filter(el => el.type === 'addon')}
            />
          </Column>
        </Column>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    isAdmin: state.business.isAdmin,
    equipment: state.equipment,
    business: state.business,
    access: state.access,
    shift: state.shift,
    resolvedBills: state.statistic.resolvedBills,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
      },
      dispatch,
    ),
  };
}

export default ModalHOC(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TodayReport)));
