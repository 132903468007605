import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { showMessage } from '../../../redux/message/actions';
import { editCommunication, addPersonalId } from '../../../redux/communication/actions';
import Notifications from './Notifications';

function mapStateToProps(state) {
  return {
    user: state.user,
    communication: state.communication,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        editCommunication,
        addPersonalId,
      },
      dispatch,
    ),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notifications));
