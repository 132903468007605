import {
  ADD_BILL,
  ADD_TO_BILL,
  REMOVE_BILL,
  CALCULATE_BILL,
  REMOVE_FROM_BILL,
  SET_ACTIVE_KEY,
  GET_RESOLVED_BILLS,
  CLEAR_BILL,
  ADD_COMMENT,
  EDIT_BILL,
  ADD_PROMOTION,
  CALCULATE_PROMOTION,
  ADD_SALE_TO_BILL,
  REMOVE_PROMOTION,
  EDIT_BILL_PAYMENT,
  ADD_CLIENT_TO_BILL,
  REMOVE_CLIENT_FROM_BILL,
  CLEAN_ACTIVE_BILLS,
} from './actions';
import { removeFromBillItems } from './helpers';

const initialState = {
  activeBills: [],
  resolvedBills: [],
  activeKey: null,
};

function statisticReducer(state = initialState, action) {
  switch (action.type) {
    case CLEAN_ACTIVE_BILLS: return { ...state, activeBills: [] };
    case SET_ACTIVE_KEY:
      return { ...state, activeKey: action.payload };
    case GET_RESOLVED_BILLS:
      return { ...state, resolvedBills: action.payload };
    case ADD_BILL:
      return { ...state, activeBills: [...state.activeBills, action.payload], activeKey: action.payload.id };
    case CALCULATE_BILL:
      const filterBills = state.activeBills.filter(bill => bill.id !== action.payload.id);
      return {
        ...state,
        resolvedBills: [...state.resolvedBills, {
          ...state.activeBills.find(el => el.id === action.payload.id), ...action.payload,
        }],
        activeBills: filterBills,
        activeKey: state.activeKey === action.payload.id
          ? filterBills.length > 0 ? filterBills[0].id : null
          : state.activeKey,
      };
    case ADD_TO_BILL:
      return {
        ...state,
        activeBills: state.activeBills.map(bill => (bill.id === action.payload.id ? {
          ...bill, items: [...bill.items, action.payload.item],
        } : bill
        )),
      };
    case EDIT_BILL:
      return {
        ...state,
        activeBills: state.activeBills.map(bill => (bill.id === action.payload.id ? {
          ...bill, items: [...bill.items, action.payload.item],
        } : bill
        )),
      };
    case EDIT_BILL_PAYMENT:
      return {
        ...state,
        resolvedBills: state.resolvedBills.map(bill => (bill.id === action.payload.id ? action.payload : bill
        )),
      };
    case ADD_SALE_TO_BILL:
      return {
        ...state,
        activeBills: state.activeBills.map(bill => (bill.id === action.payload.id ? {
          ...bill,
          sale: {
            amount: 0, percent: 0, ...bill.sale, ...action.payload.sale, promotion: null,
          },
        } : bill
        )),
      };
    case ADD_CLIENT_TO_BILL:
      return {
        ...state,
        activeBills: state.activeBills.map(bill => (bill.id === state.activeKey ? {
          ...bill,
          client: action.payload,
        } : bill
        )),
      };
    case REMOVE_CLIENT_FROM_BILL:
      return {
        ...state,
        activeBills: state.activeBills.map(bill => (bill.id === state.activeKey ? {
          ...bill,
          client: null,
        } : bill
        )),
      };
    case ADD_PROMOTION:
      return {
        ...state,
        activeBills: state.activeBills.map(bill => (bill.id === action.payload.id ? {
          ...bill,
          sale: { amount: 0, percent: 0, promotion: action.payload.promotion },
        } : bill
        )),
      };
    case REMOVE_PROMOTION:
      return {
        ...state,
        activeBills: state.activeBills.map(bill => (bill.id === action.payload ? {
          ...bill,
          sale: { percent: 0, amount: 0, promotion: null },
        } : bill
        )),
      };
    case CALCULATE_PROMOTION:
      return {
        ...state,
        activeBills: state.activeBills.map(bill => (bill.id === state.activeKey ? {
          ...bill,
          sale: { ...bill.sale, [action.payload.type]: action.payload.count },
        } : bill
        )),
      };
    case ADD_COMMENT:
      return {
        ...state,
        activeBills: state.activeBills.map(bill => (bill.id === action.payload.id ? {
          ...bill, comment: action.payload.comment,
        } : bill
        )),
      };
    case REMOVE_FROM_BILL:
      const { id, size, item } = action.payload;
      return {
        ...state,
        activeBills: state.activeBills.map(bill => (bill.id === id ? {
          ...bill, items: removeFromBillItems(bill.items, item, size),
        } : bill)),
      };
    case CLEAR_BILL:
      return {
        ...state,
        activeBills: state.activeBills.map(bill => (bill.id === action.payload ? {
          ...bill, items: [], sale: { percent: 0 }, comment: '',
        } : bill)),
      };
    case REMOVE_BILL:
      const filteredBills = state.activeBills.filter(bill => bill.id !== action.payload);
      return {
        ...state,
        activeBills: filteredBills,
        activeKey: state.activeKey === action.payload
          ? filteredBills.length > 0 ? filteredBills[0].id : null
          : state.activeKey,
      };
    case 'CLEAN_REDUCERS': {
      return initialState;
    }
    default:
      return state;
  }
}

export default statisticReducer;
