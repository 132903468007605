import {
  GET_CLIENTS, GET_GROUPS, GET_LOYALTY, EDIT_CLIENT, EDIT_GROUP, EDIT_LOYALTY, ADD_GROUP, ADD_CLIENT,
  REMOVE_CLIENT, REMOVE_GROUP, EDIT_SALE, GET_SALES, ADD_SALE, REMOVE_SALE,
} from './actions';

export const initialState = {
  clients: [],
  groups: [],
  groupsLoaded: false,
  salesLoaded: false,
  sales: [],
  loyalty: {
    bonusRanges: [],
    saleRanges: [],
    excludedCategories: [],
    excludedItems: [],
  },
};

function marketingReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CLIENTS:
      return {
        ...state,
        clients: action.payload,
      };
    case GET_LOYALTY:
      return {
        ...state,
        loyalty: { ...state.loyalty, ...action.payload },
      };
    case GET_GROUPS:
      return {
        ...state,
        groups: action.payload,
        groupsLoaded: true,
      };
    case GET_SALES:
      return {
        ...state,
        sales: action.payload,
        salesLoaded: true,
      };
    case EDIT_SALE: return {
      ...state,
      sales: state.sales.map(el => (el.id === action.payload.id ? action.payload : el)),
    };
    case ADD_SALE: return {
      ...state,
      sales: [...state.sales, action.payload],
    };
    case REMOVE_SALE: return {
      ...state,
      sales: state.sales.filter(el => el.id !== action.payload),
    };
    case EDIT_LOYALTY: return {
      ...state,
      loyalty: { ...state.loyalty, ...action.payload },
    };
    case EDIT_CLIENT: return {
      ...state,
      clients: state.clients.map(el => (el.id === action.payload.id ? action.payload : el)),
    };
    case EDIT_GROUP: return {
      ...state,
      groups: state.groups.map(el => (el.id === action.payload.id ? action.payload : el)),
    };
    case ADD_GROUP: return {
      ...state,
      groups: [...state.groups, action.payload],
    };
    case ADD_CLIENT: return {
      ...state,
      clients: [...state.clients, action.payload],
    };
    case REMOVE_CLIENT: return {
      ...state,
      clients: state.clients.filter(el => el.id !== action.payload),
    };
    case REMOVE_GROUP: return {
      ...state,
      groups: state.groups.filter(el => el.id !== action.payload),
    };
    case 'CLEAN_REDUCERS': {
      return initialState;
    }
    default: return state;
  }
}

export default marketingReducer;
