import React, { Component, Suspense, lazy } from 'react';
import { withTranslation } from 'react-i18next';
import { MenuOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button, Modal, Menu as AntdMenu, Dropdown,
} from 'antd';
import {
  ManageCategories, Header, Column, MenuTabs,
} from '../../components';
import './style.scss';
import AddonModal from '../Addon';
import ProductModal from '../Product';
import ItemModal from '../Item';
import { isOnline } from '../../persistance';
import { showNetworkError } from '../../redux/message/actions';

const LazyCreateAddon = lazy(() => import('../Addon/Create'));
const LazyCreateProduct = lazy(() => import('../Product/Create'));
const LazyCreateItem = lazy(() => import('../Item/Create'));

class Menu extends Component {
  constructor(props) {
    super(props);
    const { menu: { idMap }, history: { location: { state } } } = props;
    const selectedItem = state && state.itemId ? idMap[state.itemId] : null;
    const selectedAddon = state && state.addonId ? idMap[state.addonId] : null;
    const selectedProduct = state && state.productId ? idMap[state.productId] : null;
    this.state = {
      visibleMobileMenu: false,
      selectedAddon,
      selectedProduct,
      showReceiptModal: false,
      selectedItem,
      newProduct: undefined,
      newItem: undefined,
    };
  }

  componentDidMount() {
    const { selectedItem, selectedAddon, selectedProduct } = this.state;
    if (selectedProduct) {
      this.productModal.show();
    } else if (selectedItem) {
      this.itemModal.show();
    } else if (selectedAddon) {
      this.addonModal.show();
    }
  }

  selectAddon = (selectedAddon) => {
    this.setState({ selectedAddon });
    this.addonModal.show();
  };

  selectProduct = (selectedProduct) => {
    this.setState({ selectedProduct });
    this.productModal.show();
  };

  selectItem = (selectedItem) => {
    this.setState({ selectedItem });
    this.itemModal.show();
  };

  listCallback = (el) => {
    if (el.type === 'addon') {
      return this.selectAddon(el);
    }
    if (el.components) {
      this.selectProduct(el);
    } else {
      this.selectItem(el);
    }
  };

  showReceiptModal = () => {
    if (!isOnline()) return showNetworkError();
    this.setState({ showReceiptModal: true });
  };

  hideReceiptModal = () => this.setState({ showReceiptModal: false });

  makeProductCopy = () => {
    this.productModal.hide();
    this.setState(state => ({ newProduct: state.selectedProduct, selectedProduct: null }),
      () => this.createProductModal.show());
  };

  makeItemCopy = () => {
    this.itemModal.hide();
    this.setState(state => ({ newItem: state.selectedItem, selectedItem: null }),
      () => this.createItemModal.show());
  };

  render() {
    const {
      menu: { componentsLoaded }, t, business,
    } = this.props;
    const {
      selectedAddon, selectedProduct, selectedItem, showReceiptModal, newProduct, newItem, visibleMobileMenu,
    } = this.state;
    const mobileMenu = (
      <AntdMenu className="menu-mobile">
        <AntdMenu.Item onClick={() => {
          if (!isOnline()) return showNetworkError();
          this.manageCategoriesModal.show();
        }}
        >
          <MenuOutlined />{t('actionDrawer.manageCategories')}
        </AntdMenu.Item>
        {componentsLoaded && (
        <AntdMenu.Item onClick={() => {
          if (!isOnline()) return showNetworkError();
          this.createProductModal.show();
        }}
        >
          <PlusOutlined /> {t('actionDrawer.createProduct')}
        </AntdMenu.Item>
        )}
        <AntdMenu.Item onClick={() => {
          if (!isOnline()) return showNetworkError();
          this.createItemModal.show();
        }}
        >
          <PlusOutlined style={{ color: '#303641' }} /> {t('actionDrawer.createItem')}
        </AntdMenu.Item>
        <AntdMenu.Item onClick={() => {
          if (!isOnline()) return showNetworkError();
          this.createAddonModal.show();
        }}
        >
          <PlusOutlined />{t('actionDrawer.createAddon')}
        </AntdMenu.Item>
      </AntdMenu>
    );
    return (
      <div
        className="menu-page"
        onClick={() => {
          if (visibleMobileMenu) {
            this.setState({ visibleMobileMenu: false });
          }
        }}
      >
        <Header className="menu-page-header">
          <Button
            className="mobile-hide"
            id="menu-receipt"
            data-tour="menu-tour-2-1"
            onClick={this.showReceiptModal}
            icon={<PlusOutlined />}
            type="link"
          >
            {t('actionDrawer.createReceipt')}
          </Button>
          <h2 className="header-name">{business.name}</h2>
          <Button
            className="mobile-hide"
            data-tour="menu-tour-2"
            onClick={() => {
              if (!isOnline()) return showNetworkError();
              this.manageCategoriesModal.show();
            }}
            icon={<MenuOutlined />}
            type="link"
          >
            {t('actionDrawer.manageCategories')}
          </Button>
          <Dropdown
            onClick={(e) => { e.stopPropagation(); }}
            visible={visibleMobileMenu}
            className="mobile-show"
            overlay={mobileMenu}
            placement="bottomCenter"
          >
            <Button
              onClick={() => this.setState({ visibleMobileMenu: true })}
              icon={<MenuOutlined />}
              type="link"
            >{t('layout.menu')}
            </Button>
          </Dropdown>
        </Header>
        <MenuTabs withAddons listCallback={this.listCallback} />
        <Modal
          visible={showReceiptModal}
          onCancel={this.hideReceiptModal}
          maskClosable={false}
          width="300px"
          footer={null}
          title={t('actionDrawer.createModalTitle')}
        >
          <Column width="100%" justifyContent="flex-start" alignItems="center">
            {componentsLoaded && (
              <Button
                style={{ width: '202px' }}
                data-tour="menu-tour-3"
                onClick={() => this.createProductModal.show()}
                icon={<PlusOutlined />}
              >
                {t('actionDrawer.createProduct')}
              </Button>
            )}
            <Button
              style={{ margin: '20px 0', width: '202px' }}
              data-tour="menu-tour-4"
              onClick={() => this.createItemModal.show()}
              icon={<PlusOutlined />}
            >
              {t('actionDrawer.createItem')}
            </Button>
            <Button
              style={{ width: '202px' }}
              data-tour="menu-tour-5"
              onClick={() => this.createAddonModal.show()}
              icon={<PlusOutlined />}
            >
              {t('actionDrawer.createAddon')}
            </Button>
          </Column>
        </Modal>
        <Suspense fallback={<div />}>
          <LazyCreateProduct
            callback={() => this.setState({ newProduct: undefined })}
            product={newProduct}
            ref={(ref) => { this.createProductModal = ref; }}
          />
        </Suspense>
        <Suspense fallback={<div />}>
          <LazyCreateItem
            callback={() => this.setState({ newItem: undefined })}
            item={newItem}
            ref={(ref) => { this.createItemModal = ref; }}
          />
        </Suspense>
        <ManageCategories ref={(ref) => { this.manageCategoriesModal = ref; }} />
        <Suspense fallback={<div />}>
          <LazyCreateAddon ref={(ref) => { this.createAddonModal = ref; }} />
        </Suspense>
        <AddonModal addon={selectedAddon} ref={(ref) => { this.addonModal = ref; }} />
        <ProductModal
          makeCopy={this.makeProductCopy}
          product={selectedProduct}
          ref={(ref) => { this.productModal = ref; }}
        />
        <ItemModal makeCopy={this.makeItemCopy} item={selectedItem} ref={(ref) => { this.itemModal = ref; }} />
      </div>
    );
  }
}

export default withTranslation()(Menu);
