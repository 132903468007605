import React from 'react';
import { Timeline } from 'antd';
import { withTranslation } from 'react-i18next';
import {
  PageHeader, Column, UpdateVersion, Page, Content,
} from '../../../components';

const Versions = () => (
  <Page wrapperClassName="storage-page settings-page">
    <PageHeader />
    <Content>
      <UpdateVersion />
      <Column
        className="mobile-block default-card"
        width="100%"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Timeline mode="left">
          <Timeline.Item color="green">
            <h3 className="version-title">2021-03-22 - Релиз версии #3.0.0!</h3>
            <p>Изменили тарифную сетку - теперь существует всего один тарифный план - <b>UNIVERSAL</b></p>
            <p>Изменили стоимость подписки - теперь Shoptoria POS доступна всего за <b>10$ в месяц</b></p>
            <p>Добавили вкладку <b>клиенты</b> и <b>группы клиентов</b></p>
            <p>Добавили поддержку <b>программ лояльности</b></p>
            <p>Добавили <b>бонусную</b> и <b>скидочную</b> системы</p>
            <p>Добавили возможность оплаты заказа <b>бонусами</b></p>
            <p>Провели частичний редизайн главной страницы</p>
            <p>Починили <b>Telegram бота</b></p>
            <p>Добавили больше фильтров и сортировок к таблицам</p>
            <p>Исправили работу приложения без интернета</p>
            <p>Изменили шрифты на более читабельные</p>
            <p>Оптимизировали размер приложения</p>
            <p>Улучшили работу с базой данных</p>
            <p>Оптимизировали количество загружаемых данных</p>
          </Timeline.Item>
          <Timeline.Item color="green">
            <h3 className="version-title">2020-10-07 - Релиз версии #2.0.0!</h3>
            <p>Добавили страницу с хронологией обновлений</p>
            <p>Добавили вкладку <b>Модификаторы</b> в Терминал</p>
            <p>Разделили страницу Общих настроек на <b>Общие</b> и <b>Уведомления</b></p>
            <p>
              Добавили страницу <b>Настроек Терминала</b> - теперь можно исключить из&nbsp;
              <b>Терминала</b> не используемые общие категории
            </p>
            <p>Добавили возможность скрыть название категорий с карточки товара</p>
            <p>Добавили поиск по компонентам при создании модификатора</p>
            <p>Добавили возможность скрыть <b>Категорию меню</b> из Терминала</p>
            <p>Улучшили работу с базой данных</p>
            <p>Оптимизировали количество загружаемых данных</p>
          </Timeline.Item>
          <Timeline.Item color="green">
            <h3 className="version-title">2020-09-17 - Релиз версии #1.2.0!</h3>
            <p>Исправили отображение кнопок для IOS устройств</p>
            <p>Улучшили работу модуля синхронизации данных</p>
            <p>Исправили проблему редактирования модификаторов</p>
            <p>Добавили ссылку на страницу приложени в Play Market</p>
            <p>Отключили автозакрытие <b>кассовых смен</b></p>
            <p>Исправили отображение не закрытых кассовых смен</p>
            <p>Исправили ошибку отображения картинок</p>
            <p>Добавили страницу информации для разработчиков, чтобы помочь вам при общении с тех. поддержкой</p>
            <p>Исправили отображение списка должностей при создании нового заведения</p>
            <p>Исправили проблему с загрузкой чеков после перезагрузки приложения</p>
            <p>Уменьшили объем загружаемых данных</p>
          </Timeline.Item>
          <Timeline.Item color="green">
            <h3 className="version-title">2020-09-07 - Релиз версии #1.1.1!</h3>
            <p>Добавили <b>целочисленную скидку</b> - теперь вы можете выбрать сумму скидки для чека</p>
            <p>Добавили кнопку <b>Установки</b> - теперь установить приложение на устройство можно в один клик</p>
            <p>Убрали ограничение на количество модификаторов, созданных с одного компонента</p>
            <p>
              Добавили возможность убирать части рецепта при продаже, для корректного списания со склада,&nbsp;
              в случае надобности
            </p>
            <p>Добавили статистику продаж по сотрудникам</p>
            <p>
              Идет работа над <b>Программой лояльности</b> - уже сейчас ваш администратор может добавлять&nbsp;
              клиентов в базу и создавать скидочные группы
            </p>
            <p>Обновили тарифную сетку подписок</p>
            <p>
              Добавили ссылку на <a target="_blank" href="https://t.me/shoptoriaPos">этот канал</a>,&nbsp;
              чтобы нас становилось еще больше
            </p>
          </Timeline.Item>
          <Timeline.Item color="green">
            <h3 className="version-title">2020-08-24 - Релиз версии #1.1.0!</h3>
            <p>Улучшили систему обнаружения ошибок</p>
            <p>Исправили UI для IOS устройств</p>
            <p>Добавили кеширование закрытых чеков и возможность их синхронизации в меню <b>Терминала.</b>&nbsp;
              Теперь Ваши данные о продажах не исченут в случае непредвиденных ошибок!
            </p>
            <p>
              Добавили обработку финансовых транзакций в <b>Терминале</b> - теперь все операции с сейфом&nbsp;
              заведение видны во вкладке <b>Финансы - Транзакции</b>, а операции с кассой - в отчете за смену
            </p>
            <p>Обновили работу с базой данных - теперь загрузка происходит в 2.5 раза быстрее! :rocket:</p>
            <p>Исправили мелкие баги и улучшили стабильность приложения</p>
          </Timeline.Item>
          <Timeline.Item color="green">
            <h3 className="version-title">2020-08-06 - Релиз версии #1.0.6!</h3>
            <p>Добавили новую страницу - <b>Поставщики</b></p>
            <p>Добавили новое поле в поставку - Поставщик</p>
            <p>При нажатии на номер поставщика, вы сразу можете позвонить ему)</p>
            <p>Добавили возможность <b>печати</b> отчетов</p>
            <p>Обновили движок приложения</p>
          </Timeline.Item>
          <Timeline.Item color="green">
            <h3 className="version-title">2020-07-24 - Релиз версии #1.0.5!</h3>
            <p>Добавили <b>развесные товары!</b></p>
            <p>Исправили сортировку таблицы Остатки на складе</p>
            <p>Исправили количество списываемых с остатков компонентов и материалов при продажах</p>
            <p>Изменили логику сохранения чеков в базу - стало быстрее</p>
            <p>
              Добавили новые финансовые транзакции при открытии\закрытии кассовой смены, которые отображают&nbsp;
              <b>Сумму размена</b>
            </p>
          </Timeline.Item>
          <Timeline.Item color="green">
            <h3 className="version-title">2020-07-22 - Релиз версии #1.0.4!</h3>
            <p>Расширен отчет по <b>Инвентаризации</b> - теперь вы увидите и финансовую сторону процесса!</p>
            <p>
              Добавили финансовые транзации для открытие и закрытия кассовых смен для контроля передачи&nbsp;
              <b>размена</b> между сменами.
            </p>
            <p>Фикс мелких багов</p>
          </Timeline.Item>
          <Timeline.Item color="green">
            <h3 className="version-title">2020-07-20 - Релиз версии #1.0.3!</h3>
            <p>Внутрение оптимизации приложения</p>
            <p>Повышение стабильности работы в офлайне</p>
            <p>Фикс мелких багов</p>
          </Timeline.Item>
          <Timeline.Item color="green">
            <h3 className="version-title">2020-07-18 - Релиз версии #1.0.2!</h3>
            <p>Оптимизация работы с данными ускорила работу приложения в 2.5 раза!</p>
            <p>Изменение архитектуры базы данных позволило улучшить работу в офлайне</p>
            <p>Теперь после установки приложения им можно пользоваться и в вертикальной ориентации устройства</p>
            <p>Добавили новую единицу измерения для компонентов - <b>шт</b></p>
            <p>Добавили возможность выйти из аккаунта в окне <b>Доступа администратора</b></p>
            <p>Исправили интерфейс для IOS устройств</p>
          </Timeline.Item>
          <Timeline.Item color="green">
            <h3 className="version-title">2020-07-02 - Релиз версии #1.0.1!</h3>
            <p>Новый дизайн для <b>мобильной версии</b></p>
            <p>Оптимизация страницы <b>Терминал</b>, для работы на слабых устройствах</p>
            <p>Обновили иконку приложения на рабочем столе</p>
          </Timeline.Item>
        </Timeline>
      </Column>
    </Content>
  </Page>
);

export default withTranslation()(Versions);
