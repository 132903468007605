import {
  getKeeperSettingsDataRequest, updateKeeperSettingsRequest,
} from '../../api/db/keeper';
import { showErrorMessage, showMessage } from '../message/actions';
import i18n from '../../utils/translation';
import { getPersistedKeeperSettings, isOnline, persistWithKey } from '../../persistance';
import { idbKeeper } from '../../config';

export const SET_EMPLOYER = 'SET_EMPLOYER';
export const GET_KEEPER_SETTINGS = 'GET_KEEPER_SETTINGS';
export const SET_KEEPER_SETTINGS = 'SET_KEEPER_SETTINGS';

export function setEmployer(employer) {
  return { type: SET_EMPLOYER, payload: employer };
}

export function getKeeperSettings(persisted = false) {
  return (dispatch) => {
    if (persisted || !isOnline()) {
      return getPersistedKeeperSettings()
        .then(data => dispatch({ type: GET_KEEPER_SETTINGS, payload: data }));
    }
    return getKeeperSettingsDataRequest()
      .then((res) => {
        dispatch({ type: GET_KEEPER_SETTINGS, payload: res.data });
      })
      .catch(showErrorMessage);
  };
}

export function editKeeperSettings(data) {
  return dispatch => updateKeeperSettingsRequest(data)
    .then((res) => {
      dispatch({ type: SET_KEEPER_SETTINGS, payload: res.data });

      persistWithKey('keeper', res.data, idbKeeper);

      dispatch(showMessage({ text: i18n.t('actions.keeperEditedTitle') }));
    })
    .catch(showErrorMessage);
}
