import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { calculateBill } from '../../redux/statistic/actions';
import PayModal from './PayModal';
import ModalHOC from '../../common/ModalHOC';

function mapStateToProps(state) {
  return {
    activeKey: state.statistic.activeKey,
    bills: state.statistic.activeBills,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        calculateBill,
      },
      dispatch,
    ),
  };
}

export default ModalHOC(withRouter(connect(mapStateToProps, mapDispatchToProps)(PayModal)));
