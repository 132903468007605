import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { showMessage } from '../../../redux/message/actions';
import Sales from './Sales';
import { removeSale } from '../../../redux/marketing/actions';

function mapStateToProps(state) {
  return {
    sales: state.marketing.sales,
    salesLoaded: state.marketing.salesLoaded,
    menu: state.menu,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showMessage,
        removeSale,
      },
      dispatch,
    ),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sales));
