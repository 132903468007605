import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Table } from 'antd';
import { connect } from 'react-redux';
import {
  calculateComponentsCost, calculateConsumablesCost, getSizeName, sizesSort, validateNumberInput, getEmptyMaterial,
} from '../utils/helpers';
import NumberInput from './NumberInput';

class GeneralTable extends Component {
  constructor(props) {
    super(props);
    const { price } = props;
    this.state = {
      price,
    };
  }

  getMissedItems = () => {
    const {
      components, consumables, equipment: { idMap }, t,
    } = this.props;
    const usedConsumables = consumables.map(el => Object.keys(el.sizes).map(key => el.sizes[key])).flat();
    const filteredUniqConsumables = Array.from(new Set(usedConsumables));
    return [
      ...components.filter(el => el.cost === 0).map(el => el.name),
      ...filteredUniqConsumables.map(el => idMap[el] || getEmptyMaterial(t))
        .filter(el => el.cost === 0 && el.id !== 0).map(el => el.name),
    ];
  };

  updatePrice = (value, size) => {
    const { callback } = this.props;
    this.setState(state => ({
      price: { ...state.price, [size]: parseInt(value, 10) || 0 },
    }), () => callback(this.state));
  };

  getRecordByName = (record, size, text) => {
    const { t, business } = this.props;
    const { price } = this.state;
    switch (record.name) {
      case t('global.price'): return (
        <span className="product-modal-table-text">{`${price[size]} ${business.currency}`}</span>);
      case t('global.gross'): return (
        <span className="product-modal-table-text">{text || 0} {business.currency}</span>);
      case t('cost.title'): return (<span className="product-modal-table-text">{text || 0} {business.currency}</span>);
      default: return (
        <span className="product-modal-table-text">{parseInt(price[size] / text * 100, 10) || 0} %</span>
      );
    }
  };

  getFormByName = (record, size, text) => {
    const { t, business } = this.props;
    const { price } = this.state;
    switch (record.name) {
      case t('global.price'): return (
        <NumberInput
          onKeyUp={validateNumberInput}
          style={{ width: '100%', minWidth: '100px' }}
          placeholder="0"
          type="tel"
          onChange={e => this.updatePrice(e.target.value, size)}
          value={price[size] === 0 ? '' : price[size]}
          addonAfter={business.currency}
        />
      );
      case t('global.gross'): return (
        <span className="product-modal-table-text">{text || 0} {business.currency}</span>);
      case t('cost.title'): return (
        <span className="product-modal-table-text">{text || 0} {business.currency}</span>);
      default: return (
        <span className="product-modal-table-text">{parseInt(price[size] / text * 100, 10) || 0} %</span>
      );
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.sizes.length !== this.props.sizes.length || nextProps.inEdit !== this.props.inEdit) {
      const { price } = nextProps;
      this.setState({ price });
    }
  }

  render() {
    const {
      inEdit, sizes, t, components, consumables,
    } = this.props;
    const { price } = this.state;
    const calculationColumns = [
      { title: t('global.pricing'), dataIndex: 'name', width: 170 },
      ...sizes.sort(sizesSort).map(size => ({
        title: getSizeName(size),
        className: 'table-header-center',
        dataIndex: size,
        // Magic for same column width, where 0.95 is 95vw, 170px is a name cell width and 48px is a container padding
        width: window.innerWidth > 768 ? (window.innerWidth * 0.95 - 170 - 48) / sizes.length : 200,
        render: (text, record) => (inEdit
          ? this.getFormByName(record, size, text)
          : this.getRecordByName(record, size, text)),
      })),
    ];

    const data = [
      { key: 1, name: t('global.price'), ...price },
    ];

    const allItemHasCost = this.getMissedItems().length === 0;

    if (allItemHasCost && (components.length > 0 || consumables.length > 0)) {
      const { menu, equipment } = this.props;
      const costs = sizes.reduce((res, size) => ({
        ...res,
        [size]: (calculateComponentsCost(components, menu.idMap, size)
        + calculateConsumablesCost(consumables, equipment.idMap, size)).toFixed(2),
      }), {});
      data.push({ key: 2, name: t('cost.title'), ...costs });
      data.push({ key: 3, name: t('global.extra'), ...costs });
      data.push({
        key: 4,
        name: t('global.gross'),
        ...sizes.reduce((res, size) => ({
          ...res,
          [size]: (parseInt(price[size], 10) - calculateComponentsCost(components, menu.idMap, size)
          - calculateConsumablesCost(consumables, equipment.idMap, size)).toFixed(2),
        }), {}),
      });
    }
    return (
      <Table
        bordered
        pagination={false}
        size="default"
        className="storage-page-table"
        columns={calculationColumns}
        dataSource={data}
        footer={
          !allItemHasCost && (components.length === 0 || consumables.length === 0)
            ? () => t('cost.missedItem')
            : undefined
        }
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    equipment: state.equipment,
    business: state.business,
  };
}

export default connect(mapStateToProps, {})(withTranslation()(GeneralTable));
